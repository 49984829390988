import createActionCreator from './createActionCreator';

export const buildActionCreators = (definition, actionTypes) =>
    Object.keys(definition).reduce((actionCreators, key) => {
        const actionCreator = createActionCreator(actionTypes[key], definition[key]);
        return actionCreator
            ? {
                  ...actionCreators,
                  [key]: actionCreator,
              }
            : actionCreators;
    }, {});

export default buildActionCreators;
