import {
    EVENT_TYPE,
    NOTIFICATION_TYPES,
    RESOURCE_TYPE,
    UPDATE_FREQUENCY,
} from '/b2b/notifications/constants';

const UPDATE_FREQUENCY_LABELS = {
    [UPDATE_FREQUENCY.NEVER]: 'notifications.updateFrequency.never',
    [UPDATE_FREQUENCY.DAILY]: 'notifications.updateFrequency.daily',
    [UPDATE_FREQUENCY.WEEKLY]: 'notifications.updateFrequency.weekly',
    [UPDATE_FREQUENCY.MONTHLY]: 'notifications.updateFrequency.monthly',
};

const UPDATE_FREQUENCY_DAILY_NEVER_LABELS = {
    [UPDATE_FREQUENCY.NEVER]: 'notifications.updateFrequency.never',
    [UPDATE_FREQUENCY.DAILY]: 'notifications.updateFrequency.daily',
    [UPDATE_FREQUENCY.WEEKLY]: 'notifications.updateFrequency.weekly',
};

const UPDATE_FREQUENCY_ALWAYS_NEVER_LABELS = {
    [UPDATE_FREQUENCY.NEVER]: 'notifications.updateFrequency.never',
    [UPDATE_FREQUENCY.ALWAYS]: 'notifications.updateFrequency.always',
};

const FREQUENCY_OPTIONS = {
    ...Object.entries(UPDATE_FREQUENCY_LABELS).reduce((options, [value, label]) => {
        options[value.toLowerCase()] = { label, value };

        return options;
    }, {}),
};

const DAILY_OR_NEVER_FREQUENCY_OPTIONS = {
    ...Object.entries(UPDATE_FREQUENCY_DAILY_NEVER_LABELS).reduce((options, [value, label]) => {
        options[value.toLowerCase()] = { label, value };

        return options;
    }, {}),
};

const ALWAYS_OR_NEVER_FREQUENCY_OPTIONS = {
    ...Object.entries(UPDATE_FREQUENCY_ALWAYS_NEVER_LABELS).reduce((options, [value, label]) => {
        options[value.toLowerCase()] = { label, value };

        return options;
    }, {}),
};

export function getPreferenceKeyPath(name) {
    const [, resourceType, eventType, preference] = name.split('.');
    return [resourceType, eventType, preference];
}

export const SCHEMA_OPTIONS = {
    /*
    [NOTIFICATION_TYPES.ASKANALYST]: {
        name: `${RESOURCE_TYPE.ASKANALYST}.${EVENT_TYPE.ASKANALYST_RESPONSE}`,
        label: 'Privacy Team',
        type: 'button',
        helperText: 'When you receive a response from the Privacy Team',
        formGroup: {
            options: FREQUENCY_OPTIONS,
        },
    },
    */
    [NOTIFICATION_TYPES.CONSENT_MANAGER]: {
        name: `${RESOURCE_TYPE.CONSENTMANAGER}.${EVENT_TYPE.TATTLE}`,
        label: 'notifications.consentManager.label',
        type: 'button',
        helperText: 'notifications.consentManager.theFirstTimeYouReceiveATattle',
        formGroup: {
            options: FREQUENCY_OPTIONS,
        },
    },
    [NOTIFICATION_TYPES.DATA_MAPPING]: {
        name: `${RESOURCE_TYPE.DISCO}.${EVENT_TYPE.SCAN}`,
        label: 'notifications.dataProvider.dataProviderScans',
        type: 'button',
        helperText: 'notifications.dataProvider.whenIsInitiated',
        formGroup: {
            options: FREQUENCY_OPTIONS,
        },
    },
    [NOTIFICATION_TYPES.DSAR]: {
        name: `${RESOURCE_TYPE.DSAR}.${EVENT_TYPE.DSAR_NEW_REQUEST}`,
        label: 'notifications.dsar.updates',
        type: 'button',
        helperText: 'notifications.dsar.requests',
        formGroup: {
            options: DAILY_OR_NEVER_FREQUENCY_OPTIONS,
        },
    },
    [NOTIFICATION_TYPES.LEGISLATION]: {
        name: `${RESOURCE_TYPE.LEGISLATION}.${EVENT_TYPE.LEGISLATION_POST_UPDATE}`,
        label: 'notifications.legislation.privacyLawUpdates',
        type: 'button',
        helperText: 'notifications.legislation.legislationIsPending',
        formGroup: {
            options: FREQUENCY_OPTIONS,
        },
    },
    [NOTIFICATION_TYPES.LITIGATION]: {
        name: `${RESOURCE_TYPE.LITIGATION}.${EVENT_TYPE.LITIGATION_UPDATE}`,
        label: 'notifications.litigation.vendorLawsuitUpdates',
        type: 'button',
        helperText: 'notifications.litigation.followedVendors',
        formGroup: {
            options: FREQUENCY_OPTIONS,
        },
    },
    [NOTIFICATION_TYPES.VENDOR]: {
        name: `${RESOURCE_TYPE.VENDOR}.${EVENT_TYPE.SCORE_UPDATE}`,
        label: 'notifications.vendor.updates',
        type: 'button',
        helperText: 'notifications.vendor.privacyScoreChanges',
        formGroup: {
            options: FREQUENCY_OPTIONS,
        },
    },
    [`${NOTIFICATION_TYPES.VENDOR}.preferences.assigned`]: {
        name: `preferences.${RESOURCE_TYPE.VENDOR}.${EVENT_TYPE.SCORE_UPDATE}.assigned`,
        label: 'notifications.vendor.preferences.updates',
        type: 'switch',
    },
    [`${NOTIFICATION_TYPES.VENDOR}.preferences.scoreThresholdEnabled`]: {
        name: `preferences.${RESOURCE_TYPE.VENDOR}.${EVENT_TYPE.SCORE_UPDATE}.scoreThresholdEnabled`,
        label: 'notifications.vendor.privacyScore.enabled.label',
        type: 'checkbox',
    },
    [`${NOTIFICATION_TYPES.VENDOR}.preferences.scoreThreshold`]: {
        depends: {
            [`preferences.${RESOURCE_TYPE.VENDOR}.${EVENT_TYPE.SCORE_UPDATE}.scoreThresholdEnabled`]: true,
        },
        numericality: { strict: true },
        name: `preferences.${RESOURCE_TYPE.VENDOR}.${EVENT_TYPE.SCORE_UPDATE}.scoreThresholdValue`,
        label: 'notifications.vendor.privacyScore.threshold.label',
        type: 'slider',
        helperText: 'notifications.vendor.privacyScore.threshold.helperText',
        props: {
            inputSlider: false,
            min: 0,
            max: 100,
            step: 1,
            marks: [0, 25, 50, 75, 100].map(value => ({ value, label: value })),
            style: { maxWidth: '50%' },
        },
    },
    [NOTIFICATION_TYPES.ASSESSMENT]: {
        name: `${RESOURCE_TYPE.ASSESSMENT_ASSIGNMENT}.${EVENT_TYPE.ASSESSMENT_ASSIGNED}`,
        label: 'notifications.assessments',
        type: 'button',
        helperText: 'notifications.assessment.assessmentAssignment',
        formGroup: {
            options: ALWAYS_OR_NEVER_FREQUENCY_OPTIONS,
        },
    },
    [NOTIFICATION_TYPES.SITE_BREACH]: {
        name: `${RESOURCE_TYPE.SITE_BREACH}.${EVENT_TYPE.VENDOR_BREACH}`,
        label: 'notifications.vendorBreaches',
        type: 'button',
        helperText: 'notifications.vendor.vendorBreaches',
        formGroup: {
            options: ALWAYS_OR_NEVER_FREQUENCY_OPTIONS,
        },
    },
};

export const notificationDefaults = {
    [`preferences.${RESOURCE_TYPE.VENDOR}.${EVENT_TYPE.SCORE_UPDATE}.assigned`]: false,
    [`preferences.${RESOURCE_TYPE.VENDOR}.${EVENT_TYPE.SCORE_UPDATE}.scoreThresholdEnabled`]: false,
    [`preferences.${RESOURCE_TYPE.VENDOR}.${EVENT_TYPE.SCORE_UPDATE}.scoreThresholdValue`]: 75,
};

export const createHelperText = notificationPermissions =>
    notificationPermissions.reduce((helperTextOptions, value) => {
        if (SCHEMA_OPTIONS[value]) {
            const { helperText, name } = SCHEMA_OPTIONS[value];
            helperTextOptions[name] = helperText || '';
        }

        return helperTextOptions;
    }, {});

export default (notificationPermissions = []) =>
    notificationPermissions.reduce((schema, value) => {
        if (!schema[value] && SCHEMA_OPTIONS[value]) {
            // eslint-disable-next-line no-unused-vars
            const { helperText, name, ...remain } = SCHEMA_OPTIONS[value];
            schema[name] = remain;
        }
        for (const option of Object.keys(SCHEMA_OPTIONS)) {
            const { name, ...remain } = SCHEMA_OPTIONS[option];
            if (!schema[name] && option.startsWith(value)) {
                schema[name] = remain;
            }
        }
        return schema;
    }, {});
