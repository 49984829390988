// Palette
import palette from '../palette';

export default {
    styleOverrides: {
        root: {
            backgroundColor: palette.background.paper,
            '&.Mui-selected': {
                backgroundColor: palette.common.grey[200],
            },
            '&.Mui-focused': {
                backgroundColor: palette.common.grey[300],
            },
            '&:hover': {
                backgroundColor: palette.common.grey[300],
            },
            '&.Mui-selected:hover': {
                backgroundColor: palette.common.grey[300],
            },
            '&.Mui-disabled': {
                pointerEvents: 'auto',
            },
        },
    },
};
