import { defaultMergeStrategy, defaultProcessStrategy } from '/b2b/common/helpers/Normalizr';

import { schema } from 'normalizr';

export const entityName = 'consentConfigTattle';
export const idAttribute = 'storeKey';

export const mergeStrategy = defaultMergeStrategy(idAttribute);
export const processStrategy = defaultProcessStrategy(idAttribute);

export default new schema.Entity(entityName, undefined, {
    idAttribute: value => value[idAttribute] || value.config_id || value.id,
    mergeStrategy,
    processStrategy,
});
