import PropTypes from 'prop-types';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import styles from './TabPanel.styles';

const useStyles = makeStyles(styles);

function a11yProps(name, tab) {
    return {
        id: `${name}-tabpanel-${tab}`,
        'aria-labelledby': `${name}-tab-${tab}`,
    };
}

const TabPanel = props => {
    const { children, className, name, selected, tab, forceRenderTabPanel, ...remain } = props;
    const classes = useStyles(props);
    return (
        <div
            role="tabpanel"
            {...a11yProps(name, tab)}
            {...remain}
            className={selected !== tab ? classes.hidden : className}
        >
            {forceRenderTabPanel || tab === selected ? children : null}
        </div>
    );
};

TabPanel.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    name: PropTypes.string,
    tab: PropTypes.any.isRequired,
    selected: PropTypes.any,
    forceRenderTabPanel: PropTypes.bool,
};

export default TabPanel;
