export default theme => ({
    active: {
        color: theme.palette.tertiary.main,
    },
    iconWrapper: {
        padding: theme.spacing(0, 1, 0, 0),
        color: theme.palette.common.muted,
        cursor: 'pointer',
        display: 'inline-block',
    },
});
