import Component from './TrafficGraph.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const mapStateToProps = state => {
    return {
        data: fromState.Dashboard.getTrafficData(state),
        isLoading: fromState.Dashboard.isLoading(state),
        daysAgo: 30,
        localeCode: fromState.Locale.getLocaleCode(state),
    };
};

export default connect(mapStateToProps, {})(Component);
