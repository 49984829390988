import { DOMAIN } from './Dashboard.constants';
import get from 'lodash/get';

export const getStore = state => get(state, DOMAIN) || {};

export const getError = state => getStore(state).error;
export const getFilters = state => getStore(state).filters || {};
export const hasFilters = state =>
    Object.values(getFilters(state)).reduce(
        (hasFilters, filters) => hasFilters || filters.length,
        false
    );

export const isLoading = state => getStore(state).loading;
