import {
    resendPhoneVerificationCode,
    setPreferredMFA as setPreferredMFAService,
    verifyPhone,
} from '../../../services';

import AuthActions from '../../../state/Auth.actions';

export const resendCode = params => dispatch => {
    dispatch(AuthActions.resendPhoneVerificationCodeBegin(params));

    return resendPhoneVerificationCode(params)
        .then(response => {
            return dispatch(AuthActions.resendPhoneVerificationCodeSuccess(params, response));
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthActions.resendPhoneVerificationCodeFailure(params, error));

            throw message;
        });
};

export const setPreferredMFA = params => dispatch => {
    dispatch(AuthActions.setPreferredMfaBegin(params));

    return setPreferredMFAService(params)
        .then(response => {
            return dispatch(AuthActions.setPreferredMfaSuccess(params, response));
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthActions.setPreferredMfaFailure(params, message));

            throw message;
        });
};

export const verify = params => dispatch => {
    dispatch(AuthActions.verifyPhoneBegin(params));

    return verifyPhone(params)
        .then(response => {
            dispatch(AuthActions.verifyPhoneSuccess(params, response));

            return setPreferredMFA({ mfaType: 'SMS' })(dispatch);
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthActions.verifyPhoneFailure(params, message));

            throw message;
        });
};
