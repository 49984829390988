import noop from 'lodash/noop';
import { v4 as uuid } from 'uuid';

export const noParameters = noop;
export const singleParameter = payload => ({
    payload,
});
export const namedParameters = (...names) => {
    let prefill;
    if (typeof names[names.length - 1] === 'object') {
        // Use prefill
        prefill = names.pop();
    }
    if (names.some(name => typeof name !== 'string')) {
        throw new Error(`Redux Utils :: 'namedParameters' arguments must be of type 'String'`);
    }
    return (...params) => ({
        payload: names.reduce(
            (payload, name, index) => {
                return {
                    ...payload,
                    [name]: params[index],
                };
            },
            { ...prefill }
        ),
    });
};

export const withMeta =
    getPayload =>
    (meta, ...params) => {
        if (meta && typeof meta === 'object') {
            const { asyncId = uuid() } = meta || {};
            meta.asyncId = asyncId;
        }
        return {
            ...getPayload(...params),
            ...(meta ? { meta } : {}),
        };
    };
