import Actions from '../../authentication/state/user/notifications/AuthUserNotifications.actions';
import DBActions from '../../common/state/db/DB.actions';
import Poll from '../../common/helpers/Poll';
import { fetchNotifications } from '../services';
import schema from '../state/db/Notification.schema';

export const pollNotifications =
    ({ immediate = true, delay = 1, ...params }) =>
    dispatch => {
        const meta = { schema };
        const request = Poll(
            () => {
                dispatch(Actions.refreshBegin(meta, params));

                return fetchNotifications(params);
            },
            {
                delay,
                immediate,
                interval: 60000,
                retry: 20,
                timeout: 10000,
            }
        );

        return request
            .change(response => {
                dispatch(DBActions.update(meta, response));
                dispatch(Actions.refreshSuccess(meta, params, response));
            })
            .catch(error => {
                const { message } = error;
                dispatch(Actions.refreshFailure(meta, params, message));
                switch (message) {
                    case 'Timeout':
                    case 'Aborted': {
                        break;
                    }

                    default: {
                        throw message;
                    }
                }
            });
    };
