import { types as ActionTypes, asyncEvents } from './DB.actions';
import DBSchema, { lookup } from './DB.schema';

import { types as AuthActionTypes } from '../../../authentication/state/Auth.actions';
import CustomerReducer from './customer/Customer.reducer';
import UserReducer from '../../../users/state/db/User.reducer';
import castArray from 'lodash/castArray';
import { genericReducer } from '../../helpers/ReduxHelpers';
import { normalize } from 'normalizr';
import reduceReducers from 'reduce-reducers';

export const initialState = Object.keys(lookup).reduce(
    (state, key) => ((state[key] = {}), state),
    {}
);

initialState.__async__ = Object.keys(initialState).reduce(
    (state, key) => ((state[key] = {}), state),
    {}
);

const asyncEntityLoader =
    type =>
    (state, { params, response: payload = {} } = {}, meta) => {
        const { schema } = meta || {};
        if (!schema) {
            return state;
        }
        const { id = schema.idAttribute(payload) } = meta;
        const { results = id ? [{ ...payload, id }] : [] } = payload;
        const entities = {
            ...(state.__async__[schema.key] || {}),
        };
        const updateParamKeys = Object.keys(params);
        if (type.slice(-5).toLowerCase() === 'begin') {
            results.forEach(entity => {
                const entityId = schema.idAttribute(entity);
                entities[entityId] = {
                    ...entities[entityId],
                    ...updateParamKeys.reduce(
                        (updateParams, key) => ((updateParams[key] = true), updateParams),
                        {}
                    ),
                };
            });
        } else if (
            type.slice(-7).toLowerCase() === 'success' ||
            type.slice(-7).toLowerCase() === 'failure'
        ) {
            results.forEach(entity => {
                const entityId = schema.idAttribute(entity);
                const newEntityAsync = { ...entities[entityId] };
                updateParamKeys.forEach(key => delete newEntityAsync[key]);
                if (Object.keys(newEntityAsync).length === 0) {
                    delete entities[entityId];
                } else {
                    entities[entityId] = newEntityAsync;
                }
            });
        } else {
            return state;
        }
        return {
            ...state,
            __async__: {
                ...state.__async__,
                [schema.key]: entities,
            },
        };
    };
const asyncReducer = genericReducer(
    initialState.__async__,
    Object.keys(asyncEvents).reduce(
        (reducers, event) => (
            (reducers[ActionTypes[event]] = asyncEntityLoader(ActionTypes[event])), reducers
        ),
        {}
    )
);

const mergeDBEntities = (state, response = {}, meta) => {
    const { schema } = meta || {};
    if (!schema) {
        return state;
    }
    let responseId;
    try {
        responseId = schema.idAttribute(response);
    } catch (e) {
        // Response was not a details object
    }
    const { id = responseId } = meta;
    const { results = response ? [response] : [] } = response || {};
    const { entities } = normalize(
        results.map(result => (id ? { ...result, id } : result)),
        [lookup[schema.key] || schema]
    );
    // We need to perform the merge this way so that it uses the merge strategy per-schema
    return {
        __async__: state.__async__,
        ...(normalize([state, entities], [DBSchema]).entities || state),
    };
};

export default reduceReducers(
    initialState,
    CustomerReducer,
    UserReducer,
    asyncReducer,
    genericReducer(initialState, {
        [ActionTypes.init]: (state, initData) => {
            const { db /* eslint-disable-line no-unused-vars */, ...initEntities } = normalize(
                initData,
                DBSchema
            ).entities;
            return {
                ...state,
                ...initEntities,
            };
        },
        [AuthActionTypes.logout]: () => initialState,
        [ActionTypes.create]: mergeDBEntities,
        [ActionTypes.retrieve]: mergeDBEntities,
        [ActionTypes.update]: mergeDBEntities,
        [ActionTypes.delete]: (state, response, meta) => {
            const { schema } = meta || {};
            if (!schema) {
                return state;
            }
            const { id = schema.idAttribute(response) } = meta;
            const newState = { ...state[schema.key] };
            castArray(id).forEach(removed => {
                delete newState[removed];
            });
            return {
                ...state,
                [schema.key]: newState,
            };
        },
    })
);
