import { Link, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import UnauthenticatedPaths from '../UnauthenticatedRoutes.paths';
import ValidationForm from '../../../common/components/ValidationForm';
import makeStyles from '@mui/styles/makeStyles';
import schema from './ForgotPassword.schema';
import styles from './ForgotPassword.styles';

const useStyles = makeStyles(styles);

const ResetPassword = props => {
    const { onForgotPassword, username } = props;
    const classes = useStyles(props);
    return (
        <div className={classes.content}>
            <div className={classes.contentBody}>
                <ValidationForm
                    title="Forgot Password"
                    subtitle="Enter your email address and we will send you a password reset code."
                    submitText="Email Reset Code"
                    schema={schema}
                    onSubmit={onForgotPassword}
                    defaultValues={{ username }}
                    allowSubmit={!!username}
                >
                    <Typography className={classes.resend} variant="body1">
                        Already have a reset code?{' '}
                        <Link
                            component={RouterLink}
                            className={classes.resendUrl}
                            to={UnauthenticatedPaths.RESET_PASSWORD}
                        >
                            Reset Your Password
                        </Link>
                    </Typography>
                </ValidationForm>
            </div>
        </div>
    );
};

ResetPassword.propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    onForgotPassword: PropTypes.func.isRequired,
    username: PropTypes.string,
};

export default ResetPassword;
