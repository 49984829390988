import { Link, Typography } from '@mui/material';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';

import Locale from '/b2b/common/components/Locale';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import UnauthenticatedPaths from '/b2b/routing/UnauthenticatedRoutes/UnauthenticatedRoutes.paths';
import ValidationForm from '/b2b/common/components/ValidationForm';
import makeStyles from '@mui/styles/makeStyles';
import schema from './SignIn.schema';
import styles from './SignIn.styles';

const useStyles = makeStyles(styles);
const defaultValues = { useCognito: false };

const SignIn = props => {
    const { email, isLoading, onSignIn, rememberMe } = props;
    const classes = useStyles(props);
    const isMounted = useRef(false);
    const [useCognito, setUseCognito] = useState(null);
    const submitText = useCognito ? 'common.signIn' : 'common.next';

    const handleSignIn = params => {
        return onSignIn({ ...params, useCognito }).then(context => {
            isMounted.current && setUseCognito(!!context);
            return { useCognito: !!context };
        });
    };

    const values = useMemo(() => {
        return {
            rememberMe,
            useCognito: useCognito === null ? !!email : useCognito,
            username: useCognito !== false ? email || '' : '',
        };
    }, [email, rememberMe, useCognito]);

    useEffect(() => {
        const { username } = values;
        if (!useCognito && username) {
            onSignIn({ username, useCognito: null }).then(context => {
                isMounted.current && setUseCognito(!!context);
            });
        }
    }, [useCognito]);

    useEffect(() => {
        isMounted.current = true;
        return () => (isMounted.current = false);
    });

    return (
        <div className={classes.content}>
            <div className={classes.contentBody}>
                <ValidationForm
                    title="common.signIn"
                    defaultValues={defaultValues}
                    submitText={submitText}
                    schema={schema}
                    onSubmit={handleSignIn}
                    continuousValidation={false}
                    isLoading={isLoading}
                    allowSubmit={!isLoading && useCognito !== null}
                    values={values}
                >
                    {useCognito && (
                        <Fragment>
                            <Typography className={classes.signUp} variant="body1">
                                <Link
                                    component={RouterLink}
                                    className={classes.signUpUrl}
                                    to={UnauthenticatedPaths.FORGOT_PASSWORD}
                                >
                                    <Locale path="password.forgot" />
                                </Link>
                            </Typography>
                            <Typography className={classes.signUp} variant="body1">
                                <Locale path="auth.shouldUseDifferentAccount" />{' '}
                                <Link
                                    component={RouterLink}
                                    className={classes.signUpUrl}
                                    to={UnauthenticatedPaths.SIGN_IN}
                                    onClick={() => setUseCognito(false)}
                                >
                                    <Locale path="auth.useDifferentAccount" />
                                </Link>
                            </Typography>
                        </Fragment>
                    )}
                </ValidationForm>
            </div>
        </div>
    );
};

SignIn.propTypes = {
    className: PropTypes.string,
    email: PropTypes.string,
    isLoading: PropTypes.bool,
    onSignIn: PropTypes.func.isRequired,
    rememberMe: PropTypes.bool,
    useCognito: PropTypes.bool,
};

export default SignIn;
