import {
    contrastRgb,
    hslToRgb,
    hueFromString,
    intToHexString,
    parseColor,
} from '../common/helpers/Color';
import { extendPermissions, formatCustomer, formatRoles } from '../common/services/format';

import escapeRegExp from 'lodash/escapeRegExp';
import { formatOrganizations } from '../organizations/format';
import { getInitials } from '../common/helpers/String';
import { makeResultsFormatter } from '../common/services/helpers';

/**
 * This is the placeholder entity description for a User result
 * @typedef UserEntity
 * @property {string} firstName First Name
 * @property {string} lastName Last Name
 * @property {string} userId Unique userId
 * @property {string} email Email address for the user
 * @property {string} name Full name of the user
 * @property {string} initials Initials for the user
 */

export const formatUser = details => {
    const {
        name: fullName,
        email: providedEmail,
        flags: featureFlags = [],
        first_name,
        last_name,
        firstname,
        lastname,
        first,
        last,
        photoUrl,
        customer_id,
        permissions,
        orgIds,
        organizations = orgIds,
        roles,
        user_id,
        userId = user_id,
        ...remain
    } = details || {};

    let { firstName, lastName, photo, customers = [], customerId = customer_id } = details;
    customers = customers.map(formatCustomer);
    // eslint-disable-next-line camelcase
    firstName = firstName || firstname || first_name || first;
    // eslint-disable-next-line camelcase
    lastName = lastName || lastname || last_name || last;
    photo = photo || photoUrl;
    const name =
        `${firstName || ''}${firstName && lastName ? ' ' : ''}${lastName || ''}` || fullName;
    const initials = name && getInitials(name);
    // Must lowercase all emails
    const email = `${providedEmail || ''}`.toLowerCase();
    // const rgb = name && hslToRgb(hueFromString(`${name}`) / 360, 0.6, 0.9);
    // const color = rgb && intToHexString.apply(null, rgb);
    const newPermissions = extendPermissions(null, permissions);

    const newDetails = {
        ...remain,
        featureFlags,
        firstName,
        lastName,
        userId: userId || email,
        email,
        name,
        initials,
        photo,
        organizations: organizations && formatOrganizations(organizations),
        roles: roles && formatRoles(roles, customerId),
        // Custom Color
        /*
            textColor: color && contrastRgb.apply(null, parseColor(color)) ? '#ffffff' : '#000000',
            color: color && `#${intToHexString.apply(null, parseColor(color))}`,
            */
        customers: customerId ? [...customers, customerId] : customers,
        permissions: newPermissions,
    };

    // Use the color logic from the ORM
    if (name) {
        const rgb = hslToRgb(hueFromString(`${name}`) / 360, 0.6, 0.9);
        const { color = intToHexString.apply(null, rgb) } = details;
        // Custom Color
        newDetails.textColor =
            color && contrastRgb.apply(null, parseColor(color)) ? '#ffffff' : '#000000';
        newDetails.color = color && `#${intToHexString.apply(null, parseColor(color))}`;
    }

    return newDetails;
};

export const formatUserResults = makeResultsFormatter(formatUser);

export const filterAutocompleteUsers = (options, { inputValue }) => {
    if (!inputValue) {
        // No search string, return everything
        return options;
    }
    // eslint-disable-next-line security/detect-non-literal-regexp
    const regExp = new RegExp(
        `(${escapeRegExp(inputValue).split(' ').filter(Boolean).join(')|(')})`,
        'im'
    );
    return options.filter(option => {
        // Label is username, value is email
        const { label = option, value = option } = option;
        return regExp.test(`${label}`) || regExp.test(`${value}`);
    });
};
