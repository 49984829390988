import Component from './TodoList.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const mapStateToProps = state => ({
    isLoading: fromState.Dashboard.isLoading(state),
    todos: fromState.Dashboard.getTodos(state),
});

export default connect(mapStateToProps)(Component);
