import { defaultMergeStrategy, defaultProcessStrategy } from '../../../common/helpers/Normalizr';

import Agent from './agent/DataStoreAgent.schema';
import Classification from './classification/DataStoreClassification.schema';
import Connection from './connection/DataStoreConnection.schema';
import Customer from '../../../common/state/db/customer/Customer.schema';
import DSARRequestType from '/b2b/dsarRequests/state/db/RequestType.schema';
import Field from './connection/field/DataStoreConnectionField.schema';
import Organization from '/b2b/organizations/state/db/Organization.schema';
import RequestAction from './requestAction/DataStoreRequestAction.schema';
import { schema } from 'normalizr';

export const entityName = 'dataStore';
export const idAttribute = 'providerId';

export const mergeStrategy = defaultMergeStrategy(idAttribute);
export const processStrategy = defaultProcessStrategy(
    idAttribute,
    function (details, parent, parentKey) {
        if (parentKey !== null && parentKey === 'provider') {
            // Has an agent defined
            const agentId = Agent.getId(parent);
            const currentAgents = details.agents || [];
            if (!currentAgents.includes(agentId)) {
                details.agents = [...currentAgents, agentId];
            }
        }
        return details;
    }
);

const DataStore = new schema.Entity(entityName, undefined, {
    idAttribute: value => value[idAttribute] || value.provider_id || value.id,
    mergeStrategy,
    processStrategy,
});

/*
A Data Store has:
    - many agents
*/
DataStore.define({
    agents: [Agent],
});

/*
An Agent has:
    - one provider
    - many connections
*/
Agent.define({
    connections: [Connection],
    provider: DataStore,
});

/*
A Connection has:
    - one customer
    - one agent
    - many organizations
*/
Connection.define({
    customer: Customer,
    agent: Agent,
    organizations: [Organization],
});

export const FieldRequestAction = new schema.Object({
    requestAction: RequestAction,
    requestType: DSARRequestType,
});

/*
A Field has:
    - one classification
    - one connection
    - multiple request action mappings
*/
Field.define({
    classification: Classification,
    connection: Connection,
    requestActions: [FieldRequestAction],
});

export default {
    [Agent.key]: Agent,
    [Classification.key]: Classification,
    [Connection.key]: Connection,
    [DataStore.key]: DataStore,
    [Field.key]: Field,
    [RequestAction.key]: RequestAction,
};
