import { cobalt, emerald, white } from '../../colors';

/* eslint react/prop-types: 0 */
import React from 'react';
import { SvgIcon } from '@mui/material';

export default function Products(props) {
    const { active, ...remain } = props;
    const primary = active ? emerald : emerald;
    const secondary = active ? cobalt : white;
    return (
        <SvgIcon fontSize="large" viewBox="0 0 66.53 66.53" {...remain}>
            <path d="m66.53 43.7h-43.7v-43.7h43.7zm-37.7-6h31.7v-31.7h-31.7z" fill={secondary} />
            <path d="m0 21.83h44.7v44.7h-44.7z" fill="none" />
            <path d="m44.2 66h-43.7v-43.67h43.7zm-37.7-6h31.7v-31.67h-31.7z" fill={primary} />
        </SvgIcon>
    );
}
