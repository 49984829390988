export default theme => ({
    formControl: {
        display: 'flex',
        margin: theme.spacing(1, 0),
    },
    error: {
        color: theme.palette.error.main,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0),
    },
});
