import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
} from '../../common/helpers/ReduxHelpers';

import { DOMAIN } from './NotificationList.constants';

export const events = {};

export const asyncEvents = makeAsyncEvents(['fetchResults'], true);

export const types = makeActionTypes([...Object.keys(events), ...Object.keys(asyncEvents)], DOMAIN);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
    },
    types
);
