import { Link, Typography } from '@mui/material';
import React, { Fragment, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import ReselectMFA from '../components/ReselectMFA';
import ValidationForm from '/b2b/common/components/ValidationForm';
import defaultSchema from './VerifyPhone.schema';
import makeStyles from '@mui/styles/makeStyles';
import styles from './VerifyPhone.styles';

const useStyles = makeStyles(styles);

const VerifyPhone = props => {
    const { phone, verify, resendCode } = props;
    const classes = useStyles(props);
    const [resent, setResent] = useState(null);
    const handleResend = () => {
        resendCode()
            .then(() => setResent(true))
            .catch(setResent);
    };

    const schema = useMemo(() => {
        const schema = {
            ...defaultSchema,
        };
        if (!phone) {
            delete schema.phone;
        }
        return schema;
    }, [phone]);

    useEffect(() => {
        resendCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.content}>
            <div className={classes.contentBody}>
                <ValidationForm
                    title="Confirm Sign In"
                    submitText="Continue Sign In"
                    schema={schema}
                    onSubmit={verify}
                    continuousValidation={false}
                    defaultValues={{ phone }}
                    extraFields={() => {
                        return (
                            <Fragment>
                                <Typography className={classes.resend} variant="body1">
                                    Didn&apos;t get a confirmation code?{' '}
                                    <Link className={classes.resendUrl} onClick={handleResend}>
                                        Resend
                                    </Link>
                                </Typography>
                                <Typography
                                    className={
                                        resent === true
                                            ? classes.resentSuccess
                                            : classes.submitError
                                    }
                                    variant="body2"
                                >
                                    {resent === true ? 'Confirmation code resent!' : resent}
                                </Typography>
                            </Fragment>
                        );
                    }}
                >
                    <ReselectMFA mfaType="SMS" />
                </ValidationForm>
            </div>
        </div>
    );
};

VerifyPhone.propTypes = {
    className: PropTypes.string,
    phone: PropTypes.string,
    username: PropTypes.string,
    hasPhone: PropTypes.bool,
    resent: PropTypes.bool,
    verify: PropTypes.func.isRequired,
    resendCode: PropTypes.func.isRequired,
};

export default VerifyPhone;
