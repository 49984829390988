import forms, { initialState as initialForms } from './forms/AuthCustomerDSARForms.reducer';
import stats, { initialState as initialStats } from './stats/AuthCustomerDSARStats.reducer';

import { combineReducers } from 'redux';

export const initialState = {
    forms: initialForms,
    stats: initialStats,
};

export default combineReducers({
    forms,
    stats,
});
