import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
} from '../../../common/helpers/ReduxHelpers';

import { DOMAIN } from './ConversationThreads.constants';

export const events = {};

export const asyncEvents = makeAsyncEvents(['fetchResults'], true);
export const postEvents = makeAsyncEvents(['postReply'], true);

export const types = makeActionTypes(
    [...Object.keys(events), ...Object.keys(asyncEvents), ...Object.keys(postEvents)],
    DOMAIN
);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
        ...postEvents,
    },
    types
);
