import {
    MAX_TIMEOUT_SECONDS,
    MIN_TIMEOUT_SECONDS,
    PUBLISH_STATUS,
    PUBLISH_STATUS_MESSAGES,
    validConsentConfigModes,
} from './constants';
import { intToHexString, parseColor } from '../common/helpers/Color';

import { filterUnique } from '../common/helpers/Array';
import { flatten } from '../common/helpers/flat';
import set from 'lodash/set';
import startCase from 'lodash/startCase';
import themePalette from '../common/theme/palette';

export const getPublishStatusText = publishStatus =>
    PUBLISH_STATUS_MESSAGES[publishStatus] || startCase(publishStatus || '');

export const isPublishing = publishStatus => {
    switch (publishStatus) {
        case PUBLISH_STATUS.BUILDING:
        case PUBLISH_STATUS.IN_PROGRESS:
        case PUBLISH_STATUS.QUEUED: {
            return true;
        }
    }
    return false;
};

export const createTranslationOverridesFromState = state => {
    const flatMap = flatten(state);
    return Object.entries(flatMap).reduce((overrides, [key, value]) => {
        const path = key.split('.');
        const localeCode = path.pop();
        if (localeCode) {
            const localization = overrides[localeCode] || {};
            set(localization, path, value);
            overrides[localeCode] = localization;
        }
        return overrides;
    }, {});
};

export const transformTranslationsForRequest = translations => {
    const flatTranslations = flatten(translations);
    return Object.entries(flatTranslations).reduce((transformed, [key, value]) => {
        if (!value) {
            return transformed;
        }

        const [localeCode, ...pathParts] = key.split('.');
        set(transformed, [...pathParts, localeCode], value);

        return transformed;
    }, {});
};

export const createConsentConfigPayloadFromState = (state, isNewUx) => {
    const { configuration, domain, domains = [], mode, ...remain } = state;
    const {
        additionalLinks,
        storagePolicyHref,
        iframeBlocking,
        iab = {},
        timeoutSeconds,
        translations = {},
        palette,
    } = configuration;
    // Only include iab.usp if notified is `true` (matching original payload)
    const { usp, ...restOfIab } = iab;
    const newIab = usp?.notified === false ? { ...restOfIab } : iab;

    const newConfiguration = {
        ...configuration,
        translations: isNewUx ? transformTranslationsForRequest(translations) : translations, // TODO: This can permanently be the transform...() once new UX is released
        iframeBlocking: iframeBlocking || '',
        storagePolicyHref: encodeURI(storagePolicyHref),
        additionalLinks: additionalLinks.map(([text, href]) => [text, encodeURI(href)]),
        iab: {
            ...newIab,
            notified: undefined,
            signatory: undefined,
        },
        palette,
        timeoutSeconds: Math.min(
            MAX_TIMEOUT_SECONDS,
            Math.max(MIN_TIMEOUT_SECONDS, timeoutSeconds || 10)
        ),
    };

    return {
        ...remain,
        mode: validConsentConfigModes.includes(mode) ? mode : validConsentConfigModes[0], // TODO: Add check for valid rules
        domains: domain ? [domain, ...domains].filter(filterUnique) : domains,
        configuration: newConfiguration,
    };
};

export const makeComplianceModeChipInlineStyles = (mode, darker = false) => {
    let color = darker ? themePalette.info.light : themePalette.data2.main;
    let textColor = themePalette.text.primary;

    switch (mode) {
        case '': {
            color = themePalette.tertiary.main;
            textColor = themePalette.tertiary.contrastText;
            break;
        }

        case 'debug': {
            color = darker ? themePalette.common.gray : themePalette.common.neutral;
            break;
        }

        case 'permissive': {
            color = darker ? themePalette.success.dark : themePalette.success.main;
            break;
        }

        default: {
            break;
        }
    }

    return {
        backgroundColor: `#${intToHexString.apply(null, parseColor(color))}`,
        borderColor: `#${intToHexString.apply(null, parseColor(color))}`,
        color: `#${intToHexString.apply(null, parseColor(textColor))}`,
    };
};

export const mapOrgToOption = ({ name, orgId }) => ({
    label: name,
    value: orgId,
});
