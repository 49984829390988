import { FormControlLabel, Switch } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import styles from './ValidationSwitch.styles';

const useStyles = makeStyles(styles);

const ValidationSwitch = props => {
    const { checked, name, value, onChange, error, disabled, inverted, ...remain } = props;
    const classes = useStyles(props);
    const handleToggle = event => {
        const newCheckedState = event.target.checked;
        onChange && onChange(newCheckedState ? value : null);
    };
    const labelClass = classNames(classes.label, error ? classes.error : '');

    return (
        <FormControlLabel
            className={labelClass}
            key={value}
            control={
                <Switch
                    checked={inverted ? !checked : checked}
                    color="secondary"
                    disabled={disabled}
                    onChange={handleToggle}
                    name={name}
                    className={classes.switch}
                    value={value}
                />
            }
            {...remain}
        />
    );
};

ValidationSwitch.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    inverted: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onChange: PropTypes.func,
    required: PropTypes.bool,
    value: PropTypes.string,
};

export default ValidationSwitch;
