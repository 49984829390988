const MIN_VALUE = Number.MIN_SAFE_INTEGER;
const MAX_VALUE = Number.MAX_SAFE_INTEGER;

export const validateNumber = value => !isNaN(value);

export const isNumberInRange = (value, minValue = MIN_VALUE, maxValue = MAX_VALUE) => {
    if (!value) {
        return false;
    }
    const floatValue = parseFloat(value);
    return floatValue <= maxValue && floatValue >= minValue;
};

export const createCounterObject = arrayOfKeys =>
    arrayOfKeys.reduce((counterObj, currValue) => {
        counterObj[currValue] = 0;
        return counterObj;
    }, {});
