import { Link, Typography } from '@mui/material';
import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import UnauthenticatedPaths from '../UnauthenticatedRoutes.paths';
import ValidationForm from '../../../common/components/ValidationForm';
import makeStyles from '@mui/styles/makeStyles';
import schema from './SignUpConfirm.schema';
import styles from './SignUpConfirm.styles';

const useStyles = makeStyles(styles);

const SignUpConfirm = props => {
    const { username, code, resent, onSignUpConfirm, onResendSignUpCode } = props;
    const classes = useStyles(props);
    return (
        <div className={classes.content}>
            <div className={classes.contentBody}>
                <ValidationForm
                    title="Confirm Sign Up"
                    subtitle="Check your email for a confirmation code, and enter it here."
                    submitText="Confirm Account"
                    defaultValues={{ username, code }}
                    schema={schema}
                    onSubmit={onSignUpConfirm}
                    extraFields={({ values }) => {
                        return (
                            <Fragment>
                                <Typography className={classes.resend} variant="body1">
                                    Didn&apos;t get a confirmation email?{' '}
                                    <Link
                                        className={classes.resendUrl}
                                        onClick={() =>
                                            onResendSignUpCode({
                                                username: values.username,
                                            })
                                        }
                                    >
                                        Resend
                                    </Link>
                                </Typography>
                                <Typography
                                    className={
                                        resent === true
                                            ? classes.resentSuccess
                                            : classes.submitError
                                    }
                                    variant="body2"
                                >
                                    {resent === true ? 'Confirmation email resent!' : resent}
                                </Typography>
                            </Fragment>
                        );
                    }}
                >
                    <Typography className={classes.signIn} variant="body1">
                        Back to{' '}
                        <Link
                            component={RouterLink}
                            className={classes.signInUrl}
                            to={UnauthenticatedPaths.SIGN_IN}
                        >
                            Sign In
                        </Link>
                    </Typography>
                </ValidationForm>
            </div>
        </div>
    );
};

SignUpConfirm.propTypes = {
    className: PropTypes.string,
    code: PropTypes.string,
    resent: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    username: PropTypes.string,
    onSignUpConfirm: PropTypes.func.isRequired,
    onResendSignUpCode: PropTypes.func.isRequired,
};

export default SignUpConfirm;
