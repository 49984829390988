import { Link, Typography } from '@mui/material';
import React, { useState } from 'react';
import { failureMessages, validatePassword } from '../../../common/helpers/Password';

import InvalidIcon from '@mui/icons-material/Close';
import Locale from '../../../common/components/Locale';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import UnauthenticatedPaths from '../UnauthenticatedRoutes.paths';
import ValidIcon from '@mui/icons-material/Check';
import ValidationForm from '../../../common/components/ValidationForm';
import makeStyles from '@mui/styles/makeStyles';
import schema from './ResetPassword.schema';
import styles from './ResetPassword.styles';

const useStyles = makeStyles(styles);

const ResetPassword = props => {
    const { onResetPassword, resetAuthState, username } = props;
    const classes = useStyles(props);
    const [passwordChecks, updatePasswordChecks] = useState(validatePassword('', true));

    const handleFieldChange = (field, value) => {
        if (field === 'newPassword') {
            updatePasswordChecks(validatePassword(value, true));
        }
    };

    return (
        <div className={classes.content}>
            <div className={classes.contentBody}>
                <ValidationForm
                    title="password.reset"
                    submitText="common.reset"
                    schema={props.schema}
                    onSubmit={onResetPassword}
                    onFieldChange={handleFieldChange}
                    defaultValues={{
                        username,
                    }}
                    helperText={{
                        newPassword: props.failureMessages.map(([key, message]) => (
                            <span className={classes.validate} key={key}>
                                {passwordChecks[key] ? (
                                    <ValidIcon className={classes.validIcon} />
                                ) : (
                                    <InvalidIcon className={classes.invalidIcon} />
                                )}
                                <span className={classes.validateLabel}>
                                    <Locale path={message} />
                                </span>
                            </span>
                        )),
                    }}
                >
                    <Typography className={classes.resend} variant="body1">
                        <Locale path="password.didNotGetCode" />{' '}
                        <Link
                            component={RouterLink}
                            className={classes.resendUrl}
                            onClick={resetAuthState}
                            to={UnauthenticatedPaths.FORGOT_PASSWORD}
                        >
                            <Locale path="password.resend" />
                        </Link>
                    </Typography>
                    <Typography className={classes.signIn} variant="body1">
                        <Locale path="password.remember" />{' '}
                        <Link
                            component={RouterLink}
                            className={classes.signInUrl}
                            onClick={resetAuthState}
                            to={UnauthenticatedPaths.SIGN_IN}
                        >
                            <Locale path="common.signIn" />
                        </Link>
                    </Typography>
                </ValidationForm>
            </div>
        </div>
    );
};

ResetPassword.propTypes = {
    className: PropTypes.string,
    failureMessages: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    onResetPassword: PropTypes.func.isRequired,
    resetAuthState: PropTypes.func.isRequired,
    schema: PropTypes.object.isRequired,
    username: PropTypes.string,
};

ResetPassword.defaultProps = { schema, failureMessages };

export default ResetPassword;
