import { errorReducer, genericReducer, loadingReducer } from '../../../common/helpers/ReduxHelpers';

import { types as ActionTypes } from './RequestTypes.actions';
import { combineReducers } from 'redux';
import schema from '/b2b/dsarRequests/state/db/RequestType.schema';

export const initialState = {
    loading: false,
    error: null,
    requestTypes: [],
};

export default combineReducers({
    loading: loadingReducer(
        initialState.loading,
        [
            ActionTypes.fetchResultsBegin,
            ActionTypes.fetchResultsSuccess,
            ActionTypes.fetchResultsFailure,
        ],
        schema
    ),
    error: errorReducer(
        initialState.error,
        [
            ActionTypes.fetchResultsBegin,
            ActionTypes.fetchResultsSuccess,
            ActionTypes.fetchResultsFailure,
        ],
        schema
    ),
    requestTypes: genericReducer(initialState.requestTypes, {
        [ActionTypes.fetchResultsSuccess]: (state, value) => {
            const { response: { results = [] } = {} } = value || {};
            return results;
        },
    }),
});
