import {
    assignProduct,
    assignVendor,
    formatDocument,
    formatDocumentAudit,
    formatDocumentVersion,
} from './format';
import { assignResults, getPaginatedResults, handleError } from '../common/services/helpers';

import API from '@aws-amplify/api';
import { apiName } from '@osano-b2b';

export const fetchFollowingDocumentChanges = params =>
    getPaginatedResults(`/documents/customer/follow`, params, formatDocumentAudit);

export const fetchProductDocuments = (productId, params) => {
    const options = {
        // Paging on product documents is currently unsupported
        /*
        queryStringParameters: {
            ...formatPagingParams(params),
        },
        */
    };
    return (
        API.get(apiName, `/product/${productId}/documents`, options)
            .catch(handleError)
            //.then(formatResults)
            .then(results => ({
                ...params,
                count: results ? results.length : 0,
                limit: results ? results.length : params.limit || 0,
                results: results ? results.map(formatDocument) : [],
            }))
            .then(assignResults(assignProduct(productId)))
    );
};

export const fetchVendorDocuments = params => {
    const { vendorId } = params;
    const options = {
        // Paging on vendor documents is currently unsupported
        /*
        queryStringParameters: {
            ...formatPagingParams(params),
        },
        */
    };
    return (
        API.get(apiName, `/vendor/${vendorId}/documents`, options)
            .catch(handleError)
            //.then(formatResults)
            .then(results => ({
                ...params,
                count: results ? results.length : 0,
                limit: results ? results.length : params.limit || 0,
                results: results ? results.map(formatDocument) : [],
            }))
            .then(assignResults(assignVendor(vendorId)))
    );
};

export const fetchDocumentById = documentId => {
    const options = {};
    return API.get(apiName, `/document/${documentId}`, options)
        .catch(handleError)
        .then(formatDocument);
};

export const fetchDocumentChangesCount = () => {
    const options = {};
    return API.get(apiName, `/documents/customer/follow/update-count`, options)
        .catch(handleError)
        .then(({ count = 0 } = {}) => count);
};

export const fetchDocumentVersionsById = (documentId, params) =>
    getPaginatedResults(`/document/${documentId}/versions`, params, formatDocumentVersion);

export const fetchDocumentVersionById = params => {
    const { documentId, versionId } = params;
    const options = {};
    return API.get(apiName, `/document/${documentId}/version/${versionId}`, options)
        .catch(handleError)
        .then(formatDocumentVersion);
};

const fetchMarkdownFromUrls = (...args) => {
    const options = {};
    const promises = args.map(file => fetch(file, options));
    return Promise.all(promises)
        .then(response => {
            const [then, now] = response;
            if (!then.ok) {
                const error = (then && then.message) || (then && then.statusText);
                throw new Error(error);
            }
            if (!now || !now.ok) {
                return Promise.all([then.text()]);
            }
            return Promise.all([then.text(), now.text()]);
        })
        .then(response => {
            let [then, now] = response;
            now = now || then;
            return {
                oldVersion: then,
                newVersion: now,
            };
        });
};

export const fetchDocumentMarkdownById = params => {
    const { documentId, versionId = null } = params;
    const path = versionId
        ? `/document/${documentId}/version/${versionId}/markdown`
        : `/document/${documentId}/markdown`;

    const options = {};
    return API.get(apiName, path, options)
        .catch(handleError)
        .then(data => {
            // TODO: Remove this when parameters are passed from API
            // Parse data from url
            const url = new URL(data.url);
            const attributes = {};
            /* eslint-disable-next-line no-unused-vars */
            for (let key of url.searchParams.keys()) {
                const value = url.searchParams.getAll(key);
                attributes[key] = value.length === 1 ? value[0] : value;
            }
            const { versionId: returnedVersion } = attributes;
            return fetchMarkdownFromUrls(data.url).then(results => {
                return {
                    ...data,
                    versionId: versionId || returnedVersion,
                    ...results,
                };
            });
        });
};

export const fetchDocumentMarkdownCompare = params => {
    const { documentId, fromVersionId, toVersionId } = params;
    const options = {};
    return Promise.all([
        API.get(apiName, `/document/${documentId}/version/${fromVersionId}/markdown`, options),
        API.get(apiName, `/document/${documentId}/version/${toVersionId}/markdown`, options),
    ])
        .catch(handleError)
        .then(data => {
            const [fromVersion, toVersion] = data;
            return fetchMarkdownFromUrls(fromVersion.url, toVersion.url);
        });
};
