export default theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.border}`,
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        height: theme.height.topBar,
        zIndex: theme.zIndex.appBar,
    },
    '@keyframes blinker': {
        '0%': { opacity: 0 },
        '20%': { opacity: 1 },
        '80%': { opacity: 1 },
        '100%': { opacity: 0 },
    },
    blinkerBadge: {
        animationName: '$blinker',
        animationDuration: '2s',
        animationTimingFunction: 'easeInOut',
        animationIterationCount: 'infinite',
    },
    toolbar: {
        minHeight: 'auto',
        width: '100%',
        padding: theme.spacing(0, 2),
        justifyContent: 'flex-end',
    },
    title: {
        marginLeft: theme.spacing(1),
    },
    customerButton: {
        marginLeft: theme.spacing(-0.5),
    },
    localeButton: {
        marginLeft: 'auto',
    },
    singleCustomer: {
        cursor: 'initial',
        '&:hover': {
            backgroundColor: 'inherit',
        },
    },
    popover: {
        zIndex: theme.zIndex.tooltip + 1,
    },
    notificationsButton: {
        marginLeft: theme.spacing(1),
    },
    resourceCenter: {
        marginLeft: theme.spacing(1),
    },
    userDetailsButton: {
        cursor: 'pointer',
        marginRight: theme.spacing(1),
        '&:first-child': {
            marginLeft: 'auto',
        },
    },
    menuButton: {
        '& + $userDetailsButton': {
            marginLeft: 'auto',
        },
    },
    notificationsButtonActive: {
        transitionProperty: 'background-color, color',
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.contrastText,
        '&:hover': {
            color: theme.palette.text.primary,
        },
    },
    signOutButton: {
        marginLeft: theme.spacing(1),
    },
    customerSelect: {
        marginLeft: theme.spacing(1),
        marginRight: 'auto',
    },
    divider: {
        height: theme.height.topBar,
    },
});
