import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    namedParameters,
    singleParameter,
} from '../../common/helpers/ReduxHelpers';

import { DOMAIN } from './ConfigDetails.constants';

export const events = {
    fieldChange: namedParameters('field', 'value', 'oldValue'),
    fieldErrors: singleParameter,
    classificationViewChange: singleParameter,
};

export const asyncEvents = makeAsyncEvents(
    ['fetchDetails', 'create', 'retrieve', 'update', 'delete', 'publish', 'republish'],
    true
);

export const types = makeActionTypes([...Object.keys(events), ...Object.keys(asyncEvents)], DOMAIN);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
    },
    types
);
