import {
    contrastRgb,
    hslToRgb,
    hueFromString,
    intToHexString,
    parseColor,
} from '../../../helpers/Color';
import {
    defaultIdAttribute,
    defaultMergeStrategy,
    defaultProcessStrategy,
} from '../../../helpers/Normalizr';

import { schema } from 'normalizr';

export const entityName = 'subscriptions';
export const idAttribute = 'planId';

export const mergeStrategy = defaultMergeStrategy(idAttribute);
export const processStrategy = defaultProcessStrategy(idAttribute, data => {
    let { [idAttribute]: planId = data.id } = data;
    const rgb = hslToRgb(hueFromString(`${planId}`) / 360, 0.6, 0.9);
    const { color = intToHexString.apply(null, rgb) } = data;

    const isBusinessPlan = 'business' === planId;

    const isBusinessPlusPlan = 'businessplus' === planId;

    const isDeveloperPlan = 'developer' === planId;

    const isEnterprisePlan = 'enterprise' === planId;

    return {
        isBusinessPlan,
        isBusinessPlusPlan,
        isDeveloperPlan,
        isEnterprisePlan,
        textColor: color && contrastRgb.apply(null, parseColor(color)) ? '#ffffff' : '#000000',
        color: color && `#${intToHexString.apply(null, parseColor(color))}`,
        ...data,
    };
});

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
