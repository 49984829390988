import {
    FormControl,
    InputLabel,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select,
} from '@mui/material';
import React, { useCallback, useEffect } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import Locale from '/b2b/common/components/Locale';
import PropTypes from 'prop-types';
import { ROLES } from '/b2b/authentication/constants';
import makeStyles from '@mui/styles/makeStyles';

const roles = Object.entries(ROLES)
    .filter(([id]) => id !== 'ALL')
    .map(([id, name]) => ({
        name,
        id,
    }));

const styles = theme => {
    return {
        formControl: {
            display: 'flex',
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(1),
        },
        popover: {
            zIndex: theme.zIndex.tooltip + 1,
        },
    };
};

const useStyles = makeStyles(styles);

const UserRoleSelect = props => {
    const {
        customerId,
        label = 'common.userRole',
        value,
        options = roles,
        onChange,
        ...remain
    } = props;
    const classes = useStyles(props);
    const isOpen = React.useRef(false);
    const inputLabel = React.useRef(null);
    const [currentValue, setCurrentValue] = React.useState(value);

    const handleChange = useCallback(event => {
        const val = event?.target?.value || event;
        setCurrentValue(val);
    }, []);

    const handleClose = useCallback(() => {
        isOpen.current = false;
        if (currentValue.length) {
            onChange && onChange(currentValue, value);
        } else {
            setCurrentValue(value);
        }
    }, [handleChange, currentValue, value]);
    const handleOpen = useCallback(() => {
        isOpen.current = true;
    }, []);

    const handleRenderValue = useCallback(() => {
        // Selected is not `currentValue`
        return (
            currentValue
                .map(option => {
                    const roleMatch = options.find(({ id }) => id === option);
                    if (!roleMatch) {
                        // eslint-disable-next-line no-console
                        console.error(
                            `You are assigned a role that does not exist on the current customer "${customerId}". Please verify that the "${option
                                .split('-')
                                .slice(0, -1)
                                .join('-')}" role exists on this customer.`
                        );
                        return null;
                    }
                    return roleMatch.name;
                })
                .filter(Boolean)
                .join(', ') || '--'
        );
    }, [customerId, currentValue, options]);

    useEffect(() => {
        setCurrentValue(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="user_role" ref={inputLabel}>
                <Locale path={label} />
            </InputLabel>
            <Select
                variant="outlined"
                label={<Locale path={label} />}
                name="role"
                id="user_role"
                {...remain}
                onOpen={handleOpen}
                onClose={handleClose}
                onChange={handleChange}
                multiple={true}
                value={currentValue}
                renderValue={handleRenderValue}
                MenuProps={{ PopoverClasses: { root: classes.popover } }}
            >
                {options.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                        <ListItemText primary={name} />
                        <ListItemSecondaryAction>
                            {currentValue && currentValue.includes(id) ? <CheckIcon /> : null}
                        </ListItemSecondaryAction>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

UserRoleSelect.propTypes = {
    customerId: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.array,
};

UserRoleSelect.defaultProps = { options: roles };

export default UserRoleSelect;
