import { FormControlLabel, FormHelperText, Radio } from '@mui/material';
import React, { Fragment, isValidElement } from 'react';

import Locale from '/b2b/common/components/Locale';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import styles from './ValidationRadio.styles';

const useStyles = makeStyles(styles);

const ValidationRadio = props => {
    const { checked, name, helperText, label, value, onChange, error, disabled, inverted } = props;
    const classes = useStyles(props);
    const handleCheckboxToggle = event => {
        const newCheckedState = event.target.checked;
        onChange && onChange(newCheckedState ? value : null);
    };
    const labelClass = classNames(classes.label, error ? classes.error : '');

    return (
        <Fragment>
            <FormControlLabel
                className={labelClass}
                key={value}
                label={isValidElement(label) ? label : <Locale path={label} />}
                control={
                    <Fragment>
                        <Radio
                            checked={inverted ? !checked : checked}
                            color="secondary"
                            disabled={disabled}
                            onChange={handleCheckboxToggle}
                            name={name}
                            className={classes.checkbox}
                            value={value}
                        />
                    </Fragment>
                }
            />
            {helperText ? (
                isValidElement(helperText) ? (
                    helperText
                ) : (
                    <FormHelperText className={classes.helperText}>
                        <Locale path={helperText} />
                    </FormHelperText>
                )
            ) : null}
        </Fragment>
    );
};

ValidationRadio.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    inverted: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    required: PropTypes.bool,
    value: PropTypes.string,
};

export default ValidationRadio;
