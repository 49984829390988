import {
    buildActionCreators,
    makeActionTypes,
    namedParameters,
    singleParameter,
} from '/b2b/common/helpers/ReduxHelpers';

import { DOMAIN } from './Cookies.constants';

export const events = {
    add: singleParameter,
    change: namedParameters('index', 'newRegexp', 'oldRegexp'),
    classify: namedParameters('index', 'classification', 'previousClassification', 'storeKey'),
    delete: namedParameters('index', 'storeKey'),
};

export const types = makeActionTypes(Object.keys(events), DOMAIN);

export default buildActionCreators(events, types);
