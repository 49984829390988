import {
    defaultIdAttribute,
    defaultMergeStrategy,
    defaultProcessStrategy,
} from '../../../common/helpers/Normalizr';

import { schema } from 'normalizr';

/**
 * @typedef LegislationEntity
 * @property {number|string} id
 * @property {number|string} [legislationId=id] The ORM ID for the update
 * @property {string} title The title of the legislation update
 * @property {string} author The author of the update
 * @property {string} content The body of the update
 * @property {string} excerpt A brief overview of the update
 * @property {'publish'|'update'} status The status of the post
 * @property {import('./Jurisdiction.schema').JurisdictionEntity[]} jurisdictions The jurisdictions that the legislation update affects
 * @property {string[]} whyShouldCustomersCare A list of reasons why a customer should care
 * @property {string[]} suggestedAction A list of things a customer should do
 */

export const entityName = 'legislation';
export const idAttribute = 'legislationId';

export const mergeStrategy = defaultMergeStrategy(idAttribute);
export const processStrategy = defaultProcessStrategy(idAttribute);

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
