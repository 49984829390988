import {
    defaultIdAttribute,
    defaultMergeStrategy,
    defaultProcessStrategy,
} from '/b2b/common/helpers/Normalizr';

import Vendor from './vendor/IabVendor.schema';
import { schema } from 'normalizr';

export const entityName = 'iabTcfGvl';
export const idAttribute = 'vendorListVersion';

export const mergeStrategy = defaultMergeStrategy(idAttribute);
export const processStrategy = defaultProcessStrategy(idAttribute);

const Iab = new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});

Iab.define({
    vendors: [Vendor],
});

export default Iab;
