export default theme => ({
    '@keyframes fadeIn': {
        from: { opacity: 0 },
        to: { opacity: 1 },
    },
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100vh',
    },
    grid: {
        height: '100%',
    },
    quoteWrapper: {
        animationName: '$fadeIn',
        animationDuration: '600ms',

        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },
    },
    contentWrapper: {
        animationName: '$fadeIn',
        animationDuration: '600ms',
    },
});
