import { FormControl, FormHelperText, InputLabel, Select as MuiSelect } from '@mui/material';
import React, { useMemo, useRef } from 'react';

import PropTypes from 'prop-types';
import castArray from 'lodash/castArray';
import { shouldRetranslate } from '../../state/locale/Locale.selectors';
import { translate } from '../../helpers/i18n';
import useBoundCallback from '../../helpers/Hooks/useBoundCallback';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { withFormikField } from './FormikFields';

/**
 * @typedef {Object} Base
 * @property {Object=} translationOptions The `options` object provided to all internal <Locale/> translations for the TextField
 *
 * @typedef {Base & import('@mui/material').SelectProps & Pick<import('@mui/material').TextFieldProps, 'helperText'>} SelectProps
 */

/**
 * @type {React.ForwardRefExoticComponent<SelectProps>}
 */
export const Select = React.forwardRef((props, ref) => {
    const {
        'aria-describedby': ariaDescribedBy,
        children,
        defaultValue,
        disabled,
        displayEmpty,
        error,
        FormControlProps,
        FormHelperTextProps,
        fullWidth,
        helperText,
        id: providedId,
        label,
        multiple,
        onChange,
        placeholder,
        renderValue,
        required,
        translationOptions,
        value,
        // The following values may be passed by FormikField and we need to filter them out
        options, // eslint-disable-line no-unused-vars, react/prop-types
        ...remain
    } = props;

    const id = useRef(providedId || uuid());
    const errorId = useRef(uuid());
    const updateTranslation = useSelector(shouldRetranslate);
    const labelId = `o-${id.current}-label`;

    const translatedPlaceholder = useMemo(
        () => placeholder && translate(placeholder, translationOptions),
        [placeholder, updateTranslation, translationOptions]
    );

    const translatedLabel = useMemo(
        () =>
            !label || React.isValidElement(label) ? label : translate(label, translationOptions),
        [label, updateTranslation, translationOptions]
    );

    const handleRenderValue = useBoundCallback(
        (children, multiple, translatedPlaceholder, providedRenderValue, selected = []) => {
            if (selected.length === 0 && translatedPlaceholder) {
                // We must make this look like a placeholder with CSS
                return (
                    <span style={{ opacity: 0.42 /* MUI Placeholder Opacity Value */ }}>
                        {translatedPlaceholder}
                    </span>
                );
            }
            if (providedRenderValue) {
                return providedRenderValue(selected);
            }
            const matches = React.Children.toArray(children)
                .map(child => [child.props.value, child.props.children])
                .filter(([value]) => (multiple ? selected.includes(value) : selected === value))
                .map(([, label]) => label);
            return multiple
                ? castArray(matches)
                      .reduce((a, b) => a.concat(b, ', '), [])
                      .slice(0, -1)
                : matches[0];
        },
        [children, multiple, translatedPlaceholder, renderValue]
    );

    const memoValue = useMemo(() => (multiple ? castArray(value || []) : value), [multiple, value]);

    return (
        <FormControl
            fullWidth={!!fullWidth}
            error={error}
            required={!!required}
            {...FormControlProps}
        >
            {label ? (
                <InputLabel
                    disabled={disabled}
                    shrink={label && placeholder ? true : undefined}
                    required={required}
                    id={labelId}
                >
                    {translatedLabel}
                </InputLabel>
            ) : null}
            <MuiSelect
                ref={ref}
                fullWidth
                labelId={labelId}
                id={id.current}
                disabled={disabled}
                displayEmpty={displayEmpty ?? !!placeholder}
                multiple={multiple}
                defaultValue={defaultValue ?? multiple ? [] : undefined}
                renderValue={handleRenderValue}
                value={memoValue}
                {...remain}
                aria-describedby={
                    !helperText // If there is an error, the aria-describedby needs to reference the error message, and that's handled by MUI internally
                        ? ariaDescribedBy || (label && labelId) || undefined
                        : errorId.current
                }
                notched={!!(placeholder && label) || undefined}
                onChange={onChange}
                label={translatedLabel}
            >
                {children}
            </MuiSelect>
            {helperText && (
                <FormHelperText
                    {...FormHelperTextProps}
                    id={errorId.current}
                    sx={{ whiteSpace: 'pre-line', ...FormHelperTextProps?.sx }}
                >
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
});

Select.propTypes = {
    'aria-describedby': PropTypes.string,
    children: PropTypes.node,
    defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    disabled: PropTypes.bool,
    displayEmpty: PropTypes.bool,
    error: PropTypes.bool,
    FormControlProps: PropTypes.object,
    FormHelperTextProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.any,
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    renderValue: PropTypes.func,
    required: PropTypes.bool,
    translationOptions: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
};

export default Select;

export const FormikSelect = withFormikField(Select, { type: 'select' });
FormikSelect.displayName = 'FormikSelect';
