/* eslint-disable camelcase */
import { darken, lighten } from '@mui/material';

export const white = '#FFFFFF';
export const black = '#000000';

export const grey = {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
};

export const pacificblue = '#0288D1';
export const pacificblueLight = lighten(pacificblue, 0.88);
export const storm = '#180D43';
export const stormLight = lighten(storm, 0.4);
export const cobalt = '#2b18c2';
export const indigo = '#7A3FF1';
export const indigo_hover = '#6232C1';
export const indigoLight = '#9565F4';
export const emerald = '#37cd84';
export const emerald_hover = '#2CA46A';
export const paper = '#f4f4f4';
export const thistle = '#d2cfff';
export const periwinkle = '#c4aefa';
export const violet = '#7a3ff1';
export const purple = indigo;

export const deepKoamaru = '#37336C';
export const deepKoamaruLight = lighten(deepKoamaru, 0.4);

export const blue = cobalt;
export const green = emerald;
export const orange = '#F6B047';
export const red = '#F44336';
export const red_hover = '#D32F2F';

export const purpleGradient = 'linear-gradient(78deg, #F7F1FF 0%, #E2D2FF 125.18%)';
export const violetGradient =
    'linear-gradient(258deg, #FAF0F9 -1.33%, #F5EBF5 -1.32%, #F1E0F0 100%)';
export const warningGradient = 'linear-gradient(45deg, #F8E3D3 0%, #FDF6D9 100%)';
export const successGradient = 'linear-gradient(260deg, #E2F5FE 2.24%, #E0F7EB 100%)';
export const infoGradient = 'linear-gradient(260deg, #F5FAFF 2.24%, #E2F5FE 100%)';
export const errorGradient = 'linear-gradient(79deg, #FFF0F0 0%, #FFDBDA 113.77%)';
export const greyGradient = 'linear-gradient(79deg, #F5F5F5 0%, #EEEEE 113.77%)';

export const gradients = {
    purple: purpleGradient,
    violet: violetGradient,
    warning: warningGradient,
    success: successGradient,
    info: infoGradient,
    error: errorGradient,
    grey: greyGradient,
};

export const danger = {
    backgroundColor: darken(white, 0.025),
    contrastHover: lighten(red, 0.95),
    contrastText: white,
    main: red,
    hover: red_hover,
    light: lighten(red, 0.08),
    dark: darken(red, 0.08),
};

export const secondary = {
    contrastText: white,
    contrastHover: lighten(indigo, 0.95),
    main: indigo,
    hover: indigo_hover,
    light: lighten(indigo, 0.08),
    dark: darken(indigo, 0.08),
};

export const secondaryLight = {
    contrastText: white,
    contrastHover: darken(indigoLight, 0.95),
    main: indigoLight,
    hover: lighten(indigoLight, 0.15),
    light: lighten(indigoLight, 0.08),
    dark: darken(indigoLight, 0.08),
};

export const primary = {
    contrastText: white,
    contrastHover: lighten(indigo, 0.95),
    main: indigo,
    hover: indigo_hover,
    light: lighten(indigo, 0.08),
    dark: darken(indigo, 0.08),
};

export const tertiary = {
    contrastText: white,
    contrastHover: lighten(storm, 0.95),
    main: storm,
    light: lighten(storm, 0.08),
    dark: darken(storm, 0.08),
};
