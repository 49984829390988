export default theme => ({
    root: {
        margin: theme.spacing(4),
        position: 'relative',
    },
    info: {
        alignItems: 'center',
        justifyContent: 'stretch',
        display: 'flex',
        margin: theme.spacing(2, 0),
    },
    locationText: {
        marginTop: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
    dateText: {
        color: theme.palette.text.secondary,
    },
    avatar: {
        flex: '0 1 auto',
        marginRight: theme.spacing(2),
        height: theme.spacing(8),
        width: theme.spacing(8),
    },
    title: {
        flex: '1 1 auto',
    },
    progressWrapper: {
        marginTop: theme.spacing(2),
    },
    progressRoot: {
        borderRadius: '3px',
        marginTop: theme.spacing(1),
        overflow: 'hidden',
    },
    progressColorPrimary: {
        backgroundColor: theme.palette.common.neutral,
    },
    uploadButton: {
        marginRight: theme.spacing(2),
    },
    loadingWrapper: {
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,0.9)',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: theme.zIndex.loadingWrapper,
    },
});
