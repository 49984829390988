import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import AuthenticatedPaths from '../../routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { DateUtils } from '@aws-amplify/core';
import { apiName } from '@osano-b2b';
import castArray from 'lodash/castArray';
import escapeRegExp from 'lodash/escapeRegExp';
import identity from 'lodash/identity';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import { translate } from '../helpers/i18n';

export const controller = new AbortController();
export const abortAllRequests = () => {
    controller.abort();
};

export const allSettled = promises => {
    let wrappedPromises = promises.map(p =>
        Promise.resolve(p).then(
            val => ({ state: 'fulfilled', value: val }),
            err => ({ state: 'rejected', reason: err })
        )
    );
    return Promise.all(wrappedPromises);
};

export const addProductAction = {
    action: `${AuthenticatedPaths.PRODUCTS}/add`,
    label: 'Find Products Now',
};
export const addVendorAction = {
    action: `${AuthenticatedPaths.VENDORS}?following=false`,
    label: 'Find Vendors Now',
};
export const followJurisdictionAction = {
    action: AuthenticatedPaths.LEGISLATION,
    label: 'Follow More Regions',
};
export const planExclusion = {
    action: history => {
        window.open('https://www.osano.com/plans', '_blank');
        history.replace('/');
    },
    label: 'View Upgrade Options',
};
export const upgradeAction = {
    action: 'https://www.osano.com/plans',
    label: 'View Upgrade Options',
};
export const goBackAction = { action: -1, label: 'OK' };
export const cancelAction = { action: 0, label: 'Take Me Back' };
export const goToDashboard = { action: null, label: 'OK' };

export const fixTimeDrift = () => {
    return Auth.currentAuthenticatedUser().then(cognitoUser => {
        DateUtils.setClockOffset(cognitoUser.signInUserSession.clockDrift * 1000 * -1);
    });
};

export const handleResponse = response => {
    return response
        ? response.text().then(responseBodyAsText => {
              try {
                  const json = JSON.parse(responseBodyAsText);
                  if (response.ok) {
                      // Response had a success code
                      if ((json?.status || '').toLowerCase() === 'error') {
                          // Response was an error with a status code
                          return Promise.reject(json);
                      }
                      return { json, headers: response.headers };
                  } else {
                      // Message body was readable JSON
                      return Promise.reject(json);
                  }
              } catch (e) {
                  // Response was malformed JSON
                  if (response.ok) {
                      // Response had a success code, treat as a success
                      return { body: responseBodyAsText, headers: response.headers };
                  }
              }
              // Unhandled error
              return responseBodyAsText
                  ? // We had a response body, it's probably a message
                    Promise.reject({ status: 'error', message: responseBodyAsText })
                  : // We had no response body, lets error with the entire response
                    Promise.reject(response);
          })
        : Promise.reject({ code: 504, status: 'error', message: 'common.errors.502' });
};

export const handleError = (error, params) => {
    const errors = {};
    let { request, message = error, name } = error;
    let responseData = error.response?.data || {};
    let serverData;
    const code = responseData.errorCode || error.errorCode || error.code || request?.status;
    let serverMessage = responseData.message || '';
    try {
        serverData = JSON.parse(responseData.message);
        const { message: parsedMessage = responseData.message } = serverData;
        serverMessage = parsedMessage;
    } catch (inlineError) {
        // Leave the message alone
    }
    if (request) {
        switch (request.responseType) {
            case 'text': {
                try {
                    // Check if the error is JSON
                    responseData = JSON.parse(request.responseText);
                } catch (inlineError) {
                    message = request.responseText;
                }
                break;
            }
            case 'json': {
                break;
            }

            default: {
                try {
                    message = request.responseText;
                } catch (inlineError) {
                    // Leave the message alone
                }
            }
        }
    }

    if (code >= 200 && code <= 299) {
        return { response: message, status: 'success', code };
    }

    let roadblocks;
    // Message replacement
    switch (code) {
        case 'FE0001': {
            // 'The requested product has not been followed',
            roadblocks = [addProductAction, goBackAction];
            message = 'monitoring.errors.notFollowingProduct';
            break;
        }
        case 'FE0002': {
            // 'A product for the requested vendor is not being followed',
            roadblocks = [addProductAction, goBackAction];
            message = 'monitoring.errors.cannotViewVendor';
            break;
        }
        case 'FE0003': {
            // 'Jurisdiction is not being followed',
            roadblocks = [followJurisdictionAction, goBackAction];
            message = 'monitoring.errors.notFollowingRegion';
            break;
        }
        case 'FE0004': {
            // 'call not allowed by plan',
            roadblocks = [planExclusion, goToDashboard];
            message = 'common.errors.needHigherSubscriptionTier';
            break;
        }
        case 'FE0005': {
            // 'call would exceed plan limit'
            roadblocks = [upgradeAction, cancelAction, { action: 0 }];
            message = 'common.errors.planLimitReached';
            break;
        }
        case 'FE0006': {
            // 'call not allowed by permissions'
            roadblocks = [cancelAction, { action: 0 }];
            message = 'common.errors.permissionDenied';
            break;
        }
        case 'FE0007': {
            // 'too many results'
            roadblocks = [cancelAction, { action: 0 }];
            message = 'common.errors.tooManyResults';
            break;
        }
        case 'FE0008': {
            // 'User does not have permission'
            roadblocks = [cancelAction, { action: 0 }];
            message = 'common.errors.userRolePermissionDenied';
            break;
        }
        case 'FE0009': {
            // Login disabled on customer account
            window.location = 'https://www.osano.com/accounts/locked';
            break;
        }
        case 'FE0010': {
            // 'The requested vendor has not been followed',
            roadblocks = [addVendorAction, goBackAction];
            message = 'monitoring.errors.notFollowingVendor';
            break;
        }
        case 'FE0011': {
            // 'call not allowed by user'
            roadblocks = [cancelAction, { action: 0 }];
            message = serverMessage || 'common.errors.permissionDenied';
            break;
        }
        case 'FE0012': {
            // 'Thank you, but you are no longer required to complete this assessment'
            roadblocks = [goBackAction];
            message = 'views.Assessment.assignment.error.deletedAssignment';
            break;
        }
        case 0: {
            // Network error (offline), or CORS error, or ERR_CONNECTION_REFUSED
            message = 'common.errors.502';
            break;
        }
        case 504: // For now, treat 504 as a server error...don't let the user know it's a timeout
        case 502: {
            message = 'common.errors.502';
            break;
        }
        case 400: {
            serverMessage
                .split('child ')
                .filter(Boolean)
                .forEach(errorString => {
                    const matches = errorString.match(/"(.+?)" fails because \[(.+?)\]/i);
                    if (matches && matches.length > 2) {
                        for (let index = 1; index < matches.length; index += 2) {
                            const param = matches[index];
                            const allErrors = matches[index + 1]
                                .split(',')
                                .map(error => error.trim());
                            errors[param] = (errors[param] || []).concat(allErrors);
                        }
                    }
                });

            message =
                (serverMessage.indexOf('child ') === -1 && serverMessage) || 'common.errors.400';
            break;
        }
        case 401:
        case 403: {
            roadblocks = [goBackAction];
            message = serverMessage || 'common.errors.403';
            break;
        }
        case 404: {
            const { search, pageSize } = params || {};
            if (search !== undefined && pageSize !== undefined) {
                // Paged results search not found
                return {
                    response: {
                        data: [],
                        paging: { total: 0, pageNumber: 1, pageSize, pages: 1 },
                    },
                    status: 'success',
                    code: 200,
                };
            }
            switch (serverMessage) {
                case 'Unable to find a matching user for this customer':
                    message = 'common.errors.cannotFindMatchingUser';
                    break;
                default:
                    roadblocks = [goBackAction];
                    message = serverMessage || 'common.errors.404';
            }
            break;
        }
        case 409: {
            message = serverMessage || 'common.errors.409';
            switch (serverMessage) {
                case 'Configuration updated after supplied update time':
                    message = 'common.errors.cannotUpdateOutdatedConfig';
                    break;
                case 'Cannot delete customer root organization':
                    message = 'common.errors.cannotDeleteRootOrg';
                    break;
            }
            roadblocks = [cancelAction];
            break;
        }

        default: {
            if (serverData) {
                /**
                 * There are occasions where there is a server error, but we do not want
                 * to prevent the user from continuing their actions. In these cases, we
                 * will return the message to the caller along with the status code and
                 * data that the user is expecting.
                 */
                throw {
                    data: serverData,
                    status: 'error',
                    code: responseData.code || code,
                    name,
                    errors: {},
                    message: serverMessage || translate('common.errors.500'),
                };
            } else {
                message =
                    serverMessage || (typeof message === 'string' ? message : 'common.errors.500');
            }
            break;
        }
    }

    const translatedMessage = translate(message);

    throw {
        ...responseData,
        status: 'error',
        code,
        name,
        errors,
        message:
            roadblocks !== undefined
                ? {
                      roadblocks,
                      message: translatedMessage,
                  }
                : translatedMessage,
    };
};

export const handleErrorNoRoadblock = error => {
    try {
        return handleError(error);
    } catch (thrownObject) {
        const message = thrownObject.message.message || thrownObject.message;

        throw { ...thrownObject, message };
    }
};

export const fetchJSON = (url, opts) => {
    const options = {
        method: 'GET',
        mode: 'cors',
        redirect: 'follow',
        referrer: 'no-referrer',
        ...opts,
    };
    return fetch(url, options)
        .catch(handleError)
        .then(handleResponse)
        .then(({ json }) => {
            return json;
        });
};

export const formatPaging = ({
    total: count,
    nextPage,
    pageNumber = 1,
    pageSize: limit = 25,
} = {}) => ({
    count,
    hasNextPage: !!nextPage || (pageNumber < count / limit && count % limit > 0),
    limit,
    nextPage,
    offset: (pageNumber - 1) * limit,
    pageNumber,
    page: pageNumber,
    pageSize: limit,
});

/**
 * @template T
 * @typedef FormattedResponse
 * @type {{ results: Array<T> } & ReturnType<formatPaging>}
 */

/**
 * @template T
 * @typedef EntityFormatter
 * @type {(result: (any|T), index: number, allResults: (any|T)[]) => T}
 */

/**
 *  @template R
 *  @export
 *  @typedef ResultsResponse
 *  @property {R[]} [data = []] Response data
 *  @property {PagedParams} paging The paging data
 *  @property {R[]} [results]
 */

/**
 * @template T
 * @param {EntityFormatter<T>} entityFormatter
 * @returns {(response: ResultsResponse<T>) => FormattedResponse<T>}
 */
export const makeResultsFormatter =
    (entityFormatter = identity) =>
    response => {
        const { data = [], paging, results = data, ...remain } = response || {};
        const formattedResults =
            entityFormatter && entityFormatter !== identity
                ? results.map(entityFormatter)
                : results;
        const filtered = formattedResults.filter(identity);
        const formattedPaging = response?.data ? formatPaging(paging) : paging;

        return {
            ...remain,
            ...formattedPaging,
            results: filtered,
        };
    };

/**
 *  @typedef {Object} PagedParams
 *  @property {number} [pageSize]
 *  @property {number} [limit]
 *  @property {number} [page]
 *  @property {number} [offset]
 *  @property {string} [query]
 *  @property {string} [sort]
 *  @property {['asc', 'desc', 'none', null]} [sortDirection],
 */

/**
 * Formats the provided paging params into a format expected by the backend
 *
 * @param {PagedParams?} params
 * @returns {{
 *  pageNumber: number,
 *  pageSize: number,
 *  search: string,
 *  sortField: string,
 *  sortDir: string,
 * }}
 */
export const formatPagingParams = (params = {}) => {
    const {
        pageSize = 25,
        limit = pageSize,
        page = 1,
        offset = (page - 1) * limit,
        query,
        sort,
        sortDirection,
    } = params;
    const queryStringParameters = {};

    // Query should only be a string or an array of strings
    if (isString(query) || (isArray(query) && query.every(isString))) {
        // eslint-disable-next-line scanjs-rules/assign_to_search
        queryStringParameters.search = query.toString();
    }
    if (offset && limit) {
        queryStringParameters.pageNumber = ~~(offset / limit) + 1;
    }
    if (limit) {
        queryStringParameters.pageSize = limit;
    }
    if (sort) {
        queryStringParameters.sortField = sort;
        if (sortDirection) {
            queryStringParameters.sortDir = sortDirection;
        } else {
            queryStringParameters.sortDir = 'asc';
        }
    }
    return queryStringParameters;
};

/**
 * Extracts the provided params.filters object into querystring object
 *
 * @template {Record<string,*>} P
 * @param {{ filters: P }} params
 * @returns {{ [K in keyof P]: string }} The stringified filters to add to the querystring
 */
export const formatFilterParams = (params = {}) => {
    const { filters } = params;
    const queryStringParameters = {};

    Object.entries(filters || {}).forEach(([key, value]) => {
        // We need to allow for specific null filters
        if (value === null || !isNil(value)) {
            queryStringParameters[key] = castArray(value)
                .filter(val => val === null || !isNil(val))
                .map(val => `${val}`)
                .join(',');
        }
    });
    return queryStringParameters;
};

/**
 * Helper function wrapping boilerplate GET requests for paginated data, formatting it, and handling errors.
 *
 * @template T
 * @param {string} uri - the URI for the request
 * @param {PagedParams} [params] - optional query string parametes
 * @param {EntityFormatter<T>} [formatter] - optional function to map over each entity
 * @returns {Promise<FormattedResponse<T>>} a Promise that resolves to error data or the formatted response data
 */
export const getPaginatedResults = (uri, params, formatter) => {
    const options = params && {
        queryStringParameters: {
            ...formatPagingParams(params),
        },
    };

    return API.get(apiName, uri, options).catch(handleError).then(makeResultsFormatter(formatter));
};

/**
 * Helper function that allows client side filter/sort/paging of a static results set.
 *
 * @template {Record<string,any>} T
 *
 * @param {PagedParams} [params={}] Request parameters
 * @param {{ data?: Array<T>, results?: Array<T> }} response Array of results
 * @param {string} sortKey The key to sort results on
 * @param {string[]} queryKeys The keys to search results on
 * @returns {ReturnType<makeResultsFormatter>}
 */
export const clientSideResultsFilter = (params, response, sortKey, queryKeys) => {
    let {
        pageSize: providedPageSize,
        limit = providedPageSize,
        page: providedPage,
        offset = (providedPage - 1) * limit,
        query,
        sort,
        sortDir,
    } = params || {};
    const { data = [], results = data } = response;
    let filteredResults = results;
    if (query) {
        filteredResults = filteredResults.filter(result =>
            queryKeys.some(key =>
                // eslint-disable-next-line security/detect-non-literal-regexp
                new RegExp(`${escapeRegExp(query)}`).test(result[`${key}`])
            )
        );
    }
    if (sort) {
        filteredResults.sort(({ [`${sortKey}`]: a }, { [`${sortKey}`]: b }) =>
            `${a}`.localeCompare(`${b}`)
        );
        if (sortDir.toLowerCase() === 'desc') {
            filteredResults.reverse();
        }
    }
    if (!Number.isNaN(offset) || !Number.isNaN(limit)) {
        offset = Number.isNaN(offset) ? 0 : offset;
        limit = Number.isNaN(offset + limit) ? filteredResults.length : offset + limit;
        filteredResults = filteredResults.slice(offset, limit);
    }
    return makeResultsFormatter({
        paging: {
            total: filteredResults.length,
            nextPage:
                !Number.isNaN(limit) &&
                filteredResults[filteredResults.length - 1] === results[results.length - 1],
            pages: Math.floor((filteredResults.length - 1) / limit) + 1,
            pageNumber: Math.ceil(offset + 1 / limit),
            pageSize: limit,
        },
        data: filteredResults,
    });
};

export const assignField = (field, value) => details => ({
    ...details,
    [`${field}`]: value,
});

export const assignId = id => details => ({
    ...details,
    id,
});

export const assignResults = assigner => makeResultsFormatter(assigner);

export const formatDate = value => {
    let date;
    let newDate = new Date();
    let epoch = parseInt(value, 10);
    if (epoch < 10000000000) {
        epoch *= 1000;
    }
    newDate.setTime(epoch).toLocaleString();

    if (!isNaN(newDate)) {
        date =
            newDate.getFullYear() +
            '-' +
            ('0' + (newDate.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + newDate.getDate()).slice(-2) +
            ' ' +
            newDate.getHours() +
            ':' +
            ('0' + newDate.getMinutes()).slice(-2);
    }

    return date;
};

export const promisifiedFileReader = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.addEventListener('error', () =>
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ status: 'error', message: 'common.errors.fileRead' })
        );
        reader.readAsArrayBuffer(file);
    });
