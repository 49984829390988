export const formatDashboard = dashboardData => {
    const { todos = {} } = dashboardData;
    const formattedTodos = Object.entries(todos).reduce((acc, [product, productTodos]) => {
        const formattedValue = Object.entries(productTodos).reduce(
            (acc, [todo, todoCount]) => ({
                ...acc,
                [todo]: todoCount || 0,
            }),
            {}
        );
        return {
            ...acc,
            [product]: formattedValue,
        };
    }, {});
    return {
        ...dashboardData,
        todos: formattedTodos,
    };
};
