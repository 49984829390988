export default theme => ({
    root: {
        position: 'relative',
    },
    field: {
        margin: theme.spacing(3),
    },
    form: {
        margin: 'auto',
        padding: 0,
    },
    textField: {
        width: '420px',
        maxWidth: '100%',
        marginRight: theme.spacing(3),
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    progressWrapper: {
        marginTop: theme.spacing(2),
    },
    progressRoot: {
        borderRadius: '3px',
        marginTop: theme.spacing(1),
        overflow: 'hidden',
    },
    progressColorPrimary: {
        backgroundColor: theme.palette.common.neutral,
    },
    loadingWrapper: {
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,0.9)',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: theme.zIndex.loadingWrapper,
    },
});
