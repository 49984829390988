import { fixTimeDrift, formatPagingParams, handleError } from './helpers';
import { formatCustomer, formatCustomerResults } from './format';

import API from '@aws-amplify/api';
import { apiName } from '@osano-b2b';
import { formatOrganizationResults } from '../../organizations/format';

export const BROWSE_CUSTOMER = {
    id: 'browse',
    name: 'Just Browsing',
    organizations: [],
    permissions: {},
    subscription: {},
    role: 'browse',
    roles: [],
    shortCode: 'BROWSE',
    beaconId: process.env.HELPSCOUT_BEACON_ID || 'd2d7843d-6a2a-43e3-a838-16562e0ce4c0',
    canChat: false,
    canEmail: false,
    token: false,
};

export const fetchCustomers = (params = {}) => {
    const options = {
        queryStringParameters: formatPagingParams(params),
    };

    return fixTimeDrift()
        .then(() => API.get(apiName, `/user/customers`, options))
        .catch(handleError)
        .then(formatCustomerResults);
};

export const fetchCustomerDetails = () => {
    const options = {};
    return API.get(apiName, `/customer`, options).catch(handleError).then(formatCustomer);
};

export const updateCustomerDetails = params => {
    const options = {
        body: params,
    };
    return API.patch(apiName, `/customer`, options).catch(handleError).then(formatCustomer);
};

export const fetchUserOrganizations = params => {
    const { userId } = params;
    const options = {
        queryStringParameters: formatPagingParams(params),
    };
    return API.get(apiName, `/customer/user-organizations/${userId}`, options)
        .catch(handleError)
        .then(({ organizations }) => formatOrganizationResults({ results: organizations }));
};

export const upgradeCustomerPlan = params => {
    const options = {
        body: params,
        responseType: 'text',
    };
    return API.post(apiName, `/customer/plan-upgrade-request`, options)
        .then(response => {
            try {
                // Check if the response is JSON
                return JSON.parse(response);
            } catch (error) {
                return { status: 'success', code: 200, response };
            }
        })
        .catch(handleError);
};
