import {
    buildActionCreators,
    makeActionTypes,
    namedParameters,
    singleParameter,
} from '/b2b/common/helpers/ReduxHelpers';

import { DOMAIN } from './Disclosures.constants';

export const events = {
    add: singleParameter,
    fieldChange: namedParameters('index', 'field', 'value', 'oldValue'),
    delete: namedParameters('index'),
};

export const types = makeActionTypes(Object.keys(events), DOMAIN);

export default buildActionCreators(events, types);
