import AuthenticatedPaths from '../routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';

export const TRIGGERS = {
    // Route roadblocks
    ASSESSMENTS: {
        id: '1666367868fGfq2342',
        action: 'view',
        on: AuthenticatedPaths.ASSESSMENTS,
        type: 'route',
    },
    ANALYSIS_REQUEST: {
        id: '1658781683fJqv893',
        action: 'view',
        on: AuthenticatedPaths.ANALYSIS_REQUEST,
        type: 'route',
    },
    ASK_ANALYST: {
        id: '1580760027iHbr6924',
        action: 'view',
        on: AuthenticatedPaths.ASK_ANALYST,
        type: 'route',
    },
    DATA_MAPPING_SOURCES: {
        id: '1689643526xPgb7357',
        action: 'view',
        on: AuthenticatedPaths.DATA_MAPPING_SOURCES,
        type: 'route',
    },
    DATA_MAPPING_APPLICATIONS: {
        id: '1689643526xPgb7357',
        action: 'view',
        on: AuthenticatedPaths.DATA_MAPPING_APPLICATIONS,
        type: 'route',
    },
    DATA_MAP: {
        id: '1689643526xPgb7357',
        action: 'view',
        on: AuthenticatedPaths.DATA_MAP,
        type: 'route',
    },
    DATA_STORES: {
        id: '1689643526xPgb7357',
        action: 'view',
        on: AuthenticatedPaths.DATA_STORES,
        type: 'route',
    },
    DSAR_ACTION_ITEMS: {
        id: '1580760478tSej355',
        action: 'view',
        on: AuthenticatedPaths.DSAR_ACTION_ITEMS,
        type: 'route',
    },
    DSAR_FORMS: {
        id: '1580760478tSej355',
        action: 'view',
        on: AuthenticatedPaths.DSAR_FORMS,
        type: 'route',
    },
    DSAR_REQUESTS: {
        id: '1580760478tSej355',
        action: 'view',
        on: AuthenticatedPaths.DSAR_REQUESTS,
        type: 'route',
    },
    GDPR: {
        id: '1580760481pDeh9565',
        action: 'view',
        on: AuthenticatedPaths.GDPR,
        type: 'route',
    },
    DOCUMENT_CHANGES: {
        id: '1580760484jZqv8509',
        action: 'view',
        on: AuthenticatedPaths.DOCUMENT_CHANGES,
        type: 'route',
    },
    LEGISLATION: {
        id: '1580760488jZlm3505',
        action: 'view',
        on: AuthenticatedPaths.LEGISLATION,
        type: 'route',
    },
    LITIGATION: {
        id: '1580760491nVbl8626',
        action: 'view',
        on: AuthenticatedPaths.LITIGATION,
        type: 'route',
    },
    REPORTS: {
        id: '1691589347qBvg6262',
        action: 'view',
        on: AuthenticatedPaths.REPORTS,
        type: 'route',
    },
    REPORTS_CONSENT: {
        id: '1692309764sSbz2807',
        action: 'view',
        on: AuthenticatedPaths.REPORTS_CONSENT,
        type: 'route',
    },
    REPORTS_DATASUBJECT: {
        id: '1692309912vFcv4516',
        action: 'view',
        on: AuthenticatedPaths.REPORTS_DATASUBJECT,
        type: 'route',
    },
    REPORTS_VENDOR: {
        id: '1692310066iBrk7996',
        action: 'view',
        on: AuthenticatedPaths.REPORTS_VENDOR,
        type: 'route',
    },
    RESOURCES: {
        id: '1664210309pDcx3858',
        action: 'view',
        on: AuthenticatedPaths.RESOURCES,
        type: 'route',
    },

    // Feature blocks
    CCPA_OPT_OUT: {
        id: '1580760334tTnv9867',
        action: 'write',
        on: 'ccpa_opt_out',
        type: 'feature',
    },
    CMP_TRANSLATIONS: {
        id: '1627072325bUxo0133',
        action: 'write',
        on: 'cmp_translations',
        type: 'feature',
    },
    IAB_EU_TCF2: {
        id: '1596061781vPli7343',
        action: 'write',
        on: 'iab_eu_tcf_2',
        type: 'feature',
    },
    CONSENT_MANAGE_PREFERENCES: {
        id: '1610405875zKoe5703',
        action: 'write',
        on: 'consent_manage_prefs',
        type: 'feature',
    },
    CONSENT_MONITORING: {
        id: '1580767067wDzy5902',
        action: 'view',
        on: AuthenticatedPaths.CONSENT_MONITOR,
        route: `${AuthenticatedPaths.CONSENT_MANAGER}/:ccId/consents`,
        redirect: `${AuthenticatedPaths.CONSENT_MANAGER}/:ccId`,
        type: 'route',
    },
    CONSENT_SCHEDULED_URL_SCANS: {
        id: '1588108144mFeq4321',
        action: 'write',
        on: 'scan_config_urls',
        type: 'feature',
    },
    CONSENT_SCHEDULED_SITEMAP_SCANS: {
        id: '1589835075xWgj0734',
        action: 'write',
        on: 'sitemap_config_urls',
        type: 'feature',
    },
    CROSS_DOMAIN: {
        id: '1580760331iTry2126',
        action: 'write',
        on: 'consent_cross_domain',
        type: 'feature',
    },
    VENDOR_LITIGATION: {
        id: '1580760491nVbl8626',
        action: 'view',
        on: AuthenticatedPaths.LITIGATION,
        route: `${AuthenticatedPaths.VENDORS}/:vendorId/lawsuits`,
        redirect: `${AuthenticatedPaths.VENDORS}/:vendorId`,
        type: 'route',
    },
    VENDOR_MANAGEMENT: {
        id: '1681759501tJvb1313',
        action: 'read',
        on: 'vendor_management',
        type: 'feature',
    },
    VENDOR_UPLOADS: {
        id: '1622589533vWml8617',
        action: 'view',
        on: AuthenticatedPaths.UPLOADS,
        route: `${AuthenticatedPaths.VENDORS}/:vendorId/uploads`,
        redirect: `${AuthenticatedPaths.VENDORS}/:vendorId`,
        type: 'route',
    },
    PRODUCT_UPLOADS: {
        id: '1622589533vWml8617',
        action: 'view',
        on: AuthenticatedPaths.UPLOADS,
        route: `${AuthenticatedPaths.PRODUCTS}/:productId/uploads`,
        redirect: `${AuthenticatedPaths.PRODUCTS}/:productId`,
        type: 'route',
    },
    CM_VERSION_ROLLBACK_ROUTE: {
        id: '1594416612hXob6843',
        action: 'view',
        on: AuthenticatedPaths.CONSENT_MANAGER,
        route: `${AuthenticatedPaths.CONSENT_MANAGER}/:ccId/versions`,
        redirect: `${AuthenticatedPaths.CONSENT_MANAGER}/:ccId`,
        type: 'route',
    },
    CM_VERSION_ROLLBACK: {
        id: '1594416612hXob6843',
        action: 'write',
        on: 'consentConfigRollbacks',
        type: 'feature',
    },
    ORGANIZATIONS: {
        id: '1595600681dRtn7694',
        action: 'view',
        on: AuthenticatedPaths.ORGANIZATIONS,
        route: AuthenticatedPaths.ORGANIZATIONS,
        type: 'route',
    },
    CODE_SPLITTING: {
        id: '1677516534mNad0707',
        action: 'write',
        on: 'cmp_code_splitting',
        type: 'feature',
    },

    // Limits
    MAX_ASSESSMENTS: {
        id: '1580754229vNjv0898',
        route: AuthenticatedPaths.ASSESSMENTS,
        type: 'limit',
    },
    MAX_CONSENT_CONFIGS: {
        id: '1580754229vNjv0898',
        route: AuthenticatedPaths.CONSENT_MANAGER,
        type: 'limit',
    },
    MAX_CONSENT_VIEWS: {
        id: '1580760327yNiu8888',
        route: AuthenticatedPaths.CONSENT_MANAGER,
        type: 'limit',
    },
    MAX_CONSENT_SCHEDULED_URL_SCANS: {
        id: '1588108621lEkt6957',
        route: `${AuthenticatedPaths.CONSENT_MANAGER}/:ccId/url-scans`,
        type: 'limit',
    },
    MAX_CONSENT_SCHEDULED_SITEMAP_SCANS: {
        id: '1589835069bNqp997',
        route: `${AuthenticatedPaths.CONSENT_MANAGER}/:ccId`,
        type: 'limit',
    },
    MAX_DATA_STORES: {
        id: '1625626507xEhj2484',
        route: AuthenticatedPaths.DATA_STORES,
        type: 'limit',
    },
    MAX_PRODUCTS: {
        id: '1580760020aPpp2983',
        route: AuthenticatedPaths.PRODUCTS,
        type: 'limit',
    },
    MAX_ORGANIZATIONS: {
        id: null,
        route: AuthenticatedPaths.ORGANIZATIONS,
        type: 'limit',
    },
    MAX_USERS: {
        id: '1580760024lEfo1653',
        route: AuthenticatedPaths.USERS,
        type: 'limit',
    },
    MAX_VENDORS: {
        id: '1580760020aPpp2983',
        route: AuthenticatedPaths.VENDORS,
        type: 'limit',
    },
    MAX_DSAR_FORMS: {
        id: '1583856341nZio9602',
        route: AuthenticatedPaths.DSAR_FORMS,
        type: 'limit',
    },
};
