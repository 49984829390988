export default {
    root: {
        alignSelf: 'flex-start',
        '&:hover .MuiSwitch-switchBase': {
            backgroundColor: 'rgba(119, 100, 250, 0.04)',
        },
    },
    labelPlacementStart: {
        marginLeft: 0,
        marginRight: -11,
    },
};
