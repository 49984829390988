import { buildActionCreators, makeActionTypes, singleParameter } from '../../helpers/ReduxHelpers';

import { DOMAIN } from './Userpilot.constants';

export const events = {
    setUserpilotId: singleParameter,
    setUserpilotContentId: singleParameter,
};

export const types = makeActionTypes([...Object.keys(events)], DOMAIN);

export default buildActionCreators(
    {
        ...events,
    },
    types
);
