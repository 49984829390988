import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    singleParameter,
} from '../../../common/helpers/ReduxHelpers';

import { DOMAIN } from './AuthUser.constants';

export const events = {
    setFeatureFlags: singleParameter,
};

export const asyncEvents = makeAsyncEvents(['fetchDetails', 'update'], true);

export const types = makeActionTypes([...Object.keys(events), ...Object.keys(asyncEvents)], DOMAIN);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
    },
    types
);
