export default theme => ({
    root: {
        width: '350px',
        maxWidth: '100%',
        zIndex: '99999',
    },
    header: {
        backgroundColor: theme.palette.background.default,
        backgroundPositionX: 'right',
        backgroundPositionY: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },
    subtitle: {
        color: theme.palette.text.secondary,
    },
    content: {},
    listItem: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.background.default,
        },
    },
    listItemIcon: {},
    listItemTextSecondary: {
        marignTop: theme.spacing(0.5),
        color: theme.palette.text.dark,
    },
    arrowForward: {
        color: theme.palette.text.secondary,
        height: theme.spacing(2),
        width: theme.spacing(2),
    },
    footer: {
        paddingBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
    },
    viewAllButton: {
        marginRight: '1em',
    },
    notificationButtons: {
        paddingBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
    },
    empty: {
        textAlign: 'center',
        padding: theme.spacing(3),
    },
    emptyImageWrapper: {
        marginBottom: theme.spacing(3),
    },
    emptyImage: {
        width: '240px',
        maxWidth: '100%',
        height: 'auto',
    },
    urgentItem: {
        color: theme.palette.text.urgent,
    },
});
