export default theme => ({
    root: {
        position: 'relative',
    },
    textField: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    form: {
        margin: 'auto',
        padding: 0,
    },
    progress: {
        display: 'block',
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    fieldError: {
        color: theme.palette.error.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    submitError: {
        color: theme.palette.error.main,
        alignText: 'center',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    validate: {
        padding: 0,
        display: 'block',
        marginBottom: theme.spacing(1),
    },
    validateLabel: {
        lineHeight: 1.5,
        marginLeft: theme.spacing(1),
        verticalAlign: 'middle',
    },
    validIcon: {
        color: theme.palette.success.main,
        verticalAlign: 'middle',
    },
    invalidIcon: {
        color: theme.palette.error.main,
        verticalAlign: 'middle',
    },
    loadingWrapper: {
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,0.9)',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: theme.zIndex.loadingWrapper,
    },
});
