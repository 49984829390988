import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
} from '/b2b/common/helpers/ReduxHelpers';

import { DOMAIN } from './RequestConversation.constants';

export const asyncEvents = makeAsyncEvents(['fetchConversation', 'sendMessage'], true);

export const types = makeActionTypes(Object.keys(asyncEvents), DOMAIN);

export default buildActionCreators(asyncEvents, types);
