import { fetchCustomers, selectCustomer, signOut } from './CustomerSelect.commands';

import Component from './CustomerSelect.component';
import { connect } from 'react-redux';
import fromState from '../../common/state/selectors';

const mapStateToProps = state => {
    const userId = fromState.Auth.user.getId(state);
    const customerIds = fromState.Auth.user.customers.getResults(state);
    return {
        userId,
        isLoading: !userId || fromState.Auth.user.customers.isLoading(state),
        customerIds,
        customers: fromState.DB.customer.getEntitiesForIds(state, customerIds),
        customerCount: fromState.Auth.user.customers.getResultCount(state),
        error: fromState.Auth.user.customers.getError(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchCustomers: () => dispatch(fetchCustomers()),
        selectCustomer: customer => dispatch(selectCustomer(customer)),
        signOut: () => dispatch(signOut()),
    };
};

const mergeProps = (stateProps, dispatchProps) => {
    return {
        ...stateProps,
        onClose: dispatchProps.signOut,
        onInitialLoad: stateProps.userId ? dispatchProps.fetchCustomers : () => {},
        onSelectCustomer: dispatchProps.selectCustomer,
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
