import { types as ActionTypes } from './Cookies.actions';
import { genericReducer } from '/b2b/common/helpers/ReduxHelpers';

export const initialState = [];

export default genericReducer(initialState, {
    [ActionTypes.add]: (state, item) => {
        return [
            { storeKey: '', classification: '', provider: '', purpose: '', expiry: '', ...item },
            ...state,
        ];
    },
    [ActionTypes.change]: (state, { index, newRegexp: storeKey, oldRegexp }) => {
        if ((!index && index !== 0) || !state[index] || storeKey === oldRegexp) {
            return state;
        }
        return [...state.slice(0, index), { ...state[index], storeKey }, ...state.slice(index + 1)];
    },
    [ActionTypes.classify]: (state, { index, classification }) => {
        if ((!index && index !== 0) || !state[index]) {
            return state;
        }
        return [
            ...state.slice(0, index),
            { ...state[index], classification },
            ...state.slice(index + 1),
        ];
    },
    [ActionTypes.delete]: (state, { index }) => {
        if ((!index && index !== 0) || !state[index]) {
            return state;
        }
        return [...state.slice(0, index), ...state.slice(index + 1)];
    },
});
