import React, { useCallback, useRef, useState } from 'react';
import { failureMessages, validatePassword } from '/b2b/common/helpers/Password';

import { CircularProgress } from '@mui/material';
import ConfirmSessionLogoutDialog from './components/ConfirmSessionLogoutDialog';
import InvalidIcon from '@mui/icons-material/Close';
import Locale from '/b2b/common/components/Locale';
import Portlet from '/b2b/common/components/Portlet';
import PortletContent from '/b2b/common/components/PortletContent';
import PortletFooter from '/b2b/common/components/PortletFooter';
import PropTypes from 'prop-types';
import SubmitButton from '/b2b/common/components/SubmitButton';
import ValidIcon from '@mui/icons-material/Check';
import ValidationForm from '/b2b/common/components/ValidationForm';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import schema from './Password.schema';
import styles from './Password.styles';

const useStyles = makeStyles(styles);

const Password = props => {
    const { className, isLoading, onUpdateUserPassword } = props;
    const classes = useStyles(props);
    const [passwordChecks, updatePasswordChecks] = useState(validatePassword('', true));
    const handleSubmit = useRef(onUpdateUserPassword);
    const [isValid, setIsValid] = useState(false);
    const [canSubmit, setCanSubmit] = useState(isValid);
    const handleFieldChange = (field, value) => {
        if (field === 'newPassword') {
            updatePasswordChecks(validatePassword(value, true));
        }
    };
    const rootClassName = classNames(classes.root, className);

    const [confirmSessionLogoutOpen, setConfirmSessionLogoutOpen] = useState(false);

    const handleUpdatePasswordSubmit = () => {
        isValid && canSubmit && setConfirmSessionLogoutOpen(true);
    };

    const handleCloseSessionLogoutDialog = () => {
        setConfirmSessionLogoutOpen(false);
        handleSubmit.current && handleSubmit.current({ revoke: false });
    };

    const handleConfirmSessionLogoutDialog = () => {
        setConfirmSessionLogoutOpen(false);
        handleSubmit.current && handleSubmit.current({ revoke: true });
    };

    const handleValidate = useCallback(validation => {
        setCanSubmit(validation === undefined);
        setIsValid(!validation);
    }, []);

    return (
        <Portlet className={rootClassName}>
            <PortletContent>
                <ValidationForm
                    className={classes.form}
                    subtitle={'password.update'}
                    title={'password.title'}
                    submitButton={({ onClick }) => {
                        handleSubmit.current = onClick;
                        return null;
                    }}
                    schema={props.schema}
                    onSubmit={onUpdateUserPassword}
                    onValidate={handleValidate}
                    onFieldChange={handleFieldChange}
                    helperText={{
                        newPassword: props.failureMessages.map(([key, message]) => (
                            <span className={classes.validate} key={key}>
                                {passwordChecks[key] ? (
                                    <ValidIcon className={classes.validIcon} />
                                ) : (
                                    <InvalidIcon className={classes.invalidIcon} />
                                )}
                                <span className={classes.validateLabel}>
                                    <Locale path={message} />
                                </span>
                            </span>
                        )),
                    }}
                />
            </PortletContent>
            <PortletFooter className={classes.portletFooter}>
                <SubmitButton
                    color="primary"
                    variant="contained"
                    disabled={!isValid || !canSubmit}
                    onClick={handleUpdatePasswordSubmit}
                    loading={isLoading}
                >
                    <Locale path="common.update" />
                </SubmitButton>
            </PortletFooter>
            <ConfirmSessionLogoutDialog
                onCancel={handleCloseSessionLogoutDialog}
                onConfirm={handleConfirmSessionLogoutDialog}
                open={confirmSessionLogoutOpen}
            />
            {isLoading && (
                <div className={classes.loadingWrapper}>
                    <CircularProgress />
                </div>
            )}
        </Portlet>
    );
};

Password.propTypes = {
    className: PropTypes.string,
    failureMessages: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    onUpdateUserPassword: PropTypes.func.isRequired,
    schema: PropTypes.object.isRequired,
};

Password.defaultProps = { schema, failureMessages };

export default Password;
