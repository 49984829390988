import { AppBar, Tabs as MuiTabs } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import styles from './Tabs.styles';
import { translate } from '../../helpers/i18n';

const useStyles = makeStyles(styles);

const Tabs = props => {
    const { ariaLabel, className, color, children, elevation, orientation, ...remain } = props;
    const classes = useStyles(props);
    return (
        <AppBar
            position="static"
            classes={{ root: classes.appBar }}
            className={
                orientation === 'vertical' ? classes.appBarVertical : classes.appBarHorizontal
            }
            color={color}
            elevation={elevation}
        >
            <MuiTabs
                {...remain}
                aria-label={translate(ariaLabel)}
                orientation={orientation}
                className={className}
                classes={{
                    root: classes.tabs,
                    vertical: classes.verticalTabs,
                    indicator: classes['MuiTabs-indicator'],
                    flexContainerVertical: classes['MuiTabs-flexContainerVertical'],
                }}
            >
                {children}
            </MuiTabs>
        </AppBar>
    );
};

Tabs.propTypes = {
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    color: PropTypes.string,
    elevation: PropTypes.number,
    name: PropTypes.string,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    tab: PropTypes.string,
    textColor: PropTypes.string,
    initialTab: PropTypes.string,
    onChange: PropTypes.func,
};

Tabs.defaultProps = {
    color: 'tertiary',
    textColor: 'inherit',
};

export default Tabs;
