import Component from './AccountDetails.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';
import { updateUserDetails } from './AccountDetails.commands';

const mapStateToProps = state => {
    const userId = fromState.Auth.user.getId(state);
    const details = fromState.DB.user.getEntityById(state, userId);
    const { firstName, lastName, email } = details;
    return {
        isLoading: fromState.Auth.user.isLoading(state),
        userId,
        phone: fromState.Auth.user.getPhone(state),
        firstName,
        lastName,
        email,
        roles: fromState.Auth.user.getRolesString(state),
    };
};

const mapDispatchToProps = {
    updateUserDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
