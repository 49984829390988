export const CHART_TYPE = 'treemap';

export const OPTIONS = {
    plugins: {
        legend: {
            display: false,
        },
        datalabels: false,
        tooltip: {
            position: 'nearest',
            padding: 10,
            bodySpacing: 5,
            callbacks: {
                title: items => items[items.length - 1]?.raw?.g,
            },
        },
    },
};

export const UNCLASSIFIED_PATH = 'dataMapping.classificationTypes.none.name';

export const PROVIDER = 'provider';
export const CONNECTION = 'connection';
export const CLASSIFICATION = 'classification';

export const GROUPS_STATE = {
    [PROVIDER]: {
        checked: true,
        color: 'data1',
        label: 'layouts.Dashboard.components.FieldClassificationStats.provider',
    },
    [CONNECTION]: {
        checked: true,
        color: 'data2',
        label: 'layouts.Dashboard.components.FieldClassificationStats.connection',
    },
    [CLASSIFICATION]: {
        checked: true,
        color: 'data3',
        label: 'layouts.Dashboard.components.FieldClassificationStats.classification',
    },
};
