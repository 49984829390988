const createCustomMutationObserver = (
    elementToObserve,
    callback,
    observerOptions = { childList: true, subtree: true }
) => {
    const observer = new MutationObserver(callback);
    observer.observe(elementToObserve, observerOptions);
    return observer;
};

export const createArrayOfCustomMutationObservers = (
    elementsArray,
    callbackForEachObserver,
    callbackBeforeObserver = null
) =>
    elementsArray.map(currElement => {
        if (callbackBeforeObserver) {
            callbackBeforeObserver();
        }
        return createCustomMutationObserver(currElement, callbackForEachObserver);
    });

export default createCustomMutationObserver;
