import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import ResponsiveDialog from '/b2b/common/components/ResponsiveDialog';
import makeStyles from '@mui/styles/makeStyles';
import styles from './ConfirmSessionLogoutDialog.styles';

const useStyles = makeStyles(styles);

const ConfirmSessionLogoutDialog = props => {
    const { onCancel, open, onConfirm } = props;
    const classes = useStyles(props);
    return (
        <ResponsiveDialog
            title="Logout Current Sessions?"
            open={open}
            onClose={onCancel}
            onCancel={onCancel}
            onConfirm={onConfirm}
            cancelText="No"
            confimText="Yes"
        >
            <Box marginBottom={2} className={classes.warning}>
                Changing your password will not sign out of any existing sessions. Any browser that
                is currently logged into this account will still have access until they sign out.
            </Box>
            <Box marginBottom={2} className={classes.confirm}>
                Would you like to sign out all currently open sessions?
            </Box>
        </ResponsiveDialog>
    );
};

ConfirmSessionLogoutDialog.propTypes = {
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    open: PropTypes.bool,
    role: PropTypes.symbol,
};

export default ConfirmSessionLogoutDialog;
