import { mapStateToProps, mergeProps } from './Permission.container';

import Component from './Permission.component';
import React from 'react';
import { connect } from 'react-redux';

export default (action, contentType, extraProps) => (AllowedComponent, FallbackComponent) =>
    connect(mapStateToProps, undefined, (stateProps, dispatchProps, ownProps) =>
        mergeProps(stateProps, dispatchProps, {
            ...ownProps,
            ...extraProps,
            toPerform: action,
            on: contentType,
            children: React.createElement(AllowedComponent, ownProps),
            fallbackComponent: FallbackComponent,
        })
    )(Component);
