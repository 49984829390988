import AuthenticatedPaths from './AuthenticatedRoutes.paths';
import Component from './AuthenticatedRoutes.component';
import { ENABLE_NEW_CMP_UX } from '/b2b/common/components/FeatureFlag';
import { PERMISSIONS } from '../../authentication/constants';
import { connect } from 'react-redux';
import { fetchUserDetails } from './AuthenticatedRoutes.commands';
import fromState from '../../common/state/selectors';
import { isFeatureEnabled } from '/b2b/common/helpers/FeatureFlags';

const mapStateToProps = state => {
    const userId = fromState.Auth.user.getId(state);
    const roles = fromState.DB.user.getEntityFieldById(state, { id: userId, field: 'roles' }) || [];
    const hasPermissionToReadAssessments =
        fromState.Auth.user.hasPermission(state, {
            toPerform: 'read',
            on: PERMISSIONS.ASSESSMENT,
        }) ||
        fromState.Auth.user.hasPermission(state, {
            toPerform: 'read',
            on: PERMISSIONS.ADMIN,
        });

    const hasPermissionToViewAssessments = fromState.Auth.customer.hasPermission(state, {
        toPerform: 'view',
        on: AuthenticatedPaths.ASSESSMENTS,
    });

    const hasPermissionToViewOrganizations = fromState.Auth.user.hasPermission(state, {
        toPerform: 'view',
        on: AuthenticatedPaths.ORGANIZATIONS,
    });

    const hasPermissionToViewReportsConsent = fromState.Auth.user.hasPermission(state, {
        toPerform: 'view',
        on: AuthenticatedPaths.REPORTS_CONSENT,
    });

    const hasPermissionToViewReportsSubjectRights = fromState.Auth.user.hasPermission(state, {
        toPerform: 'view',
        on: AuthenticatedPaths.REPORTS_DATASUBJECT,
    });

    const hasPermissionToViewReportsVendor = fromState.Auth.user.hasPermission(state, {
        toPerform: 'view',
        on: AuthenticatedPaths.REPORTS_VENDOR,
    });

    return {
        isAuthenticated: fromState.Auth.isAuthenticated(state),
        isNewCmpUxFeatureFlagEnabled: isFeatureEnabled(state, ENABLE_NEW_CMP_UX),
        userId: fromState.Auth.user.getEmail(state),
        customerToken: fromState.Auth.getCustomerToken(state),
        quicksightCategories: fromState.Quicksight.getCategories(state),
        roles,
        hasPermissionToReadAssessments,
        hasPermissionToViewAssessments,
        hasPermissionToViewOrganizations,
        hasPermissionToViewReportsConsent,
        hasPermissionToViewReportsSubjectRights,
        hasPermissionToViewReportsVendor,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchUserDetails: userId => dispatch(fetchUserDetails(userId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
