import { DOMAIN } from './AuthCustomerUsers.constants';
import get from 'lodash/get';
import { planLimitReached } from '/b2b/common/helpers/Limits';

export const getStore = state => get(state, DOMAIN) || {};

export const getResults = state => getStore(state).results || [];
export const getResultCount = state => getStore(state).resultCount || 0;
export const getError = state => getStore(state).error;
export const getCreationError = state => getStore(state).creationError;
export const getPlanData = state => getStore(state).planData || {};
export const getPlanLimitReached = state => planLimitReached(getPlanData(state));

export const getRequestParams = state => {
    const { offset, limit, query } = getStore(state);
    return {
        offset,
        limit,
        query,
    };
};

export const isLoading = state => getStore(state).loading;
export const isCreating = state => getStore(state).creating;
