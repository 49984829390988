import Component from './Permission.component';
import { Fragment } from 'react';
import castArray from 'lodash/castArray';
import { connect } from 'react-redux';
import fromState from '../../state/selectors';
import { isAllowedTo } from '../../helpers/Permissions';
import isPlainObject from 'lodash/isPlainObject';

export const mapStateToProps = state => {
    const customer = fromState.Auth.getCustomer(state);
    const { roles } = fromState.DB.user.getEntityById(state, fromState.Auth.user.getId(state));
    return {
        roles,
        customer,
        permissions: fromState.Auth.user.getPermissions(state),
    };
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { roles, customer, permissions } = stateProps;
    const {
        children,
        toPerform: action,
        on: contentType,
        onAny: anyType,
        fallbackComponent,
        without = false,
        forRole = false,
        shouldRender = true,
    } = ownProps;
    const checks = isPlainObject(action) ? action : {};
    const contentTypes =
        anyType === undefined
            ? typeof contentType === 'string'
                ? contentType.split(',')
                : castArray(contentType).filter(val => !!val)
            : typeof anyType === 'string'
              ? anyType.split(',')
              : castArray(anyType).filter(val => !!val);
    if (!isPlainObject(action)) {
        const actions =
            typeof action === 'string' ? action.split(',') : castArray(action).filter(val => !!val);

        while (contentTypes.length > actions.length) {
            actions.push(actions[0]);
        }
        while (contentTypes.length < actions.length) {
            contentTypes.push(contentTypes[0]);
        }
        contentTypes.forEach((type, index) => {
            checks[type] = checks[type] || {};
            checks[type][actions[index]] = true;
        });
    }
    const check =
        anyType === undefined
            ? isAllowedTo(roles, !forRole ? customer : undefined, checks, permissions)
            : Object.entries(checks).reduce((isAllowed, [type, actions]) => {
                  const typeChecks = { [type]: actions };
                  return (
                      isAllowed ||
                      isAllowedTo(roles, !forRole ? customer : undefined, typeChecks, permissions)
                  );
              }, false);
    const forceRender = shouldRender === true ? (check && !without) || (!check && without) : false;
    return {
        children: forceRender ? children : null,
        component: forceRender ? Fragment : fallbackComponent,
    };
};

export default connect(mapStateToProps, undefined, mergeProps)(Component);
