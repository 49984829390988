export const API_KEY_BODY = 'API_KEY_BODY';
export const API_KEY_HEADER = 'API_KEY_HEADER';
export const API_KEY_QUERY = 'API_KEY_QUERY';
export const BASIC = 'BASIC';
export const BEARER = 'BEARER';
export const OAUTH1 = 'OAUTH1';
export const OAUTH2 = 'OAUTH2';
export const OPENID = 'OPENID';
export const SAML = 'SAML';
export const AUTH_TYPE_CUSTOM = 'CUSTOM';

export const AUTH_TYPES = [
    AUTH_TYPE_CUSTOM,
    API_KEY_BODY,
    API_KEY_HEADER,
    API_KEY_QUERY,
    BASIC,
    BEARER,
    OAUTH1,
    OAUTH2,
    OPENID,
    SAML,
];

export const INTERNAL = 'internal';
export const SAAS = 'saas';

export const PROVIDER_TYPES = { INTERNAL, SAAS };

export const AUTH_TYPE_PATHS = {
    [AUTH_TYPE_CUSTOM]: 'dataMapping.authTypes.apiKey',
    [API_KEY_BODY]: 'dataMapping.authTypes.apiKey',
    [API_KEY_HEADER]: 'dataMapping.authTypes.apiKey',
    [API_KEY_QUERY]: 'dataMapping.authTypes.apiKey',
    [BASIC]: 'dataMapping.authTypes.apiKey',
    [BEARER]: 'dataMapping.authTypes.apiKey',
    [OAUTH1]: 'dataMapping.authTypes.oAuth',
    [OAUTH2]: 'dataMapping.authTypes.oAuth',
    [OPENID]: 'dataMapping.authTypes.openId',
    [SAML]: 'dataMapping.authTypes.saml',
};

export const ONE_CLICK_PATH = 'dataMapping.authTypes.oneClick';

export const STATUS_ERROR = 'ERROR';
export const STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const STATUS_SYNCED = 'SYNCED';
export const STATUS_QUEUED = 'QUEUED';
export const STATUS_WAITING_FOR_AUTH = 'WAITING_FOR_AUTH';

export const DATA_STORE_CONNECTION_STATUS = {
    [STATUS_ERROR]: 'dataStores.status.error',
    [STATUS_IN_PROGRESS]: 'dataStores.status.inProgress',
    [STATUS_SYNCED]: 'dataStores.status.synced',
    [STATUS_QUEUED]: 'dataStores.status.queued',
    [STATUS_WAITING_FOR_AUTH]: 'dataStores.status.waitingForAuth',
};

export const TYPE_ON_PREM_SCHEMA = 'sqlSchema';
export const TYPE_ON_PREM_DATASET = 'sqlTable';
export const TYPE_ON_PREM_FIELD = 'sqlSchema';
export const TYPE_SAAS_SCHEMA = 'schema';
export const TYPE_SAAS_DATASET = 'object';
export const TYPE_SAAS_DATASET_ALT = 'table';
export const TYPE_SAAS_FIELD_SET = 'fieldSet';
export const TYPE_SAAS_FIELD = 'field';

export const COMPLETED = 'COMPLETED';
export const ERROR = 'ERROR';
export const EXPIRED = 'EXPIRED';
export const IN_PROGRESS = 'IN_PROGRESS';
export const INITIALIZING = 'INITIALIZING';
export const NO_RESULTS = 'NO_RESULTS';
export const QUEUED = 'QUEUED';

export const CLASSIFICATION_UNCLASSIFIED = 'dataMapping.classificationTypes.none.name';

export const DATA_SEARCH_STATUS_KEYS = {
    COMPLETED,
    ERROR,
    EXPIRED,
    IN_PROGRESS,
    INITIALIZING,
    QUEUED,
};

export const DATA_DELETION_STATUS_KEYS = {
    COMPLETED,
    ERROR,
    EXPIRED,
    IN_PROGRESS,
    INITIALIZING,
    QUEUED,
};

export const RESOLVED_DATA_SEARCH_STATUS_KEYS = [COMPLETED, EXPIRED, NO_RESULTS, ERROR];

export const ACTION_ITEMS_SEARCH_STATUS_SHORT = {
    [COMPLETED]: 'dsar.actionItems.search.shortStatus.complete',
    [ERROR]: 'dsar.actionItems.search.shortStatus.error',
    [EXPIRED]: 'dsar.actionItems.search.shortStatus.expired',
    [INITIALIZING]: 'dsar.actionItems.search.shortStatus.initializing',
    [IN_PROGRESS]: 'dsar.actionItems.search.shortStatus.inProgress',
    [QUEUED]: 'dsar.actionItems.search.shortStatus.queued',
};

export const DSAR_REQUEST_ACTIONS = {
    SUMMARIZE: 'dsar.requestActions.summarizeFieldNamesValues',
    SUMMARIZE_FIELD_NAMES: 'dsar.requestActions.summarizeFieldNames',
    DELETE: 'dsar.requestActions.delete',
};

export const DSAR_DATA_STORE_REQUEST_ACTION_KEYS = {
    NO_ACTION: 'NO_ACTION',
    DELETED: 'DELETED',
    UPDATED: 'UPDATED',
    MINIMIZED: 'MINIMIZED',
};

export const DSAR_DATA_STORE_REQUEST_ACTION = {
    [DSAR_DATA_STORE_REQUEST_ACTION_KEYS.NO_ACTION]: 'dsar.requestActions.unmodified',
    [DSAR_DATA_STORE_REQUEST_ACTION_KEYS.DELETED]: 'dsar.requestActions.delete',
    [DSAR_DATA_STORE_REQUEST_ACTION_KEYS.UPDATED]: 'dsar.requestActions.updated',
    [DSAR_DATA_STORE_REQUEST_ACTION_KEYS.MINIMIZED]: 'dsar.requestActions.minimized',
};

export const EVENTS = {
    ADD_CONNECTION: 'AddConnection',
    // CLASSIFY_CONNECTION_FIELD: 'ClassifyConnectionField',
    DELETE_CONNECTION: 'DeleteConnection',
    // FILTER_CONNECTIONS: 'FilterConnections',
    NEW_CONNECTION: 'NewConnection',
    NEW_DATA_STORE_FIELD: 'NewDataStoreField',
    // SEARCH_CONNECTIONS: 'SearchConnections',
    // SELECT_AUTH_API_KEY: 'SelectAuthApiKey',
    // SELECT_AUTH_BASIC: 'SelectAuthBasic',
    // SELECT_AUTH_BEARER: 'SelectAuthBearer',
    // SELECT_AUTH_O_AUTH: 'SelectAuthOAuth',
    // SELECT_AUTH_ONE_CLICK: 'SelectAuthOneClick',
    // SELECT_AUTH_OPEN_ID: 'SelectAuthOpenId',
    // SELECT_AUTH_SAML: 'SelectAuthSaml',
    SELECT_AUTHENTICATION_METHOD: 'SelectAuthenticationMethod',
    // SELECT_REQUEST_ACTIONS: 'SelectRequestActions',
    SELECT_RESCAN: 'SelectRescan',
    // SYNC_CONNECTION: 'SyncConnection',
    // VIEW_ERROR_MESSAGE: 'ViewErrorMessage',
    ADD_PRODUCT_ASSOCIATION_CONFIRM: 'AddProductAssociationConfirm',
    ADD_PRODUCT_ASSOCIATION_CLOSE: 'AddProductAssociationClose',

    // TODO: Remove when ENABLE_PRIVACY_RISK is removed
    ADD_VENDOR_ASSOCIATION_CONFIRM: 'AddVendorAssociationConfirm',
    ADD_VENDOR_ASSOCIATION_CLOSE: 'AddVendorAssociationClose',
};

export const RECEIVER = 'RECEIVER';
export const SENDER = 'SENDER';

export const RELATIONSHIP_TYPES = {
    RECEIVER,
    SENDER,
};

export const DATA_STORE = 'DATA_STORE';
export const VENDOR = 'VENDOR';

export const RELATIONSHIP_TARGET_TYPES = {
    DATA_STORE,
    VENDOR,
};

export const DATA_DISCOVERY_OPERATION = 'SCHEMA';
