import { DOMAIN } from './ConversationThreads.constants';
import get from 'lodash/get';

export const getStore = state => get(state, DOMAIN) || {};

export const getId = state => getStore(state).id;
export const getError = state => getStore(state).error;
export const getResults = state => getStore(state).results || [];
export const getResultCount = state => getStore(state).resultCount || 0;
export const getNewPost = state => getStore(state).newPost || undefined;
export const getRequestParams = state => {
    const { offset, limit } = getStore(state);
    return {
        offset,
        limit,
    };
};

export const isLoading = state => getStore(state).loading;
export const isPosting = state => getStore(state).posting;
