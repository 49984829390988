/* eslint-disable camelcase */
import {
    allSettled,
    assignResults,
    fetchJSON,
    formatFilterParams,
    formatPagingParams,
    handleError,
    makeResultsFormatter,
} from '../common/services/helpers';
import {
    assignConsentConfig,
    formatConfig,
    formatConfigResults,
    formatConfigRevisionsResults,
    formatConsentDataResults,
    formatConsentStats,
    formatConsentTrafficStats,
    formatRuleResults,
    formatScanUrl,
    formatScanUrlResults,
    formatSitemapURL,
    formatSitemapURLResults,
    formatTattleResults,
} from './format';

import API from '@aws-amplify/api';
import { apiName } from '@osano-b2b';
import { assignCustomer } from '../common/services/format';
import castArray from 'lodash/castArray';
import { consentManagerLocaleUri } from '@osano-b2b';
import { createConsentConfigPayloadFromState } from './utils';
import noop from 'lodash/noop';
import pickBy from 'lodash/pickBy';
import { validateIP } from '../common/helpers/URL';

const languagesToLoad = [];
const languagesLoaded = {};
const loadLanguage = (language, onLoad) =>
    language &&
    fetchJSON(`${consentManagerLocaleUri}${language}.json`)
        .then(json => {
            languagesLoaded[language] = json;
            onLoad(language, json);
        })
        .catch(noop)
        .then(() => loadLanguage(languagesToLoad.shift(), onLoad));
export const preloadLanguages = (arrayOfLocaleCodes, onLoad) => {
    languagesToLoad.splice(
        0,
        languagesToLoad.length,
        ...arrayOfLocaleCodes.filter(language =>
            languagesLoaded[language]
                ? !!(onLoad(language, languagesLoaded[language]) && false)
                : true
        )
    );
    loadLanguage(languagesToLoad.shift(), onLoad);
    return function cancel() {
        languagesToLoad.splice(0, languagesToLoad.length);
    };
};

export const fetchConfigs = params => {
    const { customerId, filters } = params;
    const { publishStatus, mode, organizations: organizationsFilter } = filters || {};
    const organizations = organizationsFilter?.length
        ? castArray(organizationsFilter).map(o => (typeof o === 'string' ? o : o.value))
        : undefined;
    const queryParams = pickBy(
        { publishStatus, mode, organizations },
        value => !!value && value.toString().length
    );
    const options = {
        queryStringParameters: {
            ...formatPagingParams(params),
            ...queryParams,
        },
    };
    return API.get(apiName, `/consentmanager/configs`, options)
        .catch(handleError)
        .then(formatConfigResults)
        .then(assignResults(assignCustomer(customerId)));
};

export const fetchConfigById = ccId => {
    const options = {};
    return API.get(apiName, `/consentmanager/config/${encodeURIComponent(ccId)}`, options)
        .catch(handleError)
        .then(formatConfig);
};

export const createConfig = (state, isNewUx) => {
    const body = createConsentConfigPayloadFromState(state, isNewUx);
    const options = {
        body,
    };
    return API.post(apiName, `/consentmanager/config`, options)
        .catch(handleError)
        .then(responseOrId =>
            typeof responseOrId === 'object'
                ? { ...state, ...responseOrId }
                : { id: responseOrId, ...state }
        )
        .then(formatConfig);
};

export const copyConfigById = ({ ccId, crossDomain, domains, name }) => {
    const options = {
        body: {
            crossDomain,
            domains,
            name,
        },
    };

    return API.post(
        apiName,
        `/consentmanager/config/${encodeURIComponent(ccId)}/duplicate`,
        options
    )
        .catch(handleError)
        .then(formatConfig);
};

export const updateConfigById = (ccId, updated, state, isNewUx) => {
    const body = createConsentConfigPayloadFromState(state, isNewUx);

    const options = {
        body: {
            ...body,
            updated,
        },
    };

    return API.patch(apiName, `/consentmanager/config/${encodeURIComponent(ccId)}`, options)
        .catch(handleError)
        .then(formatConfig);
};

export const deleteConfig = ccId => {
    const options = {
        responseType: 'text',
    };
    return API.del(apiName, `/consentmanager/config/${encodeURIComponent(ccId)}`, options)
        .then(response => {
            try {
                // Check if the response is JSON
                return JSON.parse(response);
            } catch (error) {
                return { status: 'success', code: 200, response };
            }
        })
        .catch(handleError)
        .then(response => {
            const { status, response: resp } = response;
            if (status === 'success') {
                return ccId;
            }
            return resp || response;
        });
};

export const deleteMultipleConfigs = configs => {
    return allSettled(configs.map(ccId => deleteConfig(ccId)));
};

export const publishConsentConfigById = ({
    ccId,
    description,
    keepUnclassifiedTattles,
    publish = true,
}) => {
    const options = {
        body: {
            description,
            keepUnclassifiedTattles,
            publish,
        },
    };
    return API.post(
        apiName,
        `/consentmanager/config/${encodeURIComponent(ccId)}/publish`,
        options
    ).catch(handleError);
};

export const deleteTattles = ccId => {
    return API.del(apiName, `/consentmanager/config/${ccId}/tattles`).catch(handleError);
};

export const fetchConsentStats = () => {
    const options = {};
    return API.get(apiName, `/consentmanager/stats/daily-cons-decln-counts`, options)
        .catch(handleError)
        .then(formatConsentStats);
};

export const fetchConsentStatsByConfigId = params => {
    const { ccId } = params;
    const options = {};
    return API.get(
        apiName,
        `/consentmanager/stats/daily-cons-decln-counts/${encodeURIComponent(ccId)}`,
        options
    )
        .catch(handleError)
        .then(formatConsentStats);
};

export const fetchConsentTrafficStats = (params = {}) => {
    const { daysAgo = 30 } = params;
    const options = {
        queryStringParameters: { daysAgo },
    };
    return API.get(apiName, `/consentmanager/stats/daily-cons-traffic-counts`, options)
        .catch(handleError)
        .then(formatConsentTrafficStats);
};

export const fetchRevisionsByConfigId = ccId => {
    return API.get(apiName, `/consentmanager/config/${ccId}/revisions`)
        .catch(handleError)
        .then(formatConfigRevisionsResults);
};

export const rollbackConsentVersion = params => {
    const { ccId, deployRevision, keepUnclassifiedTattles } = params;
    const options = {
        body: {
            keepUnclassifiedTattles,
            deployRevision,
        },
    };
    return API.post(
        apiName,
        `/consentmanager/config/${encodeURIComponent(ccId)}/publish`,
        options
    ).catch(handleError);
};

export const fetchTrafficStatsByConfigId = params => {
    const { ccId, daysAgo = 30 } = params;
    const options = {
        queryStringParameters: { daysAgo },
    };
    return API.get(apiName, `/consentmanager/stats/daily-cons-traffic-counts/${ccId}`, options)
        .catch(handleError)
        .then(formatConsentTrafficStats);
};

export const fetchConsentsByConfigId = params => {
    const { ccId, filters } = params;
    let { consentedCategories, consentedDomains, end, extUsrData, ip, start, userConsentId } =
        filters;
    if (ip && !validateIP(ip)) {
        ip = undefined;
    }
    if (start > end) {
        end = start;
    }
    const queryParams = pickBy(
        { ip, start, end, consentedDomains, userConsentId, consentedCategories, extUsrData },
        value => !!value && value.toString().length
    );
    if (queryParams.consentedDomains) {
        queryParams.consentedDomains = castArray(queryParams.consentedDomains).join(',');
    }
    if (queryParams.consentedCategories) {
        queryParams.consentedCategories = castArray(queryParams.consentedCategories).join(',');
    }
    const options = {
        queryStringParameters: {
            ...formatPagingParams(params),
            ...queryParams,
        },
    };
    return API.get(apiName, `/consentmanager/config/${encodeURIComponent(ccId)}/consents`, options)
        .catch(handleError)
        .then(formatConsentDataResults);
};

export const fetchScanUrlsByConfigId = params => {
    const { ccId } = params;
    const queryStringParameters = {
        ...formatPagingParams(params),
        ...formatFilterParams(params),
    };
    const options = {
        queryStringParameters,
    };
    return API.get(apiName, `/scan/config/${ccId}/urls`, options)
        .catch(handleError)
        .then(formatScanUrlResults);
};

export const addScanUrl = params => {
    const { ccId, payload } = params;
    const options = {
        body: payload,
    };
    return API.post(apiName, `/scan/config/${ccId}/url`, options)
        .catch(handleError)
        .then(response => {
            if (!response) {
                return payload;
            }
            return response;
        })
        .then(formatScanUrl);
};

export const deleteScanUrl = params => {
    const { ccId, url } = params;
    const options = {
        body: {
            url,
        },
    };
    return API.del(apiName, `/scan/config/${ccId}/url`, options).catch(handleError);
};

export const deleteMultipleScanUrls = ({ ccId, urls }) => {
    return allSettled(urls.map(url => deleteScanUrl({ ccId, url }))).then(results => {
        const failures = results.filter(({ state }) => state === 'rejected');

        if (failures.length > 0) {
            throw failures[0].reason;
        }
    });
};

export const fetchIABVendorsByConfigId = params => {
    const { ccId, filters } = params;
    const queryStringParameters = {
        ...formatPagingParams(params),
        ...(filters || {}),
    };
    const options = {
        queryStringParameters,
    };
    return API.get(apiName, `/consentmanager/config/${ccId}/iab/vendors`, options)
        .catch(handleError)
        .then(makeResultsFormatter());
};

export const addIABVendorToConfig = params => {
    const { ccId, vendorId } = params;
    const options = {
        body: {
            vendorId,
        },
    };
    return API.post(apiName, `/consentmanager/config/${ccId}/iab/vendor`, options).catch(
        handleError
    );
};

export const deleteIABVendorFromConfig = params => {
    const { ccId, vendorId } = params;
    const options = {
        body: {
            vendorId,
        },
    };
    return API.del(apiName, `/consentmanager/config/${ccId}/iab/vendor`, options).catch(
        handleError
    );
};

export const deleteMultipleIABVendorsFromConfig = ({ ccId, vendorIds }) => {
    return allSettled(vendorIds.map(vendorId => deleteIABVendorFromConfig({ ccId, vendorId })));
};

export const fetchSitemaps = params => {
    const { ccId } = params;
    const queryStringParameters = {
        ...formatPagingParams(params),
        ...formatFilterParams(params),
    };
    const options = {
        queryStringParameters,
    };
    return API.get(apiName, `/scan/config/${ccId}/sitemaps`, options)
        .catch(handleError)
        .then(formatSitemapURLResults);
};

export const addSitemap = params => {
    const { ccId, payload } = params;
    const options = {
        body: payload,
    };
    return API.post(apiName, `/scan/config/${ccId}/sitemap`, options)
        .catch(handleError)
        .then(response => {
            if (!response) {
                return payload;
            }
            return response;
        })
        .then(formatSitemapURL);
};

export const deleteSitemapById = params => {
    const { ccId, url } = params;
    const options = {
        body: {
            url,
        },
    };
    return API.del(apiName, `/scan/config/${ccId}/sitemap`, options).catch(handleError);
};

export const deleteMultipleSitemapsById = ({ ccId, urls }) => {
    return allSettled(urls.map(url => deleteSitemapById({ ccId, url }))).then(results => {
        const failures = results.filter(({ state }) => state === 'rejected');

        if (failures.length > 0) {
            throw failures[0].reason;
        }
    });
};

export const updateSitemapById = (ccId, body) => {
    return API.patch(apiName, `/scan/config/${ccId}/sitemap`, { body })
        .catch(handleError)
        .then(response => {
            if (!response) {
                return body;
            }
            return response;
        });
};

export const updateScanUrlById = (ccId, body) => {
    return API.patch(apiName, `/scan/config/${ccId}/url`, { body })
        .catch(handleError)
        .then(response => {
            if (!response) {
                return body;
            }
            return response;
        });
};

export const fetchTattlesById = params => {
    const { ccId, storeType = 'cookie' } = params;

    const queryStringParameters = {
        storeType,
        ...formatPagingParams(params),
    };

    const options = {
        queryStringParameters,
    };

    return API.get(
        apiName,
        `/consentmanager/config/${encodeURIComponent(ccId)}/categorized-tattles`,
        options
    )
        .catch(handleError)
        .catch(error => {
            // The most common reason for request failure is query string filters and sort, so lets remove them
            const url = document.location.href.split('?')[0];
            if (url !== document.location.href) {
                delete queryStringParameters.sortDir;
                delete queryStringParameters.sortField;
                return API.get(
                    apiName,
                    `/consentmanager/config/${encodeURIComponent(ccId)}/categorized-tattles`,
                    options
                );
            }
            throw error;
        })
        .then(assignResults(assignConsentConfig(ccId)))
        .then(formatTattleResults);
};

export const fetchRulesById = params => {
    const { ccId, storeType = 'cookie' } = params;

    const queryStringParameters = {
        storeType,
        ...formatPagingParams(params),
        ...formatFilterParams(params),
    };

    const options = {
        queryStringParameters,
    };

    return API.get(apiName, `/consentmanager/config/${encodeURIComponent(ccId)}/rules`, options)
        .catch(handleError)
        .catch(error => {
            // The most common reason for request failure is query string filters and sort, so lets remove them
            const url = document.location.href.split('?')[0];
            if (url !== document.location.href) {
                const newQueryStringParameters = {
                    storeType,
                    ...formatPagingParams(params),
                };
                delete newQueryStringParameters.sortDir;
                delete newQueryStringParameters.sortField;
                return API.get(
                    apiName,
                    `/consentmanager/config/${encodeURIComponent(ccId)}/rules`,
                    { queryStringParameters: newQueryStringParameters }
                );
            }
            throw error;
        })
        .then(assignResults(assignConsentConfig(ccId)))
        .then(formatRuleResults);
};

export const publishMultipleRulesById = ({ ccId, rules: body = [] }) => {
    const options = {
        body,
    };

    return API.post(apiName, `/consentmanager/config/${encodeURIComponent(ccId)}/rules`, options)
        .catch(handleError)
        .then(() => ({
            response: {},
        }));
};

export const updateMultipleRulesById = ({ ccId, body = [] }) => {
    const options = {
        body,
    };

    return (
        API.patch(apiName, `/consentmanager/config/${encodeURIComponent(ccId)}/rules`, options)
            .catch(handleError)
            // Sometimes response will not contain the updated values we passed, so lets assume they were a success
            .then(response =>
                response?.results
                    ? response
                    : {
                          results: body,
                      }
            )
            .then(formatRuleResults)
    );
};

export const deleteMultipleRulesById = ({ ccId, ids, ruleIds = ids }) => {
    const options = {
        body: {
            ruleIds,
        },
    };

    return API.del(apiName, `/consentmanager/config/${encodeURIComponent(ccId)}/rules`, options)
        .catch(handleError)
        .then(() => ({
            response: {},
        }));
};

const iabVendorJsonCache = {};

export const fetchAllIabVendors = (params = {}) => {
    const { iabVersion = 2, territory = 'eu' } = params;
    return new Promise((resolve, reject) => {
        if (!iabVendorJsonCache[iabVersion]) {
            const url = new URL(
                `/assets/json/vendor-list.json?territory=${territory}&version=${iabVersion}`,
                `${document.location.protocol}//${document.location.host}`
            );
            const options = {
                method: 'GET',
                mode: 'cors',
                redirect: 'follow',
                referrer: 'no-referrer',
            };
            resolve(
                fetchJSON(url, options)
                    .catch(reject)
                    .then(json => {
                        iabVendorJsonCache[iabVersion] = json;
                        iabVendorJsonCache[iabVersion].lastUpdated =
                            json?.lastUpdated || new Date().toUTCString();
                        return json;
                    })
            );
        } else {
            resolve(iabVendorJsonCache[iabVersion]);
        }
    }).then(json => {
        return {
            lastUpdated: json?.lastUpdated || new Date().toUTCString(),
            vendorListVersion: json?.vendorListVersion || 0,
            vendors: Object.values(json?.vendors || {}),
        };
    });
};

export const fetchDisclosuresById = params => {
    const { customerId, configId } = params;

    return API.get(
        apiName,
        `/customer/${encodeURIComponent(customerId)}/config/${encodeURIComponent(
            configId
        )}/disclosures`
    ).catch(handleError);
};
