import Component from './RecoverPassword.component';
import { completeNewPassword } from './RecoverPassword.commands';
import { connect } from 'react-redux';
import fromState from '../../../common/state/selectors';

const mapStateToProps = state => {
    return {
        authState: fromState.Auth.getAuthState(state),
        errorMesage: fromState.Auth.getError(state),
        isLoading: fromState.Auth.isLoading(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCompleteNewPassword: params => dispatch(completeNewPassword(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
