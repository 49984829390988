import { Button, CircularProgress, Typography } from '@mui/material';
import React, { Fragment, forwardRef, useEffect, useRef } from 'react';

import DynamicTabbed from '../DynamicTabbed';
import PropTypes from 'prop-types';
import ValidationFormFields from './ValidationFormFields.component';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import startCase from 'lodash/startCase';
import styles from './ValidationForm.styles';

const useStyles = makeStyles(styles);

const scrollToRef = ref => ref.current && window.scrollTo(0, ref.current.offsetTop);

const ValidationForm = props => {
    const {
        className,
        tabSchemas,
        children,
        defaultValues,
        errors,
        helperText,
        onFieldBlur,
        onFieldChange,
        onFieldRevert,
        disabled,
        isLoading,
        onSubmit,
        canSubmit,
        orientation = 'horizontal',
        submitError,
        title,
        subtitle,
        submitButton,
        submitText = 'Submit',
        touched,
        values,
    } = props;
    const classes = useStyles(props);
    const errorRef = useRef(null);

    useEffect(() => {
        submitError && scrollToRef(errorRef);
    }, [submitError]);

    const SubmitButton =
        submitButton ||
        forwardRef((props, ref) => (
            <Button
                color="primary"
                size="large"
                variant="contained"
                type="submit"
                {...props}
                ref={ref}
            />
        ));

    return (
        <form
            className={classNames(classes.form, className)}
            onSubmit={onSubmit}
            disabled={disabled}
        >
            <div className={classes.info}>
                <div className={classes.title}>
                    <Typography variant="h2">{title}</Typography>
                    {subtitle && (
                        <Typography className={classes.subtitle} variant="body1">
                            {subtitle}
                        </Typography>
                    )}
                </div>
                {isLoading ? (
                    <CircularProgress className={classes.progress} />
                ) : onSubmit ? (
                    <SubmitButton
                        onClick={onSubmit}
                        disabled={!canSubmit}
                        className={classes.submitButton}
                    >
                        {submitText}
                    </SubmitButton>
                ) : null}
            </div>
            {submitError && (
                <Typography ref={errorRef} className={classes.submitError} variant="body2">
                    {submitError}
                </Typography>
            )}
            <DynamicTabbed orientation={orientation} className={classes.root}>
                {children}
                {Object.entries(tabSchemas).map(([label, schema]) => {
                    return (
                        <Fragment
                            key={label}
                            tab={label}
                            label={startCase(label)}
                            TabProps={{
                                value: label,
                            }}
                        >
                            <ValidationFormFields
                                defaultValues={defaultValues}
                                errors={errors}
                                disabled={disabled}
                                isLoading={isLoading}
                                helperText={helperText}
                                schema={schema}
                                onFieldBlur={onFieldBlur}
                                onFieldChange={onFieldChange}
                                onFieldRevert={onFieldRevert}
                                touched={touched}
                                values={values}
                            />
                        </Fragment>
                    );
                })}
            </DynamicTabbed>
        </form>
    );
};

ValidationForm.propTypes = {
    canSubmit: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    fields: PropTypes.shape({
        hidden: PropTypes.bool,
    }),
    tabSchemas: PropTypes.objectOf(PropTypes.object),
    extraFields: PropTypes.any,
    isLoading: PropTypes.bool,
    onFieldChange: PropTypes.func,
    onFieldRevert: PropTypes.func,
    onSubmit: PropTypes.func,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    title: PropTypes.string,
    subtitle: PropTypes.string,
    submitButton: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    submitText: PropTypes.string,
    submitError: PropTypes.string,
    defaultValues: PropTypes.object,
    errors: PropTypes.object,
    helperText: PropTypes.object,
    onFieldBlur: PropTypes.func,
    touched: PropTypes.object,
    values: PropTypes.object,
};

export default ValidationForm;
