export default theme => ({
    content: {
        justifyContent: 'center',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    contentBody: {
        flexGrow: 0.3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center',
        },
    },
    list: {
        flexBasis: '700px',
        padding: theme.spacing(0, 2),
    },
    title: {
        fontSize: '2rem',
        margin: theme.spacing(2, 0),
        '&:empty': {
            margin: 0,
        },
    },
    subtitle: {
        color: theme.palette.text.secondary,
        margin: theme.spacing(2, 0),
        '$title + &': {
            marginTop: theme.spacing(-1.5),
        },
    },
    mfaOption: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    loadingWrapper: {
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,0.9)',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: theme.zIndex.loadingWrapper,
    },
});
