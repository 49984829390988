import { noParameters } from './parameterize';

export const createActionCreator = (type, getPayload = noParameters) =>
    type
        ? (...params) => {
              return {
                  type,
                  ...getPayload(...params),
              };
          }
        : undefined;

export default createActionCreator;
