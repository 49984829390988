import { Divider, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import styles from './ValidationDivider.styles';

const useStyles = makeStyles(styles);

const ValidationDivider = props => {
    const { label } = props;
    const classes = useStyles(props);
    return (
        <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
            className={classes.root}
        >
            <Grid item xs>
                <Divider />
            </Grid>
            {label && (
                <Fragment>
                    <Grid item xs={1} className={classes.label}>
                        <Typography variant="body1">{label}</Typography>
                    </Grid>
                    <Grid item xs>
                        <Divider />
                    </Grid>
                </Fragment>
            )}
        </Grid>
    );
};

ValidationDivider.propTypes = {
    label: PropTypes.string,
};

export default ValidationDivider;
