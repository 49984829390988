import {
    contrastRgb,
    hslToRgb,
    hueFromString,
    intToHexString,
    parseColor,
} from '../../../common/helpers/Color';
/* eslint-disable camelcase */
import {
    defaultIdAttribute,
    defaultMergeStrategy,
    defaultProcessStrategy,
} from '../../../common/helpers/Normalizr';

import { getInitials } from '../../../common/helpers/String';
import { schema } from 'normalizr';

export const entityName = 'users';
export const idAttribute = 'email';

export const mergeStrategy = defaultMergeStrategy(idAttribute);
export const processStrategy = defaultProcessStrategy(idAttribute, details => {
    let {
        name: fullName,
        email: providedEmail,
        first_name,
        last_name,
        firstname,
        lastname,
        first,
        last,
        firstName,
        lastName,
        id, // eslint-disable-line no-unused-vars
        ...newDetails
    } = details;
    firstName = firstName || firstname || first_name || first;
    lastName = lastName || lastname || last_name || last;
    const name =
        fullName || `${firstName || ''}${firstName && lastName ? ' ' : ''}${lastName || ''}`;
    const initials = name && getInitials(name);
    // Must lowercase all emails
    const email = `${providedEmail || ''}`.toLowerCase();
    // Do NOT assume we provide new name or email
    if (firstName) {
        newDetails.firstName = firstName;
    }
    if (lastName) {
        newDetails.lastName = lastName;
    }
    if (name) {
        newDetails.name = name;
        newDetails.initials = initials;
        const rgb = hslToRgb(hueFromString(`${name}`) / 360, 0.6, 0.9);
        const { color = intToHexString.apply(null, rgb) } = details;
        // Custom Color
        newDetails.textColor =
            color && contrastRgb.apply(null, parseColor(color)) ? '#ffffff' : '#000000';
        newDetails.color = color && `#${intToHexString.apply(null, parseColor(color))}`;
    }
    if (email) {
        newDetails.email = email;
    }
    return newDetails;
});

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
