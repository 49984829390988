import {
    types as ActionTypes,
    asyncEvents as AsyncEvents,
    creationEvents as CreationEvents,
} from './OrganizationsList.actions';
import {
    CRUDPagingQueryReducer,
    crudPagingReducers,
    errorReducer,
    genericReducer,
    loadingReducer,
} from '../../common/helpers/ReduxHelpers';

import AuthenticatedPaths from '../../routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { types as NavActionTypes } from '../../common/state/nav/Nav.actions';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import schema from './db/Organization.schema';

export const initialState = {
    offset: 0,
    limit: 25,
    query: '',
    results: [],
    resultCount: 0,
    error: null,
    loading: false,
    creating: false,
    creationError: null,
    planData: {},
};

export default reduceReducers(
    initialState,
    combineReducers({
        creating: loadingReducer(
            initialState.creating,
            Object.keys(CreationEvents).map(key => ActionTypes[key])
        ),
        creationError: errorReducer(
            initialState.creationError,
            Object.keys(CreationEvents).map(key => ActionTypes[key])
        ),
        ...crudPagingReducers(
            initialState,
            Object.keys(AsyncEvents).map(key => ActionTypes[key]),
            schema
        ),
    }),
    genericReducer(initialState, {
        [NavActionTypes.routeChanged]: CRUDPagingQueryReducer(
            initialState,
            AuthenticatedPaths.ORGANIZATIONS
        ),
    })
);
