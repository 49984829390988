import { getUserMfaOptions, sendMFASelection } from '../../../services';

import AuthActions from '/b2b/authentication/state/Auth.actions';

export const getMfaOptions = () => dispatch => {
    dispatch(AuthActions.getMfaOptionsBegin());

    return getUserMfaOptions()
        .then((mfaOptions = []) => {
            dispatch(AuthActions.getMfaOptionsSuccess(mfaOptions));

            return mfaOptions;
        })
        .catch(error => {
            AuthActions.getMfaOptionsFailure(error);

            return ['SMS_MFA', 'SOFTWARE_TOKEN_MFA'];
        });
};

export const selectMFA = params => dispatch => {
    const { preferredMFA, ...remain } = params;

    if (preferredMFA === 'SELECT_MFA_TYPE') {
        return sendMFASelection(params).then(response => {
            const { challengeName } = response || {};

            return dispatch(AuthActions.selectMfa({ ...remain, mfaType: challengeName }));
        });
    }
    dispatch(AuthActions.selectMfa(params));

    return Promise.resolve(remain);
};
