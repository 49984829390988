/* eslint react/prop-types: 0 */
import React from 'react';
import { SvgIcon } from '@mui/material';

import { white } from '/b2b/common/colors';

export default function ReportsIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M19.4,22H4.6c-0.7,0-1.1,0-1.5-0.2c-0.4-0.2-0.7-0.5-0.9-0.9C2,20.5,2,20.1,2,19.4v-5.8c0-0.7,0-1.1,0.2-1.5
                c0.2-0.4,0.5-0.7,0.9-0.9C3.5,11,3.9,11,4.6,11H8V8.6c0-0.7,0-1.1,0.2-1.5c0.2-0.4,0.5-0.7,0.9-0.9C9.5,6,9.9,6,10.6,6h2.8
                c0.2,0,0.4,0,0.6,0V4.6c0-0.7,0-1.1,0.2-1.5c0.2-0.4,0.5-0.7,0.9-0.9C15.5,2,15.9,2,16.6,2h2.8c0.7,0,1.1,0,1.5,0.2
                c0.4,0.2,0.7,0.5,0.9,0.9C22,3.5,22,3.9,22,4.6v14.8c0,0.7,0,1.1-0.2,1.5c-0.2,0.4-0.5,0.7-0.9,0.9C20.5,22,20.1,22,19.4,22z
                M4.6,13C4.3,13,4,13,4,13c0,0,0,0.3,0,0.6v5.8C4,19.7,4,20,4,20c0,0,0.3,0,0.6,0H8v-7H4.6z M16,20h3.4c0.3,0,0.6,0,0.6,0
                c0,0,0-0.3,0-0.6V4.6C20,4.3,20,4,20,4c0,0-0.3,0-0.6,0h-2.8C16.3,4,16,4,16,4c0,0,0,0.3,0,0.6V8c0,0,0,0.1,0,0.1c0,0.2,0,0.3,0,0.5
                V20z M10,20h4V8.6C14,8.3,14,8,14,8c0,0-0.3,0-0.6,0h-2.8C10.3,8,10,8,10,8c0,0,0,0.3,0,0.6V20z"
                fill={white}
            />
        </SvgIcon>
    );
}
