/* eslint-disable react/no-children-prop */
import { Redirect, Route, Switch } from 'react-router-dom';

import AccountRoutes from '../../account/routes/BrowseRoutes';
import BrowsePaths from './BrowseRoutes.paths';
import DashboardLayout from '../../common/layouts/Dashboard';
import NotFound from '../NotFound';
import React from 'react';
import UnauthenticatedPaths from '../UnauthenticatedRoutes/UnauthenticatedRoutes.paths';

const redirects = Object.values(UnauthenticatedPaths).map(path => (
    <Route key={path} path={path}>
        <Redirect to={BrowsePaths.ACCOUNT} />
    </Route>
));

const BrowseRoutes = () => {
    return (
        <DashboardLayout>
            <Switch>
                <Route
                    path={UnauthenticatedPaths.OAUTH}
                    children={<Redirect to={BrowsePaths.ACCOUNT} />}
                />
                <Route path={BrowsePaths.ACCOUNT} children={<AccountRoutes />} />
                <Route exact path={BrowsePaths.NOT_FOUND} children={<NotFound />} />
                <Route exact path="/" children={<Redirect to={BrowsePaths.ACCOUNT} />} />
                {redirects}
                <Route children={<Redirect to={BrowsePaths.NOT_FOUND} />} />
            </Switch>
        </DashboardLayout>
    );
};

BrowseRoutes.propTypes = {};

export default BrowseRoutes;
