export default theme => ({
    root: {
        padding: theme.spacing(1, 3),
        whiteSpace: 'nowrap',
    },
    wrapper: {
        alignItems: 'stretch',
    },
    textColorInherit: {
        color: theme.palette.background.paper,
        '&.Mui-selected': {
            color: theme.palette.tertiary.main,
            backgroundColor: theme.palette.background.paper,
        },
    },
});
