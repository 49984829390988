import { types as ActionTypes } from './ValidationForm.actions';
import castArray from 'lodash/castArray';
import { emptyObject } from './ValidationForm.selectors';
import { genericReducer } from '../../helpers/ReduxHelpers';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import set from 'lodash/set';
import unset from 'lodash/unset';

export const initialState = {
    continuousValidation: false,
    constraints: {},
    values: {},
    touched: emptyObject,
    errors: emptyObject,
    shouldTab: false,
    submitAttempted: false,
    submitting: false,
    submitError: null,
    tabSchemas: {},
    valid: false,
};

export const initState = (state, props) => {
    const {
        continuousValidation = state.continuousValidation,
        defaultValues,
        schema,
        values: newValues = emptyObject,
    } = props;
    const { tabSchemas, values } = Object.entries(schema).reduce(
        (newState, [field, constraint]) => {
            let {
                formGroup: { multiple: multiGroup } = {},
                props: { defaultValue, multiple } = {},
                scheme: { props: { tab = 'default' } = {} } = {},
            } =
                // Functional constraints must be evaluated
                typeof constraint === 'function'
                    ? constraint(get(newValues, field, get(state.values, field)), newValues, field)
                    : constraint;

            const { tabSchemas, values } = newState;
            if (multiGroup || (multiple && !isArray(defaultValue))) {
                defaultValue = defaultValue ? castArray(defaultValue) : [];
            }

            set(tabSchemas, tab, get(tabSchemas, tab, {}));
            set(tabSchemas[tab], field, constraint);

            const newValue = get(
                newValues,
                field,
                get(state.values, field, get(defaultValues, field, defaultValue))
            );
            newValue !== undefined && set(values, field, newValue);
            return newState;
        },
        { tabSchemas: {}, values: { ...state.values } }
    );

    return {
        ...state,
        // Always reset errors/touched after init/reload
        errors: emptyObject,
        touched: emptyObject,
        tabSchemas,
        values,
        shouldTab: Object.keys(tabSchemas).length > 1,
        continuousValidation,
        constraints: schema,
    };
};

export default genericReducer(initialState, {
    [ActionTypes.reload]: initState,
    [ActionTypes.fieldBlur]: (state, field) => {
        const touched = { ...state.touched };
        set(touched, field, get(touched, field) && true);
        return {
            ...state,
            touched,
        };
    },
    [ActionTypes.fieldChange]: (state, { field, value, oldValue }) => {
        const values = { ...state.values };
        const touched = { ...state.touched };
        set(values, field, value);
        set(touched, field, value !== oldValue && true);
        return {
            ...state,
            values,
            touched,
            submitError: null,
        };
    },
    [ActionTypes.fieldRevert]: (state, { field, oldValue }) => {
        const values = { ...state.values };
        const touched = { ...state.touched };
        set(values, field, oldValue);
        unset(touched, field);
        return {
            ...state,
            values,
            touched,
            submitError: null,
        };
    },

    [ActionTypes.validate]: (state, errors) => {
        const hasErrors = errors && Object.keys(errors).length > 0;
        return {
            ...state,
            errors: hasErrors ? errors : emptyObject,
            valid: hasErrors ? false : true,
        };
    },

    [ActionTypes.submitBegin]: state => ({
        ...state,
        submitAttempted: true,
        submitError: null,
        submitting: true,
    }),

    [ActionTypes.submitFailure]: (state, submitError) => ({
        ...state,
        submitError,
        submitting: false,
    }),

    [ActionTypes.submitSuccess]: (state, response) => {
        const newValues = Object.entries(response || {})
            .filter(([key]) => state.values[key] !== undefined)
            .reduce((values, [key, value]) => ((values[key] = value), values), {});
        return {
            ...state,
            values: {
                ...state.values,
                ...newValues,
            },
            touched: emptyObject,
            submitAttempted: false,
            submitError: null,
            submitting: false,
            valid: true,
        };
    },
});
