import {
    types as ActionTypes,
    asyncEvents as AsyncEvents,
    statusEvents as StatusEvents,
} from './RequestDetails.actions';
import {
    CRUDDetailsReducers,
    errorReducer,
    genericReducer,
    loadingReducer,
} from '../../../common/helpers/ReduxHelpers';
import {
    default as activity,
    initialState as initialActivityState,
} from './activity/RequestActivity.reducer';
import {
    default as conversation,
    initialState as initialConversationState,
} from './conversation/RequestConversation.reducer';

import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import AuthenticatedPaths from '../../../routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { types as NavActionTypes } from '../../../common/state/nav/Nav.actions';
import { combineReducers } from 'redux';
import { matchPath } from 'react-router-dom';
import reduceReducers from 'reduce-reducers';
import schema from '../db/Request.schema';

export const initialState = {
    id: '',
    loading: false,
    error: null,
    statusLoading: false,
    statusError: null,
    activity: initialActivityState,
    conversation: initialConversationState,
};

export default reduceReducers(
    initialState,
    combineReducers({
        activity,
        conversation,
        statusLoading: loadingReducer(
            initialState.statusLoading,
            Object.keys(StatusEvents).map(key => ActionTypes[key]),
            schema
        ),
        statusError: errorReducer(
            initialState.statusError,
            Object.keys(StatusEvents).map(key => ActionTypes[key]),
            schema
        ),
        ...CRUDDetailsReducers(
            initialState,
            Object.keys(AsyncEvents).map(key => ActionTypes[key]),
            schema
        ),
    }),
    genericReducer(initialState, {
        [AuthActionTypes.logout]: () => initialState,
        [NavActionTypes.routeChanged]: (state, payload) => {
            const { location, action } = payload;
            const match = matchPath(
                location.pathname,
                `${AuthenticatedPaths.DSAR_REQUESTS}/:requestId`
            );
            const { params: { requestId } = {} } = match || {};
            if (action === 'LOAD' && match) {
                return {
                    ...state,
                    id: requestId,
                };
            }
            if (!match) {
                return initialState;
            }
            return state;
        },
    })
);
