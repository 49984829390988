import AuthActions from '/b2b/authentication/state/Auth.actions';
import AuthCustomerActions from '/b2b/authentication/state/customer/AuthCustomer.actions';
import { DEFAULT_LOCALE } from '/b2b/common/helpers/i18n';
import { fetchCustomerDetails as FetchCustomerDetailsService } from '/b2b/common/services/customer';
import { fetchLocale as FetchLocaleService } from '/b2b/common/services/locale';
import LocaleActions from '/b2b/common/state/locale/Locale.actions';
import NavActions from '/b2b/common/state/nav/Nav.actions';
import { signOut as SignOutService } from '/b2b/authentication/services';
import customerSchema from '/b2b/common/state/db/customer/Customer.schema';

export const signOut = () => dispatch => {
    SignOutService();
    dispatch(AuthActions.logout());
};

export const selectCustomer = customer => dispatch => {
    dispatch(NavActions.changeCustomer(customer));
    return Promise.resolve(customer);
};

export const fetchCustomerDetails = params => dispatch => {
    const { customerId } = params;
    const meta = { schema: customerSchema, id: customerId };
    dispatch(AuthCustomerActions.fetchDetailsBegin(meta, params));
    return FetchCustomerDetailsService()
        .then(details => {
            dispatch(AuthCustomerActions.fetchDetailsSuccess(meta, params, details));
            return details;
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthCustomerActions.fetchDetailsFailure(meta, params, message));
        });
};

export const selectLocale =
    (locale = DEFAULT_LOCALE) =>
    dispatch => {
        const params = { locale };
        dispatch(LocaleActions.fetchLocaleBegin(params));
        return FetchLocaleService(params)
            .then(response => dispatch(LocaleActions.fetchLocaleSuccess(params, response)))
            .catch(error => dispatch(LocaleActions.fetchLocaleFailure(params, error)));
    };
