import Component from './ForgotPassword.component';
import { connect } from 'react-redux';
import { forgotPassword } from './ForgotPassword.commands';
import fromState from '../../../common/state/selectors';

const mapStateToProps = state => {
    return {
        isLoading: fromState.Auth.isLoading(state),
        username: fromState.Auth.user.getId(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onForgotPassword: params => dispatch(forgotPassword(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
