import { FormControl, Select as MUISelect, MenuItem, OutlinedInput } from '@mui/material';
import React, { useCallback, useMemo } from 'react';

import Locale from '../Locale';
import PropTypes from 'prop-types';
import { languageCodeLookup } from '../../helpers/Localization';
import makeStyles from '@mui/styles/makeStyles';
import styles from './LocaleSelect.styles';
import { translate } from '../../helpers/i18n';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles(styles);

const LocaleSelect = props => {
    const {
        disabled = [],
        id,
        name,
        value: selectedValue,
        options,
        onChange,
        placeholder,
        ...remain
    } = props;
    const classes = useStyles(props);
    const uid = React.useRef(id || uuid());

    const handleChange = useCallback(
        event => {
            onChange && onChange(event.target.value);
        },
        [onChange]
    );

    const tryGetNativeLanguage = value => {
        const native = languageCodeLookup[`${value}`]?.native;
        return native ? `(${native})` : '';
    };

    const sortedLocales = options
        .map(value => ({
            value,
            label: translate(`languages.${value}.label`),
            native: tryGetNativeLanguage(value),
        }))
        .sort((a, b) => {
            const aLabel = `${a.label} ${a.native}`;
            const bLabel = `${b.label} ${b.native}`;
            return aLabel.localeCompare(bLabel);
        });
    const localeOptions = useMemo(
        () =>
            sortedLocales.map(locale => (
                <MenuItem
                    key={locale.value}
                    value={locale.value}
                    selected={locale.value === selectedValue}
                    disabled={disabled.includes(locale.value)}
                >
                    <Locale path={`languages.${locale.value}.label`} /> {locale.native}
                </MenuItem>
            )),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedValue, options.join(','), disabled.join(',')]
    );

    return (
        <FormControl classes={{ root: classes.formControl }} variant="outlined">
            <MUISelect
                input={<OutlinedInput name={name} />}
                inputProps={{
                    name,
                    id: uid.current,
                }}
                onChange={handleChange}
                value={selectedValue}
                displayEmpty={!!placeholder}
                {...remain}
            >
                {placeholder && (
                    <MenuItem value="" disabled selected={!selectedValue}>
                        <Locale path={placeholder} />
                    </MenuItem>
                )}
                {localeOptions}
            </MUISelect>
        </FormControl>
    );
};

LocaleSelect.propTypes = {
    disabled: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    value: PropTypes.string,
};

export default LocaleSelect;
