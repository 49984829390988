import { Button, ButtonGroup, FormLabel } from '@mui/material';
import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import castArray from 'lodash/castArray';
import makeStyles from '@mui/styles/makeStyles';
import startCase from 'lodash/startCase';
import styles from './ValidationButtonGroup.styles';
import { translate } from '/b2b/common/helpers/i18n';

const useStyles = makeStyles(styles);

const ValidationFormGroup = props => {
    const {
        disabled,
        fullWidth = false,
        label,
        options,
        value: selected,
        onChange,
        id = '',
        ...remain
    } = props;
    const classes = useStyles(props);
    const checked = castArray(selected);

    return (
        <Fragment>
            <FormLabel component="legend">{translate(label)}</FormLabel>
            <ButtonGroup size="small" fullWidth={fullWidth} classes={{ root: classes.root }}>
                {Object.entries(options).map(([val, option], index) => {
                    const label = translate(option.label || startCase(val));
                    const value = option.value !== undefined ? option.value : val;
                    const isChecked =
                        option.checked !== undefined ? !!option.checked : !!checked.includes(value);
                    return (
                        <Button
                            className={isChecked ? classes.checked : ''}
                            key={index}
                            id={`${id}--${index}`}
                            {...remain}
                            {...option}
                            onClick={() => onChange(value)}
                            color={isChecked ? 'primary' : 'tertiary'}
                            variant={isChecked ? 'contained' : undefined}
                            disabled={option.disabled || disabled}
                        >
                            {label}
                        </Button>
                    );
                })}
            </ButtonGroup>
        </Fragment>
    );
};

ValidationFormGroup.propTypes = {
    component: PropTypes.any,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.object,
    onChange: PropTypes.func,
};

export default ValidationFormGroup;
