/* eslint react/prop-types: 0 */
import React from 'react';
import { SvgIcon } from '@mui/material';

import { white } from '../../colors';

export default function GDPR(props) {
    return (
        <SvgIcon fontSize="large" viewBox="0 0 24 24" {...props}>
            <path
                d="M12,23c-6.3,0-11-2.4-11-5.5c0-1.6,1.3-3.1,3.6-4.1c0.5-0.2,1.1,0,1.3,0.5c0.2,0.5,0,1.1-0.5,1.3
                C3.9,15.9,3,16.7,3,17.5c0,1.7,3.8,3.5,9,3.5c5.2,0,9-1.8,9-3.5c0-0.8-0.9-1.6-2.4-2.3c-0.5-0.2-0.7-0.8-0.5-1.3
                c0.2-0.5,0.8-0.7,1.3-0.5c2.3,1,3.6,2.5,3.6,4.1C23,20.6,18.3,23,12,23z M12,18c-0.4,0-0.7-0.2-0.9-0.6c-0.5-1-1.4-1.9-2.4-2.9
                C7,12.9,5,10.9,5,8c0-3.9,3.1-7,7-7c3.9,0,7,3.1,7,7c0,2.9-2,4.9-3.7,6.6c-1,1-1.9,1.9-2.4,2.9C12.7,17.8,12.4,18,12,18z M12,3
                C9.2,3,7,5.2,7,8c0,2.1,1.5,3.6,3.1,5.2c0.7,0.6,1.3,1.3,1.9,2c0.6-0.7,1.2-1.4,1.9-2C15.5,11.6,17,10.1,17,8C17,5.2,14.8,3,12,3z
                M12,10c-1.1,0-2-0.9-2-2s0.9-2,2-2c1.1,0,2,0.9,2,2S13.1,10,12,10z M12,8L12,8L12,8z"
                fill={white}
            />
        </SvgIcon>
    );
}
