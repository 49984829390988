import { denormalize } from 'normalizr';
import get from 'lodash/get';
import { getStore as getDB } from '../../../common/state/db/DB.selectors';
import schema from './Legislation.schema';

export const DOMAIN = `db.${schema.key}`;

export const getStore = state => get(state, DOMAIN) || {};

/**
 * Returns a denormalized (hydrated) entity from the Redux ORM for the LegislationEntity corresponding to the provided `id`
 *
 * @param {Object} state The redux state
 * @param {string|number} id The id for the Legislation update
 * @returns {import('./Legislation.schema').LegislationEntity}
 */
export const getEntityById = (state, id) => denormalize(id, schema, getDB(state)) || {};
export const getEntitiesForIds = (state, ids) => denormalize(ids, [schema], getDB(state));

export const getEntityFieldById = (state, { id, field }) =>
    get(getStore(state), [id, field]) || null;
