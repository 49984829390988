import {
    ClickAwayListener,
    Divider,
    Grow,
    ListItem,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@mui/material';
import FeatureFlag, { On } from '/b2b/common/components/FeatureFlag';

import Locale from '/b2b/common/components/Locale';
import NavLinkListItem from '/b2b/common/components/NavLinkListItem';
import Permission from '/b2b/common/components/Permission';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { navMenuItems } from '../constants';
import styles from '../Sidebar.styles';

const useStyles = makeStyles(styles);

const SidebarPopperMenu = props => {
    const { anchorEl, onClose, title, rolePermissions } = props;
    const classes = useStyles();

    const handleClose = event => {
        if (anchorEl && anchorEl.contains(event.target)) {
            return;
        }

        onClose();
    };

    const menuListItem = page => {
        return (
            <ListItem
                key={page.path}
                activeClassName={classes.activeListItem}
                classes={{
                    root: classNames(
                        classes.listItem,
                        classes.submenuItem,
                        classes.flyoutSubmenuItem
                    ),
                }}
                component={NavLinkListItem}
                to={page.path}
                isActive={(match, location) =>
                    page.activePathExclusions
                        ? location.pathname.indexOf(`${page.path}`) === 0 &&
                          page.activePathExclusions?.every(
                              exclusion => location.pathname.indexOf(exclusion) !== 0
                          )
                        : location.pathname.indexOf(`${page.path}`) === 0
                }
                onClick={handleClose}
            >
                <ListItemText
                    classes={{
                        primary: classNames(
                            classes.submenuItemText,
                            classes.listItemText,
                            classes.flyoutSubmenuText
                        ),
                    }}
                    primary={<Locale path={page.title} />}
                />
            </ListItem>
        );
    };

    const featureFlagWrappedItem = (featureFlagProps, menuItem) => {
        return (
            <FeatureFlag {...featureFlagProps}>
                <On>{menuItem}</On>
            </FeatureFlag>
        );
    };

    const permissionWrappedItem = (permissionProps, menuItem) => {
        return <Permission {...permissionProps}>{menuItem}</Permission>;
    };

    return (
        <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="right-start" transition>
            {({ TransitionProps }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: 'left bottom',
                    }}
                >
                    <Paper sx={{ marginLeft: '14px' }}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="composition-menu" aria-labelledby="composition-button">
                                <MenuItem
                                    classes={{
                                        root: classNames(
                                            classes.flyoutSubmenuText,
                                            classes.flyoutSubmenuHeader
                                        ),
                                    }}
                                >
                                    <Locale path={title} />
                                </MenuItem>
                                <Divider component="li" variant="middle" />
                                {navMenuItems[title]?.pages.map(page => {
                                    const featureFlag = page.featureFlag;
                                    const featureFlagProps = {
                                        flag: featureFlag,
                                        key: page.path,
                                    };
                                    const pageRolePermissions = page.rolePermissions;
                                    const permission = page.permission;
                                    const permissionProps = permission
                                        ? {
                                              forRole: true,
                                              toPerform: permission.action,
                                              [permission.type]: permission.permissions,
                                              key: page.path,
                                          }
                                        : null;

                                    if (pageRolePermissions) {
                                        return (
                                            pageRolePermissions.some(rp => rolePermissions[rp]) &&
                                            (featureFlag
                                                ? featureFlagWrappedItem(
                                                      featureFlagProps,
                                                      menuListItem(page)
                                                  )
                                                : menuListItem(page))
                                        );
                                    } else if (permission) {
                                        return featureFlag
                                            ? featureFlagWrappedItem(
                                                  featureFlagProps,
                                                  permissionWrappedItem(
                                                      permissionProps,
                                                      menuListItem(page)
                                                  )
                                              )
                                            : permissionWrappedItem(
                                                  permissionProps,
                                                  menuListItem(page)
                                              );
                                    } else if (featureFlag) {
                                        return featureFlagWrappedItem(
                                            featureFlagProps,
                                            menuListItem(page)
                                        );
                                    } else {
                                        return menuListItem(page);
                                    }
                                })}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
};

SidebarPopperMenu.propTypes = {
    anchorEl: PropTypes.object,
    onClose: PropTypes.func,
    rolePermissions: PropTypes.object,
    title: PropTypes.string,
};

export default SidebarPopperMenu;
