import theme from '/b2b/common/theme';

export default () => ({
    container: {
        paddingTop: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(2),
    },
    gridContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    dividerContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
});
