import { types as ActionTypes, asyncEvents as AsyncEvents } from './NotificationList.actions';
import { genericReducer, resultsReducers } from '../../common/helpers/ReduxHelpers';

import { types as AuthActionTypes } from '../../authentication/state/Auth.actions';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import schema from './db/Notification.schema';

export const initialState = {
    results: [],
    resultCount: 0,
    error: null,
    loading: false,
};

export default reduceReducers(
    initialState,
    combineReducers({
        ...resultsReducers(
            initialState,
            Object.keys(AsyncEvents).map(key => ActionTypes[key]),
            schema
        ),
    }),
    genericReducer(initialState, {
        [AuthActionTypes.logout]: () => initialState,
    })
);
