import * as AuthCustomerActivity from './activity/AuthCustomerActivity.selectors';
import * as AuthCustomerConsentConfigs from './consentConfigs/AuthCustomerConsentConfigs.selectors';
import * as AuthCustomerDSAR from './dsar/AuthCustomerDSAR.selectors';
import * as AuthCustomerUsers from './users/AuthCustomerUsers.selectors';

import { DOMAIN } from './AuthCustomer.constants';
import castArray from 'lodash/castArray';
import { denormalize } from 'normalizr';
import get from 'lodash/get';
import { getStore as getDB } from '../../../common/state/db/DB.selectors';
import { isAllowedTo } from '../../../common/helpers/Permissions';
import schema from '../../../common/state/db/customer/Customer.schema';

export const activity = AuthCustomerActivity;
export const consentConfigs = AuthCustomerConsentConfigs;
export const dsar = AuthCustomerDSAR;
export const users = AuthCustomerUsers;

export const getStore = state => get(state, DOMAIN) || {};

export const getEntityById = (state, id) => denormalize(id, schema, getDB(state)) || {};
export const getEntitiesForIds = (state, ids) => denormalize(ids, [schema], getDB(state));

export const getError = state => getStore(state).error;
export const getId = state => getStore(state).id;
export const getSubscription = state => getStore(state).subscription;
/** @type {import('react-redux').Selector<Object,string>} */
export const getShortCode = state => getStore(state).shortCode || getId(state);
export const getBeaconId = state => getStore(state).beaconId;
export const getOrganizations = state =>
    get(getEntityById(state, getId(state)), 'organizations') || [];
export const getPermissions = state => getStore(state).permissions;
const getAllowedByPlan = (state, { toPerform: action, on: contentType }) =>
    getPermissions(state).allow[action].includes(contentType);
export const getCodeSplittingAllowedByPlan = state =>
    getAllowedByPlan(state, { toPerform: 'write', on: 'cmp_code_splitting' });
export const getRoles = state => getEntityById(state, getId(state)).roles || [];
export const getRootOrg = state =>
    getEntityById(state, getId(state)).organizations.find(({ isRoot }) => isRoot);
export const getRootOrgId = state => get(getRootOrg(state), 'orgId');
export const isLoading = state => getStore(state).loading || false;
export const canChat = state => getStore(state).canChat;
export const canEmail = state => getStore(state).canEmail;

export const hasPermission = (state, { toPerform: action, on: contentType, onAny: anyType }) => {
    const customer = getStore(state);
    if (anyType) {
        return castArray(anyType).some(type =>
            isAllowedTo([], customer, { [type]: { [action]: true } }, getPermissions(state))
        );
    }
    return isAllowedTo([], customer, { [contentType]: { [action]: true } }, getPermissions(state));
};
