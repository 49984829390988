import { types as ActionTypes } from './DateRangePicker.actions';
import { genericReducer } from '../../helpers/ReduxHelpers';
import moment from 'moment';

export const initialState = {
    clearState: undefined,
    maxDate: undefined,
    maxRangeDays: 30,
    minDate: undefined,
    open: false,
    picking: '',
    selectedMaxDate: undefined,
    selectedMinDate: undefined,
};

const pickingValues = ['min', 'max'];
const selectingPicking = picking =>
    picking && pickingValues.includes(picking.toLowerCase())
        ? picking.toLowerCase()
        : pickingValues[0];

export const initState = props => {
    const { minDate: providedMinDate, maxDate: providedMaxDate, maxRangeDays } = props;
    const maxDate = providedMaxDate ? parseInt(providedMaxDate, 10) : initialState.maxDate;
    const minDate = providedMinDate ? parseInt(providedMinDate, 10) : initialState.minDate;
    const clearState = {
        ...initialState,
        maxDate,
        minDate,
        selectedMaxDate: maxDate ? maxDate : initialState.selectedMaxDate,
        selectedMinDate: minDate ? minDate : initialState.selectedMinDate,
    };
    return {
        ...clearState,
        maxRangeDays,
        clearState,
    };
};

export default genericReducer(initialState, {
    [ActionTypes.pick]: (state, picking) => {
        return {
            ...state,
            open: true,
            picking: selectingPicking(picking),
        };
    },
    [ActionTypes.closeDialog]: state => {
        const { maxDate, minDate } = state;
        return {
            ...state,
            open: false,
            picking: '',
            selectedMaxDate: maxDate ? maxDate : initialState.selectedMaxDate,
            selectedMinDate: minDate ? minDate : initialState.selectedMinDate,
        };
    },
    [ActionTypes.openDialog]: state => {
        return {
            ...state,
            open: true,
        };
    },
    [ActionTypes.change]: (state, day) => {
        if (day === null) {
            // Clear
            const { maxDate, minDate } = state.clearState;
            const newState = {
                ...state,
                picking: initialState.picking,
                maxDate,
                minDate,
                selectedMaxDate: maxDate ? maxDate : undefined,
                selectedMinDate: minDate ? minDate : undefined,
            };
            return newState;
        }
        const now = moment();
        const { picking, maxRangeDays, maxDate, minDate, selectedMaxDate, selectedMinDate } = state;
        if (picking === 'min') {
            // Min changed
            const clampedMaxDate = now.isBefore(day.clone().add(maxRangeDays, 'days'))
                ? now
                : day.clone().add(maxRangeDays, 'days');
            if (
                // We have a max day clamp
                maxRangeDays &&
                // We already had a max day selected
                selectedMaxDate &&
                // The selected max day is after the clamped max day
                clampedMaxDate.isBefore(selectedMaxDate, 'day')
            ) {
                return {
                    ...state,
                    selectedMaxDate: clampedMaxDate.valueOf(),
                    selectedMinDate: day.valueOf(),
                };
            } else {
                return {
                    ...state,
                    picking: !maxDate ? 'max' : state.picking,
                    selectedMaxDate: selectedMaxDate || day.valueOf(),
                    selectedMinDate: day.valueOf(),
                };
            }
        } else if (picking === 'max') {
            // Max changed
            const clampedMinDate = day.clone().subtract(maxRangeDays, 'days');
            if (
                // We have a max day clamp
                maxRangeDays &&
                // We already had a min day selected
                selectedMinDate &&
                // The selected min day is before the new max day - max day range
                clampedMinDate.isAfter(selectedMinDate, 'day')
            ) {
                return {
                    ...state,
                    selectedMaxDate: day.valueOf(),
                    selectedMinDate: clampedMinDate.valueOf(),
                };
            } else {
                return {
                    ...state,
                    picking: !minDate ? 'min' : state.picking,
                    selectedMaxDate: day.valueOf(),
                    selectedMinDate: selectedMinDate || day.valueOf(),
                };
            }
        }
        return state;
    },
    [ActionTypes.reset]: state => {
        return {
            ...state,
            maxDate: initialState.maxDate,
            minDate: initialState.minDate,
            open: initialState.open,
            picking: initialState.picking,
            selectedMaxDate: initialState.selectedMaxDate,
            selectedMinDate: initialState.selectedMinDate,
        };
    },
    [ActionTypes.cancel]: state => {
        const { maxDate, minDate } = state;
        return {
            ...state,
            open: false,
            picking: initialState.picking,
            selectedMaxDate: maxDate,
            selectedMinDate: minDate,
        };
    },
    [ActionTypes.accept]: (state, [selectedMinDate, selectedMaxDate]) => {
        return {
            ...state,
            open: false,
            picking: initialState.picking,
            maxDate: selectedMaxDate ? selectedMaxDate : initialState.maxDate,
            minDate: selectedMinDate ? selectedMinDate : initialState.minDate,
            selectedMaxDate,
            selectedMinDate,
        };
    },
});
