import {
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';

import DateRangeIcon from '@mui/icons-material/DateRange';
import Divider from '/b2b/common/components/Divider';
import Locale from '../../../Locale';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import styles from './DateRangePickerInput.styles';

const InputLabelProps = {
    shrink: true,
};

const useStyles = makeStyles(styles);

const DateRangePickerInput = props => {
    const { format, onPick, startProps, endProps, title } = props;
    const { label: startText, value: startValue, placeholder: startPlaceholder = '─' } = startProps;
    const { label: endText, value: endValue, placeholder: endPlaceholder = '─' } = endProps;

    const handleClickMin = useCallback(() => onPick('min'), [onPick]);
    const handleClickMax = useCallback(() => onPick('max'), [onPick]);
    const startLabel = useMemo(() => <Locale path={startText} />, [startText]);
    const endLabel = useMemo(() => <Locale path={endText} />, [endText]);

    const minString = startValue == null ? startPlaceholder : moment(startValue).format(format);
    const maxString = endValue == null ? endPlaceholder : moment(endValue).format(format);

    const classes = useStyles(props);

    return (
        <Grid container spacing={4} className={classes.container}>
            <Typography variant="h6" className={classes.title}>
                <Locale path={title} />
            </Typography>
            <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={5.5}>
                    <FormControl fullWidth>
                        <TextField
                            {...startProps}
                            inputProps={{
                                ...startProps.inputProps,
                                onClick: handleClickMin,
                                value: minString,
                            }}
                            label={startLabel}
                            InputLabelProps={{ ...startProps.InputLabelProps, ...InputLabelProps }}
                            InputProps={{
                                ...startProps.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={startProps.disabled}
                                            size="large"
                                            onClick={handleClickMin}
                                        >
                                            <DateRangeIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={1} className={classes.dividerContainer}>
                    <Divider />
                </Grid>
                <Grid item xs={5.5}>
                    <FormControl fullWidth>
                        <TextField
                            {...endProps}
                            inputProps={{
                                ...endProps.inputProps,
                                onClick: handleClickMax,
                                value: maxString,
                            }}
                            label={endLabel}
                            InputLabelProps={{ ...endProps.InputLabelProps, ...InputLabelProps }}
                            InputProps={{
                                ...endProps.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={endProps.disabled}
                                            size="large"
                                            onClick={handleClickMax}
                                        >
                                            <DateRangeIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    );
};

DateRangePickerInput.propTypes = {
    format: PropTypes.string,
    onPick: PropTypes.func.isRequired,
    endProps: PropTypes.object,
    startProps: PropTypes.object,
    title: PropTypes.string,
};

export default DateRangePickerInput;
