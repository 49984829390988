import { Button, CircularProgress } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Locale from '/b2b/common/components/Locale';
import Portlet from '/b2b/common/components/Portlet';
import PortletContent from '/b2b/common/components/PortletContent';
import PortletFooter from '/b2b/common/components/PortletFooter';
import PropTypes from 'prop-types';
import ValidationForm from '/b2b/common/components/ValidationForm';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { notificationDefaults } from './Notifications.schema';
import styles from './Notifications.styles';

const useStyles = makeStyles(styles);

const Notifications = props => {
    const {
        className,
        helperText,
        isLoading,
        onUpdateUserNotifications,
        onInitialLoad,
        userId,
        notificationSettings,
        schema,
    } = props;
    const classes = useStyles(props);
    const handleSubmit = useRef(onUpdateUserNotifications);
    const [isValid, setIsValid] = useState(false);
    const [canSubmit, setCanSubmit] = useState(isValid);

    const handleValidate = useCallback(validation => {
        setCanSubmit(validation === undefined);
        setIsValid(!validation);
    }, []);

    const handleFieldChange = useCallback(
        (field, value, oldValue) => {
            handleValidate(isValid || value !== oldValue);
        },
        [handleValidate, isValid]
    );

    const handleSettingsSubmit = useCallback(() => {
        return handleSubmit.current && handleSubmit.current();
    }, []);

    useEffect(() => {
        userId && onInitialLoad(userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);
    const rootClassName = classNames(classes.root, className);
    return (
        <Portlet className={rootClassName}>
            <PortletContent>
                <ValidationForm
                    className={classes.form}
                    title={'common.notifications'}
                    subtitle={'notifications.manageYourNotificationPreferences'}
                    submitButton={({ onClick }) => {
                        handleSubmit.current = onClick;
                        return null;
                    }}
                    schema={schema}
                    onSubmit={onUpdateUserNotifications}
                    onFieldChange={handleFieldChange}
                    onValidate={handleValidate}
                    defaultValues={notificationDefaults}
                    values={notificationSettings}
                    helperText={helperText}
                />
            </PortletContent>
            <PortletFooter className={classes.portletFooter}>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!isValid || !canSubmit}
                    onClick={handleSettingsSubmit}
                >
                    <Locale path="common.update" />
                </Button>
            </PortletFooter>
            {isLoading && (
                <div className={classes.loadingWrapper}>
                    <CircularProgress />
                </div>
            )}
        </Portlet>
    );
};

Notifications.propTypes = {
    className: PropTypes.string,
    handleSettingsSubmit: PropTypes.func,
    helperText: PropTypes.object,
    isLoading: PropTypes.bool,
    onInitialLoad: PropTypes.func,
    onUpdateUserNotifications: PropTypes.func,
    notificationSettings: PropTypes.object,
    userId: PropTypes.string,
    schema: PropTypes.object,
};

export default Notifications;
