import { DOMAIN } from './Rules.constants';
import get from 'lodash/get';
import { getEntityById } from '../db/rule/Rule.selectors';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

const omitNil = obj => omitBy(obj, val => isNil(val));

export const getStore = state => get(state, DOMAIN) || {};

export const getResults = state => getStore(state).results || [];
export const getResultCount = state => getStore(state).resultCount || 0;
export const getError = state => getStore(state).error;
export const getErrors = state => getStore(state).errors || [];
export const getChanges = state => getStore(state).changes || {};
export const getChangesById = (state, id) => get(getChanges(state), id, {});
export const getErrorsById = (state, id) => get(getChangesById(state, id), 'errors');
export const getSaving = state => getStore(state).saving || [];
export const getDeleting = state => getStore(state).deleting || [];

export const getRequestFilters = state => getStore(state).filters || {};
export const hasFilters = state =>
    Object.values(getRequestFilters(state)).reduce(
        (hasFilters, filters) => hasFilters || filters.length,
        false
    );
export const hasNextPage = state => getStore(state).nextPage;

export const getRequestParams = state => {
    const { offset, limit, pageNumber } = getStore(state);
    return {
        offset,
        limit,
        pageNumber,
    };
};

export const hasUnsavedChanges = (state, id) =>
    !!Object.keys(id ? getChangesById(state, id) : getChanges(state)).length;
export const hasErrors = (state, id) =>
    id ? getErrors(state).includes(id) : !!getErrors(state).length;
export const isDeleting = (state, id) =>
    !!getDeleting(state).filter(ruleId => !id || id === ruleId).length;
export const isLoading = state => getStore(state).loading;
export const isSaving = (state, id) =>
    !!getSaving(state).filter(ruleId => !id || id === ruleId).length;

export const getPayload = (state, id) => {
    const {
        classification,
        description,
        disclosure = false,
        expiry,
        patternType,
        rule,
        vendorId: originalVendorId,
        vendorName: originalVendorName,
    } = getEntityById(state, id);
    const {
        // We don't want to submit errors
        // eslint-disable-next-line no-unused-vars
        errors,
        vendorId = originalVendorId,
        vendorName = originalVendorName,
        ...changes
    } = getChangesById(state, id);
    return omitNil({
        classification,
        description,
        disclosure,
        expiry,
        patternType,
        rule,
        storeKey: id,
        ...changes,
        vendorId: vendorId === vendorName ? null : vendorId,
        vendorName,
    });
};
