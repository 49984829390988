import * as featureBlocks from '../helpers/featureBlocks';

import {
    ASSESSMENTS_PATHS,
    DATA_DISCOVERY_PATHS,
    DATA_MAPPING_PATHS,
    DSAR_REQUEST_PATHS,
    PERMISSIONS,
    REPORTING_PATHS,
    ROLES,
    UI_VIEW_PREFIX,
    UNIFIED_CONSENT_PATHS,
} from '../../authentication/constants';
/* eslint-disable camelcase */
import { formatPaging, makeResultsFormatter } from './helpers';

import AuthenticatedPaths from '../../routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import castArray from 'lodash/castArray';
import { formatOrganizations } from '../../organizations/format';
import { formatUser } from '/b2b/users/format';
import { getInitials } from '../helpers/String';
import identity from 'lodash/identity';
import kebabCase from 'lodash/kebabCase';
import snakeCase from 'lodash/snakeCase';
import uniq from 'lodash/uniq';

export const formatResults = makeResultsFormatter();

export const assignCustomer = customerId => details => ({
    ...details,
    customer: customerId,
});

export const assignConversation = conversationId => details => ({
    ...details,
    conversation: conversationId,
});

export const assignConsentConfig = ccId => details => ({
    ...details,
    consentConfig: ccId,
});

export const assignLitigation = litigationId => details => ({
    ...details,
    litigation: litigationId,
});

export const assignSubprocessor =
    ({ productId, vendorId }) =>
    details => ({
        productId,
        vendorId,
        subprocessors: [details],
    });

export const allowedPermissions = allowedOrSection => {
    if (typeof allowedOrSection === 'boolean') {
        return allowedOrSection ? ['read', 'write', 'delete'] : [];
    } else if (allowedOrSection === 'string') {
        return allowedOrSection.toLowerCase().split(',');
    }

    return castArray(allowedOrSection)
        .filter(val => !!val)
        .map(val => val.toString().toLowerCase());
};

export const extendPermissions = (newPermissions, existingPermissions = {}) => {
    const pathPermissions = { ...AuthenticatedPaths };
    const explicitPermissions = Object.entries(newPermissions || {}).reduce(
        (perms, [sectionOrIndex, allowedOrSection]) => {
            switch (sectionOrIndex) {
                case 'assessment': {
                    // The assessment permission should grant path access
                    allowedOrSection === false
                        ? perms.deny.view.push(...ASSESSMENTS_PATHS)
                        : perms.allow.view.push(...ASSESSMENTS_PATHS);
                    break;
                }

                case 'dsar': {
                    const associatedPaths = DSAR_REQUEST_PATHS;
                    allowedOrSection === false
                        ? perms.deny.view.push(...associatedPaths)
                        : perms.allow.view.push(...associatedPaths);
                    break;
                }

                case 'gdpr': {
                    const associatedPaths = [AuthenticatedPaths.GDPR];
                    allowedOrSection === false
                        ? perms.deny.view.push(...associatedPaths)
                        : perms.allow.view.push(...associatedPaths);
                    break;
                }

                case 'organizations': {
                    const associatedPaths = [AuthenticatedPaths.ORGANIZATIONS];
                    const allow = allowedPermissions(allowedOrSection);
                    const permissionName = snakeCase(`${sectionOrIndex}`).toLowerCase();

                    allow.forEach(
                        perm => perms.allow[perm] && perms.allow[perm].push(permissionName)
                    );
                    allow
                        ? perms.allow.view.push(...associatedPaths)
                        : perms.deny.view.push(...associatedPaths);
                    break;
                }

                case 'dataDiscovery': {
                    const associatedPaths = DATA_DISCOVERY_PATHS;
                    // subject rights customers should still get data discovery access
                    allowedOrSection === false && !newPermissions.dsar
                        ? perms.deny.view.push(...associatedPaths)
                        : perms.allow.view.push(...associatedPaths);
                    break;
                }

                case 'dataMapping': {
                    const associatedPaths = DATA_MAPPING_PATHS;
                    allowedOrSection === false
                        ? perms.deny.view.push(...associatedPaths)
                        : perms.allow.view.push(...associatedPaths);
                    break;
                }

                case 'universalConsent': {
                    const associatedPaths = UNIFIED_CONSENT_PATHS;

                    if (allowedOrSection === true) {
                        perms.allow.view.push(...associatedPaths);
                    } else {
                        perms.deny.view.push(...associatedPaths);
                    }
                    break;
                }

                case 'reporting': {
                    /*
                        reporting is a platform feature, but you should only be
                        able to see the reports for the modules you have paid for
                    */
                    allowedOrSection === true
                        ? perms.allow.view.push(AuthenticatedPaths.REPORTS)
                        : perms.deny.view.push(REPORTING_PATHS);

                    allowedOrSection === true && newPermissions.consentManager
                        ? perms.allow.view.push(AuthenticatedPaths.REPORTS_CONSENT)
                        : perms.deny.view.push(AuthenticatedPaths.REPORTS_CONSENT);

                    allowedOrSection === true && newPermissions.dsar
                        ? perms.allow.view.push(AuthenticatedPaths.REPORTS_DATASUBJECT)
                        : perms.deny.view.push(AuthenticatedPaths.REPORTS_DATASUBJECT);

                    allowedOrSection === true && newPermissions.vendorManagement
                        ? perms.allow.view.push(AuthenticatedPaths.REPORTS_VENDOR)
                        : perms.deny.view.push(AuthenticatedPaths.REPORTS_VENDOR);
                    break;
                }

                default: {
                    if (`${sectionOrIndex}`.indexOf(UI_VIEW_PREFIX) === 0) {
                        sectionOrIndex = `${sectionOrIndex}`.split(UI_VIEW_PREFIX)[1];
                    }
                    if (AuthenticatedPaths[snakeCase(`${sectionOrIndex}`).toUpperCase()]) {
                        // It's an actual path
                        const path =
                            AuthenticatedPaths[snakeCase(`${sectionOrIndex}`).toUpperCase()];
                        delete pathPermissions[path];
                        if (allowedOrSection === false) {
                            perms.deny.view.push(path);
                        } else {
                            perms.allow.view.push(path);
                        }
                    } else {
                        // Some other permission
                        const allow = allowedPermissions(allowedOrSection);

                        const permissionName = snakeCase(`${sectionOrIndex}`).toLowerCase();
                        allow.forEach(
                            perm => perms.allow[perm] && perms.allow[perm].push(permissionName)
                        );
                    }
                }
            }
            return perms;
        },
        {
            ...existingPermissions,
            allow: {
                view: [...(existingPermissions?.allow?.view || [])],
                read: [...(existingPermissions?.allow?.read || [])],
                write: [...(existingPermissions?.allow?.write || [])],
                delete: [...(existingPermissions?.allow?.delete || [])],
            },
            deny: {
                view: [...(existingPermissions?.deny?.view || [])],
                read: [...(existingPermissions?.deny?.read || [])],
                write: [...(existingPermissions?.deny?.write || [])],
                delete: [...(existingPermissions?.deny?.delete || [])],
            },
        }
    );

    return explicitPermissions;
};

export const formatRoles = (roles, customerId) =>
    roles
        .filter(({ role } = {}) => role && role !== ROLES.PURCHASING)
        .map(roleEntity => {
            const { permissions, role } = roleEntity || {};
            if (customerId) {
                roleEntity.roleId = roleEntity.id = `${kebabCase(role)}-${customerId}`;
            }
            if (permissions) {
                roleEntity.permissions = uniq(
                    (permissions || []).map(permission => permission?.permission || permission)
                ).filter(permission => permission !== PERMISSIONS.ALL);
            }
            return roleEntity;
        });

export const formatUIAllowedList = uiAllowedList => {
    // Filter and rename conflicting uiAllowedList routes
    ['organizations'].forEach(viewPermission => {
        const canView = uiAllowedList[`${viewPermission}`];
        delete uiAllowedList[`${viewPermission}`];
        uiAllowedList[`${UI_VIEW_PREFIX}${viewPermission}`] = canView;
    });

    // Add a `/vendors` route for customers with the `vendorManagement` permission
    if (uiAllowedList.vendorManagement) {
        uiAllowedList[`${UI_VIEW_PREFIX}vendors`] = true;
    }

    // all dataMapping customers should get dataDiscovery access
    if (uiAllowedList.dataMapping) {
        uiAllowedList.dataDiscovery = true;
    }

    return uiAllowedList;
};

export const formatCustomer = details => {
    const {
        name,
        id,
        customer_id: customerId,
        customer_name: customerName,
        uiAllowedList = {},
        orgIds = [],
        organizations = orgIds,
        permissions,
        roles,
        ...remain
    } = details;

    const initials = getInitials(name || customerName);
    const newOrganizations = formatOrganizations(organizations);
    const newPermissions = extendPermissions(
        {
            ...featureBlocks.uiAllowedList,
            ...formatUIAllowedList(uiAllowedList),
        },
        permissions
    );
    const newRoles = formatRoles(roles, id || customerId);

    return {
        ...remain,
        customerId: id || customerId,
        name: name || customerName,
        initials,
        organizations: newOrganizations,
        permissions: newPermissions,
        roles: newRoles,
        customerPlan: {
            ...uiAllowedList,
        },
    };
};
export const formatCustomerResults = makeResultsFormatter(formatCustomer);

export const formatAudit = data => {
    let { id, auditId = `audit_${id}`, created, updated, type = 'update', ...remain } = data;
    if (!updated) {
        type = 'new';
        updated = created;
    }
    return {
        ...remain,
        auditId,
        created,
        updated,
        type,
    };
};
export const formatAuditResults = makeResultsFormatter(formatAudit);

export const formatAuditEvent = data => {
    const {
        additional: auditEventData,
        audit_event_type: auditEventType,
        audit_log_id: auditLogId,
        audit_time: auditTime,
        audit_user: auditUser,
        description: auditDescription,
        name: auditName,
        product: auditProduct,
        resource_id: auditResourceId,
        resource_type: auditResourceType,
        resources: auditResources,
    } = data;

    return {
        auditEventData: {
            ...(auditEventData || {}),
            description: auditDescription,
            name: auditName,
        },
        auditEventType,
        auditLogId,
        auditTime,
        auditUser: auditUser === 'AUTOMATION' ? 'common.automation' : auditUser,
        auditProduct,
        auditResourceId,
        auditResourceType,
        auditResources: auditResources || [],
    };
};
export const formatAuditEventEventResults = makeResultsFormatter(formatAuditEvent);

export const formatDomain = identity;
export const formatDomainResults = makeResultsFormatter(formatDomain);

export const formatFollowing = result => ({ ...result, following: true });
export const formatFollowingResults = makeResultsFormatter(formatFollowing);

export const formatConversationThread = thread => {
    const { createdBy, _embedded, ...remain } = thread;
    const { attachments = [] } = _embedded;
    const { image /* eslint-disable-line no-unused-vars */, ...userData } = createdBy;
    const user = formatUser(userData);
    return {
        ...remain,
        attachments,
        createdBy,
        user: {
            ...user,
            id: user.email,
        },
    };
};
export const formatConversation = conversation => {
    const {
        subject,
        createdBy,
        customFields,
        threads: threadCount,
        _embedded,
        ...remain
    } = conversation;
    const { image /* eslint-disable-line no-unused-vars */, ...userData } = createdBy;
    const user = formatUser(userData);
    const { threads = [] } = _embedded;

    const customerId = customFields.filter(item => item.name === 'customerId')[0] || {};

    return {
        ...remain,
        subject: subject.length > 50 ? subject.slice(0, 49) : subject,
        createdBy: {
            ...createdBy,
            image,
        },
        customerId: customerId.value,
        threadCount,
        threads: threads.map(formatConversationThread),
        user: {
            ...user,
            id: user.email,
        },
    };
};
export const formatConversationResults = makeResultsFormatter(formatConversation);
export const formatConversationThreadResults = makeResultsFormatter(formatConversation);

export const extractConversationThreads = response => {
    const conversation = formatConversation(response);
    const { threadCount, customFields = [], threads = [] } = conversation;

    const customerId = customFields.filter(item => item.name === 'customerId')[0] || {};

    return {
        count: threadCount,
        results: threads.map(thread =>
            assignCustomer(customerId.value)(assignConversation(conversation)(thread))
        ),
        ...formatPaging({
            total: threadCount,
            pageNumber: 1,
        }),
    };
};
