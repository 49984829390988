import {
    contrastRgb,
    hslToRgb,
    hueFromString,
    intToHexString,
    parseColor,
} from '../../../common/helpers/Color';
import {
    defaultIdAttribute,
    defaultMergeStrategy,
    defaultProcessStrategy,
} from '../../../common/helpers/Normalizr';

import { ROLES } from '../../../authentication/constants';
import { schema } from 'normalizr';

export const entityName = 'organizations';
export const idAttribute = 'orgId';

export const mergeStrategy = defaultMergeStrategy(idAttribute);
export const processStrategy = defaultProcessStrategy(
    idAttribute,
    function (details, parent, parentKey) {
        const { name, parentOrgId, customer, ...org } = details;
        if (name) {
            const rgb = hslToRgb(hueFromString(`${name}`) / 360, 0.6, 0.9);
            const { color = intToHexString.apply(null, rgb) } = details;
            org.isRoot = name === ROLES.ALL || parentOrgId === null;
            org.name = name;
            // Custom Color
            org.textColor =
                color && contrastRgb.apply(null, parseColor(color)) ? '#ffffff' : '#000000';
            org.color = color && `#${intToHexString.apply(null, parseColor(color))}`;
        }
        if (parentOrgId) {
            org.parentOrgId = parentOrgId;
        }
        if (customer && typeof customer !== 'object') {
            org.customer = { id: customer };
        } else {
            org.customer = customer;
        }
        if (parentKey !== null && parentKey === entityName && org.isRoot) {
            // Only set the root org if the current org is the root
            parent.isRootOrgMember = true;
        }
        return org;
    }
);

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
