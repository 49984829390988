import { Box, CircularProgress } from '@mui/material';

import Paper from '../Paper';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

const styles = theme => ({
    root: {
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
    },
    loadingWrapper: {
        alignItems: 'flex-start',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: theme.zIndex.loadingWrapper,
        '&::before': {
            backgroundColor: 'rgba(255,255,255,0.9)',
            borderRadius: theme.spacing(1),
            bottom: '1px',
            content: '""',
            left: '1px',
            position: 'absolute',
            right: '1px',
            top: '1px',
        },
    },
    progress: {
        position: 'sticky',
        top: `calc(50vh - ${theme.height.topBar} - 20px)`,
        margin: `calc(25% - 20px) 0`,
    },
});

const useStyles = makeStyles(styles);

const Portlet = props => {
    const { BoxProps = {}, className, children, loading } = props;
    const classes = useStyles(props);
    const rootClassName = classNames(classes.root, className);
    return (
        <Box {...BoxProps} sx={{ position: 'relative', ...BoxProps.sx }}>
            <Paper className={rootClassName} elevation={1} outlined squared={false}>
                {children}
            </Paper>
            {loading && (
                <Box className={classes.loadingWrapper}>
                    <CircularProgress className={classes.progress} />
                </Box>
            )}
        </Box>
    );
};

Portlet.propTypes = {
    BoxProps: PropTypes.object,
    children: PropTypes.node,
    className: PropTypes.string,
    loading: PropTypes.bool,
};

export default Portlet;
