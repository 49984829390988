import { DOMAIN } from './RequestList.constants';
import get from 'lodash/get';

export const getStore = state => get(state, DOMAIN) || {};

export const getError = state => getStore(state).error;
export const getColumnOrder = state => getStore(state).columnOrder || [];
export const hasColumnOrder = state =>
    Object.values(getColumnOrder(state)).reduce(
        (hasColumnOrder, columnOrder) => hasColumnOrder || columnOrder.length > 0,
        false
    );
export const getColumnVisibility = state => getStore(state).columnVisibility || {};
export const hasColumnVisibility = state =>
    Object.values(getColumnVisibility(state)).reduce(
        (hasColumnVisibility, columnVisibility) =>
            hasColumnVisibility || Object.keys(columnVisibility).length > 0,
        false
    );

export const isLoading = state => getStore(state).loading;
