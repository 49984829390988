import {
    buildActionCreators,
    makeActionTypes,
    namedParameters,
    noParameters,
    singleParameter,
} from '../../helpers/ReduxHelpers';

export const events = {
    reload: namedParameters('values', 'schema', 'defaultValues'),
    fieldBlur: singleParameter,
    fieldChange: namedParameters('field', 'value', 'oldValue'),
    fieldRevert: namedParameters('field', 'oldValue'),
    validate: singleParameter,
    submitBegin: noParameters,
    submitFailure: singleParameter,
    submitSuccess: singleParameter,
};

export const types = makeActionTypes(Object.keys(events), '@@Components__ValidationForm');

export default buildActionCreators(events, types);
