import {
    defaultIdAttribute,
    defaultMergeStrategy,
    defaultProcessStrategy,
} from '../../../common/helpers/Normalizr';

import { schema } from 'normalizr';

export const entityName = 'dsarActionItem';
export const idAttribute = 'dsarActionItemId';

export const mergeStrategy = defaultMergeStrategy(idAttribute);
export const processStrategy = defaultProcessStrategy(idAttribute);

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
