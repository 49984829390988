import { types as ActionTypes, asyncEvents as AsyncEvents } from './AssessmentsReporting.actions';
import {
    errorReducer,
    genericReducer,
    loadingReducer,
    passthroughReducer,
} from '/b2b/common/helpers/ReduxHelpers';

import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import { combineReducers } from 'redux';
import moment from 'moment';
import reduceReducers from 'reduce-reducers';

export const initialState = {
    error: null,
    filters: {
        dateRange: [
            moment().subtract(6, 'months').startOf('day').valueOf(),
            moment().endOf('day').valueOf(),
        ],
        limit: 5,
    },
    loading: false,
};

export default reduceReducers(
    initialState,
    combineReducers({
        filters: passthroughReducer(initialState.filters),
        loading: loadingReducer(
            initialState.loading,
            Object.keys(AsyncEvents).map(key => ActionTypes[key])
        ),
        error: errorReducer(
            initialState.error,
            Object.keys(AsyncEvents).map(key => ActionTypes[key])
        ),
    }),
    genericReducer(initialState, {
        [ActionTypes.setFilters]: (state, filters) => ({
            ...state,
            filters: {
                ...state.filters,
                ...filters,
            },
        }),
        [AuthActionTypes.logout]: () => initialState,
    })
);
