import AuthActions from '../authentication/state/Auth.actions';
import AuthCustomerActions from '../authentication/state/customer/AuthCustomer.actions';
import { fetchCustomerDetails as FetchCustomerDetailsService } from '../common/services/customer';
import { signOut as SignOutService } from '../authentication/services';
import UserpilotActions from '../common/state/userpilot/Userpilot.actions';
import schema from '../common/state/db/customer/Customer.schema';

export const fetchCustomerDetails = customerId => dispatch => {
    const meta = { schema, id: customerId };
    dispatch(AuthCustomerActions.fetchDetailsBegin(meta, customerId));
    return FetchCustomerDetailsService()
        .then(customers => {
            dispatch(AuthCustomerActions.fetchDetailsSuccess(meta, customerId, customers));
            return customers;
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthCustomerActions.fetchDetailsFailure(meta, customerId, message));
        });
};

export const signOut = () => dispatch => {
    SignOutService();
    dispatch(AuthActions.logout());
};

export const triggerUserPilot = id => dispatch =>
    dispatch(UserpilotActions.setUserpilotContentId(id));
