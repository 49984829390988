import AuthUserActions from '../../authentication/state/user/AuthUser.actions';
import { fetchUserDetails as FetchUserDetailsService } from '../../users/services';
import schema from '../../users/state/db/User.schema';

export const fetchUserDetails = userId => dispatch => {
    const params = { userId };
    const meta = { schema };
    dispatch(AuthUserActions.fetchDetailsBegin(meta, params));
    return FetchUserDetailsService()
        .then(details => {
            dispatch(AuthUserActions.fetchDetailsSuccess(meta, params, details));
            return details;
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthUserActions.fetchDetailsFailure(meta, params, message));
        });
};
