import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Slide,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useRef } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import Error from '../Error';
import Locale from '../Locale';
import PropTypes from 'prop-types';
import SubmitButton from '../SubmitButton';
import makeStyles from '@mui/styles/makeStyles';
import styles from './ResponsiveDialog.styles';
import { useTheme } from '@mui/material/styles';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles(styles, { name: 'ResponsiveDialog' });

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ResponsiveDialog = props => {
    const {
        title,
        disclaimer,
        children,
        classes: providedClasses,
        actions,
        error,
        formId,
        isConfirmDisabled,
        isLoading,
        loadingText,
        backAction,
        onBack,
        backText = 'common.back',
        onConfirm,
        confirmTrackEvent,
        confirmText = 'Submit',
        confirmButtonProps,
        onClose,
        onCancel,
        cancelTrackEvent,
        cancelText = 'common.close',
        open,
        showCloseButton,
        translationOptions,
        delegateScrollingToChildren,
        confirmVariant,
        container,
        contentBackgroundColor,
        ...remain
    } = props;
    const classes = { ...useStyles({ contentBackgroundColor }), ...providedClasses };
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const id = useRef(uuid());

    return (
        <Dialog
            {...remain}
            classes={providedClasses}
            container={container?.current}
            open={!!open}
            onClose={onClose}
            fullScreen={fullScreen}
            aria-labelledby={id.current}
            TransitionComponent={Transition}
        >
            <DialogTitle id={id.current} className={classes.title}>
                {typeof title === 'string' ? (
                    <Typography component="p" variant="h4">
                        <Locale path={title} options={translationOptions} />
                    </Typography>
                ) : (
                    title
                )}
                {onClose && (fullScreen || showCloseButton) ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                        size="small"
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent
                className={classes.content}
                classes={{
                    root: isLoading
                        ? classes.noScroll
                        : delegateScrollingToChildren
                          ? classes.delegateScroll
                          : undefined,
                }}
            >
                {typeof children === 'string' ? (
                    <Locale path={children} options={translationOptions} />
                ) : (
                    children
                )}
            </DialogContent>
            {onBack || onCancel || onConfirm || (actions && actions.length) ? (
                <DialogActions className={classes.actions}>
                    {backAction ? (
                        backAction
                    ) : onBack ? (
                        <Button
                            key="backButton"
                            onClick={onBack}
                            disabled={isLoading}
                            color="tertiary"
                            variant="text"
                            startIcon={<ArrowBackIcon />}
                        >
                            <Locale path={backText} options={translationOptions} />
                        </Button>
                    ) : null}
                    {onCancel || onConfirm || (actions && actions.length) ? (
                        <div className={classes.defaultActions}>
                            {onCancel && (
                                <Button
                                    key="cancelButton"
                                    onClick={onCancel}
                                    className={classes.cancelButton}
                                    disabled={isLoading}
                                    data-track={cancelTrackEvent}
                                >
                                    <Locale path={cancelText} options={translationOptions} />
                                </Button>
                            )}
                            {actions && actions.length ? actions : null}
                            {onConfirm && (
                                <SubmitButton
                                    key="confirmButton"
                                    onClick={onConfirm}
                                    autoFocus
                                    loading={isLoading}
                                    loadingText={loadingText}
                                    disabled={isConfirmDisabled}
                                    variant="contained"
                                    color={confirmVariant || 'primary'}
                                    className={classes.submitButton}
                                    data-track={confirmTrackEvent}
                                    formId={formId}
                                    {...confirmButtonProps}
                                >
                                    <Locale path={confirmText} options={translationOptions} />
                                </SubmitButton>
                            )}
                        </div>
                    ) : null}
                </DialogActions>
            ) : null}
            {disclaimer && (
                <Typography variant="caption" display="block" gutterBottom className={classes.body}>
                    <Locale path={disclaimer} options={translationOptions} />
                </Typography>
            )}
            {error && (
                <Error variant="caption" display="block" gutterBottom className={classes.body}>
                    <Locale path={error} options={translationOptions} />
                </Error>
            )}
        </Dialog>
    );
};

ResponsiveDialog.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    disclaimer: PropTypes.string,
    error: PropTypes.string,
    children: PropTypes.node,
    actions: PropTypes.arrayOf(PropTypes.node),
    backAction: PropTypes.node,
    backText: PropTypes.string,
    cancelText: PropTypes.string,
    cancelTrackEvent: PropTypes.string,
    confirmText: PropTypes.string,
    confirmButtonProps: PropTypes.object,
    confirmTrackEvent: PropTypes.string,
    container: PropTypes.object,
    contentBackgroundColor: PropTypes.string,
    formId: PropTypes.string,
    isConfirmDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadingText: PropTypes.string,
    open: PropTypes.bool,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    showCloseButton: PropTypes.bool,
    translationOptions: PropTypes.object,
    delegateScrollingToChildren: PropTypes.bool,
    confirmVariant: PropTypes.string,
};

ResponsiveDialog.defaultProps = {
    delegateScrollingToChildren: false,
};

export default ResponsiveDialog;
