import { changeCustomerPlan, changeUserRole, setFeatureFlags } from './AdminHelpers.commands';

import Component from './AdminHelpers.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const mapStateToProps = state => {
    const userId = fromState.Auth.user.getId(state);
    const customerId = fromState.Auth.customer.getId(state);
    const { roles = [] } = fromState.DB.user.getEntityById(state, userId);
    const featureFlags = fromState.Auth.user.getFeatureFlags(state);
    const featureFlagOptions = featureFlags
        .map(({ name }) => ({ value: name, label: name }))
        .sort((a, b) => a.label.localeCompare(b.label));
    const { customerPlan, roles: roleOptions = [] } = fromState.DB.customer.getEntityById(
        state,
        customerId
    );
    return {
        customerId,
        customerPlan,
        featureFlags: featureFlags.filter(({ enabled }) => enabled).map(({ name }) => name),
        featureFlagOptions,
        roles,
        options: roleOptions,
        canChangeCustomerPlan: fromState.Auth.canChangeCustomerPlan(state),
        canChangeRole: fromState.Auth.canChangeRole(state),
        canLogMissingTranslations: fromState.Auth.canLogMissingTranslations(state),
        canToggleFeatureFlags: fromState.Auth.canToggleFeatureFlags(state),
        userDetails: fromState.DB.user.getEntityById(state, userId),
    };
};

const mapDispatchToProps = {
    changeCustomerPlan,
    changeUserRole,
    setFeatureFlags,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
