import { NOTIFICATION_TYPES, UPDATE_FREQUENCY } from '/b2b/notifications/constants';
import createSchema, { createHelperText } from './Notifications.schema';
import {
    fetchUserNotificationSettings,
    updateUserNotificationSettings,
} from './Notifications.commands';

import Component from './Notifications.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const mapStateToProps = state => {
    const userId = fromState.Auth.user.getId(state);
    const notificationPermissions = Object.values(NOTIFICATION_TYPES).filter(value =>
        fromState.Auth.user.hasPermission(state, {
            toPerform: 'write',
            on: value,
        })
    );
    const schema = createSchema(notificationPermissions);
    const notificationSettings = fromState.DB.user.getEntityFieldById(state, {
        id: userId,
        field: 'notificationSettings',
    });

    return {
        isLoading: fromState.Auth.user.isLoading(state),
        helperText: createHelperText(notificationPermissions),
        notificationSettings: Object.keys(schema).reduce((defaults, key) => {
            defaults[key] = key.startsWith('preferences')
                ? (notificationSettings && notificationSettings[key]) || undefined
                : (notificationSettings && notificationSettings[key]) || UPDATE_FREQUENCY.NEVER;
            return defaults;
        }, {}),
        schema,
        userId,
    };
};

const mapDispatchToProps = dispatch => ({
    onInitialLoad: userId => dispatch(fetchUserNotificationSettings({ userId })),
    onUpdateUserNotifications: userId => settings =>
        dispatch(updateUserNotificationSettings({ userId, settings })),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { userId } = stateProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        onUpdateUserNotifications: dispatchProps.onUpdateUserNotifications(userId),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
