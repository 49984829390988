import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    namedParameters,
    noParameters,
} from '/b2b/common/helpers/ReduxHelpers';

import { DOMAIN } from './RequestActivity.constants';

export const events = {
    addFilter: namedParameters('field', 'value'),
    removeFilter: namedParameters('field', 'value'),
    setFilter: namedParameters('field', 'value'),
    clearFilter: namedParameters('field'),
    assignFilters: namedParameters('value'),
    resetFilters: noParameters,
};

export const asyncEvents = makeAsyncEvents(['fetchResults'], true);

export const types = makeActionTypes([...Object.keys(events), ...Object.keys(asyncEvents)], DOMAIN);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
    },
    types
);
