import get from 'lodash/get';
import isArray from 'lodash/isArray';

export const emptyObject = {};

export const getErrors = state => get(state, 'errors') || emptyObject;
export const getErrorsForField = (state, field) => get(getErrors(state), field) || [];
export const getValues = state => get(state, 'values') || {};
export const getValueForField = (state, field) => get(getValues(state), field, null);
export const getTouched = state => get(state, 'touched') || emptyObject;

export const getConstraints = state => get(state, 'constraints') || {};
export const getSchemeForField = (state, field) => {
    const constraint = get(getConstraints(state), field) || {};
    let scheme = constraint;
    // Functional constraints must be evaluated
    if (typeof constraint === 'function') {
        scheme = constraint(getValueForField(state, field), getValues(state), field);
    }
    return scheme;
};

export const getFieldTab = (state, field) => {
    const { scheme } = getSchemeForField(state, field);
    const { props: { tab } = {} } = scheme || {};
    return tab || 'default';
};

export const hasFieldBeenTouched = (state, field) => Boolean(get(getTouched(state), field));
export const hasTriedToSubmit = state => get(state, 'submitAttempted') || false;

export const isPristine = state => Object.keys(getTouched(state)).length === 0;
export const isSubmitting = state => get(state, 'submitting') === true;
export const isValid = state => get(state, 'valid') === true;

export const canFieldBeEmpty = (state, field) => {
    const { presence } = getSchemeForField(state, field);
    return (presence && presence?.allowEmpty) === false ? false : true;
};

export const canSubmit = (state, forceAllow) => {
    return !isSubmitting(state) && ((isValid(state) && !isPristine(state)) || forceAllow);
};

export const shouldShowFieldError = (state, field) => {
    const { continuousValidation } = state;
    const fieldTouched = hasFieldBeenTouched(state, field);
    if (continuousValidation && fieldTouched) {
        // Field should always show errors whenever they are found
        return true;
    }

    const triedToSubmit = hasTriedToSubmit(state);
    const value = getValueForField(state, field);
    if (triedToSubmit && !canFieldBeEmpty(state, field) && value === '') {
        // We tried to submit, but a field can't be empty
        return true;
    }

    // Always show the error if a
    return triedToSubmit;
};

export const isFieldVisible = (state, field) => {
    const { type, depends } = getSchemeForField(state, field);
    if (type === 'hidden') {
        return false;
    }
    if (depends) {
        return Object.entries(depends).every(([dependsOn, expected]) => {
            const value = getValueForField(state, dependsOn);
            if (isArray(expected)) {
                return expected.includes(value);
            }
            if (!!expected === expected) {
                // Depends is a boolean, so we check for existence
                if (isArray(value)) {
                    return !!value.length === expected;
                }
                if (typeof value !== 'boolean') {
                    return !!value;
                }
            }
            return value === expected;
        });
    }
    return true;
};

export const getFieldEntity = (state, field) => {
    const constraint = getSchemeForField(state, field);
    let value = getValueForField(state, field);

    const { type = 'text' } = constraint;

    switch (type) {
        case 'text': {
            value = typeof value === 'string' ? value : value ? value.toString() : '';
            break;
        }
    }

    return {
        field,
        scheme: constraint,
        tab: getFieldTab(state, field),
        value,
        error: shouldShowFieldError(state, field) ? getErrorsForField(state, field).join('/n') : '',
        required: !canFieldBeEmpty(state, field),
    };
};
