import { types as actionTypes } from './TrusthubPageDetails.actions';
import { genericReducer } from '../../common/helpers/Redux/genericReducer';

const key = 'isCreateLoading';

export const initialState = {
    [key]: false,
};

export default genericReducer(initialState, {
    [actionTypes.createBegin]: state => ({
        ...state,
        [key]: true,
    }),
    [actionTypes.createSuccess]: state => ({
        ...state,
        [key]: false,
    }),
    [actionTypes.createFailure]: state => ({
        ...state,
        [key]: false,
    }),
});
