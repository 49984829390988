import { resendCode, verify } from './VerifyPhone.commands';

import Component from './VerifyPhone.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const mapStateToProps = state => {
    const phone = fromState.Auth.user.getPhone(state);
    return {
        username: fromState.Auth.user.getId(state),
        phone,
        hasPhone: !!phone,
        authState: fromState.Auth.getAuthState(state),
        isLoading: fromState.Auth.isLoading(state),
    };
};

const mapDispatchToProps = { verify, resendCode };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
