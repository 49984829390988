import {
    defaultIdAttribute,
    defaultMergeStrategy,
    defaultProcessStrategy,
} from '/b2b/common/helpers/Normalizr';

import { schema } from 'normalizr';

/*
{
    "id":147,
    "name":"Adacado Technologies Inc. (DBA Adacado)",
    "purposes":[1,2,3,4,5,6,7,8,9,10],
    "legIntPurposes":[],
    "flexiblePurposes":[7,8,9,10],
    "specialPurposes":[1,2],
    "features":[3],
    "specialFeatures":[1],
    "policyUrl":"https://www.adacado.com/privacy-policy-april-25-2018/"
}
*/

export const entityName = 'iabVendor';
export const idAttribute = 'id';

export const mergeStrategy = defaultMergeStrategy(idAttribute);
export const processStrategy = defaultProcessStrategy(idAttribute);

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
