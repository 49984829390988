import { types as ActionTypes, asyncEvents as AsyncEvents } from './RequestConversation.actions';
import {
    asyncReducer,
    errorReducer,
    genericReducer,
    loadingReducer,
} from '/b2b/common/helpers/ReduxHelpers';

import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import { types as NavActionTypes } from '/b2b/common/state/nav/Nav.actions';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

export const initialState = {
    error: null,
    loading: false,
    messages: [],
};

const asyncTypes = Object.keys(AsyncEvents).map(key => ActionTypes[key]);

export default reduceReducers(
    initialState,
    combineReducers({
        error: errorReducer(initialState.error, asyncTypes),
        loading: loadingReducer(initialState.loading, asyncTypes),
        messages: asyncReducer(initialState.messages, asyncTypes, {
            onSuccess: (state, { params: messages = [] }) => messages || state,
        }),
    }),
    genericReducer(initialState, {
        [AuthActionTypes.logout]: () => initialState,
        [NavActionTypes.routeChanged]: () => initialState,
    })
);
