import theme from './common/theme/index';

export default () => ({
    closeIcon: {
        color: theme.palette.common.white,
    },
    success: {
        backgroundColor: theme.palette.primary.main,
        '& $closeIcon': {
            color: theme.palette.primary.contrastText,
        },
    },
    info: {
        backgroundColor: theme.palette.secondary.main,
        '& $closeIcon': {
            color: theme.palette.secondary.contrastText,
        },
    },
    warning: {
        backgroundColor: theme.palette.tertiary.main,
        '& $closeIcon': {
            color: theme.palette.tertiary.contrastText,
        },
    },
    error: {
        backgroundColor: theme.palette.error.main,
        '& $closeIcon': {
            color: theme.palette.error.contrastText,
        },
    },
});
