const hashCode = (str, slots = 1) =>
    str.split('').reduce((hash, char, index) => {
        hash[index % slots] = (Math.imul(31, hash[index % slots]) + char.charCodeAt(0)) | 0;
        return hash;
    }, new Array(slots).fill(0));

const colorNameLookup = {
    aliceblue: '#f0f8ff',
    antiquewhite: '#faebd7',
    aqua: '#00ffff',
    aquamarine: '#7fffd4',
    azure: '#f0ffff',
    beige: '#f5f5dc',
    bisque: '#ffe4c4',
    black: '#000000',
    blanchedalmond: '#ffebcd',
    blue: '#0000ff',
    blueviolet: '#8a2be2',
    brown: '#a52a2a',
    burlywood: '#deb887',
    cadetblue: '#5f9ea0',
    chartreuse: '#7fff00',
    chocolate: '#d2691e',
    coral: '#ff7f50',
    cornflowerblue: '#6495ed',
    cornsilk: '#fff8dc',
    crimson: '#dc143c',
    cyan: '#00ffff',
    darkblue: '#00008b',
    darkcyan: '#008b8b',
    darkgoldenrod: '#b8860b',
    darkgray: '#a9a9a9',
    darkgreen: '#006400',
    darkkhaki: '#bdb76b',
    darkmagenta: '#8b008b',
    darkolivegreen: '#556b2f',
    darkorange: '#ff8c00',
    darkorchid: '#9932cc',
    darkred: '#8b0000',
    darksalmon: '#e9967a',
    darkseagreen: '#8fbc8f',
    darkslateblue: '#483d8b',
    darkslategray: '#2f4f4f',
    darkturquoise: '#00ced1',
    darkviolet: '#9400d3',
    deeppink: '#ff1493',
    deepskyblue: '#00bfff',
    dimgray: '#696969',
    dodgerblue: '#1e90ff',
    firebrick: '#b22222',
    floralwhite: '#fffaf0',
    forestgreen: '#228b22',
    fuchsia: '#ff00ff',
    gainsboro: '#dcdcdc',
    ghostwhite: '#f8f8ff',
    gold: '#ffd700',
    goldenrod: '#daa520',
    gray: '#808080',
    green: '#008000',
    greenyellow: '#adff2f',
    honeydew: '#f0fff0',
    hotpink: '#ff69b4',
    'indianred ': '#cd5c5c',
    indigo: '#4b0082',
    ivory: '#fffff0',
    khaki: '#f0e68c',
    lavender: '#e6e6fa',
    lavenderblush: '#fff0f5',
    lawngreen: '#7cfc00',
    lemonchiffon: '#fffacd',
    lightblue: '#add8e6',
    lightcoral: '#f08080',
    lightcyan: '#e0ffff',
    lightgoldenrodyellow: '#fafad2',
    lightgrey: '#d3d3d3',
    lightgreen: '#90ee90',
    lightpink: '#ffb6c1',
    lightsalmon: '#ffa07a',
    lightseagreen: '#20b2aa',
    lightskyblue: '#87cefa',
    lightslategray: '#778899',
    lightsteelblue: '#b0c4de',
    lightyellow: '#ffffe0',
    lime: '#00ff00',
    limegreen: '#32cd32',
    linen: '#faf0e6',
    magenta: '#ff00ff',
    maroon: '#800000',
    mediumaquamarine: '#66cdaa',
    mediumblue: '#0000cd',
    mediumorchid: '#ba55d3',
    mediumpurple: '#9370d8',
    mediumseagreen: '#3cb371',
    mediumslateblue: '#7b68ee',
    mediumspringgreen: '#00fa9a',
    mediumturquoise: '#48d1cc',
    mediumvioletred: '#c71585',
    midnightblue: '#191970',
    mintcream: '#f5fffa',
    mistyrose: '#ffe4e1',
    moccasin: '#ffe4b5',
    navajowhite: '#ffdead',
    navy: '#000080',
    oldlace: '#fdf5e6',
    olive: '#808000',
    olivedrab: '#6b8e23',
    orange: '#ffa500',
    orangered: '#ff4500',
    orchid: '#da70d6',
    palegoldenrod: '#eee8aa',
    palegreen: '#98fb98',
    paleturquoise: '#afeeee',
    palevioletred: '#d87093',
    papayawhip: '#ffefd5',
    peachpuff: '#ffdab9',
    peru: '#cd853f',
    pink: '#ffc0cb',
    plum: '#dda0dd',
    powderblue: '#b0e0e6',
    purple: '#800080',
    rebeccapurple: '#663399',
    red: '#ff0000',
    rosybrown: '#bc8f8f',
    royalblue: '#4169e1',
    saddlebrown: '#8b4513',
    salmon: '#fa8072',
    sandybrown: '#f4a460',
    seagreen: '#2e8b57',
    seashell: '#fff5ee',
    sienna: '#a0522d',
    silver: '#c0c0c0',
    skyblue: '#87ceeb',
    slateblue: '#6a5acd',
    slategray: '#708090',
    snow: '#fffafa',
    springgreen: '#00ff7f',
    steelblue: '#4682b4',
    tan: '#d2b48c',
    teal: '#008080',
    thistle: '#d8bfd8',
    tomato: '#ff6347',
    turquoise: '#40e0d0',
    violet: '#ee82ee',
    wheat: '#f5deb3',
    white: '#ffffff',
    whitesmoke: '#f5f5f5',
    yellow: '#ffff00',
    yellowgreen: '#9acd32',
};

export const colorNameToHex = color => {
    if (typeof colorNameLookup[color.toLowerCase()] != 'undefined') {
        return colorNameLookup[color.toLowerCase()];
    }
    return color;
};

/**
 *
 *
 * @param {string} [color='']
 * @param {string} defaultColor
 * @return {Array}
 */
export const parseColor = (color = '', defaultColor) => {
    if (color === 'transparent') {
        return [0, 0, 0, 0];
    }

    if (color.indexOf('rgb') === 0) {
        try {
            const colorArray = color
                .replace(/[^\d,.]/g, '')
                .split(',')
                .map(value => {
                    const val = parseFloat(value);
                    if (isNaN(val)) {
                        throw new Error('Invalid color value');
                    }
                    return val;
                });
            return colorArray;
        } catch (error) {
            // Error parsing rgb color
        }
    }

    let hex = `${color || ''}`.toLowerCase().replace(/[^0-9a-f]/, '');
    if (hex.length % 2) {
        // hex is an odd length
        if (hex.length === 3) {
            // HEX short code
            hex = hex
                .split('')
                .map(c => `${c}${c}`)
                .join('');
        } else {
            return parseColor(defaultColor, 'rgba(0,0,0,1)');
        }
    }

    const components = [];
    let pointer = 0;
    while (pointer < hex.length && components.length < 4) {
        const colorInt = parseInt(hex.slice(pointer, pointer + 2), 16);
        components.push(colorInt & 255);
        pointer += 2;
    }
    if (pointer < hex.length && components.length === 3) {
        // Parse the alpha channel of a hex
        const colorInt = parseInt(hex.slice(pointer, pointer + 2), 16);
        components.push(colorInt / 255);
    }
    return components;
};

export const intToHexInt = (...args) => args.map(i => ((i % 256) + 256) % 256);
export const intToHexString = (...args) =>
    // Only RGB on hex strings
    args.slice(0, 3).reduce((hex, i) => `${hex}${`00${i.toString(16)}`.slice(-2)}`, '');

export const intToHue = i => ((i % 360) + 360) % 360;

export const floatToHexInt = f => ((~~(f * 255) % 256) + 256) % 256;
export const floatToHexString = (...args) =>
    args.reduce((hex, f) => `${hex}${`00${floatToHexInt(f).toString(16)}`.slice(-2)}`, '');

export const floatToHue = f => (((f % 1) + 1) % 1) * 360;

const hashHex = str => intToHexInt.apply(null, hashCode(str, 3));

export const hue2rgb = (p, q, t) => {
    if (t < 0) {
        t += 1;
    }
    if (t > 1) {
        t -= 1;
    }
    if (t < 1 / 6) {
        return p + (q - p) * 6 * t;
    }
    if (t < 1 / 2) {
        return q;
    }
    if (t < 2 / 3) {
        return p + (q - p) * (2 / 3 - t) * 6;
    }
    return p;
};

export const hslToRgb = (h, s, l, a = 1) => {
    let r,
        g,
        b = 0;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }
    return [floatToHexInt(r), floatToHexInt(g), floatToHexInt(b), floatToHexInt(a)];
};

export const rgbToHsl = (r, g, b, a = 1) => {
    // Make r, g, and b fractions of 1
    r /= 255;
    g /= 255;
    b /= 255;

    // Find greatest and smallest channel values
    let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;
    // Calculate hue
    // No difference
    if (delta === 0) {
        h = 0;
    }
    // Red is max
    else if (cmax === r) {
        h = ((g - b) / delta) % 6;
    }
    // Green is max
    else if (cmax === g) {
        h = (b - r) / delta + 2;
    }
    // Blue is max
    else {
        h = (r - g) / delta + 4;
    }

    h = Math.round(h * 60);

    // Make negative hues positive behind 360°
    if (h < 0) {
        h += 360;
    }

    l = (cmax + cmin) / 2;

    // Calculate saturation
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    return [h, s, l, a];
};

export const hslToHex = (h, s, l, a = 1) => intToHexString.apply(null, hslToRgb(h, s, l, a));

export const rainbow = (numOfSteps, step) => {
    // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
    // Adam Cole, 2011-Sept-14
    // HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
    let r, g, b;
    const h = step / numOfSteps;
    const i = ~~(h * 6);
    const f = h * 6 - i;
    const q = 1 - f;
    switch (i % 6) {
        case 0:
            r = 1;
            g = f;
            b = 0;
            break;
        case 1:
            r = q;
            g = 1;
            b = 0;
            break;
        case 2:
            r = 0;
            g = 1;
            b = f;
            break;
        case 3:
            r = 0;
            g = q;
            b = 1;
            break;
        case 4:
            r = f;
            g = 0;
            b = 1;
            break;
        case 5:
            r = 1;
            g = 0;
            b = q;
            break;
    }
    return `#${floatToHexString(r, g, b)}`;
};

export const hueFromString = str => rgbToHsl.apply(null, hashHex(str))[0];

export const contrastRgb = (r, g, b) => {
    // calculate contrast of color (standard grayscale algorithmic formula)
    var contrast = (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;
    return contrast >= 128 ? 0 : 1;
};

export const LightenDarkenColor = (col, percentage) => {
    const amt = Math.floor(
        Math.min(255, Math.max(-255, Math.abs(percentage) < 1 ? 255 * percentage : percentage))
    );

    let [r, g, b] = parseColor(col);

    r += amt;

    if (r > 255) {
        r = 255;
    } else if (r < 0) {
        r = 0;
    }

    b += amt;

    if (b > 255) {
        b = 255;
    } else if (b < 0) {
        b = 0;
    }

    g += amt;

    if (g > 255) {
        g = 255;
    } else if (g < 0) {
        g = 0;
    }

    return `#${intToHexString(r, g, b)}`;
};

export const darken = (col, amt) => LightenDarkenColor(col, -amt);
export const lighten = (col, amt) => LightenDarkenColor(col, amt);
export const cssColorRegexp =
    // eslint-disable-next-line security/detect-unsafe-regex
    /^#[a-f\d]{3}(?:[a-f\d]?|(?:[a-f\d]{3}(?:[a-f\d]{2})?)?)\b$|^rgba?\((?:(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%),\s*(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%),\s*(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%)(?:,\s*((?:\d{1,2}|100)%|0(?:\.\d+)?|1))?|(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%)\s+(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%)\s+(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%)(?:\s+((?:\d{1,2}|100)%|0(?:\.\d+)?|1))?)\)$/;
// eslint-disable-next-line security/detect-non-literal-regexp
export const namedColorRegexp = new RegExp(`^(${Object.keys(colorNameLookup).join('|')})$`, 'i');
