import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    noParameters,
} from '../../common/helpers/ReduxHelpers';

import { DOMAIN } from './OrganizationsList.constants';

export const events = {
    openAddOrganizationDialog: noParameters,
    closeAddOrganizationDialog: noParameters,
};

export const asyncEvents = makeAsyncEvents(['fetchResults', 'update', 'delete'], true);
export const creationEvents = makeAsyncEvents(['create'], true);

export const types = makeActionTypes(
    [...Object.keys(events), ...Object.keys(asyncEvents), ...Object.keys(creationEvents)],
    DOMAIN
);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
        ...creationEvents,
    },
    types
);
