import {
    CHART_TYPE,
    UNCLASSIFIED_PATH,
    OPTIONS as options,
    GROUPS_STATE as state,
} from './FieldClassificationStats.constants';
import { Checkbox, CircularProgress, FormControlLabel, Typography } from '@mui/material';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';

import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { Chart } from 'chart.js';
import Locale from '/b2b/common/components/Locale';
import Portlet from '/b2b/common/components/Portlet';
import PortletContent from '/b2b/common/components/PortletContent';
import PropTypes from 'prop-types';
import history from '/b2b/common/history';
import makeStyles from '@mui/styles/makeStyles';
import palette from '/b2b/common/theme/palette';
import styles from './FieldClassificationStats.styles';
import { translate } from '/b2b/common/helpers/i18n';

const useStyles = makeStyles(styles);

const reducer = (state, action) => ({ ...state, ...action });

const getEventData = (event, items) => {
    if (items && items.length) {
        const data = event.chart.data.datasets[0].data;
        const index = items[items.length - 1].index;
        const {
            classification: classificationLabel,
            connection: connectionLabel,
            children,
        } = data[index]._data;
        if (classificationLabel || connectionLabel) {
            const { classificationId, connectionId } = children.find(
                ({ classification, connection }) =>
                    classification === classificationLabel || connection === connectionLabel
            );
            return {
                classificationId: classificationLabel && classificationId,
                connectionId: connectionLabel && connectionId,
            };
        }
    }
    return {};
};

const FieldClassificationStats = props => {
    const { fieldClassifications = [], isLoading, localeCode } = props;
    const classes = useStyles(props);
    const [groups, dispatch] = useReducer(reducer, state);
    const chartRef = useRef(null);
    const chartClass = useRef(null);

    const formatText = (ctx, hover) => {
        const item = ctx?.dataset?.data[ctx.dataIndex];
        if (!item) {
            return {};
        }

        const { g: label, w, h } = item;
        const type = Object.keys(groups).reduce((type, key) => {
            if (Object.prototype.hasOwnProperty.call(item._data, key)) {
                type = key;
            }
            return type;
        }, '');

        let fontColor = palette.common.black;
        let backgroundColor;

        if (label === translate(UNCLASSIFIED_PATH)) {
            fontColor = palette.common.black;
            backgroundColor = hover ? palette.common.grey[300] : palette.common.grey[200];
        } else {
            const typeColor = groups[type].color;
            fontColor = palette[typeColor][hover ? 'contrastHover' : 'contrastText'] || fontColor;
            backgroundColor = palette[typeColor][hover ? 'hover' : 'main'];
        }

        const tooSmall = w < 40 || h < 20;

        if (tooSmall) {
            fontColor = 'transparent';
        }

        return {
            backgroundColor,
            fontColor,
            font: {
                color: fontColor,
                family: "'Jost', sans-serif",
                size: tooSmall ? 0 : 14,
                style: 'normal',
                lineHeight: tooSmall ? 0 : 1,
            },
        };
    };

    /*
      can't use react-chartjs-2 due to issues with treemap animation -
      need to use native chartjs class with ref
    */
    useEffect(() => {
        const data = {
            datasets: fieldClassifications.length
                ? [
                      {
                          tree: fieldClassifications.map(({ classification, ...remain }) => ({
                              classification: translate(classification),
                              ...remain,
                          })),
                          groups: Object.keys(groups),
                          key: 'count',
                          spacing: 1,
                          borderWidth: 2,
                          captions: {
                              display: true,
                              color: ctx => formatText(ctx).fontColor,
                              font: ctx => formatText(ctx).font,
                              padding: 3,
                          },
                          labels: {
                              display: true,
                              color: ctx => formatText(ctx).fontColor,
                              font: ctx => formatText(ctx).font,
                          },
                          color: ctx => formatText(ctx).fontColor,
                          borderColor: ctx => formatText(ctx).backgroundColor,
                          backgroundColor: ctx => formatText(ctx).backgroundColor,
                          hoverBackgroundColor: ctx => formatText(ctx, true).backgroundColor,
                      },
                  ]
                : [],
        };

        const config = {
            type: CHART_TYPE,
            data,
            options: {
                ...options,
                layout: {
                    padding: {
                        bottom: 0,
                        top: 0,
                        left: 3,
                        right: 3,
                    },
                },
                onHover: (event, items) => {
                    const { connectionId } = getEventData(event, items);
                    event.native.target.style.cursor = connectionId ? 'pointer' : 'default';
                },
                onClick: (event, items) => {
                    const { classificationId, connectionId } = getEventData(event, items);
                    connectionId &&
                        history.push(
                            `${AuthenticatedPaths.DATA_STORES}/${connectionId}${
                                classificationId ? `?classification=${classificationId}` : ''
                            }`
                        );
                },
            },
        };

        if (chartRef.current.$chartjs) {
            chartClass.current.destroy();
            chartClass.current = new Chart(chartRef.current, config);
        } else {
            chartClass.current = new Chart(chartRef.current, config);
        }
    }, [fieldClassifications, localeCode]);

    useEffect(() => {
        if (chartClass.current && fieldClassifications.length) {
            // need to manually update chart to trigger animations
            const groupKeys = Object.entries(groups)
                .filter(([, { checked }]) => checked)
                .map(([key]) => key);
            chartClass.current.data.datasets[0].groups = groupKeys;
            chartClass.current.update();
        }
    }, [groups]);

    const handleGroupChange = event => {
        const { checked, name } = event.target;
        const checkedCount = Object.values(groups).filter(({ checked }) => checked).length;
        // must have at least one group checked
        if (checked || checkedCount > 1) {
            dispatch({ [name]: { ...groups[name], checked } });
        }
    };

    return (
        <Portlet className={classes.root} elevation={0}>
            <PortletContent noPadding className={classes.portletContent}>
                <div className={classes.details}>
                    <div>
                        <Typography variant="h3">
                            <Locale
                                path={'layouts.Dashboard.components.FieldClassificationStats.label'}
                            />
                        </Typography>
                        {!!fieldClassifications.length && (
                            <Typography variant="body1">
                                <Locale
                                    path={
                                        'layouts.Dashboard.components.FieldClassificationStats.fieldsBy.label'
                                    }
                                />
                                {': '}

                                {Object.values(groups)
                                    .filter(({ checked } = {}) => checked)
                                    .map(({ label }, idx, arr) => (
                                        <Fragment key={label}>
                                            <Locale path={label} />
                                            {idx < arr.length - 1 && ' / '}
                                        </Fragment>
                                    ))}
                            </Typography>
                        )}
                    </div>
                    <div>
                        {!!fieldClassifications.length &&
                            Object.entries(groups).map(([key, { checked, color, label } = {}]) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleGroupChange}
                                            name={key}
                                            color={color === 'tertiary' ? 'default' : color}
                                        />
                                    }
                                    key={key}
                                    label={<Locale path={label} />}
                                />
                            ))}
                    </div>
                </div>
                <div className={classes.chart}>
                    <canvas ref={chartRef} />
                </div>
                {isLoading && (
                    <div className={classes.progressWrapper}>
                        <CircularProgress />
                    </div>
                )}
            </PortletContent>
        </Portlet>
    );
};

FieldClassificationStats.propTypes = {
    className: PropTypes.string,
    fieldClassifications: PropTypes.array,
    isLoading: PropTypes.bool,
    localeCode: PropTypes.string,
};

export default FieldClassificationStats;
