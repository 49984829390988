import buildActionCreators from '../../common/helpers/Redux/buildActionCreators';
import makeActionTypes from '../../common/helpers/Redux/makeActionTypes';
import makeAsyncEvents from '../../common/helpers/Redux/makeAsyncEvents';

export const DOMAIN = 'trusthubPageDetails';

export const asyncEvents = makeAsyncEvents(['create'], true);

export const types = makeActionTypes(Object.keys(asyncEvents), DOMAIN);

export default buildActionCreators(asyncEvents, types);
