export default theme => ({
    root: {
        textAlign: 'center',
        margin: theme.spacing(2, 0),
    },
    label: {
        maxWidth: 'none',
        padding: theme.spacing(0, 1),
    },
});
