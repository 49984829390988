/* eslint-disable scanjs-rules/property_localStorage */
import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { MISSING_TRANSLATIONS_KEY } from '/b2b/common/helpers/i18n';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';

const MissingTranslations = () => {
    const [missingTranslations, setMissingTranslations] = useState(
        JSON.parse(window.localStorage.getItem(MISSING_TRANSLATIONS_KEY) || '{}')
    );

    const logMissingTranslationKeys = useBoundCallback(
        missingTranslations => {
            // eslint-disable-next-line no-console
            console.log(missingTranslations);
        },
        [missingTranslations]
    );
    const clearMissingTranslationKeys = useCallback(() => {
        // eslint-disable-next-line scanjs-rules/property_localStorage
        setMissingTranslations({});
        window.localStorage.setItem(MISSING_TRANSLATIONS_KEY, '{}');
    }, []);

    useEffect(() => {
        // We need to listen for changes in other tabs
        const updateMissingTranslations = (/** @type {StorageEvent} */ event) => {
            if (
                event.key !== MISSING_TRANSLATIONS_KEY ||
                event.storageArea !== window.localStorage
            ) {
                return;
            }
            setMissingTranslations(JSON.parse(event.newValue || '{}'));
        };
        window.addEventListener('storage', updateMissingTranslations);
        return () => {
            window.removeEventListener('storage', updateMissingTranslations);
        };
    }, []);

    return (
        <Box m={1}>
            <Typography variant="caption" gutterBottom>
                Missing Translations
            </Typography>
            <ButtonGroup size="small" fullWidth>
                <Button onClick={logMissingTranslationKeys}>Log</Button>
                <Button
                    onClick={clearMissingTranslationKeys}
                    disabled={Object.keys(missingTranslations).length === 0}
                >
                    Clear
                </Button>
            </ButtonGroup>
        </Box>
    );
};

MissingTranslations.propTypes = {};

export default MissingTranslations;
