import { createSchema } from '/b2b/common/helpers/Schema';

export default createSchema({
    password: {
        label: 'password.current',
    },
    newPassword: {
        label: 'common.fields.newPassword.label',
    },
    passwords: {
        label: 'password.confirmNew',
        equality: {
            attribute: 'newPassword',
            message: 'password.validations.notMatching',
        },
    },
});
