import Actions from '../state/db/DB.actions';
import { types as AuthUserNotificationActionTypes } from '../../authentication/state/user/notifications/AuthUserNotifications.actions';
import { CRUDActionTypes } from '../helpers/ReduxHelpers';
import NotificationSchema from '../../notifications/state/db/Notification.schema';
import fromState from '../state/selectors';

export default store => next => action => {
    const { dispatch, getState } = store;
    const { type, meta: providedMeta, payload = {} } = action;
    // Action could be a thunk
    let nextResponse;
    if (type) {
        const { schema: providedSchema, ...remainingMeta } = providedMeta || {};
        const meta = {
            ...remainingMeta,
            schema: providedSchema
                ? {
                      key: providedSchema.key,
                      idAttribute: providedSchema.idAttribute,
                  }
                : undefined,
        };
        const { error, response = payload } = payload || {};
        if (providedSchema && !error && response) {
            const actionType = type.split('/').pop();
            switch (actionType) {
                case CRUDActionTypes.createSuccess: {
                    // Entity Response
                    dispatch(Actions.create(meta, response));
                    break;
                }
                case CRUDActionTypes.fetchResultsSuccess: {
                    // List Response
                    dispatch(Actions.update(meta, response));
                    break;
                }
                case CRUDActionTypes.updateSuccess:
                case CRUDActionTypes.fetchDetailsSuccess: {
                    // Entity Response
                    dispatch(Actions.update(meta, response));
                    break;
                }
                case CRUDActionTypes.deleteSuccess: {
                    // Entity Response
                    dispatch(Actions.delete(meta, response));
                    break;
                }
            }
        }
        nextResponse = next({
            ...action,
            // Overwrite the meta to reduce the Redux store size
            meta,
        });
    } else {
        nextResponse = next(action);
    }

    switch (type) {
        case AuthUserNotificationActionTypes.markAllSuccess: {
            const { params: { read } = {} } = payload || {};
            // We need to mark all notifications either read or unread
            const allNotifications = Object.keys(
                fromState.DB.notification.getStore(getState())
            ).map(id => ({ id: parseInt(id, 10), read: !!read }));
            dispatch(
                Actions.update(
                    { schema: NotificationSchema },
                    { ...payload, results: allNotifications }
                )
            );
            break;
        }
    }

    return nextResponse;
};
