import { formatDate, makeResultsFormatter } from '../common/services/helpers';

import { ROLES } from '../authentication/constants';
import startCase from 'lodash/startCase';

export const formatOrganization = details => {
    const { created, name, orgId, owners, parentOrgId, updated } = details;

    const createdDate = formatDate(created);
    const updatedDate = formatDate(updated);

    return name !== ROLES.ALL
        ? {
              created: createdDate,
              name,
              orgId,
              owners,
              parentOrgId,
              updated: updatedDate,
          }
        : undefined;
};

export const formatOrganizationResults = makeResultsFormatter(formatOrganization);

export const formatOrganizations = (organizations = []) =>
    organizations.map(org => {
        let { orgId, name } = org;
        if (typeof org !== 'object') {
            orgId = `${org}`;
        }
        return { orgId, name: name ? startCase(name) : undefined };
    });
