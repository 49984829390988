/* eslint-disable react/no-children-prop */
import { Redirect, Route, Switch } from 'react-router-dom';

import PropTypes from 'prop-types';
import React from 'react';
import SelectMFA from './SelectMFA';
import SetupPhone from './SetupPhone';
import SetupTOTP from './SetupTOTP';
import UnauthenticatedPaths from '../../../routing/UnauthenticatedRoutes/UnauthenticatedRoutes.paths';
import VerifyPhone from './VerifyPhone';
import VerifyTOTP from './VerifyTOTP';

const SelectMFARoutes = props => {
    const { needsPhoneSetup, needsTOTPSetup, needsPhoneVerification, needsTOTPVerification } =
        props;

    let initialRoute = null;

    if (needsPhoneSetup) {
        initialRoute = `${UnauthenticatedPaths.SELECT_MFA}/setup-phone`;
    } else if (needsTOTPSetup) {
        initialRoute = `${UnauthenticatedPaths.SELECT_MFA}/setup-totp`;
    } else if (needsPhoneVerification) {
        initialRoute = `${UnauthenticatedPaths.SELECT_MFA}/verify-phone`;
    } else if (needsTOTPVerification) {
        initialRoute = `${UnauthenticatedPaths.SELECT_MFA}/verify-totp`;
    }

    return (
        <Switch>
            {initialRoute && <Route children={<Redirect to={initialRoute} />} />}
            <Route
                exact
                path={`${UnauthenticatedPaths.SELECT_MFA}/setup-phone`}
                children={<SetupPhone />}
            />
            <Route
                exact
                path={`${UnauthenticatedPaths.SELECT_MFA}/setup-totp`}
                children={<SetupTOTP />}
            />
            <Route
                exact
                path={`${UnauthenticatedPaths.SELECT_MFA}/verify-phone`}
                children={<VerifyPhone />}
            />
            <Route
                exact
                path={`${UnauthenticatedPaths.SELECT_MFA}/verify-totp`}
                children={<VerifyTOTP />}
            />
            <Route exact path={`${UnauthenticatedPaths.SELECT_MFA}`} children={<SelectMFA />} />
            <Route path="/" children={<Redirect to={UnauthenticatedPaths.SELECT_MFA} />} />
        </Switch>
    );
};

SelectMFARoutes.propTypes = {
    needsPhoneSetup: PropTypes.bool,
    needsTOTPSetup: PropTypes.bool,
    needsPhoneVerification: PropTypes.bool,
    needsTOTPVerification: PropTypes.bool,
};

export default SelectMFARoutes;
