import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import Locale from '/b2b/common/components/Locale/Locale.component';
import PropTypes from 'prop-types';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const styles = theme => {
    return {
        formControl: {
            display: 'flex',
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(1),
        },
        popover: {
            zIndex: theme.zIndex.tooltip + 1,
        },
    };
};

const useStyles = makeStyles(styles);

function handleClose() {
    setTimeout(() => {
        document.activeElement.blur();
    }, 0);
}

const FeatureFlagSelect = props => {
    const classes = useStyles();
    const id = 'admin_toggle_feature_flag';
    const { label, onChange, options, value } = props;
    const inputLabel = React.useRef(null);
    const handleChange = event => {
        onChange && onChange(event.target.value);
    };

    return (
        <FormControl
            classes={{
                root: classes.formControl,
            }}
            variant="outlined"
        >
            <InputLabel id={`${id}_label`} ref={inputLabel}>
                <Locale path={label} />
            </InputLabel>
            <Select
                id={id}
                labelId={`${id}_label`}
                onChange={handleChange}
                onClose={handleClose}
                variant="outlined"
                label={<Locale path={label} />}
                multiple
                value={value}
                name="featureFlags"
                MenuProps={{ PopoverClasses: { root: classes.popover } }}
            >
                {options.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

FeatureFlagSelect.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    value: PropTypes.arrayOf(PropTypes.string),
};

export default FeatureFlagSelect;
