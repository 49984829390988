import {
    types as ActionTypes,
    asyncEvents as AsyncEvents,
    events as Events,
} from './RequestActivity.actions';
import {
    CRUDPagingQueryReducer,
    collectionReducer,
    crudPagingReducers,
    genericReducer,
} from '/b2b/common/helpers/ReduxHelpers';

import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { types as NavActionTypes } from '/b2b/common/state/nav/Nav.actions';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

export const initialState = {
    offset: 0,
    limit: 25,
    query: '',
    results: [],
    resultCount: 0,
    error: null,
    loading: false,
    filters: {},
    sort: '',
    sortDirection: 'none',
};

export default reduceReducers(
    initialState,
    combineReducers({
        filters: collectionReducer(
            initialState.filters,
            Object.keys(Events).map(key => ActionTypes[key])
        ),
        ...crudPagingReducers(
            initialState,
            Object.keys(AsyncEvents).map(key => ActionTypes[key])
        ),
    }),
    genericReducer(initialState, {
        [AuthActionTypes.logout]: () => initialState,
        [NavActionTypes.routeChanged]: CRUDPagingQueryReducer(
            initialState,
            `${AuthenticatedPaths.DSAR_REQUESTS}/:requestId`
        ),
    })
);
