import muiTheme from '../default';
// Palette
import palette from '../palette';

export default {
    fixedHeader: {
        [muiTheme.breakpoints.down('sm')]: {
            backgroundColor: palette.common.neutral,
            display: 'flex',
            width: '60px',
            padding: muiTheme.spacing(1, 0, 1, 1),

            '& ~ *:nth-child(even)': {
                justifyContent: 'center',
                width: 'calc(50% - 80px)',
            },

            '& ~ *:nth-child(odd)': {
                width: 'calc(50% + 80px)',
            },
        },
    },
    fixedLeft: {
        [muiTheme.breakpoints.down('sm')]: {
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
        },
    },
};
