/* eslint-disable react/no-children-prop */
import { Redirect, Route, Switch } from 'react-router-dom';

import AuthenticatedPaths from '../AuthenticatedRoutes/AuthenticatedRoutes.paths';
import ForgotPassword from './ForgotPassword';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import RecoverPassword from './RecoverPassword';
import ResetPassword from './ResetPassword';
import SelectMFARoutes from '../../authentication/routes/SelectMFA';
import SignInRoutes from '../../authentication/routes/SignIn';
// import SignUp from './SignUp';
import SignUpConfirm from './SignUpConfirm';
import UnauthenticatedPaths from './UnauthenticatedRoutes.paths';
import Welcome from '../../common/components/Welcome';
import makeStyles from '@mui/styles/makeStyles';
import styles from './UnauthenticatedRoutes.styles';

const useStyles = makeStyles(styles);

const redirects = Object.values(AuthenticatedPaths).map(path => (
    <Route key={path} path={path}>
        <Redirect to={UnauthenticatedPaths.SIGN_IN} />
    </Route>
));

const UnauthenticatedRoutes = props => {
    const {
        isAuthenticated = false,
        needsAccountConfirmation = false,
        needsMFASelected = false,
        needsSignIn = true,
        needsPasswordRecovery = false,
        needsSignInConfirmed = false,
        needsNewInvite = false,
        needsNewPassword = false,
    } = props;
    const classes = useStyles(props);

    let initialRoute = null;

    if (needsPasswordRecovery) {
        initialRoute = UnauthenticatedPaths.RESET_PASSWORD;
    } else if (needsSignInConfirmed) {
        initialRoute = UnauthenticatedPaths.SIGN_IN_CONFIRM;
    } else if (needsAccountConfirmation) {
        initialRoute = UnauthenticatedPaths.SIGN_UP_CONFIRM;
    } else if (needsNewInvite) {
        initialRoute = UnauthenticatedPaths.SIGN_UP_RESEND;
    } else if (needsNewPassword) {
        initialRoute = UnauthenticatedPaths.RECOVER_PASSWORD;
    } else if (needsMFASelected) {
        initialRoute = UnauthenticatedPaths.SELECT_MFA;
    }

    return isAuthenticated ? null : (
        <div className={classes.root}>
            <Grid className={classes.grid} container>
                <Grid className={classes.quoteWrapper} item lg={5}>
                    <Welcome />
                </Grid>
                <Grid className={classes.contentWrapper} item lg={7} xs={12}>
                    <Switch>
                        {initialRoute && <Route children={<Redirect to={initialRoute} />} />}
                        <Route
                            exact
                            path={UnauthenticatedPaths.RESET_PASSWORD}
                            children={<ResetPassword />}
                        />
                        <Route
                            exact
                            path={UnauthenticatedPaths.FORGOT_PASSWORD}
                            children={<ForgotPassword />}
                        />
                        {needsSignIn && (
                            <Route children={<Redirect to={UnauthenticatedPaths.SIGN_IN} />} />
                        )}
                        <Route
                            exact
                            path={UnauthenticatedPaths.SIGN_UP_CONFIRM}
                            children={<SignUpConfirm />}
                        />
                        <Route
                            exact
                            path={UnauthenticatedPaths.RECOVER_PASSWORD}
                            children={<RecoverPassword />}
                        />
                        <Route
                            path={UnauthenticatedPaths.SELECT_MFA}
                            children={<SelectMFARoutes />}
                        />
                        <Route path={UnauthenticatedPaths.SIGN_IN} children={<SignInRoutes />} />
                        {/* <Route exact path={UnauthenticatedPaths.SIGN_UP} children={<SignUp/>} /> */}
                        {redirects}
                        <Route path="/" children={<Redirect to={UnauthenticatedPaths.SIGN_IN} />} />
                    </Switch>
                </Grid>
            </Grid>
        </div>
    );
};

UnauthenticatedRoutes.propTypes = {
    isAuthenticated: PropTypes.bool,
    needsAccountConfirmation: PropTypes.bool,
    needsPasswordRecovery: PropTypes.bool,
    needsSignInConfirmed: PropTypes.bool,
    needsNewInvite: PropTypes.bool,
    needsNewPassword: PropTypes.bool,
    needsMFASelected: PropTypes.bool,
    needsSignIn: PropTypes.bool,
};

export default UnauthenticatedRoutes;
