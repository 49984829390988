import { defaultConfiguration, dialogTypePositions } from '../../../constants';

import { types as ActionTypes } from './Palette.actions';
import { types as ConfigActionTypes } from '../../ConfigDetails.actions';
import { genericReducer } from '/b2b/common/helpers/ReduxHelpers';

export const initialState = {
    ...defaultConfiguration.palette,
};

export default genericReducer(initialState, {
    [ConfigActionTypes.createSuccess]: (state, { response }) => {
        const { configuration: { palette = {} } = {} } = response;
        return {
            ...state,
            ...palette,
        };
    },
    [ConfigActionTypes.fetchDetailsSuccess]: (state, { response }) => {
        const { configuration: { palette = {} } = {} } = response;
        return {
            ...state,
            ...palette,
        };
    },
    [ConfigActionTypes.updateSuccess]: (state, { response }) => {
        const { configuration: { palette = {} } = {} } = response;
        return {
            ...state,
            ...palette,
        };
    },
    [ActionTypes.fieldChange]: (state, { field, value }) => {
        const newState = { ...state };
        switch (field) {
            case 'dialogType': {
                const positions = Object.keys(dialogTypePositions[value] || {});
                const index = positions.indexOf(newState.displayPosition);
                newState.displayPosition = positions[index >= 0 ? index : 0];
                break;
            }
        }
        return {
            ...newState,
            [field]: value,
        };
    },
});
