import {
    BROWSE_CUSTOMER,
    fetchCustomers as fetchCustomersService,
} from '../../common/services/customer';

import AuthActions from '/b2b/authentication/state/Auth.actions';
import AuthUserCustomersActions from '/b2b/authentication/state/user/customers/AuthUserCustomers.actions';
import NavActions from '../../common/state/nav/Nav.actions';
import { signOut as SignOutService } from '/b2b/authentication/services';
import { formatCustomer } from '../../common/services/format';
import fromState from '../../common/state/selectors';
import schema from '../../common/state/db/customer/Customer.schema';

export const signOut = () => dispatch => {
    SignOutService();
    dispatch(AuthActions.logout());
};

export const fetchCustomers = extraParams => (dispatch, getState) => {
    const meta = { schema };
    const params = { ...extraParams, limit: 100 };
    dispatch(AuthUserCustomersActions.fetchResultsBegin(meta, params));

    return fetchCustomersService(params)
        .then(response => {
            const { results: customers } = response;
            if (customers.length === 0) {
                throw { message: 'You are not assigned to any accounts.', code: 404 };
            }
            dispatch(AuthUserCustomersActions.fetchResultsSuccess(meta, params, response));
            return customers;
        })
        .catch(error => {
            dispatch(AuthUserCustomersActions.fetchResultsFailure(meta, params, error));
            return [formatCustomer(BROWSE_CUSTOMER)];
        })
        .then(customers => {
            if (customers.length === 1) {
                dispatch(NavActions.changeCustomer(customers[0]));
            } else {
                const customerId = fromState.Auth.customer.getId(getState());
                if (customerId) {
                    const customer = customers.find(option => option.customerId === customerId);
                    if (customer) {
                        dispatch(NavActions.changeCustomer(customer));
                    }
                }
            }
            return customers;
        });
};

export const selectCustomer = customer => dispatch => {
    dispatch(NavActions.changeCustomer(customer));
    return Promise.resolve(customer);
};
