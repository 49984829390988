export default () => ({
    root: {
        width: '100%',
    },
    content: {
        padding: 0,
        minHeight: '4rem',
        minWidth: '600px',
    },
    image: {
        display: 'inline-block',
        marginTop: '50px',
        maxWidth: '100%',
        width: '554px',
    },
});
