import * as clipboard from 'clipboard-polyfill';

import Component from './AuthenticatorCode.component';
import UIActions from '../../state/ui/UI.actions';
import { connect } from 'react-redux';
import fromState from '../../state/selectors';

const copyToClipboard = text => {
    const blob = new Blob([text], { type: 'text/html' });
    const items = [
        new clipboard.ClipboardItem({
            'text/html': blob,
            'text/plain': text,
        }),
    ];
    return clipboard.write(items);
};

const mapStateToProps = state => {
    return {
        userId: fromState.Auth.user.getId(state),
    };
};

const mapDispatchToProps = dispatch => ({
    copyToClipboard: code => {
        copyToClipboard(code).then(
            () =>
                dispatch(
                    UIActions.createSnack('codeBlock.copiedToClipboard', { variant: 'success' })
                ),
            () => dispatch(UIActions.createSnack('codeBlock.unableToCopy', { variant: 'error' }))
        );
    },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...ownProps,
        ...stateProps,
        onCopy: dispatchProps.copyToClipboard,
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
