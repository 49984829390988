import {
    contrastRgb,
    hslToRgb,
    hueFromString,
    intToHexString,
    parseColor,
} from '../../../helpers/Color';
import {
    defaultIdAttribute,
    defaultMergeStrategy,
    defaultProcessStrategy,
} from '../../../helpers/Normalizr';

import { schema } from 'normalizr';

export const entityName = 'categories';
export const idAttribute = 'categoryId';

export const mergeStrategy = defaultMergeStrategy(idAttribute);
export const processStrategy = defaultProcessStrategy(idAttribute, data => {
    const { name } = data;
    const rgb = hslToRgb(hueFromString(`${name}`) / 360, 0.6, 0.9);
    const { color = intToHexString.apply(null, rgb) } = data;
    return {
        ...data,
        textColor: color && contrastRgb.apply(null, parseColor(color)) ? '#ffffff' : '#000000',
        color: color && `#${intToHexString.apply(null, parseColor(color))}`,
    };
});

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
