// Palette
import palette from '../palette';

export default {
    variants: [
        {
            props: { variant: 'contained' },
            style: {
                boxShadow: 'none',
                '&.Mui-disabled': {
                    color: palette.background.default,
                },
            },
        },
        {
            props: { variant: 'contained', color: 'primary' },
            style: {
                '&:hover .Mui-label': {
                    color: palette.common.white,
                },
            },
        },
    ],
    styleOverrides: {
        root: {
            fontFamily: "'Jost', sans-serif",
            fontWeight: 'bold',
            textTransform: 'none',
            transitionProperty: 'background-color, border, box-shadow, color',
            '&.Mui-disabled': {
                pointerEvents: 'auto',
            },
        },
    },
};
