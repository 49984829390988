import { assignId, getPaginatedResults, handleError } from '../common/services/helpers';
import { formatLitigation, formatUnicourtCase } from './format';

import API from '@aws-amplify/api';
import { apiName } from '@osano-b2b';

export const fetchFollowingLitigation = params =>
    getPaginatedResults(`/litigation/customer/follow`, params, formatLitigation);

export const fetchFollowingLitigationCount = () =>
    API.get(apiName, `/litigation/customer/follow/case-count`, {})
        .catch(handleError)
        .then(({ litigationCount = 0 } = {}) => litigationCount);

/**
 * Gets the litigation associated with the provided "vendorId"
 *
 * @param {{ vendorId: string }|import('../common/services/format').PagedParams } params
 * @returns {{ results: { domain: string, created: date }[]}}
 */
export const fetchLitigationForVendor = (vendorId, params) =>
    getPaginatedResults(`/litigation/vendor/${vendorId}`, params, formatLitigation);

export const fetchLitigationCaseById = caseId => {
    const options = {};
    return API.get(apiName, `/litigation/${caseId}`, options)
        .catch(handleError)
        .then(response => response.case || {})
        .then(assignId(caseId))
        .then(formatUnicourtCase);
};
