import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';

export const defaultIdAttribute = idAttribute => value =>
    ['string', 'number'].indexOf(typeof value) > -1 ? value : value[idAttribute] || value.id;
export const defaultMergeStrategy =
    (idAttribute, callback = identity) =>
    (entityA, entityB) => {
        const newEntity = Object.entries(entityB).reduce((entity, [key, value]) => {
            if (value !== undefined && !isEqual(value, entity[key])) {
                entity[key] = value;
            }
            return entity;
        }, entityA);
        return callback(newEntity) || newEntity;
    };
export const mergeCustomizer =
    (idAttribute = 'id') =>
    (objValue, srcValue) => {
        if (objValue === undefined || isEqual(srcValue, objValue)) {
            // We need this so we don't unnecessarily re-create redux Objects or Arrays
            return srcValue;
        } else if (srcValue === undefined) {
            // Shortcut for adding a new object
            return objValue;
        } else if (isPlainObject(objValue)) {
            return merge({}, objValue, srcValue);
        } else if (Array.isArray(objValue)) {
            if (isEmpty(objValue) && !isEmpty(srcValue)) {
                return [...srcValue];
            } else if (!isEmpty(objValue) && isEmpty(srcValue)) {
                return objValue;
            } else if (isEmpty(objValue) && isEmpty(srcValue)) {
                // both empty
                return objValue;
            } else {
                // if array is array of objects, then assume each object has id, and merge based on id
                // so create new array, based objValue. id should match in each spot

                if (
                    isPlainObject(objValue[0]) &&
                    Object.prototype.hasOwnProperty.call(objValue[0], idAttribute)
                ) {
                    const srcCollection = keyBy(srcValue, defaultIdAttribute(idAttribute));

                    const aligned = objValue.map(el => {
                        const id = defaultIdAttribute(idAttribute)(el);
                        if (Object.prototype.hasOwnProperty.call(srcCollection, id)) {
                            const srcEl = srcCollection[id];
                            delete srcCollection[id];
                            return merge({}, el, srcEl);
                        } else {
                            return el;
                        }
                    });

                    aligned.push(...Object.values(srcCollection));

                    return aligned;
                } else {
                    return [...objValue, ...srcValue].filter(
                        (value, index, self) => self.indexOf(value) === index
                    );
                }
            }
        }
        // Value is a primitive...return new value
        return srcValue;
    };

export const deepMergeStrategy =
    (idAttribute = 'id', callback = identity) =>
    (entityA, entityB) => {
        const newEntity = mergeWith({}, entityA, entityB, mergeCustomizer(idAttribute));
        return callback(newEntity) || newEntity;
    };
/**
 * @template T
 * @param {string} idAttribute
 * @param {import('normalizr').schema.StrategyFunction<T>} callback
 * @returns {import('normalizr').schema.StrategyFunction<T>}
 */
export const defaultProcessStrategy =
    (idAttribute = 'id', callback = identity) =>
    (entity, ...args) => {
        const { id, [idAttribute]: entityId, ...details } = entity;
        const targetId = entityId || id;
        return (
            callback.apply(null, [
                {
                    [idAttribute]: targetId,
                    ...details,
                },
                ...args,
            ]) || entity
        );
    };
