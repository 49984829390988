export default () => ({
    root: {
        justifyContent: 'center',
        height: '100%',
    },
    container: {},
    wrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: 0,
    },
    stat: {
        fontSize: '7rem',
        lineHeight: 1,
        textAlign: 'center',
    },
    upgrade: {
        fontSize: '3rem',
        lineHeight: 1.5,
        padding: '4px',
        fontWeight: 'bold',
        textAlign: 'center',
        maxWidth: '215px',
        filter: 'blur(10px)',
    },
    link: {
        fontSize: '24px',
        marginTop: '7px',
        textAlign: 'center',
        display: 'block',
    },
    title: {
        textAlign: 'center',
    },
    circleProgress: {
        lineHeight: 1,
        fontSize: '10rem',
        padding: '60px',
        boxSizing: 'content-box',
    },
});
