import { types as ActionTypes } from './Userpilot.actions';
import { types as NavActionTypes } from '../nav/Nav.actions';
import { TRIGGERS } from '../../userpilot.triggers';
import { combineReducers } from 'redux';
import { genericReducer } from '../../helpers/ReduxHelpers';
import { matchPath } from 'react-router-dom';
import reduceReducers from 'reduce-reducers';

export const initialState = {
    userpilotId: '64yk44f7',
    userpilotContentId: null,
};

export default reduceReducers(
    initialState,
    combineReducers({
        userpilotId: genericReducer(initialState.userpilotId, {
            [ActionTypes.setUserpilotId]: (state, newId) => newId || initialState.userpilotId,
        }),
        userpilotContentId: genericReducer(initialState.userpilotContentId, {
            [ActionTypes.setUserpilotContentId]: (state, newId) => newId || null,
        }),
    }),
    genericReducer(initialState, {
        [NavActionTypes.routeChanged]: (state, { location }) => {
            // Place any route change effects not involving the state here
            const { userpilotContentId } = state;
            if (userpilotContentId) {
                // Check if there is a route match
                const { pathname } = location;
                const matches = Object.values(TRIGGERS).filter(
                    ({ id, on, route = on, redirect: anyTriggerPath = route }) => {
                        if (id === userpilotContentId) {
                            return matchPath(pathname, { path: anyTriggerPath });
                        }
                        return false;
                    }
                );
                if (matches.length) {
                    return state;
                }
            }
            return {
                ...state,
                userpilotContentId: null,
            };
        },
    })
);
