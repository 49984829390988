export default theme => ({
    form: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        flexBasis: '700px',
        margin: 'auto',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    colorPicker: {
        flex: '1 1 100%',
        zIndex: 'auto',
    },
    textField: {
        flex: '1 1 100%',
    },
    selectInput: {
        flex: '1 1 100%',
    },
    formGroup: {
        flex: '1 1 100%',
        '& + $formGroup': {
            margin: theme.spacing(2, 0, 0),
        },
        '&:first-child': {
            marginTop: theme.spacing(1),
        },
        '&:last-child': {
            marginBottom: 0,
        },
    },
    title: {
        marginTop: theme.spacing(3),
        '&:empty': {
            marginTop: 0,
        },
    },
    subtitle: {
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(0.5),
    },
    divider: {
        textAlign: 'center',
        margin: theme.spacing(0),
    },
    fields: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        marginTop: theme.spacing(-2, 0),
        justifyContent: 'stretch',
        flexWrap: 'wrap',
    },
    progress: {
        display: 'block',
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    copyButton: {
        cursor: 'pointer',
    },
    submitButton: {
        marginTop: theme.spacing(2),
        width: '100%',
        marginBottom: theme.spacing(3),
    },
    fieldError: {
        color: theme.palette.error.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    disabled: {
        opacity: 0.6,
        pointerEvents: 'none',
    },
    submitError: {
        color: theme.palette.error.main,
        alignText: 'center',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
});
