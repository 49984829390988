import { types as ActionTypes } from './AuthenticatorCode.actions';
import { genericReducer } from '../../helpers/ReduxHelpers';

export const initialState = {
    error: null,
    qrCode: null,
    isLoading: false,
};

export default genericReducer(initialState, {
    [ActionTypes.generateCodeBegin]: state => ({
        ...state,
        error: initialState.error,
        isLoading: true,
    }),

    [ActionTypes.generateCodeFailure]: (state, error) => ({
        ...state,
        qrCode: initialState.qrCode,
        error,
        isLoading: false,
    }),

    [ActionTypes.generateCodeSuccess]: (state, qrCode) => {
        return {
            ...state,
            qrCode,
            error: initialState.error,
            isLoading: false,
        };
    },
});
