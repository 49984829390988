/* eslint react/prop-types: 0 */
import React from 'react';
import { SvgIcon } from '@mui/material';

import { white } from '../../colors';

export default function Legislation(props) {
    return (
        <SvgIcon fontSize="large" viewBox="0 0 24 24" {...props}>
            <path
                d="M12,23C5.9,23,1,18.1,1,12S5.9,1,12,1s11,4.9,11,11S18.1,23,12,23z M12,3c-5,0-9,4-9,9c0,5,4,9,9,9c5,0,9-4,9-9
                C21,7,17,3,12,3z"
                fill={white}
            />
            <path
                d="M8.5,16.9c-0.2,0-0.3,0-0.5-0.1c-0.4-0.1-0.8-0.5-0.9-0.9c-0.2-0.5,0-0.9,0.2-1.5l1.5-4.5
                C8.9,9.8,8.9,9.6,9,9.4C9.1,9.3,9.3,9.1,9.4,9c0.2-0.1,0.3-0.2,0.5-0.2l4.5-1.5l0.3,0.9l-0.3-0.9l0.3,0.9l-0.3-0.9
                C15,7.1,15.5,7,16,7.1c0.4,0.2,0.8,0.5,0.9,0.9c0.2,0.5,0,0.9-0.2,1.5l-1.5,4.5c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.4,0.4
                c-0.2,0.1-0.3,0.2-0.5,0.2l-4.5,1.5C9.2,16.8,8.8,16.9,8.5,16.9z M9.1,15.5L9.1,15.5L9.1,15.5z M8.5,14.9L8.5,14.9L8.5,14.9z
                M10.7,10.7l-1.3,4l4-1.3l1.3-4L10.7,10.7z M15.5,9.1L15.5,9.1L15.5,9.1z M14.9,8.5L14.9,8.5L14.9,8.5z"
                fill={white}
            />
        </SvgIcon>
    );
}
