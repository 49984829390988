import Actions from '/b2b/authentication/state/user/notifications/AuthUserNotifications.actions';
import { markAllNotificationsRead as MarkAllNotificationsService } from '/b2b/notifications/services';
import schema from '/b2b/notifications/state/db/Notification.schema';

export const markAllNotificationsRead = () => dispatch => {
    const meta = { schema };
    dispatch(Actions.markAllBegin(meta, { read: true }));
    return MarkAllNotificationsService({ read: true })
        .then(response => {
            dispatch(Actions.markAllSuccess(meta, { read: true }));
            return response;
        })
        .catch(() => {
            dispatch(Actions.markAllFailure(meta, { read: true }));
        });
};
