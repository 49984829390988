import { defaultIdAttribute, defaultProcessStrategy } from '/b2b/common/helpers/Normalizr';

import merge from 'lodash/merge';
import { schema } from 'normalizr';
import { idAttribute as threadIdAttribute } from './thread/HelpScoutConversationThread.schema';
import unionWith from 'lodash/unionWith';

export const entityName = 'helpScoutConversations';
export const idAttribute = 'helpScoutConversationId';

export const mergeStrategy = (entityA, entityB) => {
    return {
        ...entityA,
        ...entityB,
        threads: unionWith(entityA.threads, entityB.threads, (threadA, threadB) => {
            const a =
                typeof threadA === 'object'
                    ? defaultIdAttribute(threadIdAttribute)(threadA)
                    : threadA;
            const b =
                typeof threadA === 'object'
                    ? defaultIdAttribute(threadIdAttribute)(threadB)
                    : threadB;
            return a === b;
        }),
        _embedded: merge(entityA._embedded, entityB._embedded),
    };
};
export const processStrategy = defaultProcessStrategy(idAttribute);

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
