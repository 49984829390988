import {
    namedParameters,
    noParameters,
    singleParameter,
} from '/b2b/common/helpers/Redux/parameterize';

import buildActionCreators from '/b2b/common/helpers/Redux/buildActionCreators';
import makeActionTypes from '/b2b/common/helpers/Redux/makeActionTypes';
import makeAsyncEvents from '/b2b/common/helpers/Redux/makeAsyncEvents';

export const DOMAIN = 'quicksight';

export const events = {
    event: namedParameters('event', 'metadata'),
    reload: noParameters,
    clearParameters: singleParameter,
    clearParameter: namedParameters('category', 'field'),
    deleteParameter: namedParameters('category', 'field'),
    updateParameter: namedParameters('category', 'field', 'value'),
};

export const asyncEvents = makeAsyncEvents(['fetchUrl', 'loadFrame']);

export const types = makeActionTypes([...Object.keys(events), ...Object.keys(asyncEvents)], DOMAIN);

export default buildActionCreators({ ...events, ...asyncEvents }, types);
