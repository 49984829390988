import { types as ActionTypes, asyncEvents as AsyncEvents } from './Locale.actions';
import {
    asyncReducer,
    errorReducer,
    genericReducer,
    loadingReducer,
} from '../../helpers/ReduxHelpers';

import { combineReducers } from 'redux';
import { determineLocale } from '../../helpers/i18n';
import reduceReducers from 'reduce-reducers';

export const initialState = {
    loading: false,
    error: null,
    code: determineLocale(),
    reloadIncrement: {},
};

const asyncTypes = Object.keys(AsyncEvents).map(key => ActionTypes[key]);

export default reduceReducers(
    initialState,
    combineReducers({
        error: errorReducer(initialState.error, asyncTypes),
        loading: loadingReducer(initialState.loading, asyncTypes),
        code: asyncReducer(initialState.code, asyncTypes, {
            onSuccess: (state, { params: { code = initialState.code } = {} } = {}) => code || state,
        }),
        reloadIncrement: genericReducer(initialState.reloadIncrement, {
            // Every time the locale is extended, we need to force a reload to pick up new strings
            [ActionTypes.localeExtended]: (state, { localeCode }) => {
                const { [`${localeCode}`]: reloadCount = 0 } = state;
                return {
                    ...state,
                    [`${localeCode}`]: reloadCount + 1,
                };
            },
        }),
    })
);
