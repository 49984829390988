/* eslint-disable camelcase */
import {
    resendPhoneVerificationCode,
    updateUserAttributes,
    verifyPhone,
} from '/b2b/authentication/services';

import AuthActions from '/b2b/authentication/state/Auth.actions';
import { formatPhoneNumber } from '/b2b/common/helpers/Phone';

export const resendCode = params => dispatch => {
    dispatch(AuthActions.resendPhoneVerificationCodeBegin(params));

    return resendPhoneVerificationCode(params)
        .then(response => {
            return dispatch(AuthActions.resendPhoneVerificationCodeSuccess(params, response));
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthActions.resendPhoneVerificationCodeFailure(params, error));
            throw message;
        });
};

export const verify = params => dispatch => {
    dispatch(AuthActions.verifyPhoneBegin(params));

    return verifyPhone(params)
        .then(response => {
            return dispatch(AuthActions.verifyPhoneSuccess(params, response));
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthActions.verifyPhoneFailure(params, message));
            throw message;
        });
};

export const update = params => dispatch => {
    const { phone, user } = params;
    dispatch(AuthActions.updateUserAttributesBegin(params));

    return updateUserAttributes({
        user,
        phone: formatPhoneNumber(phone),
        phone_number_verified: false,
    })
        .then(response => {
            return dispatch(AuthActions.updateUserAttributesSuccess(params, response));
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthActions.updateUserAttributesFailure(params, error));
            throw message;
        });
};
