import { Box } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PropTypes from 'prop-types';
import React from 'react';
import StyleButton from '../StyleButton';
import makeStyles from '@mui/styles/makeStyles';
import styles from './BlockStyleControls.styles';

const useStyles = makeStyles(styles);

const BLOCK_TYPES = [
    {
        label: 'ordered-list-item',
        icon: <FormatListNumberedIcon />,
        style: 'ordered-list-item',
    },
    {
        label: 'unordered-list-item',
        icon: <FormatListBulletedIcon />,
        style: 'unordered-list-item',
    },
];

const BlockStyleControls = props => {
    const classes = useStyles(props);
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    const onToggle = e => {
        props.onToggle(e);
    };

    return (
        <Box className={classes.iconWrapper}>
            {BLOCK_TYPES.map((type, i) => (
                <StyleButton
                    key={i}
                    icon={type.icon}
                    onToggle={onToggle}
                    label={type.label}
                    style={type.style}
                    active={type.style === blockType}
                />
            ))}
        </Box>
    );
};

BlockStyleControls.propTypes = {
    editorState: PropTypes.object,
    onToggle: PropTypes.func,
    setEditorState: PropTypes.func,
};

export default BlockStyleControls;
