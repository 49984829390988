import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    namedParameters,
} from '/b2b/common/helpers/ReduxHelpers';

import { DOMAIN } from './IAB.constants';

export const events = {
    fieldChange: namedParameters('field', 'value', 'oldValue'),
};

export const asyncEvents = makeAsyncEvents(
    ['fetchVendors', 'addIabVendor', 'clearIabVendors', 'removeIabVendor', 'updateIabVendors'],
    true
);

export const types = makeActionTypes([...Object.keys(events), ...Object.keys(asyncEvents)], DOMAIN);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
    },
    types
);
