import { fetchCustomerDetails, selectCustomer, selectLocale } from './Topbar.commands';

import Component from './Topbar.component';
import { PERMISSIONS } from '/b2b/authentication/constants';
import UIActions from '/b2b/common/state/ui/UI.actions';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const mapStateToProps = state => {
    const currentCustomerId = fromState.Auth.customer.getId(state);
    const currentLocale = fromState.Locale.getLocaleCode(state);
    const userId = fromState.Auth.user.getId(state);
    const customerIds = fromState.Auth.user.customers.getResults(state);
    return {
        canReadCustomer: fromState.Auth.user.hasPermission(state, {
            toPerform: 'read',
            on: PERMISSIONS.SUBSCRIPTION_MGMT,
        }),
        customerIds,
        customers: fromState.DB.customer.getEntitiesForIds(state, customerIds),
        currentCustomerId,
        currentLocale,
        customerDetails: fromState.DB.customer.getEntityById(state, currentCustomerId),
        notificationsOpen: fromState.UI.areNotificationsOpen(state),
        notificationsLimit: 4,
        notificationCount: fromState.Auth.user.notifications.getResultCount(state),
        urgentNotifications: fromState.Auth.user.notifications.getUrgentNotifications(state),
        userDetails: fromState.DB.user.getEntityById(state, userId),
        userDetailsOpen: fromState.UI.areUserDetailsOpen(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        selectCustomer: customer => dispatch(selectCustomer(customer)),
        selectLocale: localeCode => dispatch(selectLocale(localeCode)),
        onCloseNotifications: () => dispatch(UIActions.closeNotifications()),
        onOpenNotifications: () => dispatch(UIActions.openNotifications()),
        onCloseUserDetails: () => dispatch(UIActions.closeUserDetails()),
        onOpenUserDetails: () => dispatch(UIActions.openUserDetails()),
        fetchCustomerDetails: customerId => dispatch(fetchCustomerDetails({ customerId })),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { customers, customerIds } = stateProps;
    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        onChangeCustomer: customerId =>
            dispatchProps.selectCustomer(customers[customerIds.indexOf(customerId)]),
        onChangeLocale: locale => dispatchProps.selectLocale(locale),
        onToggleNotifications: stateProps.notificationsOpen
            ? dispatchProps.onCloseNotifications
            : dispatchProps.onOpenNotifications,
        onToggleUserDetails: stateProps.userDetailsOpen
            ? dispatchProps.onCloseUserDetails
            : dispatchProps.onOpenUserDetails,
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps, { forwardRef: true })(
    Component
);
