// Palette
import palette from '../palette';

export default {
    variants: [
        {
            props: { color: 'primary', variant: 'outlined' },
            style: {
                '&:hover': {
                    backgroundColor: palette.primary.light,
                },
            },
        },
        {
            props: { color: 'primary', deletable: true },
            style: {
                '&:focus': {
                    backgroundColor: palette.primary.light,
                    '& svg': {
                        backgroundColor: 'inherit',
                    },
                },
            },
        },
    ],
    styleOverrides: {
        root: {
            fontFamily: "'Jost', sans-serif",
            transitionProperty: 'background-color, border, box-shadow, color',
        },
        label: {
            display: 'flex',
            alignItems: 'center',
        },
    },
};
