import {
    defaultIdAttribute,
    defaultMergeStrategy,
    defaultProcessStrategy,
} from '/b2b/common/helpers/Normalizr';
import { entityName, idAttribute } from './DataStoreConnection.constants';

import { schema } from 'normalizr';

export { entityName, idAttribute } from './DataStoreConnection.constants';

export const mergeStrategy = defaultMergeStrategy(idAttribute);
export const processStrategy = defaultProcessStrategy(
    idAttribute,
    function (details, parent, parentKey) {
        const { id = details, [idAttribute]: realId = id } = details;
        if (parentKey !== null && parentKey === 'connections') {
            // Has an agent
            const currentConnections = parent.connections || [];
            if (!currentConnections.includes(realId)) {
                parent.connections = [...currentConnections, realId];
            }
        }
        return details;
    }
);

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
