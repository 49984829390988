import palette from '../palette';
import theme from '../default';

export default {
    main: {
        display: 'flex',
        flex: '1 0 auto',
        padding: theme.spacing(1.5, 0),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    searchIcon: {
        color: palette.text.secondary,
    },
    searchText: {
        flex: '1 1 auto',
    },
    clearIcon: {
        '&:hover': {
            color: palette.error.main,
        },
    },
};
