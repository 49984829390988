export default theme => ({
    container: {
        justifySelf: 'space-between',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        margin: theme.spacing(0, -2),
        padding: theme.spacing(0, 2),
    },
    disabled: {
        opacity: 0.5,
    },
    box: {
        border: `1px solid ${theme.palette.common.muted}`,
        position: 'relative',
        width: '1rem',
        height: '1rem',
        marginRight: theme.spacing(1),
    },
    empty: {
        '&:before': {
            position: 'absolute',
            content: '""',
            left: 0,
            top: '50%',
            right: 0,
            borderTop: '1px solid',
            borderColor: theme.palette.error.main,
            transform: 'rotate(-45deg)',
        },
    },
});
