import { useRouteMatch } from 'react-router-dom';

export const useRouteMatchParams = (route, paramsArray) => {
    const { params = {} } = useRouteMatch(route) || {};
    return Object.entries(params).reduce((acc, [key, value]) => {
        if ((!paramsArray || paramsArray.includes(key)) && value) {
            acc[key] = decodeURIComponent(value);
        }
        return acc;
    }, {});
};

export default useRouteMatchParams;
