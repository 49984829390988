import { Box, Paper } from '@mui/material';
import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { getParametersForCategory, getUrlForCategory } from '../../state/Quicksight.selectors';
import { useDispatch, useSelector } from 'react-redux';

import Actions from '../../state/Quicksight.actions';
import Autocomplete from '/b2b/common/components/FormControls/Autocomplete';
import PropTypes from 'prop-types';
import castArray from 'lodash/castArray';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { fetchConfigs } from '/b2b/consent/services';
import isEqual from 'lodash/isEqual';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';

const quicksightContext = createEmbeddingContext();

const loadQuicksight = async (customerId, reportsUrl, container, onMessage) => {
    const frameOptions = {
        url: reportsUrl,
        container,
        resizeHeightOnSizeChangedEvent: true,
        onChange: onMessage,
    };

    const contentOptions = {
        parameters: [
            {
                Name: 'customerId',
                Values: [customerId],
            },
        ],
        locale: 'en-US', // TODO: map our own locale support to Quicksight
        attributeOptions: {
            overlayContent: true,
        },
        onMessage,
    };

    const context = await quicksightContext;
    return context.embedDashboard(frameOptions, contentOptions);
};

const Quicksight = props => {
    const { category, customerId } = props;
    const quicksight = useRef();
    const dashboard = useRef();
    const dispatch = useDispatch();
    const parameters = useSelector(state => getParametersForCategory(state, category), isEqual);
    const reportsUrl = useSelector(state => getUrlForCategory(state, category));
    const { configId } =
        useSelector(state => getParametersForCategory(state, category), isEqual) || {};

    const onQuicksightEvent = useCallback((event, metadata) => {
        dispatch(Actions.event(event, { ...metadata, category }));
    }, []);

    const handleUpdateConsentConfig = useBoundCallback(
        (category, event, values) => {
            dispatch(Actions.updateParameter(category, 'configId', values));
        },
        [category]
    );

    const handleConsentConfigSearch = useBoundCallback(
        params =>
            fetchConfigs(params).then(({ results }) => {
                // Format into Autocomplete results
                return results.map(({ name, configId }) => ({
                    label: name,
                    value: configId,
                }));
            }),
        []
    );

    useEffect(() => {
        if (reportsUrl) {
            const params = { customerId, category };
            dispatch(Actions.loadFrameBegin(params));
            loadQuicksight(customerId, reportsUrl, quicksight.current, onQuicksightEvent).then(
                frame => {
                    dispatch(Actions.loadFrameSuccess(params));
                    dashboard.current = frame;
                }
            );
        }
    }, [reportsUrl]);

    useLayoutEffect(() => {
        if (customerId) {
            dispatch(Actions.clearParameters(category));
        }
    }, [customerId]);

    useLayoutEffect(() => {
        if (dashboard.current && customerId) {
            const { configId } = parameters || {};
            const hasConfig = !!configId && !!configId.length;
            const newParameters = [
                {
                    Name: 'customerId',
                    Values: [customerId],
                },
            ];
            if (hasConfig) {
                newParameters.push({
                    Name: 'configId',
                    Values: castArray(configId)
                        .map(id => id?.value || id)
                        .filter(Boolean),
                });
            }
            dashboard.current.reset();
            dashboard.current.setParameters(newParameters);
        }
    }, [customerId, parameters]);

    return (
        <Paper sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {category === 'consent' ? (
                <Autocomplete
                    label="views.ConsentManager.columns.name.label"
                    placeholder="common.table.search"
                    defaultValue={castArray(configId).filter(({ value } = {}) => !!value)}
                    onChange={handleUpdateConsentConfig}
                    onRequestResults={handleConsentConfigSearch}
                    multiple
                    sx={theme => ({ padding: theme.spacing(2, 2, 1) })}
                />
            ) : null}
            <Box sx={{ flex: 1, height: '100%' }} ref={quicksight} />
        </Paper>
    );
};

Quicksight.propTypes = {
    category: PropTypes.string,
    customerId: PropTypes.string,
};

export default Quicksight;
