import AuthenticatorCode from '/b2b/common/components/AuthenticatorCode';
import { createSchema } from '/b2b/common/helpers/Schema';

export default createSchema({
    qrCode: {
        presence: false,
        type: 'component',
        component: AuthenticatorCode,
    },
    code: {
        label: 'Code',
        presence: { allowEmpty: false },
        props: {
            placeholder: 'Enter your One-Time code...',
            InputLabelProps: {
                shrink: true,
            },
        },
        length: {
            is: 6,
            notValid: 'is not valid',
        },
    },
});
