import { CircularProgress, Divider, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import MFAOptionsList from './components/MFAOptionsList';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import styles from './SelectMFA.styles';

const useStyles = makeStyles(styles);

const SetupMFA = props => {
    const {
        challengeName,
        getMfaOptions,
        selectMFA,
        isPhoneVerified,
        isSMSEnabled,
        isTOTPEnabled,
        savedMFAOptions,
        preferredMFA,
    } = props;
    const classes = useStyles(props);
    const isMounted = useRef(false);
    const [mfaOptions, setMFAOptions] = useState([...savedMFAOptions]);
    const [isLoading, setIsLoading] = useState(true);
    const [head, setHead] = useState('Multi-Factor Authentication');
    const [subhead, setSubhead] = useState(
        'Select a Multi-Factor Authentication method to complete your log in.'
    );

    const handleSelectMFA = useCallback(
        mfaType => {
            setIsLoading(true);
            return selectMFA({
                challengeName,
                isTOTPEnabled,
                isSMSEnabled,
                isPhoneVerified,
                preferredMFA,
                mfaType,
            }).then(() => {
                isMounted.current && setIsLoading(false);
            });
        },
        [challengeName, isPhoneVerified, isSMSEnabled, isTOTPEnabled, preferredMFA, selectMFA]
    );

    useEffect(() => {
        isMounted.current = true;
        setIsLoading(true);
        getMfaOptions()
            .then(mfaProps => {
                if (mfaOptions.length === 1) {
                    handleSelectMFA(mfaOptions[0]);
                } else if (mfaProps.length === 0) {
                    // None are configured
                    setHead('Configure Multi-Factor Authentication');
                    setSubhead(
                        'Osano requires all users to enable either SMS or Authenticator App security before you may log in.'
                    );
                    mfaProps = ['SMS_MFA', 'SOFTWARE_TOKEN_MFA'];
                }
                setMFAOptions(mfaProps);
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
        return () => (isMounted.current = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isLoading ? (
        <div className={classes.loadingWrapper}>
            <CircularProgress />
        </div>
    ) : (
        <div className={classes.content}>
            <div className={classes.contentBody}>
                <div className={classes.list}>
                    <Typography className={classes.title} variant="h2">
                        {head}
                    </Typography>
                    <Typography className={classes.subtitle} variant="body1">
                        {subhead}
                    </Typography>
                    <Divider />
                    <MFAOptionsList options={mfaOptions} onSelect={handleSelectMFA} />
                    <Divider />
                </div>
            </div>
        </div>
    );
};

SetupMFA.propTypes = {
    challengeName: PropTypes.string,
    isPhoneVerified: PropTypes.bool,
    isSMSEnabled: PropTypes.bool,
    isTOTPEnabled: PropTypes.bool,
    preferredMFA: PropTypes.string,
    savedMFAOptions: PropTypes.array,
    getMfaOptions: PropTypes.func,
    selectMFA: PropTypes.func,
};

export default SetupMFA;
