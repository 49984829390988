import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import React, { Fragment } from 'react';

import EditNoteIcon from '@mui/icons-material/BorderColor';
import Locale from '/b2b/common/components/Locale';
import { NAV_LABELS } from '/b2b/common/layouts/Dashboard/components/Sidebar/constants';
import ProductTodos from './components/ProductTodos';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import startCase from 'lodash/startCase';
import styles from './TodoList.styles';
import toLower from 'lodash/toLower';

const useStyles = makeStyles(styles);

const TodoList = props => {
    const classes = useStyles(props);

    const {
        isLoading,
        todos: {
            cookieConsent = {},
            unifiedConsent = {},
            subjectRights = {},
            dataMapping = {},
            vendorMonitoring = {},
            assessments = {},
        } = {},
    } = props;

    return (
        <Paper className={classes.paper} elevation={0}>
            <Box className={classes.header}>
                <EditNoteIcon className={classes.icon} />
                <Typography variant="h3">
                    <Locale path="common.todo" formatter={str => startCase(toLower(str))} />
                </Typography>
            </Box>
            {isLoading ? (
                <Box className={classes.loading}>
                    <CircularProgress />
                </Box>
            ) : (
                <Fragment>
                    {Object.keys(cookieConsent || {}).length > 0 && (
                        <ProductTodos title={NAV_LABELS.consentManager} todos={cookieConsent} />
                    )}
                    {Object.keys(unifiedConsent || {}).length > 0 && (
                        <ProductTodos title={NAV_LABELS.unifiedConsent} todos={unifiedConsent} />
                    )}
                    {Object.keys(subjectRights || {}).length > 0 && (
                        <ProductTodos title={NAV_LABELS.dsar} todos={subjectRights} />
                    )}
                    {Object.keys(dataMapping || {}).length > 0 && (
                        <ProductTodos title={NAV_LABELS.dataMapping} todos={dataMapping} />
                    )}
                    {Object.keys(vendorMonitoring || {}).length > 0 && (
                        <ProductTodos title={NAV_LABELS.vendor} todos={vendorMonitoring} />
                    )}
                    {Object.keys(assessments || {}).length > 0 && (
                        <ProductTodos title={NAV_LABELS.assessments} todos={assessments} />
                    )}
                </Fragment>
            )}
        </Paper>
    );
};

TodoList.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    todos: PropTypes.object.isRequired,
};

export default TodoList;
