import theme from '../default';

export default {
    popper: {
        zIndex: 1500,
    },
    tooltip: {
        lineHeight: 1.125,
        padding: theme.spacing(1, 1.5),
    },
};
