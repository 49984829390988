import muiTheme from '../default';
// Palette
import palette from '../palette';

export default {
    root: {
        fontSize: '0.875rem',
    },
    stackedCommon: {
        [muiTheme.breakpoints.down('md')]: {
            border: 0,
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            minHeight: '44px',
            lineHeight: 1,
            '&:nth-child(2)': {
                backgroundColor: palette.common.neutral,
            },
            '&:nth-child(1)': {
                backgroundColor: palette.common.neutral,
            },
            width: '50%',
            '&:nth-child(odd)': {
                width: 'calc(50% - 80px)',
            },
            '&:nth-child(even)': {
                width: 'calc(50% + 80px)',
            },
        },
    },
    stackedHeader: {
        marginBottom: muiTheme.spacing(1),
    },
    responsiveStackedSmall: {
        [muiTheme.breakpoints.down('md')]: {
            border: 0,
            height: 'auto',
            minHeight: '44px',
            lineHeight: 1.25,
            whiteSpace: 'pre-wrap',
            '&:first-of-type': {
                backgroundColor: palette.common.neutral,
            },
        },
    },
};
