import { createSchema } from '/b2b/common/helpers/Schema';

export default createSchema({
    useCognito: {
        type: 'hidden',
        presence: false,
    },
    username: (value, { useCognito }) => {
        const constraint = {
            label: 'Email Address',
            type: 'email',
            presence: { allowEmpty: false },
            email: true,
            length: {
                maximum: 128,
            },
            props: {
                autoFocus: true,
            },
        };
        if (!useCognito) {
            return constraint;
        }
        delete constraint.props;
        constraint.disabled = true;
        return constraint;
    },
    password: (value, { useCognito }) => {
        const constraint = {
            label: 'Password',
            type: 'password',
            // We should not validate on sign in
            password: false,
            presence: false,
            depends: {
                useCognito: true,
            },
            length: {
                maximum: 128,
            },
        };
        if (!useCognito) {
            return constraint;
        }
        constraint.presence = { allowEmpty: false };
        return constraint;
    },
    rememberMe: {
        type: 'checkbox',
        label: 'Remember Me',
        presence: { allowEmpty: true },
    },
});
