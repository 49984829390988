export default theme => ({
    form: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        flexBasis: '700px',
        margin: 'auto',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    noPadding: {
        marginTop: 0,
        padding: 0,
    },
    row: {
        flex: '1 1 100%',
    },
    field: {
        '& + $row': {
            marginTop: theme.spacing(2),
        },
    },
    textField: {
        flex: '1 1 100%',
        margin: theme.spacing(2, 0),
    },
    selectInput: {
        flex: '1 1 100%',
        margin: theme.spacing(2, 0),
    },
    formControl: {
        flex: '1 1 100%',
        margin: theme.spacing(2, 0),
    },
    title: {
        margin: theme.spacing(2, 0),
        overflowWrap: 'anywhere',
        '&:empty': {
            margin: 0,
        },
    },
    subtitle: {
        color: theme.palette.text.secondary,
        margin: theme.spacing(2, 0),
        overflowWrap: 'anywhere',
        '$title + &': {
            marginTop: theme.spacing(-1.5),
        },
    },
    divider: {
        textAlign: 'center',
        margin: theme.spacing(0),
    },
    fields: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'stretch',
        flexWrap: 'wrap',
        '$title ~ &': {
            marginTop: theme.spacing(1),
            width: '100%',
        },
    },
    progress: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        lineHeight: 1,
        position: 'absolute',
    },
    submitButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        position: 'relative',
    },
    submitting: {
        visibility: 'hidden',
    },
    fieldError: {
        color: theme.palette.error.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    submitError: {
        color: theme.palette.error.main,
        alignText: 'center',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
});
