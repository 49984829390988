/* eslint-disable camelcase */
import { useEffect, useRef } from 'react';

import Logger from '../../helpers/Logger';
import PropTypes from 'prop-types';
import moment from 'moment';
import noop from 'lodash/noop';
import useShouldEnableLiveChat from './Userpilot.hook';

class Pilot {
    constructor() {
        this._tracks = [];
        this._id = null;
        this._ident = {};
        this._contentId = null;
    }

    reload() {}

    identify(id, ident) {
        this._id = id;
        this._ident = {
            ...this._ident,
            ...ident,
        };
    }

    track(...args) {
        this._tracks.push(args);
    }

    reset() {
        this._tracks.length = 0;
        this._id = null;
        this._ident = {};
    }

    trigger(contentId) {
        this._contentId = contentId;
    }

    get id() {
        return this._id;
    }

    get tracks() {
        return this._tracks.slice(0);
    }

    get ident() {
        return { ...this._ident };
    }

    get contentId() {
        return this._contentId;
    }
}
const userpilotSingleton = new Pilot();
window.userpilot = userpilotSingleton;

const loadedPredicate = () => {
    // Predicate, will throw when not initialized
    if (window.userpilot === userpilotSingleton) {
        throw 'Not Loaded';
    }

    if (userpilotSingleton.id) {
        window.userpilot.identify(userpilotSingleton.id, userpilotSingleton.ident);
    }

    userpilotSingleton.tracks.forEach(track =>
        window.userpilot.track.apply(window.userpilot, track)
    );

    userpilotSingleton.reset();
    window.userpilot.reload();
};

export function asyncOnReady(predicate, { timeout = 20000, interval = 60, attempts = 200 } = {}) {
    if (typeof predicate !== 'function') {
        return Promise.reject(new Error('predicate must be a function'));
    }
    if (typeof timeout !== 'number') {
        return Promise.reject(new Error('timeout must be given'));
    }

    let resolved = false;
    let success;
    let rejected;
    let afterAll = noop;
    const markResolved = response => (
        ((resolved = true), (success = true), (rejected = false)), afterAll(response)
    );
    const markRejected = error => {
        resolved = true;
        success = false;
        rejected = false;
        return error;
    };

    const promise = new Promise(function (resolve, reject) {
        let intervalId;
        let timeoutId;
        let tries = 0;
        function check() {
            if (rejected === true) {
                clearInterval(intervalId);
                clearInterval(timeoutId);
                reject(markRejected(new Error('rejected by unmount')));
            } else {
                try {
                    predicate();
                    clearInterval(intervalId);
                    clearInterval(timeoutId);
                    return resolve(markResolved());
                } catch (error) {
                    tries++;
                }
                if (tries >= attempts) {
                    clearInterval(intervalId);
                    clearInterval(timeoutId);
                    reject(markRejected(new Error('too many attempts')));
                }
            }
        }
        check();

        intervalId = setInterval(check, interval || 60);
        timeoutId = setTimeout(() => {
            clearInterval(intervalId);
            reject(markRejected(new Error('timeout')));
        }, timeout);
    });

    promise.afterAll = callback => ((afterAll = callback), promise);
    promise.isResolved = () => resolved;
    promise.isSuccessful = () => success;
    promise.catch(error => {
        Logger.info('Userpilot: asyncOnReady', error);
    });

    return {
        reject() {
            rejected = true;
        },
    };
}

const cleanupEmbed = () => {
    document.querySelectorAll('script').forEach(element => {
        if (element.src.indexOf('https://deploy.userpilot.io') === 0) {
            element.parentNode.removeChild(element);
        }
    });
    window.userpilot = userpilotSingleton;
};

const loadEmbed = id => {
    const baseScriptUrl = 'https://js.userpilot.io/';

    const scriptPath = `${id}/latest.js`;
    const scriptUrl = `${baseScriptUrl}${scriptPath}`;

    const firstScript = document.getElementsByTagName('script')[0];
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = scriptUrl;
    firstScript.parentNode.insertBefore(script, firstScript);
};

const Userpilot = props => {
    const {
        user,
        clearContentId,
        customer,
        customerId,
        toggleUserpilotExperienceEnabled,
        userpilotId,
        userpilotContentId,
        userRole,
    } = props;
    const {
        created,
        name,
        email,
        firstName: userFirstName,
        lastName: userLastName,
        username,
        userId = username,
        zJwt,
    } = user;
    const { name: customerName, custUserHash, subscription: { planId: plan = '' } = {} } = customer;
    const embedReady = useRef(null);

    useShouldEnableLiveChat(toggleUserpilotExperienceEnabled);

    useEffect(() => {
        loadEmbed(userpilotId);
        if (!embedReady.current) {
            embedReady.current = asyncOnReady(loadedPredicate);
        }
        return () => {
            embedReady.current && embedReady.current.reject();
            embedReady.current = null;
            cleanupEmbed();
        };
    }, [userpilotId]);

    useEffect(() => {
        if (userpilotContentId) {
            window.userpilot.on('completed', clearContentId);
            window.userpilot.on('dismissed', clearContentId);
            window.userpilot.trigger(userpilotContentId);
        } else {
            window.userpilot.reload();
        }
        return () => {
            if (userpilotContentId) {
                window.userpilot.off('completed', clearContentId);
                window.userpilot.off('dismissed', clearContentId);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userpilotContentId]);

    useEffect(() => {
        if (userId && email && userRole && created && customerId && plan) {
            const company = {
                id: customerId,
                tier: plan,
            };
            const created_at = moment(created).toISOString();
            window.userpilot.identify(
                userId, // Used to identify users
                {
                    company,
                    created_at,
                    custUserHash,
                    email,
                    name,
                    plan,
                    userFirstName,
                    userLastName,
                    userRole,
                    zJwt,
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        created,
        customerId,
        customerName,
        custUserHash,
        email,
        name,
        plan,
        userFirstName,
        userId,
        userLastName,
        userRole,
    ]);

    return null;
};

Userpilot.propTypes = {
    clearContentId: PropTypes.func,
    customer: PropTypes.object,
    customerId: PropTypes.string,
    isLoading: PropTypes.bool,
    toggleUserpilotExperienceEnabled: PropTypes.bool,
    user: PropTypes.object,
    userId: PropTypes.string,
    userpilotContentId: PropTypes.string,
    userpilotId: PropTypes.string,
    userRole: PropTypes.string,
};

export default Userpilot;
