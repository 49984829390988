import { getConstraints, getValues, shouldShowFieldError } from './ValidationForm.selectors';

import Actions from './ValidationForm.actions';
import get from 'lodash/get';
import identity from 'lodash/identity';
import validatejs from 'validate.js';

export const updateField = (field, value, onFieldChange) => (dispatch, getState) => {
    const state = getState();
    const { constraints, values } = state;
    const { [field]: constraint } = constraints;
    const { pattern, mask = identity } = constraint || {};
    const oldValue = get(values, field);
    const matches = pattern ? pattern.exec(value) : [value];
    const newValue = mask(matches ? matches[0] : value);
    if (oldValue !== newValue) {
        dispatch(Actions.fieldChange(field, newValue, oldValue));
        const additionalChanges = onFieldChange(field, newValue, oldValue, getValues(state)) || {};
        Object.entries(additionalChanges).forEach(([key, val]) => {
            dispatch(Actions.fieldChange(key, val, val));
        });
    }
};

export const validateForm = (payload, extraValidation) => (dispatch, getState) => {
    const state = getState();
    const constraints = getConstraints(state);
    const values = { ...getValues(state), ...(payload || {}) };
    const errors = validatejs(values, constraints);
    const otherErrors = extraValidation(values);
    const hasErrors = !!(errors || otherErrors);
    const allErrors = hasErrors
        ? Object.entries({ ...errors, ...otherErrors }).reduce((shownErrors, [field, error]) => {
              if (shouldShowFieldError(state, field)) {
                  shownErrors = shownErrors || {};
                  shownErrors[field] = error;
              }
              return shownErrors;
          }, {})
        : undefined;
    dispatch(Actions.validate(allErrors));
    return Promise.resolve(allErrors);
};
