import { formatAudit } from '../common/services/format';

export const assignProduct = productId => details => ({
    ...details,
    product: productId,
});

export const assignVendor = vendorId => details => ({
    ...details,
    vendor: vendorId,
});

export const formatDocument = data => {
    const { docId, documentId = docId, ...document } = data;
    return {
        documentId,
        ...document,
    };
};

export const formatDocumentAudit = data => {
    const { auditId, created, updated, type } = formatAudit(data);
    return {
        auditId,
        created,
        updated,
        type,
        document: formatDocument(data),
    };
};

export const formatDocumentVersion = data => {
    const {
        docId: documentId,
        s3Version: versionId,
        isSignificant,
        isValidated,
        created,
        updated,
        ...document
    } = data;
    return {
        versionId,
        id: versionId,
        isSignificant,
        isValidated,
        created,
        updated,
        document: formatDocument({ documentId, ...document }),
    };
};
