import palette from './palette';

export default {
    useNextVariants: true,
    h1: {
        color: palette.text.primary,
        fontFamily: "'Jost', sans-serif",
        fontWeight: '700',
        fontSize: '35px',
        letterSpacing: '-0.24px',
        lineHeight: '1.14',
    },
    h2: {
        color: palette.text.primary,
        fontFamily: "'Jost', sans-serif",
        fontWeight: '700',
        fontSize: '29px',
        letterSpacing: '-0.24px',
        lineHeight: '1.1',
    },
    h3: {
        color: palette.text.primary,
        fontFamily: "'Jost', sans-serif",
        fontWeight: '700',
        fontSize: '24px',
        letterSpacing: '-0.06px',
        lineHeight: '1.17',
    },
    h4: {
        color: palette.text.secondary,
        fontFamily: "'Jost', sans-serif",
        fontWeight: '700',
        fontSize: '20px',
        letterSpacing: '-0.06px',
        lineHeight: '1.2',
    },
    h5: {
        color: palette.text.secondary,
        fontFamily: "'Jost', sans-serif",
        fontWeight: '700',
        fontSize: '16px',
        letterSpacing: '-0.05px',
        lineHeight: '1.25',
    },
    h6: {
        color: palette.text.secondary,
        fontFamily: "'Jost', sans-serif",
        fontWeight: '700',
        fontSize: '14px',
        letterSpacing: '-0.05px',
        lineHeight: '1.43',
    },
    subtitle1: {
        fontFamily: "'Roboto', sans-serif",
        color: palette.text.primary,
        fontSize: '16px',
        letterSpacing: '-0.05px',
        lineHeight: '1.56',
    },
    subtitle2: {
        fontFamily: "'Roboto', sans-serif",
        color: palette.text.primary,
        fontSize: '14px',
        letterSpacing: 0,
        lineHeight: '1.14',
    },
    body1: {
        fontFamily: "'Roboto', sans-serif",
        color: palette.text.primary,
        fontSize: '14px',
        letterSpacing: '-0.05px',
        lineHeight: '1.5',
    },
    body2: {
        fontFamily: "'Roboto', sans-serif",
        color: palette.text.primary,
        fontSize: '12px',
        letterSpacing: '-0.04px',
        lineHeight: '1.17',
    },
    button: {
        fontFamily: "'Jost', sans-serif",
        fontSize: '14px',
    },
    caption: {
        fontFamily: "'Roboto', sans-serif",
        color: palette.text.secondary,
        fontSize: '12px',
        letterSpacing: '0.3px',
        lineHeight: '1.33',
    },
};
