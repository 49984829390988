import { createSchema } from '/b2b/common/helpers/Schema';

export default createSchema({
    code: {
        label: 'Authenticator Code',
        presence: { allowEmpty: false },
        props: {
            placeholder: 'Enter your code...',
            InputLabelProps: {
                shrink: true,
            },
        },
        length: {
            is: 6,
            notValid: 'is not valid',
        },
    },
});
