const promiseReduce = ([response, error], [resolve, reject]) => {
    if (error) {
        try {
            return reject ? [reject(error), undefined] : [undefined, error];
        } catch (err) {
            // Our reject re-threw
            return [undefined, err];
        }
    }
    try {
        return [resolve ? resolve(response) : response, undefined];
    } catch (err) {
        // Our resolve threw
        return [undefined, err];
    }
};

/**
    @typedef PollingOptions
    @type {Object}
    @property {boolean=true} immediate If the Poll should start immediately
    @property {number=1} delay The delay before starting the Poll in milliseconds. Defaults to 1 millisecond.
    @property {number=3000} interval The interval in milliseconds at which to trigger a new Poll request after the previous one succeeds. Defaults to 3 seconds (3000 milliseconds)
    @property {number=0} retry The number of times the Poll will retry after a timeout. Defaults to 0.
    @property {number=10000} timeout The number of milliseconds the the Poll will wait for a response before timing out. Defaults to 10 seconds (10000 milliseconds)
*/

/**
    @typedef PollPromise
    @type {Object}
    @property {Function} start Starts the polling
    @property {Function} abort Aborts the currently running Poll
    @property {Function} then Thenable callback function that will trigger every time the Poll resolves
    @property {Function} change Thenable callback function that will trigger every time the Poll response value is different than the previous Poll
    @property {Function} catch Thenable callback function that will trigger every time the Poll rejects
*/

/**
 *  Poll
 *
 *  @param   {function(AbortController):Promise} makeRequest Your function that returns a Promise.
 *  @param   {PollingOptions} options The Polling options.
 *  @returns {PollPromise} A Promise-Like object that triggers every time the Poll completes
 */
const Poll = (
    makeRequest,
    { immediate = true, delay = 0, interval = 3000, retry = 0, timeout = 10000 } = {}
) => {
    let currentPoll = 0;
    let timeoutId = 0;
    let retries = 0;
    let priorResponse;
    let raceReject;
    let restartPoll;
    let controller;

    const resolves = [];
    const changes = [];

    const abortError = new DOMException(`Aborted`, `AbortError`);
    const timeoutError = new DOMException(`Timeout`, 'TimeoutError');
    const abortListener = () => raceReject && raceReject(abortError);
    const timeoutListener = reject => reject && reject(timeoutError);

    const connectionRegainedListener = () => {
        window.removeEventListener('online', connectionRegainedListener);
        restartPoll(1);
    };

    const success = response => {
        raceReject = undefined;
        // Make sure the timeout is canceled
        clearTimeout(timeoutId);

        // eslint-disable-next-line eqeqeq
        if (response != undefined && JSON.stringify(priorResponse) !== JSON.stringify(response)) {
            priorResponse = response;
            changes.reduce(
                (previousResponse, callback) => callback(previousResponse) ?? previousResponse,
                response
            );
        }
        retries = 0;
        const [, error] = resolves.reduce(promiseReduce, [response, undefined]);
        if (!error) {
            restartPoll(interval);
        }
    };

    const failure = error => {
        raceReject = undefined;
        // Make sure any currently running Polls are stopped
        clearTimeout(currentPoll);
        // Make sure the timeout is canceled
        clearTimeout(timeoutId);
        if (error && error.code === 0 && !navigator.onLine) {
            // No response, lost connection
            window.addEventListener('online', connectionRegainedListener);
            return;
        }
        if (error) {
            switch (error.name) {
                case 'TimeoutError': {
                    if (!controller || controller.signal.aborted) {
                        // If we already aborted before the timeout, do nothing
                        return;
                    }
                    // Test if we need to retry
                    if (retries < retry) {
                        retries++;
                        restartPoll(0);
                        return;
                    }
                    controller.signal.removeEventListener('abort', abortListener);
                    controller.abort();
                    break;
                }
                case 'AbortError': {
                    controller && controller.signal.removeEventListener('abort', abortListener);
                    break;
                }
            }
        }
        controller && resolves.reduce(promiseReduce, [undefined, error ?? new Error()]);
        controller = undefined;
    };

    restartPoll = function (success, failure, milliseconds) {
        if (controller) {
            // There's an existing Poll we need to clean up
            const existingController = controller;
            // Clearing the pointer so we do not trigger abort for the Poll
            controller = undefined;
            existingController.signal.removeEventListener('abort', abortListener);
            existingController.abort();
        }
        // New Abort controller for EVERY request since they cannot be reused
        const abortController = new AbortController();
        abortController.signal.addEventListener('abort', abortListener);
        controller = abortController;
        // Create a temporary reject in case we abort between resolutions
        raceReject = failure;
        currentPoll = setTimeout(() => {
            // Referencing the scoped `abortController` in case the Poll was aborted or restarted before the timeout
            if (!abortController.signal.aborted) {
                Promise.race([
                    new Promise((res, rej) => {
                        try {
                            Promise.resolve(makeRequest(abortController)).then(res, rej);
                        } catch (e) {
                            rej(e);
                        }
                    }),
                    new Promise((res, rej) => {
                        raceReject = rej;
                        timeoutId = setTimeout(timeoutListener, timeout, rej);
                    }),
                ])
                    .then(success, failure)
                    .catch(() => {
                        clearTimeout(timeoutId);
                    });
            }
        }, milliseconds);
    }.bind(Poll, success, failure);

    if (immediate) {
        restartPoll(Math.max(0, delay));
    }

    const promiseLikeObject = {
        start: () => (currentPoll === 0 && restartPoll(0), promiseLikeObject),
        abort: () => (controller && controller.abort(), promiseLikeObject),
        then: (fn, rej) => (resolves.push([fn, rej]), promiseLikeObject),
        change: fn => (changes.push(fn), promiseLikeObject),
        catch: fn => (resolves.push([undefined, fn]), promiseLikeObject),
    };

    return promiseLikeObject;
};

export default Poll;
