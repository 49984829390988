import { DEFAULT_LOCALE, extend as extendLocale } from '../helpers/i18n';
import { allSettled, fetchJSON, handleError } from './helpers';

import moment from 'moment';

const importLocalePhrases = locale =>
    fetchJSON(`/assets/locale/${locale}.json`).then(json => {
        extendLocale(json, locale);
        return {
            code: locale,
            locale: { ...json },
        };
    });

const importLocalizedDateModule = locale => {
    // 'en' module is bundled with moment
    if (locale === 'en') {
        moment.locale(locale);
        return Promise.resolve();
    }

    return import(`moment/locale/${locale}`).catch(handleError).then(() => moment.locale(locale));
};

export const fetchLocale = ({ locale = DEFAULT_LOCALE } = {}) =>
    allSettled([importLocalizedDateModule(locale), importLocalePhrases(locale)]).then(
        ([, { state, value }]) => {
            return state === 'fulfilled' ? value : undefined;
        }
    );
