import theme from '../default';

export default {
    root: {
        minWidth: 0,
        '& .MuiInputAdornment-root p': {
            color: theme.palette.text.disabled,
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: '6rem',
        },
    },
};
