import Component from './Account.component';
import { connect } from 'react-redux';
import { fetchUserDetails } from './Account.commands';
import fromState from '../../../common/state/selectors';

const mapStateToProps = state => {
    const userId = fromState.Auth.user.getId(state);

    const hasPermissionToUpdateCognitoMFA = fromState.Auth.user.hasPermission(state, {
        toPerform: 'updateCognito',
        on: 'account.mfa',
    });
    const hasPermissionToUpdateCognitoPassword = fromState.Auth.user.hasPermission(state, {
        toPerform: 'updateCognito',
        on: 'account.password',
    });

    return {
        hasPermissionToUpdateCognitoMFA,
        hasPermissionToUpdateCognitoPassword,
        isLoading: fromState.Auth.isLoading(state),
        error: fromState.Auth.getError(state),
        userId,
        details: fromState.DB.user.getEntityById(state, userId),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchUserDetails: userId => () => dispatch(fetchUserDetails({ userId })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
