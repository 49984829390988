import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    singleParameter,
} from '/b2b/common/helpers/ReduxHelpers';

import { DOMAIN } from './AuthUserNotifications.constants';

export const events = {
    markRead: singleParameter,
    markUnread: singleParameter,
};

export const asyncEvents = makeAsyncEvents(['fetchResults', 'refresh'], true);
export const markAllEvents = makeAsyncEvents(['markAll'], true);
export const types = makeActionTypes(
    [...Object.keys(events), ...Object.keys(asyncEvents), ...Object.keys(markAllEvents)],
    DOMAIN
);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
        ...markAllEvents,
    },
    types
);
