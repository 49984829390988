import { FormControl, FormControlLabel, FormHelperText, Switch as MuiSwitch } from '@mui/material';
import React, { useRef } from 'react';

import Locale from '../Locale';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import startCase from 'lodash/startCase';
import useBoundCallback from '../../helpers/Hooks/useBoundCallback';
import { v4 as uuid } from 'uuid';
import { withFormikField } from './FormikFields';

const useStyles = makeStyles(props => ({
    root: {
        ...props.styles?.root,
    },
}));

/**
 * @typedef {Object} Base
 * @property {Object=} translationOptions The `options` object provided to all internal <Locale/> translations for the TextField
 * @property {boolean} inverted Reverses the visual representation of the `checked` value
 *
 * @typedef {Base & Pick<import('@mui/material').FormControlLabelProps, 'labelPlacement'> & Pick<import('@mui/material').FormControlProps, 'error' | 'fullWidth' | 'required'> & Pick<import('@mui/material').TextFieldProps, 'label' | 'helperText'> & import('@mui/material').SwitchProps} SwitchProps
 */

/**
 * @type {React.ForwardRefExoticComponent<SwitchProps>}
 */
export const Switch = React.forwardRef((props, ref) => {
    const {
        'aria-describedby': ariaDescribedBy,
        checked,
        disabled,
        error,
        fullWidth,
        helperText,
        id,
        inverted,
        name,
        onChange,
        required,
        translationOptions,
        label = name ? startCase(`${name}`) : undefined,
        labelPlacement,
        // The following values may be passed by FormikField and we need to filter them out
        indeterminate, // eslint-disable-line no-unused-vars
        multiple, // eslint-disable-line no-unused-vars
        placeholder, // eslint-disable-line no-unused-vars
        type, // eslint-disable-line no-unused-vars
        ...remain
    } = props;

    const classes = useStyles(props);
    const errorId = useRef(uuid());

    const _onChange = useBoundCallback(
        (onChange, inverted, event, value) => {
            if (onChange) {
                if (inverted) {
                    event.target.checked = !value;
                }
                onChange(event, inverted ? !value : value);
            }
        },
        [onChange, inverted]
    );

    return (
        <FormControl
            style={{ minWidth: 0 }}
            fullWidth={!!fullWidth}
            error={error}
            required={!!required}
            className={classes?.root}
        >
            <FormControlLabel
                labelPlacement={labelPlacement}
                label={
                    !label || React.isValidElement(label) ? (
                        label
                    ) : (
                        <Locale path={label} options={translationOptions} />
                    )
                }
                control={
                    <MuiSwitch
                        inputProps={{ id }}
                        inputRef={ref}
                        checked={
                            checked !== undefined ? (inverted ? !checked : checked) : undefined
                        }
                        color="secondary"
                        disabled={disabled}
                        name={name}
                        aria-describedby={
                            !helperText // If there is an error, the aria-describedby needs to reference the error message, and that's handled by MUI internally
                                ? ariaDescribedBy
                                : errorId.current
                        }
                        sx={{
                            display: 'flex',
                            alignSelf: 'baseline',
                        }}
                        onChange={_onChange}
                        {...remain}
                    />
                }
            />
            {helperText ? (
                <FormHelperText id={errorId.current} sx={{ whiteSpace: 'pre-line', marginX: 0 }}>
                    {helperText}
                </FormHelperText>
            ) : null}
        </FormControl>
    );
});

Switch.propTypes = {
    'aria-describedby': PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.any,
    id: PropTypes.string,
    indeterminate: PropTypes.bool,
    inverted: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    labelPlacement: PropTypes.oneOf(['bottom', 'end', 'start', 'top']),
    multiple: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    translationOptions: PropTypes.object,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Switch;

export const FormikSwitch = withFormikField(Switch, { type: 'checkbox' });
FormikSwitch.displayName = 'FormikSwitch';
