import { denormalize } from 'normalizr';
import get from 'lodash/get';
import { getStore as getDB } from '/b2b/common/state/db/DB.selectors';
import schema from './DataStoreConnectionField.schema';

export const DOMAIN = `db.${schema.key}`;

export const getStore = state => get(state, DOMAIN) || {};

export const getEntityById = (state, id) => denormalize(id, schema, getDB(state)) || {};
export const getEntitiesForIds = (state, ids) => denormalize(ids, [schema], getDB(state));

export const getEntityFieldById = (state, { id, field }) =>
    get(getStore(state), `${id}.${field}`) || null;

export const getFieldsByDataStoreId = (state, id) =>
    Object.values(getStore(state, id)).filter(field => Number(field.connection_id) === Number(id));
