import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { translate } from '../../helpers/i18n';
import { useSnackbar } from 'notistack';

const SnackbarTray = props => {
    const [displayed, setDisplayed] = useState([]);
    const { snacks, snackOrder, deleteSnack } = props;
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        let displayedIndex = 0;
        if (snackOrder.length) {
            setDisplayed(
                snackOrder.map(key => {
                    while (displayedIndex < displayed.length && key !== displayed[displayedIndex]) {
                        // We dismissed a snack
                        closeSnackbar(displayed[displayedIndex]);
                        displayedIndex++;
                    }
                    if (displayedIndex >= displayed.length) {
                        // New snack
                        const { message, options } = snacks[key];
                        enqueueSnackbar(translate(message, options), {
                            key,
                            variant: 'info',
                            style: { whiteSpace: 'pre-line' },
                            autoHideDuration: 5000,
                            ...options,
                            onClose: (event, reason, closedKey) => {
                                if (options.onClose) {
                                    options.onClose(event, reason, closedKey);
                                }
                                switch (reason) {
                                    case 'clickaway': {
                                        break;
                                    }
                                    default: {
                                        deleteSnack({ event, reason, key: closedKey });
                                    }
                                }
                            },
                        });
                    } else {
                        displayedIndex++;
                    }
                    return key;
                })
            );
        } else {
            // All snacked removed
            displayed.map(key => {
                closeSnackbar(key);
            });
            setDisplayed([]);
        }
    }, [snackOrder]);

    return null;
};

SnackbarTray.propTypes = {
    deleteSnack: PropTypes.func,
    snacks: PropTypes.object,
    snackOrder: PropTypes.array,
};

export default SnackbarTray;
