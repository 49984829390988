import { matchPath, useHistory } from 'react-router';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import useBoundCallback from './useBoundCallback';

export function useNavigationBlock(props) {
    const { enabled, onNavigate, onCancel, onConfirm } = props;
    const history = useHistory();
    const unblock = useRef();

    const [locationPrevented, setLocationPrevented] = useState(false);

    const redirect = useCallback((redirect, action) => {
        // Temporarily unblock
        const originalUnblock = unblock.current;
        unblock.current = false;
        if (location) {
            switch (action) {
                case 'PUSH': {
                    history.push(redirect);
                    break;
                }
                case 'POP': {
                    history.goBack();
                    history.replace(redirect);
                    break;
                }
                default: {
                    history.replace(redirect);
                    break;
                }
            }
        }
        if (unblock.current === false) {
            unblock.current = originalUnblock;
        } else {
            originalUnblock();
        }
    }, []);

    const shouldAllowNavigation = useBoundCallback(
        (shouldAllow, location, action) => {
            // Check for temporary unblock
            if (unblock.current !== false) {
                const allow = shouldAllow(location, action);
                if (allow === false || typeof allow === 'string') {
                    if (typeof allow === 'string') {
                        // Possible redirect, so check if there is a route match
                        const { pathname } = location;
                        const match = matchPath(pathname, { exact: true, path: allow });
                        if (match) {
                            // We want to stay on the current page
                            return false;
                        }
                        // We have a redirect
                        redirect(allow, action);
                        return false;
                    }
                    setLocationPrevented({ location, action });
                    // Returning false will stop navigation
                    return false;
                }
                return true;
            }
            return true;
        },
        [onNavigate]
    );

    const handleConfirm = useBoundCallback(
        (confirm, prevented, ...args) => {
            const promise = (confirm && confirm(...args)) || Promise.resolve();
            return promise.then(response => {
                const { location, action } = prevented;
                redirect(location, action);
                return response;
            });
        },
        [onConfirm, locationPrevented]
    );

    const handleCancel = useBoundCallback(
        (cancel, ...args) => {
            const promise = (cancel && cancel(...args)) || Promise.resolve();
            return promise.then(response => {
                return response;
            });
        },
        [onCancel]
    );

    useLayoutEffect(() => {
        if (enabled) {
            unblock.current = history.block(shouldAllowNavigation);
            // Current path could be a block...so lets run it just to be sure
            shouldAllowNavigation(history.location, 'REPLACE');
        }
        return () => {
            unblock.current && unblock.current();
            unblock.current = undefined;
        };
    }, [enabled]);

    return {
        onCancel: handleCancel,
        onConfirm: handleConfirm,
    };
}

export default useNavigationBlock;
