import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    noParameters,
} from '/b2b/common/helpers/ReduxHelpers';

import { DOMAIN } from './AuthCustomerUsers.constants';

export const events = {
    openAddUserDialog: noParameters,
    closeAddUserDialog: noParameters,
};

export const asyncEvents = makeAsyncEvents(['fetchResults', 'update', 'delete'], true);
export const creationEvents = makeAsyncEvents(['create'], true);

export const types = makeActionTypes(
    [...Object.keys(events), ...Object.keys(asyncEvents), ...Object.keys(creationEvents)],
    DOMAIN
);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
        ...creationEvents,
    },
    types
);
