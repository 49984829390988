import PropTypes from 'prop-types';
import React from 'react';
import ReselectMFA from '../components/ReselectMFA';
import ValidationForm from '/b2b/common/components/ValidationForm';
import makeStyles from '@mui/styles/makeStyles';
import schema from './SetupPhone.schema';
import styles from './SetupPhone.styles';

const useStyles = makeStyles(styles);

const SetupPhone = props => {
    const { phone, onSubmit } = props;
    const classes = useStyles(props);
    return (
        <div className={classes.content}>
            <div className={classes.contentBody}>
                <ValidationForm
                    title="Phone Setup"
                    subtitle="In order to use SMS as your MFA option, you must verify your phone number. Enter your phone number for a confirmation code sent via text message."
                    submitText="Update Phone Number"
                    schema={schema}
                    onSubmit={onSubmit}
                    defaultValues={{ phone }}
                    allowSubmit
                    continuousValidation={false}
                >
                    <ReselectMFA mfaType="SMS" />
                </ValidationForm>
            </div>
        </div>
    );
};

SetupPhone.propTypes = {
    className: PropTypes.string,
    phone: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onReselectMFA: PropTypes.func.isRequired,
};

export default SetupPhone;
