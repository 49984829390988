export default theme => ({
    root: {
        textAlign: 'center',
        margin: theme.spacing(1, 0),
    },
    label: {
        flex: '1 1 auto',
        maxWidth: 'fit-content',
    },
});
