import React from 'react';
import { SvgIcon } from '@mui/material';

export default function Osano(props) {
    return (
        <SvgIcon color="secondary" fontSize="large" {...props}>
            <path
                className="logo"
                d="M6.53883013,12.3505589 C6.5705829,13.7125274 7.07353852,14.8762959 8.04790054,15.8418645 C9.02165192,16.8078368 10.3389845,17.2908229 11.9998982,17.2908229 C13.6604049,17.2908229 14.9777374,16.8078368 15.9520995,15.8418645 C16.9258509,14.8762959 17.4288065,13.7125274 17.4609663,12.3505589 L17.4609663,11.4953929 C17.4288065,10.1338282 16.9258509,8.97005967 15.9520995,8.0040874 C14.9777374,7.0385188 13.6604049,6.55533083 11.9998982,6.55533083 C10.3389845,6.55533083 9.02165192,7.0385188 8.04790054,8.0040874 C7.07353852,8.97005967 6.5705829,10.1338282 6.53883013,11.4953929 L6.53883013,12.3505589 Z M24,12.8731043 C23.8719712,15.81825 22.7382348,18.3516566 20.5987906,20.4733241 C18.3471941,22.7219445 15.4808966,23.8459519 11.9998982,23.8459519 C8.5186963,23.8459519 5.65260239,22.7219445 3.40100584,20.4733241 C1.2611546,18.3516566 0.127418137,15.81825 0,12.8731043 L0,10.9728476 C0.127418137,8.02790368 1.2611546,5.49469893 3.40100584,3.3726278 C5.65260239,1.1244111 8.5186963,0 11.9998982,0 C15.4808966,0 18.3471941,1.1244111 20.5987906,3.3726278 C22.7382348,5.49469893 23.8719712,8.02790368 24,10.9728476 L24,12.8731043 Z"
            ></path>
        </SvgIcon>
    );
}
