import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';

import Locale from '../Locale';
import PropTypes from 'prop-types';
import SubmitButton from '../SubmitButton';
import ValidationFormFields from './ValidationFormFields.component';
import classNames from 'classnames';
import { emptyObject } from './ValidationForm.selectors';
import makeStyles from '@mui/styles/makeStyles';
import styles from './ValidationForm.styles';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles(styles);

const scrollToRef = ref => ref.current && window.scrollTo(0, ref.current.offsetTop);

const ValidationForm = props => {
    const {
        alignSubmit = 'stretch',
        className,
        defaultValues = emptyObject,
        errors = emptyObject,
        fields,
        children,
        disabled,
        extraFields,
        formId,
        isLoading,
        onSubmit,
        onReset,
        canSubmit,
        preventSubmitOnEnter,
        helperText,
        noPadding,
        submitError,
        title,
        schema,
        subtitle,
        submitButton,
        submitText = 'Submit',
        resetButton,
        resetText = 'Reset',
        onFieldBlur,
        onFieldChange,
        usePopper,
        onFieldRevert,
        touched = emptyObject,
        values,
    } = props;
    const classes = useStyles(props);
    const errorRef = useRef(null);
    const id = useRef(formId || uuid());

    useEffect(() => {
        submitError && scrollToRef(errorRef);
    }, [submitError]);

    const SubmitButtonComponent = submitButton || SubmitButton;
    const ResetButtonComponent = resetButton || Button;
    return (
        <form
            id={id.current}
            className={classNames(classes.form, noPadding ? classes.noPadding : '', className)}
            onSubmit={
                onSubmit
                    ? onSubmit
                    : event => {
                          event.preventDefault();
                          return false;
                      }
            }
            disabled={disabled}
        >
            {preventSubmitOnEnter && (
                <div style={{ display: 'none' }}>
                    <input
                        type="submit"
                        onClick={e => {
                            e.preventDefault();
                            return false;
                        }}
                    />
                </div>
            )}
            {title && (
                <Typography className={classes.title} variant="h2">
                    <Locale path={title} />
                </Typography>
            )}
            {subtitle && (
                <Typography className={classes.subtitle} variant="body1">
                    <Locale path={subtitle} />
                </Typography>
            )}
            <div className={classes.fields}>
                <ValidationFormFields
                    defaultValues={defaultValues}
                    errors={errors}
                    disabled={disabled}
                    isLoading={isLoading}
                    helperText={helperText}
                    schema={schema}
                    onFieldBlur={onFieldBlur}
                    onFieldChange={onFieldChange}
                    onFieldRevert={onFieldRevert}
                    touched={touched}
                    values={values}
                    fields={fields}
                    usePopper={usePopper}
                />
                {extraFields}
            </div>
            {submitError && (
                <Typography ref={errorRef} className={classes.submitError} variant="body2">
                    <Locale path={submitError} />
                </Typography>
            )}
            <Box display="flex" sx={{ justifyContent: alignSubmit }}>
                {onSubmit ? (
                    <SubmitButtonComponent
                        formId={id.current}
                        loading={isLoading}
                        onClick={onSubmit}
                        disabled={!canSubmit}
                        className={classes.submitButton}
                        sx={{ flexGrow: alignSubmit !== 'stretch' ? '0' : '1' }}
                    >
                        <Locale path={submitText} />
                    </SubmitButtonComponent>
                ) : null}
                {onReset ? (
                    <ResetButtonComponent
                        onClick={onReset}
                        disabled={isLoading}
                        className={classes.submitButton}
                        sx={{ flexGrow: alignSubmit !== 'stretch' ? '0' : '1' }}
                    >
                        <Locale path={resetText} />
                    </ResetButtonComponent>
                ) : null}
            </Box>
            {children}
        </form>
    );
};

ValidationForm.propTypes = {
    alignSubmit: PropTypes.oneOf([
        'flex-start',
        'flex-end',
        'center',
        'space-between',
        'space-around',
    ]),
    canSubmit: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    defaultValues: PropTypes.object,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    fields: PropTypes.array,
    formId: PropTypes.string,
    extraFields: PropTypes.any,
    isLoading: PropTypes.bool,
    noPadding: PropTypes.bool,
    onFieldBlur: PropTypes.func,
    onFieldChange: PropTypes.func,
    onFieldRevert: PropTypes.func,
    onReset: PropTypes.func,
    onSubmit: PropTypes.func,
    preventSubmitOnEnter: PropTypes.bool,
    helperText: PropTypes.object,
    schema: PropTypes.object,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    resetButton: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    resetText: PropTypes.string,
    submitButton: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    submitText: PropTypes.string,
    submitError: PropTypes.string,
    usePopper: PropTypes.bool,
    touched: PropTypes.object,
    values: PropTypes.object,
};

export default ValidationForm;
