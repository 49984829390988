import {
    CRUDDetailsReducers,
    errorReducer,
    genericReducer,
    loadingReducer,
    passthroughReducer,
} from '../../common/helpers/ReduxHelpers';
import configuration, {
    initialState as initialConfigurationState,
} from './configuration/Configuration.reducer';
import consents, { initialState as initialConsentsState } from './consents/Consents.reducer';
import rules, { initialState as initialRulesState } from './rules/Rules.reducer';
import tattles, { initialState as initialTattlesState } from './tattles/Tattles.reducer';
import versions, { initialState as initialVersionsState } from './versions/Versions.reducer';

import { types as ActionTypes } from './ConfigDetails.actions';
import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import AuthenticatedPaths from '../../routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { COMPLIANCE_TYPES } from '../constants';
import { types as ConfigActionTypes } from './configuration/Configuration.actions';
import { types as CookiesActionTypes } from './configuration/cookies/Cookies.actions';
import { types as DisclosureActionTypes } from './configuration/disclosures/Disclosures.actions';
import { EVENTS } from '../constants';
import { types as IABActionTypes } from './configuration/iab/IAB.actions';
import { types as NavActionTypes } from '../../common/state/nav/Nav.actions';
import { types as PaletteActionTypes } from './configuration/palette/Palette.actions';
import { types as RulesActionTypes } from './rules/Rules.actions';
import { types as ScriptsActionTypes } from './configuration/scripts/Scripts.actions';
import analytics from '../../common/analytics';
import { combineReducers } from 'redux';
import { matchPath } from 'react-router-dom';
import omit from 'lodash/omit';
import reduceReducers from 'reduce-reducers';
import schema from './db/ConsentConfig.schema';
import set from 'lodash/set';

export const initialState = {
    id: '',
    loading: false,
    updating: false,
    publishing: false,
    submitted: false,
    error: null,
    updateError: null,
    publishError: null,
    rulesUpdated: false,
    classificationView: 'unclassified',
    publishedRevision: 0,
    tattleRecordStopped: false,

    preview: COMPLIANCE_TYPES.DEFAULT,
    configuration: initialConfigurationState,
    consents: initialConsentsState,
    tattles: initialTattlesState,
    rules: initialRulesState,
    versions: initialVersionsState,

    domains: [],
    organizations: [],
    name: '',
    mode: 'debug',

    errors: {},
    touched: {},
};

const touchField =
    prefix =>
    (state, { field, value, oldValue }) => {
        if (value !== oldValue) {
            const touched = { ...state };
            set(touched, `${prefix}${field}`, true);
            return touched;
        }
        return state;
    };

const clearFieldError =
    prefix =>
    (state, { field, value, oldValue }) => {
        if (value !== oldValue) {
            return omit(state, `${prefix}${field}`);
        }
        return state;
    };

export default reduceReducers(
    initialState,
    combineReducers({
        ...CRUDDetailsReducers(
            initialState,
            [
                ActionTypes.fetchDetailsBegin,
                ActionTypes.fetchDetailsSuccess,
                ActionTypes.fetchDetailsFailure,
            ],
            schema
        ),
        updating: loadingReducer(
            initialState.updating,
            [
                ActionTypes.createBegin,
                ActionTypes.createSuccess,
                ActionTypes.createFailure,
                ActionTypes.updateBegin,
                ActionTypes.updateSuccess,
                ActionTypes.updateFailure,
                ActionTypes.deleteBegin,
                ActionTypes.deleteSuccess,
                ActionTypes.deleteFailure,
            ],
            schema
        ),
        updateError: errorReducer(
            initialState.updateError,
            [
                ActionTypes.fetchDetailsBegin,
                ActionTypes.createBegin,
                ActionTypes.createSuccess,
                ActionTypes.createFailure,
                ActionTypes.updateBegin,
                ActionTypes.updateSuccess,
                ActionTypes.updateFailure,
                ActionTypes.deleteBegin,
                ActionTypes.deleteSuccess,
                ActionTypes.deleteFailure,
            ],
            schema
        ),
        publishing: loadingReducer(
            initialState.publishing,
            [ActionTypes.publishBegin, ActionTypes.publishSuccess, ActionTypes.publishFailure],
            schema
        ),
        publishError: errorReducer(
            initialState.publishError,
            [
                ActionTypes.fetchDetailsBegin,
                ActionTypes.createBegin,
                ActionTypes.updateBegin,
                ActionTypes.deleteBegin,
                ActionTypes.publishBegin,
                ActionTypes.publishSuccess,
                ActionTypes.publishFailure,
            ],
            schema
        ),
        submitted: genericReducer(initialState.submitted, {
            [ActionTypes.createBegin]: () => true,
            [ActionTypes.fetchDetailsBegin]: () => true,
            [ActionTypes.updateBegin]: () => true,
            [ActionTypes.createSuccess]: () => false,
            [ActionTypes.fetchDetailsSuccess]: () => false,
            [ActionTypes.updateSuccess]: () => false,
            [ActionTypes.fieldErrors]: () => true,
        }),
        configuration,
        consents,
        domains: passthroughReducer(initialState.domains),
        tattles,
        rules,
        versions,
        organizations: passthroughReducer(initialState.organizations),
        name: passthroughReducer(initialState.name),
        mode: passthroughReducer(initialState.mode),
        preview: passthroughReducer(initialState.preview),
        rulesUpdated: genericReducer(initialState.rulesUpdated, {
            [ActionTypes.fetchDetailsSuccess]: (
                state,
                { response: { lastPublished = 0, rulesLastUpdated = 0 } = {} } = {}
            ) => rulesLastUpdated > lastPublished,
            [ActionTypes.publishSuccess]: () => false,
            [RulesActionTypes.createSuccess]: () => true,
            [RulesActionTypes.updateSuccess]: () => true,
            [RulesActionTypes.deleteSuccess]: () => true,
        }),
        publishedRevision: genericReducer(initialState.publishedRevision, {
            [ActionTypes.republishSuccess]: (state, { response: { deployRevision } }) => {
                return deployRevision;
            },
        }),
        errors: genericReducer(initialState.errors, {
            [ActionTypes.fieldChange]: clearFieldError(''),
            [ConfigActionTypes.fieldChange]: clearFieldError('configuration.'),
            [PaletteActionTypes.fieldChange]: clearFieldError('configuration.palette.'),
            [IABActionTypes.fieldChange]: clearFieldError('configuration.iab.'),
            [ScriptsActionTypes.change]: (state, { newRegexp, oldRegexp }) =>
                clearFieldError('configuration.scripts.')(state, {
                    field: oldRegexp,
                    value: oldRegexp,
                    oldValue: newRegexp,
                }),
            [ScriptsActionTypes.classify]: (
                state,
                { classification, storeKey, previousClassification }
            ) =>
                clearFieldError('configuration.scripts.')(state, {
                    field: storeKey,
                    value: classification,
                    oldValue: previousClassification,
                }),
            [ScriptsActionTypes.delete]: (state, { storeKey }) =>
                clearFieldError('configuration.scripts.')(state, {
                    field: storeKey,
                    value: true,
                }),
            [CookiesActionTypes.change]: (state, { newRegexp, oldRegexp }) =>
                clearFieldError('configuration.cookies.')(state, {
                    field: oldRegexp,
                    value: oldRegexp,
                    oldValue: newRegexp,
                }),
            [CookiesActionTypes.classify]: (
                state,
                { classification, storeKey, previousClassification }
            ) =>
                clearFieldError('configuration.cookies.')(state, {
                    field: storeKey,
                    value: classification,
                    oldValue: previousClassification,
                }),
            [CookiesActionTypes.delete]: (state, { storeKey }) =>
                clearFieldError('configuration.cookies.')(state, {
                    field: storeKey,
                    value: true,
                }),
            [DisclosureActionTypes.fieldChange]: (state, { index, field, value, oldValue }) =>
                clearFieldError(`configuration.disclosures.${index}.`)(state, {
                    field,
                    value,
                    oldValue,
                }),
            [DisclosureActionTypes.delete]: (state, { index }) =>
                clearFieldError(`configuration.disclosures.`)(state, {
                    field: index,
                    value: true,
                }),
        }),
        touched: genericReducer(initialState.touched, {
            [ActionTypes.fieldChange]: touchField(''),
            [ConfigActionTypes.fieldChange]: touchField('configuration.'),
            [PaletteActionTypes.fieldChange]: touchField('configuration.palette.'),
            [IABActionTypes.fieldChange]: touchField('configuration.iab.'),
            [IABActionTypes.addIabVendorSuccess]: (state, { response }) => {
                const id = schema.idAttribute(response);
                return touchField('configuration.iab.tcf.vendors.')(state, {
                    field: 'vendors',
                    value: [...(state.configuration?.iab?.tcf?.vendors || []), id],
                });
            },
            [IABActionTypes.removeIabVendorSuccess]: (state, { response }) => {
                const id = schema.idAttribute(response);
                const value = [...(state.configuration?.iab?.tcf?.vendors || [])].filter(
                    existingId => `${existingId}` !== `${id}`
                );
                return touchField('configuration.iab.tcf.vendors.')(state, {
                    field: 'vendors',
                    value,
                });
            },
            [IABActionTypes.clearIabVendorsSuccess]: state => {
                return touchField('configuration.iab.tcf.vendors.')(state, {
                    field: 'vendors',
                    value: [],
                });
            },
            [IABActionTypes.updateIabVendorsSuccess]: (state, { response = {} } = {}) => {
                const { results = [] } = response;
                return touchField('configuration.iab.tcf.vendors.')(state, {
                    field: 'vendors',
                    value: results,
                });
            },
            [ScriptsActionTypes.add]: state => ({
                ...state,
                configuration: {
                    ...state.configuration,
                    scripts: true,
                },
            }),
            [ScriptsActionTypes.change]: state => ({
                ...state,
                configuration: {
                    ...state.configuration,
                    scripts: true,
                },
            }),
            [ScriptsActionTypes.classify]: state => ({
                ...state,
                configuration: {
                    ...state.configuration,
                    scripts: true,
                },
            }),
            [ScriptsActionTypes.delete]: state => ({
                ...state,
                configuration: {
                    ...state.configuration,
                    scripts: true,
                },
            }),
            [CookiesActionTypes.add]: state => ({
                ...state,
                configuration: {
                    ...state.configuration,
                    cookies: true,
                },
            }),
            [CookiesActionTypes.change]: state => ({
                ...state,
                configuration: {
                    ...state.configuration,
                    cookies: true,
                },
            }),
            [CookiesActionTypes.classify]: state => ({
                ...state,
                configuration: {
                    ...state.configuration,
                    cookies: true,
                },
            }),
            [CookiesActionTypes.delete]: state => ({
                ...state,
                configuration: {
                    ...state.configuration,
                    cookies: true,
                },
            }),
            [DisclosureActionTypes.add]: state => ({
                ...state,
                configuration: {
                    ...state.configuration,
                    disclosures: true,
                },
            }),
            [DisclosureActionTypes.fieldChange]: state => ({
                ...state,
                configuration: {
                    ...state.configuration,
                    disclosures: true,
                },
            }),
            [DisclosureActionTypes.delete]: state => ({
                ...state,
                configuration: {
                    ...state.configuration,
                    disclosures: true,
                },
            }),
        }),
        classificationView: genericReducer(initialState.classificationView, {
            [ActionTypes.classificationViewChange]: (state, view) => view,
        }),
        tattleRecordStopped: passthroughReducer(initialState.tattleRecordStopped),
    }),
    genericReducer(initialState, {
        [ActionTypes.createSuccess]: (state, { response }) => {
            const { domains, name, mode, publishedRevision, organizations } = response;
            return {
                ...state,
                domains,
                organizations,
                name,
                mode,
                publishedRevision,
                errors: initialState.errors,
                touched: initialState.touched,
            };
        },
        [ActionTypes.fetchDetailsSuccess]: (state, { response }) => {
            const { domains, organizations, name, mode, publishedRevision, tattleRecordStopped } =
                response;
            return {
                ...state,
                domains,
                organizations,
                name,
                mode,
                publishedRevision,
                errors: initialState.errors,
                touched: initialState.touched,
                tattleRecordStopped,
            };
        },
        [ActionTypes.updateSuccess]: (state, { response }) => {
            const { domains, name, mode, publishedRevision, organizations, tattleRecordStopped } =
                response;

            return {
                ...state,
                domains,
                organizations,
                name,
                mode,
                publishedRevision,
                errors: initialState.errors,
                touched: initialState.touched,
                tattleRecordStopped,
            };
        },
        [ActionTypes.fieldChange]: (state, { field, value, oldValue }) => {
            const newState = { ...state };
            switch (field) {
                case 'configuration.cookieBlocking':
                case 'configuration.iframeBlocking':
                case 'configuration.scriptBlocking':
                case 'mode': {
                    analytics.track(EVENTS.SET_MODE);
                    break;
                }
            }
            if (value === oldValue || field === 'configuration' || field === 'tattles') {
                return state;
            }
            if (field && field.startsWith('configuration.translations.')) {
                analytics.track(EVENTS.ADD_CUSTOM_TEXT);
            }
            set(newState, field, value);

            return newState;
        },
        [ActionTypes.fieldErrors]: (state, errors) => {
            return {
                ...state,
                errors,
            };
        },
        [AuthActionTypes.logout]: () => initialState,
        [NavActionTypes.routeChanged]: (state, { location }) => {
            const isInside = matchPath(location.pathname, {
                path: `${AuthenticatedPaths.CONSENT_MANAGER}/:id`,
            });
            if (isInside) {
                const {
                    params: { id },
                } = isInside;
                if (id !== state.id) {
                    return {
                        ...state,
                        id,
                    };
                }
                return state;
            }
            return {
                ...state,
                ...initialState,
            };
        },
    })
);
