export const EVENTS = {
    // ADD_ASSESSMENT_ASSIGNEE: 'AddAssessmentAssignee',
    CREATE_ASSESSMENT: 'CreateAssessment',
};

export const ASSIGNMENT_STATUS = {
    COMPLETE: 'complete',
    IN_PROGRESS: 'in-progress',
    NOT_STARTED: 'not-started',
    OVERDUE: 'overdue',
};

export const ASSIGNMENT_STATUS_MESSAGES = {
    complete: 'views.Assessment.assignment.status.complete',
    'in-progress': 'views.Assessment.assignment.status.inProgress',
    'not-started': 'views.Assessment.assignment.status.notStarted',
    overdue: 'views.Assessment.assignment.status.overdue',
};

export const APPLICATION_DISCOVERY_TEMPLATE = 'applicationDiscovery';
export const CUSTOM_TEMPLATE = 'customTemplate';

export const ASSIGNMENT_OPTION_ALL = 'all';
export const ASSIGNMENT_OPTION_ORGS = 'organizations';

export const TEMPLATE_STATUS = {
    IN_USE: 'in-use',
    DRAFT: 'draft',
    ARCHIVED: 'archived',
};

export const TEMPLATE_STATUS_MESSAGES = {
    'in-use': 'views.Assessment.templates.custom.status.inUse',
    draft: 'views.Assessment.templates.custom.status.draft',
    archived: 'views.Assessment.templates.custom.status.archived',
};

export const MIN_OPTIONS = 2;

export const TEMPLATE_FIELD_TYPES = (hasAutoAnswersEnabled = false) => ({
    ...(hasAutoAnswersEnabled && {
        autoAnswer: {
            hasOptions: false,
            translation: 'views.Assessment.templates.question.type.autoanswer',
            requiredDisabled: true,
        },
    }),
    checkbox: {
        hasOptions: true,
        translation: 'views.Assessment.templates.question.type.checkbox',
    },
    date: {
        hasOptions: false,
        translation: 'views.Assessment.templates.question.type.date',
    },
    select: {
        hasOptions: true,
        translation: 'views.Assessment.templates.question.type.dropdown',
    },
    evidenceUpload: {
        hasOptions: false,
        translation: 'views.Assessment.templates.question.type.evidenceUpload',
    },
    radio: {
        hasOptions: true,
        translation: 'views.Assessment.templates.question.type.radiobuttons',
    },
    text: {
        hasOptions: false,
        translation: 'views.Assessment.templates.question.type.text',
    },
});

export const LOGIC_ACTION_TYPES = {
    enable: {
        translation: 'views.Assessment.templates.questionLogic.enable',
    },
    skipTo: {
        translation: 'views.Assessment.templates.questionLogic.skipTo',
    },
};

export const DATA_STORE_FIELDS = {
    classifications: { translation: 'common.fields.classifications.label' },
    country: { translation: 'common.fields.country.label' },
    label: { translation: 'common.labels' },
    purposeOfProcessing: { translation: 'common.fields.purposeOfProcessing.label' },
};

export const RESOURCE_TYPES = {
    DATA_STORE: 'dataStore',
};
