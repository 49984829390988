export default {
    root: {
        '&:not(:last-child)': {
            marginBottom: '1px',
        },
        '&:last-child': {
            marginTop: '2px',
        },
    },
};
