export default theme => {
    return {
        badge: {
            zIndex: 1,
        },
        dayWrapper: {
            position: 'relative',
        },
        selected: {},
        highlight: {
            color: theme.palette.primary.contrastText,
            '&:after': {
                background: theme.palette.primary.hover,
                content: '""',
                position: 'absolute',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                zIndex: 0,
            },
        },
        startHighlight: {
            '&:after': {
                borderTopLeftRadius: '50%',
                borderBottomLeftRadius: '50%',
                left: '2px',
            },
        },
        endHighlight: {
            '&:after': {
                borderTopRightRadius: '50%',
                borderBottomRightRadius: '50%',
                right: '2px',
            },
        },
        preview: {
            '&:before': {
                borderColor: theme.palette.text.disabled,
                borderStyle: 'dashed',
                borderWidth: '2px 0',
                content: '""',
                position: 'absolute',
                top: '0',
                left: '2px',
                right: '2px',
                bottom: '0',
                zIndex: 0,
            },
        },
        startPreview: {
            '&:before': {
                borderRadius: '50% 0 0 50%',
                borderWidth: '2px 0 2px 2px',
            },
            '&$selected:before': {
                left: '50%',
            },
        },
        endPreview: {
            '&:before': {
                borderRadius: '0 50% 50% 0',
                borderWidth: '2px 2px 2px 0',
            },
            '&$selected:before': {
                right: '50%',
            },
        },
        outsideCurrentMonth: {},
        highlightOutsideCurrentMonth: {
            background: theme.palette.primary.dark,
            '&$selected': {
                background: 'transparent',
            },
        },
        day: {
            color: 'inherit',
            fontSize: theme.typography.caption.fontSize,
            height: 36,
            padding: theme.spacing(0, 0.25),
            position: 'relative',
            width: 36,
            zIndex: 1,
        },
        selectedDay: {},
        highlightDay: {},
        startHighlightDay: {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
        },
        endHighlightDay: {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
        },
        previewDay: {},
        startPreviewDay: {},
        endPreviewDay: {},
        outsideCurrentMonthDay: {
            color: theme.palette.text.disabled,
            opacity: 0.5,
            '&.Mui-selected': {
                opacity: 1,
            },
        },
        highlightOutsideCurrentMonthDay: {
            color: theme.palette.primary.contrastHover,
        },
    };
};
