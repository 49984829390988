export default {
    resetLink: {
        display: 'none',
    },
    gridListTile: {
        marginTop: 0,
    },
    header: {},
    title: {
        fontSize: '1.25rem',
        fontWeight: 'bold',
        marginLeft: 0,
    },
};
