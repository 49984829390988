export default theme => ({
    list: {
        padding: 0,
    },
    listItem: {
        flex: '1 0 100%',
        padding: theme.spacing(0.5),
        margin: theme.spacing(0, -0.5),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus': {
            outline: 'none',
            backgroundColor: theme.palette.action.focus,
        },
    },
    listItemButton: {
        marginRight: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
    },
    listItemLocale: {
        fontWeight: 'bold',
        paddingBottom: theme.spacing(1),
    },
    listItemTranslation: {
        fontSize: '0.875em',
    },
    rtl: {
        direction: 'rtl',
        textAlign: 'right',
    },
    toolbar: {
        alignItems: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: 0,
        position: 'relative',
    },
    fab: {
        bottom: '50%',
        left: '100%',
        margin: theme.spacing(-6, -1, 0, -6),
        zIndex: 1,
    },
});
