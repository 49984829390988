import * as Conversations from './conversation/HelpScoutConversation.selectors';
import * as Customers from './customer/HelpScoutCustomer.selectors';
import * as Mailboxes from './mailbox/HelpScoutMailbox.selectors';
import * as Tags from './tag/HelpScoutTag.selectors';
import * as Users from './user/HelpScoutUser.selectors';

export const conversations = Conversations;
export const customers = Customers;
export const mailboxes = Mailboxes;
export const tags = Tags;
export const users = Users;
