import * as Cookies from './cookies/Cookies.selectors';
import * as Disclosures from './disclosures/Disclosures.selectors';
import * as IAB from './iab/IAB.selectors';
import * as Palette from './palette/Palette.selectors';
import * as Scripts from './scripts/Scripts.selectors';

import { DOMAIN } from './Configuration.constants';
import { defaultConfiguration } from '../../constants';
import fromState from '../../../common/state/selectors';
import get from 'lodash/get';
import { getTcfV2Vendors } from '../../../common/state/db/iab/Iab.selectors';

export const cookies = Cookies;
export const disclosures = Disclosures;
export const iab = IAB;
export const palette = Palette;
export const scripts = Scripts;

export const getStore = state => get(state, DOMAIN) || {};
export const getField = (state, field, defaultValue = '') => {
    const value = getStore(state)[field];
    return value === undefined || value === null ? defaultValue : value;
};

export const getPayload = state => {
    const isCodeSplittingAllowedByPlan =
        fromState.Auth.customer.getCodeSplittingAllowedByPlan(state);
    const hasPermissionToUpdateCrossDomain = fromState.Auth.user.hasPermission(state, {
        toPerform: 'write',
        on: 'consent_cross_domain',
    });
    const hasPermissionToSetCCPARelaxed = fromState.Auth.user.hasPermission(state, {
        toPerform: 'write',
        on: 'ccpa_opt_out',
    });
    const hasPermissionToSetIABEnabled = fromState.Auth.user.hasPermission(state, {
        toPerform: 'write',
        on: 'iab_eu_tcf_2',
    });
    const hasPermissionToSetManagePreferences = fromState.Auth.user.hasPermission(state, {
        toPerform: 'write',
        on: 'consent_manage_prefs',
    });
    const codeSplitting = isCodeSplittingAllowedByPlan ? getField(state, 'codeSplitting') : false;
    const crossDomain = hasPermissionToUpdateCrossDomain ? getField(state, 'crossDomain') : false;
    const ccpaRelaxed = hasPermissionToSetCCPARelaxed ? !!getField(state, 'ccpaRelaxed') : false;
    const iabEnabled = hasPermissionToSetIABEnabled ? !!getField(state, 'iabEnabled') : false;
    const iframeBlocking = getField(state, 'iframeBlocking');
    const managePreferencesEnabled = hasPermissionToSetManagePreferences
        ? !!getField(state, 'managePreferencesEnabled')
        : false;

    const iabData = IAB.getStore(state);

    // Process IAB vendors
    const tcfv2Vendors = getTcfV2Vendors(state, iabData?.tcf?.vendors);

    // Add remoteConsent to state if it's true
    const remoteConsent = getField(state, 'remoteConsent');
    const remoteConsentObj = remoteConsent ? { remoteConsent } : {};

    return {
        additionalLinks: getField(state, 'additionalLinks', defaultConfiguration.additionalLinks),
        palette: palette.getStore(state),
        scripts: scripts.getStore(state),
        cookies: cookies.getStore(state),
        disclosures: disclosures.getResults(state),
        policyLinkText: getField(state, 'policyLinkText', defaultConfiguration.policyLinkText),
        policyLinkInDrawer: getField(state, 'policyLinkInDrawer'),
        storagePolicyHref: getField(state, 'storagePolicyHref'),
        translations: getField(state, 'translations'),
        codeSplitting,
        crossDomain,
        ccpaRelaxed,
        dntSupport: getField(state, 'dntSupport') || false,
        forcedClassifyEnabled: getField(state, 'forcedClassifyEnabled') || false,
        googleConsent: getField(state, 'googleConsent') || false,
        ...remoteConsentObj,
        gpcSupport: getField(state, 'gpcSupport') || false,
        iabEnabled,
        legacyBrowserSupport: getField(state, 'legacyBrowserSupport') || false,
        iframeBlocking,
        managePreferencesEnabled,
        iab: {
            ...iabData,
            tcf: {
                ...iabData?.tcf,
                v2: {
                    vendors: tcfv2Vendors,
                },
            },
        },
        timeoutSeconds: parseInt(getField(state, 'timeoutSeconds') || 10, 10),
        allowTimeout: getField(state, 'allowTimeout', defaultConfiguration.allowTimeout),
        forceManagePreferences: getField(state, 'forceManagePreferences') || false,
    };
};
