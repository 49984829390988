import {
    confirmSignIn as confirmSignInService,
    resendCode as resendCodeService,
    signOut as signOutService,
} from '../../../services';

import AuthActions from '../../../state/Auth.actions';
import fromState from '/b2b/common/state/selectors';

export const confirmSignIn = params => dispatch => {
    dispatch(AuthActions.confirmSignInBegin(params));

    return confirmSignInService(params)
        .then(response => {
            return dispatch(
                AuthActions.confirmSignInSuccess({ ...params, password: null }, response)
            );
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthActions.confirmSignInFailure(params, error));

            throw message;
        });
};

export const resendCode = () => (dispatch, getState) => {
    const state = getState();
    const username = fromState.Auth.user.getId(state);
    const password = fromState.Auth.user.getPassword(state);
    const mfaType = fromState.Auth.getMfaType(state);
    const hasOtherMFAMethods = fromState.Auth.getMfaOptions(state).length > 1;
    const params = { hasOtherMFAMethods, mfaType, username, password };
    dispatch(AuthActions.resendCodeBegin(params));

    return resendCodeService(params)
        .then(response => {
            return dispatch(AuthActions.resendCodeSuccess(params, response));
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthActions.resendCodeFailure(params, error));

            throw message;
        });
};

export const signOut = () => dispatch => {
    signOutService();
    dispatch(AuthActions.logout());
};
