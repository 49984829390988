import {
    contrastRgb,
    hslToRgb,
    hueFromString,
    intToHexString,
    parseColor,
} from '../../../common/helpers/Color';
import {
    defaultIdAttribute,
    defaultMergeStrategy,
    defaultProcessStrategy,
} from '../../../common/helpers/Normalizr';

import { schema } from 'normalizr';

/**
 * @typedef JurisdictionEntity
 * @property {number} id
 * @property {number} [jurisdictionId=id]
 * @property {'country'|'province'|'territory'|'state'|'region'|} type The type of jurisdiction
 * @property {string} name The name of the jurisdiction
 * @property {string} [customerId] The customer who followed this jurisdiction
 * @property {string} [email] The email of the user who followed the jurisdiction
 * @property {number} created The timestamp of the creation date
 * @property {number} updated The timestamp of the updated date
 * @property {string} color The color symbolizing the jurisdiction type
 * @property {string} textColor The text color to use for the jurisdiction when placed over the `color` as a background.
 */

export const entityName = 'jurisdictions';
export const idAttribute = 'jurisdictionId';

export const mergeStrategy = defaultMergeStrategy(idAttribute);
export const processStrategy = defaultProcessStrategy(idAttribute, data => {
    const newJurisdictionData = { ...data };
    let { type, name } = data;
    if (!type) {
        if (name) {
            [type, name] = (name || ':').split(':');
            newJurisdictionData.type = type;
            newJurisdictionData.name = name;
        }
    }

    if (type) {
        const rgb = hslToRgb(hueFromString(`${type}`) / 360, 0.6, 0.9);
        const { color = intToHexString.apply(null, rgb) } = data;
        newJurisdictionData.textColor =
            color && contrastRgb.apply(null, parseColor(color)) ? '#ffffff' : '#000000';
        newJurisdictionData.color = color && `#${intToHexString.apply(null, parseColor(color))}`;
    }

    return newJurisdictionData;
});

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
