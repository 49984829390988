import { useCallback, useEffect } from 'react';

export const FORCE_BUSINESS_HOURS_PARAM = 'forceBusinessHours';
export const FORCE_BUSINESS_HOURS_VALUE = '6C9D921A-DECC-4A13-BDDD-AF5E6AFB6420';

export const EXPERIENCE_PARAM = 'st';
export const LIVE_CHAT_ENABLED_VALUE = 'BE706A4E-EC17-4506-BF09-BEC0D593555A';
export const LIVE_CHAT_DISABLED_VALUE = '51FFD9E1-CADE-406A-AD81-ECFCC00EAE32';

export const CENTRAL_TIMEZONE = 'America/Chicago';

export const INSTANCES = {
    FIRST: 1,
    SECOND: 2,
    THIRD: 3,
    FOURTH: 4,
    LAST: -1,
};

export const WEEKDAYS = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
};

export const MONTHS = {
    JANUARY: 0,
    FEBRUARY: 1,
    MARCH: 2,
    APRIL: 3,
    MAY: 4,
    JUNE: 5,
    JULY: 6,
    AUGUST: 7,
    SEPTEMBER: 8,
    OCTOBER: 9,
    NOVEMBER: 10,
    DECEMBER: 11,
};

export const BUSINESS_HOURS = {
    START_HOURS: 8,
    START_MINUTES: 30,
    END_HOURS: 17,
    END_MINUTES: 30,
};

const { FIRST, FOURTH, LAST } = INSTANCES;
const { MONDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY } = WEEKDAYS;
const { JANUARY, MARCH, MAY, JUNE, JULY, SEPTEMBER, NOVEMBER, DECEMBER } = MONTHS;
const { START_HOURS, START_MINUTES, END_HOURS, END_MINUTES } = BUSINESS_HOURS;

function getDateOfNthWeekday(instance, weekdayIndex, monthIndex) {
    const year = new Date().getFullYear();

    if (instance === INSTANCES.LAST) {
        // Set the date to the last day of the specified month
        const date = new Date(year, monthIndex + 1, 0);

        // Find the day of the week index of the last day of the month (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        const lastWeekday = date.getDay();

        // Calculate the number of days to move back to reach the last occurrence of the weekday
        const daysToLastWeekday = (lastWeekday - weekdayIndex + 7) % 7;

        // Set the date to the last occurrence of the weekday in the specified month
        date.setDate(date.getDate() - daysToLastWeekday);

        return date;
    } else {
        // Find the first occurrence of the weekday in the specified month
        const date = new Date(year, monthIndex, 1);

        while (date.getDay() !== weekdayIndex) {
            date.setDate(date.getDate() + 1);
        }

        // Move to the instance of the weekday
        date.setDate(date.getDate() + (instance - 1) * 7);

        return date;
    }
}

function isCompanyHoliday() {
    const now = new Date();
    const year = now.getFullYear();

    // Create a new Date object with Central Timezone offset
    const centralDate = new Date(
        now.toLocaleString('en-US', {
            timeZone: CENTRAL_TIMEZONE,
        })
    );

    const holidays = [
        {
            name: "New Year's Day",
            date: new Date(year, JANUARY, 1),
        },
        {
            name: 'Osanian Appreciation Day',
            date: getDateOfNthWeekday(FIRST, FRIDAY, MARCH),
        },
        {
            name: 'Memorial Day',
            date: getDateOfNthWeekday(LAST, MONDAY, MAY),
        },
        {
            name: 'Juneteenth',
            date: new Date(year, JUNE, 19),
        },
        {
            name: 'Independence Day',
            date: new Date(year, JULY, 4),
        },
        {
            name: 'Labor Day',
            date: getDateOfNthWeekday(FIRST, MONDAY, SEPTEMBER),
        },
        {
            name: 'Thanksgiving Day',
            date: getDateOfNthWeekday(FOURTH, THURSDAY, NOVEMBER),
        },
        {
            name: 'Day after Thanksgiving',
            date: getDateOfNthWeekday(FOURTH, FRIDAY, NOVEMBER),
        },
        {
            name: 'Day before Christmas Eve',
            date: new Date(year, DECEMBER, 23),
        },
        {
            name: 'Christmas Eve',
            date: new Date(year, DECEMBER, 24),
        },
        {
            name: 'Christmas Day',
            date: new Date(year, DECEMBER, 25),
        },
    ];

    // Check if the current date matches any holiday in the list
    for (const holiday of holidays) {
        const holidayMonth = holiday.date.getMonth();
        const holidayDayOfWeek = holiday.date.getDay();
        const holidayDate = holiday.date.getDate();
        const shiftedHolidayDate =
            // Shift Saturday holidays to Friday
            holidayDayOfWeek === SATURDAY
                ? holidayDate - 1
                : // Shift Sunday holidays to Monday
                  holidayDayOfWeek === SUNDAY
                  ? holidayDate + 1
                  : // Otherwise, use the original date
                    holidayDate;

        if (
            centralDate.getMonth() === holidayMonth &&
            centralDate.getDate() === shiftedHolidayDate
        ) {
            return true;
        }
    }

    // If it's not a company holiday, return false
    return false;
}

function isWithinBusinessHours() {
    const now = new Date();

    // Create a new Date object with Central Timezone offset
    const centralDate = new Date(
        now.toLocaleString('en-US', {
            timeZone: CENTRAL_TIMEZONE,
        })
    );

    const dayOfWeek = centralDate.getDay();
    const currentHour = centralDate.getHours();
    const currentMinute = centralDate.getMinutes();

    const isWeekend = dayOfWeek === SUNDAY || dayOfWeek === SATURDAY;
    const isBeforeBusinessHours =
        currentHour < START_HOURS || (currentHour === START_HOURS && currentMinute < START_MINUTES);
    const isAfterBusinessHours =
        currentHour > END_HOURS || (currentHour === END_HOURS && currentMinute > END_MINUTES);

    return !isWeekend && !isBeforeBusinessHours && !isAfterBusinessHours;
}

// This hook determines whether or not to enable userpilot live chat
export default function useShouldEnableLiveChat(toggleUserpilotExperienceEnabled) {
    const setUserpilotExperience = useCallback(() => {
        try {
            const userpilotExtension = document.getElementById('userpilot-chrome-extension-css');
            if (!window.userpilot || userpilotExtension !== null) {
                return;
            }

            const currentURL = new URL(window.location.href);

            const withinBusinessHours = isWithinBusinessHours();
            const notCompanyHoliday = !isCompanyHoliday();
            const normalBusinessHours = withinBusinessHours && notCompanyHoliday;

            const forceLiveChat =
                currentURL.searchParams.get(FORCE_BUSINESS_HOURS_PARAM) ===
                FORCE_BUSINESS_HOURS_VALUE;

            if (normalBusinessHours || forceLiveChat) {
                currentURL.searchParams.set(EXPERIENCE_PARAM, LIVE_CHAT_ENABLED_VALUE);
            } else {
                currentURL.searchParams.set(EXPERIENCE_PARAM, LIVE_CHAT_DISABLED_VALUE);
            }

            window.history.replaceState(null, null, currentURL.href);

            window.userpilot.reload();
        } catch {
            // Do nothing
        }
    }, []);

    // Set the chat experience on page load and when the URL changes
    useEffect(() => {
        if (toggleUserpilotExperienceEnabled) {
            setUserpilotExperience();
        }
    }, [window.location.pathname, toggleUserpilotExperienceEnabled]);

    // Set the chat experience every 30 seconds
    useEffect(() => {
        if (!toggleUserpilotExperienceEnabled) {
            return;
        }
        const interval = setInterval(setUserpilotExperience, 30000);
        return () => clearInterval(interval);
    }, [toggleUserpilotExperienceEnabled]);
}
