import React, { useCallback, useMemo } from 'react';
import {
    getLabelForJurisdictionOption,
    jurisdictionLookupMap,
    jurisdictionOptions,
} from '/b2b/common/helpers/Jurisdiction';

import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import ValidationAutocomplete from '../ValidationAutocomplete';
import { defaultIsOptionEqualToValue } from '/b2b/common/components/FormControls/Autocomplete';
import escapeRegExp from 'lodash/escapeRegExp';
import { makeStyles } from '@mui/styles';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';

const useStyles = makeStyles(theme => ({
    jurisdictionMenuItem: {
        fontWeight: theme.typography.fontWeightRegular,
    },
    jurisdictionMenuGroup: {
        fontWeight: theme.typography.fontWeightBold,
    },
    jurisdictionMenuGroupItem: {
        backgroundColor: theme.palette.background.default,
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const getGroupForJurisdictionOption = ({ value, country }) =>
    jurisdictionLookupMap[value]?.country || country || '';
const getJurisdictionGroupOptions = ({ children }) => children;
const isJurisdictionEqualToValue = (option, value) =>
    defaultIsOptionEqualToValue(option, value) || (value === '' && option?.value === null);
const filterJurisdictions = (getOptionLabel, options, { inputValue }) => {
    if (!inputValue) {
        // No search string, return everything
        return options;
    }
    // eslint-disable-next-line security/detect-non-literal-regexp
    const regExp = new RegExp(
        `(${escapeRegExp(inputValue).split(' ').filter(Boolean).join(')|(')})`,
        'im'
    );
    return options.filter(option => {
        const label = getOptionLabel(option);
        return regExp.test(`${label}`);
    });
};
const renderJurisdiction = (classes, groupBy, getOptionLabel, renderProps, option) => {
    const { key, className, ...otherProps } = renderProps;
    const { value } = option;
    return (
        <li
            key={key}
            className={`${className} ${
                value === groupBy(option)
                    ? classes.jurisdictionMenuGroup
                    : classes.jurisdictionMenuGroupItem
            }`}
            {...otherProps}
        >
            {getOptionLabel(option)}
        </li>
    );
};

const renderTags = value => {
    return (
        <Typography noWrap textOverflow="ellipsis">
            {value.map(
                ({ label }, index, list) => `${label}${index < list.length - 1 ? ', ' : ''}`
            )}
        </Typography>
    );
};

export const ValidationJurisdictionAutocomplete = props => {
    const classes = useStyles();
    const getJurisdictionOptionLabel = useCallback(
        value =>
            getLabelForJurisdictionOption(
                typeof value === 'string' ? jurisdictionLookupMap[`${value}`] : value
            ),
        []
    );
    const groupJurisdictionBy = useCallback(getGroupForJurisdictionOption, []);
    const renderJurisdictionGroup = useCallback(getJurisdictionGroupOptions, []);
    const isJurisdictionOptionEqualToValue = useCallback(isJurisdictionEqualToValue, []);
    const filterJurisdictionOptions = useBoundCallback(filterJurisdictions, [
        getJurisdictionOptionLabel,
    ]);
    const renderJurisdictionOption = useBoundCallback(renderJurisdiction, [
        classes,
        groupJurisdictionBy,
        getJurisdictionOptionLabel,
    ]);

    const jurisdictionClasses = useMemo(
        () => ({
            groupUl: classes.jurisdictionMenuGroup,
            option: classes.jurisdictionMenuItem,
        }),
        []
    );

    return (
        <ValidationAutocomplete
            blurOnSelect
            fullWidth
            filterSelectedOptions={false}
            {...props}
            options={jurisdictionOptions}
            filterOptions={filterJurisdictionOptions}
            groupBy={groupJurisdictionBy}
            getOptionLabel={getJurisdictionOptionLabel}
            isOptionEqualToValue={isJurisdictionOptionEqualToValue}
            renderGroup={renderJurisdictionGroup}
            renderOption={renderJurisdictionOption}
            renderTags={renderTags}
            classes={jurisdictionClasses}
        />
    );
};

ValidationJurisdictionAutocomplete.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
};

export default ValidationJurisdictionAutocomplete;
