import ApiKey from '../../../apiKeys/state/db/ApiKey.schema';
import Audit from './audit/Audit.schema';
import Breach from '/b2b/vendors/state/db/Breach.schema';
import Category from './category/Category.schema';
import ConsentConfig from '../../../consent/state/db/ConsentConfig.schema';
import ConsentConfigRule from '../../../consent/state/db/rule/Rule.schema';
import ConsentConfigTattle from '../../../consent/state/db/tattle/Tattle.schema';
import Customer from './customer/Customer.schema';
import DSARActionItem from '../../../dsarActionItems/state/db/ActionItem.schema';
import DSARActionItemActivity from '../../../dsarActionItems/state/ActionItemField.schema';
import DSARActionItemField from '../../../dsarActionItems/state/ActionItemActivity.schema';
import DSARForm from '../../../dsarForms/state/db/Form.schema';
import DSARRequest from '../../../dsarRequests/state/db/Request.schema';
import DSARRequestType from '../../../dsarRequests/state/db/RequestType.schema';
import DataStore from '../../../dataStores/state/db/DataStore.schema';
import Document from '../../../documents/state/db/Document.schema';
import Domain from './domain/Domain.schema';
import HelpScout from '../../../askAnalyst/state/db/HelpScout.schema';
import Iab from './iab/Iab.schema';
import IabVendor from './iab/vendor/IabVendor.schema';
import Jurisdiction from '../../../legislation/state/db/Jurisdiction.schema';
import Legislation from '../../../legislation/state/db/Legislation.schema';
import Litigation from '../../../litigation/state/db/Litigation.schema';
import Notification from '../../../notifications/state/db/Notification.schema';
import Organization from '../../../organizations/state/db/Organization.schema';
import Product from '/b2b/vendors/state/db/Product.schema';
import Role from './role/Role.schema';
import Subscription from './subscription/Subscription.schema';
import Template from '../../../assessments/state/db/Template.schema';
import Trusthub from '../../../trusthub/state/db/Trusthub.schema';
import TrusthubPage from '../../../trusthub/state/db/TrusthubPage.schema';
import UnicourtAttorney from '../../../litigation/state/db/UnicourtAttorney.schema';
import UnicourtCase from '../../../litigation/state/db/UnicourtCase.schema';
import User from '../../../users/state/db/User.schema';
import Vendor from '/b2b/vendors/state/db/Vendor.schema';
import { schema } from 'normalizr';

/*
A vendor has:
    - many following "Customers"
    - many products
    - many documents
    - many sub-processors
    - many breaches
*/
Vendor.define({
    documents: [Document],
    following: [Customer],
    products: [Product],
    subprocessors: [Vendor],
    breaches: [Breach],
});

/*
A product has:
    - one vendor
    - many documents
    - many domains
    - many categories
*/
Product.define({
    categories: [Category],
    documents: [Document],
    domains: [Domain],
    subprocessors: [Vendor],
    vendor: Vendor,
});

/*
A customer has:
    - many users
    - many DSAR Forms
    - many Consent Management configurations
    - many Organizations
    - many Roles
    - one subscription
    - many Templates
    - many Trusthubs
*/
Customer.define({
    dsarForms: [DSARForm],
    consentConfigs: [ConsentConfig],
    followedJurisdictions: [Jurisdiction],
    users: [User],
    organizations: [Organization],
    roles: [Role],
    subscription: Subscription,
    templates: [Template],
    trusthubs: [Trusthub],
});

/*
A template has:
    - one owner
*/
Template.define({
    owner: User,
});

/*
A user has:
    - many customers
    - many organizations
    - many roles
    - many dsarActionItems
*/
User.define({
    customers: [Customer],
    followedJurisdictions: [Jurisdiction],
    organizations: [Organization],
    roles: [Role],
    dsarActionItems: [DSARActionItem],
});

/*
A document has:
    - one vendor OR
      one product OR
      one litigation
    - many audits
*/
Document.define({
    changes: [Audit],
    product: Product,
    vendor: Vendor,
    litigation: Litigation,
});

/*
A legislation update has:
    - many documents
    - many jurisdictions
*/
Legislation.define({
    documents: [Document],
    jurisdictions: [Jurisdiction],
});

UnicourtAttorney.define({
    cases: [UnicourtCase],
});

UnicourtCase.define({
    attorneys: [UnicourtAttorney],
    parties: new schema.Array(
        new schema.Object({
            // eslint-disable-next-line camelcase
            party_types: new schema.Array(
                new schema.Object({
                    // eslint-disable-next-line camelcase
                    attorney_association: [UnicourtAttorney],
                })
            ),
        })
    ),
});

/*
A litigation update has:
    - many vendors
    - one case
*/
Litigation.define({
    vendors: [Vendor],
    case: UnicourtCase,
});

/*
An audit has:
    - one document OR one user OR one product OR one vendor
*/
Audit.define({
    document: Document,
    product: Product,
    vendor: Vendor,
    user: User,
});

/*
A consent config has:
    - one customer
    - many organizations
*/
ConsentConfig.define({
    customer: Customer,
    organizations: [Organization],
    iabVendors: [IabVendor],
});

/*
A notification has:
    - one customer
    - one vendor OR
      one product OR
      one legislation OR
      one litigation OR
      one document
*/
Notification.define({
    customer: Customer,
    document: Document,
    legislation: Legislation,
    litigation: Litigation,
    product: Product,
    vendor: Vendor,
});

DataStore.dataStoreConnections.define({
    defaultAssignee: User,
    owners: [User],
    primaryOwner: User,
});

/*
A DSARRequest has:
    - one assignee
    - many organizations
*/
DSARRequest.define({
    assignee: User,
    organizations: [Organization],
    type: DSARRequestType,
});

/*
A DSARActionItem has:
    - many DSAR Action Item Activity entries
    - many DSAR Action Item Fields
    - one DSAReqest
*/
DSARActionItem.define({
    activity: [DSARActionItemActivity],
    fields: [DSARActionItemField],
    request: DSARRequest,
});

/*
A DSARForm has:
    - one customer
    - many organizations
*/
DSARForm.define({
    customer: Customer,
    organizations: [Organization],
});

/*
An Organization has:
    - one customer
    - many users
*/
Organization.define({
    customer: Customer,
    users: [User],
});

/*
A Trusthub has:
    - one customer
    - many pages
*/
Trusthub.define({
    customer: Customer,
    pages: [TrusthubPage],
});

export const lookup = {
    [ApiKey.key]: ApiKey,
    [Audit.key]: Audit,
    [Breach.key]: Breach,
    [Category.key]: Category,
    [ConsentConfig.key]: ConsentConfig,
    [ConsentConfigTattle.key]: ConsentConfigTattle,
    [ConsentConfigRule.key]: ConsentConfigRule,
    [Customer.key]: Customer,
    ...DataStore,
    [Document.key]: Document,
    [Domain.key]: Domain,
    [DSARRequest.key]: DSARRequest,
    [DSARRequestType.key]: DSARRequestType,
    [DSARActionItem.key]: DSARActionItem,
    [DSARActionItemActivity.key]: DSARActionItemActivity,
    [DSARActionItemField.key]: DSARActionItemField,
    [DSARForm.key]: DSARForm,
    ...HelpScout,
    [Iab.key]: Iab,
    [IabVendor.key]: IabVendor,
    [Legislation.key]: Legislation,
    [Litigation.key]: Litigation,
    [Notification.key]: Notification,
    [Organization.key]: Organization,
    [Product.key]: Product,
    [Jurisdiction.key]: Jurisdiction,
    [Role.key]: Role,
    [Subscription.key]: Subscription,
    [Template.key]: Template,
    [Trusthub.key]: Trusthub,
    [TrusthubPage.key]: TrusthubPage,
    [UnicourtAttorney.key]: UnicourtAttorney,
    [UnicourtCase.key]: UnicourtCase,
    [User.key]: User,
    [Vendor.key]: Vendor,
};

export default new schema.Object(
    Object.entries(lookup).reduce((schemes, [key, entitySchema]) => {
        schemes[key] = new schema.Values(entitySchema);
        return schemes;
    }, {})
);
