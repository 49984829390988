export default theme => ({
    label: {
        flex: '1 1 100%',
        width: '100%',
        border: '1px solid #EDEDED',
        borderRadius: '4px',
        marginLeft: 0,
        marginRight: 0,
        '& $helperText': {
            color: theme.palette.text.primary,
        },
        '&:first-child': {
            marginTop: theme.spacing(1),
        },
        '&:last-child': {
            marginBottom: 0,
        },
        '& .MuiFormControlLabel-label': {
            fontSize: theme.typography.fontSizeSmall,
            transformOrigin: 'top left',
            position: 'absolute',
            top: 0,
            left: 0,
            transform: `translate(${theme.spacing(1.8)}, 0) scale(0.75)`,
            backgroundColor: theme.palette.common.white,
        },
    },
    errorText: {
        color: theme.palette.error.main,
        marginLeft: 0,
        marginRight: 0,
        whiteSpace: 'pre-line',
    },
    helperText: {
        color: theme.palette.text.primary,
        marginLeft: 0,
        marginRight: 0,
        whiteSpace: 'pre-line',
    },
});
