import React from 'react';
import { SvgIcon } from '@mui/material';

export default function Osano(props) {
    return (
        <SvgIcon color="secondary" fontSize="large" viewBox="0 0 191 37" {...props}>
            <defs>
                <path id="a" d="M0 .258h35.973V36H0z" />
                <path id="c" d="M.255.169h26.558v35.528H.255z" />
                <path id="e" d="M.018.258h35.973V36H.018z" />
                <path id="g" d="M.112.258h36.661V36H.112z" />
            </defs>
            <g className="logo" fill="none" fillRule="evenodd">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a" />
                </mask>
                <path
                    className="filled"
                    d="M9.801 18.77c.048 2.041.801 3.786 2.262 5.233 1.46 1.448 3.434 2.172 5.923 2.172 2.49 0 4.464-.724 5.924-2.172 1.46-1.447 2.214-3.192 2.262-5.233v-1.282c-.048-2.04-.802-3.785-2.262-5.233-1.46-1.447-3.435-2.171-5.924-2.171-2.49 0-4.464.724-5.923 2.171-1.46 1.448-2.214 3.192-2.262 5.233v1.282zm26.172.783c-.192 4.414-1.891 8.212-5.098 11.392C27.5 34.315 23.204 36 17.986 36c-5.217 0-9.513-1.685-12.888-5.055C1.89 27.765.19 23.967 0 19.553v-2.848C.191 12.29 1.89 8.494 5.098 5.313 8.473 1.943 12.768.258 17.986.258S27.5 1.943 30.875 5.313c3.207 3.18 4.906 6.978 5.098 11.392v2.848z"
                    mask="url(#b)"
                />
                <g transform="translate(42 1)">
                    <mask id="d" fill="#fff">
                        <use xlinkHref="#c" />
                    </mask>
                    <path
                        className="filled"
                        d="M26.213 22.027c1.071 3.37.708 6.492-1.088 9.363-1.798 2.872-4.778 4.308-8.943 4.308H.332V25.8h15.136c.833-.143 1.255-.499 1.267-1.068.012-.57-2.517-1.792-7.586-3.667-4.617-1.638-7.45-4.165-8.496-7.583-.834-3.63-.357-6.716 1.428-9.255C3.865 1.688 7.304.335 12.398.169H24.75v9.861H13.826c-1.334-.118-2.107.16-2.32.837-.215.676.344 1.323 1.677 1.94l4.712 1.78c4.475 1.59 7.248 4.07 8.318 7.44"
                        mask="url(#d)"
                    />
                </g>
                <g transform="translate(73)">
                    <mask id="f" fill="#fff">
                        <use xlinkHref="#e" />
                    </mask>
                    <path
                        className="filled"
                        d="M26.19 17.488c-.048-2.04-.802-3.785-2.262-5.233-1.46-1.447-3.435-2.172-5.924-2.172-2.49 0-4.464.725-5.923 2.172-1.46 1.448-2.214 3.192-2.262 5.233v1.282c.048 2.041.742 3.785 2.082 5.233 1.34 1.448 3.135 2.171 5.385 2.171s4.009-.427 5.278-1.281v10.003c-1.317.736-2.837 1.104-4.56 1.104-5.218 0-9.514-1.685-12.888-5.055C1.908 27.765.209 23.967.018 19.553v-2.848c.191-4.414 1.89-8.211 5.098-11.392C8.49 1.943 12.786.258 18.004.258s9.514 1.685 12.889 5.055c3.207 3.18 4.906 6.978 5.098 11.392V36H26.19V17.488z"
                        mask="url(#f)"
                    />
                </g>
                <path
                    className="filled"
                    d="M114 36V16.582c.192-4.45 1.892-8.279 5.102-11.485C122.48 1.699 126.778 0 132 0c5.221 0 9.521 1.7 12.898 5.097 3.21 3.206 4.91 7.034 5.102 11.485V36h-9.808V17.372c-.049-2.058-.803-3.816-2.264-5.277-1.461-1.459-3.437-2.189-5.928-2.189s-4.467.73-5.928 2.19c-1.461 1.46-2.216 3.218-2.264 5.276V36H114z"
                />
                <g transform="translate(154)">
                    <mask id="h" fill="#fff">
                        <use xlinkHref="#g" />
                    </mask>
                    <path
                        className="filled"
                        d="M10.1 18.77c.049 2.041.817 3.786 2.306 5.233 1.487 1.448 3.5 2.172 6.036 2.172 2.537 0 4.55-.724 6.037-2.172 1.488-1.447 2.256-3.192 2.306-5.233v-1.282c-.05-2.04-.818-3.785-2.306-5.233-1.487-1.447-3.5-2.171-6.037-2.171s-4.549.724-6.036 2.171c-1.489 1.448-2.257 3.192-2.306 5.233v1.282zm26.673.783c-.195 4.414-1.927 8.212-5.195 11.392C28.138 34.315 23.76 36 18.442 36c-5.317 0-9.695-1.685-13.135-5.055-3.269-3.18-5-6.978-5.195-11.392v-2.848C.307 12.29 2.038 8.494 5.307 5.313 8.747 1.943 13.125.258 18.442.258c5.318 0 9.696 1.685 13.136 5.055 3.268 3.18 5 6.978 5.195 11.392v2.848z"
                        mask="url(#h)"
                    />
                </g>
            </g>
        </SvgIcon>
    );
}
