import React, { useState } from 'react';
import { failureMessages, validatePassword } from '../../../common/helpers/Password';

import InvalidIcon from '@mui/icons-material/Close';
import Locale from '../../../common/components/Locale';
import PropTypes from 'prop-types';
import ValidIcon from '@mui/icons-material/Check';
import ValidationForm from '../../../common/components/ValidationForm';
import makeStyles from '@mui/styles/makeStyles';
import schema from './RecoverPassword.schema';
import styles from './RecoverPassword.styles';

const useStyles = makeStyles(styles);

const RecoverPassword = props => {
    const { onCompleteNewPassword } = props;
    const classes = useStyles(props);
    const [passwordChecks, updatePasswordChecks] = useState(validatePassword('', true));

    const handleFieldChange = (field, value) => {
        if (field === 'newPassword') {
            updatePasswordChecks(validatePassword(value, true));
        }
    };
    return (
        <div className={classes.content}>
            <div className={classes.contentBody}>
                <ValidationForm
                    title="password.reset"
                    subtitle="password.requireNew"
                    submitText="password.setNew"
                    schema={props.schema}
                    onSubmit={onCompleteNewPassword}
                    onFieldChange={handleFieldChange}
                    helperText={{
                        newPassword: props.failureMessages.map(([key, message]) => (
                            <span className={classes.validate} key={key}>
                                {passwordChecks[key] ? (
                                    <ValidIcon className={classes.validIcon} />
                                ) : (
                                    <InvalidIcon className={classes.invalidIcon} />
                                )}
                                <span className={classes.validateLabel}>
                                    <Locale path={message} />
                                </span>
                            </span>
                        )),
                    }}
                />
            </div>
        </div>
    );
};

RecoverPassword.propTypes = {
    className: PropTypes.string,
    failureMessages: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    onCompleteNewPassword: PropTypes.func.isRequired,
    schema: PropTypes.object,
};

RecoverPassword.defaultProps = { schema, failureMessages };

export default RecoverPassword;
