// @ts-check
/**
 * Moves an item in an array from one position to another
 * @param {number} from
 * @param {number} to
 * @this {Array}
 * @return {ThisParameterType<Array>}
 */
function scopeMove(from, to) {
    this.splice(to, 0, ...this.splice(from, 1));
    return this;
}

/**
 * @param {any[]|number} maybeArray
 * @param {number} from
 * @param {number|undefined} to
 * @this {Array|any}
 */
export function move(maybeArray, from, to) {
    if (typeof from !== 'number') {
        throw new Error('move: from and to must be numbers');
    }
    if (Array.isArray(maybeArray)) {
        return maybeArray.length ? scopeMove.call(maybeArray, from, to) : maybeArray;
    }
    if (typeof maybeArray !== 'number') {
        if (!Array.isArray(this)) {
            throw new Error('move: must be provided an array');
        }
        throw new Error('move: must be provided a from and to number');
    }
    if (Array.isArray(this)) {
        return scopeMove.call(this, from, to);
    }
    throw new Error('move: must be called with an array');
}

export const filterUnique = (value, index, list) => list.indexOf(value) === index;

move([], 0, 1);
move.call([], 0, 1);
