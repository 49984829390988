import * as mapDispatchToProps from './VerifyPhoneDialog.commands';

import Component from './VerifyPhoneDialog.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const mapStateToProps = state => {
    const phone = fromState.Auth.user.getPhone(state);
    return {
        defaultValues: { currentPhone: phone, phone },
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...ownProps,
        ...stateProps,
        onUpdate: dispatchProps.update,
        onVerify: dispatchProps.verify,
        onResend: dispatchProps.resendCode,
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
