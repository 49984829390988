import AuthActions, { types as AuthActionTypes } from '../../authentication/state/Auth.actions';
import { refreshTokens, signOut } from '../../authentication/services';

import Actions from '../state/ui/UI.actions';
import { CRUDActionTypes } from '../helpers/ReduxHelpers';
import fromState from '../state/selectors';

const authActions = Object.values(AuthActionTypes);

export default ({ dispatch, getState }) =>
    next =>
    action => {
        const state = getState();
        const { type, payload = {}, meta } = action;
        // Only refresh tokens all of the following conditions are met:
        const refresh =
            fromState.Auth.isAuthenticated(state) && // User is authenticated
            !meta?.noRefresh && // Meta noRefresh flag is not set
            payload?.error !== 'Aborted' && // Request was not aborted
            !authActions.includes(type); // Action is not an auth action
        if (refresh) {
            // Check for expired session / id tokens and refresh if necessary
            refreshTokens().catch(() => {
                // Could not refresh tokens
                dispatch(AuthActions.logout());
                signOut();
            });
        }
        // Action could be a thunk
        if (type) {
            const { params, error, response = payload } = payload || {};
            const actionType = type.split('/').pop();
            if (Object.values(CRUDActionTypes).includes(actionType)) {
                if (type.slice(-5).toLowerCase() === 'begin') {
                    dispatch(Actions.asyncBegin(meta, params));
                } else if (type.slice(-7).toLowerCase() === 'success') {
                    dispatch(Actions.asyncSuccess(meta, params, response));
                } else {
                    dispatch(Actions.asyncFailure(meta, params, error));
                }
            }
        }

        return next(action);
    };
