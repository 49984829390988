import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import ReactScrollbarCustom from 'react-scrollbars-custom';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import styles from './Scrollbar.styles';

const useStyles = makeStyles(styles);

const ScrollbarPart = props => {
    const { elementRef, ...remain } = props;
    return <div ref={elementRef} {...remain} />;
};

ScrollbarPart.propTypes = {
    elementRef: PropTypes.any,
};

const Scrollbar = props => {
    const { className, dark, ...remain } = props;
    const classes = useStyles(props);

    const componentProps = useMemo(
        () => ({
            wrapperProps: {
                className: classes.wrapper,
            },
            scrollerProps: {
                className: classes.scrollArea,
            },
            contentProps: {
                className: classes.content,
            },
            trackXProps: {
                className: classNames(classes.track, classes.trackHorizontal),
            },
            trackYProps: {
                className: classNames(classes.track, classes.trackVertical),
            },
            thumbXProps: {
                className: classNames(classes.thumb, classes.thumbHorizontal),
            },
            thumbYProps: {
                className: classNames(classes.thumb, classes.thumbVertical),
            },
        }),
        [
            classes.wrapper,
            classes.scrollArea,
            classes.content,
            classes.track,
            classes.trackHorizontal,
            classes.trackVertical,
            classes.thumb,
            classes.thumbHorizontal,
            classes.thumbVertical,
        ]
    );

    return (
        <ReactScrollbarCustom
            createContext
            noDefaultStyles
            disableTracksWidthCompensation
            className={classNames(className, classes.root, dark ? classes.dark : '')}
            {...remain}
            renderer={ScrollbarPart}
            {...componentProps}
        />
    );
};

Scrollbar.propTypes = {
    className: PropTypes.string,
    dark: PropTypes.bool,
};

export default Scrollbar;
