// Palette
import palette from '../palette';

export default {
    root: {
        borderBottom: `1px solid ${palette.divider}`,
        fontFamily: "'Roboto', sans-serif",
        fontSize: '14px',
    },
    head: {
        fontSize: '14px',
        color: palette.text.primary,
        fontWeight: 800,
    },
};
