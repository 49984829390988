import * as ConversationList from './conversationList/ConversationList.selectors';
import * as ConversationThreads from './conversationThreads/ConversationThreads.selectors';

import { DOMAIN } from './AskAnalyst.constants';
import get from 'lodash/get';

export const conversationList = ConversationList;
export const conversationThreads = ConversationThreads;

export const getStore = state => get(state, DOMAIN) || {};
