import { types as ActionTypes, asyncEvents as AsyncEvents } from './Dashboard.actions';
import {
    asyncReducer,
    errorReducer,
    genericReducer,
    loadingReducer,
} from '../../common/helpers/ReduxHelpers';

import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

export const initialState = {
    data: {
        consent: {},
        stats: [
            { title: 'Vendors Monitored', value: '--' },
            { title: 'Lawsuits Filed in last 3 months', value: '--' },
            { title: 'Doc Changes in last 30 days', value: '--' },
        ],
        traffic: {},
        fieldClassifications: [],
    },
    loading: false,
    error: null,
};

const asyncTypes = Object.keys(AsyncEvents).map(key => ActionTypes[key]);

export default reduceReducers(
    initialState,
    combineReducers({
        error: errorReducer(initialState.error, asyncTypes),
        loading: loadingReducer(initialState.loading, asyncTypes),
        data: asyncReducer(initialState.data, asyncTypes, {
            onSuccess: (state, { response = {} }) => ({
                ...state,
                ...response,
            }),
        }),
    }),
    genericReducer(initialState, {
        [AuthActionTypes.logout]: () => initialState,
    })
);
