import { alpha } from '@mui/material/styles';

export default theme => ({
    root: {
        position: 'relative',
    },
    title: {
        margin: theme.spacing(2, 0),
        '&:empty': {
            margin: 0,
        },
    },
    progress: {
        display: 'block',
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    delete: {
        backgroundColor: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        '&:hover': {
            backgroundColor: alpha(theme.palette.error.main, 0.92),
        },
    },
    loadingWrapper: {
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,0.9)',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: theme.zIndex.loadingWrapper,
    },
});
