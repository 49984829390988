import {
    getEntityFieldById as getNotificationFieldById,
    getUnreadIds,
} from '/b2b/notifications/state/db/Notification.selectors';
import {
    markAllNotificationsRead as markAllNotificationsReadService,
    markNotificationById as markNotificationService,
} from '/b2b/notifications/services';

import Actions from './AuthCustomer.actions';
import CustomerSchema from '../../../common/state/db/customer/Customer.schema';
import DBActions from '../../../common/state/db/DB.actions';
import { fetchCustomerDetails as FetchCustomerDetailsService } from '../../../common/services/customer';
import NotificationSchema from '/b2b/notifications/state/db/Notification.schema';
import UIActions from '../../../common/state/ui/UI.actions';
import { getId as getCustomerId } from './AuthCustomer.selectors';

export const fetchDetails =
    (params = {}) =>
    (dispatch, getState) => {
        const customerId = getCustomerId(getState());
        const meta = { schema: CustomerSchema, id: customerId };
        dispatch(Actions.fetchDetailsBegin(meta, params));

        return FetchCustomerDetailsService()
            .then(details => {
                dispatch(Actions.fetchDetailsSuccess(meta, params, details));

                return details;
            })
            .catch(error => {
                const { message } = error;
                dispatch(Actions.fetchDetailsFailure(meta, params, message));
            });
    };

export const markNotificationRead = id => (dispatch, getState) => {
    const updated = Date.now();
    const meta = { schema: NotificationSchema, id };
    // Anticipate the mark being successful
    dispatch(DBActions.update(meta, { read: true, updated }));

    return markNotificationService(id, { read: true }).catch(error => {
        // Reset the mark when we fail, but only if a previous call didn't succeed
        const currentUpdated = getNotificationFieldById(getState(), { id, field: 'updated' });
        if (currentUpdated === updated) {
            const { message: errorMessage } = error;
            const { message = errorMessage, roadblocks } = errorMessage || {};
            if (roadblocks) {
                dispatch(
                    UIActions.setRoadblock({
                        message,
                        roadblocks,
                    })
                );
            } else {
                dispatch(UIActions.createSnack(message, { variant: 'error' }));
            }
            dispatch(DBActions.update(meta, { read: false, updated: Date.now() }));

            throw message;
        }
    });
};

export const markNotificationUnread = id => (dispatch, getState) => {
    const updated = Date.now();
    const meta = { schema: NotificationSchema, id };
    // Anticipate the mark being successful
    dispatch(DBActions.update(meta, { read: false, updated }));

    return markNotificationService(id, { read: false }).catch(error => {
        // Reset the mark when we fail, but only if a previous call didn't succeed
        const currentUpdated = getNotificationFieldById(getState(), { id, field: 'updated' });
        if (currentUpdated === updated) {
            const { message: errorMessage } = error;
            const { message = errorMessage, roadblocks } = errorMessage || {};
            if (roadblocks) {
                dispatch(
                    UIActions.setRoadblock({
                        message,
                        roadblocks,
                    })
                );
            } else {
                dispatch(UIActions.createSnack(message, { variant: 'error' }));
            }
            dispatch(DBActions.update(meta, { read: true, updated: Date.now() }));

            throw message;
        }
    });
};

export const markAllNotificationsRead = () => (dispatch, getState) => {
    const unreadIds = getUnreadIds(getState());
    const updated = Date.now();
    const meta = { schema: NotificationSchema, id: unreadIds };
    // Anticipate the mark being successful
    dispatch(DBActions.update(meta, { read: true, updated }));

    return markAllNotificationsReadService().catch(error => {
        const state = getState();
        // Reset the mark when we fail, but only if the notification updated date is the same
        const revertedIds = unreadIds.filter(
            id => getNotificationFieldById(state, { id, field: 'updated' }) === updated
        );
        if (revertedIds.length) {
            meta.id = revertedIds;
            const { message: errorMessage } = error;
            const { message = errorMessage, roadblocks } = errorMessage || {};
            if (roadblocks) {
                dispatch(
                    UIActions.setRoadblock({
                        message,
                        roadblocks,
                    })
                );
            } else {
                dispatch(UIActions.createSnack(message, { variant: 'error' }));
            }
            dispatch(DBActions.update(meta, { read: false, updated: Date.now() }));

            throw message;
        }
    });
};
