import { FormGroup, FormLabel } from '@mui/material';
import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import castArray from 'lodash/castArray';
import startCase from 'lodash/startCase';
import { translate } from '/b2b/common/helpers/i18n';

const ValidationFormGroup = props => {
    const {
        component: Component,
        disabled,
        label,
        options = {},
        value: selected,
        ...remain
    } = props;

    const checked = castArray(selected);

    return (
        <Fragment>
            <FormLabel component="legend">{translate(label)}</FormLabel>
            <FormGroup>
                {Object.entries(options).map(([val, option], index) => {
                    const label = translate(option.label || startCase(val));
                    const value = option.value !== undefined ? option.value : val;
                    const isChecked =
                        option.checked !== undefined ? !!option.checked : !!checked.includes(value);
                    return (
                        <Component
                            {...remain}
                            key={val}
                            index={index}
                            label={label}
                            value={value}
                            {...option}
                            checked={isChecked}
                            disabled={option.disabled || disabled}
                        />
                    );
                })}
            </FormGroup>
        </Fragment>
    );
};

ValidationFormGroup.propTypes = {
    component: PropTypes.any,
    disabled: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool]),
    options: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    multiple: PropTypes.bool,
};

export default ValidationFormGroup;
