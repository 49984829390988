export default {
    FORGOT_PASSWORD: '/sign-in/forgot-password',
    OAUTH: '/oauth',
    RECOVER_PASSWORD: '/sign-in/set-new-password',
    RESET_PASSWORD: '/sign-in/reset-password',
    SELECT_MFA: '/sign-in/select-mfa',
    SIGN_IN_CONFIRM: '/sign-in/confirm-mfa',
    SIGN_IN: '/sign-in',
    SIGN_UP_CONFIRM: '/sign-in/activate-account',
    SIGN_UP_RESEND: '/sign-in/resend-activation',
    SIGN_UP: '/sign-up',
};
