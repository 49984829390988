import { DOMAIN } from './Tattles.constants';
import get from 'lodash/get';
import { getEntityById as getTattleEntityById } from '../db/tattle/Tattle.selectors';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

const omitNil = obj => omitBy(obj, val => isNil(val));

export const getStore = state => get(state, DOMAIN) || {};

export const getResults = state => getStore(state).results || [];
export const getResultCount = state => getStore(state).resultCount || 0;
export const getError = state => getStore(state).error;
export const getErrors = state => getStore(state).errors || [];
export const getChanges = state => getStore(state).changes || {};
export const getChangesById = (state, id) => get(getChanges(state), id, {});
export const getErrorsById = (state, id) => get(getChangesById(state, id), 'errors');

export const getRequestFilters = state => getStore(state).filters || {};
export const hasFilters = state =>
    Object.values(getRequestFilters(state)).reduce(
        (hasFilters, filters) => hasFilters || filters.length,
        false
    );
export const hasNextPage = state => getStore(state).nextPage;
export const hasUnsavedChanges = (state, id) =>
    !!Object.keys(id ? getChangesById(state, id) : getChanges(state)).length;
export const hasErrors = (state, id) =>
    id ? getErrors(state).includes(id) : !!getErrors(state).length;

export const getRequestParams = state => {
    const { offset, limit, pageNumber } = getStore(state);
    return {
        offset,
        limit,
        pageNumber,
    };
};

export const isLoading = state => getStore(state).loading;

export const getPayload = (state, id) => {
    const {
        classification,
        description,
        disclosure = false,
        expiry = null,
        pattern,
        vendorId: originalVendorId,
        vendorName: originalVendorName,
    } = getTattleEntityById(state, id);
    const {
        // We don't want to submit errors
        // eslint-disable-next-line no-unused-vars
        errors,
        vendorId = originalVendorId,
        vendorName = originalVendorName,
        ...changes
    } = getChangesById(state, id);
    return omitNil({
        classification,
        description,
        disclosure,
        expiry,
        /*
         *  There is currently no way to allow a customer to select this,
         *  and the assumption should be 'REGEXP' as this column is intended
         *  to be used for server-side matching only and does not impact CMP matches.
         *  Additionally, "DIRECT_MATCH" makes it into a RegExp with `^{string}$`
         */
        // patternType: pattern && pattern !== id ? 'REGEXP' : 'DIRECT_MATCH',
        patternType: 'REGEXP',
        rule: pattern || id,
        storeKey: id,
        ...changes,
        vendorId: vendorId === vendorName ? null : vendorId,
        vendorName,
    });
};
