import { AUTH_STATES } from '../../../authentication/constants';
import AuthActions from '/b2b/authentication/state/Auth.actions';
import Component from './ResetPassword.component';
import { connect } from 'react-redux';
import fromState from '../../../common/state/selectors';
import { resetPassword } from './ResetPassword.commands';

const mapStateToProps = state => {
    return {
        isLoading: fromState.Auth.isLoading(state),
        username: fromState.Auth.user.getId(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        resetAuthState: () => dispatch(AuthActions.updateAuthState(AUTH_STATES.signedOut)),
        onResetPassword: ({ passwords, ...remain }) =>
            dispatch(
                resetPassword({
                    ...remain,
                    confirmNewPassword: passwords,
                })
            ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
