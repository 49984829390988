import {
    ASSESSMENTS_PATHS,
    CONSENTS_PATHS,
    DATA_DISCOVERY_PATHS,
    DATA_MAPPING_PATHS,
    DSAR_REQUEST_PATHS,
    MONITORING_PATHS,
    UNIFIED_CONSENT_PATHS,
} from '/b2b/authentication/constants';

import { DOMAIN } from './Dashboard.constants';
import { TODOS } from '../constants';
import fromState from '/b2b/common/state/selectors';
import get from 'lodash/get';

export const getStore = state => get(state, DOMAIN) || {};

export const getData = state => getStore(state).data || {};
export const getError = state => getStore(state).error;

export const getConsentData = state => getData(state).consent;
export const getStatsData = state => getData(state).stats;
export const getTrafficData = state => getData(state).traffic;
export const getFieldClassificationsData = state => getData(state).fieldClassifications;
export const getTodos = state => {
    const todos = getData(state).todos || {};

    const filteredTodos = Object.fromEntries(
        Object.entries(todos)
            .map(([product, productTodos]) => {
                const filteredTodos = Object.fromEntries(
                    Object.entries(productTodos).filter(([todo]) => {
                        const { customerPermission, rolePermission } = TODOS[todo] || {};

                        // If neither permission is defined, deny access
                        if (!customerPermission && !rolePermission) {
                            return false;
                        }

                        // Check both permissions if they exist
                        const hasCustomerPermission = customerPermission
                            ? fromState.Auth.customer.hasPermission(state, customerPermission)
                            : true;

                        const hasRolePermission = rolePermission
                            ? fromState.Auth.user.hasPermission(state, rolePermission)
                            : true;

                        // If both permissions exist, both must pass; otherwise, the existing one must pass
                        return hasCustomerPermission && hasRolePermission;
                    })
                );

                return [product, filteredTodos];
            })
            .filter(([, filteredTodos]) => Object.keys(filteredTodos).length > 0) // Remove empty products
    );

    return filteredTodos;
};

export const getPermissions = state => {
    const canViewAssessments = fromState.Auth.customer.hasPermission(state, {
        toPerform: 'view',
        onAny: ASSESSMENTS_PATHS,
    });
    const canViewCookieConsent = fromState.Auth.customer.hasPermission(state, {
        toPerform: 'view',
        onAny: CONSENTS_PATHS,
    });
    const canViewDataDiscovery = fromState.Auth.customer.hasPermission(state, {
        toPerform: 'view',
        onAny: DATA_DISCOVERY_PATHS,
    });
    const canViewDataMapping = fromState.Auth.customer.hasPermission(state, {
        toPerform: 'view',
        onAny: DATA_MAPPING_PATHS,
    });
    const canViewMonitoring = fromState.Auth.customer.hasPermission(state, {
        toPerform: 'view',
        onAny: MONITORING_PATHS,
    });
    const canViewSubjectRights = fromState.Auth.customer.hasPermission(state, {
        toPerform: 'view',
        onAny: DSAR_REQUEST_PATHS,
    });
    const canViewUnifiedConsent = fromState.Auth.customer.hasPermission(state, {
        toPerform: 'view',
        onAny: UNIFIED_CONSENT_PATHS,
    });

    return {
        canViewAssessments,
        canViewCookieConsent,
        canViewDataDiscovery,
        canViewDataMapping,
        canViewMonitoring,
        canViewSubjectRights,
        canViewUnifiedConsent,
    };
};

export const isLoading = state => getStore(state).loading;
