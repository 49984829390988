import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    noParameters,
} from '../../../common/helpers/ReduxHelpers';

import { DOMAIN } from './ConversationList.constants';

export const events = {
    openAddConversationDialog: noParameters,
    closeAddConversationDialog: noParameters,
};

export const asyncEvents = makeAsyncEvents(['fetchResults'], true);
export const crudEvents = makeAsyncEvents(['create', 'delete'], true);

export const types = makeActionTypes(
    [...Object.keys(events), ...Object.keys(asyncEvents), ...Object.keys(crudEvents)],
    DOMAIN
);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
        ...crudEvents,
    },
    types
);
