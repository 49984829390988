/* eslint-disable camelcase */
import noop from 'lodash/noop';

const isDev = ['dev', 'development', 'ci'].includes(process.env.MODE);

const thirdPartyLogger = isDev ? console : {};

const Logger = {
    assert: noop,
    clear: noop,
    count: noop,
    countReset: noop,
    debug: thirdPartyLogger.debug ? thirdPartyLogger.debug.bind(thirdPartyLogger) : noop,
    dir: noop,
    dirxml: noop,
    error: thirdPartyLogger.error ? thirdPartyLogger.error.bind(thirdPartyLogger) : noop,
    exception: thirdPartyLogger.error ? thirdPartyLogger.error.bind(thirdPartyLogger) : noop,
    group: noop,
    groupCollapsed: noop,
    groupEnd: noop,
    info: thirdPartyLogger.info ? thirdPartyLogger.info.bind(thirdPartyLogger) : noop,
    log: isDev ? console.log : noop /* eslint-disable-line no-console */,
    profile: noop,
    profileEnd: noop,
    table: noop,
    time: noop,
    timeEnd: noop,
    timeLog: noop,
    timeStamp: noop,
    trace: noop,
    warn: thirdPartyLogger.warning ? thirdPartyLogger.warning.bind(thirdPartyLogger) : noop,
};

export default Logger;
