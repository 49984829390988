import palette from '../palette';
import theme from '../default';

export default {
    root: {
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.up('xs')]: {
            paddingBottom: 0,
        },
    },
    chip: {
        backgroundColor: palette.secondary.main,
        color: palette.secondary.contrastText,
        '&:hover': {
            backgroundColor: palette.secondary.hover,
            color: palette.secondary.contrastText,
        },
        '&:focus': {
            backgroundColor: palette.secondary.light,
            color: palette.secondary.contrastText,
        },
        '& svg': {
            backgroundColor: 'inherit',
            color: palette.secondary.contrastText,
            '&:hover': {
                backgroundColor: 'inherit',
                color: palette.secondary.contrastText,
            },
            '&:focus': {
                backgroundColor: 'inherit',
                color: palette.secondary.contrastText,
            },
        },
    },
};
