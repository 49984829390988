import {
    types as ActionTypes,
    asyncEvents as AsyncEvents,
    crudEvents as CrudEvents,
} from './ConversationList.actions';
import {
    crudPagingReducers,
    genericReducer,
    loadingReducer,
} from '../../../common/helpers/ReduxHelpers';

import { combineReducers } from 'redux';
import schema from '../db/conversation/HelpScoutConversation.schema';

export const initialState = {
    offset: 0,
    limit: 25,
    results: [],
    resultCount: 0,
    error: null,
    loading: false,
    posting: false,
    newPost: null,
};

export default combineReducers({
    newPost: genericReducer(initialState.newPost, {
        [ActionTypes.createBegin]: (state, { params: post }) => {
            return post;
        },
        [ActionTypes.createSuccess]: () => {
            return null;
        },
        [ActionTypes.createFailure]: () => {
            return null;
        },
    }),
    posting: loadingReducer(
        initialState.posting,
        Object.keys(CrudEvents).map(key => ActionTypes[key])
    ),
    ...crudPagingReducers(
        initialState,
        Object.keys(AsyncEvents).map(key => ActionTypes[key]),
        schema
    ),
});
