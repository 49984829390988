import dashboardReducer, { initialState as dashboardInitialState } from './Dashboard.reducer';

import { genericReducer } from '/b2b/common/helpers/ReduxHelpers';

export const initialState = {
    dashboard: dashboardInitialState,
};

export default genericReducer(initialState, {
    dashboard: dashboardReducer,
});
