import { DOMAIN } from './RequestTypes.constants';
import get from 'lodash/get';
import schema from '/b2b/dsarRequests/state/db/RequestType.schema';

export const getStore = state => get(state, DOMAIN) || {};
export const getTypes = state => getStore(state).requestTypes;
export const getError = state => getStore(state).error;

export const isLoading = state => getStore(state).loading;

export const getIds = state => getTypes(state).map(result => schema.idAttribute(result));
