import AuthenticatedPaths from '../../../routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import UnauthenticatedPaths from '../../../routing/UnauthenticatedRoutes/UnauthenticatedRoutes.paths';

export const DOMAIN = 'ui';

// We inverse alphabetically sort the paths so that the most specific paths are first
const validPaths = Object.values({
    ...UnauthenticatedPaths,
    ...AuthenticatedPaths,
}).sort((pathA, pathB) => pathB.localeCompare(pathA));
export const pathMatcher = path => validPaths.find(authPath => path.indexOf(authPath) === 0);

export const documentTitleByPath = {
    [AuthenticatedPaths.ASSESSMENTS]: 'title.assessments',
    [AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS]: 'title.assessmentAssignments',
    [AuthenticatedPaths.ACCOUNT]: 'title.account',
    [AuthenticatedPaths.API_KEYS]: 'title.apiKeys',
    [AuthenticatedPaths.ASK_ANALYST]: 'title.consultPrivacyTeam',
    [AuthenticatedPaths.CONSENT_MANAGER]: 'title.consentManager',
    [AuthenticatedPaths.UC_PRIVACY_PROTOCOLS]: 'title.ucProtocols',
    [AuthenticatedPaths.UC_CONFIGS]: 'common.configurations',
    [AuthenticatedPaths.UC_DASHBOARD]: 'common.reporting',
    [AuthenticatedPaths.UC_INTEGRATIONS]: 'title.ucIntegrations',
    [AuthenticatedPaths.DASHBOARD]: 'title.dashboard',
    [AuthenticatedPaths.DATA_STORES]: 'title.dataProviders',
    [AuthenticatedPaths.DOCUMENT_CHANGES]:
        'layouts.Dashboard.components.Sidebar.policyChanges.label',
    [AuthenticatedPaths.DOCUMENTS]: 'Documents',
    [AuthenticatedPaths.DSAR_ACTION_ITEMS]: 'dsar.actionItems.title',
    [AuthenticatedPaths.DSAR_FORMS]: 'title.dsarForms',
    [AuthenticatedPaths.DSAR_REQUESTS]: 'title.dsar',
    [AuthenticatedPaths.FOLLOWING]: 'title.following',
    [AuthenticatedPaths.GDPR]: 'title.gdpr',
    [AuthenticatedPaths.LEGISLATION]: 'title.legislation',
    [AuthenticatedPaths.LITIGATION]: 'title.litigation',
    [AuthenticatedPaths.NOTIFICATIONS]: 'title.notifications',
    [AuthenticatedPaths.ORGANIZATIONS]: 'common.organizations',
    [AuthenticatedPaths.PRODUCTS]: 'title.products',
    [AuthenticatedPaths.USERS]: 'title.users',
    [AuthenticatedPaths.VENDORS]: 'title.vendors',
    [UnauthenticatedPaths.SELECT_MFA]: 'title.selectMFA',
    [UnauthenticatedPaths.SIGN_IN_CONFIRM]: 'common.signIn',
    [UnauthenticatedPaths.SIGN_IN]: 'common.signIn',
    [UnauthenticatedPaths.SIGN_UP_CONFIRM]: 'common.signIn',
    [UnauthenticatedPaths.SIGN_UP]: 'common.signIn',
};
