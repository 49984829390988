import Actions from '../../state/ui/UI.actions';
import Component from './SnackbarTray.component';
import { connect } from 'react-redux';
import fromState from '../../state/selectors';

const mapStateToProps = state => {
    return {
        snacks: fromState.UI.getSnacks(state),
        snackOrder: fromState.UI.getSnackOrder(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteSnack: key => dispatch(Actions.deleteSnack(key)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
