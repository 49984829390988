import Component from './SetupPhone.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';
import { update } from './SetupPhone.commands';

const mapStateToProps = state => {
    const phone = fromState.Auth.getPhone(state);
    return {
        username: fromState.Auth.user.getId(state),
        phone,
        hasPhone: !!phone,
        authState: fromState.Auth.getAuthState(state),
        isLoading: fromState.Auth.isLoading(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: params => dispatch(update(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
