/* eslint react/prop-types: 0 */
import React from 'react';
import { SvgIcon } from '@mui/material';

import { white } from '../../colors';

export default function Vendors(props) {
    return (
        <SvgIcon fontSize="large" viewBox="0 0 24 24" {...props}>
            <path
                d="M12,22.9c-0.1,0-0.3,0-0.4,0c-0.3-0.1-0.5-0.2-0.8-0.3C8.5,21.1,3,17.4,3,12V7.2c0-0.9,0-1.4,0.2-1.9
                C3.4,4.9,3.7,4.5,4,4.1c0.4-0.4,0.9-0.5,1.7-0.9l5.4-2c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0.1,0.6,0.2l5.4,2
                c0.8,0.3,1.3,0.5,1.7,0.9c0.4,0.3,0.6,0.7,0.8,1.2C21,5.8,21,6.3,21,7.2V12c0,5.4-5.5,9.1-7.8,10.5c-0.3,0.2-0.5,0.3-0.8,0.3
                C12.3,22.8,12.1,22.9,12,22.9z M12,3.1c0,0-0.1,0-0.2,0.1l-5.4,2C5.8,5.4,5.5,5.5,5.3,5.6C5.2,5.7,5.1,5.9,5.1,6C5,6.2,5,6.6,5,7.2
                V12c0,4.4,5.2,7.8,6.8,8.8c0.1,0.1,0.2,0.1,0.2,0.1c0,0,0.1-0.1,0.2-0.1c1.6-0.9,6.8-4.3,6.8-8.8V7.2c0-0.6,0-1-0.1-1.2
                c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.5-0.3-1.1-0.5l-5.4-2C12.1,3.1,12,3.1,12,3.1z M11,14.5c-0.3,0-0.5-0.1-0.7-0.3l-2-2
                c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l1.3,1.3l3.8-3.8c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-4.5,4.5C11.5,14.4,11.3,14.5,11,14.5z"
                fill={white}
            />
        </SvgIcon>
    );
}
