export const languageCodeLookup = {
    ab: {
        en: 'Abkhaz',
        native: 'аҧсуа',
    },
    aa: {
        en: 'Afar',
        native: 'Afaraf',
    },
    af: {
        en: 'Afrikaans',
        native: 'Afrikaans',
    },
    ak: {
        en: 'Akan',
        native: 'Akan',
    },
    sq: {
        en: 'Albanian',
        native: 'Shqip',
    },
    am: {
        en: 'Amharic',
        native: 'አማርኛ',
    },
    ar: {
        en: 'Arabic',
        native: 'العربية',
    },
    an: {
        en: 'Aragonese',
        native: 'Aragonés',
    },
    hy: {
        en: 'Armenian',
        native: 'Հայերեն',
    },
    as: {
        en: 'Assamese',
        native: 'অসমীয়া',
    },
    av: {
        en: 'Avaric',
        native: 'авар мацӀ, магӀарул мацӀ',
    },
    ae: {
        en: 'Avestan',
        native: 'avesta',
    },
    ay: {
        en: 'Aymara',
        native: 'aymar aru',
    },
    az: {
        en: 'Azerbaijani',
        native: 'azərbaycan dili',
    },
    bm: {
        en: 'Bambara',
        native: 'bamanankan',
    },
    ba: {
        en: 'Bashkir',
        native: 'башҡорт теле',
    },
    eu: {
        en: 'Basque',
        native: 'euskara, euskera',
    },
    be: {
        en: 'Belarusian',
        native: 'Беларуская',
    },
    bn: {
        en: 'Bengali',
        native: 'বাংলা',
    },
    bh: {
        en: 'Bihari',
        native: 'भोजपुरी',
    },
    bi: {
        en: 'Bislama',
        native: 'Bislama',
    },
    bs: {
        en: 'Bosnian',
        native: 'bosanski jezik',
    },
    br: {
        en: 'Breton',
        native: 'brezhoneg',
    },
    bg: {
        en: 'Bulgarian',
        native: 'български език',
    },
    my: {
        en: 'Burmese',
        native: 'ဗမာစာ',
    },
    ca: {
        en: 'Catalan; Valencian',
        native: 'Català',
    },
    ch: {
        en: 'Chamorro',
        native: 'Chamoru',
    },
    ce: {
        en: 'Chechen',
        native: 'нохчийн мотт',
    },
    ny: {
        en: 'Chichewa; Chewa; Nyanja',
        native: 'chiCheŵa, chinyanja',
    },
    zh: {
        en: 'Chinese',
        native: '中文 (Zhōngwén), 汉语, 漢語',
    },
    'zh-hk': {
        en: 'Chinese (Hong Kong SAR)',
        native: '中文 香港 (Zhōngwén xiānggǎng)',
    },
    'zh-tw': {
        en: 'Chinese (Taiwan)',
        native: '中文 台灣 (Zhōngwén táiwān)',
    },
    cv: {
        en: 'Chuvash',
        native: 'чӑваш чӗлхи',
    },
    kw: {
        en: 'Cornish',
        native: 'Kernewek',
    },
    co: {
        en: 'Corsican',
        native: 'corsu, lingua corsa',
    },
    cr: {
        en: 'Cree',
        native: 'ᓀᐦᐃᔭᐍᐏᐣ',
    },
    hr: {
        en: 'Croatian',
        native: 'hrvatski',
    },
    cs: {
        en: 'Czech',
        native: 'česky, čeština',
    },
    da: {
        en: 'Danish',
        native: 'dansk',
    },
    dv: {
        en: 'Divehi; Dhivehi; Maldivian;',
        native: 'ދިވެހި',
    },
    nl: {
        en: 'Dutch',
        native: 'Nederlands, Vlaams',
    },
    en: {
        en: 'English',
        native: 'English',
    },
    'en-gb': {
        en: 'British English',
        native: 'British English',
    },
    eo: {
        en: 'Esperanto',
        native: 'Esperanto',
    },
    et: {
        en: 'Estonian',
        native: 'eesti, eesti keel',
    },
    ee: {
        en: 'Ewe',
        native: 'Eʋegbe',
    },
    fo: {
        en: 'Faroese',
        native: 'føroyskt',
    },
    fj: {
        en: 'Fijian',
        native: 'vosa Vakaviti',
    },
    fi: {
        en: 'Finnish',
        native: 'suomi, suomen kieli',
    },
    fr: {
        en: 'French',
        native: 'français, langue française',
    },
    ff: {
        en: 'Fula; Fulah; Pulaar; Pular',
        native: 'Fulfulde, Pulaar, Pular',
    },
    gl: {
        en: 'Galician',
        native: 'Galego',
    },
    ka: {
        en: 'Georgian',
        native: 'ქართული',
    },
    de: {
        en: 'German',
        native: 'Deutsch',
    },
    el: {
        en: 'Greek, Modern',
        native: 'Ελληνικά',
    },
    gn: {
        en: 'Guaraní',
        native: 'Avañeẽ',
    },
    gu: {
        en: 'Gujarati',
        native: 'ગુજરાતી',
    },
    ht: {
        en: 'Haitian; Haitian Creole',
        native: 'Kreyòl ayisyen',
    },
    ha: {
        en: 'Hausa',
        native: 'Hausa, هَوُسَ',
    },
    he: {
        en: 'Hebrew (modern)',
        native: 'עברית',
    },
    hz: {
        en: 'Herero',
        native: 'Otjiherero',
    },
    hi: {
        en: 'Hindi',
        native: 'हिन्दी, हिंदी',
    },
    ho: {
        en: 'Hiri Motu',
        native: 'Hiri Motu',
    },
    hu: {
        en: 'Hungarian',
        native: 'Magyar',
    },
    ia: {
        en: 'Interlingua',
        native: 'Interlingua',
    },
    id: {
        en: 'Indonesian',
        native: 'Bahasa Indonesia',
    },
    ie: {
        en: 'Interlingue',
        native: 'Originally called Occidental; then Interlingue after WWII',
    },
    ga: {
        en: 'Irish',
        native: 'Gaeilge',
    },
    ig: {
        en: 'Igbo',
        native: 'Asụsụ Igbo',
    },
    ik: {
        en: 'Inupiaq',
        native: 'Iñupiaq, Iñupiatun',
    },
    io: {
        en: 'Ido',
        native: 'Ido',
    },
    is: {
        en: 'Icelandic',
        native: 'Íslenska',
    },
    it: {
        en: 'Italian',
        native: 'Italiano',
    },
    iu: {
        en: 'Inuktitut',
        native: 'ᐃᓄᒃᑎᑐᑦ',
    },
    ja: {
        en: 'Japanese',
        native: '日本語 (にほんご／にっぽんご)',
    },
    jv: {
        en: 'Javanese',
        native: 'basa Jawa',
    },
    kl: {
        en: 'Kalaallisut, Greenlandic',
        native: 'kalaallisut, kalaallit oqaasii',
    },
    kn: {
        en: 'Kannada',
        native: 'ಕನ್ನಡ',
    },
    kr: {
        en: 'Kanuri',
        native: 'Kanuri',
    },
    ks: {
        en: 'Kashmiri',
        native: 'कश्मीरी, كشميري‎',
    },
    kk: {
        en: 'Kazakh',
        native: 'Қазақ тілі',
    },
    km: {
        en: 'Khmer',
        native: 'ភាសាខ្មែរ',
    },
    ki: {
        en: 'Kikuyu, Gikuyu',
        native: 'Gĩkũyũ',
    },
    rw: {
        en: 'Kinyarwanda',
        native: 'Ikinyarwanda',
    },
    ky: {
        en: 'Kirghiz, Kyrgyz',
        native: 'кыргыз тили',
    },
    kv: {
        en: 'Komi',
        native: 'коми кыв',
    },
    kg: {
        en: 'Kongo',
        native: 'KiKongo',
    },
    ko: {
        en: 'Korean',
        native: '한국어 (韓國語), 조선말 (朝鮮語)',
    },
    ku: {
        en: 'Kurdish',
        native: 'Kurdî, كوردی‎',
    },
    kj: {
        en: 'Kwanyama, Kuanyama',
        native: 'Kuanyama',
    },
    la: {
        en: 'Latin',
        native: 'latine, lingua latina',
    },
    lb: {
        en: 'Luxembourgish, Letzeburgesch',
        native: 'Lëtzebuergesch',
    },
    lg: {
        en: 'Luganda',
        native: 'Luganda',
    },
    li: {
        en: 'Limburgish, Limburgan, Limburger',
        native: 'Limburgs',
    },
    ln: {
        en: 'Lingala',
        native: 'Lingála',
    },
    lo: {
        en: 'Lao',
        native: 'ພາສາລາວ',
    },
    lt: {
        en: 'Lithuanian',
        native: 'lietuvių kalba',
    },
    lu: {
        en: 'Luba-Katanga',
        native: '',
    },
    lv: {
        en: 'Latvian',
        native: 'latviešu valoda',
    },
    gv: {
        en: 'Manx',
        native: 'Gaelg, Gailck',
    },
    mk: {
        en: 'Macedonian',
        native: 'македонски јазик',
    },
    mg: {
        en: 'Malagasy',
        native: 'Malagasy fiteny',
    },
    ms: {
        en: 'Malay',
        native: 'bahasa Melayu, بهاس ملايو‎',
    },
    ml: {
        en: 'Malayalam',
        native: 'മലയാളം',
    },
    mt: {
        en: 'Maltese',
        native: 'Malti',
    },
    mi: {
        en: 'Māori',
        native: 'te reo Māori',
    },
    mr: {
        en: 'Marathi (Marāṭhī)',
        native: 'मराठी',
    },
    mh: {
        en: 'Marshallese',
        native: 'Kajin M̧ajeļ',
    },
    mn: {
        en: 'Mongolian',
        native: 'монгол',
    },
    na: {
        en: 'Nauru',
        native: 'Ekakairũ Naoero',
    },
    nv: {
        en: 'Navajo, Navaho',
        native: 'Diné bizaad, Dinékʼehǰí',
    },
    nb: {
        en: 'Norwegian Bokmål',
        native: 'Norsk bokmål',
    },
    nd: {
        en: 'North Ndebele',
        native: 'isiNdebele',
    },
    ne: {
        en: 'Nepali',
        native: 'नेपाली',
    },
    ng: {
        en: 'Ndonga',
        native: 'Owambo',
    },
    nn: {
        en: 'Norwegian Nynorsk',
        native: 'Norsk nynorsk',
    },
    no: {
        en: 'Norwegian',
        native: 'Norsk',
    },
    ii: {
        en: 'Nuosu',
        native: 'ꆈꌠ꒿ Nuosuhxop',
    },
    nr: {
        en: 'South Ndebele',
        native: 'isiNdebele',
    },
    oc: {
        en: 'Occitan',
        native: 'Occitan',
    },
    oj: {
        en: 'Ojibwe, Ojibwa',
        native: 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
    },
    cu: {
        en: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
        native: 'ѩзыкъ словѣньскъ',
    },
    om: {
        en: 'Oromo',
        native: 'Afaan Oromoo',
    },
    or: {
        en: 'Oriya',
        native: 'ଓଡ଼ିଆ',
    },
    os: {
        en: 'Ossetian, Ossetic',
        native: 'ирон æвзаг',
    },
    pa: {
        en: 'Panjabi, Punjabi',
        native: 'ਪੰਜਾਬੀ, پنجابی‎',
    },
    pi: {
        en: 'Pāli',
        native: 'पाऴि',
    },
    fa: {
        en: 'Persian',
        native: 'فارسی',
    },
    pl: {
        en: 'Polish',
        native: 'polski',
    },
    ps: {
        en: 'Pashto, Pushto',
        native: 'پښتو',
    },
    pt: {
        en: 'Portuguese',
        native: 'Português',
    },
    'pt-br': {
        en: 'Brazilian Portuguese',
        native: 'Português Brasileiro',
    },
    qu: {
        en: 'Quechua',
        native: 'Runa Simi, Kichwa',
    },
    rm: {
        en: 'Romansh',
        native: 'rumantsch grischun',
    },
    rn: {
        en: 'Kirundi',
        native: 'kiRundi',
    },
    ro: {
        en: 'Romanian, Moldavian, Moldovan',
        native: 'română',
    },
    ru: {
        en: 'Russian',
        native: 'русский язык',
    },
    sa: {
        en: 'Sanskrit (Saṁskṛta)',
        native: 'संस्कृतम्',
    },
    sc: {
        en: 'Sardinian',
        native: 'sardu',
    },
    sd: {
        en: 'Sindhi',
        native: 'सिन्धी, سنڌي، سندھی‎',
    },
    se: {
        en: 'Northern Sami',
        native: 'Davvisámegiella',
    },
    sm: {
        en: 'Samoan',
        native: 'gagana faa Samoa',
    },
    sg: {
        en: 'Sango',
        native: 'yângâ tî sängö',
    },
    sr: {
        en: 'Serbian',
        native: 'српски језик',
    },
    gd: {
        en: 'Scottish Gaelic; Gaelic',
        native: 'Gàidhlig',
    },
    sn: {
        en: 'Shona',
        native: 'chiShona',
    },
    si: {
        en: 'Sinhala, Sinhalese',
        native: 'සිංහල',
    },
    sk: {
        en: 'Slovak',
        native: 'slovenčina',
    },
    sl: {
        en: 'Slovene',
        native: 'slovenščina',
    },
    so: {
        en: 'Somali',
        native: 'Soomaaliga, af Soomaali',
    },
    st: {
        en: 'Southern Sotho',
        native: 'Sesotho',
    },
    es: {
        en: 'Spanish; Castilian',
        native: 'español, castellano',
    },
    su: {
        en: 'Sundanese',
        native: 'Basa Sunda',
    },
    sw: {
        en: 'Swahili',
        native: 'Kiswahili',
    },
    ss: {
        en: 'Swati',
        native: 'SiSwati',
    },
    sv: {
        en: 'Swedish',
        native: 'svenska',
    },
    ta: {
        en: 'Tamil',
        native: 'தமிழ்',
    },
    te: {
        en: 'Telugu',
        native: 'తెలుగు',
    },
    tg: {
        en: 'Tajik',
        native: 'тоҷикӣ, toğikī, تاجیکی‎',
    },
    th: {
        en: 'Thai',
        native: 'ไทย',
    },
    ti: {
        en: 'Tigrinya',
        native: 'ትግርኛ',
    },
    bo: {
        en: 'Tibetan Standard, Tibetan, Central',
        native: 'བོད་ཡིག',
    },
    tk: {
        en: 'Turkmen',
        native: 'Türkmen, Түркмен',
    },
    tl: {
        en: 'Tagalog',
        native: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
    },
    tn: {
        en: 'Tswana',
        native: 'Setswana',
    },
    to: {
        en: 'Tonga (Tonga Islands)',
        native: 'faka Tonga',
    },
    tr: {
        en: 'Turkish',
        native: 'Türkçe',
    },
    ts: {
        en: 'Tsonga',
        native: 'Xitsonga',
    },
    tt: {
        en: 'Tatar',
        native: 'татарча, tatarça, تاتارچا‎',
    },
    tw: {
        en: 'Twi',
        native: 'Twi',
    },
    ty: {
        en: 'Tahitian',
        native: 'Reo Tahiti',
    },
    ug: {
        en: 'Uighur, Uyghur',
        native: 'Uyƣurqə, ئۇيغۇرچە‎',
    },
    uk: {
        en: 'Ukrainian',
        native: 'українська',
    },
    ur: {
        en: 'Urdu',
        native: 'اردو',
    },
    uz: {
        en: 'Uzbek',
        native: 'zbek, Ўзбек, أۇزبېك‎',
    },
    ve: {
        en: 'Venda',
        native: 'Tshivenḓa',
    },
    vi: {
        en: 'Vietense',
        native: 'Tiếng Việt',
    },
    vo: {
        en: 'Volapük',
        native: 'Volapük',
    },
    wa: {
        en: 'Walloon',
        native: 'Walon',
    },
    cy: {
        en: 'Welsh',
        native: 'Cymraeg',
    },
    wo: {
        en: 'Wolof',
        native: 'Wollof',
    },
    fy: {
        en: 'Western Frisian',
        native: 'Frysk',
    },
    xh: {
        en: 'Xhosa',
        native: 'isiXhosa',
    },
    yi: {
        en: 'Yiddish',
        native: 'ייִדיש',
    },
    yo: {
        en: 'Yoruba',
        native: 'Yorùbá',
    },
    za: {
        en: 'Zhuang, Chuang',
        native: 'Saɯ cueŋƅ, Saw cuengh',
    },
    'de-at': {
        en: 'Austrian German',
        native: 'Österreichisches Deutsch',
    },
    'nl-be': {
        en: 'Belgian Dutch',
        native: 'Belgisch Nederlands',
    },
    'fr-ca': {
        en: 'French Canadian',
        native: 'Français Canadien',
    },
    'es-mx': {
        en: 'Mexican Spanish',
        native: 'Español Mexicano',
    },
};

export const languageOptions = [
    { label: 'English', value: 'en' },
    ...Object.entries(languageCodeLookup)
        .filter(([isoCode]) => isoCode !== 'en')
        .map(([value, { en: label }]) => ({
            label,
            value,
        })),
];
export const getLanguageName = (isoFrom, isoTo = 'en') =>
    languageCodeLookup[isoFrom] ? languageCodeLookup[isoFrom][isoTo] || 'Unknown' : 'Unknown';
