import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    singleParameter,
    withMeta,
} from '../../helpers/ReduxHelpers';

export const events = {
    init: singleParameter,
    create: withMeta(singleParameter),
    retrieve: withMeta(singleParameter),
    update: withMeta(singleParameter),
    delete: withMeta(singleParameter),
};

export const asyncEvents = makeAsyncEvents(['create', 'retrieve', 'update', 'delete'], true);

export const types = makeActionTypes([...Object.keys(events), ...Object.keys(asyncEvents)], 'DB');

export default buildActionCreators({ ...events, ...asyncEvents }, types);
