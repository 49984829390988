import { ENABLE_DASHBOARD_V2, isFeatureEnabled } from '/b2b/common/helpers/FeatureFlags';
import { fetchDashboard, fetchDashboardLegacy } from './Dashboard.commands';

import Component from './Dashboard.component';
import { connect } from 'react-redux';
import fromState from '../../common/state/selectors';

const mapStateToProps = state => ({
    isDashboardV2Enabled: isFeatureEnabled(state, ENABLE_DASHBOARD_V2),
    permissions: fromState.Dashboard.getPermissions(state),
    todos: fromState.Dashboard.getTodos(state),
});

const mapDispatchToProps = {
    fetchDashboard,
    fetchDashboardLegacy,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
