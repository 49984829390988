import { Button, CircularProgress } from '@mui/material';
import React, { useMemo } from 'react';

import Locale from '../Locale';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import styles from './SubmitButton.styles';

const useStyles = makeStyles(styles);

/**
 * @typedef OsanoSubmitButtonBaseProps
 * @property {string} [formId] The ID of the form that the button should submit
 * @property {boolean} [loading] Whether the button is in a loading state
 * @property {string} [loadingText] The text to display when the button is in a loading state (can be a localization string)
 */

/**
 * @typedef {import('@mui/material').ButtonBaseProps & OsanoSubmitButtonBaseProps} OsanoSubmitButtonProps
 */

/**
 * @type {React.ForwardRefExoticComponent<OsanoSubmitButtonProps>}
 */
const SubmitButton = React.forwardRef((props, ref /* eslint-disable-line no-unused-vars */) => {
    const {
        children,
        formId,
        loading,
        className,
        loadingText,
        size = 'large',
        disabled,
        ...remain
    } = props;
    const classes = useStyles(props);
    const localizedLoadingText = useMemo(
        () => (loadingText ? <Locale path={loadingText} /> : ' '),
        [loadingText]
    );
    return (
        <Button
            form={formId}
            color="primary"
            size={size}
            variant="contained"
            type="submit"
            className={classNames(className, classes.root)}
            ref={ref}
            disabled={loading || disabled}
            {...remain}
        >
            {loading ? localizedLoadingText : children}
            {loading && (
                <CircularProgress
                    className={classNames(
                        loadingText ? classes.loading : classes.progress,
                        size === 'large'
                            ? classes.large
                            : size === 'medium'
                              ? classes.medium
                              : classes.small
                    )}
                    color="inherit"
                    size={loadingText ? 12 : size === 'large' ? 32 : size === 'medium' ? 24 : 16}
                />
            )}
        </Button>
    );
});

SubmitButton.propTypes = {
    children: PropTypes.node,
    formId: PropTypes.string,
    loading: PropTypes.bool,
    loadingText: PropTypes.any,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.string,
};

export default SubmitButton;
