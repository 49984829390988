import { types as ActionTypes, asyncEvents as AsyncEvents } from './Dashboard.actions';
import {
    errorReducer,
    genericReducer,
    loadingReducer,
    passthroughReducer,
} from '../../common/helpers/ReduxHelpers';

import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

export const initialState = {
    error: null,
    filters: {
        configurations: [],
        privacyProtocols: [],
        jurisdictions: [],
        dateRange: [],
    },
    loading: false,
};

export default reduceReducers(
    initialState,
    combineReducers({
        filters: passthroughReducer(initialState.filters),
        loading: loadingReducer(
            initialState.loading,
            Object.keys(AsyncEvents).map(key => ActionTypes[key])
        ),
        error: errorReducer(
            initialState.error,
            Object.keys(AsyncEvents).map(key => ActionTypes[key])
        ),
    }),
    genericReducer(initialState, {
        [AuthActionTypes.logout]: () => initialState,
    })
);
