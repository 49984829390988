import { DOMAIN } from './DataStoreClassifications.constants';
import get from 'lodash/get';

const emptyArray = [];

export const getStore = state => get(state, DOMAIN) || {};

export const getResults = state => getStore(state).results || emptyArray;
export const getResultCount = state => getStore(state).resultCount || 0;
export const getError = state => getStore(state).error;

export const getRequestParams = state => {
    const { offset, limit, query } = getStore(state);
    return {
        offset,
        limit,
        query,
    };
};

export const getClassificationIdsWithoutSelections = state =>
    getStore(state).classificationIdsWithoutSelections;

export const isLoading = state => getStore(state).loading;
