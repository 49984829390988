import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';
import SMSIcon from '@mui/icons-material/Sms';
import TOTPIcon from '@mui/icons-material/ScreenLockPortrait';

const MFA_OPTIONS = {
    SMS_MFA: {
        icon: SMSIcon,
        primary: 'SMS',
        secondary: '(standard text messaging rates apply)',
        value: 'SMS',
    },
    SOFTWARE_TOKEN_MFA: {
        icon: TOTPIcon,
        primary: 'Authenticator App',
        secondary: '(e.g. Google Authenticator, Authy)',
        value: 'TOTP',
    },
};

const MFAOptionsList = props => {
    const { options, onSelect } = props;
    return (
        <List>
            {options.map(option => {
                const { icon: IconComponent, value, ...remain } = MFA_OPTIONS[option];
                return (
                    <ListItem key={value} button onClick={() => onSelect(value)}>
                        <ListItemAvatar>
                            <Avatar>
                                <IconComponent />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText {...remain} />
                    </ListItem>
                );
            })}
        </List>
    );
};

MFAOptionsList.propTypes = {
    options: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func,
};

export default MFAOptionsList;
