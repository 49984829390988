import { types as ActionTypes, asyncEvents as AsyncEvents } from './Consents.actions';

import { combineReducers } from 'redux';
import { resultsReducers } from '../../../common/helpers/ReduxHelpers';

export const initialState = {
    results: [],
    resultCount: 0,
    error: null,
    loading: false,
};

export default combineReducers({
    ...resultsReducers(
        initialState,
        Object.keys(AsyncEvents).map(key => ActionTypes[key])
    ),
});
