export default theme => ({
    toolbarWrapper: {
        padding: theme.spacing(2),
        background: theme.palette.white,
        borderBottom: '1px solid #EDEDED',
    },
    editorWrapper: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        border: '1px solid #EDEDED',
    },
});
