import camelCase from 'lodash/camelCase';
import castArray from 'lodash/castArray';
import { filterUnique } from './Array';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import { lookup } from '../state/db/DB.schema';
import makeActionTypes from './Redux/makeActionTypes';
import { makeAsyncEvents } from './Redux/makeAsyncEvents';
import { matchPath } from 'react-router-dom';
import noop from 'lodash/noop';
import { normalize } from 'normalizr';

export { buildActionCreators } from './Redux/buildActionCreators';
export { createActionCreator } from './Redux/createActionCreator';
export { genericReducer } from './Redux/genericReducer';
export { makeActionTypes } from './Redux/makeActionTypes';
export { namedParameters, noParameters, singleParameter, withMeta } from './Redux/parameterize';
export { prefixActionTypes } from './Redux/prefixActionTypes';
export { makeAsyncEvents } from './Redux/makeAsyncEvents';

export const CRUDActionTypes = makeActionTypes(
    Object.keys(
        makeAsyncEvents(['fetchResults', 'fetchDetails', 'create', 'retrieve', 'update', 'delete'])
    )
);

export const isStateUnchanged = (stateChanges, originalState) =>
    Object.keys(stateChanges).every(key => {
        const a = stateChanges[key];
        const b = originalState[key];
        if ((isPlainObject(a) && isPlainObject(b)) || (isArray(a) && isArray(b))) {
            return isStateUnchanged(a, b);
        }
        return a === b;
    });

export const passthroughReducer = initialValue => state =>
    state === undefined ? initialValue : state;

export const collectionReducer = (initialState = {}, actionTypes) => {
    if (!Array.isArray(actionTypes)) {
        throw new Error(
            `Redux Utils :: 'collectionReducer' actionTypes must be an array of type 'String'`
        );
    }
    return (state, { type, payload = {} }, meta = {}) => {
        const activeState = state === undefined || state === null ? initialState : state;
        const { field, value } = payload || {};
        if (actionTypes.includes(type)) {
            const action = type.split('/').pop();
            if (action.slice(0, 3).toLowerCase() === 'add') {
                let newResults = activeState[field];
                const { update = 'append', index = newResults.length } = meta;
                switch (update) {
                    case 'prepend': {
                        newResults = [value, ...newResults];
                        break;
                    }
                    case 'append': {
                        newResults = [...newResults, value];
                        break;
                    }
                    case 'insert': {
                        newResults = [
                            ...newResults.slice(0, index),
                            value,
                            ...newResults.slice(index + 1),
                        ];
                        break;
                    }
                }
                return {
                    ...activeState,
                    [field]: newResults.filter(filterUnique),
                };
            } else if (action.slice(0, 5).toLowerCase() === 'remove') {
                return {
                    ...activeState,
                    [field]: (activeState[field] || []).filter(val => val !== value),
                };
            } else if (action.slice(0, 6).toLowerCase() === 'filter') {
                return {
                    ...activeState,
                    [field]: (activeState[field] || []).filter(val => value.indexOf(val) === -1),
                };
            } else if (action.slice(0, 3).toLowerCase() === 'set') {
                return {
                    ...activeState,
                    [field]: value,
                };
            } else if (action.slice(0, 6).toLowerCase() === 'assign') {
                return {
                    ...activeState,
                    ...value,
                };
            } else if (action.slice(0, 5).toLowerCase() === 'clear') {
                return {
                    ...activeState,
                    [field]: [],
                };
            } else if (action.slice(0, 5).toLowerCase() === 'reset') {
                return initialState;
            }
        }
        return activeState;
    };
};

const asyncRequestLookup = {};
export const extractBaseActionType = type => {
    const check = type.slice(-7).toLowerCase();
    if (check.slice(-5) === 'begin') {
        return type.slice(0, -6);
    }
    if (check === 'success' || check === 'failure') {
        return type.slice(0, -8);
    }
    return type;
};
export const setMostRecentAsyncRequest = (
    type,
    { asyncId, requestName = extractBaseActionType(type) } = {}
) => {
    // State actions should never be stored
    if (type.indexOf('@@') !== 0 || asyncId) {
        asyncRequestLookup[requestName] = asyncId;
    }
};
export const isMostRecentAsyncRequest = (
    type,
    { asyncId, requestName = extractBaseActionType(type) } = {}
) => {
    return (
        // State actions should always be permitted
        type.indexOf('@@') === 0 || !asyncId || asyncRequestLookup[requestName] === asyncId
    );
};

export const loadingReducer = (initialState = false, asyncTypes) => {
    if (!Array.isArray(asyncTypes)) {
        throw new Error(
            `Redux Utils :: 'loadingReducer' asyncTypes must be an array of type 'String'`
        );
    }
    return (state, { type, meta }) => {
        const activeState = state === undefined || state === null ? !!initialState : state;
        if (asyncTypes.includes(type)) {
            if (type.slice(-5).toLowerCase() === 'begin') {
                setMostRecentAsyncRequest(type, meta);
                return true;
            } else if (
                type.slice(-7).toLowerCase() === 'success' ||
                type.slice(-7).toLowerCase() === 'failure'
            ) {
                return isMostRecentAsyncRequest(type, meta) ? false : state;
            }
        }
        return activeState;
    };
};

export const defaultError = 'An error occurred';

export const errorReducer = (initialState = null, asyncTypes) => {
    if (!Array.isArray(asyncTypes)) {
        throw new Error(
            `Redux Utils :: 'errorReducer' asyncTypes must be an array of type 'String'`
        );
    }
    return (state, { type, payload = {}, meta }) => {
        const { error = null } = payload || {};
        const { message = error } = error || {};
        const activeState = state === undefined || state === null ? initialState : state;
        if (asyncTypes.includes(type)) {
            if (type.slice(-5).toLowerCase() === 'begin') {
                setMostRecentAsyncRequest(type, meta);
                return null;
            } else if (type.slice(-7).toLowerCase() === 'failure') {
                return isMostRecentAsyncRequest(type, meta) ? message || defaultError : state;
            } else if (type.slice(-7).toLowerCase() === 'success') {
                return isMostRecentAsyncRequest(type, meta) ? null : state;
            }
        }
        return activeState;
    };
};

export const asyncReducer = (
    initialState = null,
    asyncTypes,
    { onSuccess = noop, onFailure = noop, onBegin = noop } = {}
) => {
    if (!Array.isArray(asyncTypes)) {
        throw new Error(
            `Redux Utils :: 'asyncReducer' asyncTypes must be an array of type 'String'`
        );
    }
    return (state, { type, payload, meta } = {}) => {
        const activeState = state === undefined || state === null ? initialState : state;
        if (asyncTypes.includes(type)) {
            if (type.slice(-7).toLowerCase() === 'success') {
                const newState = isMostRecentAsyncRequest(type, meta)
                    ? onSuccess(activeState, payload, meta, type)
                    : activeState;
                return newState !== undefined ? newState : activeState;
            } else if (type.slice(-7).toLowerCase() === 'failure') {
                const newState = isMostRecentAsyncRequest(type, meta)
                    ? onFailure(activeState, payload, meta, type)
                    : activeState;
                return newState !== undefined ? newState : activeState;
            } else if (type.slice(-5).toLowerCase() === 'begin') {
                setMostRecentAsyncRequest(type, meta);
                const newState = onBegin(activeState, payload, meta, type);
                return newState !== undefined ? newState : activeState;
            }
        }
        return activeState;
    };
};

export const responseReducer = (initialState = null, asyncTypes, callbacks) => {
    if (!Array.isArray(asyncTypes)) {
        throw new Error(
            `Redux Utils :: 'responseReducer' asyncTypes must be an array of type 'String'`
        );
    }
    const reducer = asyncReducer(initialState, asyncTypes, callbacks);
    return (state, { type, payload = {}, meta }) => {
        const { response = payload } = payload || {};
        return reducer(state, { type, payload: response, meta });
    };
};

export const paramsReducer = (initialState = null, asyncTypes, callbacks) => {
    if (!Array.isArray(asyncTypes)) {
        throw new Error(
            `Redux Utils :: 'paramsReducer' asyncTypes must be an array of type 'String'`
        );
    }
    const reducer = asyncReducer(initialState, asyncTypes, callbacks);
    return (state, { type, payload = {}, meta }) => {
        const { params = {} } = payload || {};
        return reducer(state, { type, payload: params, meta });
    };
};

export const defaultCRUDState = {
    loading: false,
    error: null,
    id: '',
    offset: 0,
    planData: {},
    limit: 10,
    pageNumber: 1,
    nextPage: null,
    query: '',
    results: [],
    resultCount: 0,
    sort: '',
    sortDirection: 'none',
};

export const initialCRUDStatus = { error: null, loading: false };
export const crudStatusReducer = (initialState = initialCRUDStatus, asyncTypes) => {
    if (!Array.isArray(asyncTypes)) {
        throw new Error(
            `Redux Utils :: 'crudStatusReducer' asyncTypes must be an array of type 'String'`
        );
    }
    return asyncReducer(initialState, asyncTypes, {
        onBegin: () => ({
            loading: true,
            error: null,
        }),
        onFailure: (state, { error }) => ({
            loading: false,
            error,
        }),
        onSuccess: () => initialState,
    });
};

export const crudStatusByIdReducer = (initialState = {}, asyncTypes, ORMSchema) => {
    if (!Array.isArray(asyncTypes)) {
        throw new Error(
            `Redux Utils :: 'crudStatusByIdReducer' asyncTypes must be an array of type 'String'`
        );
    }
    return asyncReducer(initialState, asyncTypes, {
        onBegin: (state, { params }, { id: metaId, schema = ORMSchema } = {}) => {
            const ids = metaId ? castArray(metaId) : Array.isArray(params) ? params : [];
            if (!ids.length) {
                return state;
            }
            const newState = { ...state };
            ids.forEach(update => {
                const updateId = schema ? schema.idAttribute(update) : update;
                newState[updateId] = {
                    ...newState[updateId],
                    loading: true,
                    error: null,
                };
            });
            return newState;
        },
        onFailure: (state, { error, params }, { id: metaId, schema = ORMSchema } = {}) => {
            const ids = metaId ? castArray(metaId) : Array.isArray(params) ? params : [];
            if (!ids.length) {
                return state;
            }
            const newState = { ...state };
            ids.forEach(update => {
                const updateId = schema ? schema.idAttribute(update) : update;
                newState[updateId] = {
                    ...newState[updateId],
                    loading: false,
                    error,
                };
            });
            return newState;
        },
        onSuccess: (state, { params, response = {} }, meta) => {
            const { id: metaId = response.id || response, schema = ORMSchema } = meta || {};
            const ids = metaId ? castArray(metaId) : Array.isArray(params) ? params : [];
            const newState = { ...state };
            let resultIds =
                schema && response
                    ? normalize(castArray(response), [lookup[schema.key] || schema]).result || ids
                    : ids;
            if (schema && (resultIds.length === 0 || resultIds[0] === undefined)) {
                resultIds = ids;
            }
            if (!resultIds.length) {
                return state;
            }
            resultIds.forEach(updateId => {
                delete newState[updateId];
            });
            return newState;
        },
    });
};

export const CRUDDetailsReducers = (passedState = {}, asyncTypes, ORMSchema) => {
    if (!Array.isArray(asyncTypes)) {
        throw new Error(
            `Redux Utils :: 'CRUDDetailsReducers' asyncTypes must be an array of type 'String'`
        );
    }
    const initialState =
        typeof passedState === 'object'
            ? { ...defaultCRUDState, ...passedState }
            : defaultCRUDState;
    return {
        error: errorReducer(initialState.error, asyncTypes),
        loading: loadingReducer(initialState.loading, asyncTypes),
        id: asyncReducer(initialState.id, asyncTypes, {
            onBegin: (state, payload, { id } = {}) => id || state,
            onSuccess: (state, { response = {} }, { id, schema = ORMSchema } = {}) =>
                (schema && normalize(response, lookup[schema.key] || schema).result) || id || state,
        }),
    };
};

export const CRUDPagingQueryReducer =
    (initialState, pathToMatch) =>
    (state, { location = document.location } = {}) => {
        const isCurrentPage = matchPath(location.pathname, {
            path: pathToMatch,
            exact: true,
        });
        if (!isCurrentPage) {
            return {
                ...state,
                offset: initialState.offset || 0,
                pageNumber: initialState.pageNumber || 1,
                // Remember limit
                // limit: initialState.limit,
                query: initialState.query || '',
                sort: initialState.sort || '',
                sortDirection: initialState.sortDirection || 'none',
            };
        }
        const { query, offset, pageNumber, limit, sort, sortDirection } = state;
        const params = new URLSearchParams(location.search);
        return {
            ...state,
            offset: params.get('offset') ? parseInt(params.get('offset'), 10) : offset,
            pageNumber: params.get('pageNumber') || pageNumber,
            query: params.get('query') || query,
            limit: params.get('limit') ? parseInt(params.get('limit'), 10) : limit,
            sort: params.get('sort') || sort,
            sortDirection: params.get('sortDirection') || sortDirection,
        };
    };

const asyncResultsCallbacks = {
    onBegin: (state, payload, meta = {}) => {
        const { params = payload } = payload || {};
        const { offset } = params || {};
        const { update = 'replace' } = meta;
        let newResults = state;
        switch (update) {
            case 'replace': {
                newResults = offset === 0 ? [] : state;
                break;
            }
        }
        return newResults;
    },
    onSuccess: (state, payload, meta = {}, type) => {
        const { response = payload } = payload || {};
        const { results = [] } = response;
        const { update = 'replace', schema, id } = meta;
        if (results && results.length === 0) {
            switch (camelCase(type.split('/').pop())) {
                case 'createSuccess': {
                    results.push(response);
                    break;
                }

                case 'deleteSuccess': {
                    state.forEach(existingId => {
                        if (existingId !== id) {
                            results.push(existingId);
                        }
                    });
                    break;
                }
            }
        }
        let newResults = schema
            ? normalize(castArray(results), [lookup[schema.key] || schema]).result
            : results;
        switch (update) {
            case 'append': {
                newResults = [...state, ...newResults];
                break;
            }
            case 'prepend': {
                newResults = [...newResults, ...state];
                break;
            }
            case 'persist':
            case 'replace': {
                break;
            }
            default: {
                newResults = state;
                break;
            }
        }
        return schema
            ? newResults.filter((id, index) => newResults.indexOf(id) === index)
            : newResults;
    },
};

const asyncResultsCountCallbacks = {
    onBegin: (state, payload, meta = {}) => {
        const { params = payload } = payload || {};
        const { offset } = params || {};
        const { update = 'replace' } = meta;
        switch (update) {
            case 'replace': {
                return offset === 0 ? 0 : state;
            }
        }
        return state;
    },
    onSuccess: (state, payload, meta, type) => {
        const { response = payload } = payload || {};
        let { count, results } = response || {};
        if (count === undefined) {
            switch (camelCase(type.split('/').pop())) {
                case 'createSuccess': {
                    count = results ? results.length : state + 1;
                    break;
                }

                case 'deleteSuccess': {
                    count = results ? results.length : Math.max(state - 1, 0);
                    break;
                }
            }
        }
        return count || 0;
    },
};

const asyncPlanDataCallbacks = {
    onSuccess: (state, response = {}, meta, type) => {
        const { planData } = response;
        let { total, limit } = { ...state, ...planData };
        if (!planData && total !== undefined && limit !== undefined) {
            switch (camelCase(type.split('/').pop())) {
                case 'createSuccess': {
                    total = total >= 0 ? total + 1 : total;
                    break;
                }

                case 'deleteSuccess': {
                    total = total >= 0 ? Math.max(total - 1, 0) : total;
                    break;
                }
            }
        }
        return { total, limit };
    },
};

export const makeAsyncPreviousCallbacks = (previousState, key, defaultValue) => ({
    onBegin: (state, payload) => {
        const { [`${key}`]: value = defaultValue } = payload || {};
        if (state !== value) {
            previousState[`${key}`] = state;
        }
        return value;
    },
    onSuccess: (state, payload) => {
        const { [`${key}`]: value } = payload || {};
        if (value !== undefined) {
            previousState[`${key}`] = value;
        }
        return value;
    },
    onFailure: () => {
        return previousState[`${key}`];
    },
});

export const resultsReducers = (passedState = {}, asyncTypes) => {
    if (!Array.isArray(asyncTypes)) {
        throw new Error(
            `Redux Utils :: 'resultsReducers' asyncTypes must be an array of type 'String'`
        );
    }
    const initialState =
        typeof passedState === 'object'
            ? { ...defaultCRUDState, ...passedState }
            : defaultCRUDState;
    const previousState = { ...initialState };
    return {
        error: errorReducer(initialState.error, asyncTypes),
        loading: loadingReducer(initialState.loading, asyncTypes),
        results: asyncReducer(initialState.results, asyncTypes, asyncResultsCallbacks),
        resultCount: asyncReducer(initialState.resultCount, asyncTypes, asyncResultsCountCallbacks),
        nextPage: responseReducer(
            initialState.nextPage,
            asyncTypes,
            makeAsyncPreviousCallbacks(previousState, 'nextPage', null)
        ),
        pageNumber: responseReducer(
            initialState.pageNumber,
            asyncTypes,
            makeAsyncPreviousCallbacks(previousState, 'pageNumber', 1)
        ),
        planData: responseReducer(initialState.planData, asyncTypes, asyncPlanDataCallbacks),
    };
};

export const crudPagingReducers = (passedState = {}, asyncTypes) => {
    if (!Array.isArray(asyncTypes)) {
        throw new Error(
            `Redux Utils :: 'crudPagingReducers' asyncTypes must be an array of type 'String'`
        );
    }
    const initialState =
        typeof passedState === 'object'
            ? { ...defaultCRUDState, ...passedState }
            : defaultCRUDState;
    const previousState = { ...initialState };
    return {
        error: errorReducer(initialState.error, asyncTypes),
        loading: loadingReducer(initialState.loading, asyncTypes),
        results: asyncReducer(initialState.results, asyncTypes, asyncResultsCallbacks),
        resultCount: asyncReducer(initialState.resultCount, asyncTypes, asyncResultsCountCallbacks),
        offset: paramsReducer(
            initialState.offset,
            asyncTypes,
            makeAsyncPreviousCallbacks(previousState, 'offset', 0)
        ),
        limit: paramsReducer(
            initialState.limit,
            asyncTypes,
            makeAsyncPreviousCallbacks(previousState, 'limit', 25)
        ),
        nextPage: responseReducer(
            initialState.nextPage,
            asyncTypes,
            makeAsyncPreviousCallbacks(previousState, 'nextPage', null)
        ),
        pageNumber: responseReducer(
            initialState.pageNumber,
            asyncTypes,
            makeAsyncPreviousCallbacks(previousState, 'pageNumber', 1)
        ),
        planData: responseReducer(initialState.planData, asyncTypes, asyncPlanDataCallbacks),
        query: paramsReducer(
            initialState.query,
            asyncTypes,
            makeAsyncPreviousCallbacks(previousState, 'query', '')
        ),
        sort: paramsReducer(
            initialState.sort,
            asyncTypes,
            makeAsyncPreviousCallbacks(previousState, 'sort', '')
        ),
        sortDirection: paramsReducer(
            initialState.sortDirection,
            asyncTypes,
            makeAsyncPreviousCallbacks(previousState, 'sortDirection', 'none')
        ),
    };
};
