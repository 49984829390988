import { Box } from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import PropTypes from 'prop-types';
import React from 'react';
import StyleButton from '../StyleButton';
import makeStyles from '@mui/styles/makeStyles';
import styles from './InlineStyleControls.styles';

const useStyles = makeStyles(styles);

const INLINE_STYLES = [
    { label: 'bold', icon: <FormatBoldIcon />, style: 'BOLD' },
    { label: 'italic', icon: <FormatItalicIcon />, style: 'ITALIC' },
    { label: 'underline', icon: <FormatUnderlinedIcon />, style: 'UNDERLINE' },
];

const InlineStyleControls = props => {
    const classes = useStyles(props);
    const { editorState } = props;
    const currentStyle = editorState.getCurrentInlineStyle();

    return (
        <Box className={classes.iconWrapper}>
            {INLINE_STYLES.map((type, i) => (
                <StyleButton
                    key={i}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    icon={type.icon}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            ))}
        </Box>
    );
};

InlineStyleControls.propTypes = {
    editorState: PropTypes.object,
    onToggle: PropTypes.func,
    setEditorState: PropTypes.func,
};

export default InlineStyleControls;
