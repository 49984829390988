import * as clipboard from 'clipboard-polyfill/text';

import Component from './ValidationField.component';
import UIActions from '/b2b/common/state/ui/UI.actions';
import { connect } from 'react-redux';

const mapDispatchToProps = dispatch => ({
    onCopy: text => {
        clipboard.writeText(text).then(
            () =>
                dispatch(
                    UIActions.createSnack('codeBlock.copiedToClipboard', { variant: 'success' })
                ),
            () => dispatch(UIActions.createSnack('codeBlock.unableToCopy', { variant: 'error' }))
        );
    },
});

export default connect(null, mapDispatchToProps)(Component);
