import {
    confirmSignUp as ConfirmSignUpService,
    resendSignUpCode as ResendSignUpCodeService,
} from '/b2b/authentication/services';

import AuthActions from '/b2b/authentication/state/Auth.actions';

export const resendSignUpCode = params => dispatch => {
    dispatch(AuthActions.resendSignUpCodeBegin(params));
    return ResendSignUpCodeService(params)
        .then(response => {
            return dispatch(AuthActions.resendSignUpCodeSuccess(params, response));
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthActions.resendSignUpCodeFailure(params, error));
            throw message;
        });
};

export const confirmSignUp = params => dispatch => {
    dispatch(AuthActions.confirmSignUpBegin(params));
    return ConfirmSignUpService(params)
        .then(response => {
            return dispatch(AuthActions.confirmSignUpSuccess(params, response));
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthActions.confirmSignUpFailure(params, error));
            throw message;
        });
};
