export default theme => ({
    root: {
        width: '350px',
        maxWidth: '100%',
        zIndex: theme.zIndex.appBar,
    },
    header: {
        backgroundColor: theme.palette.background.default,
        backgroundPositionX: 'right',
        backgroundPositionY: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
    },
    subtitle: {
        color: theme.palette.text.secondary,
    },
    content: {},
    listItem: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.background.default,
        },
    },
    listItemIcon: {
        marginRight: 0,
    },
    listItemTextSecondary: {
        marignTop: '4px',
        color: theme.palette.text.secondary,
    },
    arrowForward: {
        color: theme.palette.text.secondary,
        height: '16px',
        width: '16px',
    },
    footer: {
        paddingBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
    },
    empty: {
        textAlign: 'center',
        padding: theme.spacing(3),
    },
    emptyImageWrapper: {
        marginBottom: theme.spacing(3),
    },
    emptyImage: {
        width: '240px',
        maxWidth: '100%',
        height: 'auto',
    },
    textField: {
        display: 'flex',
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        marginTop: theme.spacing(1),
    },
    disabled: {
        backgroundColor: theme.palette.background.disabled,
        opacity: '.4',
        cursor: 'default',
    },
    listSubheader: {
        display: 'flex',
        lineHeight: 1.5,
        flexDirection: 'column',
    },
});
