import {
    allSettled,
    formatFilterParams,
    formatPagingParams,
    handleError,
} from '../common/services/helpers';
import { formatUser, formatUserResults } from './format';
import { mapFromCognitoParams, mapToCognitoParams } from '../authentication/services';

import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import { apiName } from '@osano-b2b';

const allowedUserDetailsProps = [
    'name',
    'email',
    'flags',
    'firstName',
    'lastName',
    'email_verified',
    'address',
    'phone',
];

export const fetchMyUserDetails = () => {
    return API.get(apiName, `/me`, {}).catch(handleError).then(formatUser);
};

export const fetchUserById = userId => {
    return API.get(apiName, `/customer/user/${encodeURIComponent(userId)}`, {})
        .catch(handleError)
        .then(formatUser);
};

export const updateCustomerUserDetails = params => {
    const { email, ...body } = params;

    return API.patch(apiName, `/customer/user/${encodeURIComponent(email)}`, { body })
        .catch(handleError)
        .then(formatUser);
};

export const fetchUserDetails = async passedUser => {
    try {
        const user = passedUser || (await Auth.currentAuthenticatedUser());
        const attributes = await Auth.userAttributes(user);
        const cognitoAttributes = attributes.reduce(
            (details, field) => ((details[field.Name] = field.Value), details),
            {}
        );
        let details = {};
        try {
            details = await fetchUserById(cognitoAttributes.email);
        } catch (error) {
            // Does not have a customer details entry
        }

        return {
            ...formatUser(mapFromCognitoParams(cognitoAttributes, allowedUserDetailsProps)),
            ...details,
        };
    } catch (error) {
        handleError(error);
    }
};

export const updateUserDetails = async params => {
    try {
        await updateCustomerUserDetails(params);
        try {
            const user = await Auth.currentAuthenticatedUser();
            const newProps = mapToCognitoParams(params, [
                'name',
                'given_name',
                'family_name',
                'address',
                'phone_number',
            ]);
            const result = await Auth.updateUserAttributes(user, newProps);

            if (result === 'SUCCESS') {
                return mapFromCognitoParams(newProps);
            }

            throw result;
        } catch (error) {
            // Could not update Cognito user details
        }
    } catch (error) {
        handleError(error);
    }
};

export const createUser = ({ organizations, orgIds = organizations, ...params }) => {
    const options = {
        body: { orgIds, ...params },
        responseType: 'text',
    };

    return API.post(apiName, `/customer/user`, options)
        .then(response => {
            try {
                // Check if the response is JSON
                return JSON.parse(response);
            } catch (error) {
                return { status: 'success', code: 200, response };
            }
        })
        .catch(handleError)
        .then(responseOrId => {
            const { response: resp = responseOrId } = responseOrId;

            return typeof resp === 'object'
                ? { ...params, ...resp }
                : { id: resp || params.email, ...params };
        })
        .then(formatUser);
};

export const fetchUsers = params => {
    const options = {
        queryStringParameters: {
            ...formatFilterParams(params),
            ...formatPagingParams(params),
        },
    };

    return API.get(apiName, `/customer/users`, options)
        .catch(e => handleError(e, options.queryStringParameters))
        .then(formatUserResults);
};

export const deleteUser = userId => {
    return API.del(apiName, `/customer/user/${encodeURIComponent(userId)}`, {
        responseType: 'text',
    })
        .then(response => {
            try {
                // Check if the response is JSON
                return JSON.parse(response);
            } catch (error) {
                return { status: 'success', code: 200, response };
            }
        })
        .catch(handleError)
        .then(response => {
            const { status, response: resp } = response;
            if (status === 'success') {
                return userId;
            }

            return resp || response;
        });
};

export const deleteMultipleUsers = users => {
    return allSettled(users.map(userId => deleteUser(userId)));
};
