import {
    buildActionCreators,
    makeActionTypes,
    noParameters,
    singleParameter,
} from '../../helpers/ReduxHelpers';

export const events = {
    generateCodeBegin: noParameters,
    generateCodeFailure: singleParameter,
    generateCodeSuccess: singleParameter,
};

export const types = makeActionTypes(Object.keys(events), '@@Components__AuthenticatorCode');

export default buildActionCreators(events, types);
