import {
    colorNameToHex,
    contrastRgb,
    hslToRgb,
    hueFromString,
    intToHexString,
    parseColor,
} from '/b2b/common/helpers/Color';
import { defaultIdAttribute, defaultProcessStrategy } from '/b2b/common/helpers/Normalizr';

import { idAttribute as attachmentIdAttribute } from '../attachment/HelpScoutConversationAttachment.schema';
import merge from 'lodash/merge';
import { schema } from 'normalizr';
import unionWith from 'lodash/unionWith';

export const entityName = 'helpScoutConversationThreads';
export const idAttribute = 'helpScoutConversationThreadId';

export const mergeStrategy = (entityA, entityB) => ({
    ...entityA,
    ...entityB,
    attachments: unionWith(entityA.attachments, entityB.attachments, (attachmentA, attachmentB) => {
        const a =
            typeof attachmentA === 'object'
                ? defaultIdAttribute(attachmentIdAttribute)(attachmentA)
                : attachmentA;
        const b =
            typeof attachmentB === 'object'
                ? defaultIdAttribute(attachmentIdAttribute)(attachmentB)
                : attachmentB;
        return a === b;
    }),
    _embedded: merge(entityA._embedded, entityB._embedded),
});
export const processStrategy = defaultProcessStrategy(idAttribute, data => {
    const { type } = data;
    const rgb = hslToRgb(hueFromString(`${type}`) / 360, 0.6, 0.9);
    let { color = intToHexString.apply(null, rgb) } = data;
    color = colorNameToHex(color);
    return {
        textColor: color && contrastRgb.apply(null, parseColor(color)) ? '#ffffff' : '#000000',
        color: color && `#${intToHexString.apply(null, parseColor(color))}`,
        ...data,
        name,
    };
});

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
