import React, { useCallback, useEffect, useMemo } from 'react';
import { canSubmit, getValues } from './ValidationForm.selectors';
import { initState, initialState, default as reducer } from './ValidationForm.reducer';
import { updateField, validateForm } from './ValidationForm.commands';
import { useMemoCompare, useThunkReducer } from '../../helpers/Hooks';

import Actions from './ValidationForm.actions';
import PropTypes from 'prop-types';
import TabbedValidationFormComponent from './TabbedValidationForm.component';
import ValidationFormComponent from './ValidationForm.component';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import throttle from 'lodash/throttle';
import useBoundCallback from '../../helpers/Hooks/useBoundCallback';

const emptyObject = {};

/**
 *
 * @param {object} props
 * @param {object} props.schema - Note that if your schema uses a `depends` property, you must include that property in the `defaultValues` object.
 * @returns
 */
const ValidationFormContainer = ({
    disabled,
    defaultValues = emptyObject,
    isLoading: forceLoading,
    onFieldChange = noop,
    onSubmit,
    validate = noop,
    onValidate = noop,
    schema,
    components,
    helperText,
    extraFields = noop,
    allowSubmit,
    continuousValidation,
    values: manuallyUpdatedValues,
    ...remain
}) => {
    const [state, dispatch, getState] = useThunkReducer(
        reducer,
        {
            continuousValidation,
            defaultValues,
            schema,
        },
        props => initState(initialState, props)
    );
    const {
        errors,
        submitAttempted,
        submitError,
        submitting,
        shouldTab,
        tabSchemas,
        touched,
        values,
    } = state;

    const handleValidateForm = useCallback(
        payload => {
            return dispatch(validateForm(payload, validate)).then(errors => {
                onValidate && onValidate(errors);
                return errors;
            });
        },
        [dispatch, onValidate, validate]
    );

    const handleFieldChange = useCallback(
        (field, value) => {
            dispatch(updateField(field, value, onFieldChange));
        },
        [dispatch, onFieldChange]
    );

    const handleFieldRevert = useBoundCallback(
        (manuallyUpdatedValues, field, multiple) => {
            dispatch(
                Actions.fieldRevert(
                    field,
                    manuallyUpdatedValues[`${field}`] || (multiple ? [] : null)
                )
            );
        },
        [manuallyUpdatedValues]
    );

    const handleFieldBlur = useCallback(
        field => {
            dispatch(Actions.fieldBlur(field));
            handleValidateForm(getValues(getState()));
        },
        [dispatch, handleValidateForm, getState]
    );

    const handleSubmit = useCallback(
        eventOrValues => {
            let payload = getValues(getState());
            try {
                eventOrValues && eventOrValues.preventDefault();
            } catch (error) {
                // eventOrValues is extra parameters
                payload = { ...payload, ...eventOrValues };
            }
            dispatch(Actions.submitBegin());
            return handleValidateForm(payload).then(errors => {
                // If no errors, submit
                if (!errors) {
                    return onSubmit
                        ? onSubmit(payload)
                              .then(response => {
                                  dispatch(Actions.submitSuccess(response));
                                  return response;
                              })
                              .catch(error => {
                                  let { message = error } = error || {};
                                  dispatch(Actions.submitFailure(message));
                                  return {};
                              })
                        : Promise.resolve(dispatch(Actions.submitSuccess()));
                }
                dispatch(Actions.submitFailure(''));
            });
        },
        [dispatch, handleValidateForm, onSubmit]
    );

    const throttledValidate = useMemo(
        () => throttle(handleValidateForm, 300, { trailing: true }),
        [handleValidateForm]
    );

    useEffect(() => {
        throttledValidate(values);
        return () => {
            throttledValidate.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceLoading, values]);

    useEffect(() => {
        !forceLoading && !submitting && dispatch(Actions.reload(undefined, schema, defaultValues));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema]);

    useEffect(() => {
        !forceLoading &&
            !submitting &&
            dispatch(Actions.reload(manuallyUpdatedValues, schema, defaultValues));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manuallyUpdatedValues]);

    useEffect(() => {
        handleValidateForm(getValues(getState()));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canSubmit(state, allowSubmit)]);

    const props = {
        ...remain,
        isLoading: forceLoading || submitting,
        onSubmit: handleSubmit,
        onFieldBlur: handleFieldBlur,
        onFieldChange: handleFieldChange,
        onFieldRevert: handleFieldRevert,
        canSubmit: canSubmit(state, allowSubmit),
        submitError,
        defaultValues,
        errors: useMemoCompare(errors, isEqual),
        values: useMemoCompare(values, isEqual),
        disabled,
        helperText,
        components,
        touched,
        submitAttempted,
    };

    return shouldTab ? (
        <TabbedValidationFormComponent
            {...props}
            tabSchemas={tabSchemas}
            extraFields={extraFields({
                onBlur: handleFieldBlur,
                onChange: handleFieldChange,
                onRevert: handleFieldRevert,
                values,
                touched,
                errors,
            })}
        />
    ) : (
        <ValidationFormComponent
            {...props}
            schema={schema}
            extraFields={extraFields({
                onBlur: handleFieldBlur,
                onChange: handleFieldChange,
                onRevert: handleFieldRevert,
                values,
                touched,
                errors,
            })}
        />
    );
};

ValidationFormContainer.propTypes = {
    allowSubmit: PropTypes.bool,
    continuousValidation: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultValues: PropTypes.object,
    isLoading: PropTypes.bool,
    extraFields: PropTypes.func,
    onFieldChange: PropTypes.func,
    onSubmit: PropTypes.func,
    validate: PropTypes.func,
    onValidate: PropTypes.func,
    schema: PropTypes.object,
    components: PropTypes.object,
    helperText: PropTypes.object,
    values: PropTypes.object,
};

export default ValidationFormContainer;
