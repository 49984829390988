/* eslint react/prop-types: 0 */
import React from 'react';
import { SvgIcon } from '@mui/material';

import { white } from '../../colors';

export default function ConsentManagement(props) {
    return (
        <SvgIcon fontSize="large" viewBox="0 0 24 24" {...props}>
            <path
                d="M16.2,22H7.8C6,22,5,22,4.2,21.6c-0.8-0.4-1.4-1-1.7-1.7C2,19,2,18,2,16.2V7.8C2,6,2,5,2.4,4.2
                c0.4-0.8,1-1.4,1.7-1.7C5,2,6,2,7.8,2H16c0.6,0,1,0.4,1,1s-0.4,1-1,1H7.8C6.3,4,5.5,4,5.1,4.2C4.7,4.4,4.4,4.7,4.2,5.1
                C4,5.5,4,6.3,4,7.8v8.4c0,1.5,0,2.3,0.2,2.7c0.2,0.4,0.5,0.7,0.9,0.9C5.5,20,6.3,20,7.8,20h8.4c1.5,0,2.3,0,2.7-0.2
                c0.4-0.2,0.7-0.5,0.9-0.9c0.2-0.4,0.2-1.2,0.2-2.7V12c0-0.6,0.4-1,1-1s1,0.4,1,1v4.2c0,1.8,0,2.8-0.4,3.6c-0.4,0.8-1,1.4-1.7,1.7
                C19,22,18,22,16.2,22z M12,15c-0.3,0-0.5-0.1-0.7-0.3l-3-3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2.3,2.3l9.3-9.3c0.4-0.4,1-0.4,1.4,0
                s0.4,1,0,1.4l-10,10C12.5,14.9,12.3,15,12,15z"
                fill={white}
            />
        </SvgIcon>
    );
}
