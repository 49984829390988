import { confirmSignIn, resendCode, signOut } from './SignInConfirm.commands';

import Component from './SignInConfirm.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const mapStateToProps = state => ({
    mfaType: fromState.Auth.getMfaType(state),
});

const mapDispatchToProps = {
    confirmSignIn,
    resendCode,
    signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
