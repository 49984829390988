import * as Agents from './agent/DataStoreAgent.selectors';
import * as Classifications from './classification/DataStoreClassification.selectors';
import * as Connections from './connection/DataStoreConnection.selectors';
import * as Fields from './connection/field/DataStoreConnectionField.selectors';
import * as RequestActions from './requestAction/DataStoreRequestAction.selectors';

import { denormalize } from 'normalizr';
import get from 'lodash/get';
import { getStore as getDB } from '../../../common/state/db/DB.selectors';
import schema from './DataStore.schema';

export const agent = Agents;
export const connection = Connections;
export const field = Fields;
export const classification = Classifications;
export const requestAction = RequestActions;

export const DOMAIN = `db.${schema.key}`;

export const getStore = state => get(state, DOMAIN) || {};

export const getEntityById = (state, id) => denormalize(id, schema, getDB(state)) || {};
export const getEntitiesForIds = (state, ids) => denormalize(ids, [schema], getDB(state));

export const getEntityFieldById = (state, { id, field }) =>
    get(getStore(state), [id, field]) || null;
