import { Box, MenuItem, TextField, useMediaQuery } from '@mui/material';
import React, { useCallback, useState } from 'react';

import ErrorIcon from '@mui/icons-material/ErrorOutline';
import Locale from '../Locale';
import LocalizedTooltip from '../LocalizedTooltip';
import PropTypes from 'prop-types';
import Tab from '../Tab';
import TabPanel from '../TabPanel';
import Tabs from '../Tabs';
import castArray from 'lodash/castArray';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material/styles';
import styles from './DynamicTabbed.styles';
import useBoundCallback from '../../helpers/Hooks/useBoundCallback';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(styles);

const VBox = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(0.5),
}));

const errorsForTab = (tabsWithErrors, tab) => {
    const errors = castArray(
        tabsWithErrors && tabsWithErrors.length > 0
            ? tabsWithErrors.filter(err => castArray(err)[0] === tab)
            : []
    ).pop();
    return errors && castArray(errors.slice(-1));
};

const DynamicTabbed = props => {
    const {
        children,
        name,
        tab: controlledTab,
        initialTab,
        onChange,
        title,
        errorTabs,
        ContentWrap,
        ...remain
    } = props;
    const classes = useStyles(props);
    let { orientation } = props;
    const [uncontrolledTab, setTab] = useState(controlledTab || initialTab);
    const tab = controlledTab || uncontrolledTab;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const hasError = useBoundCallback(errorsForTab, [errorTabs]);
    const handleChange = useCallback(
        (event, newValue) => {
            // If tab === -1, the tab you changed to does not exist
            if (newValue === -1) {
                // Since we might need to overwrite the query string
                onChange && onChange(tab);
                return;
            }
            !controlledTab && setTab(newValue);
            onChange && onChange(newValue) && event.preventDefault();
        },
        [controlledTab, onChange, tab]
    );

    return (
        <div
            className={classNames(
                classes.root,
                orientation === 'vertical' && !isMobile ? classes.vertical : classes.horizontal
            )}
        >
            {isMobile && orientation === 'vertical' ? (
                <TextField
                    className={classes.mobileTabs}
                    select
                    label={title ? <Locale path={title} /> : undefined}
                    value={tab}
                    onChange={event => handleChange(event, event.target.value)}
                >
                    {React.Children.map(children, (child, index) => {
                        const { tab, tabMenu, label } = child ? child.props || {} : {};
                        const errors = hasError(tab);
                        return tab ? (
                            <MenuItem key={tab} value={tab}>
                                <LocalizedTooltip
                                    title={errors}
                                    delimiter={`\n`}
                                    classes={classes}
                                    placement="bottom-start"
                                >
                                    <VBox>
                                        <Locale path={label} />
                                        {errors ? (
                                            <ErrorIcon fontSize="small" color="danger" />
                                        ) : null}
                                    </VBox>
                                </LocalizedTooltip>
                            </MenuItem>
                        ) : tabMenu ? (
                            <MenuItem key={index} value={tab}>
                                <LocalizedTooltip
                                    title={errors}
                                    delimiter={`\n`}
                                    classes={classes}
                                    placement="bottom-start"
                                >
                                    <VBox>
                                        {child}
                                        {errors ? (
                                            <ErrorIcon fontSize="small" color="danger" />
                                        ) : null}
                                    </VBox>
                                </LocalizedTooltip>
                            </MenuItem>
                        ) : null;
                    })}
                </TextField>
            ) : (
                <Tabs
                    {...remain}
                    className={
                        orientation === 'vertical' ? classes.verticalTabs : classes.horizontalTabs
                    }
                    color={orientation === 'vertical' ? 'transparent' : undefined}
                    elevation={orientation === 'vertical' ? 0 : undefined}
                    orientation={orientation}
                    variant={orientation === 'vertical' ? 'standard' : 'scrollable'}
                    textColor={orientation === 'vertical' ? 'secondary' : undefined}
                    value={tab}
                    onChange={handleChange}
                    scrollButtons="auto"
                >
                    {React.Children.map(children, child => {
                        const {
                            TabProps,
                            tab: panelTab,
                            tabMenu,
                            label,
                        } = child ? child.props || {} : {};
                        const errors = hasError(panelTab);
                        return panelTab ? (
                            <Tab
                                label={
                                    <LocalizedTooltip
                                        title={errors}
                                        delimiter={`\n`}
                                        classes={classes}
                                        placement="bottom-start"
                                    >
                                        <VBox>
                                            <Locale path={label} />
                                            {errors ? (
                                                <ErrorIcon fontSize="small" color="danger" />
                                            ) : null}
                                        </VBox>
                                    </LocalizedTooltip>
                                }
                                className={
                                    orientation === 'vertical' ? classes.tabVertical : classes.tab
                                }
                                value={panelTab}
                                disableRipple
                                disableFocusRipple
                                name={name}
                                {...TabProps}
                            />
                        ) : tabMenu ? (
                            child
                        ) : null;
                    })}
                </Tabs>
            )}
            <ContentWrap className={classes.panels}>
                {React.Children.map(children, child => {
                    const {
                        TabPanelProps,
                        tab: panelTab,
                        tabMenu,
                    } = child ? child.props || {} : {};
                    return panelTab ? (
                        panelTab === tab && !tabMenu ? (
                            <TabPanel
                                key={panelTab}
                                selected={tab}
                                tab={panelTab}
                                name={name}
                                className={classes.panel}
                                {...TabPanelProps}
                            >
                                {child}
                            </TabPanel>
                        ) : null
                    ) : tabMenu ? null : (
                        child
                    );
                })}
            </ContentWrap>
        </div>
    );
};

DynamicTabbed.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    ContentWrap: PropTypes.elementType,
    errorTabs: PropTypes.array,
    name: PropTypes.string,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    tab: PropTypes.string,
    initialTab: PropTypes.string,
    onChange: PropTypes.func,
    title: PropTypes.string,
};

DynamicTabbed.defaultProps = {
    ContentWrap: Box,
};

export default DynamicTabbed;
