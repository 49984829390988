import Component from './Password.component';
import { changeUserPassword } from './Password.commands';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const mapStateToProps = state => {
    const userId = fromState.Auth.user.getId(state);
    return {
        isLoading: fromState.Auth.isLoading(state),
        userId,
        details: fromState.DB.user.getEntityById(state, userId),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeUserPassword: params => dispatch(changeUserPassword(params)),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { userId, ...remain } = stateProps;
    return {
        ...ownProps,
        ...remain,
        onUpdateUserPassword: params =>
            dispatchProps.changeUserPassword({
                userId,
                ...params,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
