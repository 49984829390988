import { defaultIdAttribute, defaultProcessStrategy } from '../../../common/helpers/Normalizr';

import { filterUnique } from '/b2b/common/helpers/Array';
import { schema } from 'normalizr';

export const entityName = 'unicourtAttorneys';
export const idAttribute = 'attorney_id';

export const mergeStrategy = (entityA, entityB) => ({
    ...entityA,
    ...entityB,
    cases: [...(entityA.cases || []), ...(entityB.cases || [])].filter(filterUnique),
});
export const processStrategy = defaultProcessStrategy(idAttribute);

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
