import { types as ActionTypes } from './Translations.actions';
import { LANGUAGES } from '/b2b/consent/constants';

export const initialState = {
    locales: [...LANGUAGES],
    nextLocale: '',
    order: [],
    translations: {},
};

export const initReducer = (translations = {}) => {
    const order = Object.keys(translations);
    return {
        ...initialState,
        nextLocale: initialState.locales.find(locale => !order.includes(locale)),
        order,
        translations,
    };
};

export default function (state, action) {
    const { type, payload } = action;
    const { locales, order, translations } = state;
    switch (type) {
        case ActionTypes.add: {
            const { locale, value } = payload;
            const newOrder = [locale, ...order].filter((loc, i, l) => l.indexOf(loc) === i);
            return {
                ...state,
                nextLocale: locales.find(locale => !newOrder.includes(locale)),
                order: newOrder,
                translations: {
                    ...translations,
                    [locale]: value.replace(/\s\s+/g, ' ').trim(),
                },
            };
        }

        case ActionTypes.delete: {
            const locale = payload;
            const { nextLocale } = state;
            const newTranslations = { ...translations };
            delete newTranslations[locale];
            const newOrder = order.filter(loc => loc !== locale);
            return {
                ...state,
                nextLocale:
                    locale === nextLocale
                        ? locales.find(
                              locale => !newOrder.includes(locale) && locale !== nextLocale
                          )
                        : nextLocale,
                order: newOrder,
                translations: newTranslations,
            };
        }

        case ActionTypes.dragField: {
            const { dragIndex, hoverIndex } = payload;
            const newOrder = [...order];
            const dragged = newOrder.splice(dragIndex, 1);
            return {
                ...state,
                order: [
                    ...newOrder.slice(0, hoverIndex),
                    ...dragged,
                    ...newOrder.slice(hoverIndex),
                ],
            };
        }

        case ActionTypes.openDialog: {
            return {
                ...state,
                open: true,
            };
        }

        case ActionTypes.cancelDialog: {
            return initReducer(payload);
        }

        case ActionTypes.confirmDialog: {
            return {
                ...state,
                open: false,
            };
        }

        case ActionTypes.revert: {
            return initReducer(payload);
        }

        case ActionTypes.selectLocale: {
            return {
                ...state,
                nextLocale: payload,
            };
        }

        case ActionTypes.update: {
            const { locale, value } = payload;
            const newOrder = [locale, ...order].filter((loc, i, l) => l.indexOf(loc) === i);
            return {
                ...state,
                order: newOrder,
                translations: {
                    ...translations,
                    [locale]: value.replace(/\s\s+/g, ' ').trim(),
                },
            };
        }
    }
}
