import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@mui/material';
import React, { Fragment, useState } from 'react';

import Locale from '/b2b/common/components/Locale';
import Portlet from '/b2b/common/components/Portlet';
import PortletContent from '/b2b/common/components/PortletContent';
import PropTypes from 'prop-types';
import ResponsiveDialog from '/b2b/common/components/ResponsiveDialog';
import VerifyPhoneDialog from './components/VerifyPhoneDialog';
import VerifyTOTPDialog from './components/VerifyTOTPDialog';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import styles from './AccountSecurity.styles';
import { translate } from '/b2b/common/helpers/i18n';

const useStyles = makeStyles(styles);

const AccountSecurity = props => {
    const {
        className,
        mfaOptions,
        additionalMfaOptions,
        isLoading,
        onSetPreferredMFA,
        onRemoveMFA,
    } = props;
    const classes = useStyles(props);
    const rootClassName = classNames(classes.root, className);

    const [state, setState] = useState({
        removeType: null,
        setupType: null,
    });
    const { setupType, removeType } = state;

    const handleConfigureMfaType = mfaType => {
        setState({
            ...state,
            setupType: mfaType,
        });
    };

    const handleRemoveMfaType = mfaType => {
        setState({
            ...state,
            removeType: mfaType,
        });
    };

    const handleSetupClose = () => {
        setState({
            ...state,
            setupType: null,
        });
    };

    const handleSetupConfirm = () => {
        onSetPreferredMFA && onSetPreferredMFA({ mfaType: setupType });
        handleSetupClose();
    };

    const handleRemoveTypeClose = () => {
        setState({
            ...state,
            removeType: null,
        });
    };
    const handleRemoveTypeReject = () => {
        handleRemoveTypeClose();
    };
    const handleRemoveTypeConfirm = () => {
        onRemoveMFA && onRemoveMFA({ mfaType: removeType });
        handleRemoveTypeClose();
    };

    return (
        <Portlet className={rootClassName}>
            <PortletContent>
                <Typography className={classes.title} variant="h2">
                    <Locale path="views.Account.components.AccountSecurity.heading" />
                </Typography>
                {mfaOptions.length > 0 && (
                    <Fragment>
                        <Typography variant="h4" gutterBottom>
                            <Locale path="views.Account.components.AccountSecurity.configuredOptions" />
                        </Typography>
                        <Divider />
                        <List>
                            {mfaOptions.map(option => {
                                const {
                                    icon: IconComponent,
                                    primary,
                                    secondary,
                                    value,
                                    ...remain
                                } = option;
                                return (
                                    <ListItem key={value}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <IconComponent />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={translate(primary)}
                                            secondary={translate(secondary)}
                                            {...remain}
                                        />
                                        <ListItemSecondaryAction>
                                            <Button
                                                edge="end"
                                                aria-label={translate(
                                                    'views.Account.components.AccountSecurity.remove'
                                                )}
                                                onClick={() => handleRemoveMfaType(value)}
                                                className={classes.delete}
                                            >
                                                <Locale path="views.Account.components.AccountSecurity.remove" />
                                            </Button>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Fragment>
                )}
                {additionalMfaOptions.length > 0 && (
                    <Fragment>
                        <Typography variant="h4" gutterBottom>
                            {mfaOptions.length > 0 ? (
                                <>
                                    <Locale path="views.Account.components.AccountSecurity.additional" />{' '}
                                </>
                            ) : (
                                ''
                            )}
                            <Locale path="views.Account.components.AccountSecurity.MFAOptions" />
                        </Typography>
                        <Divider />
                        <List>
                            {additionalMfaOptions.map(option => {
                                const {
                                    icon: IconComponent,
                                    primary,
                                    secondary,
                                    value,
                                    ...remain
                                } = option;
                                return (
                                    <ListItem key={value}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <IconComponent />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={translate(primary)}
                                            secondary={translate(secondary)}
                                            {...remain}
                                        />
                                        <ListItemSecondaryAction>
                                            <Button
                                                edge="end"
                                                aria-label={translate(
                                                    'views.Account.components.AccountSecurity.configure'
                                                )}
                                                onClick={() => handleConfigureMfaType(value)}
                                                color="primary"
                                                variant="contained"
                                            >
                                                {translate(
                                                    'views.Account.components.AccountSecurity.configure'
                                                )}
                                            </Button>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Fragment>
                )}
            </PortletContent>
            <ResponsiveDialog
                title="views.Account.components.AccountSecurity.dialogs.setupAuthenticator"
                open={setupType === 'TOTP'}
                onClose={handleSetupClose}
                maxWidth="sm"
                fullWidth
            >
                <VerifyTOTPDialog onSubmit={handleSetupConfirm} />
            </ResponsiveDialog>
            <ResponsiveDialog
                title="views.Account.components.AccountSecurity.dialogs.setupSMS"
                open={setupType === 'SMS'}
                onClose={handleSetupClose}
                maxWidth="sm"
                fullWidth
            >
                <VerifyPhoneDialog onSubmit={handleSetupConfirm} />
            </ResponsiveDialog>
            <ResponsiveDialog
                title={translate(
                    'views.Account.components.AccountSecurity.dialogs.removeAuthentication.title'
                ).replace('removeType', removeType)} // TODO: Change this to use node-polyglot's interpolation
                open={!!removeType}
                onClose={handleRemoveTypeClose}
                onCancel={handleRemoveTypeReject}
                onConfirm={handleRemoveTypeConfirm}
                cancelText="common.no"
                confirmText="common.yes"
                maxWidth="sm"
                fullWidth
            >
                <Box marginBottom={2}>
                    <Locale path="views.Account.components.AccountSecurity.dialogs.removeAuthentication.body" />
                </Box>
                <Box>
                    <Locale path="views.Account.components.AccountSecurity.dialogs.removeAuthentication.footer" />
                </Box>
            </ResponsiveDialog>
            {isLoading && (
                <div className={classes.loadingWrapper}>
                    <CircularProgress />
                </div>
            )}
        </Portlet>
    );
};

AccountSecurity.propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    onSetPreferredMFA: PropTypes.func,
    onRemoveMFA: PropTypes.func,
    mfaOptions: PropTypes.array,
    additionalMfaOptions: PropTypes.array,
};

export default AccountSecurity;
