import {
    types as ActionTypes,
    asyncEvents as AsyncEvents,
} from './AuthCustomerConsentConfigs.actions';
import {
    CRUDPagingQueryReducer,
    crudPagingReducers,
    genericReducer,
} from '/b2b/common/helpers/ReduxHelpers';

import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { types as NavActionTypes } from '/b2b/common/state/nav/Nav.actions';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import schema from '/b2b/consent/state/db/ConsentConfig.schema';

export const initialState = {
    offset: 0,
    limit: 25,
    query: '',
    results: [],
    resultCount: 0,
    error: null,
    loading: false,
    planData: {},
};

export default reduceReducers(
    initialState,
    combineReducers({
        ...crudPagingReducers(
            initialState,
            Object.keys(AsyncEvents).map(key => ActionTypes[key]),
            schema
        ),
    }),
    genericReducer(initialState, {
        [NavActionTypes.routeChanged]: CRUDPagingQueryReducer(
            initialState,
            AuthenticatedPaths.CONSENT_MANAGER
        ),
    })
);
