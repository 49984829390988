import Component from './DashboardLayout.component';
import UIActions from '../../state/ui/UI.actions';
import { connect } from 'react-redux';
import fromState from '../../state/selectors';
import { refresh } from './DashboardLayout.commands';

const mapStateToProps = state => {
    const customerId = fromState.Auth.getCustomerId(state);
    const userId = fromState.Auth.getEmail(state);
    const { roles: customerRoles = [] } = fromState.DB.customer.getEntityById(state, customerId);
    const { roles: userRoles = [] } = fromState.DB.user.getEntityById(state, userId);

    const permissionsDetermined = customerRoles.length > 0 && userRoles.length > 0;

    return {
        customerId,
        userId,
        drawerOpen: fromState.UI.isDrawerOpen(state),
        blackout: fromState.UI.isBlackout(state),
        permissionsDetermined,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        closeDrawer: () => dispatch(UIActions.closeDrawer()),
        openDrawer: () => dispatch(UIActions.openDrawer()),
        refresh: () => dispatch(refresh()),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...ownProps,
        ...stateProps,
        onAuthChange: dispatchProps.refresh,
        onCloseDrawer: dispatchProps.closeDrawer,
        onToggleDrawer: stateProps.drawerOpen
            ? dispatchProps.closeDrawer
            : dispatchProps.openDrawer,
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
