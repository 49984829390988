export default theme => ({
    root: {},
    blackout: {
        zIndex: theme.zIndex.blackout,
    },
    topbar: {
        position: 'fixed',
        width: '100%',
        top: 0,
        left: 0,
        right: 'auto',
        zIndex: theme.zIndex.appBar,
        transition: theme.transitions.create(['width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    footer: {
        flex: '0 0 auto',
        width: '100%',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: 0,
        position: 'relative',
    },
    topbarShift: {
        marginLeft: theme.width.drawer,
        width: `calc(100vw - ${theme.width.drawer})`,
    },
    topbarShiftMini: {
        marginLeft: theme.width.drawerMini,
        width: `calc(100vw - ${theme.width.drawerMini})`,
    },
    drawerRoot: {
        zIndex: theme.zIndex.modal,
    },
    drawerPaper: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: theme.width.drawer,
        backgroundColor: theme.palette.tertiary.main,
        color: theme.palette.common.white,
        '&.minified': {
            width: theme.width.drawerMini,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            backgroundColor: theme.palette.tertiary.main,
        },
    },
    sidebar: {
        width: theme.width.drawer,
        '&.minified': {
            width: theme.width.drawerMini,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingTop: theme.height.topBar,
        zIndex: 0,
        position: 'relative',
        backgroundColor: theme.palette.background.default,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    panel: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        position: 'relative',
    },
    contentShift: {
        marginLeft: theme.width.drawer,
    },
    contentShiftMini: {
        marginLeft: theme.width.drawerMini,
    },
    logoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '67px',
        flexShrink: 0,
        textAlign: 'center',
        '&.minified': {
            flexDirection: 'column-reverse',
            height: 'auto',
        },
    },
    logoLink: {
        fontSize: 0,
        flex: '1 1 auto',
        margin: theme.spacing(1, 2),
    },
    logo: {
        cursor: 'pointer',
        height: 'auto',
        width: '150px',
        '& .logo .filled': {
            fill: theme.palette.common.white,
        },
    },
    logoSquare: {
        cursor: 'pointer',
        height: 'auto',
        width: '33px',
        '& .logo': {
            fill: theme.palette.common.white,
        },
    },
    logoDivider: {
        marginBottom: theme.spacing(2),
    },
    openDrawerToggle: {
        color: theme.palette.common.white,
    },
    closeDrawerToggle: {
        color: theme.palette.common.white,
        transform: 'scaleX(-1)',
    },
});
