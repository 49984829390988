/* eslint-disable camelcase */
import {
    deepMergeStrategy,
    defaultIdAttribute,
    defaultProcessStrategy,
} from '../../../helpers/Normalizr';

import { getInitials } from '../../../helpers/String';
import { idAttribute as organizationIdAttribute } from '/b2b/organizations/state/db/Organization.schema';
import { schema } from 'normalizr';

export const entityName = 'customers';
export const idAttribute = 'customerId';

export const mergeStrategy = (entityA, entityB) => {
    if (entityB?.followedJurisdictions) {
        // Followed regions should not be deep merged
        entityA.followedJurisdictions = entityB.followedJurisdictions;
    }
    if (entityB?.permissions) {
        // Permissions should not be deep merged
        entityA.permissions = entityB.permissions;
    }
    return deepMergeStrategy(idAttribute)(entityA, entityB);
};
export const processStrategy = defaultProcessStrategy(idAttribute, (details, parent, parentKey) => {
    let { name, customerName, customer_name, ...remain } = details;
    const customer = {
        ...remain,
    };
    name = customerName || customer_name || name;
    if (name) {
        customer.name = name;
        customer.initials = getInitials(name);
    }
    if (parent) {
        if (typeof parentKey === 'string' && parentKey.indexOf('customer') !== -1) {
            // Has a parent that is another entity
            if (parent[`${organizationIdAttribute}`]) {
                // Parent is an Organization
                customer.isRootOrgMember =
                    customer.isRootOrgMember || parent.isRoot === true || undefined;
                customer.organizations = [
                    ...(customer.organizations || []),
                    parent[`${organizationIdAttribute}`],
                ].filter((v, i, a) => a.indexOf(v) === i);
            }
        }
    }
    return customer;
});

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
