import { DOMAIN } from './Locale.constants';
import get from 'lodash/get';

export const getStore = state => get(state, DOMAIN) || {};

export const isLoading = state => getStore(state).loading;

export const getLocaleCode = state => getStore(state).code;
export const getReloadIncrement = state => getStore(state).reloadIncrement;

export const shouldRetranslate = state =>
    `${getLocaleCode(state)}-${get(getReloadIncrement(state), getLocaleCode(state), 0)}`;
