/* eslint-disable */
import {
    types as ActionTypes,
    asyncEvents as AsyncEvents,
    contextEvents as ContextEvents,
} from './Auth.actions';
import customer, { initialState as initialCustomer } from './customer/AuthCustomer.reducer';
import {
    genericReducer,
    loadingReducer,
    passthroughReducer,
} from '../../common/helpers/ReduxHelpers';
import user, { initialState as initialUser } from './user/AuthUser.reducer';

import { AUTH_STATES } from '../constants';
import { types as NavActionTypes } from '../../common/state/nav/Nav.actions';
import { combineReducers } from 'redux';
import { filterUnique } from '/b2b/common/helpers/Array';
import reduceReducers from 'reduce-reducers';

export const initialState = {
    customer: initialCustomer,
    user: initialUser,
    authState: AUTH_STATES.signedOut,
    error: null,
    mfaType: '',
    mfaOptions: [],
    cognitoUser: null,
    loading: false,
    fetchingContext: false,
    resentCode: null,
    customerToken: null,
    rememberMe: false,
};

export const defaultError = 'An error occurred during authorization';

export default reduceReducers(
    initialState,
    combineReducers({
        user,
        customer,
        customerToken: genericReducer(initialState.customerToken, {
            [ActionTypes.signInSuccess]: () => initialState.customerToken,
        }),
        cognitoUser: genericReducer(initialState.cognitoUser, {
            [ActionTypes.signInSuccess]: (state, { response: { cognitoUser = {} } = {} } = {}) => {
                const {
                    challengeName = '',
                    challengeParam = state ? state.challengeParam : '',
                    username,
                    authenticationFlowType,
                    preferredMFA,
                    UserMFASettingList,
                } = cognitoUser;
                return {
                    username,
                    challengeName,
                    challengeParam,
                    authenticationFlowType,
                    preferredMFA,
                    UserMFASettingList: [...(UserMFASettingList || [])],
                };
            },
            [ActionTypes.setPreferredMfaSuccess]: (state, { response: { cognitoUser } = {} }) => {
                const {
                    challengeName = '',
                    challengeParam = state ? state.challengeParam : '',
                    username,
                    authenticationFlowType,
                    preferredMFA,
                    UserMFASettingList,
                } = cognitoUser || {};
                return cognitoUser
                    ? {
                          username,
                          challengeName,
                          challengeParam,
                          authenticationFlowType,
                          preferredMFA,
                          UserMFASettingList: [...(UserMFASettingList || [])],
                      }
                    : state;
            },
            [ActionTypes.completeNewPasswordSuccess]: (state, { response: cognitoUser } = {}) => {
                const {
                    challengeName = '',
                    challengeParam = state ? state.challengeParam : '',
                    username,
                    authenticationFlowType,
                    preferredMFA,
                    UserMFASettingList,
                } = cognitoUser || {};
                return cognitoUser
                    ? {
                          username,
                          challengeName,
                          challengeParam,
                          authenticationFlowType,
                          preferredMFA,
                          UserMFASettingList: [...(UserMFASettingList || [])],
                      }
                    : state;
            },
        }),
        fetchingContext: loadingReducer(
            initialState.fetchingContext,
            Object.keys(ContextEvents).map(key => ActionTypes[key])
        ),
        mfaOptions: genericReducer(initialState.mfaOptions, {
            [ActionTypes.getMfaOptionsSuccess]: (state, mfaOptions) => mfaOptions,
            [ActionTypes.removeMfa]: (
                state,
                { params: { mfaType: providedMFA } = {}, cognitoUser = {} }
            ) => {
                const { UserMFASettingList } = cognitoUser;
                if (UserMFASettingList) {
                    // If it's available, use it
                    return UserMFASettingList;
                }
                switch (providedMFA) {
                    case 'SMS':
                    case 'SMS_MFA': {
                        return [...state].filter(value => value !== 'SMS_MFA');
                    }
                    case 'TOTP':
                    case 'SOFTWARE_TOKEN_MFA': {
                        return [...state].filter(value => value !== 'SOFTWARE_TOKEN_MFA');
                    }
                }
                return state;
            },
            [ActionTypes.setPreferredMfaSuccess]: (
                state,
                { params: { mfaType: providedMFA } = {}, response: { cognitoUser = {} } = {} }
            ) => {
                const { UserMFASettingList } = cognitoUser;
                if (UserMFASettingList) {
                    // If it's available, use it
                    return UserMFASettingList;
                }
                switch (providedMFA) {
                    case 'SMS':
                    case 'SMS_MFA': {
                        return ['SMS_MFA', ...state].filter(filterUnique);
                    }
                    case 'TOTP':
                    case 'SOFTWARE_TOKEN_MFA': {
                        return ['SOFTWARE_TOKEN_MFA', ...state].filter(filterUnique);
                    }
                    case 'NOMFA': {
                        return [];
                    }
                }
                return state;
            },
            [ActionTypes.signInSuccess]: (state, { response: { cognitoUser } = {} }) => {
                const { preferredMFA = 'NOMFA', UserMFASettingList } = cognitoUser || {};
                const { challengeParam = {}, challengeName: mfaType = preferredMFA } =
                    cognitoUser || {};
                if (UserMFASettingList) {
                    // If it's available, use it
                    return UserMFASettingList;
                }
                switch (mfaType) {
                    case 'SMS':
                    case 'SMS_MFA': {
                        return ['SMS_MFA'];
                    }
                    case 'TOTP':
                    case 'SOFTWARE_TOKEN_MFA': {
                        return ['SOFTWARE_TOKEN_MFA'];
                    }
                    case 'SELECT_MFA_TYPE': {
                        // Multiple MFA methods are defined, select the one to use
                        const {
                            MFAS_CAN_CHOOSE = '[]', // "["SMS_MFA","SOFTWARE_TOKEN_MFA"]"
                        } = challengeParam;
                        try {
                            return JSON.parse(MFAS_CAN_CHOOSE);
                        } catch (errpr) {
                            return state;
                        }
                    }
                    case 'NOMFA': {
                        // No MFA defined, enforce MFA selection
                        return ['SMS_MFA', 'SOFTWARE_TOKEN_MFA'];
                    }
                }
                return state;
            },
        }),
        mfaType: genericReducer(initialState.mfaType, {
            [ActionTypes.selectMfa]: (state, { mfaType }) => {
                switch (mfaType) {
                    case 'SMS':
                    case 'SMS_MFA': {
                        return 'SMS';
                    }
                    case 'TOTP':
                    case 'SOFTWARE_TOKEN_MFA': {
                        return 'TOTP';
                    }
                    case 'SELECT_MFA_TYPE': {
                        return 'SELECT_MFA_TYPE';
                    }
                }
                return 'NOMFA';
            },
            [ActionTypes.signInSuccess]: (state, { response: { cognitoUser } = {} }) => {
                const { preferredMFA = 'NOMFA' } = cognitoUser || {};
                const { challengeName: mfaType = preferredMFA } = cognitoUser || {};

                switch (mfaType) {
                    case 'SMS_MFA': {
                        return 'SMS';
                    }
                    case 'SOFTWARE_TOKEN_MFA': {
                        return 'TOTP';
                    }
                    case 'SELECT_MFA_TYPE': {
                        return 'SELECT_MFA_TYPE';
                    }
                }
                return 'NOMFA';
            },
            [ActionTypes.setPreferredMfaSuccess]: (
                state,
                { params: { mfaType: providedMFA } = {}, response: { cognitoUser } = {} }
            ) => {
                const { preferredMFA = providedMFA } = cognitoUser || {};
                const { challengeName: mfaType = preferredMFA } = cognitoUser || {};

                switch (mfaType) {
                    case 'SMS':
                    case 'SMS_MFA': {
                        return 'SMS';
                    }
                    case 'TOTP':
                    case 'SOFTWARE_TOKEN_MFA': {
                        return 'TOTP';
                    }
                }
                return 'NOMFA';
            },
        }),
        rememberMe: passthroughReducer(initialState.rememberMe),
        resentCode: genericReducer(initialState.resentCode, {
            [ActionTypes.signInSuccess]: () => null,
            [ActionTypes.confirmSignInSuccess]: () => null,
            [ActionTypes.resendSignUpCodeBegin]: () => null,
            [ActionTypes.resendSignUpCodeSuccess]: () => true,
            [ActionTypes.resendSignUpCodeFailure]: (
                state,
                { error: { message = defaultError } = {} }
            ) => message,
            [ActionTypes.resendEmailVerificationCodeBegin]: () => null,
            [ActionTypes.resendEmailVerificationCodeSuccess]: () => true,
            [ActionTypes.resendEmailVerificationCodeFailure]: (
                state,
                { error: { message = defaultError } = {} }
            ) => message,
            [ActionTypes.resendPhoneVerificationCodeBegin]: () => null,
            [ActionTypes.resendPhoneVerificationCodeSuccess]: () => true,
            [ActionTypes.resendPhoneVerificationCodeFailure]: (
                state,
                { error: { message = defaultError } = {} }
            ) => message,
        }),
        authState: genericReducer(initialState.authState, {
            [ActionTypes.updateAuthState]: (state, newAuthState) =>
                Object.values(AUTH_STATES).includes(newAuthState) ? newAuthState : state,

            [ActionTypes.confirmSignInSuccess]: (
                state,
                { response: { signInUserSession = {} } = {} }
            ) => {
                if (signInUserSession !== null) {
                    return AUTH_STATES.signedIn;
                }
            },
            [ActionTypes.setPreferredMfaSuccess]: () => AUTH_STATES.signedIn,
            [ActionTypes.selectMfa]: (
                state,
                { challengeName, isTOTPEnabled, isSMSEnabled, isPhoneVerified, mfaType }
            ) => {
                if (challengeName === 'SMS_MFA' || challengeName === 'SOFTWARE_TOKEN_MFA') {
                    return AUTH_STATES.confirmSignIn;
                }
                if (challengeName === 'SELECT_MFA_TYPE') {
                    // Multiple MFA methods are defined, the selcted one should be used
                    return AUTH_STATES.confirmSignIn;
                }
                // Determine if we need more verifications
                switch (mfaType) {
                    case 'SMS':
                    case 'SMS_MFA': {
                        // We need to see if the user has entered a verified phone number
                        if (isPhoneVerified || isSMSEnabled) {
                            // This seems counterintuative, but we need to re-verify
                            return AUTH_STATES.verifyPhone;
                        }
                        return AUTH_STATES.setupPhone;
                    }
                    case 'TOTP':
                    case 'SOFTWARE_TOKEN_MFA': {
                        if (isTOTPEnabled) {
                            // TODO: Don't force a user to scan a new code
                            return AUTH_STATES.verifyTOTP;
                        }
                        return AUTH_STATES.setupTOTP;
                    }
                    case 'NOMFA': {
                        return AUTH_STATES.selectMFA;
                    }
                }
                return state;
            },
            [ActionTypes.confirmSignUpSuccess]: () => AUTH_STATES.selectMFA,
            [ActionTypes.verifyEmailSuccess]: () => {
                return AUTH_STATES.signedIn;
            },
            [ActionTypes.verifyPhoneSuccess]: () => {
                return AUTH_STATES.signedIn;
            },
            [ActionTypes.resetPasswordFailure]: () => AUTH_STATES.forgotPassword,
            [ActionTypes.forgotPasswordSuccess]: () => AUTH_STATES.forgotPassword,
            [ActionTypes.completeNewPasswordFailure]: () => AUTH_STATES.requireNewPassword,
            [ActionTypes.completeNewPasswordSuccess]: (state, { response: cognitoUser } = {}) => {
                const { challengeName = '', preferredMFA = 'NOMFA' } = cognitoUser || {};
                if (challengeName === 'SMS_MFA' || challengeName === 'SOFTWARE_TOKEN_MFA') {
                    return AUTH_STATES.confirmSignIn;
                } else if (challengeName === 'SELECT_MFA_TYPE') {
                    // Multiple MFA methods are defined, select the one to use
                    /*
                    challengeParam = {
                        CODE_DELIVERY_DELIVERY_MEDIUM, // "SMS" or "TOTP"
                        CODE_DELIVERY_DESTINATION, // "+*******8731"
                        FRIENDLY_DEVICE_NAME,
                        MFAS_CAN_CHOOSE, // "["SMS_MFA","SOFTWARE_TOKEN_MFA"]"
                    }
                    */
                    return AUTH_STATES.selectMFA;
                } else if (!preferredMFA || preferredMFA === 'NOMFA') {
                    // No MFA defined, enforce MFA selection
                    return AUTH_STATES.selectMFA;
                } else if (challengeName === 'MFA_SETUP') {
                    return AUTH_STATES.setupTotp;
                }
                return AUTH_STATES.signedIn;
            },
            [ActionTypes.signInFailure]: (state, { error }) => {
                if (error.code === 'UserNotConfirmedException') {
                    // The error happens if the user didn't finish the confirmation step when signing up
                    // In this case you need to resend the code and confirm the user
                    // About how to resend the code and confirm the user, please check the signUp part

                    // TODO: Check email or phone
                    return AUTH_STATES.confirmEmail;
                } else if (error.code === 'PasswordResetRequiredException') {
                    // The error happens when the password is reset in the Cognito console
                    // In this case you need to call forgotPassword to reset the password
                    // Please check the Forgot Password part.
                    return AUTH_STATES.forgotPassword;
                } else if (error.code === 'NotAuthorizedException') {
                    // The error happens when the incorrect password is provided, or a temporary password expired
                    if (error.message.indexOf('Temporary password has expired') >= 0) {
                        // Resend the password
                        return AUTH_STATES.resendInvitation;
                    }
                    return AUTH_STATES.signedOut;
                } else if (error.code === 'UserNotFoundException') {
                    // The error happens when the supplied username/email does not exist in the Cognito user pool
                    return AUTH_STATES.signedOut;
                }
                return AUTH_STATES.signedOut;
            },
            [ActionTypes.signInSuccess]: (
                state,
                { response: { details: { identities } = {}, cognitoUser } = {} }
            ) => {
                const {
                    challengeName = '',
                    preferredMFA = 'NOMFA',
                    signInUserSession,
                } = cognitoUser || {};
                if (challengeName === 'SMS_MFA' || challengeName === 'SOFTWARE_TOKEN_MFA') {
                    return AUTH_STATES.confirmSignIn;
                } else if (challengeName === 'NEW_PASSWORD_REQUIRED') {
                    return AUTH_STATES.requireNewPassword;
                } else if (challengeName === 'SELECT_MFA_TYPE') {
                    // Multiple MFA methods are defined, select the one to use
                    /*
                    challengeParam = {
                        CODE_DELIVERY_DELIVERY_MEDIUM, // "SMS" or "TOTP"
                        CODE_DELIVERY_DESTINATION, // "+*******8731"
                        FRIENDLY_DEVICE_NAME,
                        MFAS_CAN_CHOOSE, // "["SMS_MFA","SOFTWARE_TOKEN_MFA"]"
                    }
                    */
                    return AUTH_STATES.selectMFA;
                } else if (!signInUserSession && (!preferredMFA || preferredMFA === 'NOMFA')) {
                    // No MFA defined, if federated identities then sign in,
                    // otherwise enforce MFA selection
                    return identities ? AUTH_STATES.signedIn : AUTH_STATES.selectMFA;
                } else if (challengeName === 'MFA_SETUP') {
                    return AUTH_STATES.setupTotp;
                }
                return AUTH_STATES.signedIn;
            },
            [ActionTypes.signUpFailure]: () => AUTH_STATES.signedOut,
            [ActionTypes.signUpSuccess]: (state, { response: { userConfirmed } = {} }) => {
                if (!userConfirmed) {
                    // TODO: Figure out verification for email or phone
                    return AUTH_STATES.confirmEmail;
                }
                return AUTH_STATES.signedIn;
            },
        }),
        error: genericReducer(initialState.error, {
            [ActionTypes.signInFailure]: (state, { error: { message = defaultError } = {} }) =>
                message,
            [ActionTypes.signInSuccess]: () => null,
            [ActionTypes.signUpFailure]: (state, { error: { message = defaultError } = {} }) =>
                message,
            [ActionTypes.signUpSuccess]: () => null,
            [ActionTypes.confirmSignInFailure]: (
                state,
                { error: { message = defaultError } = {} }
            ) => message,
            [ActionTypes.confirmSignInSuccess]: () => null,
            [ActionTypes.confirmEmailFailure]: (
                state,
                { error: { message = defaultError } = {} }
            ) => message,
            [ActionTypes.confirmPhoneFailure]: (
                state,
                { error: { message = defaultError } = {} }
            ) => message,
            [ActionTypes.confirmEmailSuccess]: () => null,
            [ActionTypes.confirmPhoneSuccess]: () => null,
            [ActionTypes.resetPasswordFailure]: (
                state,
                { error: { message = defaultError } = {} }
            ) => message,
            [ActionTypes.resetPasswordSuccess]: () => null,
            [ActionTypes.completeNewPasswordFailure]: (
                state,
                { error: { message = defaultError } = {} }
            ) => message,
            [ActionTypes.completeNewPasswordSuccess]: () => null,
            [ActionTypes.forgotPasswordFailure]: (
                state,
                { error: { message = defaultError } = {} }
            ) => message,
            [ActionTypes.forgotPasswordSuccess]: () => null,
        }),
        loading: loadingReducer(
            true,
            Object.keys(AsyncEvents).map(key => ActionTypes[key])
        ),
    }),
    genericReducer(initialState, {
        [ActionTypes.logout]: state => {
            const { customer, rememberMe, user } = state;
            return {
                ...initialState,
                customer: {
                    ...initialState.customer,
                    id: rememberMe ? customer?.id : initialState.customer.id,
                },
                rememberMe,
                user: {
                    ...initialState.user,
                    id: rememberMe ? user?.id : initialState.user.id,
                },
            };
        },
        [NavActionTypes.changeCustomer]: (state, customer = {}) => {
            const { token } = customer;
            return {
                ...state,
                // role: lookupUserRole(role)[0],
                customerToken: token || null,
            };
        },
        [ActionTypes.signInSuccess]: (
            state,
            { params: { rememberMe = state.rememberMe } = {} }
        ) => {
            return {
                ...state,
                rememberMe: Boolean(rememberMe),
            };
        },
    })
);
