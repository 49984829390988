import Actions from '../state/Dashboard.actions';
import { fetchDashboard as fetchDashboardService } from '../services';

export const fetchDashboard =
    (params = {}) =>
    dispatch => {
        dispatch(Actions.fetchDetailsBegin(params));

        return fetchDashboardService(params)
            .then(response => {
                return dispatch(Actions.fetchDetailsSuccess(params, response));
            })
            .catch(error => {
                const { message } = error;
                dispatch(Actions.fetchDetailsFailure(params, message));
                //throw message;
            });
    };
