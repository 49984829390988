import { body, hide, print } from './constants';

import { v4 as uuid } from 'uuid';

// These classes are internally used by the print processor and are unique so as not to interfere with public classNames
const rand = uuid();
const ancestor = `o-print-ancestor-${rand}`;
const internalHide = `${hide}-${rand}`;
const internalPrint = `${print}-${rand}`;

const hideElement = el => {
    if (!el.classList.contains(internalPrint)) {
        el.classList.add(internalHide);
    }
};

const preserveElement = (el, rootEl) => {
    el.classList.remove(internalHide);
    el.classList.add(internalPrint);
    if (!rootEl) {
        el.classList.add(ancestor);
    }
};

const cleanElement = el => {
    [ancestor, internalHide, internalPrint].forEach(className => el.classList.remove(className));
};

const domWalkSiblings = (el, callback) => {
    let sibling = el.previousElementSibling;
    while (sibling) {
        callback(sibling);
        sibling = sibling.previousElementSibling;
    }
    sibling = el.nextElementSibling;
    while (sibling) {
        callback(sibling);
        sibling = sibling.nextElementSibling;
    }
};

const attachPrintClasses = (el, rootEl) => {
    preserveElement(el, rootEl);
    domWalkSiblings(el, hideElement);
};

const cleanup = el => {
    cleanElement(el);
    domWalkSiblings(el, cleanElement);
};

const domWalkTree = callback => element => {
    let el = element;
    callback(el, true);
    el = el.parentElement;
    while (el && el.nodeName !== 'BODY') {
        callback(el, false);
        el = el.parentElement;
    }
};

/**
 * Prints the current page elements targeted by the provided target and selector
 *
 * @param {HTMLElement|string} [targetOrSelector] The target node to print items inside of. Defaults to `document.body`.
 * @param {string} [selector] The selector to use for items you wish to print.  Defaults to `.o-print-wrapper`.
 */
export function selectivePrint(targetOrSelector, selector) {
    selector = typeof targetOrSelector === 'string' ? targetOrSelector : selector;
    let target = typeof targetOrSelector === 'string' ? document.body : targetOrSelector;

    // Check if it's undefined or an Event from a listener
    if (!target || (typeof target === 'object' && typeof target.preventDefault === 'function')) {
        target = document.body;
        // In this instance, we should default the selector to be everything with the public `print` className
        selector = selector || `.${print}`;
    }

    // Convert `target` into an array of HTMLElements
    if (target instanceof NodeList) {
        target = Array.from(target);
    } else if (target instanceof HTMLElement) {
        target = [target];
    } else if (!Array.isArray(target)) {
        throw new TypeError(
            'print(): `target` must be a NodeList or Array containing at least one Node, or a single HTMLElement'
        );
    }

    // Filter any non-HTMLElements from the array
    target = target.filter(node => node instanceof HTMLElement);
    if (target.length === 0) {
        throw new TypeError(
            'print(): `target` must be a NodeList or Array containing at least one Node, or a single HTMLElement'
        );
    }

    let els = target;
    // If selector is not provided, we only print the `target`(s)
    if (selector) {
        if (typeof selector !== 'string') {
            throw new TypeError('print(): `selector` must be a string');
        }
        // Use the selector on each provided target
        els = target.reduce(
            (nodes, node) => nodes.concat(Array.from(node.querySelectorAll(selector))),
            []
        );
    }

    document.body.classList.add(body);
    els.forEach(domWalkTree(attachPrintClasses));
    window.print();
    els.forEach(domWalkTree(cleanup));
    document.body.classList.remove(body);
}

export default selectivePrint;
