export default {
    email: {
        label: 'Email Address',
        type: 'email',
        presence: { allowEmpty: false },
        email: true,
        length: {
            maximum: 128,
        },
        props: {
            autoFocus: true,
        },
    },
};
