/* cSpell:disable */
import {
    countryCodeLookup,
    countryLookup,
    postalCodeRegExp,
    territoryLabelForCountry,
    territoryOptionsForCountry,
} from './Address';

import { getTranslatedRoleLabel } from './Roles';
import merge from 'lodash/merge';
import startCase from 'lodash/startCase';

const countries = Object.values(countryLookup).reduce(
    (countries, { twoLetter, name }) => (
        (countries[twoLetter] = { value: twoLetter, label: name }), countries
    ),
    { '': '' }
);

export const evaluateConstraints = (schema, values) => {
    return Object.entries(schema).reduce((constraints, [field, constraint]) => {
        // We only need to evaluate functional constraints
        if (typeof constraint === 'function') {
            constraints[field] = constraint(values[field], values, field);
        } else {
            constraints[field] = constraint;
        }
        return constraints;
    }, {});
};

export const mapOptions =
    (valueProp = 'id', labelProp = 'name', formatter) =>
    ({ [valueProp]: id, value, [labelProp]: name, label, ...remain } = {}) => ({
        ...remain,
        label: formatter ? formatter(label || name) : label || name,
        value: value || id,
    });

export const shouldShowOrganizationColumn = (results = []) =>
    results.some(
        ({ organizations = [], isRootOrgMember } = {}) =>
            organizations.length > (isRootOrgMember ? 1 : 0)
    );

export const createSchema = (fields = {}, options) => {
    const { country = 'US' } = options || {};
    const countryCode = countryCodeLookup(country);
    const postalFormat = postalCodeRegExp[countryCode];

    return Object.entries(fields).reduce((schema, [field, value]) => {
        if (typeof value === 'function') {
            // We need to never override functional validators
            schema[field] = value;

            return schema;
        }

        switch (field.toLowerCase()) {
            case 'firstname':
            case 'middlename':
            case 'lastname': {
                schema[field] = {
                    length: {
                        maximum: 32,
                    },
                };
                break;
            }

            case 'date': {
                schema[field] = {
                    type: 'date',
                };
                break;
            }

            case 'dateofbirth': {
                schema[field] = {
                    type: 'date',
                    props: {
                        DatePickerProps: {
                            clearable: true,
                            disableFuture: true,
                            openTo: 'year',
                            views: ['year', 'month', 'day'],
                        },
                        InputLabelProps: {
                            shrink: true,
                        },
                    },
                };
                break;
            }

            case 'policy': {
                schema[field] = {
                    presence: { allowEmpty: false },
                    checked: true,
                    type: 'hidden',
                };
                break;
            }

            case 'subject': {
                schema[field] = {
                    type: 'text',
                    length: {
                        maximum: 50,
                    },
                    presence: { allowEmpty: false },
                };
                break;
            }

            case 'description':
            case 'text': {
                schema[field] = {
                    presence: { allowEmpty: false },
                    props: {
                        multiline: true,
                        minRows: '2',
                        maxRows: '5',
                    },
                };
                break;
            }

            case 'username':
            case 'email': {
                schema[field] = {
                    type: 'email',
                    presence: { allowEmpty: false },
                    email: true,
                    length: {
                        maximum: 128,
                    },
                };
                break;
            }

            case 'passwords':
            case 'confirmnewpassword':
            case 'newpassword':
            case 'password': {
                schema[field] = {
                    password: true,
                    presence: { allowEmpty: false },
                    type: 'password',
                    length: {
                        maximum: 128,
                    },
                };
                break;
            }

            case 'code': {
                schema[field] = {
                    presence: { allowEmpty: false },
                    length: {
                        maximum: 64,
                    },
                };
                break;
            }

            case 'phone': {
                schema[field] = {
                    presence: { allowEmpty: false },
                    type: 'phone',
                    phone: {
                        country: countryCode,
                    },
                    props: {
                        InputLabelProps: {
                            shrink: true,
                        },
                    },
                };
                break;
            }

            case 'organizations': {
                schema[field] = {
                    label: 'common.fields.organizations.label',
                    type: options?.organizations?.isHidden ? 'hidden' : 'autocomplete',
                    props: {
                        InputLabelProps: {
                            shrink: true,
                        },
                        inputProps: {
                            autoComplete: 'organizations',
                        },
                        multiple: true,
                        placeholder: 'common.fields.organizations.placeholder',
                        options: options?.organizations?.options
                            ?.filter(({ isRoot }) => !isRoot)
                            .map(mapOptions('orgId', 'name', startCase)),
                    },
                };
                break;
            }

            case 'roles': {
                schema[field] = {
                    label: 'common.fields.roles.label',
                    presence: { allowEmpty: false },
                    type: options?.roles?.isHidden ? 'hidden' : 'autocomplete',
                    props: {
                        required: true,
                        InputLabelProps: {
                            shrink: true,
                        },
                        inputProps: {
                            autoComplete: 'roles',
                        },
                        multiple: true,
                        placeholder: 'common.fields.roles.placeholder',
                        getOptionLabel: getTranslatedRoleLabel,
                        // eslint-disable-next-line no-unused-vars
                        options: options?.roles?.options?.map(({ role, description, ...rest }) => ({
                            ...rest,
                            label: getTranslatedRoleLabel({ value: role }),
                            value: role,
                        })),
                    },
                };
                break;
            }

            case 'state':
            case 'region':
            case 'territory': {
                schema[field] = {
                    label: territoryLabelForCountry(country),
                    type: 'select',
                    inclusion: {
                        within: { '': '', ...territoryOptionsForCountry(country) },
                        message: `%{value} is not a valid ${territoryLabelForCountry(country)}`,
                    },
                };
                break;
            }

            case 'postal_code': {
                schema[field] = {
                    format: postalFormat,
                };
                break;
            }

            case 'country': {
                schema[field] = {
                    type: 'select',
                    inclusion: {
                        within: countries,
                        message: `%{value} is not a valid country.`,
                    },
                };
                break;
            }

            case 'domain': {
                schema[field] = {
                    domain: { required: true },
                    mask: value => {
                        try {
                            return new URL(value).hostname;
                        } catch (e) {
                            try {
                                return new URL(`http://${value}`).hostname;
                            } catch (e) {
                                return value;
                            }
                        }
                    },
                };
                break;
            }

            default: {
                schema[field] = {};
            }
        }

        const props = typeof value === 'object' ? value : {};
        schema[field] = merge(schema[field], props);

        return schema;
    }, {});
};
