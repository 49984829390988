import Component from './DatePicker.component';
import { connect } from 'react-redux';
import fromState from '../../state/selectors';

const mapStateToProps = state => ({
    // Since Dates are formatted rather than translated, we only need to re-render on locale change
    localeCode: fromState.Locale.getLocaleCode(state),
});

export default connect(mapStateToProps)(Component);
