export default {
    styleOverrides: {
        root: {
            '&.MuiModal-root': {
                marginTop: 0,
            },
        },
        paper: {
            flex: '1 1 auto',
        },
    },
};
