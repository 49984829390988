import {
    Avatar,
    Badge,
    Button,
    ClickAwayListener,
    Divider,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Popper,
    Toolbar,
    Tooltip,
    useMediaQuery,
} from '@mui/material';
import React, { forwardRef, useRef, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { LOCALES } from '/b2b/common/helpers/i18n';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationList from './components/NotificationList';
import NotificationStatus from '/b2b/notifications/components/NotificationStatus.container';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import Permission from '/b2b/common/components/Permission';
import PropTypes from 'prop-types';
import ResourceCenter from '/b2b/common/components/ResourceCenter';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import UserDetails from './components/UserDetails';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { matchPath } from 'react-router-dom';
import noop from 'lodash/noop';
import styles from './Topbar.styles';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(styles);

const Topbar = forwardRef((props, topBarRef) => {
    const theme = useTheme();
    const hideDrawerIcon = useMediaQuery(theme.breakpoints.up('lg'));
    const {
        className,
        currentCustomerId,
        currentLocale,
        customers = [],
        customerDetails,
        notificationCount,
        urgentNotifications,
        notificationsLimit,
        notificationsOpen,
        onCloseNotifications = noop,
        onCloseUserDetails = noop,
        onOpenNotifications = noop,
        onOpenUserDetails = noop,
        onChangeCustomer = noop,
        onChangeLocale = noop,
        onToggleSidebar,
        userDetailsOpen,
    } = props;
    const classes = useStyles(props);
    const location = useLocation();
    const { name: customerName = '', photo: customerPhoto = '' } = customerDetails;
    const rootClassName = classNames(classes.root, className);
    const onNotificationPage = matchPath(location.pathname, {
        path: AuthenticatedPaths.NOTIFICATIONS,
    });
    const onAccountPage = matchPath(location.pathname, { path: AuthenticatedPaths.ACCOUNT });
    const [detailsAnchorEl, setDetailsAnchorEl] = useState(null);
    const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
    const handleShowNotifications = event => {
        setNotificationAnchorEl(event.currentTarget);
        onOpenNotifications();
    };
    const handleCloseNotifications = () => {
        onCloseNotifications();
    };
    const handleShowUserDetails = event => {
        setDetailsAnchorEl(event.currentTarget);
        onOpenUserDetails();
    };
    const handleCloseUserDetails = () => {
        onCloseUserDetails();
    };

    const [customerOpen, setCustomerOpen] = useState(false);
    const customerAnchorRef = useRef(null);

    const [localeOpen, setLocaleOpen] = useState(false);
    const localeAnchorRef = useRef(null);

    function handleCloseCustomer(event) {
        if (customerAnchorRef.current && customerAnchorRef.current.contains(event.target)) {
            return;
        }
        setCustomerOpen(false);
    }

    function handleCloseLocale(event) {
        if (localeAnchorRef.current && localeAnchorRef.current.contains(event.target)) {
            return;
        }
        setLocaleOpen(false);
    }

    function handleCustomerClick(customerId) {
        setCustomerOpen(false);
        onChangeCustomer(customerId);
    }

    function handleLocaleClick(localeCode) {
        setLocaleOpen(false);
        onChangeLocale(localeCode);
        // Some browsers don't support the `localStorage` API, so we need to check for it
        // eslint-disable-next-line scanjs-rules/property_localStorage
        window.localStorage && window.localStorage.setItem('selectedLocale', localeCode);
    }

    const notificationBadge = (
        <Badge
            classes={{
                badge:
                    notificationCount > 0 && urgentNotifications ? classes.blinkerBadge : undefined,
            }}
            badgeContent={notificationCount === 0 ? null : notificationCount}
            color={urgentNotifications ? 'success' : 'tertiary'}
            invisible={notificationCount <= 0}
            variant="dot"
            overlap="circular"
        >
            <NotificationsIcon />
        </Badge>
    );

    return (
        <div ref={topBarRef}>
            <NotificationStatus
                unreadNotifications={notificationCount}
                invisible
                limit={notificationsLimit}
            />
            <div className={rootClassName}>
                <Toolbar className={classes.toolbar}>
                    {!hideDrawerIcon && (
                        <IconButton
                            className={classes.menuButton}
                            onClick={onToggleSidebar}
                            variant="outlined"
                            size="large"
                        >
                            <Avatar
                                alt={customerName}
                                className={classes.menuButton}
                                src={customerPhoto}
                            >
                                <MenuIcon />
                            </Avatar>
                        </IconButton>
                    )}
                    {customers.length > 1 && (
                        <Button
                            className={classNames(
                                classes.customerButton,
                                classes.title,
                                customers.length <= 1 ? classes.singleCustomer : ''
                            )}
                            ref={customerAnchorRef}
                            aria-owns={customerOpen ? 'menu-list-grow' : undefined}
                            aria-haspopup
                            onClick={() => setCustomerOpen(prevOpen => !prevOpen)}
                            color="tertiary"
                        >
                            {customerName}
                            <ArrowDropDownIcon />
                        </Button>
                    )}
                    {customers.length > 1 && (
                        <Popper
                            className={classes.popover}
                            open={customerOpen}
                            anchorEl={customerAnchorRef.current}
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                >
                                    <Paper id="menu-list-grow">
                                        <ClickAwayListener onClickAway={handleCloseCustomer}>
                                            <MenuList>
                                                {customers.map(customer => {
                                                    const { customerId, name } = customer;
                                                    return customerId === '' ? null : (
                                                        <MenuItem
                                                            key={customerId}
                                                            value={customerId}
                                                            selected={
                                                                customerId === currentCustomerId
                                                            }
                                                            disabled={
                                                                customerId === currentCustomerId
                                                            }
                                                            onClick={() =>
                                                                handleCustomerClick(customerId)
                                                            }
                                                        >
                                                            {name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    )}

                    <Button
                        className={classNames(classes.localeButton)}
                        ref={localeAnchorRef}
                        aria-owns={localeOpen ? 'menu-list-grow' : undefined}
                        aria-haspopup
                        onClick={() => setLocaleOpen(prevOpen => !prevOpen)}
                        color="tertiary"
                    >
                        {LOCALES.find(({ value }) => value === currentLocale).label}
                        <ArrowDropDownIcon />
                    </Button>
                    <Popper
                        className={classes.popover}
                        open={localeOpen}
                        anchorEl={localeAnchorRef.current}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper id="menu-list-grow">
                                    <ClickAwayListener onClickAway={handleCloseLocale}>
                                        <MenuList>
                                            {LOCALES.sort((a, b) =>
                                                a.label > b.label ? 1 : -1
                                            ).map(({ label, value }) => (
                                                <MenuItem
                                                    key={value}
                                                    value={value}
                                                    selected={value === currentLocale}
                                                    disabled={value === currentLocale}
                                                    onClick={() => handleLocaleClick(value)}
                                                >
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                    <Tooltip title="Settings" aria-label="Settings">
                        <IconButton
                            className={classNames(
                                classes.userDetailsButton,
                                onAccountPage && classes.userDetailsButtonActive
                            )}
                            onClick={handleShowUserDetails}
                            size="large"
                        >
                            <SettingsIcon color="tertiary" />
                        </IconButton>
                    </Tooltip>
                    <Divider orientation="vertical" className={classes.divider} />
                    <Permission toPerform="view" on={AuthenticatedPaths.NOTIFICATIONS}>
                        <Tooltip title="Notifications" aria-label="Notifications">
                            <IconButton
                                className={classNames(
                                    classes.notificationsButton,
                                    onNotificationPage && classes.notificationsButtonActive
                                )}
                                onClick={handleShowNotifications}
                                size="large"
                                color="tertiary"
                            >
                                {notificationBadge}
                            </IconButton>
                        </Tooltip>
                    </Permission>
                    <ResourceCenter className={classes.resourceCenter} />
                </Toolbar>
            </div>
            <Popover
                anchorEl={notificationAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                className={classes.popover}
                onClose={handleCloseNotifications}
                open={notificationsOpen}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <NotificationList onSelect={handleCloseNotifications} limit={notificationsLimit} />
            </Popover>
            <Popover
                anchorEl={detailsAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                className={classes.popover}
                onClose={handleCloseUserDetails}
                open={userDetailsOpen}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <UserDetails onSelect={handleCloseUserDetails} />
            </Popover>
        </div>
    );
});

Topbar.propTypes = {
    canReadCustomer: PropTypes.bool,
    className: PropTypes.string,
    currentCustomerId: PropTypes.string,
    currentLocale: PropTypes.string,
    customers: PropTypes.array,
    customerDetails: PropTypes.object,
    isOnCustomerPage: PropTypes.bool,
    notificationsLimit: PropTypes.number,
    notificationCount: PropTypes.number,
    notificationsOpen: PropTypes.bool,
    isSidebarOpen: PropTypes.bool,
    onChangeCustomer: PropTypes.func,
    onChangeLocale: PropTypes.func,
    onCloseNotifications: PropTypes.func,
    onCloseUserDetails: PropTypes.func,
    onCustomerClick: PropTypes.func,
    onInitialLoad: PropTypes.func,
    onToggleSidebar: PropTypes.func,
    onOpenNotifications: PropTypes.func,
    onOpenUserDetails: PropTypes.func,
    title: PropTypes.string,
    userDetails: PropTypes.object,
    userDetailsOpen: PropTypes.bool,
    urgentNotifications: PropTypes.bool,
};

Topbar.defaultProps = {
    onToggleSidebar: noop,
};

export default Topbar;
