import Component from './Quicksight.component';
import { connect } from 'react-redux';
import { getCustomerId } from '/b2b/authentication/state/Auth.selectors';

const mapStateToProps = state => {
    return {
        customerId: getCustomerId(state),
    };
};

export default connect(mapStateToProps, {})(Component);
