import { AUTH_STATES } from '../../../constants';
import AuthActions from '../../../state/Auth.actions';
import { resetTemporaryPassword as resetTemporaryPasswordService } from '../../../services';

export const resetAuthState = () => dispatch =>
    dispatch(AuthActions.updateAuthState(AUTH_STATES.signedOut));

export const resetTemporaryPassword = email => dispatch => {
    dispatch(AuthActions.resetTemporaryPasswordBegin(email));

    return resetTemporaryPasswordService(email)
        .then(response => {
            return dispatch(AuthActions.resetTemporaryPasswordSuccess(email, response));
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthActions.resetTemporaryPasswordFailure(email, error));

            throw message;
        });
};
