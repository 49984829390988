import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Slider, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import styles from './ValidationSlider.styles';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles(styles);

const ValidationSlider = props => {
    const { value, onChange, error, label, ...remain } = props;
    const classes = useStyles(props);
    const uid = useRef(uuid());
    const [newValue, setNewValue] = useState(value);
    const handleChange = (event, value) => {
        const newValue = value;
        setNewValue(newValue);
    };

    const handleChangeCommitted = (event, value) => {
        const newValue = value;
        onChange && onChange(newValue);
    };

    useEffect(() => {
        setNewValue(value);
    }, [value]);

    const labelClass = classNames(classes.label, error ? classes.error : '');

    return (
        <Fragment key={uid.current}>
            <Typography id={uid.current} className={labelClass} gutterBottom>
                {label}
            </Typography>
            <div className={classes.sliderContainer}>
                <Slider
                    className={classes.slider}
                    aria-labelledby={uid.current}
                    valueLabelDisplay="auto"
                    color="secondary"
                    defaultValue={value}
                    onChange={handleChange}
                    onChangeCommitted={handleChangeCommitted}
                    value={newValue}
                    {...remain}
                />
            </div>
        </Fragment>
    );
};

ValidationSlider.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.number,
    min: PropTypes.number,
    marks: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.any.isRequired,
                label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
        PropTypes.arrayOf(PropTypes.string),
    ]),
    step: PropTypes.number,
};

export default ValidationSlider;
