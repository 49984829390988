import palette from '../palette';
// Colors
import { primary } from '../../colors';

export default {
    root: {
        backgroundColor: palette.background.default,
        '&:hover': {
            backgroundColor: primary.light,
        },
        '&$focused': {
            backgroundColor: primary.light,
        },
    },
};
