import AuthUserActions from '../../../authentication/state/user/AuthUser.actions';
import { fetchUserDetails as fetchUserDetailsService } from '../../../users/services';
import schema from '../../../users/state/db/User.schema';

export const fetchUserDetails = params => dispatch => {
    const { userId } = params;
    const meta = { schema, id: userId };
    dispatch(AuthUserActions.fetchDetailsBegin(meta, params));

    return fetchUserDetailsService()
        .then(details => {
            dispatch(AuthUserActions.fetchDetailsSuccess(meta, params, details));
            return details;
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthUserActions.fetchDetailsFailure(meta, params, message));
            throw message;
        });
};
