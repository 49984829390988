import {
    checkUser as checkUserService,
    confirmSignInFederated,
    fetchAmplifyContext,
    fixAuthContext,
} from './authentication/services';

import AuthActions from './authentication/state/Auth.actions';
import LocaleActions from './common/state/locale/Locale.actions';
import UIActions from './common/state/ui/UI.actions';
import UnauthenticatedPaths from './routing/UnauthenticatedRoutes/UnauthenticatedRoutes.paths';
import { determineLocale } from './common/helpers/i18n';
import { fetchLocale as fetchLocaleService } from './common/services/locale';
import fromState from './common/state/selectors';
import { matchPath } from 'react-router-dom';
import { updateAuth } from './amplify.config';

export const setUserIdle = isIdle => dispatch => dispatch(UIActions.setUserIdle(isIdle));

export const fetchLocale = () => dispatch => {
    const meta = { noRefresh: true };
    const params = { locale: determineLocale() };
    dispatch(LocaleActions.fetchLocaleBegin(meta, params));

    return fetchLocaleService(params)
        .then(response => dispatch(LocaleActions.fetchLocaleSuccess(meta, params, response)))
        .catch(error => dispatch(LocaleActions.fetchLocaleFailure(meta, params, error)));
};

export const checkUser = () => (dispatch, getState) => {
    const params = { username: fromState.Auth.getEmail(getState()) };
    dispatch(AuthActions.signInBegin(params));

    return fixAuthContext()
        .then(() => checkUserService())
        .then(response => {
            dispatch(AuthActions.signInSuccess(params, response));

            return response;
        })
        .catch(amplifyError => {
            // Check for an OAuth route
            const isOAuth = matchPath(window.location.pathname, {
                path: UnauthenticatedPaths.OAUTH,
            });
            if (isOAuth) {
                return confirmSignInFederated(window.location.href)
                    .then(response => {
                        dispatch(AuthActions.signInFederatedSuccess(params, response));
                        dispatch(AuthActions.signInSuccess(params, response));

                        return response;
                    })
                    .catch(error => {
                        dispatch(AuthActions.signInFederatedFailure(params, error));
                        dispatch(AuthActions.signInFailure(params, error));

                        throw error;
                    });
            }
            // Could be that we have the wrong Amplify context,
            // Cognito users use a different User Pool
            // Reset the context
            updateAuth();
            // If we have a user saved, fetch the context
            if (params.username) {
                dispatch(AuthActions.fetchSignInContextBegin(params));

                return fetchAmplifyContext(params)
                    .catch(error => {
                        // Login disabled for customer account
                        if (error.errorCode === 'FE0009') {
                            window.location = 'https://www.osano.com/accounts/locked';
                        }

                        dispatch(AuthActions.fetchSignInContextFailure(params, error));
                        dispatch(AuthActions.signInFailure(params, error));

                        throw error;
                    })
                    .then(context => {
                        const formattedContext = updateAuth(context);
                        dispatch(AuthActions.fetchSignInContextSuccess(params, formattedContext));

                        // Check the user again
                        return checkUserService()
                            .then(response => {
                                dispatch(AuthActions.signInSuccess(params, response));

                                return response;
                            })
                            .catch(error => {
                                dispatch(AuthActions.signInFailure(params, error));

                                throw error;
                            });
                    });
            }
            dispatch(AuthActions.signInFailure(params, amplifyError));

            throw amplifyError;
        });
};

export const deleteSnack = key => dispatch => {
    dispatch(UIActions.deleteSnack(key));
};
