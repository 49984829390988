import { defaultMergeStrategy, defaultProcessStrategy } from '../../common/helpers/Normalizr';

import { schema } from 'normalizr';

export const entityName = 'dsarActionItemField';
export const idAttribute = 'actionItemFieldId';

export const mergeStrategy = defaultMergeStrategy(idAttribute);
export const processStrategy = defaultProcessStrategy(idAttribute);

export default new schema.Entity(entityName, undefined, {
    idAttribute: value =>
        typeof value === 'string'
            ? value
            : value[idAttribute] || `${value.dsarActionItemId}-${value.fieldId}`,
    mergeStrategy,
    processStrategy,
});
