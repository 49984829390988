import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import Component from './UserDetails.component';
import { TRIGGERS } from '/b2b/common/userpilot.triggers';
import UserpilotActions from '/b2b/common/state/userpilot/Userpilot.actions';
import { canManageDataMapping } from '/b2b/authentication/state/user/AuthUser.selectors';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';
import noop from 'lodash/noop';
import { signOut } from './UserDetails.commands';

const mapStateToProps = state => {
    const hasPermissionToViewOrganizations = fromState.Auth.user.hasPermission(state, {
        toPerform: 'view',
        on: AuthenticatedPaths.ORGANIZATIONS,
    });

    const customerId = fromState.Auth.customer.getId(state);
    const subscriptionId =
        (customerId &&
            fromState.DB.customer.getEntityFieldById(state, {
                id: customerId,
                field: 'subscription',
            })) ||
        '';

    const { isBusinessPlan = false, isBusinessPlusPlan = false } =
        (subscriptionId && fromState.DB.subscription.getEntityById(state, subscriptionId)) || {};

    return {
        hasPermissionToViewOrganizations,
        isBusinessPlan,
        isBusinessPlusPlan,
        shouldRenderApiKeys: canManageDataMapping(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSignOut: () => dispatch(signOut()),
        businessUpgrade: () =>
            dispatch(UserpilotActions.setUserpilotContentId(TRIGGERS.BUSINESS.id)),
        businessPlusUpgrade: () =>
            dispatch(UserpilotActions.setUserpilotContentId(TRIGGERS.BUSINESS_PLUS.id)),
        basicUpgrade: () =>
            dispatch(UserpilotActions.setUserpilotContentId(TRIGGERS.BASIC_AND_DEVELOPER.id)),
        userPilotOrganizations: () =>
            dispatch(UserpilotActions.setUserpilotContentId(TRIGGERS.ORGANIZATIONS.id)),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { hasPermissionToViewOrganizations } = stateProps;
    return {
        ...ownProps,
        ...stateProps,
        onSignOut: dispatchProps.onSignOut,
        onOrganizationsClick: hasPermissionToViewOrganizations
            ? noop
            : dispatchProps.userPilotOrganizations,
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
