import { DOMAIN } from './DataStoreRequestActions.constants';
import get from 'lodash/get';

export const getStore = state => get(state, DOMAIN) || {};

export const getResults = state => getStore(state).results || [];
export const getResultCount = state => getStore(state).resultCount || 0;
export const getError = state => getStore(state).error;

export const getRequestParams = state => {
    const { offset, limit, query } = getStore(state);
    return {
        offset,
        limit,
        query,
    };
};

export const isLoading = state => getStore(state).loading;
