import {
    types as ActionTypes,
    asyncEvents as AsyncEvents,
    postEvents as PostEvents,
} from './ConversationThreads.actions';
import {
    crudPagingReducers,
    genericReducer,
    loadingReducer,
} from '../../../common/helpers/ReduxHelpers';

import { combineReducers } from 'redux';
import schema from '../db/conversation/thread/HelpScoutConversationThread.schema';

export const initialState = {
    offset: 0,
    limit: 25,
    posting: false,
    results: [],
    resultCount: 0,
    loading: false,
    error: null,
    newPost: null,
};

export default combineReducers({
    newPost: genericReducer(initialState.newPost, {
        [ActionTypes.postReplyBegin]: (state, post) => {
            return post;
        },
        [ActionTypes.postReplySuccess]: () => {
            return null;
        },
        [ActionTypes.postReplyFailure]: () => {
            return null;
        },
    }),
    posting: loadingReducer(
        initialState.posting,
        Object.keys(PostEvents).map(key => ActionTypes[key])
    ),
    ...crudPagingReducers(
        initialState,
        Object.keys(AsyncEvents).map(key => ActionTypes[key]),
        schema
    ),
});
