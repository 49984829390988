import AuthenticatedPaths from '../routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { NOTIFICATION_TYPES } from '/b2b/notifications/constants';

export const UI_VIEW_PREFIX = '__view__';

/* All users have access */
export const UNRESTRICTED_PATHS = [
    AuthenticatedPaths.ACCOUNT,
    AuthenticatedPaths.DASHBOARD,
    AuthenticatedPaths.NOTIFICATIONS,
];

/* Permission based paths */
export const ADMIN_PATHS = [...Object.values(AuthenticatedPaths)];

export const ASSESSMENTS_PATHS = [
    AuthenticatedPaths.ASSESSMENTS,
    AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS,
    AuthenticatedPaths.ASSESSMENT_TEMPLATES,
    AuthenticatedPaths.ASSESSMENTS_REPORTING,
];
export const ADVISORY_PATHS = [
    AuthenticatedPaths.ASK_ANALYST,
    AuthenticatedPaths.LEGISLATION,
    AuthenticatedPaths.LITIGATION,
];
export const CONSENTS_PATHS = [
    AuthenticatedPaths.CONSENT_MANAGER,
    AuthenticatedPaths.CONSENT_MONITOR,
    AuthenticatedPaths.CONSENT_DASHBOARD,
];
export const UNIFIED_CONSENT_PATHS = [
    AuthenticatedPaths.CONNECT_UC_INTEGRATION,
    AuthenticatedPaths.UC_CONFIGS,
    AuthenticatedPaths.UC_PRIVACY_PROTOCOLS,
    AuthenticatedPaths.UC_INTEGRATIONS,
    AuthenticatedPaths.UC_DASHBOARD,
];
export const DATA_DISCOVERY_PATHS = [
    AuthenticatedPaths.CONNECT_DATA_MAPPING,
    AuthenticatedPaths.DATA_STORES,
];
export const DATA_MAPPING_PATHS = [
    AuthenticatedPaths.DATA_MAPPING_APPLICATIONS,
    AuthenticatedPaths.DATA_MAPPING_SOURCES,
    AuthenticatedPaths.DATA_MAP,
];
export const DSAR_REQUEST_PATHS = [
    AuthenticatedPaths.DSAR_ACTION_ITEMS,
    AuthenticatedPaths.DSAR_FORMS,
    AuthenticatedPaths.DSAR_REPORTING,
    AuthenticatedPaths.DSAR_REQUESTS,
];
export const MONITORING_PATHS = [
    AuthenticatedPaths.DOCUMENT_CHANGES,
    AuthenticatedPaths.DOCUMENTS,
    AuthenticatedPaths.PRODUCTS,
    AuthenticatedPaths.VENDORS,
];
export const ORGANIZATIONS_PATHS = [AuthenticatedPaths.ORGANIZATIONS];
export const REPORTING_PATHS = [
    AuthenticatedPaths.REPORTS,
    AuthenticatedPaths.REPORTS_CONSENT,
    AuthenticatedPaths.REPORTS_DATASUBJECT,
    AuthenticatedPaths.REPORTS_VENDOR,
];
export const SUBSCRIPTION_MGMT_PATHS = [AuthenticatedPaths.PREMIUM];
export const TRUSTHUB_PATHS = [AuthenticatedPaths.TRUSTHUB];

export const ROLES = {
    ALL: 'ALL',
    ADMIN: 'Admin',
    ADVISORY_MANAGER: 'AdvisoryManager',
    ASSESSMENT_ADMIN: 'AssessmentAdmin',
    ASSESSMENT_MANAGER: 'AssessmentManager',
    ASSESSMENT_VIEWER: 'AssessmentViewer',
    CONSENT_MANAGER: 'ConsentManager',
    CONSENT_VIEWER: 'ConsentViewer',
    DATA_MAPPING_MANAGER: 'DataMappingManager',
    DATA_MAPPING_VIEWER: 'DataMappingViewer',
    DATA_REQUESTS_MANAGER: 'DataRequestsManager',
    DATA_STORE_OWNER: 'DataSourceOwner',
    MONITORING_MANAGER: 'MonitoringManager',
    PURCHASING: 'Purchasing',
    TRUSTHUB_ADMIN: 'TrustHubAdmin',
    TRUSTHUB_EDITOR: 'TrustHubEditor',
    UC_MANAGER: 'UcManager',
    UC_VIEWER: 'UcViewer',
};

export const PERMISSIONS = {
    ALL: 'all',
    ADMIN: 'admin',
    ADVISORY: 'advisory',
    ASSESSMENT: 'assessment',
    CMP_TRANSLATIONS: 'cmp_translations',
    CONSENTS: 'consents',
    DATA_DISCOVERY: 'dataDiscovery',
    DATA_MAPPING: 'dataMapping',
    DSAR_DATASOURCE: 'dsarDatasource',
    DSAR_REQUEST: 'dsarRequest',
    MONITORING: 'monitoring',
    ORGANIZATIONS: 'organizations',
    REPORTING: 'reporting',
    SUBSCRIPTION_MGMT: 'subscriptionMgmt',
    TRUSTHUB: 'trusthub',
    UNIFIED_CONSENT: 'uc',
    VENDOR_MANAGEMENT: 'vendor_management',
};

export const PERMISSIONS_PATHS_MAP = {
    [PERMISSIONS.ADMIN]: ADMIN_PATHS,
    [PERMISSIONS.ADVISORY]: ADVISORY_PATHS,
    [PERMISSIONS.CONSENTS]: CONSENTS_PATHS,
    [PERMISSIONS.DATA_DISCOVERY]: DATA_DISCOVERY_PATHS,
    [PERMISSIONS.DATA_MAPPING]: DATA_MAPPING_PATHS,
    [PERMISSIONS.DSAR_REQUEST]: DSAR_REQUEST_PATHS,
    [PERMISSIONS.MONITORING]: MONITORING_PATHS,
    [PERMISSIONS.ORGANIZATIONS]: ORGANIZATIONS_PATHS,
    [PERMISSIONS.REPORTING]: REPORTING_PATHS,
    [PERMISSIONS.SUBSCRIPTION_MGMT]: SUBSCRIPTION_MGMT_PATHS,
    [PERMISSIONS.TRUSTHUB]: TRUSTHUB_PATHS,
    [PERMISSIONS.UNIFIED_CONSENT]: UNIFIED_CONSENT_PATHS,
};

export const ROLE_NOTIFICATIONS_MAP = {
    [ROLES.ADMIN]: [...Object.values(NOTIFICATION_TYPES)],
    [ROLES.CONSENT_MANAGER]: [NOTIFICATION_TYPES.CONSENT_MANAGER],
    [ROLES.DATA_MAPPING_MANAGER]: [NOTIFICATION_TYPES.DATA_MAPPING],
    [ROLES.DATA_MAPPING_VIEWER]: [NOTIFICATION_TYPES.DATA_MAPPING],
    [ROLES.DATA_REQUESTS_MANAGER]: [NOTIFICATION_TYPES.DSAR],
    [ROLES.DATA_STORE_OWNER]: [NOTIFICATION_TYPES.DSAR],
    [ROLES.MONITORING_MANAGER]: [
        NOTIFICATION_TYPES.LEGISLATION,
        NOTIFICATION_TYPES.LITIGATION,
        `${NOTIFICATION_TYPES.VENDOR}.preferences.assigned`,
        NOTIFICATION_TYPES.VENDOR,
        `${NOTIFICATION_TYPES.VENDOR}.preferences.privacy.score`,
        NOTIFICATION_TYPES.SITE_BREACH,
        NOTIFICATION_TYPES.REVIEW_DATE,
    ],
};

export const AUTH_STATES = {
    signIn: 'signIn',
    signUp: 'signUp',
    confirmSignIn: 'confirmSignIn',
    confirmSignUp: 'confirmSignUp',
    verifyEmail: 'verifyEmail',
    verifyPhone: 'verifyPhone',
    verifyTOTP: 'verifyTOTP',
    selectMFA: 'selectMFA',
    setupPhone: 'setupPhone',
    setupTOTP: 'setupTOTP',
    forgotPassword: 'forgotPassword',
    requireNewPassword: 'requireNewPassword',
    resendInvitation: 'resendInvitation',
    signedIn: 'signedIn',
    signedOut: 'signedOut',
    signInConfirmed: 'signInConfirmed',
};

export const MFA_TYPES = {
    SMS: ['SMS', 'SMS_MFA'],
    TOTP: ['TOTP', 'SOFTWARE_TOKEN_MFA'],
};
