import { createSchema } from '/b2b/common/helpers/Schema';

export default createSchema({
    hasPhone: {
        type: 'hidden',
    },
    phone: {
        label: 'Phone Number',
    },
});
