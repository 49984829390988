import palette from '../palette';
import theme from '../default';

export default {
    root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        padding: 0,
        height: 0,
        paddingTop: 0,
        paddingBottom: 0,
        zIndex: 150,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },
    title: {
        display: 'none',
    },
    iconButton: {
        boxShadow: theme.shadows[4],
        color: palette.danger.contrastText,
        backgroundColor: palette.danger.main,
        marginLeft: theme.spacing(1),
        alignSelf: 'flex-start',
        '&:hover': {
            color: palette.danger.contrastText,
            backgroundColor: palette.danger.dark,
        },
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center',
            order: -1,
            transform: 'translate(0, 0)',
            margin: 0,
        },
    },
    deleteIcon: {},
};
