/* eslint-disable xss/no-mixed-html */
import './common/validation';
import './charts';
import './assets/scss/index.scss';

// Amplify
import Amplify, { Hub } from '@aws-amplify/core';

// App
import App from './App.component';
import { Auth } from '@aws-amplify/auth';
import { LicenseInfo } from '@mui/x-license-pro';
import Logger from './common/helpers/Logger';
import NavActions from './common/state/nav/Nav.actions';
import React from 'react';
import ReactDOM from 'react-dom';
import amplifyConfig from './amplify.config';
import analytics from './common/analytics';
import exposed from './exposed';
import history from './common/history';
import store from './store';
import theme from './common/theme';

// Set MUI X License
LicenseInfo.setLicenseKey(process.env.MUI_PREMIUM_LICENSE_KEY);

history.listen((location, action) => {
    store.dispatch(NavActions.routeChanged({ location, action }));
});

store.dispatch(NavActions.routeChanged({ location: document.location, action: 'LOAD' }));

Logger.log(" ___ ___ ___ ___ ___  \n| . |_ -| .'|   | . | \n|___|___|__,|_|_|___|\n\n");
Logger.log('Love to code? Like 💰?\n');
Logger.log('Head over to https://www.osano.com/company/careers\n\n');

try {
    window.Osano.cm.addEventListener('osano-cm-script-blocked', function (item) {
        Logger.log('Script blocked: ' + item);
    });
    window.Osano.cm.addEventListener('osano-cm-cookie-blocked', function (item) {
        Logger.log('Cookie blocked: ' + item);
    });
} catch (error) {
    //nada
}

const ads = document.createElement('div');
ads.innerText = 'ad';
ads.className = 'adsbox';
ads.id = 'adsbox';
document.body.appendChild(ads);

function onReady(cb) {
    if (document.readyState !== 'loading') {
        cb();
    } else {
        document.addEventListener('DOMContentLoaded', cb);
    }
}

onReady(() => {
    setTimeout(() => {
        if (typeof ads === 'undefined' || ads.offsetHeight === 0) {
            let adblockDetected =
                'Your browser or a browser extension is preventing Osano from loading.';
            adblockDetected += '<br/><br/>';
            adblockDetected +=
                'Please disable any extensions that may be modifying this document and reload this page.';
            document.querySelector('p.loading-notice').innerHTML = adblockDetected;
            ads.remove();
        } else {
            ads.remove();
            Amplify.configure(amplifyConfig);
            Auth.configure(amplifyConfig.Auth);

            Logger.log(`(${process.env.MODE})`, 'Osano B2B Logger Initialized');

            Hub.listen('auth', ({ channel, payload }) => {
                store.dispatch({
                    meta: { noRefresh: true },
                    type: `AmplifyBridge/${channel}:${payload.event}`,
                    payload: {
                        data: payload.data,
                        message: payload.message,
                    },
                });
            });

            window.Osano = {
                ...window.Osano,
                ...exposed,
            };

            let resourceCenter = document.getElementById('osano-resource-center');
            if (!resourceCenter) {
                resourceCenter = document.createElement('div');
                resourceCenter.id = 'osano-resource-center';
            }
            document.body.appendChild(resourceCenter);

            window.hsConversationsSettings = {
                inlineEmbedSelector: '#osano-resource-center',
            };

            // Init Analytics
            analytics.load();

            window.addEventListener('unhandledrejection', event => {
                try {
                    event.preventDefault();
                    event.stopPropagation();
                    event.stopImmediatePropagation();
                } catch (error) {
                    // Prevent propagation
                }
                event.message && Logger.error('Unhandled Promise Rejection:', event.message);
            });
            window.addEventListener('error', event => {
                const { message } = event;
                const string = message.toLowerCase();
                const substring = 'script error';
                if (string.indexOf(substring) > -1) {
                    // Error occurred in third party library
                    try {
                        event.preventDefault();
                        event.stopPropagation();
                        event.stopImmediatePropagation();
                    } catch (error) {
                        // Prevent propagation
                    }
                    return false;
                }
                if (event.type === 'abort' || event.name === 'AbortError') {
                    // Abort long running request
                } else {
                    if (/ResizeObserver loop limit exceeded/g.test(message)) {
                        // Do not log slow resize observers, this is a common issue with MUI
                        return false;
                    }
                    Logger.error(message);
                }
                return false;
            });

            ReactDOM.render(
                React.createElement(App, {
                    history,
                    store,
                    theme,
                }),
                document.getElementById('root')
            );
        }
    }, 100);
});

if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
        module.hot.accept();
    }
}
