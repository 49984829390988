import { cobalt, emerald, white } from '../../colors';

/* eslint react/prop-types: 0 */
import React from 'react';
import { SvgIcon } from '@mui/material';

export default function Documents(props) {
    const { active, ...remain } = props;
    const primary = active ? emerald : emerald;
    const secondary = active ? cobalt : white;
    return (
        <SvgIcon fontSize="large" viewBox="0 0 98.29 74.1" {...remain}>
            <path
                d="m77.5 45.82-4.03 1.63-4.03 1.63 12.73 5.15-33.1 13.43-33.09-13.43 12.82-5.17-3.98-1.61-4.03-1.63-20.79 8.41 49.14 19.86 49.15-19.86z"
                fill={secondary}
            />
            <path
                d="m76.59 28.64-4.02 1.63-4.03 1.63 13.63 5.51-12.71 5.16-4.02 1.63-4.01 1.63-12.36 5.02-12.31-5-3.99-1.62-4.01-1.63-12.78-5.19 13.73-5.53-3.99-1.61-4.02-1.63-21.7 8.77 20.79 8.41 4.03 1.63 3.98 1.61 20.34 8.22 20.3-8.2 4.03-1.63 4.03-1.63 20.79-8.41z"
                fill={primary}
            />
            <path
                d="m49.14 0-49.14 19.87 21.69 8.77 4 1.63 4 1.61 12.78 5.17 4 1.62 2.63 1.07 2.64-1.07 4-1.62 12.8-5.15 4-1.63 4-1.63 21.7-8.77zm-.07 33.3-11.4-4.63-4-1.62-4-1.63-13.67-5.55 33.14-13.36 33 13.36-13.58 5.52-4 1.63-4 1.63z"
                fill={secondary}
            />
        </SvgIcon>
    );
}
