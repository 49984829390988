import PropTypes from 'prop-types';
import identity from 'lodash/identity';
import { translate } from '../../helpers/i18n';

/**
 * @typedef {object} LocaleProps
 * @property {(stringToFormat: string) => string} [formatter]
 * @property {object} [options] Replacement values to be used in the translation
 * @property {string|string[]} path
 */

/** @type {React.FC<LocaleProps>} */
const Locale = ({ formatter = identity, path, options = {} } = {}) =>
    (path &&
        formatter(
            translate(
                path,
                Object.entries(options).reduce(
                    (acc, [key, value]) => (
                        (acc[key] = typeof value === 'string' ? translate(value, options) : value),
                        acc
                    ),
                    {}
                )
            )
        )) ||
    null;

Locale.propTypes = {
    formatter: PropTypes.func,
    path: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]),
    options: PropTypes.object,
};

export default Locale;
