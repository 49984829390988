/* eslint-disable camelcase */
import { apiName, apiUrl, localApi, localApiName } from '@osano-b2b';

import Auth from '@aws-amplify/auth';
import fromState from './common/state/selectors';
import store from './store';

const isLocalApi = process.env.NODE_ENV === 'development' && /localhost/.test(apiUrl);

const localApiEndpoint = [];

const commonApiEndpoint = {
    region: process.env.COGNITO_REGION,
    custom_header: async () => {
        const headers = {};
        const state = store.getState();
        const id = fromState.Auth.user.getId(state);
        const token = fromState.Auth.getCustomerToken(state);
        if (token) {
            if (isLocalApi) {
                const customerId = fromState.Auth.customer.getId(state);
                headers['x-osno-uid'] = `${customerId}~${id}`;
            } else {
                headers['x-osno-uid'] = token;
            }
        } else {
            headers['x-osno-uid'] = `~${id}`;
        }
        headers['x-osno-auth'] = (await Auth.currentSession()).getIdToken().getJwtToken();
        return headers;
    },
};

if (apiName !== localApiName) {
    localApiEndpoint.push({
        name: localApiName,
        endpoint: localApi,
        region: process.env.COGNITO_REGION,
        custom_header: async () => {
            try {
                const session = await Auth.currentSession();
                const accessToken = session.getAccessToken();
                const idToken = session.getIdToken();
                const refreshToken = session.getRefreshToken();
                const state = store.getState();
                const token = fromState.Auth.getCustomerToken(state);
                const id = fromState.Auth.user.getId(state);
                const headers = {
                    authorization: `Bearer ${accessToken.getJwtToken()} ${idToken.getJwtToken()} ${refreshToken.getToken()}`,
                    ['x-osno-auth']: idToken.getJwtToken(),
                };
                if (token) {
                    if (isLocalApi) {
                        const customerId = fromState.Auth.customer.getId(state);
                        headers['x-osno-uid'] = `${customerId}~${id}`;
                    } else {
                        headers['x-osno-uid'] = token;
                    }
                } else {
                    headers['x-osno-uid'] = `~${id}`;
                }
                return headers;
            } catch (error) {
                return {};
            }
        },
    });
}

/**
 * @param {string} url
 * @param {string} [redirectUrl]
 * @returns {Window} Instance of the window that is loading the url
 */
const urlOpener = url => {
    return new Promise((resolve, reject) => {
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        const windowProxy = window.open(url, '_self');
        if (windowProxy) {
            resolve(windowProxy);
        }
        reject();
    });
};

const amplifyConfig = {
    Auth: {
        mandatorySignIn: true,
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: process.env.COGNITO_IDP_IDENTITY_POOL_ID,
        // REQUIRED - Amazon Cognito Region
        region: process.env.COGNITO_REGION,
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.COGNITO_IDP_USER_POOL_ID,
        // OPTIONAL - Amazon Cognito Web Client ID
        userPoolWebClientId: process.env.COGNITO_IDP_APP_CLIENT_ID,
        // Used for Federated Sign-In
        oauth: {
            clientID: process.env.COGNITO_IDP_APP_CLIENT_ID,
            domain: process.env.COGNITO_IDP_USER_POOL_DOMAIN,
            redirectUri: process.env.COGNITO_IDP_SIGNIN_URL,
            responseType: process.env.COGNITO_IDP_GRANT_FLOW,
            scope: `${process.env.COGNITO_IDP_OAUTH_CLAIMS}`.split(','),
            awsCognito: {
                options: {
                    clientID: process.env.COGNITO_IDP_APP_CLIENT_ID,
                    domain: process.env.COGNITO_IDP_USER_POOL_DOMAIN,
                    scopes: `${process.env.COGNITO_IDP_OAUTH_CLAIMS}`.split(','),
                    redirectSignIn: process.env.COGNITO_IDP_SIGNIN_URL,
                    redirectSignOut: process.env.COGNITO_IDP_SIGNOUT_URL,
                    responseType: process.env.COGNITO_IDP_GRANT_FLOW,
                    urlOpener,
                },
            },
        },
    },
    API: {
        endpoints: [
            // express API
            {
                ...commonApiEndpoint,
                name: apiName,
                endpoint: apiUrl,
            },
            ...localApiEndpoint,
        ],
    },
};

export const formatAuthContext = context => {
    const baseContext = context || {};
    let {
        appClientId = process.env.COGNITO_IDP_APP_CLIENT_ID,
        cognitoDomainName = process.env.COGNITO_IDP_USER_POOL_DOMAIN,
        identityPoolId = process.env.COGNITO_IDP_IDENTITY_POOL_ID,
        identityProviderName = 'Cognito',
        ssoEnabled = false,
        userPoolId = process.env.COGNITO_IDP_USER_POOL_ID,
    } = baseContext;
    if (ssoEnabled !== true) {
        appClientId = process.env.COGNITO_APP_CLIENT_ID;
        identityPoolId = process.env.COGNITO_IDENTITY_POOL_ID;
        userPoolId = process.env.COGNITO_USER_POOL_ID;
    }
    return {
        appClientId,
        cognitoDomainName,
        identityPoolId,
        identityProviderName,
        ssoEnabled,
        userPoolId,
    };
};

export const updateAuth = context => {
    const formattedContext = formatAuthContext(context);
    const { appClientId, identityPoolId, userPoolId } = formattedContext;
    // Reconfigure Amplify
    Auth.configure({
        ...amplifyConfig.Auth,
        identityPoolId,
        userPoolId,
        userPoolWebClientId: appClientId,
        oauth: {
            ...amplifyConfig.Auth.oauth,
            clientID: appClientId,
            awsCognito: {
                ...amplifyConfig.Auth.oauth.awsCognito,
                options: {
                    ...amplifyConfig.Auth.oauth.awsCognito.options,
                    clientID: appClientId,
                },
            },
        },
    });
    return formattedContext;
};

export default amplifyConfig;
