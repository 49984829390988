import { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

const NotificationStatus = props => {
    const { unreadNotifications = 0, pollNotifications, invisible, limit } = props;

    const notificationsPoll = useRef(null);

    useEffect(() => {
        if (!notificationsPoll.current) {
            notificationsPoll.current = pollNotifications({
                delay: unreadNotifications === 0 ? 1 : 1000,
                interval: 60000,
                limit: limit + 1,
            });
        }
        return () => {
            notificationsPoll.current && notificationsPoll.current.abort();
            notificationsPoll.current = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unreadNotifications]);

    return invisible ? null : unreadNotifications;
};

NotificationStatus.propTypes = {
    invisible: PropTypes.bool,
    limit: PropTypes.number,
    unreadNotifications: PropTypes.number,
    pollNotifications: PropTypes.func,
};

export default NotificationStatus;
