import Component from './Locale.component';
import { connect } from 'react-redux';
import fromState from '../../state/selectors';

export const mapStateToProps = state => ({
    shouldRetranslate: fromState.Locale.shouldRetranslate(state),
});

/** @type {React.FC<import('./Locale.component').LocaleProps>} */
export default connect(mapStateToProps)(Component);
