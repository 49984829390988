import {
    ENABLE_ASSESSMENTS_REPORTING,
    ENABLE_CMP_DASHBOARD,
    ENABLE_CUSTOM_ASSESSMENTS,
    ENABLE_DISCOVERY_SOURCES,
    ENABLE_DSAR_REPORTING,
    ENABLE_UC_DASHBOARD,
} from '/b2b/common/components/FeatureFlag';

import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { PERMISSIONS } from '/b2b/authentication/constants';

export const NAV_LABELS = {
    assessments: 'layouts.Dashboard.components.Sidebar.assessments.label',
    consentManager: 'views.ConsentManager.title',
    consultPrivacyTeam: 'layouts.Dashboard.components.Sidebar.consultPrivacyTeam.label',
    dataMapping: 'layouts.Dashboard.components.Sidebar.dataMapping.label',
    dataDiscovery: 'layouts.Dashboard.components.Sidebar.dataDiscovery.label',
    dsar: 'layouts.Dashboard.components.Sidebar.dataSubjectRequests.label',
    gdprRepresentative: 'layouts.Dashboard.components.Sidebar.gdprRepresentative.label',
    privacyTemplates: 'layouts.Dashboard.components.Sidebar.templates.label',
    regulatoryGuidance: 'layouts.Dashboard.components.Sidebar.regulatoryGuidance.label',
    reports: 'layouts.Dashboard.components.Sidebar.reports.label',
    trusthub: 'layouts.Dashboard.components.Sidebar.trusthub.label',
    unifiedConsent: 'title.unifiedConsent',
    vendor: 'layouts.Dashboard.components.Sidebar.vendorManagement.label',
};

export const SUBMENU_LABELS = {
    assessmentAssignments: 'layouts.Dashboard.components.Sidebar.assessmentAssignments.label',
    assessmentReporting: 'layouts.Dashboard.components.Sidebar.reporting.label',
    assessmentTemplates: 'layouts.Dashboard.components.Sidebar.assessmentTemplates.label',
    commonConfigurations: 'common.configurations',
    commonReporting: 'common.reporting',
    dataApplications: 'layouts.Dashboard.components.Sidebar.dataMapping.submenu.applications.label',
    dataMap: 'layouts.Dashboard.components.Sidebar.dataMapping.submenu.dataMap.label',
    dataProviders: 'layouts.Dashboard.components.Sidebar.dataMapping.submenu.dataProviders.label',
    dataSources: 'layouts.Dashboard.components.Sidebar.dataMapping.submenu.dataSources.label',
    dsarActionItems:
        'layouts.Dashboard.components.Sidebar.dataSubjectRequests.submenu.actionItemList.label',
    dsarForms:
        'layouts.Dashboard.components.Sidebar.dataSubjectRequests.submenu.requestSubmissionForms.label',
    dsarRequests:
        'layouts.Dashboard.components.Sidebar.dataSubjectRequests.submenu.requestSubmissions.label',
    reportsConsent: 'layouts.Dashboard.components.Sidebar.reports.submenu.consent.label',
    reportsDsar: 'layouts.Dashboard.components.Sidebar.reports.submenu.datasubject.label',
    reportsVendor: 'layouts.Dashboard.components.Sidebar.reports.submenu.vendor.label',
    ucConfigurations: 'common.configurations',
    ucIntegrations: 'uc.title.integrations',
    ucProtocols: 'uc.title.protocols',
    vmLitigation: 'layouts.Dashboard.components.Sidebar.vendorLawsuits.label',
    vmPolicyChanges: 'layouts.Dashboard.components.Sidebar.policyChanges.label',
    vmVendors: 'layouts.Dashboard.components.Sidebar.vendors.label',
};

export const navMenuItems = {
    [NAV_LABELS.consentManager]: {
        featureFlag: ENABLE_CMP_DASHBOARD,
        pages: [
            {
                title: SUBMENU_LABELS.commonConfigurations,
                path: AuthenticatedPaths.CONSENT_MANAGER,
                permission: null,
                featureFlag: null,
            },
            {
                title: SUBMENU_LABELS.commonReporting,
                path: AuthenticatedPaths.CONSENT_DASHBOARD,
                permission: null,
                featureFlag: ENABLE_CMP_DASHBOARD,
            },
        ],
    },
    [NAV_LABELS.unifiedConsent]: {
        pages: [
            {
                title: SUBMENU_LABELS.ucProtocols,
                path: AuthenticatedPaths.UC_PRIVACY_PROTOCOLS,
                permission: null,
                featureFlag: null,
            },
            {
                title: SUBMENU_LABELS.commonConfigurations,
                path: AuthenticatedPaths.UC_CONFIGS,
                permission: null,
                featureFlag: null,
            },
            {
                title: SUBMENU_LABELS.ucIntegrations,
                path: AuthenticatedPaths.UC_INTEGRATIONS,
                permission: null,
                featureFlag: null,
            },
            {
                title: SUBMENU_LABELS.commonReporting,
                path: AuthenticatedPaths.UC_DASHBOARD,
                permission: null,
                featureFlag: ENABLE_UC_DASHBOARD,
            },
        ],
    },
    [NAV_LABELS.dsar]: {
        pages: [
            {
                title: SUBMENU_LABELS.dsarActionItems,
                path: AuthenticatedPaths.DSAR_ACTION_ITEMS,
                permission: {
                    action: 'read',
                    type: 'on',
                    permissions: [PERMISSIONS.DSAR_DATASOURCE],
                },
                featureFlag: null,
            },
            {
                title: SUBMENU_LABELS.dsarRequests,
                path: AuthenticatedPaths.DSAR_REQUESTS,
                permission: {
                    action: 'read',
                    type: 'on',
                    permissions: [PERMISSIONS.DSAR_REQUEST],
                },
                featureFlag: null,
            },
            {
                title: SUBMENU_LABELS.dsarForms,
                path: AuthenticatedPaths.DSAR_FORMS,
                permission: {
                    action: 'read',
                    type: 'on',
                    permissions: [PERMISSIONS.DSAR_REQUEST],
                },
                featureFlag: null,
            },
            {
                title: SUBMENU_LABELS.commonReporting,
                path: AuthenticatedPaths.DSAR_REPORTING,
                permission: {
                    action: 'read',
                    type: 'on',
                    permissions: [PERMISSIONS.DSAR_REQUEST],
                },
                featureFlag: ENABLE_DSAR_REPORTING,
            },
        ],
    },
    [NAV_LABELS.dataMapping]: {
        pages: [
            {
                title: SUBMENU_LABELS.dataSources,
                path: AuthenticatedPaths.DATA_MAPPING_SOURCES,
                permission: null,
                featureFlag: ENABLE_DISCOVERY_SOURCES,
                rolePermissions: ['canReadDataMapping'],
            },
            {
                title: SUBMENU_LABELS.dataApplications,
                path: AuthenticatedPaths.DATA_MAPPING_APPLICATIONS,
                permission: null,
                featureFlag: ENABLE_DISCOVERY_SOURCES,
                rolePermissions: ['canReadDataMapping'],
            },
            {
                title: SUBMENU_LABELS.dataProviders,
                path: AuthenticatedPaths.DATA_STORES,
                permission: null,
                featureFlag: null,
                rolePermissions: ['canReadDataMapping', 'canManageSubjectRights'],
            },
            {
                title: SUBMENU_LABELS.dataMap,
                path: AuthenticatedPaths.DATA_MAP,
                permission: null,
                featureFlag: null,
                rolePermissions: ['canReadDataMapping'],
            },
        ],
    },
    [NAV_LABELS.vendor]: {
        pages: [
            {
                title: SUBMENU_LABELS.vmVendors,
                path: AuthenticatedPaths.VENDORS,
                permission: null,
                featureFlag: null,
            },
            {
                title: SUBMENU_LABELS.vmLitigation,
                path: AuthenticatedPaths.LITIGATION,
                permission: null,
                featureFlag: null,
            },
            {
                title: SUBMENU_LABELS.vmPolicyChanges,
                path: AuthenticatedPaths.DOCUMENT_CHANGES,
                permission: null,
                featureFlag: null,
            },
        ],
    },
    [NAV_LABELS.assessments]: {
        pages: [
            {
                title: NAV_LABELS.assessments,
                path: AuthenticatedPaths.ASSESSMENTS,
                activePathExclusions: [
                    AuthenticatedPaths.ASSESSMENT_TEMPLATES,
                    AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS,
                    AuthenticatedPaths.ASSESSMENTS_REPORTING,
                ],
                permission: {
                    action: 'read',
                    type: 'onAny',
                    permissions: [PERMISSIONS.ADMIN, PERMISSIONS.ASSESSMENT],
                },
                featureFlag: null,
            },
            {
                title: SUBMENU_LABELS.assessmentTemplates,
                path: AuthenticatedPaths.ASSESSMENT_TEMPLATES,
                permission: {
                    action: 'read',
                    type: 'onAny',
                    permissions: [PERMISSIONS.ADMIN, PERMISSIONS.ASSESSMENT],
                },
                featureFlag: ENABLE_CUSTOM_ASSESSMENTS,
            },
            {
                title: SUBMENU_LABELS.assessmentAssignments,
                path: AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS,
                permission: null,
                featureFlag: null,
            },
            {
                title: SUBMENU_LABELS.assessmentReporting,
                path: AuthenticatedPaths.ASSESSMENTS_REPORTING,
                permission: {
                    action: 'read',
                    type: 'on',
                    permissions: [PERMISSIONS.ASSESSMENT],
                },
                featureFlag: ENABLE_ASSESSMENTS_REPORTING,
            },
        ],
    },
    [NAV_LABELS.reports]: {
        pages: [
            {
                title: SUBMENU_LABELS.reportsConsent,
                path: AuthenticatedPaths.REPORTS_CONSENT,
                permission: {
                    action: 'write',
                    type: 'on',
                    permissions: [PERMISSIONS.CONSENTS],
                },
                featureFlag: null,
            },
            {
                title: SUBMENU_LABELS.reportsDsar,
                path: AuthenticatedPaths.REPORTS_DATASUBJECT,
                permission: {
                    action: 'write',
                    type: 'on',
                    permissions: [PERMISSIONS.DSAR_REQUEST],
                },
                featureFlag: null,
            },
            {
                title: SUBMENU_LABELS.reportsVendor,
                path: AuthenticatedPaths.REPORTS_VENDOR,
                permission: {
                    action: 'write',
                    type: 'on',
                    permissions: [PERMISSIONS.MONITORING],
                },

                featureFlag: null,
            },
        ],
    },
};
