export default theme => ({
    root: {
        height: '100%',
        position: 'relative',
    },
    portletContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    progressWrapper: {
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,0.9)',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 125,
    },
    details: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(4, 4, 0),
        [theme.breakpoints.down('xl')]: {
            flexWrap: 'wrap',
        },
    },
    chart: {
        padding: theme.spacing(3),
    },
});
