import AuthActions from '/b2b/authentication/state/Auth.actions';
import { completeNewPassword as CompleteNewPasswordService } from '/b2b/authentication/services';

export const completeNewPassword = params => dispatch => {
    const { newPassword, name } = params;
    dispatch(AuthActions.completeNewPasswordBegin(params));
    return CompleteNewPasswordService({ newPassword, name })
        .then(response => {
            return dispatch(AuthActions.completeNewPasswordSuccess(params, response));
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthActions.completeNewPasswordFailure(params, message));
            throw message;
        });
};
