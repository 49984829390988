import askAnalyst from '../../askAnalyst/state/AskAnalyst.reducer';
import assessmentsReporting from '../../assessments/routes/AssessmentsReporting/state/AssessmentsReporting.reducer.js';
import auth from '../../authentication/state/Auth.reducer';
import { combineReducers } from 'redux';
import consentConfigDetails from '../../consent/state/ConfigDetails.reducer';
import dashboard from '../../dashboard/state/Dashboard.reducer';
import dataMap from '../../dataMapping/state/DataMap.reducer';
import dataStore from '../../dataStores/state/DataStore.reducer';
import db from './db/DB.reducer';
import dsarFormDetails from '../../dsarForms/state/FormDetails.reducer';
import dsarReporting from '../../dsarReporting/state/Reporting.reducer';
import dsarRequestDetails from '../../dsarRequests/state/requestDetails/RequestDetails.reducer';
import dsarRequestList from '../../dsarRequests/state/requestList/RequestList.reducer';
import dsarTypes from '../../dsarRequests/state/requestTypes/RequestTypes.reducer';
import litigationDetails from '../../litigation/state/LitigationDetails.reducer';
import locale from './locale/Locale.reducer';
import nav from './nav/Nav.reducer';
import notificationList from '../../notifications/state/NotificationList.reducer';
import organizationDetails from '../../organizations/state/OrganizationDetails.reducer';
import organizationsList from '../../organizations/state/OrganizationsList.reducer';
import quicksight from '../../reports/state/Quicksight.reducer';
import trusthubPageDetails from '../../trusthub/state/TrusthubPageDetails.reducer';
import uc from '../../uc/state/Uc.reducer';
import ui from './ui/UI.reducer';
import userDetails from '../../users/state/UserDetails.reducer';
import userpilot from './userpilot/Userpilot.reducer';

const rootReducer = combineReducers({
    askAnalyst,
    assessmentsReporting,
    auth,
    consentConfigDetails,
    dashboard,
    dataMap,
    dataStore,
    db,
    dsarFormDetails,
    dsarReporting,
    dsarRequestDetails,
    dsarRequestList,
    dsarTypes,
    litigationDetails,
    locale,
    nav,
    notificationList,
    organizationsList,
    organizationDetails,
    quicksight,
    trusthubPageDetails,
    uc,
    ui,
    userDetails,
    userpilot,
});

export default rootReducer;
