/* eslint react/prop-types: 0 */
import React from 'react';
import { SvgIcon } from '@mui/material';

import { white } from '../../colors';

export default function Templates(props) {
    // eslint-disable-next-line no-unused-vars
    return (
        <SvgIcon fontSize="large" viewBox="0 0 24 24" {...props}>
            <path
                d="M15.2,23H8.8C7,23,6,23,5.2,22.6c-0.8-0.4-1.4-1-1.7-1.7C3,20,3,19,3,17.2V6.8C3,5,3,4,3.4,3.2
                c0.4-0.8,1-1.4,1.7-1.7C6,1,7,1,8.8,1H14c0.3,0,0.5,0.1,0.7,0.3l6,6C20.9,7.5,21,7.7,21,8v9.2c0,1.8,0,2.8-0.4,3.6
                c-0.4,0.8-1,1.4-1.7,1.7C18,23,17,23,15.2,23z M8.8,3C7.3,3,6.5,3,6.1,3.2C5.7,3.4,5.4,3.7,5.2,4.1C5,4.5,5,5.3,5,6.8v10.4
                c0,1.5,0,2.3,0.2,2.7c0.2,0.4,0.5,0.7,0.9,0.9C6.5,21,7.3,21,8.8,21h6.4c1.5,0,2.3,0,2.7-0.2c0.4-0.2,0.7-0.5,0.9-0.9
                c0.2-0.4,0.2-1.2,0.2-2.7V9h-3.4c-0.7,0-1.1,0-1.5-0.2c-0.4-0.2-0.7-0.5-0.9-0.9C13,7.5,13,7.1,13,6.4V3H8.8z M15,4.4v2
                C15,6.7,15,7,15,7c0,0,0.3,0,0.6,0h2L15,4.4z M16,18H8c-0.6,0-1-0.4-1-1s0.4-1,1-1h8c0.6,0,1,0.4,1,1S16.6,18,16,18z M16,14H8
                c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1h8c0.6,0,1,0.4,1,1C17,13.6,16.6,14,16,14z M10,10H8c-0.6,0-1-0.4-1-1s0.4-1,1-1h2
                c0.6,0,1,0.4,1,1S10.6,10,10,10z"
                fill={white}
            />
        </SvgIcon>
    );
}
