import typography from '../typography';

export default {
    root: {
        ...typography.h6,
    },
    sortAction: {
        alignItems: 'center',
        verticalAlign: 'middle',
    },
    fixedHeader: {
        whiteSpace: 'nowrap',
    },
};
