import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    namedParameters,
    noParameters,
    singleParameter,
} from '../../helpers/ReduxHelpers';

import { DOMAIN } from './UI.constants';

export const events = {
    addPreventWarning: singleParameter,
    openDrawer: noParameters,
    closeDrawer: noParameters,
    toggleDrawer: noParameters,
    toggleSubmenu: namedParameters('submenu', 'force'),
    closeNotifications: noParameters,
    openNotifications: noParameters,
    closeUserDetails: noParameters,
    openUserDetails: noParameters,
    createSnack: namedParameters('message', 'options'),
    deleteSnack: singleParameter,
    deleteAllSnacks: noParameters,
    setRoadblock: singleParameter,
    clearRoadblock: noParameters,
    setUserIdle: singleParameter,
    setResultsView: namedParameters('path', 'view'),
};

export const asyncEvents = makeAsyncEvents(['async'], true);

export const types = makeActionTypes([...Object.keys(events), ...Object.keys(asyncEvents)], DOMAIN);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
    },
    types
);
