import { DOMAIN } from './Quicksight.actions';
import get from 'lodash/get';

export const getStore = state => get(state, DOMAIN) || {};

export const getLoadError = state => get(getStore(state), 'loadError', '');
export const getCategories = state => Object.keys(get(getStore(state), 'urls', {}));
export const getParametersForCategory = (state, category) =>
    get(getStore(state), ['parameters', category], {});
export const getUrlForCategory = (state, category) =>
    get(getStore(state), ['urls', category], null);

export const isLoading = state => Boolean(get(getStore(state), 'loading'));
