import { matchPath, useLocation } from 'react-router';

import AuthenticatedPaths from './AuthenticatedRoutes/AuthenticatedRoutes.paths';
import BrowsePaths from './BrowseRoutes/BrowseRoutes.paths';
import { TRIGGERS } from '../common/userpilot.triggers';
import UnauthenticatedPaths from './UnauthenticatedRoutes/UnauthenticatedRoutes.paths';
import { hasPermission as customerHasPermission } from '../authentication/state/customer/AuthCustomer.selectors';
import isEqual from 'lodash/isEqual';
import useBoundCallback from '../common/helpers/Hooks/useBoundCallback';
import { useLayoutEffect } from 'react';
import { useMemoCompare } from '../common/helpers/Hooks';
import { useNavigationBlock } from '../common/helpers/Hooks/useNavigationBlock';
import { useSelector } from 'react-redux';

const getMatchingBlockedPath = (blockedPaths, currentPath) =>
    blockedPaths.find(({ on, route }) => matchPath(currentPath, { path: route || on }));

const emptyArray = [];

export const useOsanoRoutes = props => {
    const {
        customerId,
        customerToken,
        documentTitle,
        fetchCustomerDetails,
        isAuthenticated,
        permissionsDetermined,
        triggerUserPilot,
    } = props;
    const location = useLocation();
    const getUserpilotTriggers = useBoundCallback(
        (permissionsDetermined, state) => {
            return permissionsDetermined
                ? Object.values(TRIGGERS)
                      .filter(({ type }) => type === 'route')
                      .filter(
                          ({ action, on }) =>
                              /*
                               *  Only display the trigger if the _Customer_ does not have the
                               *  permission, not if the user is blocked due to an invalid role
                               */
                              !customerHasPermission(state, {
                                  toPerform: action,
                                  on,
                              })
                      )
                : emptyArray;
        },
        [permissionsDetermined]
    );
    const blockedPaths = useMemoCompare(useSelector(getUserpilotTriggers), isEqual);
    const shouldAllowNavigation = useBoundCallback(
        (hasCustomer, triggerUserPilot, pathTriggers, location) => {
            const trigger = getMatchingBlockedPath(pathTriggers, location.pathname);
            if (trigger) {
                const { id, redirect } = trigger;
                id && triggerUserPilot(id);
                /*
                 *  We should be performing a prevent navigation on Userpilot routes
                 *  so the only time this should trigger is on direct link, or
                 *  customer change to a prevented route...meaning we forward them to
                 *  the dashboard or sign-in.
                 */
                if (!redirect) {
                    return true;
                }
                const match = matchPath(location.pathname, { path: redirect });
                return isAuthenticated
                    ? (match && match.url) ||
                          (hasCustomer && AuthenticatedPaths.DASHBOARD) ||
                          BrowsePaths.ACCOUNT
                    : UnauthenticatedPaths.SIGN_IN;
            }
            // If there's no block, return undefined
            return undefined;
        },
        [customerToken !== false, triggerUserPilot, blockedPaths]
    );

    useNavigationBlock({
        enabled: !!blockedPaths.length && customerId,
        onNavigate: shouldAllowNavigation,
    });

    useLayoutEffect(() => {
        document.title = documentTitle;
    }, [documentTitle]);

    useLayoutEffect(() => {
        customerToken && fetchCustomerDetails(customerId);
    }, [customerToken, customerId]);

    return {
        isOauthConnection: matchPath(location.pathname, {
            path: AuthenticatedPaths.CONNECT_OAUTH,
        }),
        isDataMappingConnection: matchPath(location.pathname, {
            path: AuthenticatedPaths.CONNECT_DATA_MAPPING,
        }),
        isUcIntegrationConnection: matchPath(location.pathname, {
            path: AuthenticatedPaths.CONNECT_UC_INTEGRATION,
        }),
        isLogoutPage: matchPath(location.pathname, {
            path: AuthenticatedPaths.LOGOUT,
        }),
    };
};
