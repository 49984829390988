import { namedParameters, withMeta } from './parameterize';

import camelCase from 'lodash/camelCase';

export const makeAsyncEvents = (events, useMeta = false) => {
    if (!Array.isArray(events)) {
        throw new Error(
            `Redux Utils :: 'makeAsyncEvents' events must be an array of type 'String'`
        );
    }
    return events.reduce((constructedEvents, value) => {
        if (typeof value !== 'string') {
            return constructedEvents;
        }
        return {
            ...constructedEvents,
            [`${camelCase(value)}Begin`]: useMeta
                ? withMeta(namedParameters('params'))
                : namedParameters('params'),
            [`${camelCase(value)}Success`]: useMeta
                ? withMeta(namedParameters('params', 'response'))
                : namedParameters('params', 'response'),
            [`${camelCase(value)}Failure`]: useMeta
                ? withMeta(namedParameters('params', 'error'))
                : namedParameters('params', 'error'),
        };
    }, {});
};

export default makeAsyncEvents;
