import createSchema, { SCHEMA_OPTIONS, getPreferenceKeyPath } from './Notifications.schema';
import { fetchUserNotifications, updateUserNotifications } from '/b2b/notifications/services';

import AuthUserActions from '/b2b/authentication/state/user/AuthUser.actions';
import castArray from 'lodash/castArray';
import get from 'lodash/get';
import schema from '/b2b/users/state/db/User.schema';
import set from 'lodash/set';

const formatSettingsWithPreferences = settings => {
    const notificationSettings = settings.reduce(
        (newSettings, { eventType, resourceType, frequency }) => (
            (newSettings[`${resourceType}.${eventType}`] = frequency), newSettings
        ),
        {}
    );
    // Need to loop through settings to see if users have any custom preferences for notifications
    for (const setting of settings) {
        if (setting.preferences) {
            for (const preference of Object.keys(setting.preferences)) {
                notificationSettings[
                    `preferences.${setting.resourceType}.${setting.eventType}.${preference}`
                ] = setting.preferences[preference];
            }
        }
    }

    return notificationSettings;
};

export const fetchUserNotificationSettings = params => dispatch => {
    const { userId } = params;
    const meta = { schema, id: userId };
    dispatch(AuthUserActions.fetchDetailsBegin(meta, params));

    return fetchUserNotifications()
        .then(settings => {
            const notificationSettings = formatSettingsWithPreferences(settings);
            dispatch(
                AuthUserActions.fetchDetailsSuccess(meta, params, {
                    id: userId,
                    notificationSettings,
                })
            );
            return notificationSettings;
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthUserActions.fetchDetailsFailure(meta, params, message));
            throw message;
        });
};

export const updateUserNotificationSettings = params => dispatch => {
    const {
        userId,
        settings: { preferences, ...notificationSettings },
    } = params;
    const prefsSchema = createSchema(
        Object.keys(SCHEMA_OPTIONS).filter(key => key.includes('.preferences.'))
    );
    const preferenceLookup = {};
    for (const [key, scheme] of Object.entries(prefsSchema)) {
        const [resourceType, eventType, preference] = getPreferenceKeyPath(key);
        const value = get(preferences, [resourceType, eventType, preference], undefined);
        if (value !== undefined) {
            // If a depends clause is present, check to see that the depends value validates
            if (scheme.depends) {
                let skip = false;
                for (const [dependsOn, ifIsValue] of Object.entries(scheme.depends)) {
                    const dependsValue = get(preferences, getPreferenceKeyPath(dependsOn), false);
                    if (!castArray(ifIsValue).includes(dependsValue)) {
                        // If the depends value is not valid, skip this preference
                        skip = true;
                    }
                }
                if (skip) {
                    continue;
                }
            }
            set(preferenceLookup, [resourceType, eventType, preference], value);
        }
    }
    const settingsArray = Object.entries(notificationSettings).reduce(
        (accumulator, [resourceType, value]) => {
            Object.entries(value).forEach(([eventType, frequency]) => {
                accumulator.push({
                    eventType,
                    resourceType,
                    frequency,
                    preferences: get(preferenceLookup, [resourceType, eventType], null),
                });
            });
            return accumulator;
        },
        []
    );

    const meta = { schema, id: userId };
    dispatch(AuthUserActions.updateBegin(meta, params));

    return updateUserNotifications(settingsArray)
        .then(updatedSettings => {
            const notificationSettings = formatSettingsWithPreferences(updatedSettings);
            dispatch(
                AuthUserActions.updateSuccess(meta, params, {
                    id: userId,
                    notificationSettings,
                })
            );
            return notificationSettings;
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthUserActions.updateFailure(meta, params, message));
            throw message;
        });
};
