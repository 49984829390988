export default theme => ({
    root: {
        position: 'relative',
    },
    form: {
        margin: 'auto',
        padding: 0,
    },
    group: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    groupLabel: {
        paddingLeft: theme.spacing(2),
    },
    field: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
    textField: {
        width: '320px',
        maxWidth: '100%',
        marginRight: theme.spacing(3),
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    loadingWrapper: {
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,0.9)',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: theme.zIndex.loadingWrapper,
    },
});
