import AuthActions from '/b2b/authentication/state/Auth.actions';
import { resetPassword as ResetPasswordService } from '/b2b/authentication/services';

export const resetPassword = params => dispatch => {
    dispatch(AuthActions.resetPasswordBegin(params));
    return ResetPasswordService(params)
        .then(details => {
            const { cognitoUser = {} } = details;
            dispatch(AuthActions.resetPasswordSuccess(params, cognitoUser));
            return dispatch(AuthActions.signInSuccess(params, { cognitoUser }));
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthActions.resetPasswordFailure(params, message));
            throw message;
        });
};
