import { countryCodeLookup } from './Address';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

export const formatPhoneNumber = value => value.replace(/[^\d/+]/g, '');

export const validatePhone = (value, country = 'US') => {
    const phoneNumber = parsePhoneNumberFromString(`${value}`, countryCodeLookup(country));
    // Check that the phone number has a country (not an 800 number) and it's valid
    return phoneNumber?.country && phoneNumber.isValid();
};
