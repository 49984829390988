import PropTypes from 'prop-types';
import React from 'react';

const Permission = ({ children, component: Component, ...remain }) =>
    Component ? <Component {...remain}>{children}</Component> : null;

Permission.propTypes = {
    children: PropTypes.node,
    component: PropTypes.any,
};

export default Permission;
