import { emphasize } from '@mui/material/styles';

export default theme => ({
    root: {
        flex: '0 1 auto',
        position: 'relative',
        width: '100%',
    },
    chipRoot: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.muted,
    },
    chipFocused: {
        color: theme.palette.common.muted,
        backgroundColor: emphasize(theme.palette.common.muted, 0.08),
    },
    inputRoot: {
        minWidth: '10rem',
        '&:focus': {
            outline: 'none',
        },
    },
});
