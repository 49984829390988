/* eslint-disable camelcase */
import AuthActions from '../../../state/Auth.actions';
import { formatPhoneNumber } from '/b2b/common/helpers/Phone';
import { updateUserAttributes } from '../../../services';

export const update = params => dispatch => {
    const { phone } = params;
    dispatch(AuthActions.updateUserAttributesBegin(params));

    return updateUserAttributes({
        phone: formatPhoneNumber(phone),
        phone_number_verified: false,
    })
        .then(response => {
            dispatch(AuthActions.updateUserAttributesSuccess(params, response));

            return dispatch(
                AuthActions.selectMfa({ ...params, mfaType: 'SMS', isSMSEnabled: true })
            );
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthActions.updateUserAttributesFailure(params, error));

            throw message;
        });
};
