export default theme => ({
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center',
        },
    },
    signUp: {
        marginTop: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    signUpUrl: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
});
