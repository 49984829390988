import { extendPermissions, formatUIAllowedList } from '/b2b/common/services/format';

import AuthActions from '/b2b/authentication/state/Auth.actions';
import AuthCustomerActions from '/b2b/authentication/state/customer/AuthCustomer.actions';
import AuthUserActions from '/b2b/authentication/state/user/AuthUser.actions';
import CustomerSchema from '/b2b/common/state/db/customer/Customer.schema';
import DBActions from '/b2b/common/state/db/DB.actions';
import UserSchema from '/b2b/users/state/db/User.schema';
import fromState from '/b2b/common/state/selectors';

export const changeCustomerPlan = customerPlan => (dispatch, getState) => {
    const state = getState();
    const customerId = fromState.Auth.customer.getId(state);
    const permissions = extendPermissions(formatUIAllowedList(customerPlan));
    dispatch(
        DBActions.update(
            { id: customerId, schema: CustomerSchema, update: 'replace' },
            { customerPlan, permissions }
        )
    );
    return dispatch(AuthCustomerActions.changeCustomerPlan(permissions));
};

export const changeUserRole = roleIds => (dispatch, getState) => {
    const state = getState();
    const userId = fromState.Auth.user.getId(state);
    // Formulate role ids for current customer
    dispatch(DBActions.update({ id: userId, schema: UserSchema }, { roles: roleIds }));
    return dispatch(AuthActions.changeUserRole(roleIds));
};

export const setFeatureFlags = flags => dispatch =>
    dispatch(AuthUserActions.setFeatureFlags(flags));
