import Component from './NotificationStatus.component';
import { connect } from 'react-redux';
import fromState from '../../common/state/selectors';
import { pollNotifications } from './NotificationStatus.commands';
const mapStateToProps = state => {
    return {
        unreadNotifications: fromState.Auth.user.notifications.getResultCount(state),
    };
};

const mapDispatchToProps = {
    pollNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
