import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import styles from './StyleButton.styles';

const useStyles = makeStyles(styles);

const StyleButton = props => {
    const classes = useStyles(props);
    const { active: propActive } = props;
    const [active, setActive] = useState(props.active);
    const [activeClassName, setActiveClassName] = useState('');

    useEffect(() => {
        setActiveClassName(propActive ? classes.active : null);
    }, [propActive]);

    const onToggle = e => {
        setActive(!active);
        e.preventDefault();
        props.onToggle(props.style);
    };

    return (
        <Box className={classes.iconWrapper}>
            <Box className={activeClassName} onMouseDown={onToggle}>
                {props.icon}
            </Box>
        </Box>
    );
};

StyleButton.propTypes = {
    onToggle: PropTypes.func,
    label: PropTypes.string,
    style: PropTypes.string,
    icon: PropTypes.object,
    active: PropTypes.bool,
};

export default StyleButton;
