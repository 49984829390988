export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        flex: '1 1 auto',
        position: 'relative',
        zIndex: 0,
    },
    vertical: {
        flexDirection: 'row',
    },
    hidden: {
        height: 0,
        overflow: 'hidden',
        visibility: 'hidden',
    },
    appBar: {
        flex: '0 1 auto',
        position: 'relative',
        zIndex: 150,
    },
    appBarVertical: {
        width: 'auto',
    },
    tabs: {
        flex: '1 1 auto',
        backgroundColor: theme.palette.tertiary.main,
    },
    verticalTabs: {
        backgroundColor: 'transparent',
        flex: '1 1 auto',
        maxWidth: 'max-content',
        minWidth: '10rem',
    },
    horizontalTabs: {},
    'MuiTab-wrapper': {
        alignItems: 'stretch',
    },
    'MuiTab-root': {
        color: theme.palette.background.paper,
        padding: theme.spacing(1, 3),
        '&.Mui-selected': {
            color: theme.palette.tertiary.main,
            backgroundColor: theme.palette.background.paper,
        },
    },
    'MuiTabs-indicator': {
        backgroundColor: theme.palette.background.paper,
        display: 'none',
    },
    'MuiTabs-flexContainerVertical': {
        '& $MuiTab-wrapper': {
            alignItems: 'flex-start',
        },
    },
    panels: {
        flex: '1 1 auto',
        backgroundColor: theme.palette.background.paper,
    },
    activeTab: {
        fontWeight: 'bold',
    },
    info: {
        alignItems: 'center',
        justifyContent: 'stretch',
        display: 'flex',
        margin: theme.spacing(2, 0),
    },
    avatar: {
        flex: '0 1 auto',
        marginRight: theme.spacing(2),
        height: theme.spacing(8),
        width: theme.spacing(8),
    },
    title: {
        flex: '1 1 auto',
    },
});
