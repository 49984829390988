import React, { useEffect, useRef, useState } from 'react';

import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';

const FallbackAvatar = props => {
    const { src, fallback, imgProps, children, ...remain } = props;

    const [avatarImage, setAvatarImage] = useState(src);
    const avatar = useRef(null);
    const onAvatarLoadError = function () {
        if (avatar.current) {
            if ('naturalHeight' in avatar.current) {
                if (avatar.current.naturalHeight + avatar.current.naturalWidth === 0) {
                    avatarImage === fallback ? setAvatarImage('') : setAvatarImage(fallback);
                    return;
                }
            } else if (avatar.current.width + avatar.current.height === 0) {
                avatarImage === fallback ? setAvatarImage('') : setAvatarImage(fallback);
                return;
            }
        }
    };
    useEffect(() => {
        setAvatarImage(src);
    }, [src]);

    return (
        <Avatar
            {...remain}
            src={avatarImage ? avatarImage : undefined}
            imgProps={{
                ...imgProps,
                onLoad: onAvatarLoadError,
                onError: onAvatarLoadError,
                ref: avatar,
            }}
        >
            {avatarImage ? null : children}
        </Avatar>
    );
};

FallbackAvatar.propTypes = {
    children: PropTypes.any,
    fallback: PropTypes.string,
    src: PropTypes.string,
    imgProps: PropTypes.object,
};

export default FallbackAvatar;
