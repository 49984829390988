import { types as AuthActionTypes } from '../../authentication/state/Auth.actions';
import { types as AuthCustomerActionTypes } from '../../authentication/state/customer/AuthCustomer.actions';
import { types as NavActionTypes } from '../state/nav/Nav.actions';
import analytics from '../analytics';
import fromState from '../state/selectors';

export default ({ getState }) =>
    next =>
    action => {
        const previousCustomerId = fromState.Auth.customer.getId(getState());
        const result = next(action);
        const state = getState();
        const { type } = action;

        if (type) {
            switch (type) {
                case AuthCustomerActionTypes.fetchDetailsSuccess: {
                    const newCustomerId = action?.payload?.params?.customerId;

                    // avoid duplicate calls by checking for existance of customerId param
                    if (newCustomerId) {
                        const userEmail = fromState.Auth.user.getId(state);
                        const {
                            email,
                            firstName,
                            lastName,
                            username,
                            user_id: userId = username,
                        } = fromState.DB.user.getEntityById(state, userEmail);

                        const customerId = fromState.Auth.customer.getId(state);
                        const {
                            custUserHash,
                            name: customerName,
                            subscription: { planId: customerPlanId },
                        } = fromState.DB.customer.getEntityById(state, customerId);

                        // Always get the title in english for analytics
                        const title = fromState.UI.getDocumentTitle(state, 'en');
                        const eventData = {
                            email,
                            customerName,
                            customerPlanId,
                            custUserHash,
                            firstName,
                            lastName,
                            userId,
                        };
                        analytics.identify(userId, { ...eventData, customerId });
                        analytics.group(customerId, eventData);
                        analytics.page(title);
                    }
                    break;
                }

                case NavActionTypes.changeCustomer: {
                    const customerId = fromState.Auth.customer.getId(state);
                    // switching customer, reset analytics
                    if (previousCustomerId && previousCustomerId !== customerId) {
                        analytics.reset();
                    }
                    break;
                }

                case NavActionTypes.routeChanged: {
                    const {
                        payload: { action: route },
                    } = action;
                    // don't track initial page load here, need to identify and group first
                    if (route !== 'LOAD') {
                        // Always get the title in english for analytics
                        const title = fromState.UI.getDocumentTitle(state, 'en');
                        analytics.page(title);
                    }
                    break;
                }

                case AuthActionTypes.logout: {
                    analytics.reset();
                    break;
                }
            }
        }
        return result;
    };
