export default theme => ({
    root: {
        flex: '1 1 100%',
        width: '100%',
        '& $helperText': {
            color: theme.palette.text.primary,
        },
        '&:first-child': {
            marginTop: theme.spacing(1),
        },
        '&:last-child': {
            marginBottom: 0,
        },
    },
    errorText: {
        color: theme.palette.error.main,
        marginLeft: 0,
        marginRight: 0,
        whiteSpace: 'pre-line',
    },
    helperText: {
        color: theme.palette.text.primary,
        marginLeft: 0,
        marginRight: 0,
        whiteSpace: 'pre-line',
    },
});
