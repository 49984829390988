import Actions from './AuthUser.actions';
import { fetchMyUserDetails } from '/b2b/users/services';
import { getId } from './AuthUser.selectors';
import schema from '/b2b/users/state/db/User.schema';

export const fetchDetails =
    (providedParams = {}) =>
    (dispatch, getState) => {
        const userId = getId(getState());
        const params = { ...providedParams, userId };
        const meta = { schema, id: userId };
        dispatch(Actions.fetchDetailsBegin(meta, params));

        return fetchMyUserDetails(userId)
            .then(details => {
                dispatch(Actions.fetchDetailsSuccess(meta, params, details));
                return details;
            })
            .catch(error => {
                const { message } = error;
                dispatch(Actions.fetchDetailsFailure(meta, params, message));
            });
    };
