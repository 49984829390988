import { createSchema } from '/b2b/common/helpers/Schema';

export default createSchema({
    firstName: {
        label: 'users.firstName',
    },
    lastName: {
        label: 'users.lastName',
    },
    email: {
        disabled: true,
        label: 'common.email',
    },
});
