export default theme => ({
    root: {
        height: '100%',
        position: 'relative',
    },
    portletContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    progressWrapper: {
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,0.9)',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 125,
    },
    portletFooter: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    details: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(4, 4, 0),
    },
    summary: {},
    legends: {},
    legend: {
        display: 'flex',
        alignItems: 'center',
        '& + &': {
            marginTop: theme.spacing(1),
        },
    },
    legendColor: {
        display: 'inline-block',
        height: '18px',
        width: '18px',
        borderRadius: '3px',
        marginRight: theme.spacing(1),
    },
    chart: {
        height: '232px',
        padding: theme.spacing(3),
    },
});
