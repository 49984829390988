import { Link, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';

import Locale from '/b2b/common/components/Locale';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import UnauthenticatedPaths from '/b2b/routing/UnauthenticatedRoutes/UnauthenticatedRoutes.paths';
import ValidationForm from '/b2b/common/components/ValidationForm';
import makeStyles from '@mui/styles/makeStyles';
import schema from './SignUpResend.schema';
import styles from './SignUpResend.styles';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';

const useStyles = makeStyles(styles);

const SignUpResend = props => {
    const { email, isLoading, resetAuthState, resetTemporaryPassword } = props;
    const classes = useStyles(props);
    const [resent, setResent] = useState('');
    const onSubmit = useBoundCallback(({ email, password }) => {
        return resetTemporaryPassword(email, password).then(({ message = true }) =>
            setResent(message)
        );
    }, []);

    const values = useMemo(() => {
        return {
            email,
        };
    }, [email]);

    return (
        <div className={classes.content}>
            <div className={classes.contentBody}>
                <ValidationForm
                    title="password.expired"
                    subtitle="password.newTemporary"
                    allowSubmit={!isLoading}
                    values={values}
                    schema={schema}
                    onSubmit={onSubmit}
                    isLoading={isLoading}
                    submitText="password.reset"
                >
                    <Typography color={resent === true ? 'primary' : 'error'} variant="body2">
                        {resent === true ? <Locale path="password.sent" /> : resent}
                    </Typography>
                    <Typography className={classes.signUpResend} variant="body1">
                        <Link
                            component={RouterLink}
                            className={classes.signUpResendUrl}
                            onClick={resetAuthState}
                            to={UnauthenticatedPaths.SIGN_IN}
                        >
                            <Locale path="common.signIn" />
                        </Link>
                    </Typography>
                </ValidationForm>
            </div>
        </div>
    );
};

SignUpResend.propTypes = {
    className: PropTypes.string,
    email: PropTypes.string,
    isLoading: PropTypes.bool,
    resetAuthState: PropTypes.func.isRequired,
    resetTemporaryPassword: PropTypes.func.isRequired,
};

export default SignUpResend;
