import { types as ActionTypes, asyncEvents } from './Quicksight.actions';
import { errorReducer, loadingReducer } from '/b2b/common/helpers/ReduxHelpers';

import { combineReducers } from 'redux';
import genericReducer from '/b2b/common/helpers/Redux/genericReducer';
import reduceReducers from 'reduce-reducers';

export const initialState = {
    loading: false,
    loadError: null,
    parameters: {},
    urls: {},
};

const loadTypes = Object.keys(asyncEvents).map(key => ActionTypes[key]);

export const initState = state => {
    return {
        ...state,
        ...initialState,
    };
};
export default reduceReducers(
    initialState,
    combineReducers({
        loading: loadingReducer(initialState.loading, loadTypes),
        loadError: errorReducer(initialState.loadError, loadTypes),
        parameters: genericReducer(initialState.parameters, {
            [ActionTypes.clearParameters]: (state, category) => {
                let newState = state;
                if (state[`${category}`]) {
                    newState = {
                        ...state,
                    };
                    delete newState[`${category}`];
                }
                return newState;
            },
            [ActionTypes.clearParameter]: (state, { category, field }) => {
                let newState = state;
                const newCategory = state[`${category}`] || {};
                if (newCategory[`${field}`]) {
                    delete newCategory[`${field}`];
                    newState = {
                        ...state,
                        [`${category}`]: { ...state[`${category}`], [`${field}`]: [] },
                    };
                }
                return newState;
            },
            [ActionTypes.deleteParameter]: (state, { category, field }) => {
                let newState = state;
                const newCategory = state[`${category}`] || {};
                if (newCategory[`${field}`]) {
                    delete newCategory[`${field}`];
                    newState = {
                        ...state,
                        [`${category}`]: newCategory,
                    };
                }
                return newState;
            },
            [ActionTypes.updateParameter]: (state, { category, field, value }) => {
                return {
                    ...state,
                    [`${category}`]: { ...state[`${category}`], [`${field}`]: value },
                };
            },
        }),
        urls: genericReducer(initialState.urls, {
            [ActionTypes.fetchUrlSuccess]: (state, { params: { category }, response: url }) => ({
                ...state,
                [`${category}`]: url || null,
            }),
            [ActionTypes.fetchUrlFailure]: (state, { params: { category } }) => ({
                ...state,
                [`${category}`]: '/test.html',
            }),
        }),
    }),
    genericReducer(initialState, {
        [ActionTypes.reload]: initState,
        [ActionTypes.event]: (state, { event, metadata } = {}) => {
            const { eventLevel, eventName, message } = event || {};
            const { category } = metadata || {};
            switch (eventLevel) {
                case 'ERROR': {
                    switch (eventName) {
                        case 'FRAME_NOT_CREATED': // invoked when the creation of the iframe element failed
                        case 'NO_BODY': // invoked when there is no `body` element in the hosting html
                        case 'NO_CONTAINER': // invoked when the experience container is not found
                        case 'INVALID_CONTAINER': // invoked when the container provided is not a valid DOM node
                        case 'NO_URL': // invoked when no url is provided in the frameOptions
                        case 'INVALID_URL': // invoked when the url provided is not a valid url for the experience
                        case 'NO_FRAME_OPTIONS': // invoked when frameOptions property is not populated
                        case 'INVALID_FRAME_OPTIONS': // invoked when the frameOptions value is not object type
                            // Handle error
                            return {
                                ...state,
                                loading: false,
                                loadError: message,
                            };
                    }
                    break;
                }
                case 'INFO': {
                    switch (eventName) {
                        case 'FRAME_STARTED': // invoked just before the iframe is created
                        case 'FRAME_MOUNTED': // invoked after the iframe is appended into the experience container
                        case 'FRAME_LOADED': // invoked after iframe element emited the `load` event
                            break;
                    }
                    break;
                }
                case 'WARN': {
                    switch (eventName) {
                        case 'UNRECOGNIZED_CONTENT_OPTIONS': // invoked when the content options for the experience contain unrecognized properties
                        case 'UNRECOGNIZED_FRAME_OPTIONS': // invoked when the frame options for the experience contain unrecognized properties
                        case 'UNRECOGNIZED_EVENT_TARGET': // invoked when a message with unrecognized event target is received
                            break;
                    }
                    break;
                }
                default: {
                    switch (eventName) {
                        case 'ERROR_OCCURRED': {
                            const { errorCode } = message || {};
                            switch (errorCode) {
                                case 'Forbidden':
                                case 'Unauthorized': {
                                    if (category) {
                                        const urls = { ...state.urls };
                                        // Delete the existing url to request a new one
                                        delete urls[`${category}`];
                                        return {
                                            ...state,
                                            urls,
                                        };
                                    }
                                }
                            }
                            break;
                        }
                        case 'CONTENT_LOADED': // embedded experience is fully loaded
                        case 'PARAMETERS_CHANGED': // provided parameters changed
                        case 'SELECTED_SHEET_CHANGED': // Changed sheet
                        case 'SIZE_CHANGED': // sizing changed
                        case 'MODAL_OPENED': // modal opened
                            break;
                    }
                    break;
                }
            }
            return state;
        },
    })
);
