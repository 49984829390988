import { DOMAIN } from './AuthUserNotifications.constants';
import get from 'lodash/get';

export const getStore = state => get(state, DOMAIN) || {};

export const getUrgentNotifications = state => getStore(state).urgentNotifications;
export const getResults = state =>
    get(getStore(state), 'markingAll') ? [] : get(getStore(state), 'results') || [];
export const getResultCount = state =>
    get(getStore(state), 'markingAll') ? 0 : get(getStore(state), 'resultCount') || 0;
export const getError = state => getStore(state).error;

export const getRequestParams = state => {
    const { offset, limit, query } = getStore(state);
    return {
        offset,
        limit,
        query,
    };
};

export const isLoading = state => getStore(state).loading;
