import { confirmSignUp, resendSignUpCode } from './SignUpConfirm.commands';

import Component from './SignUpConfirm.component';
import { connect } from 'react-redux';
import fromState from '../../../common/state/selectors';

const mapStateToProps = state => {
    return {
        username: fromState.Auth.getUsername(state),
        code: '',
        resent: fromState.Auth.getSignUpCodeResent(state) || false,
        isLoading: fromState.Auth.isLoading(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSignUpConfirm: params => dispatch(confirmSignUp(params)),
        onResendSignUpCode: params => dispatch(resendSignUpCode(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
