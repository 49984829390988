import { parseColor } from '../../helpers/Color';

export default theme => ({
    root: {
        display: 'table',
        width: '100%',
        zIndex: 0,
    },
    vertical: {
        display: 'flex',
        flex: '1',
        flexDirection: 'row',
    },
    hidden: {
        height: 0,
        overflow: 'hidden',
        visibility: 'hidden',
    },
    appBar: {
        flex: '0 1 auto',
        position: 'relative',
        zIndex: 150,
    },
    appBarVertical: {
        width: 'auto',
    },
    verticalTabs: {
        bottom: 0,
        flex: '0 1 auto',
        maxWidth: 'max-content',
        minWidth: '12rem',
        position: 'sticky',
        top: 0,
        marginRight: theme.spacing(1),
    },
    tooltip: {
        whiteSpace: 'pre-line',
    },
    horizontalTabs: {},
    'MuiTab-wrapper': {
        alignItems: 'stretch',
    },
    'MuiTab-root': {
        color: theme.palette.background.paper,
        padding: theme.spacing(1, 3),
        '&.Mui-selected': {
            color: theme.palette.tertiary.main,
            backgroundColor: theme.palette.background.paper,
        },
    },
    'MuiTabs-indicator': {
        backgroundColor: theme.palette.background.paper,
        display: 'none',
    },
    'MuiTabs-flexContainerVertical': {
        '& $MuiTab-wrapper': {
            alignItems: 'flex-start',
        },
    },
    panel: {
        display: 'grid',
        height: '100%',
        position: 'relative',
    },
    panels: {
        alignSelf: 'flex-start',
        flex: '1 1 auto',
        marginBottom: theme.spacing(2),
        position: 'relative',
    },
    activeTab: {
        fontWeight: 'bold',
    },
    info: {
        alignItems: 'center',
        justifyContent: 'stretch',
        display: 'flex',
        margin: theme.spacing(2, 0),
    },
    avatar: {
        flex: '0 1 auto',
        marginRight: theme.spacing(2),
        height: theme.spacing(8),
        width: theme.spacing(8),
    },
    title: {
        flex: '1 1 auto',
    },
    tab: {},
    tabVertical: {
        alignItems: 'flex-start',
        padding: theme.spacing(1.5, 2),
        '&.Mui-selected': {
            color: theme.palette.tertiary.main,
            backgroundColor: `rgba(${parseColor(theme.palette.secondary.main)}, 0.08)`,
        },
        width: '100%',
    },
    mobileTabs: {
        left: theme.spacing(1),
        marginBottom: theme.spacing(2.5),
        position: 'sticky',
        width: `calc(100vw - ${theme.spacing(6)})`,
    },
});
