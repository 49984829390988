import { createBrowserHistory } from 'history';

const History = createBrowserHistory();

const navigationBlockers = [];
const register = (callback, index = navigationBlockers.length) => {
    if (!navigationBlockers.includes(callback)) {
        navigationBlockers.splice(index, 0, callback);
    }
    return navigationBlockers.indexOf(callback);
};

const unregister = callback => {
    const idx = navigationBlockers.indexOf(callback);
    if (idx >= 0) {
        navigationBlockers.splice(idx, 1);
    }
    return idx;
};

const originalHistoryBlock = History.block;
const block = (location, action) => {
    // Find the first callback that will block
    const allowNavigation = navigationBlockers.reduce(
        (shouldAllow, callback) =>
            typeof shouldAllow === 'string' || shouldAllow === false
                ? shouldAllow
                : callback(location, action),
        true
    );
    return allowNavigation;
};

originalHistoryBlock(block);

History.block = callback => {
    register(callback);
    return () => unregister(callback);
};

export default History;
