import Component from './SignInRoutes.component';
import UnauthenticatedPaths from '../../../routing/UnauthenticatedRoutes/UnauthenticatedRoutes.paths';
import { connect } from 'react-redux';
import fromState from '../../../common/state/selectors';
import { matchPath } from 'react-router-dom';

const mapStateToProps = state => {
    return {
        needsSignInConfirmed:
            !matchPath(location.pathname, {
                path: `${UnauthenticatedPaths.SIGN_IN_CONFIRM}`,
            }) && fromState.Auth.needsSignInConfirmed(state),
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
