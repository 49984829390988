import { denormalize } from 'normalizr';
import get from 'lodash/get';
import { getStore as getDB } from '../../../common/state/db/DB.selectors';
import schema from './Notification.schema';

export const DOMAIN = `db.${schema.key}`;

export const getStore = state => get(state, DOMAIN) || {};

export const getEntityById = (state, id) => denormalize(id, schema, getDB(state)) || {};
export const getEntitiesForIds = (state, ids) => denormalize(ids, [schema], getDB(state));

export const getEntityFieldById = (state, { id, field }) =>
    get(getStore(state), [id, field]) || null;

export const getUnreadIds = state =>
    Object.entries(getStore(state))
        .filter(([, { read }]) => !read)
        .map(([id]) => id);

export const getReadIds = state =>
    Object.entries(getStore(state))
        .filter(([, { read }]) => !!read)
        .map(([id]) => id);

export const isIdInDB = (state, id) => get(getStore(state), id, false);
