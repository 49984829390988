import { types as NavActionTypes } from '../../nav/Nav.actions';
import { genericReducer } from '../../../helpers/ReduxHelpers';
import { normalize } from 'normalizr';
import schema from './Customer.schema';

export const initialState = {};

export const defaultError = 'An error occurred during authorization';

export default genericReducer(initialState, {
    [NavActionTypes.changeCustomer]: (state, details) => {
        const normalizedData = normalize(details, schema);
        const { result: id } = normalizedData;
        const { [schema.key]: entities = {} } = normalizedData.entities || {};

        return {
            ...state,
            [schema.key]: {
                ...state[schema.key],
                [id]: {
                    ...(state[schema.key] || {})[id],
                    ...entities[id],
                },
            },
        };
    },
});
