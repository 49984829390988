import {
    fetchJSON,
    formatFilterParams,
    formatPagingParams,
    handleError,
} from '../common/services/helpers';
import {
    formatDataStore,
    formatDataStoreAgentResults,
    formatDataStoreClassificationResults,
    formatDataStoreConnectionSamples,
    formatDataStoreFieldResults,
    formatDataStoreRelationshipsResults,
    formatDataStoreRequestActionResults,
    formatDataStoreResults,
    formatDefaultRequestTypeActionResults,
    formatFieldClassificationStats,
    formatVendorRelationships,
} from './format';

import API from '@aws-amplify/api';
import { DATA_STORE_CONNECTION_STATUS } from './constants';
import { apiName } from '@osano-b2b';
import { promisifiedFileReader } from '/b2b/common/services/helpers';
import startCase from 'lodash/startCase';

export const getConnectionStatusText = connectionStatus =>
    DATA_STORE_CONNECTION_STATUS[connectionStatus] || startCase(connectionStatus || '');

export const isSyncing = connectionStatus => {
    switch (DATA_STORE_CONNECTION_STATUS[connectionStatus]) {
        case DATA_STORE_CONNECTION_STATUS.IN_PROGRESS:
        case DATA_STORE_CONNECTION_STATUS.QUEUED: {
            return true;
        }
    }
    return false;
};

export const fetchRequestActionsTaken = () => {
    const options = {};
    return API.get(apiName, '/data-mapping/request-actions-taken', options).catch(handleError);
};

export const fetchDataStores = params => {
    const options = {
        queryStringParameters: {
            ...formatFilterParams(params),
            ...formatPagingParams(params),
        },
    };
    return API.get(apiName, `/data-stores`, options)
        .catch(handleError)
        .then(formatDataStoreResults);
};

export const fetchDataStoreSearch = params => {
    const options = {
        queryStringParameters: {
            ...formatFilterParams(params),
            ...formatPagingParams(params),
        },
    };
    return API.get(apiName, `/data-store-search`, options)
        .catch(handleError)
        .then(formatDataStoreResults);
};

export const fetchDataStoreAgents = params => {
    const { feature } = params;
    const options = {
        queryStringParameters: {
            ...formatPagingParams(params),
            ...formatFilterParams(params),
            feature,
        },
    };
    return API.get(apiName, `/data-mapping/agents`, options)
        .catch(handleError)
        .then(formatDataStoreAgentResults);
};

export const fetchDataStoreById = dataStoreId => {
    const options = {};
    return API.get(apiName, `/data-stores/${dataStoreId}`, options)
        .catch(handleError)
        .then(formatDataStore);
};

export const fetchDataStoreStatusById = connectionId => {
    return fetchDataStoreById(connectionId);
};

export const fetchDataStoreFields = (connectionId, params) => {
    const options = {
        queryStringParameters: {
            ...formatPagingParams(params),
            ...formatFilterParams(params),
        },
    };
    return API.get(apiName, `/data-stores/${connectionId}/fields`, options)
        .catch(handleError)
        .then(formatDataStoreFieldResults);
};

export const fetchDataStoreFieldFilters = connectionId => {
    return API.get(apiName, `/data-stores/${connectionId}/field-filters`).catch(handleError);
};

export const createDataStore = params => {
    const options = {
        body: params,
    };
    // Do no provide credentials outside the POST
    // eslint-disable-next-line no-unused-vars
    const { credentials, ...body } = params;
    return API.post(apiName, `/data-stores`, options)
        .catch(handleError)
        .then(responseOrId =>
            typeof responseOrId === 'object'
                ? { ...body, ...responseOrId }
                : { id: responseOrId, ...body }
        )
        .then(formatDataStore);
};

export const deleteDataStore = connectionId => {
    const options = {
        responseType: 'text',
    };
    return API.del(apiName, `/data-stores/${connectionId}`, options)
        .then(response => {
            try {
                // Check if the response is JSON
                return JSON.parse(response);
            } catch (error) {
                return { status: 'success', code: 200, response };
            }
        })
        .catch(handleError)
        .then(response => {
            const { status, response: resp } = response;
            if (status === 'success') {
                return connectionId;
            }
            return resp || response;
        });
};

export const deleteMultipleDataStores = connectionIds => {
    const options = {
        responseType: 'text',
        body: {
            connectionIds,
        },
    };
    return API.del(apiName, `/data-stores`, options)
        .then(response => {
            try {
                // Check if the response is JSON
                return JSON.parse(response);
            } catch (error) {
                return { status: 'success', code: 200, response };
            }
        })
        .catch(handleError)
        .then(response => {
            const { status, response: resp } = response;
            if (status === 'success') {
                return connectionIds;
            }
            return resp || response;
        });
};

export const resyncDataStoreConnectionById = connectionId => {
    const options = {
        body: {
            connectionId,
        },
    };
    return API.put(apiName, `/data-mapping/sync`, options).catch(handleError);
};

export const fetchDataStoreConnectionSamples = url =>
    fetchJSON(url).then(formatDataStoreConnectionSamples);

export const updateDataStoreDetails = (dataStoreId, changes) => {
    const {
        agentId,
        agentOperation,
        countryIso,
        credentials,
        description,
        labels,
        purposesOfProcessing,
        name,
        nickname,
        orgIds,
        owners,
        productId,
        vendorId,
    } = changes;
    const options = {
        body: {
            agentId,
            agentOperation,
            countryIso,
            credentials,
            description,
            labels,
            purposesOfProcessing,
            name,
            nickname,
            orgIds,
            owners,
            productId,
            vendorId,
        },
    };
    return API.patch(apiName, `/data-stores/${dataStoreId}`, options)
        .catch(handleError)
        .then(formatDataStore);
};

export const createDataStoreField = (connectionId, details) => {
    const options = {
        // This endpoint is a BULK creation endpoint and expects an array of field objects
        body: [details],
    };
    return API.post(apiName, `/data-stores/${connectionId}/fields`, options).catch(handleError);
};

export const updateDataStoreFieldsById = (connectionId, fieldIds, details) => {
    const body = fieldIds.map(fieldId => ({
        fieldId,
        ...details,
    }));
    const options = {
        body,
    };
    return API.patch(apiName, `/data-stores/${connectionId}/fields`, options)
        .catch(handleError)
        .then(formatDataStoreFieldResults);
};

export const deleteDataStoreFieldsById = (connectionId, fieldIds) => {
    const options = {
        body: fieldIds,
        responseType: 'text',
    };
    return API.del(apiName, `/data-stores/${connectionId}/fields`, options)
        .then(response => {
            try {
                // Check if the response is JSON
                return JSON.parse(response);
            } catch (error) {
                return { status: 'success', code: 200, response };
            }
        })
        .catch(handleError)
        .then(response => {
            const { status, response: resp } = response;
            if (status === 'success') {
                return connectionId;
            }
            return resp || response;
        });
};

export const fetchDataStoreClassifications = params => {
    const options = {
        queryStringParameters: {
            ...formatFilterParams(params),
            ...formatPagingParams(params),
        },
    };
    return API.get(apiName, `/data-mapping/classifications`, options)
        .catch(handleError)
        .then(formatDataStoreClassificationResults);
};

export const fetchDataStoreRequestActions = params => {
    const options = {
        queryStringParameters: {
            ...formatFilterParams(params),
            ...formatPagingParams(params),
        },
    };
    return API.get(apiName, `/data-mapping/request-actions`, options)
        .catch(handleError)
        .then(formatDataStoreRequestActionResults);
};

export const requestOAuthToken = (agentId, body) => {
    const options = { body: { agentId, body } };
    return API.post(apiName, `/data-mapping/token`, options).catch(handleError);
};

export const fetchFieldClassificationStats = () => {
    return API.get(apiName, '/data-mapping/classification-stats')
        .catch(handleError)
        .then(formatFieldClassificationStats);
};

export const updateDataStoreAutomation = (dataStoreId, automationPreferences) => {
    const options = {
        body: automationPreferences,
    };
    return API.put(apiName, `/data-stores/${dataStoreId}/automation`, options).catch(handleError);
};

export const fetchDataSearchForActionItemById = actionItemId => {
    const options = {};
    return (
        API.get(apiName, `/dsar-action-items/${actionItemId}/search`, options)
            // 404 is returned when the action item is not found or does not match customerId
            .catch(handleError)
            // Will return a search entity that was initiated when the action item was created
            .then(details => {
                const {
                    search: {
                        dataSearchId,
                        searchId = dataSearchId,
                        status,
                        resultsFound,
                    } = details || {},
                } = details || {};
                return details
                    ? {
                          searchId,
                          status: status || '',
                          resultsFound: resultsFound || false,
                      }
                    : // If nothing is returned, assume there is no search
                      {
                          searchId: null,
                          status: '',
                          resultsFound: false,
                      };
            })
    );
};

export const fetchDefaultRequestTypeActionsForClassification = classificationId => {
    const options = {
        queryStringParameters: { classificationId },
    };
    return API.get(apiName, `/dsar-action-mappings`, options)
        .catch(handleError)
        .then(formatDefaultRequestTypeActionResults);
};

export const createDataStoreVendorRelationship = (dataStoreId, body) => {
    const options = {
        body,
    };
    return API.post(apiName, `/data-stores/${dataStoreId}/association`, options)
        .catch(handleError)
        .then(resp => formatVendorRelationships([resp]));
};

export const updateDataStoreVendorRelationship = (dataStoreId, associationId, body) => {
    const options = {
        body,
    };
    return API.put(
        apiName,
        `/data-stores/${dataStoreId}/association/${associationId}`,
        options
    ).catch(handleError);
};

export const deleteDataStoreVendorRelationship = (dataStoreId, associationId) => {
    return API.del(apiName, `/data-stores/${dataStoreId}/association/${associationId}`).catch(
        handleError
    );
};

export const fetchDataStoreRelationships = ({ id: dataStoreId, ...params } = {}) => {
    const options = {
        queryStringParameters: {
            ...formatFilterParams(params),
            ...formatPagingParams(params),
        },
    };
    return API.get(apiName, `/data-stores/${dataStoreId}/relationships`, options)
        .catch(handleError)
        .then(formatDataStoreRelationshipsResults);
};

export const createDataStoreRelationship = ({ dataStoreId, ...body } = {}) => {
    const options = {
        body,
    };
    return API.post(apiName, `/data-stores/${dataStoreId}/relationships`, options).catch(
        handleError
    );
};

export const deleteDataStoreRelationship = (dataStoreId, relationshipId) => {
    return API.del(apiName, `/data-stores/${dataStoreId}/relationships/${relationshipId}`).catch(
        handleError
    );
};

export const updateDataStoreRelationship = (dataStoreId, relationshipId, body) => {
    const options = {
        body,
    };
    return API.patch(
        apiName,
        `/data-stores/${dataStoreId}/relationships/${relationshipId}`,
        options
    ).catch(handleError);
};

export const fetchLabels = params => {
    const options = {
        queryStringParameters: {
            ...formatFilterParams(params),
            ...formatPagingParams(params),
        },
    };
    return API.get(apiName, `/labels`, options).catch(handleError);
};

export const fetchPurposesOfProcessing = params => {
    const options = {
        queryStringParameters: {
            ...formatFilterParams(params),
            ...formatPagingParams(params),
        },
    };
    return API.get(apiName, `/purposes-of-processing`, options).catch(handleError);
};

export const createDataStoreImportRequest = (fileName, fileType) => {
    const options = {
        body: {
            fileName,
            fileType,
        },
    };
    return API.post(apiName, `/data-store-import`, options).catch(handleError);
};

export const executePresigned = async (url, file) => {
    const fileReaderResult = await promisifiedFileReader(file);
    return fetch(url, {
        method: 'PUT',
        headers: { Accept: 'application/json' },
        body: new Blob([fileReaderResult], { type: file.type }),
    });
};

export const fetchDataStoreImportById = importId =>
    API.get(apiName, `/data-store-import/${importId}`).catch(handleError);

export const beginDataStoreImportById = importId => {
    const options = {
        body: {
            process: true,
        },
    };
    return API.put(apiName, `/data-store-import/${importId}`, options).catch(handleError);
};
