export default theme => ({
    root: {
        position: 'relative',
    },
    loading: {
        marginLeft: theme.spacing(1),
    },
    large: {
        top: 'calc(50% - 16px)',
        left: 'calc(50% - 16px)',
    },
    medium: {
        top: 'calc(50% - 12px)',
        left: 'calc(50% - 12px)',
    },
    small: {
        top: 'calc(50% - 8px)',
        left: 'calc(50% - 8px)',
    },
    progress: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        lineHeight: 1,
        position: 'absolute',
    },
});
