import { PERMISSIONS, UNIFIED_CONSENT_PATHS } from '/b2b/authentication/constants';
import {
    canManageSubjectRights,
    canManageVendors,
    canReadDataMapping,
} from '/b2b/authentication/state/user/AuthUser.selectors';

import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import Component from './Sidebar.component';
import { ROLES } from '/b2b/authentication/constants';
import UIActions from '/b2b/common/state/ui/UI.actions';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const DATA_MAPPING_ROLES = [ROLES.ADMIN, ROLES.DATA_MAPPING_MANAGER, ROLES.DATA_MAPPING_VIEWER];

const mapStateToProps = state => {
    const userId = fromState.Auth.user.getId(state);

    // Whenever roles change, we need to re-render the sidebar
    const { roles = [] } = fromState.DB.user.getEntityById(state, userId);
    const hasPermissionToReadDataMapping = roles.some(({ role } = {}) =>
        DATA_MAPPING_ROLES.includes(role)
    );

    const hasPermissionToReadAssessments =
        fromState.Auth.user.hasPermission(state, {
            toPerform: 'read',
            on: PERMISSIONS.ASSESSMENT,
        }) ||
        fromState.Auth.user.hasPermission(state, {
            toPerform: 'read',
            on: PERMISSIONS.ADMIN,
        });

    const hasPermissionToReadUnifiedConsent = fromState.Auth.user.hasPermission(state, {
        toPerform: 'read',
        on: PERMISSIONS.UNIFIED_CONSENT,
    });

    const hasPermissionToViewAssessments = fromState.Auth.customer.hasPermission(state, {
        toPerform: 'view',
        on: AuthenticatedPaths.ASSESSMENTS,
    });

    const hasPermissionToViewUnifiedConsent = fromState.Auth.customer.hasPermission(state, {
        toPerform: 'view',
        onAny: UNIFIED_CONSENT_PATHS,
    });

    return {
        roles,
        submenus: fromState.UI.getSubmenus(state),
        canManageSubjectRights: canManageSubjectRights(state),
        canManageVendors: canManageVendors(state),
        canReadDataMapping: canReadDataMapping(state),
        hasPermissionToReadAssessments,
        hasPermissionToReadDataMapping,
        hasPermissionToReadUnifiedConsent,
        hasPermissionToViewAssessments,
        hasPermissionToViewUnifiedConsent,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleSubmenu: (submenu, force) => dispatch(UIActions.toggleSubmenu(submenu, force)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Component);
