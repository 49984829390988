import { FormControlLabel } from '@mui/material';
import Locale from '/b2b/common/components/Locale/Locale.container';
import PropTypes from 'prop-types';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import styles from './ValidationTextEditor.styles';

const useStyles = makeStyles(styles);

const ValidationTextEditor = props => {
    const { children, label } = props;

    const classes = useStyles(props);
    return (
        <React.Fragment>
            <FormControlLabel
                className={classes.label}
                labelPlacement={'top'}
                label={<Locale path={label} />}
                control={children}
            />
        </React.Fragment>
    );
};

ValidationTextEditor.propTypes = {
    label: PropTypes.string,
    children: PropTypes.node,
};

export default ValidationTextEditor;
