import { EditorState, convertToRaw } from 'draft-js';
import React, { useEffect, useRef, useState } from 'react';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { Box } from '@mui/material';
import DraftJsEditor from './DraftJsEditor';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import styles from './TextEditor.styles';

const useStyles = makeStyles(styles);

const TextEditor = props => {
    const classes = useStyles(props);
    const { value, setValue } = props;
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const initialValueLoaded = useRef(false);

    const htmlToEditorState = html => {
        const contentStateFromHtml = convertFromHTML(html);
        return EditorState.createWithContent(contentStateFromHtml);
    };

    useEffect(() => {
        if (value && !initialValueLoaded.current) {
            setEditorState(htmlToEditorState(value));
            initialValueLoaded.current = true;
        } else if (!value) {
            setEditorState(EditorState.createEmpty());
        }
    }, [value]);

    const setEditorStateWrapper = es => {
        const plainText = editorState.getCurrentContent().getPlainText();
        const newEditorState = plainText.length <= 1 ? EditorState.moveFocusToEnd(es) : es;
        setEditorState(newEditorState);
    };

    useEffect(() => {
        const raw = convertToRaw(editorState.getCurrentContent());
        if (raw.blocks && raw.blocks.length === 1 && raw.blocks[0].text === '') {
            setValue(null);
        } else {
            const asHtml = convertToHTML(editorState.getCurrentContent());
            setValue(asHtml);
        }
    }, [editorState]);

    return (
        <Box className={classes.root}>
            <DraftJsEditor editorState={editorState} setEditorState={setEditorStateWrapper} />
        </Box>
    );
};

TextEditor.propTypes = {
    editorState: PropTypes.object,
    setEditorState: PropTypes.func,
    value: PropTypes.string,
    setValue: PropTypes.func,
};

export default TextEditor;
