import * as Audit from './audit/Audit.selectors';
import * as Breach from '/b2b/vendors/state/db/Breach.selectors';
import * as Category from './category/Category.selectors';
import * as ConsentConfig from '../../../consent/state/db/ConsentConfig.selectors';
import * as Customer from './customer/Customer.selectors';
import * as DSARActionItem from '../../../dsarActionItems/state/db/ActionItem.selectors';
import * as DSARForm from '../../../dsarForms/state/db/Form.selectors';
import * as DSARRequest from '../../../dsarRequests/state/db/Request.selectors';
import * as DSARRequestType from '../../../dsarRequests/state/db/RequestType.selectors';
import * as DataStore from '../../../dataStores/state/db/DataStore.selectors';
import * as Document from '../../../documents/state/db/Document.selectors';
import * as Domain from './domain/Domain.selectors';
import * as HelpScout from '../../../askAnalyst/state/db/HelpScout.selectors';
import * as Iab from './iab/Iab.selectors';
import * as Jurisdiction from '../../../legislation/state/db/Jurisdiction.selectors';
import * as Legislation from '../../../legislation/state/db/Legislation.selectors';
import * as Litigation from '../../../litigation/state/db/Litigation.selectors';
import * as Notification from '../../../notifications/state/db/Notification.selectors';
import * as Organization from '../../../organizations/state/db/Organization.selectors';
import * as Product from '/b2b/vendors/state/db/Product.selectors';
import * as Role from './role/Role.selectors';
import * as Subscription from './subscription/Subscription.selectors';
import * as Template from '../../../assessments/state/db/Template.selectors';
import * as Trusthub from '../../../trusthub/state/db/Trusthub.selectors';
import * as UnicourtAttorney from '../../../litigation/state/db/UnicourtAttorney.selectors';
import * as UnicourtCase from '../../../litigation/state/db/UnicourtCase.selectors';
import * as User from '../../../users/state/db/User.selectors';
import * as Vendor from '/b2b/vendors/state/db/Vendor.selectors';

import get from 'lodash/get';

export const audit = Audit;
export const category = Category;
export const consentConfig = ConsentConfig;
export const customer = Customer;
export const document = Document;
export const domain = Domain;
export const dsarRequest = DSARRequest;
export const dsarRequestType = DSARRequestType;
export const dsarActionItem = DSARActionItem;
export const dsarForm = DSARForm;
export const helpScout = HelpScout;
export const iab = Iab;
export const legislation = Legislation;
export const litigation = Litigation;
export const dataStore = DataStore;
export const notification = Notification;
export const organization = Organization;
export const product = Product;
export const jurisdiction = Jurisdiction;
export const role = Role;
export const subscription = Subscription;
export const trusthub = Trusthub;
export const unicourt = {
    attorney: UnicourtAttorney,
    case: UnicourtCase,
};
export const user = User;
export const vendor = Vendor;
export const breach = Breach;
export const template = Template;

export const DOMAIN = 'db';

export const getStore = state => get(state, DOMAIN) || {};
export const getAsync = state => getStore(state).__async__ || {};
export const getAsyncForSchema = (state, schema = {}) => {
    try {
        return getAsync(state)[schema.key] || {};
    } catch (e) {
        return {};
    }
};
export const getAsyncForEntity = (state, { schema, id }) =>
    get(getAsyncForSchema(state, schema), id);
export const isEntityLoading = (state, { schema, id }) =>
    !!getAsyncForEntity(state, { schema, id });
export const isEntityFieldLoading = (state, { schema, id, field }) =>
    !!(getAsyncForEntity(state, { schema, id }) || {})[field];
