import {
    removeMFA as removeMFAService,
    setPreferredMFA as setPreferredMFAService,
} from '/b2b/authentication/services';

import AuthActions from '/b2b/authentication/state/Auth.actions';

export const setPreferredMFA = params => dispatch => {
    dispatch(AuthActions.setPreferredMfaBegin(params));

    return setPreferredMFAService(params)
        .then(response => {
            return dispatch(AuthActions.setPreferredMfaSuccess(params, response));
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthActions.setPreferredMfaFailure(params, message));
            throw message;
        });
};

export const removeMFA = params => dispatch => {
    return removeMFAService(params)
        .then(({ cognitoUser }) => {
            return dispatch(AuthActions.removeMfa(params, cognitoUser));
        })
        .catch(error => {
            const { message } = error;
            throw message;
        });
};
