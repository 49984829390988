import {
    DATA_DISCOVERY_PATHS,
    DATA_MAPPING_PATHS,
    PERMISSIONS,
} from '/b2b/authentication/constants';
import React, { useEffect } from 'react';

import ConsentGraph from './components/ConsentGraph';
import FieldClassificationStats from './components/FieldClassificationStats';
import Greeting from './components/Greeting';
import { Grid } from '@mui/material';
import Permission from '../../common/components/Permission';
import PropTypes from 'prop-types';
import Stats from './components/Stats';
import TodoList from './components/TodoList';
import TrafficGraph from './components/TrafficGraph';
import makeStyles from '@mui/styles/makeStyles';
import styles from './Dashboard.styles';

const useStyles = makeStyles(styles);

const Dashboard = props => {
    const classes = useStyles(props);

    const {
        fetchDashboard,
        fetchDashboardLegacy,
        isDashboardV2Enabled,
        permissions = {},
        todos = {},
    } = props;

    useEffect(() => {
        // TODO: Remove once ENABLE_DASHBOARD_V2 feature flag is removed
        isDashboardV2Enabled ? fetchDashboard(permissions) : fetchDashboardLegacy();
    }, [isDashboardV2Enabled]);

    const canViewTodoList =
        // have at least one todo permission AND
        Object.values(permissions).some(Boolean) &&
        // have at least one todo item
        Object.values(todos).some(todo => Object.keys(todo).length > 0);

    return (
        <Grid container spacing={2} className={classes.root}>
            {isDashboardV2Enabled && (
                <Grid item xs={12}>
                    <Greeting />
                </Grid>
            )}
            <Grid item xs={12}>
                <Stats />
            </Grid>
            {isDashboardV2Enabled && canViewTodoList && (
                <Grid item xs={12}>
                    <TodoList />
                </Grid>
            )}
            <Grid item xs={12} md={6}>
                <TrafficGraph />
            </Grid>
            <Grid item xs={12} md={6}>
                <ConsentGraph />
            </Grid>
            {/* TODO: Remove once ENABLE_DASHBOARD_V2 feature flag is removed */}
            {!isDashboardV2Enabled && (
                <Permission toPerform="view" onAny={[DATA_DISCOVERY_PATHS, DATA_MAPPING_PATHS]}>
                    <Permission
                        forRole
                        toPerform="read"
                        onAny={[
                            PERMISSIONS.DATA_MAPPING,
                            PERMISSIONS.DATA_DISCOVERY,
                            PERMISSIONS.DSAR_REQUEST,
                        ]}
                    >
                        <Grid item xs={12}>
                            <FieldClassificationStats />
                        </Grid>
                    </Permission>
                </Permission>
            )}
        </Grid>
    );
};

Dashboard.propTypes = {
    fetchDashboard: PropTypes.func.isRequired,
    fetchDashboardLegacy: PropTypes.func.isRequired,
    isDashboardV2Enabled: PropTypes.bool.isRequired,
    permissions: PropTypes.object.isRequired,
    todos: PropTypes.object.isRequired,
};

export default Dashboard;
