import { cobalt, emerald, white } from '../../colors';

/* eslint react/prop-types: 0 */
import React from 'react';
import { SvgIcon } from '@mui/material';

export default function Monitor(props) {
    const { active, ...remain } = props;
    const primary = active ? emerald : emerald;
    const secondary = active ? cobalt : white;
    return (
        <SvgIcon fontSize="large" viewBox="0 0 72 73" {...remain}>
            <g fill={secondary}>
                <path d="m33.5 0h6v16h-6z" />
                <path d="m33.5 57h6v16h-6z" />
                <path d="m56 33.5h16v6h-16z" />
                <path d="m0 33.5h16v6h-16z" />
            </g>
            <path
                d="m36 57a20.5 20.5 0 1 1 20.5-20.5 20.53 20.53 0 0 1 -20.5 20.5zm0-35a14.5 14.5 0 1 0 14.5 14.5 14.51 14.51 0 0 0 -14.5-14.5z"
                fill={primary}
            />
            <circle cx="36" cy="36.5" fill={secondary} r="7" />
        </SvgIcon>
    );
}
