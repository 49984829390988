export default theme => ({
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center',
        },
    },
    signUpResend: {
        marginTop: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    signUpResendUrl: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    resend: {
        marginTop: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    resendUrl: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        '&:hover': {
            color: theme.palette.primary.main,
            cursor: 'pointer',
        },
    },
});
