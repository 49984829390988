import palette from '../palette';

export default {
    styleOverrides: {
        columnHeader: {
            '&:focus': {
                outline: 'none',
            },
        },
        cell: {
            '&:focus': {
                outline: 'none',
            },
        },
        iconButtonContainer: {
            width: 'auto',
            zIndex: 1,
        },
        footerContainer: {
            border: 'none',
        },
        main: {
            borderBottom: `1px solid ${palette.common.neutral}`,
        },
        root: {
            border: 'none',
        },
    },
};
