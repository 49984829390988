import Locale from '../Locale';
import LocalizedTooltip from '../LocalizedTooltip/LocalizedTooltip.component';
import { Tab as MuiTab } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import styles from './Tab.styles';

const useStyles = makeStyles(styles);

function a11yProps(name, tab) {
    return {
        id: `${name}-tab-${tab}`,
        'aria-controls': `${name}-tabpanel-${tab}`,
    };
}

const Tab = props => {
    const { className, label, value, name, tooltip, ...remain } = props;
    const classes = useStyles(props);
    return (
        <LocalizedTooltip title={tooltip}>
            <span>
                <MuiTab
                    {...a11yProps(name, value)}
                    {...remain}
                    label={typeof label === 'string' ? <Locale path={label} /> : label}
                    value={value}
                    className={className}
                    classes={classes}
                />
            </span>
        </LocalizedTooltip>
    );
};

Tab.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    name: PropTypes.string,
    tooltip: PropTypes.string,
    value: PropTypes.any,
};

export default Tab;
