import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { Fragment, useEffect, useReducer } from 'react';
import { initialState, default as reducer } from './AuthenticatorCode.reducer';

import Actions from './AuthenticatorCode.actions';
import { setupTOTP as GetQRCodeService } from '../../../authentication/services';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import makeStyles from '@mui/styles/makeStyles';
import { mfaIssuer } from '@osano-b2b';
import styles from './AuthenticatorCode.styles';

const useStyles = makeStyles(styles);

const AuthenticatorCode = props => {
    const { onCopy, userId, issuer = mfaIssuer || 'Osano', ...remain } = props;
    const classes = useStyles(props);
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isLoading, qrCode, error } = state;
    useEffect(() => {
        dispatch(Actions.generateCodeBegin());
        GetQRCodeService()
            .then(code => dispatch(Actions.generateCodeSuccess(code)))
            .catch(error => dispatch(Actions.generateCodeFailure(error)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
            {isLoading || error ? (
                error ? (
                    <Grid item xs classes={{ root: classes.gridItem }}>
                        <Typography variant="body1" component="p">
                            {error}
                        </Typography>
                    </Grid>
                ) : (
                    <Fragment>
                        <Grid item xs classes={{ root: classes.gridItem }}>
                            <Typography variant="body1" component="p">
                                Generating Secret Key
                            </Typography>
                        </Grid>
                        <Grid item xs={6} classes={{ root: classes.gridItem }}>
                            <CircularProgress />
                        </Grid>
                    </Fragment>
                )
            ) : (
                <Fragment>
                    <Grid item xs classes={{ root: classes.gridItem }}>
                        <Typography variant="body1" component="p">
                            Scan the QR code into your Authenticator application of choice:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} classes={{ root: classes.gridItem }}>
                        <QRCode
                            size={256}
                            bgColor="transparent"
                            renderAs="svg"
                            {...remain}
                            value={`otpauth://totp/${encodeURIComponent(
                                userId
                            )}?secret=${qrCode}&issuer=${encodeURIComponent(issuer)}`}
                        />
                    </Grid>
                    <Grid item xs classes={{ root: classes.gridItem }}>
                        <Typography variant="body1" component="p">
                            Or enter the Secret Key manually:
                        </Typography>
                    </Grid>
                    <Grid item xs classes={{ root: classes.gridItem }}>
                        <Button
                            color="secondary"
                            variant="contained"
                            classes={{ root: classes.paperRoot }}
                            onClick={() => onCopy(qrCode)}
                        >
                            {qrCode}
                        </Button>
                    </Grid>
                </Fragment>
            )}
        </Grid>
    );
};

AuthenticatorCode.propTypes = {
    userId: PropTypes.string.isRequired,
    issuer: PropTypes.string,
    onCopy: PropTypes.func,
};

export default AuthenticatorCode;
