import { buildActionCreators, makeActionTypes, singleParameter } from '../../helpers/ReduxHelpers';

import { DOMAIN } from './Nav.constants';

export const events = {
    routeChanged: singleParameter,
    changeCustomer: singleParameter,
};

export const types = makeActionTypes(Object.keys(events), DOMAIN);

export default buildActionCreators(events, types);
