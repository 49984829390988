import palette from '../palette';

export default {
    root: {
        '&:hover:not($disabled)': {
            backgroundColor: palette.background.default,
        },
        '&.Mui-focused $notchedOutline': {
            borderColor: palette.secondary.main,
        },
    },
};
