export const minScore = 300;
export const maxScore = 850;
export const riskyThreshold = 437.5;
export const prior36Months = 36;

import { getInitials } from '../common/helpers/String';
import { makeResultsFormatter } from '../common/services/helpers';

export const formatVendor = data => {
    let { score, name, ...newData } = data;
    if (score != null) {
        if (score < minScore) {
            // Score is actually a rating
            score = Math.round(minScore + score * ((maxScore - minScore) / 5));
        }
    } else {
        // Leave unscored vendors with no score
        score = null;
    }

    if (score !== undefined) {
        newData.score = score;
    }

    if (name) {
        newData.name = name;
        newData.initials = getInitials(name, 1);
    }
    return newData;
};

export const formatVendorResults = makeResultsFormatter(formatVendor);

export const formatProduct = data => {
    let { score, name, ...newData } = data;
    const { vendor = {} } = data;
    const { subprocessors = [] } = vendor;
    const { subprocessorCount = subprocessors.length } = data;
    if (score != null) {
        if (score < minScore) {
            // Score is actually a rating
            score = Math.round(minScore + score * ((maxScore - minScore) / 5));
        }
    } else {
        // Leave unscored products with no score
        score = null;
    }
    if (score !== undefined) {
        newData.score = score;
    }
    if (name) {
        newData.name = name;
        newData.initials = getInitials(name, 1);
    }
    return {
        ...newData,
        subprocessors: subprocessors.map(formatVendor),
        subprocessorCount,
    };
};

export const formatScoringResults =
    (scoreType = 'score') =>
    details => {
        const { section_scores, sectionScores = section_scores, ...remain } = details;
        remain.sectionScores = sectionScores
            ? sectionScores.map(
                  ({
                      section,
                      section_score = 0,
                      sectionscore = section_score,
                      sectionScore = sectionscore,
                  }) => {
                      return {
                          section,
                          [scoreType]: sectionScore,
                      };
                  }
              )
            : null;
        return remain;
    };
