import PropTypes from 'prop-types';
import React from 'react';
import ValidationForm from '/b2b/common/components/ValidationForm';
import schema from './VerifyTOTPDialog.schema';
import { verifyTotpToken } from '/b2b/authentication/services';

const VerifyTOTPDialog = props => {
    const { onSubmit } = props;

    const handleValidateTOTPCode = params => {
        return verifyTotpToken(params).then(() => onSubmit && onSubmit());
    };

    return <ValidationForm schema={schema} onSubmit={handleValidateTOTPCode} />;
};

VerifyTOTPDialog.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default VerifyTOTPDialog;
