export default {
    ACCOUNT: '/account',
    ACTIVITY: '/activity',
    ANALYSIS_REQUEST: '/analysis/request',
    API_KEYS: '/api-keys',
    ASK_ANALYST: '/ask-analyst',
    ASSESSMENTS: '/assessments',
    ASSESSMENT_ASSIGNMENTS: '/assessments/assignment',
    ASSESSMENT_TEMPLATES: '/assessments/templates',
    ASSESSMENTS_REPORTING: '/assessments/reporting',
    BREACHES: '/breaches',
    CONNECT_OAUTH: '/connect',
    CONNECT_DATA_MAPPING: '/connect/data-mapping',
    CONNECT_UC_INTEGRATION: '/connect/uc-integration',
    CONSENT: '/consent',
    CONSENT_DASHBOARD: '/consent/dashboard',
    CONSENT_MONITOR: '/consent-monitor',
    CONSENT_MANAGER: '/consent/banners',
    CUSTOMER: '/customer',
    DASHBOARD: '/dashboard',
    DATA_MAPPING_SOURCES: '/discovery/sources',
    DATA_MAPPING_APPLICATIONS: '/discovery/applications',
    DATA_MAP: '/discovery/map',
    DATA_STORES: '/discovery/stores',
    DOCUMENT_CHANGES: '/document-changes',
    DOCUMENTS: '/documents',
    DSAR_ACTION_ITEMS: '/subject-rights/action-items',
    DSAR_FORMS: '/subject-rights/forms',
    DSAR_REPORTING: '/subject-rights/reporting',
    DSAR_REQUESTS: '/subject-rights/requests',
    FOLLOWING: '/following',
    GDPR: '/gdpr',
    LEGISLATION: '/legislation',
    LITIGATION: '/litigation',
    LOGOUT: '/logout',
    NOT_FOUND: '/not-found',
    NOTIFICATIONS: '/notifications',
    ORGANIZATIONS: '/organizations',
    PREMIUM: '/premium',
    PRODUCTS: '/products',
    REPORTS: '/reports',
    REPORTS_CONSENT: '/reports/consent',
    REPORTS_DATASUBJECT: '/reports/datasubject',
    REPORTS_VENDOR: '/reports/vendor',
    RESOURCES: '/resources',
    TRUSTHUB: '/trusthub',
    UC: '/unified-consent',
    UC_CONFIGS: '/unified-consent/configs',
    UC_DASHBOARD: '/unified-consent/dashboard',
    UC_INTEGRATIONS: '/unified-consent/integrations',
    UC_PRIVACY_PROTOCOLS: '/unified-consent/privacy-protocols',
    UPLOADS: '/uploads',
    USERS: '/users',
    VENDORS: '/vendors',
    WEBHOOKS: '/webhooks',

    REDIRECT_CONSENT_MANAGER: '/consent-manager',
};
