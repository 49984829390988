import { Box, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';

import { ChromePicker } from 'react-color';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import noop from 'lodash/noop';
import styles from './ColorPicker.styles';

const useStyles = makeStyles(styles);

const ColorPicker = props => {
    const { value, disabled, onChange = noop, label, className, style } = props;
    const classes = useStyles(props);
    const color = value || '';
    const [showPicker, setShowPicker] = useState(false);
    /*
    const handleTextChange = event => {
        const value = event.target.value;
        onChange(value);
    };
*/
    const handlePickerChange = c => {
        const { r, g, b, a } = c.rgb;
        const value = a < 1 ? `rgba(${r},${g},${b},${a})` : c.hex;
        !disabled && onChange(value);
    };

    const handlePickerOpen = () => {
        !disabled && setShowPicker(true);
    };
    const handlePickerClose = () => {
        setShowPicker(false);
    };

    return (
        <div
            className={(className, classNames(disabled ? classes.disabled : '', classes.container))}
        >
            <Tooltip title={color}>
                <Box
                    className={classNames(
                        classes.box,
                        color.replace(/\s/g, '') ? '' : classes.empty
                    )}
                    style={{ backgroundColor: color }}
                    onClick={handlePickerOpen}
                />
            </Tooltip>
            <Typography style={style}>{label}</Typography>
            {showPicker && (
                <div style={{ position: 'absolute', zIndex: '2' }}>
                    <div
                        style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                        }}
                        onClick={handlePickerClose}
                    />
                    <ChromePicker color={color} onChange={handlePickerChange} />
                </div>
            )}
        </div>
    );
};

ColorPicker.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    disabled: PropTypes.bool,
};

export default ColorPicker;
