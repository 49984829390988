import { types as ActionTypes } from './DataMap.actions';
import { MAP_FILTERS } from '../constants';
import { genericReducer } from '../../common/helpers/ReduxHelpers';
import reduceReducers from 'reduce-reducers';

const initialFilters = {
    ...Object.keys(MAP_FILTERS).reduce(
        (acc, key) => ({
            ...acc,
            [key]: [],
        }),
        {}
    ),
};

export const initialState = {
    direction: null,
    filters: { ...initialFilters },
    fullscreen: false,
    layoutComplete: false,
    loading: true,
    nodesToConnect: {},
    savingNodeId: null,
    selectedFilters: { ...initialFilters },
    unmappedNodes: [],
};

export default reduceReducers(
    initialState,
    genericReducer(initialState, {
        [ActionTypes.setDirection]: (state, direction) => ({
            ...state,
            direction,
        }),
        [ActionTypes.setFilters]: (state, filters = {}) => ({
            ...state,
            filters: {
                ...state.filters,
                ...filters,
            },
        }),
        [ActionTypes.setFullscreen]: (state, fullscreen) => ({
            ...state,
            fullscreen,
        }),
        [ActionTypes.setLayoutComplete]: (state, layoutComplete) => ({
            ...state,
            layoutComplete,
        }),
        [ActionTypes.setLoading]: (state, loading) => ({
            ...state,
            loading,
        }),
        [ActionTypes.setNodesToConnect]: (state, nodesToConnect) => ({
            ...state,
            nodesToConnect,
        }),
        [ActionTypes.setSavingNodeId]: (state, savingNodeId) => ({
            ...state,
            savingNodeId,
        }),
        [ActionTypes.setSelectedFilters]: (state, selectedFilters = {}) => ({
            ...state,
            selectedFilters: {
                ...state.selectedFilters,
                ...selectedFilters,
            },
        }),
        [ActionTypes.setUnmappedNodes]: (state, unmappedNodes = []) => ({
            ...state,
            unmappedNodes,
        }),
    })
);
