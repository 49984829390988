import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from '@mui/material';
import castArray from 'lodash/castArray';
import { translate } from '../../helpers/i18n';

/**
 * @export
 * @typedef  LocalizedTooltipProps
 * @property {string} [delimiter]
 * @property {string | string[]} title
 * @property {Record<string, any>} [options]
 * @property {string} [shouldRetranslate]
 */

/** @type {React.FC<import('@mui/material').TooltipProps & LocalizedTooltipProps>} */
const LocalizedTooltip = props => {
    // eslint-disable-next-line no-unused-vars
    const { delimiter = ' ', title, options, shouldRetranslate, ...remain } = props;
    const label = translate(castArray(title), {
        ...options,
        delimiter: delimiter ?? options?.delimiter ?? ' ',
    });
    return <Tooltip title={label} aria-label={label} {...remain}></Tooltip>;
};

LocalizedTooltip.propTypes = {
    delimiter: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    options: PropTypes.object,
    shouldRetranslate: PropTypes.string,
};

export default LocalizedTooltip;
