/**
 * The customer uiAllowedList is overridden with false values for all
 * routes in this list (see `formatCustomer()` in format.js).
 *
 * Routes in this list should have a matching route in AuthenticatedRoutes.paths.js
 */
const blockedRoutes = ['uploads'];

export const uiAllowedList = blockedRoutes.reduce(
    (accumulator, currentRoute) => ({ ...accumulator, [currentRoute]: false }),
    {}
);

// TODO: if the use case arises, add block lists for actions other than view.
// E.g.: { document: ['delete', write'] }
