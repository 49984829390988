import camelCase from 'lodash/camelCase';
import castArray from 'lodash/castArray';
import prefixActionTypes from './prefixActionTypes';
import snakeCase from 'lodash/snakeCase';

export const makeActionTypes = (typeNames, prefix) => {
    const types = castArray(typeNames).reduce(
        (types, value) =>
            typeof value === 'string'
                ? {
                      ...types,
                      [`${camelCase(value)}`]: `${snakeCase(value).toUpperCase()}`,
                  }
                : types,
        {}
    );
    return prefixActionTypes(types, prefix);
};

export default makeActionTypes;
