import Component from './FeatureFlag.component';
import { connect } from 'react-redux';
import fromState from '../../state/selectors';

export const mapStateToProps = (state, { flag: flagName = '' }) => {
    const flags = fromState.Auth.user.getFeatureFlags(state);
    const { enabled: on = false, variant } =
        flags.find(f => f.name === flagName && f.enabled) || {};
    return { on, variant };
};

export default connect(mapStateToProps)(Component);
