import Component from './UnauthenticatedRoutes.component';
import UnauthenticatedPaths from './UnauthenticatedRoutes.paths';
import { connect } from 'react-redux';
import fromState from '../../common/state/selectors';
import { matchPath } from 'react-router-dom';

const mapStateToProps = (state, { location }) => {
    return {
        isAuthenticated: fromState.Auth.isAuthenticated(state),
        needsAccountConfirmation:
            !matchPath(location.pathname, { path: UnauthenticatedPaths.SIGN_UP_CONFIRM }) &&
            fromState.Auth.needsAccountConfirmation(state),
        needsPasswordRecovery:
            !matchPath(location.pathname, { path: UnauthenticatedPaths.RESET_PASSWORD }) &&
            fromState.Auth.needsPasswordRecovery(state),
        needsSignInConfirmed:
            !matchPath(location.pathname, { path: UnauthenticatedPaths.SIGN_IN_CONFIRM }) &&
            fromState.Auth.needsSignInConfirmed(state),
        needsNewInvite:
            !matchPath(location.pathname, {
                path: UnauthenticatedPaths.SIGN_UP_RESEND,
            }) && fromState.Auth.needsNewInvite(state),
        needsNewPassword:
            !matchPath(location.pathname, {
                path: UnauthenticatedPaths.RECOVER_PASSWORD,
            }) && fromState.Auth.needsNewPassword(state),
        needsMFASelected:
            !matchPath(location.pathname, { path: UnauthenticatedPaths.SELECT_MFA }) &&
            fromState.Auth.needsMFASelected(state),
        needsSignIn:
            !matchPath(location.pathname, {
                path: `${UnauthenticatedPaths.SIGN_IN}`,
                exact: true,
            }) && fromState.Auth.needsSignIn(state),
    };
};

export default connect(mapStateToProps)(Component);
