import Locale from '/b2b/common/components/Locale';
import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
    },
});

const useStyles = makeStyles(styles);

const NotFound = props => {
    const classes = useStyles(props);

    return (
        <div className={classes.root}>
            <Typography variant="h1" sx={{ mb: 1 }}>
                <Locale path="notFound.title" />
            </Typography>
            <Typography variant="h3" component="h2" sx={{ mb: 3 }}>
                <Locale path="notFound.subtitle" />
            </Typography>
            <Typography variant="body1">
                <Locale path="notFound.contactSupport" />
            </Typography>
        </div>
    );
};

NotFound.propTypes = {};

export default NotFound;
