/* eslint-disable react/no-children-prop */
import { Box, CircularProgress, Grid } from '@mui/material';
import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch, matchPath, useHistory, useLocation } from 'react-router-dom';

import AuthenticatedPaths from './AuthenticatedRoutes.paths';
import Dashboard from '../../dashboard/views';
import DashboardLayout from '../../common/layouts/Dashboard';
import NotFound from '../NotFound';
import PropTypes from 'prop-types';
import Quicksight from '../../reports/components/Quicksight';
import UnauthenticatedPaths from '../UnauthenticatedRoutes/UnauthenticatedRoutes.paths';

// Routes
const AccountRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "account" */
            /* webpackPrefetch: true */
            '../../account/routes/Routes'
        )
);
const ApiKeysRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "apiKeys" */
            /* webpackPrefetch: true */
            '../../apiKeys/routes/Routes'
        )
);
const AskAnalystRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "askAnalyst" */
            /* webpackPrefetch: true */
            '../../askAnalyst/routes'
        )
);
const AssessmentsRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "assessments" */
            /* webpackPrefetch: true */
            '../../assessments/routes'
        )
);
const ConsentRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "consent" */
            /* webpackPrefetch: true */
            '../../consent/routes'
        )
);
const ConsentRoutesNewUx = lazy(
    () =>
        import(
            /* webpackChunkName: "cookieConsent" */
            /* webpackPrefetch: true */
            '../../consent/routes/NewRoutes.jsx'
        )
);
const DataMappingRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "dataStores" */
            /* webpackPrefetch: true */
            '../../dataMapping/routes'
        )
);
const DataStoresRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "dataStores" */
            /* webpackPrefetch: true */
            '../../dataStores/routes'
        )
);
const DocumentRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "documents" */
            /* webpackPrefetch: true */
            '../../documents/routes'
        )
);
const DSARActionItemRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "dsarActionItems" */
            /* webpackPrefetch: true */
            '../../dsarActionItems/routes'
        )
);
const DSARFormsRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "dsarForms" */
            /* webpackPrefetch: true */
            '../../dsarForms/routes'
        )
);
const DSARReportingRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "dsarReporting" */
            /* webpackPrefetch: true */
            '../../dsarReporting/routes'
        )
);
const DSARRequestsRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "dsarRequests" */
            /* webpackPrefetch: true */
            '../../dsarRequests/routes'
        )
);
const GDPRRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "gdpr" */
            /* webpackPrefetch: true */
            '../../gdpr/routes'
        )
);
const LegislativeRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "legislation" */
            /* webpackPrefetch: true */
            '../../legislation/routes'
        )
);
const LitigationRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "litigation" */
            /* webpackPrefetch: true */
            '../../litigation/routes'
        )
);
const NotificationsRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "notifications" */
            /* webpackPrefetch: true */
            '../../notifications/routes'
        )
);
const OrganizationsRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "organizations" */
            /* webpackPrefetch: true */
            '../../organizations/routes'
        )
);
const ReportsRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "reports" */
            /* webpackPrefetch: true */
            '../../reports/routes'
        )
);
const ResourcesRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "resources" */
            /* webpackPrefetch: true */
            '../../resources/routes'
        )
);
const TrusthubRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "trusthub" */
            /* webpackPrefetch: true */
            '../../trusthub/routes'
        )
);
const UcRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "uc" */
            /* webpackPrefetch: true */
            '../../uc/routes'
        )
);
const UsersRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "users" */
            /* webpackPrefetch: true */
            '../../users/routes'
        )
);
const VendorsRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "vendors" */
            /* webpackPrefetch: true */
            '../../vendors/routes'
        )
);

const WebhooksRoutes = lazy(
    () =>
        import(
            /* webpackChunkName: "webhooks" */
            /* webpackPrefetch: true */
            '../../webhooks/routes'
        )
);

const FallbackLoader = () => (
    <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
    >
        <Grid item lg={6} xs={3}>
            <CircularProgress />
        </Grid>
    </Grid>
);

const AuthenticatedRoutes = ({
    isAuthenticated,
    isNewCmpUxFeatureFlagEnabled,
    userId,
    customerToken,
    fetchUserDetails,
    quicksightCategories,
    roles,
    hasPermissionToReadAssessments,
    hasPermissionToViewAssessments,
    hasPermissionToViewOrganizations,
    hasPermissionToViewReportsConsent,
    hasPermissionToViewReportsSubjectRights,
    hasPermissionToViewReportsVendor,
}) => {
    const mounted = useRef(null);
    const history = useHistory();
    const routerLocation = useLocation();
    const [isLoadingDetails, setLoadingDetails] = useState(true);
    useEffect(() => {
        mounted.current = true;
        if (customerToken) {
            setLoadingDetails(true);
            fetchUserDetails(userId)
                .then(() => {
                    mounted.current && setLoadingDetails(false);
                })
                .catch(() => {
                    mounted.current && setLoadingDetails(false);
                });
        }
        return () => {
            mounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerToken, fetchUserDetails]);

    useEffect(() => {
        let redirect = false;
        if (roles.length > 0) {
            // Redirects are only necessary once the user has roles to determine permissions
            if (
                // Customer has Assessments flag
                hasPermissionToViewAssessments &&
                // User does not have Admin or Assessment Role
                !hasPermissionToReadAssessments &&
                matchPath(routerLocation.pathname, AuthenticatedPaths.ASSESSMENTS) &&
                !matchPath(routerLocation.pathname, AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS)
            ) {
                // Redirect the user to Assignments if they are on Assessments and their role no longer allows
                redirect = AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS;
            }
        }
        if (redirect) {
            history.replace(redirect);
        }
    }, [hasPermissionToReadAssessments, hasPermissionToViewAssessments]);

    if (
        isAuthenticated &&
        Object.values(UnauthenticatedPaths).some(x => matchPath(routerLocation.pathname, x))
    ) {
        // User is authenticated, but on an unauthenticated page (e.g. sign in flow).
        // Do a full page load so b2b.js is reloaded. This should help mitigate app
        // crashing after deployments.
        window.location = AuthenticatedPaths.DASHBOARD;

        return <FallbackLoader />;
    }

    return isAuthenticated ? (
        <DashboardLayout>
            {isLoadingDetails ? (
                <FallbackLoader />
            ) : (
                <Suspense fallback={<FallbackLoader />}>
                    <Switch>
                        <Route path={AuthenticatedPaths.ACCOUNT} children={<AccountRoutes />} />
                        <Route path={AuthenticatedPaths.API_KEYS} children={<ApiKeysRoutes />} />
                        <Route
                            path={AuthenticatedPaths.ASK_ANALYST}
                            children={<AskAnalystRoutes />}
                        />
                        <Route
                            path={AuthenticatedPaths.CONSENT}
                            children={
                                isNewCmpUxFeatureFlagEnabled ? (
                                    <ConsentRoutesNewUx />
                                ) : (
                                    <ConsentRoutes />
                                )
                            }
                        />
                        <Route
                            path={[
                                AuthenticatedPaths.DATA_MAPPING_SOURCES,
                                AuthenticatedPaths.DATA_MAPPING_APPLICATIONS,
                                AuthenticatedPaths.DATA_MAP,
                            ]}
                            children={<DataMappingRoutes />}
                        />
                        <Route
                            path={[AuthenticatedPaths.DATA_STORES]}
                            children={<DataStoresRoutes />}
                        />
                        <Route
                            path={[
                                AuthenticatedPaths.DOCUMENTS,
                                AuthenticatedPaths.DOCUMENT_CHANGES,
                            ]}
                            children={<DocumentRoutes />}
                        />
                        <Route
                            path={AuthenticatedPaths.DSAR_ACTION_ITEMS}
                            children={<DSARActionItemRoutes />}
                        />
                        <Route
                            path={AuthenticatedPaths.DSAR_FORMS}
                            children={<DSARFormsRoutes />}
                        />
                        <Route
                            path={AuthenticatedPaths.DSAR_REPORTING}
                            children={<DSARReportingRoutes />}
                        />
                        <Route
                            path={AuthenticatedPaths.DSAR_REQUESTS}
                            children={<DSARRequestsRoutes />}
                        />
                        <Route
                            path={AuthenticatedPaths.NOTIFICATIONS}
                            children={<NotificationsRoutes />}
                        />
                        <Route
                            path={AuthenticatedPaths.LEGISLATION}
                            children={<LegislativeRoutes />}
                        />
                        <Route
                            path={AuthenticatedPaths.LITIGATION}
                            children={<LitigationRoutes />}
                        />
                        {hasPermissionToViewOrganizations && (
                            <Route
                                path={AuthenticatedPaths.ORGANIZATIONS}
                                children={<OrganizationsRoutes />}
                            />
                        )}
                        <Route path={AuthenticatedPaths.UC} children={<UcRoutes />} />
                        <Route path={AuthenticatedPaths.USERS} children={<UsersRoutes />} />
                        <Route path={AuthenticatedPaths.VENDORS} children={<VendorsRoutes />} />
                        <Route path={AuthenticatedPaths.REPORTS} children={<ReportsRoutes />} />
                        <Route
                            path={[
                                AuthenticatedPaths.ASSESSMENTS,
                                AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS,
                            ]}
                            children={<AssessmentsRoutes />}
                        />
                        <Route path={AuthenticatedPaths.RESOURCES} children={<ResourcesRoutes />} />
                        <Route path={AuthenticatedPaths.TRUSTHUB} children={<TrusthubRoutes />} />
                        <Route path={AuthenticatedPaths.GDPR} children={<GDPRRoutes />} />
                        <Route path={AuthenticatedPaths.WEBHOOKS} children={<WebhooksRoutes />} />
                        <Route
                            path={AuthenticatedPaths.REDIRECT_CONSENT_MANAGER}
                            children={<Redirect to={AuthenticatedPaths.CONSENT_MANAGER} />}
                        />
                        <Route exact path={AuthenticatedPaths.DASHBOARD} children={<Dashboard />} />
                        <Route exact path={AuthenticatedPaths.NOT_FOUND} children={<NotFound />} />
                        <Route
                            exact
                            path="/"
                            children={<Redirect to={AuthenticatedPaths.DASHBOARD} />}
                        />
                        <Route children={<Redirect to={AuthenticatedPaths.NOT_FOUND} />} />
                    </Switch>
                </Suspense>
            )}
            <div id="quicksight-container" style={{ display: 'flex', flex: '1 1 100%' }}>
                {quicksightCategories.map(category => {
                    let visible = false;
                    switch (category) {
                        case 'consent': {
                            visible =
                                hasPermissionToViewReportsConsent &&
                                matchPath(
                                    routerLocation.pathname,
                                    AuthenticatedPaths.REPORTS_CONSENT
                                );
                            break;
                        }
                        case 'datasubject': {
                            visible =
                                hasPermissionToViewReportsSubjectRights &&
                                matchPath(
                                    routerLocation.pathname,
                                    AuthenticatedPaths.REPORTS_DATASUBJECT
                                );
                            break;
                        }
                        case 'vendor': {
                            visible =
                                hasPermissionToViewReportsVendor &&
                                matchPath(
                                    routerLocation.pathname,
                                    AuthenticatedPaths.REPORTS_VENDOR
                                );
                            break;
                        }
                    }
                    return (
                        <Box
                            key={category}
                            sx={theme => ({
                                display: visible ? 'block' : 'none',
                                padding: theme.spacing(0, 4, 4),
                                width: '100%',
                            })}
                        >
                            <Quicksight category={category} />
                        </Box>
                    );
                })}
            </div>
        </DashboardLayout>
    ) : null;
};

AuthenticatedRoutes.propTypes = {
    customerToken: PropTypes.string,
    fetchUserDetails: PropTypes.func,
    hasPermissionToReadAssessments: PropTypes.bool,
    hasPermissionToViewAssessments: PropTypes.bool,
    hasPermissionToViewOrganizations: PropTypes.bool,
    hasPermissionToViewReportsConsent: PropTypes.bool,
    hasPermissionToViewReportsSubjectRights: PropTypes.bool,
    hasPermissionToViewReportsVendor: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    isNewCmpUxFeatureFlagEnabled: PropTypes.bool,
    quicksightCategories: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.array,
    userId: PropTypes.string,
};

export default AuthenticatedRoutes;
