import { types as ActionTypes, asyncEvents as AsyncEvents } from './OrganizationDetails.actions';
import { CRUDDetailsReducers, genericReducer } from '../../common/helpers/ReduxHelpers';

import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import schema from './db/Organization.schema';

export const initialState = {
    id: '',
    loading: false,
    error: null,
};

export default reduceReducers(
    initialState,
    combineReducers({
        ...CRUDDetailsReducers(
            initialState,
            Object.keys(AsyncEvents).map(key => ActionTypes[key]),
            schema
        ),
    }),
    genericReducer(initialState, {
        [AuthActionTypes.logout]: () => initialState,
    })
);
