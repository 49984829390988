export default theme => ({
    gridRoot: {
        margin: 0,
    },
    gridItem: {
        margin: theme.spacing(1),
        textAlign: 'center',
    },
    paperRoot: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
});
