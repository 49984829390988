import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
} from '/b2b/common/helpers/ReduxHelpers';

import { DOMAIN } from './AuthCustomerConsentConfigs.constants';

export const events = {};

export const asyncEvents = makeAsyncEvents(['fetchResults', 'copy', 'create', 'delete'], true);

export const types = makeActionTypes([...Object.keys(events), ...Object.keys(asyncEvents)], DOMAIN);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
    },
    types
);
