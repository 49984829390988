import { createSchema } from '/b2b/common/helpers/Schema';

export default createSchema({
    currentPhone: {
        type: 'hidden',
    },
    updated: {
        type: 'hidden',
    },
    phone(value, attributes) {
        const constraint = {
            label: 'common.fields.phone.label',
            type: 'phone',
            presence: {
                allowEmpty: false,
            },
            phone: {
                country: '', // Do not enter a country code to check all countries
            },
            props: {
                disabled: !!attributes.updated,
                InputLabelProps: {
                    shrink: true,
                },
            },
        };
        return constraint;
    },
    code(value, attributes) {
        const constraint = {
            label: 'Code',
            presence: false,
            depends: {
                updated: true,
            },
            props: {
                placeholder: 'common.fields.verificationCode.placeholder',
                InputLabelProps: {
                    shrink: true,
                },
            },
        };
        if (!attributes.updated || attributes.currentPhone !== attributes.phone) {
            return constraint;
        }
        constraint.presence = { allowEmpty: false };
        constraint.length = {
            is: 6,
            notValid: 'common.formValidation.isNotValid',
        };
        return constraint;
    },
});
