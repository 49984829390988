import { body } from '../../helpers/Print';

export default theme => ({
    root: {
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        '&:hover $track': {
            opacity: 1,
        },
        [`.${body} &`]: {
            overflow: 'visible !important',
        },
    },
    dark: {
        '& $track': {
            background: 'rgba(255, 255, 255, 0.1)',
        },
        '& $thumb': {
            background: 'rgba(255, 255, 255, 0.4)',
        },
    },
    wrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        overflow: 'hidden',
        zIndex: 0,
        [`.${body} &`]: {
            position: 'static !important',
            overflow: 'visible !important',
        },
    },
    content: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        minWidth: '100%',
        position: 'relative',
    },
    scrollArea: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        overflow: 'scroll',
        [`.${body} &`]: {
            position: 'static !important',
            overflow: 'visible !important',
        },
    },
    track: {
        position: 'absolute',
        overflow: 'hidden',
        borderRadius: theme.spacing(0.5),
        background: 'rgba(0, 0, 0, 0.1)',
        userSelect: 'none',
        transitionProperty: 'transform, opacity',
        transitionDuration: '300ms',
        transitionTimingFunction: 'ease-in-out',
        opacity: 0,
        [`.${body} &`]: {
            display: 'none !important',
        },
    },
    trackHorizontal: {
        height: theme.spacing(1),
        width: '100%',
        bottom: 0,
        left: 0,
        transform: `translateY(${theme.spacing(1)})`,

        '.trackYVisible &': {
            width: `calc(100% - ${theme.spacing(1)})`,
        },
        '.trackXVisible:hover &': {
            transform: `translateY(0)`,
        },
        [`.${body} &`]: {
            display: 'none !important',
        },
    },
    trackVertical: {
        width: theme.spacing(1),
        height: '100%',
        top: 0,
        right: 0,
        transform: `translateX(${theme.spacing(1)})`,

        '.trackXVisible &': {
            height: `calc(100% - ${theme.spacing(1)})`,
        },
        '.trackYVisible:hover &': {
            transform: `translateX(0)`,
        },
        [`.${body} &`]: {
            display: 'none !important',
        },
    },
    thumb: {
        touchAction: 'none',
        cursor: 'pointer',
        borderRadius: theme.spacing(0.5),
        background: 'rgba(0, 0, 0, 0.4)',
        [`.${body} &`]: {
            display: 'none !important',
        },
    },
    thumbHorizontal: {},
    thumbVertical: {},
});
