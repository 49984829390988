import conversationList, {
    initialState as initialConversationListState,
} from './conversationList/ConversationList.reducer';
import conversationThreads, {
    initialState as initialConversationThreadsState,
} from './conversationThreads/ConversationThreads.reducer';

import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import { combineReducers } from 'redux';
import { genericReducer } from '../../common/helpers/ReduxHelpers';
import reduceReducers from 'reduce-reducers';

export const initialState = {
    conversationList: initialConversationListState,
    conversationThreads: initialConversationThreadsState,
};

export default reduceReducers(
    initialState,
    combineReducers({
        conversationList,
        conversationThreads,
    }),
    genericReducer(initialState, {
        [AuthActionTypes.logout]: () => initialState,
    })
);
