import cookies, { initialState as initialCookiesState } from './cookies/Cookies.reducer';
import disclosures, {
    initialState as initialDisclosuresState,
} from './disclosures/Disclosures.reducer';
import { genericReducer, passthroughReducer } from '../../../common/helpers/ReduxHelpers';
import iab, { initialState as initialIABState } from './iab/IAB.reducer';
import palette, { initialState as initialPaletteState } from './palette/Palette.reducer';
import scripts, { initialState as initialScriptsState } from './scripts/Scripts.reducer';

import { types as ActionTypes } from './Configuration.actions';
import { types as ConfigActionTypes } from '../ConfigDetails.actions';
import { combineReducers } from 'redux';
import { createTranslationOverridesFromState } from '../../utils';
import { defaultConfiguration } from '../../constants';
import reduceReducers from 'reduce-reducers';
import set from 'lodash/set';

export const initialState = {
    additionalLinks: defaultConfiguration.additionalLinks,
    policyLinkText: defaultConfiguration.policyLinkText,
    policyLinkInDrawer: defaultConfiguration.policyLinkInDrawer,
    storagePolicyHref: defaultConfiguration.storagePolicyHref,
    codeSplitting: defaultConfiguration.codeSplitting,
    crossDomain: defaultConfiguration.crossDomain,
    ccpaRelaxed: defaultConfiguration.ccpaRelaxed,
    dntSupport: defaultConfiguration.dntSupport,
    forcedClassifyEnabled: defaultConfiguration.forcedClassifyEnabled,
    googleConsent: defaultConfiguration.googleConsent,
    gpcSupport: defaultConfiguration.gpcSupport,
    iabEnabled: defaultConfiguration.iabEnabled,
    legacyBrowserSupport: defaultConfiguration.legacyBrowserSupport,
    iframeBlocking: defaultConfiguration.iframeBlocking,
    managePreferencesEnabled: defaultConfiguration.managePreferencesEnabled,
    iab: initialIABState,
    timeoutSeconds: defaultConfiguration.timeoutSeconds,
    cookies: initialCookiesState,
    disclosures: initialDisclosuresState,
    scripts: initialScriptsState,
    palette: initialPaletteState,
    translations: defaultConfiguration.translations,
    translationOverrides: {},
    allowTimeout: defaultConfiguration.allowTimeout,
    forceManagePreferences: defaultConfiguration.forceManagePreferences,
    remoteConsent: defaultConfiguration.remoteConsent,
};

const configKeys = Object.keys(initialState);

const extractConfigurationKeys = (config, defaultConfig) =>
    configKeys.reduce((newConfig, key) => {
        if (key === 'iab' || key === 'palette' || key === 'translationOverrides') {
            return newConfig;
        }

        newConfig[key] = config[key] === undefined ? defaultConfig[key] : config[key];
        if (key === 'translations') {
            // We need to build the CMP overrides for translations
            newConfig.translationOverrides = createTranslationOverridesFromState(newConfig[key]);
        }

        return newConfig;
    }, {});

export default reduceReducers(
    initialState,
    combineReducers({
        additionalLinks: passthroughReducer(initialState.additionalLinks),
        policyLinkText: passthroughReducer(initialState.policyLinkText),
        policyLinkInDrawer: passthroughReducer(initialState.policyLinkInDrawer),
        storagePolicyHref: passthroughReducer(initialState.storagePolicyHref),
        codeSplitting: passthroughReducer(initialState.codeSplitting),
        crossDomain: passthroughReducer(initialState.crossDomain),
        ccpaRelaxed: passthroughReducer(initialState.ccpaRelaxed),
        dntSupport: passthroughReducer(initialState.dntSupport),
        forcedClassifyEnabled: passthroughReducer(initialState.forcedClassifyEnabled),
        googleConsent: passthroughReducer(initialState.googleConsent),
        remoteConsent: passthroughReducer(initialState.remoteConsent),
        gpcSupport: passthroughReducer(initialState.gpcSupport),
        iabEnabled: passthroughReducer(initialState.iabEnabled),
        legacyBrowserSupport: passthroughReducer(initialState.legacyBrowserSupport),
        iframeBlocking: passthroughReducer(initialState.iframeBlocking),
        managePreferencesEnabled: passthroughReducer(initialState.managePreferencesEnabled),
        timeoutSeconds: passthroughReducer(initialState.timeoutSeconds),
        palette,
        iab,
        scripts,
        cookies,
        disclosures,
        translations: passthroughReducer(initialState.translations),
        translationOverrides: passthroughReducer(initialState.translationOverrides),
        allowTimeout: passthroughReducer(initialState.allowTimeout),
        forceManagePreferences: passthroughReducer(initialState.forceManagePreferences),
    }),
    genericReducer(initialState, {
        [ConfigActionTypes.createSuccess]: (state, { response }) => {
            const { configuration = {} } = response;
            return {
                ...state,
                ...extractConfigurationKeys(configuration, state),
            };
        },
        [ConfigActionTypes.fetchDetailsSuccess]: (state, { response }) => {
            const { configuration = {} } = response;
            return {
                ...state,
                ...extractConfigurationKeys(configuration, {
                    ...state,
                    // Defaults for pre-existing configs without these settings:
                    dntSupport: false,
                    forcedClassifyEnabled: false,
                    gpcSupport: false,
                    legacyBrowserSupport: false,
                    forceManagePreferences: false,
                }),
            };
        },
        [ConfigActionTypes.updateSuccess]: (state, { response }) => {
            const { configuration = {} } = response;
            return {
                ...state,
                ...extractConfigurationKeys(configuration, state),
            };
        },
        [ActionTypes.fieldChange]: (state, { field, value }) => {
            if (
                field === 'scripts' ||
                field === 'cookies' ||
                field === 'palette' ||
                field === 'iab' ||
                field === 'disclosures'
            ) {
                return state;
            }
            const newState = { ...state };
            const fieldPath = field.split('.');
            set(newState, field.split('.'), value);
            switch (fieldPath[0]) {
                case 'translations': {
                    newState.translationOverrides = createTranslationOverridesFromState(
                        newState.translations
                    );
                }
            }
            return newState;
        },
    })
);
