import muiTheme from '../default';

export default {
    root: {
        '&.Mui-deleting': {
            color: muiTheme.palette.text.disabled,
            background: `repeating-linear-gradient(
                45deg,
                ${muiTheme.palette.common.neutral},
                ${muiTheme.palette.common.neutral} 2px,
                ${muiTheme.palette.common.white} 2px,
                ${muiTheme.palette.common.white} 4px
            )`,
            pointerEvents: 'none',
            '& *': {
                color: muiTheme.palette.text.disabled,
            },
        },
    },
    responsiveSimple: {
        [muiTheme.breakpoints.down('md')]: {
            borderTopWidth: 0,
        },
    },
    responsiveStacked: {
        [muiTheme.breakpoints.down('md')]: {
            alignItems: 'stretch',
            display: 'flex',
            flexWrap: 'wrap',
            height: 'auto',
        },
    },
};
