import PropTypes from 'prop-types';
import React from 'react';
import ReselectMFA from '../components/ReselectMFA';
import ValidationForm from '/b2b/common/components/ValidationForm';
import makeStyles from '@mui/styles/makeStyles';
import schema from './VerifyTOTP.schema';
import styles from './VerifyTOTP.styles';

const useStyles = makeStyles(styles);

const VerifyTOTP = props => {
    const { onSubmit } = props;
    const classes = useStyles(props);
    return (
        <div className={classes.content}>
            <div className={classes.contentBody}>
                <ValidationForm
                    title="auth.confirmSignIn"
                    submitText="auth.continueSignIn"
                    schema={schema}
                    onSubmit={onSubmit}
                    continuousValidation={false}
                >
                    <ReselectMFA mfaType="Authenticator" />
                </ValidationForm>
            </div>
        </div>
    );
};

VerifyTOTP.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default VerifyTOTP;
