import { FormControl, FormHelperText } from '@mui/material';

import Locale from '/b2b/common/components/Locale';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import styles from './ValidationFormControl.styles';

const useStyles = makeStyles(styles);

const ValidationFormControl = props => {
    const {
        className,
        children,
        color,
        disabled,
        error,
        focused,
        fullWidth,
        helperText,
        hiddenLabel,
        margin,
        onClick,
        required,
        size,
        variant,
    } = props;

    const formControlProps = {
        color,
        disabled,
        focused,
        fullWidth,
        hiddenLabel,
        margin,
        onClick,
        required,
        size,
        variant,
    };

    const classes = useStyles(props);
    return (
        <FormControl
            error={!!error}
            component="fieldset"
            className={classNames(classes.root, className)}
            {...formControlProps}
        >
            {children}
            {error && typeof error !== 'boolean' ? (
                <FormHelperText className={classes.errorText}>{error}</FormHelperText>
            ) : null}
            {helperText ? (
                <FormHelperText className={classes.helperText}>
                    {React.isValidElement(helperText) || Array.isArray(helperText) ? (
                        helperText
                    ) : (
                        <Locale path={helperText} />
                    )}
                </FormHelperText>
            ) : null}
        </FormControl>
    );
};

ValidationFormControl.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.bool]),
    focused: PropTypes.bool,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.any,
    hiddenLabel: PropTypes.bool,
    margin: PropTypes.oneOf(['dense', 'none', 'normal']),
    onClick: PropTypes.func,
    required: PropTypes.bool,
    size: PropTypes.oneOf(['medium', 'small']),
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

export default ValidationFormControl;
