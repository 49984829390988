import {
    fetchAmplifyContext as fetchAmplifyContextService,
    signInFederated,
    signIn as signInService,
} from '../../../services';

import AuthActions from '../../../state/Auth.actions';
import { updateAuth } from '../../../../amplify.config';

export const signIn = params => dispatch => {
    dispatch(AuthActions.signInBegin(params));

    return signInService(params)
        .then(response => {
            dispatch(AuthActions.signInSuccess(params, response));

            return response;
        })
        .catch(error => {
            let { code, message } = error;
            switch (code) {
                case 'UserNotFoundException':
                case 'NotAuthorizedException': {
                    message =
                        'There is an issue with your email or your password. Please try again. Emails are case sensitive';
                    break;
                }
            }
            dispatch(AuthActions.signInFailure(params, error));

            throw message;
        });
};

export const fetchAmplifyContext = params => dispatch => {
    updateAuth();
    dispatch(AuthActions.fetchSignInContextBegin(params));

    return (
        fetchAmplifyContextService(params)
            // eslint-disable-next-line complexity
            .then(context => {
                const formattedContext = updateAuth(context);
                const { identityProviderName, ssoEnabled } = formattedContext;
                if (ssoEnabled) {
                    const federatedParams = {};
                    switch (identityProviderName) {
                        case 'Google':
                        case 'Facebook':
                        case 'LoginWithAmazon':
                        case 'SignInWithApple': {
                            federatedParams.provider = identityProviderName;
                            break;
                        }

                        default: {
                            federatedParams.customProvider = identityProviderName;
                            break;
                        }
                    }
                    dispatch(AuthActions.signInFederatedBegin(params));

                    return signInFederated(federatedParams).catch(error => {
                        dispatch(AuthActions.signInFederatedFailure(params, error));
                    });
                }
                dispatch(AuthActions.fetchSignInContextSuccess(params, formattedContext));

                return formattedContext;
            })
            .catch(error => {
                dispatch(AuthActions.fetchSignInContextFailure(params, error));

                throw error;
            })
    );
};
