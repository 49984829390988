import { fetchConsentStats, fetchConsentTrafficStats } from '../consent/services';

import { allSettled } from '../common/services/helpers';
import { fetchDocumentChangesCount } from '../documents/services';
import { fetchFieldClassificationStats } from '../dataStores/services';
import { fetchFollowingLitigationCount } from '../litigation/services';
import { fetchFollowingVendorsCount } from '../vendors/services';

const friendlyMessage = original => {
    switch (original) {
        case 'call not allowed by plan':
            return { upgrade: true, fakeValue: Math.floor(Math.random() * 100 - 1) };
        default:
            return '';
    }
};

const makeStat = (settlement, title) => {
    const { state, value, reason } = settlement;
    const { message = reason } = reason || {};
    return {
        title,
        value: state === 'fulfilled' ? value : friendlyMessage(message) || 0,
    };
};

export const fetchDashboard = () => {
    return allSettled([
        fetchFollowingVendorsCount(),
        fetchFollowingLitigationCount(),
        fetchDocumentChangesCount(),
        fetchConsentStats(),
        fetchConsentTrafficStats(),
        fetchFieldClassificationStats(),
    ]).then(resultSet => {
        const [vendors, litigaton, documentChanges, consent, traffic, fieldClassifications] =
            resultSet;
        const stats = [
            makeStat(vendors, 'layouts.Dashboard.stats.VendorsMonitored.label'),
            makeStat(litigaton, 'layouts.Dashboard.stats.LawsuitsFiled.label'),
            makeStat(documentChanges, 'layouts.Dashboard.stats.DocChanges.label'),
        ];
        return {
            traffic: traffic.value || {},
            consent: consent.value || {},
            fieldClassifications: fieldClassifications.value || [],
            stats,
        };
    });
};
