export default theme => ({
    '@keyframes welcomeIn': {
        from: { opacity: 0 },
        to: { opacity: 1 },
    },
    logo: {
        height: 'auto',
        width: '150px',

        '& .logo .filled': {
            fill: theme.palette.common.white,
        },
    },
    quote: {
        backgroundColor: theme.palette.tertiary.main,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        animationDuration: '600ms',
        animationName: '$welcomeIn',
    },
    quoteInner: {
        padding: '2rem',
        textAlign: 'center',
        flexBasis: '600px',
    },
    quoteHeadline: {
        color: theme.palette.common.white,
        fontSize: theme.typography.fontSize * 3,
        lineHeight: 1,
    },
    quoteSubhead: {
        marginTop: theme.spacing(3),
        color: theme.palette.common.white,
        fontSize: theme.typography.fontSize * 1.5,
        lineHeight: 1.5,
    },
    link: {
        color: theme.palette.common.white,
        fontSize: '1.25rem',
    },
});
