import { denormalize } from 'normalizr';
import get from 'lodash/get';
import { getStore as getDB } from '../../../common/state/db/DB.selectors';
import schema from './Jurisdiction.schema';

const jurisdictionSort = (a, b) => {
    try {
        if (a.type === b.type) {
            return a.name.localeCompare(b.name);
        }
        if (a.type.toLowerCase() === 'region') {
            return -1;
        }
        if (a.type.toLowerCase() === 'state') {
            return 1;
        }
        if (a.type.toLowerCase() === 'country') {
            return b.type.toLowerCase() === 'region' ? 1 : -1;
        }
        return a.type.localeCompare(b.type);
    } catch (e) {
        return 0;
    }
};

export const DOMAIN = `db.${schema.key}`;

export const getStore = state => get(state, DOMAIN) || {};

export const getEntities = state =>
    (denormalize(Object.keys(getStore(state)), [schema], getDB(state)) || []).sort(
        jurisdictionSort
    );

/**
 * Returns a denormalized (hydrated) entity from the Redux ORM for the JurisdictionEntity corresponding to the provided `id`
 *
 * @param {Object} state The redux state
 * @param {string|number} id The id for the Jurisdiction
 * @returns {import('./Jurisdiction.schema').JurisdictionEntity}
 */
export const getEntityById = (state, id) => denormalize(id, schema, getDB(state)) || {};
export const getEntitiesForIds = (state, ids) =>
    (denormalize(ids, [schema], getDB(state)) || []).sort(jurisdictionSort);

export const getEntityFieldById = (state, { id, field }) =>
    get(getStore(state), [id, field]) || null;
