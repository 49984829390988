import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flexGrow: 1,
    },
    noPadding: {
        padding: 0,
    },
});

const useStyles = makeStyles(styles);

const PortletContent = props => {
    const { className, children, noPadding, ...rest } = props;
    const classes = useStyles(props);
    const rootClassName = classNames(
        {
            [classes.root]: true,
            [classes.noPadding]: noPadding,
        },
        className
    );

    return (
        <div {...rest} className={rootClassName}>
            {children}
        </div>
    );
};

PortletContent.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    noPadding: PropTypes.bool,
};

export default PortletContent;
