import { IconButton, Tooltip } from '@mui/material';

import HelpIcon from '@mui/icons-material/HelpOutline';
import React from 'react';

const ResourceCenter = () => (
    <Tooltip title="Help" aria-label="Help">
        <IconButton id="btn-resource-center" color="tertiary" size="large">
            <HelpIcon fontSize="large" />
        </IconButton>
    </Tooltip>
);

export default ResourceCenter;
