/* eslint-disable react/no-children-prop */
import { Route, Switch } from 'react-router-dom';

import Account from './Account/Account.container';
import BrowsePaths from '../../routing/BrowseRoutes/BrowseRoutes.paths';
import FailedPermissions from '../../routing/NotFound';
import PropTypes from 'prop-types';
import React from 'react';
import useRouteMatchParams from '../../common/helpers/Hooks/useRouteMatchParams';
import { withPermission } from '../../common/components/Permission';

const BrowseRoutes = () => {
    const { tab } = useRouteMatchParams(`${BrowsePaths.ACCOUNT}/:tab?`);

    return (
        <Switch>
            <Route exact path={`${BrowsePaths.ACCOUNT}/:tab?`} children={<Account tab={tab} />} />
        </Switch>
    );
};

BrowseRoutes.propTypes = {
    match: PropTypes.object.isRequired,
};

export default withPermission('view', BrowsePaths.ACCOUNT)(BrowseRoutes, FailedPermissions);
