import React, { Fragment, useCallback, useMemo } from 'react';

import CustomerPlanSelect from './components/CustomerPlanSelect';
import FeatureFlagSelect from './components/FeatureFlagSelect';
import MissingTranslations from './components/MissingTranslations';
import PropTypes from 'prop-types';
import { UI_VIEW_PREFIX } from '/b2b/authentication/constants';
import UserRoleSelect from './components/UserRoleSelect';
import { getTranslatedRoleLabel } from '/b2b/common/helpers/Roles';

const AdminHelpers = props => {
    const {
        canChangeCustomerPlan,
        canChangeRole,
        canLogMissingTranslations,
        canToggleFeatureFlags,
        changeCustomerPlan,
        changeUserRole,
        customerId,
        customerPlan,
        featureFlags,
        featureFlagOptions,
        options,
        roles,
        setFeatureFlags,
    } = props;

    const handleChangeCustomerPlan = useCallback(newPlan => {
        const newCustomerPlan = Object.entries(customerPlan).reduce((acc, [feature, value]) => {
            if (typeof value === 'boolean') {
                acc[feature] = newPlan.includes(feature);
            }
            return acc;
        }, {});
        changeCustomerPlan(newCustomerPlan);
    });

    const formattedPlanFeatures = useMemo(() => {
        return (
            Object.entries(customerPlan)
                // eslint-disable-next-line no-unused-vars
                .filter(([feature, enabled]) => enabled)
                .map(([feature]) => feature)
        );
    }, [customerPlan]);

    const formattedPlanFeatureOptions = useMemo(() => {
        return Object.entries(customerPlan)
            .map(([feature, enabled]) => ({
                enabled,
                feature,
                label: feature.replace(UI_VIEW_PREFIX, ''),
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [customerPlan]);

    const handleChangeUserRole = useCallback(newRoles => {
        if (newRoles.length) {
            // Do not allow empty roles
            changeUserRole(newRoles);
        }
    }, []);

    const formattedRoles = useMemo(() => {
        return roles.map(({ roleId }) => roleId);
    }, [roles]);

    const formattedRoleOptions = useMemo(() => {
        return options.map(({ roleId, role }) => ({
            id: roleId,
            name: getTranslatedRoleLabel({ value: role }),
        }));
    }, [options]);

    return (
        <Fragment>
            {canLogMissingTranslations ? <MissingTranslations /> : null}
            {canChangeCustomerPlan ? (
                <CustomerPlanSelect
                    customerId={customerId}
                    label="Customer Plan"
                    options={formattedPlanFeatureOptions}
                    value={formattedPlanFeatures}
                    onChange={handleChangeCustomerPlan}
                />
            ) : null}
            {canChangeRole ? (
                <UserRoleSelect
                    customerId={customerId}
                    options={formattedRoleOptions}
                    value={formattedRoles}
                    onChange={handleChangeUserRole}
                />
            ) : null}
            {canToggleFeatureFlags ? (
                <FeatureFlagSelect
                    customerId={customerId}
                    label="Feature Flags"
                    options={featureFlagOptions}
                    value={featureFlags}
                    onChange={setFeatureFlags}
                />
            ) : null}
        </Fragment>
    );
};

AdminHelpers.propTypes = {
    canChangeCustomerPlan: PropTypes.bool,
    canChangeRole: PropTypes.bool,
    canLogMissingTranslations: PropTypes.bool,
    canToggleFeatureFlags: PropTypes.bool,
    changeCustomerPlan: PropTypes.func.isRequired,
    changeUserRole: PropTypes.func.isRequired,
    customerId: PropTypes.string,
    customerPlan: PropTypes.object,
    featureFlags: PropTypes.arrayOf(PropTypes.string),
    featureFlagOptions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    options: PropTypes.array,
    roles: PropTypes.array,
    setFeatureFlags: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
};

export default AdminHelpers;
