import { QUERY_PARAMS } from './ORMResults.constants';
import { initialState } from './ORMResults.reducer';
import isArray from 'lodash/isArray';
import { planLimitReached } from '../../Limits';

export const getPlanData = state => state.planData || {};
export const getPlanLimitReached = state => planLimitReached(getPlanData(state));

export const canRequestResults = state => {
    const { requireSearch } = state;
    const minSearchLength = isNaN(parseInt(requireSearch, 10)) ? 3 : parseInt(requireSearch, 10);
    const { currentQuery } = state;
    return !requireSearch || (currentQuery && currentQuery.length >= minSearchLength);
};

export const getSearchParams = state => {
    const searchParams = new URLSearchParams();
    const filters = state.currentFilters || {};
    Object.entries(filters).forEach(([filter, value]) => {
        if (isArray(value)) {
            value.forEach(val => {
                searchParams.append(`${filter}`, `${val}`);
            });
        } else {
            searchParams.append(`${filter}`, `${value}`);
        }
    });

    QUERY_PARAMS.forEach(param => {
        const value = state[`${param}`];
        if (value && value !== initialState[`${param}`]) {
            searchParams.set(`${param}`, state[`${param}`]);
        }
    });

    return searchParams;
};

export const getQueryString = state => {
    const searchParams = getSearchParams(state);
    // Sort the keys for predictable order
    searchParams.sort();
    return searchParams.toString();
};
