import AuthActions from '../../../../state/Auth.actions';
import Component from './ReselectMFA.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const mapStateToProps = state => {
    return {
        hasOtherMFAMethods: fromState.Auth.getMfaOptions(state).length > 1,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onReselectMFA: () => dispatch(AuthActions.selectMfa({ mfaType: 'NOMFA' })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
