import { deepKoamaruLight } from '/b2b/common/colors';

export default theme => ({
    root: {
        padding: theme.spacing(4),
    },
    needsReviewButton: {
        display: 'flex',
        justifyContent: 'space-around',
        marginLeft: theme.spacing(1),
        minWidth: '8.5rem',
        '& .MuiBadge-root': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(2),
        },
    },
    status: {
        '&&': {
            color: deepKoamaruLight,
            fontSize: '16 px',
            fontFamily: 'Roboto',
            fontStyle: 'italic',
            fontWeight: '400',
            lineHeight: '25.6 px',
            wordWrap: 'break-word',
        },
    },
    accordion: {
        borderLeft: 'none',
        borderRight: 'none',
        '&:not(:last-child)': {
            borderBottom: 'none',
        },
    },
    accordionSummary: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    accordionDetails: {
        paddingTop: 0,
    },
    todoIconContainer: {
        marginLeft: theme.spacing(1),
        minWidth: theme.spacing(4),
    },
    todoIconIncomplete: {
        color: theme.palette.grey[400],
    },
    todoIconComplete: {
        color: theme.palette.success.main,
    },
    todoIconWarning: {
        color: theme.palette.error.main,
    },
    todoLink: {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
    },
    completeTodo: {
        color: theme.palette.grey[500],
    },
});
