import { types as ActionTypes, asyncEvents as AsyncEvents } from './AuthUserNotifications.actions';
import {
    CRUDPagingQueryReducer,
    crudPagingReducers,
    genericReducer,
    passthroughReducer,
} from '/b2b/common/helpers/ReduxHelpers';

import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { types as NavActionTypes } from '/b2b/common/state/nav/Nav.actions';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import schema from '/b2b/notifications/state/db/Notification.schema';

export const initialState = {
    offset: 0,
    limit: 25,
    query: '',
    results: [],
    resultCount: 0,
    error: null,
    loading: false,
    markingAll: false,
    urgentNotifications: false,
};

export default reduceReducers(
    initialState,
    combineReducers({
        markingAll: passthroughReducer(initialState.markingAll),
        urgentNotifications: genericReducer(initialState.urgentNotifications, {
            [ActionTypes.refreshSuccess]: (state, { response }) => {
                const { results } = response || {};
                return (results || [])[0]?.priority ? results[0].priority <= 10 : false;
            },
        }),
        ...crudPagingReducers(
            initialState,
            Object.keys(AsyncEvents).map(key => ActionTypes[key]),
            schema
        ),
    }),
    genericReducer(initialState, {
        [ActionTypes.markRead]: (state, notificationId) => {
            const { resultCount, results } = state;
            return {
                ...state,
                results: results.filter(id => id.toString() !== notificationId.toString()),
                resultCount: resultCount - 1,
            };
        },
        [ActionTypes.markAllBegin]: (state, payload) => {
            const { params: { read } = {} } = payload;
            if (read === undefined) {
                return false;
            }
            return {
                ...state,
                markingAll: true,
            };
        },
        [ActionTypes.markAllSuccess]: (state, { params: { read } = {} }) => {
            if (read === undefined) {
                return state;
            }
            return {
                ...state,
                markingAll: false,
                results: [],
                resultCount: 0,
            };
        },
        [ActionTypes.markAllFailure]: (state, { params: { read } = {} }) => {
            if (read === undefined) {
                return state;
            }
            return {
                ...state,
                markingAll: false,
            };
        },
        [ActionTypes.markUnread]: (state, notificationId) => {
            const { resultCount, results } = state;
            return {
                ...state,
                results: [notificationId, ...results],
                resultCount: resultCount + 1,
            };
        },
        [NavActionTypes.routeChanged]: CRUDPagingQueryReducer(
            initialState,
            AuthenticatedPaths.NOTIFICATIONS
        ),
    })
);
