import {
    deepMergeStrategy,
    defaultIdAttribute,
    defaultProcessStrategy,
} from '../../../helpers/Normalizr';

import { abilityStrategy } from '../../../helpers/Permissions';
import { schema } from 'normalizr';

export const entityName = 'roles';
export const idAttribute = 'roleId';

export const mergeStrategy = (entityA, entityB) => {
    /*
     * Do NOT merge permissions/abilities
     * Permissions are not cumulative, and abilities are deteminite
     */
    const {
        abilities: oldAbilities /* eslint-disable-line no-unused-vars */,
        permissions: oldPermissions,
        ...oldEntity
    } = entityA;
    const {
        abilities /* eslint-disable-line no-unused-vars */,
        permissions = oldPermissions,
        /* eslint-disable-line no-unused-vars */ ...newEntity
    } = entityB;
    if (permissions) {
        newEntity.permissions = permissions;
    }
    const mergedEntity = deepMergeStrategy(idAttribute)(oldEntity, newEntity);
    if (!mergedEntity?.permissions) {
        // If no permisssions...do NOT overwrite redux
        const {
            abilities /* eslint-disable-line no-unused-vars */,
            permissions /* eslint-disable-line no-unused-vars */,
            ...processedEntity
        } = mergedEntity;
        return processedEntity;
    }
    return abilityStrategy(mergedEntity);
};
export const processStrategy = defaultProcessStrategy(idAttribute, newEntity => {
    if (!newEntity?.permissions) {
        // If no permisssions...do NOT overwrite redux
        const {
            abilities /* eslint-disable-line no-unused-vars */,
            permissions /* eslint-disable-line no-unused-vars */,
            ...processedEntity
        } = newEntity;
        return processedEntity;
    }
    return abilityStrategy(newEntity);
});

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
