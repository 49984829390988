import {
    defaultIdAttribute,
    defaultMergeStrategy,
    defaultProcessStrategy,
} from '/b2b/common/helpers/Normalizr';
import { entityName, idAttribute } from './DataStoreConnectionField.constants';

import { schema } from 'normalizr';

export { entityName, idAttribute } from './DataStoreConnectionField.constants';

export const mergeStrategy = defaultMergeStrategy(idAttribute);
export const processStrategy = defaultProcessStrategy(
    idAttribute,
    function (details, parent, parentKey) {
        const { id = details, [idAttribute]: realId = id } = details;
        if (parentKey !== null && parentKey === 'fields') {
            // Has a connection
            const currentFields = parent.fields || [];
            if (!currentFields.includes(realId)) {
                parent.connections = [...currentFields, realId];
            }
        }
        return details;
    }
);

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
