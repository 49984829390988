/* eslint-disable eqeqeq */
import {
    ClickAwayListener,
    Divider,
    Grow,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    TextField,
} from '@mui/material';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import Locale from '/b2b/common/components/Locale';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import makeStyles from '@mui/styles/makeStyles';
import styles from './ValidationSelect.styles';
import { translate } from '/b2b/common/helpers/i18n';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';

const useStyles = makeStyles(styles);

const ValidationSelect = props => {
    const {
        clearable,
        component,
        dropdownStyles,
        exclusiveOption,
        placeholder,
        onChange,
        options,
        usePopper,
        multiple,
        value,
        sort,
        ...remain
    } = props;
    const classes = useStyles(props);
    const Component = component || MenuItem;

    const anchorRef = useRef();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(value || (multiple ? [] : ''));

    const exclusiveOptionString = JSON.stringify(exclusiveOption);
    const optionsString = JSON.stringify(options);
    const selectedString = JSON.stringify(selected);
    const allOptions = useMemo(
        () => ({
            '': { value: '', label: placeholder || '--', disabled: !clearable },
            ...(Array.isArray(options)
                ? options.reduce((acc, option) => {
                      acc[option.value] = option;
                      return acc;
                  }, {})
                : options),
            ...exclusiveOption,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [optionsString, exclusiveOptionString, placeholder]
    );

    const handleToggle = useCallback(() => {
        setOpen(prevOpen => !prevOpen);
    }, []);

    const handleClose = useCallback(
        (val = selected) => {
            onChange && onChange(val);
            setTimeout(() => {
                document.activeElement.blur();
            }, 0);
            setOpen(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onChange, selectedString]
    );

    const handleChange = useBoundCallback(
        (exclusive, close, canSelectMultiple, selectedOptions, e, valueOrNode) => {
            let newSelected = valueOrNode?.props?.value ?? valueOrNode ?? e?.target?.value;
            if (canSelectMultiple) {
                const currentSelected = [...selectedOptions];
                if (exclusive && (exclusive[currentSelected[0]] || exclusive[newSelected])) {
                    currentSelected.splice(0, currentSelected.length);
                }
                const idx = currentSelected.indexOf(newSelected);
                newSelected = idx === -1 ? [...currentSelected, newSelected] : [...selectedOptions];
                if (idx >= 0) {
                    newSelected.splice(idx, 1);
                }
            }
            setSelected(newSelected);
            if (anchorRef.current && anchorRef.current.contains(e.target)) {
                // Using popper
                return;
            }
            close(newSelected);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [exclusiveOption, handleClose, multiple, selected]
    );

    const handleListKeyDown = useCallback(event => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }, []);

    const handleRenderValue = useBoundCallback(
        (options, val) => {
            // Selected is not `currentValue`
            return Array.isArray(val)
                ? val
                      .map(option => translate(options[option]?.label || options[option]))
                      .join(', ') || '--'
                : translate(options[val]?.label || options[val] || '--');
        },
        [allOptions]
    );

    const handleRenderOption = useCallback(
        ([val, option]) => {
            let menuProps = option;
            if (typeof option === 'string') {
                // There are options
                menuProps = { label: option, disabled: false };
            }
            const { label, value = val } = menuProps;
            if (value == undefined) {
                // Both undefined and null are equal for value
                return null;
            }
            const isSelected =
                selected != undefined && multiple ? selected.includes(value) : selected === value;
            return (
                <Component
                    key={value}
                    value={value}
                    {...menuProps}
                    onClick={() => usePopper && handleChange(value)}
                >
                    <ListItemText primary={<Locale path={label} />} />
                    <ListItemSecondaryAction>
                        {isSelected ? <CheckIcon /> : null}
                    </ListItemSecondaryAction>
                </Component>
            );
        },
        [Component, handleChange, multiple, selectedString, usePopper]
    );

    const renderedOptions = useMemo(() => {
        const renderedOptions = sort
            ? Object.entries(options || {})
                  .map(([, value]) => value)
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map(option => [option.value, option])
                  .map(handleRenderOption)
            : Object.entries(options || {}).map(handleRenderOption);

        return [
            ...Object.entries(exclusiveOption || {}).map(handleRenderOption),
            ...(exclusiveOption ? [<Divider key="divider" variant="middle" />] : []),
            ...renderedOptions,
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, optionsString, exclusiveOptionString, selectedString, handleRenderOption]);

    useEffect(() => {
        if (!isEqual(value, selected)) {
            setSelected(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return usePopper ? (
        <Fragment>
            <TextField
                key="popper"
                aria-owns={open ? 'menu-list-grow' : undefined}
                aria-haspopup
                className={classes.root}
                color="secondary"
                onClick={handleToggle}
                ref={anchorRef}
                select
                SelectProps={{
                    displayEmpty: true,
                    renderValue: handleRenderValue,
                    MenuProps: {
                        open: false,
                    },
                }}
                variant="outlined"
                {...remain}
                value={selected}
            />
            <Popper
                anchorEl={anchorRef.current}
                className={classes.popover}
                disablePortal
                open={open}
                role={undefined}
                transition
                style={{ width: '100%', height: '100%' }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            width: '100%',
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper id="menu-list-grow" style={{ ...dropdownStyles }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                                    {placeholder && (
                                        <Component value="" disabled={!clearable}>
                                            <Locale path={placeholder} />
                                        </Component>
                                    )}
                                    {renderedOptions}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Fragment>
    ) : (
        <TextField
            key="no-popper"
            select
            className={classes.root}
            variant="outlined"
            color="secondary"
            {...remain}
            onChange={handleChange}
            value={selected}
            SelectProps={{
                displayEmpty: clearable,
                renderValue: handleRenderValue,
                multiple: !!multiple,
                ...remain.SelectProps,
            }}
        >
            {placeholder && (
                <Component value="" disabled={!clearable}>
                    <Locale path={placeholder} />
                </Component>
            )}
            {renderedOptions}
        </TextField>
    );
};

ValidationSelect.propTypes = {
    clearable: PropTypes.bool,
    component: PropTypes.any,
    dropdownStyles: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    exclusiveOption: PropTypes.object,
    options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    multiple: PropTypes.bool,
    usePopper: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
    sort: PropTypes.bool,
};

export default ValidationSelect;
