// Palette
import palette from '../palette';

export default {
    root: {
        backgroundColor: palette.common.white,
        color: palette.text.secondary,
        '&:hover': {
            backgroundColor: palette.common.neutral,
        },
        '&.Mui-disabled': {
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
        },
        '&.Mui-error': {
            backgroundColor: palette.error.main,
            color: palette.error.contrastText,
            '&:hover': {
                backgroundColor: palette.error.hover,
            },
            '&.Mui-disabled': {
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
            },
        },
    },
    primary: {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        '&:hover': {
            backgroundColor: palette.primary.hover,
        },
        '&.Mui-disabled': {
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
        },
    },
    secondary: {
        backgroundColor: palette.secondary.main,
        color: palette.secondary.contrastText,
        '&:hover': {
            backgroundColor: palette.secondary.hover,
        },
        '&.Mui-disabled': {
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
        },
    },
};
