/* eslint-disable quote-props */
/* eslint-disable security/detect-unsafe-regex */

export const countryLookup = {
    afghanistan: { name: 'Afghanistan', twoLetter: 'AF', threeLetter: 'AFG' },
    albania: { name: 'Albania', twoLetter: 'AL', threeLetter: 'ALB' },
    algeria: { name: 'Algeria', twoLetter: 'DZ', threeLetter: 'DZA' },
    'american samoa': { name: 'American Samoa', twoLetter: 'AS', threeLetter: 'ASM' },
    andorra: { name: 'Andorra', twoLetter: 'AD', threeLetter: 'AND' },
    angola: { name: 'Angola', twoLetter: 'AO', threeLetter: 'AGO' },
    anguilla: { name: 'Anguilla', twoLetter: 'AI', threeLetter: 'AIA' },
    antarctica: { name: 'Antarctica', twoLetter: 'AQ', threeLetter: 'ATA' },
    'antigua and barbuda': { name: 'Antigua and Barbuda', twoLetter: 'AG', threeLetter: 'ATG' },
    argentina: { name: 'Argentina', twoLetter: 'AR', threeLetter: 'ARG' },
    armenia: { name: 'Armenia', twoLetter: 'AM', threeLetter: 'ARM' },
    aruba: { name: 'Aruba', twoLetter: 'AW', threeLetter: 'ABW' },
    australia: { name: 'Australia', twoLetter: 'AU', threeLetter: 'AUS' },
    austria: { name: 'Austria', twoLetter: 'AT', threeLetter: 'AUT' },
    azerbaijan: { name: 'Azerbaijan', twoLetter: 'AZ', threeLetter: 'AZE' },
    bahamas: { name: 'Bahamas', twoLetter: 'BS', threeLetter: 'BHS' },
    bahrain: { name: 'Bahrain', twoLetter: 'BH', threeLetter: 'BHR' },
    bangladesh: { name: 'Bangladesh', twoLetter: 'BD', threeLetter: 'BGD' },
    barbados: { name: 'Barbados', twoLetter: 'BB', threeLetter: 'BRB' },
    belarus: { name: 'Belarus', twoLetter: 'BY', threeLetter: 'BLR' },
    belgium: { name: 'Belgium', twoLetter: 'BE', threeLetter: 'BEL' },
    belize: { name: 'Belize', twoLetter: 'BZ', threeLetter: 'BLZ' },
    benin: { name: 'Benin', twoLetter: 'BJ', threeLetter: 'BEN' },
    bermuda: { name: 'Bermuda', twoLetter: 'BM', threeLetter: 'BMU' },
    bhutan: { name: 'Bhutan', twoLetter: 'BT', threeLetter: 'BTN' },
    bolivia: { name: 'Bolivia', twoLetter: 'BO', threeLetter: 'BOL' },
    bonaire: { name: 'Bonaire', twoLetter: 'BQ', threeLetter: 'BES' },
    'bosnia and herzegovina': {
        name: 'Bosnia and Herzegovina',
        twoLetter: 'BA',
        threeLetter: 'BIH',
    },
    botswana: { name: 'Botswana', twoLetter: 'BW', threeLetter: 'BWA' },
    'bouvet island': { name: 'Bouvet Island', twoLetter: 'BV', threeLetter: 'BVT' },
    brazil: { name: 'Brazil', twoLetter: 'BR', threeLetter: 'BRA' },
    'british indian ocean territory': {
        name: 'British Indian Ocean Territory',
        twoLetter: 'IO',
        threeLetter: 'IOT',
    },
    'brunei darussalam': { name: 'Brunei Darussalam', twoLetter: 'BN', threeLetter: 'BRN' },
    bulgaria: { name: 'Bulgaria', twoLetter: 'BG', threeLetter: 'BGR' },
    'burkina faso': { name: 'Burkina Faso', twoLetter: 'BF', threeLetter: 'BFA' },
    burundi: { name: 'Burundi', twoLetter: 'BI', threeLetter: 'BDI' },
    cambodia: { name: 'Cambodia', twoLetter: 'KH', threeLetter: 'KHM' },
    cameroon: { name: 'Cameroon', twoLetter: 'CM', threeLetter: 'CMR' },
    canada: { name: 'Canada', twoLetter: 'CA', threeLetter: 'CAN' },
    'cape verde': { name: 'Cape Verde', twoLetter: 'CV', threeLetter: 'CPV' },
    'cayman islands': { name: 'Cayman Islands', twoLetter: 'KY', threeLetter: 'CYM' },
    'central african republic': {
        name: 'Central African Republic',
        twoLetter: 'CF',
        threeLetter: 'CAF',
    },
    chad: { name: 'Chad', twoLetter: 'TD', threeLetter: 'TCD' },
    chile: { name: 'Chile', twoLetter: 'CL', threeLetter: 'CHL' },
    china: { name: 'China', twoLetter: 'CN', threeLetter: 'CHN' },
    'christmas island': { name: 'Christmas Island', twoLetter: 'CX', threeLetter: 'CXR' },
    'cocos (keeling) islands': {
        name: 'Cocos (Keeling) Islands',
        twoLetter: 'CC',
        threeLetter: 'CCK',
    },
    colombia: { name: 'Colombia', twoLetter: 'CO', threeLetter: 'COL' },
    comoros: { name: 'Comoros', twoLetter: 'KM', threeLetter: 'COM' },
    congo: { name: 'Congo', twoLetter: 'CG', threeLetter: 'COG' },
    'democratic republic of the congo': {
        name: 'Democratic Republic of the Congo',
        twoLetter: 'CD',
        threeLetter: 'COD',
    },
    'cook islands': { name: 'Cook Islands', twoLetter: 'CK', threeLetter: 'COK' },
    'costa rica': { name: 'Costa Rica', twoLetter: 'CR', threeLetter: 'CRI' },
    croatia: { name: 'Croatia', twoLetter: 'HR', threeLetter: 'HRV' },
    cuba: { name: 'Cuba', twoLetter: 'CU', threeLetter: 'CUB' },
    curacao: { name: 'Curacao', twoLetter: 'CW', threeLetter: 'CUW' },
    cyprus: { name: 'Cyprus', twoLetter: 'CY', threeLetter: 'CYP' },
    'czech republic': { name: 'Czech Republic', twoLetter: 'CZ', threeLetter: 'CZE' },
    "cote d'ivoire": { name: "Cote d'Ivoire", twoLetter: 'CI', threeLetter: 'CIV' },
    denmark: { name: 'Denmark', twoLetter: 'DK', threeLetter: 'DNK' },
    djibouti: { name: 'Djibouti', twoLetter: 'DJ', threeLetter: 'DJI' },
    dominica: { name: 'Dominica', twoLetter: 'DM', threeLetter: 'DMA' },
    'dominican republic': { name: 'Dominican Republic', twoLetter: 'DO', threeLetter: 'DOM' },
    ecuador: { name: 'Ecuador', twoLetter: 'EC', threeLetter: 'ECU' },
    egypt: { name: 'Egypt', twoLetter: 'EG', threeLetter: 'EGY' },
    'el salvador': { name: 'El Salvador', twoLetter: 'SV', threeLetter: 'SLV' },
    'equatorial guinea': { name: 'Equatorial Guinea', twoLetter: 'GQ', threeLetter: 'GNQ' },
    eritrea: { name: 'Eritrea', twoLetter: 'ER', threeLetter: 'ERI' },
    estonia: { name: 'Estonia', twoLetter: 'EE', threeLetter: 'EST' },
    ethiopia: { name: 'Ethiopia', twoLetter: 'ET', threeLetter: 'ETH' },
    'falkland islands (malvinas)': {
        name: 'Falkland Islands (Malvinas)',
        twoLetter: 'FK',
        threeLetter: 'FLK',
    },
    'faroe islands': { name: 'Faroe Islands', twoLetter: 'FO', threeLetter: 'FRO' },
    fiji: { name: 'Fiji', twoLetter: 'FJ', threeLetter: 'FJI' },
    finland: { name: 'Finland', twoLetter: 'FI', threeLetter: 'FIN' },
    france: { name: 'France', twoLetter: 'FR', threeLetter: 'FRA' },
    'french guiana': { name: 'French Guiana', twoLetter: 'GF', threeLetter: 'GUF' },
    'french polynesia': { name: 'French Polynesia', twoLetter: 'PF', threeLetter: 'PYF' },
    'french southern territories': {
        name: 'French Southern Territories',
        twoLetter: 'TF',
        threeLetter: 'ATF',
    },
    gabon: { name: 'Gabon', twoLetter: 'GA', threeLetter: 'GAB' },
    gambia: { name: 'Gambia', twoLetter: 'GM', threeLetter: 'GMB' },
    georgia: { name: 'Georgia', twoLetter: 'GE', threeLetter: 'GEO' },
    germany: { name: 'Germany', twoLetter: 'DE', threeLetter: 'DEU' },
    ghana: { name: 'Ghana', twoLetter: 'GH', threeLetter: 'GHA' },
    gibraltar: { name: 'Gibraltar', twoLetter: 'GI', threeLetter: 'GIB' },
    greece: { name: 'Greece', twoLetter: 'GR', threeLetter: 'GRC' },
    greenland: { name: 'Greenland', twoLetter: 'GL', threeLetter: 'GRL' },
    grenada: { name: 'Grenada', twoLetter: 'GD', threeLetter: 'GRD' },
    guadeloupe: { name: 'Guadeloupe', twoLetter: 'GP', threeLetter: 'GLP' },
    guam: { name: 'Guam', twoLetter: 'GU', threeLetter: 'GUM' },
    guatemala: { name: 'Guatemala', twoLetter: 'GT', threeLetter: 'GTM' },
    guernsey: { name: 'Guernsey', twoLetter: 'GG', threeLetter: 'GGY' },
    guinea: { name: 'Guinea', twoLetter: 'GN', threeLetter: 'GIN' },
    'guinea-bissau': { name: 'Guinea-Bissau', twoLetter: 'GW', threeLetter: 'GNB' },
    guyana: { name: 'Guyana', twoLetter: 'GY', threeLetter: 'GUY' },
    haiti: { name: 'Haiti', twoLetter: 'HT', threeLetter: 'HTI' },
    'heard island and mcdonald mcdonald islands': {
        name: 'Heard Island and McDonald Mcdonald Islands',
        twoLetter: 'HM',
        threeLetter: 'HMD',
    },
    'holy see (vatican city state)': {
        name: 'Holy See (Vatican City State)',
        twoLetter: 'VA',
        threeLetter: 'VAT',
    },
    honduras: { name: 'Honduras', twoLetter: 'HN', threeLetter: 'HND' },
    'hong kong': { name: 'Hong Kong', twoLetter: 'HK', threeLetter: 'HKG' },
    hungary: { name: 'Hungary', twoLetter: 'HU', threeLetter: 'HUN' },
    iceland: { name: 'Iceland', twoLetter: 'IS', threeLetter: 'ISL' },
    india: { name: 'India', twoLetter: 'IN', threeLetter: 'IND' },
    indonesia: { name: 'Indonesia', twoLetter: 'ID', threeLetter: 'IDN' },
    iran: { name: 'Iran', twoLetter: 'IR', threeLetter: 'IRN' },
    iraq: { name: 'Iraq', twoLetter: 'IQ', threeLetter: 'IRQ' },
    ireland: { name: 'Ireland', twoLetter: 'IE', threeLetter: 'IRL' },
    'isle of man': { name: 'Isle of Man', twoLetter: 'IM', threeLetter: 'IMN' },
    israel: { name: 'Israel', twoLetter: 'IL', threeLetter: 'ISR' },
    italy: { name: 'Italy', twoLetter: 'IT', threeLetter: 'ITA' },
    jamaica: { name: 'Jamaica', twoLetter: 'JM', threeLetter: 'JAM' },
    japan: { name: 'Japan', twoLetter: 'JP', threeLetter: 'JPN' },
    jersey: { name: 'Jersey', twoLetter: 'JE', threeLetter: 'JEY' },
    jordan: { name: 'Jordan', twoLetter: 'JO', threeLetter: 'JOR' },
    kazakhstan: { name: 'Kazakhstan', twoLetter: 'KZ', threeLetter: 'KAZ' },
    kenya: { name: 'Kenya', twoLetter: 'KE', threeLetter: 'KEN' },
    kiribati: { name: 'Kiribati', twoLetter: 'KI', threeLetter: 'KIR' },
    "democratic people's republic of korea": {
        name: "Democratic People's Republic of Korea",
        twoLetter: 'KP',
        threeLetter: 'PRK',
    },
    korea: { name: 'Korea', twoLetter: 'KR', threeLetter: 'KOR' },
    kuwait: { name: 'Kuwait', twoLetter: 'KW', threeLetter: 'KWT' },
    kyrgyzstan: { name: 'Kyrgyzstan', twoLetter: 'KG', threeLetter: 'KGZ' },
    "lao people's democratic republic": {
        name: "Lao People's Democratic Republic",
        twoLetter: 'LA',
        threeLetter: 'LAO',
    },
    latvia: { name: 'Latvia', twoLetter: 'LV', threeLetter: 'LVA' },
    lebanon: { name: 'Lebanon', twoLetter: 'LB', threeLetter: 'LBN' },
    lesotho: { name: 'Lesotho', twoLetter: 'LS', threeLetter: 'LSO' },
    liberia: { name: 'Liberia', twoLetter: 'LR', threeLetter: 'LBR' },
    libya: { name: 'Libya', twoLetter: 'LY', threeLetter: 'LBY' },
    liechtenstein: { name: 'Liechtenstein', twoLetter: 'LI', threeLetter: 'LIE' },
    lithuania: { name: 'Lithuania', twoLetter: 'LT', threeLetter: 'LTU' },
    luxembourg: { name: 'Luxembourg', twoLetter: 'LU', threeLetter: 'LUX' },
    macao: { name: 'Macao', twoLetter: 'MO', threeLetter: 'MAC' },
    macedonia: { name: 'Macedonia', twoLetter: 'MK', threeLetter: 'MKD' },
    madagascar: { name: 'Madagascar', twoLetter: 'MG', threeLetter: 'MDG' },
    malawi: { name: 'Malawi', twoLetter: 'MW', threeLetter: 'MWI' },
    malaysia: { name: 'Malaysia', twoLetter: 'MY', threeLetter: 'MYS' },
    maldives: { name: 'Maldives', twoLetter: 'MV', threeLetter: 'MDV' },
    mali: { name: 'Mali', twoLetter: 'ML', threeLetter: 'MLI' },
    malta: { name: 'Malta', twoLetter: 'MT', threeLetter: 'MLT' },
    'marshall islands': { name: 'Marshall Islands', twoLetter: 'MH', threeLetter: 'MHL' },
    martinique: { name: 'Martinique', twoLetter: 'MQ', threeLetter: 'MTQ' },
    mauritania: { name: 'Mauritania', twoLetter: 'MR', threeLetter: 'MRT' },
    mauritius: { name: 'Mauritius', twoLetter: 'MU', threeLetter: 'MUS' },
    mayotte: { name: 'Mayotte', twoLetter: 'YT', threeLetter: 'MYT' },
    mexico: { name: 'Mexico', twoLetter: 'MX', threeLetter: 'MEX' },
    micronesia: { name: 'Micronesia', twoLetter: 'FM', threeLetter: 'FSM' },
    moldova: { name: 'Moldova', twoLetter: 'MD', threeLetter: 'MDA' },
    monaco: { name: 'Monaco', twoLetter: 'MC', threeLetter: 'MCO' },
    mongolia: { name: 'Mongolia', twoLetter: 'MN', threeLetter: 'MNG' },
    montenegro: { name: 'Montenegro', twoLetter: 'ME', threeLetter: 'MNE' },
    montserrat: { name: 'Montserrat', twoLetter: 'MS', threeLetter: 'MSR' },
    morocco: { name: 'Morocco', twoLetter: 'MA', threeLetter: 'MAR' },
    mozambique: { name: 'Mozambique', twoLetter: 'MZ', threeLetter: 'MOZ' },
    myanmar: { name: 'Myanmar', twoLetter: 'MM', threeLetter: 'MMR' },
    namibia: { name: 'Namibia', twoLetter: 'NA', threeLetter: 'NAM' },
    nauru: { name: 'Nauru', twoLetter: 'NR', threeLetter: 'NRU' },
    nepal: { name: 'Nepal', twoLetter: 'NP', threeLetter: 'NPL' },
    netherlands: { name: 'Netherlands', twoLetter: 'NL', threeLetter: 'NLD' },
    'new caledonia': { name: 'New Caledonia', twoLetter: 'NC', threeLetter: 'NCL' },
    'new zealand': { name: 'New Zealand', twoLetter: 'NZ', threeLetter: 'NZL' },
    nicaragua: { name: 'Nicaragua', twoLetter: 'NI', threeLetter: 'NIC' },
    niger: { name: 'Niger', twoLetter: 'NE', threeLetter: 'NER' },
    nigeria: { name: 'Nigeria', twoLetter: 'NG', threeLetter: 'NGA' },
    niue: { name: 'Niue', twoLetter: 'NU', threeLetter: 'NIU' },
    'norfolk island': { name: 'Norfolk Island', twoLetter: 'NF', threeLetter: 'NFK' },
    'northern mariana islands': {
        name: 'Northern Mariana Islands',
        twoLetter: 'MP',
        threeLetter: 'MNP',
    },
    norway: { name: 'Norway', twoLetter: 'NO', threeLetter: 'NOR' },
    oman: { name: 'Oman', twoLetter: 'OM', threeLetter: 'OMN' },
    pakistan: { name: 'Pakistan', twoLetter: 'PK', threeLetter: 'PAK' },
    palau: { name: 'Palau', twoLetter: 'PW', threeLetter: 'PLW' },
    palestine: { name: 'Palestine', twoLetter: 'PS', threeLetter: 'PSE' },
    panama: { name: 'Panama', twoLetter: 'PA', threeLetter: 'PAN' },
    'papua new guinea': { name: 'Papua New Guinea', twoLetter: 'PG', threeLetter: 'PNG' },
    paraguay: { name: 'Paraguay', twoLetter: 'PY', threeLetter: 'PRY' },
    peru: { name: 'Peru', twoLetter: 'PE', threeLetter: 'PER' },
    philippines: { name: 'Philippines', twoLetter: 'PH', threeLetter: 'PHL' },
    pitcairn: { name: 'Pitcairn', twoLetter: 'PN', threeLetter: 'PCN' },
    poland: { name: 'Poland', twoLetter: 'PL', threeLetter: 'POL' },
    portugal: { name: 'Portugal', twoLetter: 'PT', threeLetter: 'PRT' },
    'puerto rico': { name: 'Puerto Rico', twoLetter: 'PR', threeLetter: 'PRI' },
    qatar: { name: 'Qatar', twoLetter: 'QA', threeLetter: 'QAT' },
    romania: { name: 'Romania', twoLetter: 'RO', threeLetter: 'ROU' },
    russia: { name: 'Russia', twoLetter: 'RU', threeLetter: 'RUS' },
    rwanda: { name: 'Rwanda', twoLetter: 'RW', threeLetter: 'RWA' },
    reunion: { name: 'Reunion', twoLetter: 'RE', threeLetter: 'REU' },
    'saint barthelemy': { name: 'Saint Barthelemy', twoLetter: 'BL', threeLetter: 'BLM' },
    'saint helena': { name: 'Saint Helena', twoLetter: 'SH', threeLetter: 'SHN' },
    'saint kitts and nevis': { name: 'Saint Kitts and Nevis', twoLetter: 'KN', threeLetter: 'KNA' },
    'saint lucia': { name: 'Saint Lucia', twoLetter: 'LC', threeLetter: 'LCA' },
    'saint martin (french)': { name: 'Saint Martin (French)', twoLetter: 'MF', threeLetter: 'MAF' },
    'saint pierre and miquelon': {
        name: 'Saint Pierre and Miquelon',
        twoLetter: 'PM',
        threeLetter: 'SPM',
    },
    'saint vincent and the grenadines': {
        name: 'Saint Vincent and the Grenadines',
        twoLetter: 'VC',
        threeLetter: 'VCT',
    },
    samoa: { name: 'Samoa', twoLetter: 'WS', threeLetter: 'WSM' },
    'san marino': { name: 'San Marino', twoLetter: 'SM', threeLetter: 'SMR' },
    'sao tome and principe': { name: 'Sao Tome and Principe', twoLetter: 'ST', threeLetter: 'STP' },
    'saudi arabia': { name: 'Saudi Arabia', twoLetter: 'SA', threeLetter: 'SAU' },
    senegal: { name: 'Senegal', twoLetter: 'SN', threeLetter: 'SEN' },
    serbia: { name: 'Serbia', twoLetter: 'RS', threeLetter: 'SRB' },
    seychelles: { name: 'Seychelles', twoLetter: 'SC', threeLetter: 'SYC' },
    'sierra leone': { name: 'Sierra Leone', twoLetter: 'SL', threeLetter: 'SLE' },
    singapore: { name: 'Singapore', twoLetter: 'SG', threeLetter: 'SGP' },
    'sint maarten (dutch)': { name: 'Sint Maarten (Dutch)', twoLetter: 'SX', threeLetter: 'SXM' },
    slovakia: { name: 'Slovakia', twoLetter: 'SK', threeLetter: 'SVK' },
    slovenia: { name: 'Slovenia', twoLetter: 'SI', threeLetter: 'SVN' },
    'solomon islands': { name: 'Solomon Islands', twoLetter: 'SB', threeLetter: 'SLB' },
    somalia: { name: 'Somalia', twoLetter: 'SO', threeLetter: 'SOM' },
    'south africa': { name: 'South Africa', twoLetter: 'ZA', threeLetter: 'ZAF' },
    'south georgia and the south sandwich islands': {
        name: 'South Georgia and the South Sandwich Islands',
        twoLetter: 'GS',
        threeLetter: 'SGS',
    },
    'south sudan': { name: 'South Sudan', twoLetter: 'SS', threeLetter: 'SSD' },
    spain: { name: 'Spain', twoLetter: 'ES', threeLetter: 'ESP' },
    'sri lanka': { name: 'Sri Lanka', twoLetter: 'LK', threeLetter: 'LKA' },
    sudan: { name: 'Sudan', twoLetter: 'SD', threeLetter: 'SDN' },
    suriname: { name: 'Suriname', twoLetter: 'SR', threeLetter: 'SUR' },
    'svalbard and jan mayen': {
        name: 'Svalbard and Jan Mayen',
        twoLetter: 'SJ',
        threeLetter: 'SJM',
    },
    swaziland: { name: 'Swaziland', twoLetter: 'SZ', threeLetter: 'SWZ' },
    sweden: { name: 'Sweden', twoLetter: 'SE', threeLetter: 'SWE' },
    switzerland: { name: 'Switzerland', twoLetter: 'CH', threeLetter: 'CHE' },
    'syrian arab republic': { name: 'Syrian Arab Republic', twoLetter: 'SY', threeLetter: 'SYR' },
    taiwan: { name: 'Taiwan', twoLetter: 'TW', threeLetter: 'TWN' },
    tajikistan: { name: 'Tajikistan', twoLetter: 'TJ', threeLetter: 'TJK' },
    tanzania: { name: 'Tanzania', twoLetter: 'TZ', threeLetter: 'TZA' },
    thailand: { name: 'Thailand', twoLetter: 'TH', threeLetter: 'THA' },
    'timor-leste': { name: 'Timor-Leste', twoLetter: 'TL', threeLetter: 'TLS' },
    togo: { name: 'Togo', twoLetter: 'TG', threeLetter: 'TGO' },
    tokelau: { name: 'Tokelau', twoLetter: 'TK', threeLetter: 'TKL' },
    tonga: { name: 'Tonga', twoLetter: 'TO', threeLetter: 'TON' },
    'trinidad and tobago': { name: 'Trinidad and Tobago', twoLetter: 'TT', threeLetter: 'TTO' },
    tunisia: { name: 'Tunisia', twoLetter: 'TN', threeLetter: 'TUN' },
    turkey: { name: 'Turkey', twoLetter: 'TR', threeLetter: 'TUR' },
    turkmenistan: { name: 'Turkmenistan', twoLetter: 'TM', threeLetter: 'TKM' },
    'turks and caicos islands': {
        name: 'Turks and Caicos Islands',
        twoLetter: 'TC',
        threeLetter: 'TCA',
    },
    tuvalu: { name: 'Tuvalu', twoLetter: 'TV', threeLetter: 'TUV' },
    uganda: { name: 'Uganda', twoLetter: 'UG', threeLetter: 'UGA' },
    ukraine: { name: 'Ukraine', twoLetter: 'UA', threeLetter: 'UKR' },
    'united arab emirates': { name: 'United Arab Emirates', twoLetter: 'AE', threeLetter: 'ARE' },
    'united kingdom': { name: 'United Kingdom', twoLetter: 'GB', threeLetter: 'GBR' },
    'united states': { name: 'United States', twoLetter: 'US', threeLetter: 'USA' },
    'united states minor outlying islands': {
        name: 'United States Minor Outlying Islands',
        twoLetter: 'UM',
        threeLetter: 'UMI',
    },
    uruguay: { name: 'Uruguay', twoLetter: 'UY', threeLetter: 'URY' },
    uzbekistan: { name: 'Uzbekistan', twoLetter: 'UZ', threeLetter: 'UZB' },
    vanuatu: { name: 'Vanuatu', twoLetter: 'VU', threeLetter: 'VUT' },
    venezuela: { name: 'Venezuela', twoLetter: 'VE', threeLetter: 'VEN' },
    'viet nam': { name: 'Viet Nam', twoLetter: 'VN', threeLetter: 'VNM' },
    'virgin islands (british)': {
        name: 'Virgin Islands (British)',
        twoLetter: 'VG',
        threeLetter: 'VGB',
    },
    'virgin islands (us)': { name: 'Virgin Islands (US)', twoLetter: 'VI', threeLetter: 'VIR' },
    'wallis and futuna': { name: 'Wallis and Futuna', twoLetter: 'WF', threeLetter: 'WLF' },
    'western sahara': { name: 'Western Sahara', twoLetter: 'EH', threeLetter: 'ESH' },
    yemen: { name: 'Yemen', twoLetter: 'YE', threeLetter: 'YEM' },
    zambia: { name: 'Zambia', twoLetter: 'ZM', threeLetter: 'ZMB' },
    zimbabwe: { name: 'Zimbabwe', twoLetter: 'ZW', threeLetter: 'ZWE' },
};

export const territoryLabels = {
    Australia: 'State/Territory',
    Brazil: 'State',
    Canada: 'Province',
    Ethiopia: 'Region',
    Germany: 'State',
    India: 'State',
    Malaysia: 'State',
    Mexico: 'State',
    'United States': 'State',
    Micronesia: 'State',
    Myanmar: 'Region',
    Nigeria: 'State',
    Palau: 'State',
    'South Sudan': 'State',
};

export const territoryOptions = {
    Australia: {
        ['AU_ACT']: {
            name: 'Australian Capital Territory',
            code: 'ACT',
        },
        ['AU_NSW']: { name: 'New South Wales', code: 'NSW' },
        ['AU_NT']: { name: 'Northern Territory', code: 'NT' },
        ['AU_QLD']: { name: 'Queensland', code: 'QLD' },
        ['AU_SA']: { name: 'South Australia', code: 'SA' },
        ['AU_TAS']: { name: 'Tasmania', code: 'TAS' },
        ['AU_VIC']: { name: 'Victoria', code: 'VIC' },
        ['AU_WA']: { name: 'Western Australia', code: 'WA' },
    },
    Brazil: {
        ['BR_ACT']: {
            name: 'Acre',
            code: 'AC',
        },
        ['BR_AL']: { name: 'Alagoas', code: 'AL' },
        ['BR_AP']: { name: 'Amapa', code: 'AP' },
        ['BR_AM']: { name: 'Amazonas', code: 'AM' },
        ['BR_BA']: { name: 'Bahia', code: 'BA' },
        ['BR_CE']: { name: 'Ceara', code: 'CE' },
        ['BR_ES']: { name: 'Espirito Santo', code: 'ES' },
        ['BR_GO']: { name: 'Goias', code: 'GO' },
        ['BR_MA']: { name: 'Maranhao', code: 'MA' },
        ['BR_MT']: { name: 'Mato Grosso', code: 'MT' },
        ['BR_MS']: { name: 'Mato Grosso do Sul', code: 'MS' },
        ['BR_MG']: { name: 'Minas Gerais', code: 'MG' },
        ['BR_PA']: { name: 'Para', code: 'PA' },
        ['BR_PB']: { name: 'Paraiba', code: 'PB' },
        ['BR_PR']: { name: 'Parana', code: 'PR' },
        ['BR_PE']: { name: 'Pernambuco', code: 'PE' },
        ['BR_PI']: { name: 'Piaui', code: 'PI' },
        ['BR_RJ']: { name: 'Rio de Janeiro', code: 'RJ' },
        ['BR_RN']: { name: 'Rio Grande do Norte', code: 'RN' },
        ['BR_RS']: { name: 'Rio Grande do Sul', code: 'RS' },
        ['BR_RO']: { name: 'Rondonia', code: 'RO' },
        ['BR_RR']: { name: 'Roraima', code: 'RR' },
        ['BR_SC']: { name: 'Santa Catarina', code: 'SC' },
        ['BR_SP']: { name: 'Sao Paulo', code: 'SP' },
        ['BR_SE']: { name: 'Sergipe', code: 'SE' },
        ['BR_TO']: { name: 'Tocantins', code: 'TO' },
    },
    Canada: {
        ['CA_AB']: { name: 'Alberta', code: 'AB' },
        ['CA_BC']: { name: 'British Columbia', code: 'BC' },
        ['CA_MB']: { name: 'Manitoba', code: 'MB' },
        ['CA_NB']: { name: 'New Brunswick', code: 'NB' },
        ['CA_NL']: { name: 'Newfoundland and Labrador', code: 'NL' },
        ['CA_NT']: { name: 'Northwest Territories', code: 'NT' },
        ['CA_NS']: { name: 'Nova Scotia', code: 'NS' },
        ['CA_NU']: { name: 'Nunavut', code: 'NU' },
        ['CA_ON']: { name: 'Ontario', code: 'ON' },
        ['CA_PE']: { name: 'Prince Edward Island', code: 'PE' },
        ['CA_QC']: { name: 'Québec', code: 'QC' },
        ['CA_SK']: { name: 'Saskatchewan', code: 'SK' },
        ['CA_YT']: { name: 'Yukon', code: 'YT' },
    },
    Ethiopia: {
        ['ET_AA']: { name: 'Addis Ababa', code: 'AA' },
        ['ET_AF']: { name: 'Afar', code: 'AF' },
        ['ET_AM']: { name: 'Amhara', code: 'AM' },
        ['ET_BE']: { name: 'Benishangul-Gumuz', code: 'BE' },
        ['ET_DD']: { name: 'Dire Dawa', code: 'DD' },
        ['ET_GA']: { name: 'Gambela Peoples', code: 'GA' },
        ['ET_HA']: { name: 'Harari', code: 'HA' },
        ['ET_OR']: { name: 'Oromia', code: 'OR' },
        ['ET_SO']: { name: 'Somali', code: 'SO' },
        ['ET_SN']: { name: 'Southern Nations, Nationalities, and Peoples', code: 'SN' },
        ['ET_TI']: { name: 'Tigray', code: 'TI' },
    },
    Micronesia: {
        ['FM_YA']: { name: 'Yap', code: 'YA' },
        ['FM_CH']: { name: 'Chuuk', code: 'CH' },
        ['FM_PO']: { name: 'Pohnpei', code: 'PO' },
        ['FM_KO']: { name: 'Kosrae', code: 'KO' },
    },
    Germany: {
        ['DE_BW']: { name: 'Baden-Wurttemberg', code: 'BW' },
        ['DE_BY']: { name: 'Bavaria', code: 'BY' },
        ['DE_BE']: { name: 'Berlin', code: 'BE' },
        ['DE_BB']: { name: 'Bradenburg', code: 'BB' },
        ['DE_HB']: { name: 'Bremen', code: 'HB' },
        ['DE_HH']: { name: 'Hamburg', code: 'HH' },
        ['DE_HE']: { name: 'Hesse', code: 'HE' },
        ['DE_NI']: { name: 'Lower Saxony', code: 'NI' },
        ['DE_MV']: { name: 'Mecklenburg-Vorpommern', code: 'MV' },
        ['DE_NW']: { name: 'North Rhine-Westphalia', code: 'NW' },
        ['DE_RP']: { name: 'Rhineland Palatinate', code: 'RP' },
        ['DE_SL']: { name: 'Saarland', code: 'SL' },
        ['DE_SN']: { name: 'Saxony', code: 'SN' },
        ['DE_ST']: { name: 'Saxony-Anhalt', code: 'ST' },
        ['DE_SH']: { name: 'Schleswig-Holstein', code: 'SH' },
        ['DE_TH']: { name: 'Thuringia', code: 'TH' },
    },
    India: {
        ['IN_AP']: { name: 'Andhra Pradesh', code: 'AP' },
        ['IN_AR']: { name: 'Arunachal Pradesh', code: 'AR' },
        ['IN_AS']: { name: 'Assam', code: 'AS' },
        ['IN_BR']: { name: 'Bihar', code: 'BR' },
        ['IN_CT']: { name: 'Chhattisgarh', code: 'CT' },
        ['IN_GA']: { name: 'Goa', code: 'GA' },
        ['IN_GJ']: { name: 'Gujarat', code: 'GJ' },
        ['IN_HR']: { name: 'Harayana', code: 'HR' },
        ['IN_HP']: { name: 'Himachal Pradesh', code: 'HP' },
        ['IN_JH']: { name: 'Jharkhand', code: 'JH' },
        ['IN_KA']: { name: 'Karnataka', code: 'SN' },
        ['IN_KL']: { name: 'Kerala', code: 'KL' },
        ['IN_MP']: { name: 'Madhya Pradesh', code: 'MP' },
        ['IN_MH']: { name: 'Maharashtra', code: 'MH' },
        ['IN_MN']: { name: 'Manipur', code: 'MN' },
        ['IN_ML']: { name: 'Meghalaya', code: 'ML' },
        ['IN_MZ']: { name: 'Mizoram', code: 'MZ' },
        ['IN_NL']: { name: 'Nagaland', code: 'NL' },
        ['IN_OR']: { name: 'Odisha', code: 'OR' },
        ['IN_PB']: { name: 'Punjab', code: 'PB' },
        ['IN_RJ']: { name: 'Rajasthan', code: 'RJ' },
        ['IN_SK']: { name: 'Sikkim', code: 'SK' },
        ['IN_TN']: { name: 'Tamil Nadu', code: 'TN' },
        ['IN_TG']: { name: 'Telangana', code: 'TG' },
        ['IN_TR']: { name: 'Tripura', code: 'TR' },
        ['IN_UP']: { name: 'Uttar Pradesh', code: 'UP' },
        ['IN_UT']: { name: 'Uttarakhand', code: 'UT' },
        ['IN_WB']: { name: 'West Bengal', code: 'WB' },
    },
    Malaysia: {
        ['MY_JH']: { name: 'Johor', code: 'JH' },
        ['MY_KH']: { name: 'Kedah', code: 'KH' },
        ['MY_KL']: { name: 'Kuala Lumpur', code: 'KL' },
        ['MY_KN']: { name: 'Kelantan', code: 'KN' },
        ['MY_LA']: { name: 'Labuan', code: 'LA' },
        ['MY_ME']: { name: 'Melaka', code: 'ME' },
        ['MY_NS']: { name: 'Negeri Sembilan', code: 'NS' },
        ['MY_PG']: { name: 'Pulau Pinang', code: 'PG' },
        ['MY_PH']: { name: 'Pahang', code: 'PH' },
        ['MY_PJ']: { name: 'Putrajaya', code: 'PJ' },
        ['MY_PK']: { name: 'Perak', code: 'PK' },
        ['MY_PL']: { name: 'Perlis', code: 'PL' },
        ['MY_SA']: { name: 'Sabah', code: 'SA' },
        ['MY_SK']: { name: 'Sarawak', code: 'SK' },
        ['MY_SL']: { name: 'Selangor', code: 'SL' },
        ['MY_TE']: { name: 'Terengganu', code: 'TE' },
    },
    Mexico: {
        ['MX_AGS']: { name: 'Aguascalientes', code: 'AGS' },
        ['MX_BC']: { name: 'Baja California', code: 'BC' },
        ['MX_BCS']: { name: 'Baja California Sur', code: 'BCS' },
        ['MX_CAM']: { name: 'Campeche', code: 'CAM' },
        ['MX_CHIH']: { name: 'Chihuahua', code: 'CHIH' },
        ['MX_CHIS']: { name: 'Chiapas', code: 'CHIS' },
        ['MX_COAH']: { name: 'Coahuila', code: 'COAH' },
        ['MX_COL']: { name: 'Colima', code: 'COL' },
        ['MX_DF']: { name: 'Distrito Federal', code: 'DF' },
        ['MX_DGO']: { name: 'Durango', code: 'DGO' },
        ['MX_GRO']: { name: 'Guerrero', code: 'GRO' },
        ['MX_GTO']: { name: 'Guanajuato', code: 'GTO' },
        ['MX_HGO']: { name: 'Hidalgo', code: 'HGO' },
        ['MX_JAL']: { name: 'Jalisco', code: 'JAL' },
        ['MX_MEX']: { name: 'Mexico', code: 'MEX' },
        ['MX_MICH']: { name: 'Michoacan', code: 'MICH' },
        ['MX_MOR']: { name: 'Morelos', code: 'MOR' },
        ['MX_NAY']: { name: 'Nayarit', code: 'NAY' },
        ['MX_NL']: { name: 'Nuevo Leon', code: 'NL' },
        ['MX_OAX']: { name: 'Oaxaca', code: 'OAX' },
        ['MX_PUE']: { name: 'Puebla', code: 'PUE' },
        ['MX_QR']: { name: 'Quintana ROO', code: 'QR' },
        ['MX_QRO']: { name: 'Queretaro', code: 'QRO' },
        ['MX_SIN']: { name: 'Sinaloa', code: 'SIN' },
        ['MX_SLP']: { name: 'San Luis Potosi', code: 'SLP' },
        ['MX_SON']: { name: 'Sonara', code: 'SON' },
        ['MX_TAB']: { name: 'Tabasco', code: 'TAB' },
        ['MX_TAMPS']: { name: 'Tamaulipas', code: 'TAMPS' },
        ['MX_TLAX']: { name: 'Tlaxcala', code: 'TLAX' },
        ['MX_VER']: { name: 'Veracruz', code: 'VER' },
        ['MX_YUC']: { name: 'Yucatan', code: 'YUC' },
        ['MX_ZAC']: { name: 'Zacatecas', code: 'ZAC' },
    },
    Myanmar: {
        ['MM_AY']: { name: 'Ayeyardwady', code: 'AY' },
        ['MM_BA']: { name: 'Bago', code: 'BA' },
        ['MM_CH']: { name: 'Chin', code: 'CH' },
        ['MM_KC']: { name: 'Kachin', code: 'KC' },
        ['MM_KH']: { name: 'Kayah', code: 'KH' },
        ['MM_KN']: { name: 'Kayin', code: 'KN' },
        ['MM_MG']: { name: 'Magway', code: 'MG' },
        ['MM_ML']: { name: 'Mandalay', code: 'ML' },
        ['MM_MO']: { name: 'Mon', code: 'MO' },
        ['MM_NY']: { name: 'Naypyidaw', code: 'NY' },
        ['MM_RA']: { name: 'Rakhine', code: 'RA' },
        ['MM_SA']: { name: 'Sagaing', code: 'SA' },
        ['MM_SH']: { name: 'Shan', code: 'SH' },
        ['MM_TN']: { name: 'Tanintharyi', code: 'TN' },
        ['MM_YA']: { name: 'Yangon', code: 'YA' },
    },
    Nigeria: {
        ['NI_FC']: { name: 'Abuja', code: 'FC' },
        ['NI_AB']: { name: 'Abia', code: 'AB' },
        ['NI_AD']: { name: 'Adamawa', code: 'AD' },
        ['NI_AK']: { name: 'Akwa Ibom', code: 'AK' },
        ['NI_AN']: { name: 'Anambra', code: 'AN' },
        ['NI_BA']: { name: 'Bauchi', code: 'BA' },
        ['NI_BY']: { name: 'Bayelsa', code: 'BY' },
        ['NI_BE']: { name: 'Benue', code: 'BE' },
        ['NI_BO']: { name: 'Borno', code: 'BO' },
        ['NI_CR']: { name: 'Cross River', code: 'CR' },
        ['NI_DE']: { name: 'Delta', code: 'DE' },
        ['NI_EB']: { name: 'Ebonyi', code: 'EB' },
        ['NI_ED']: { name: 'Edo', code: 'ED' },
        ['NI_EK']: { name: 'Ekiti', code: 'EK' },
        ['NI_EN']: { name: 'Enugu', code: 'EN' },
        ['NI_GO']: { name: 'Gombe', code: 'GO' },
        ['NI_IM']: { name: 'Imo', code: 'IM' },
        ['NI_JI']: { name: 'Jigawa', code: 'JI' },
        ['NI_KD']: { name: 'Kaduna', code: 'KD' },
        ['NI_KN']: { name: 'Kano', code: 'KN' },
        ['NI_KT']: { name: 'Katsina', code: 'KT' },
        ['NI_KE']: { name: 'Kebbi', code: 'KE' },
        ['NI_KO']: { name: 'Kogi', code: 'KO' },
        ['NI_KW']: { name: 'Kwara', code: 'KW' },
        ['NI_LA']: { name: 'Lagos', code: 'LA' },
        ['NI_NA']: { name: 'Nassarawa', code: 'NA' },
        ['NI_NI']: { name: 'Niger', code: 'NI' },
        ['NI_OG']: { name: 'Ogun', code: 'OG' },
        ['NI_ON']: { name: 'Ondo', code: 'ON' },
        ['NI_OS']: { name: 'Osun', code: 'OS' },
        ['NI_OY']: { name: 'Oyo', code: 'OY' },
        ['NI_PL']: { name: 'Plateau', code: 'PL' },
        ['NI_RI']: { name: 'Rivers', code: 'RI' },
        ['NI_SO']: { name: 'Sokoto', code: 'SO' },
        ['NI_TA']: { name: 'Taraba', code: 'TA' },
        ['NI_YO']: { name: 'Yobe', code: 'YO' },
        ['NI_ZA']: { name: 'Zamfara', code: 'ZA' },
    },
    Palau: {
        ['PW_KA']: { name: 'Kayangel', code: 'KA' },
        ['PW_AM']: { name: 'Aimeliik', code: 'AM' },
        ['PW_AR']: { name: 'Airai', code: 'AR' },
        ['PW_ME']: { name: 'Melekeok', code: 'ME' },
        ['PW_ND']: { name: 'Ngaraard', code: 'ND' },
        ['PW_NC']: { name: 'Ngarchelong', code: 'NC' },
        ['PW_NM']: { name: 'Ngardmau', code: 'NM' },
        ['PW_NL']: { name: 'Ngeremlengui', code: 'NL' },
        ['PW_NP']: { name: 'Ngatpang', code: 'NP' },
        ['PW_NS']: { name: 'Ngchesar', code: 'NS' },
        ['PW_NW']: { name: 'Nigiwal', code: 'NW' },
        ['PW_AN']: { name: 'Angaur', code: 'AN' },
        ['PW_KO']: { name: 'Koror', code: 'KO' },
        ['PW_PE']: { name: 'Peleliu', code: 'PE' },
        ['PW_HA']: { name: 'Hatohobei', code: 'HA' },
        ['PW_SO']: { name: 'Sonsorol', code: 'SO' },
    },
    'South Sudan': {
        ['SS_BG']: { name: 'Central Equatoria', code: 'BG' },
        ['SS_EE']: { name: 'East Equatoria', code: 'EE' },
        ['SS_JG']: { name: 'Jungoli', code: 'JG' },
        ['SS_LK']: { name: 'Lakes', code: 'LK' },
        ['SS_BN']: { name: 'North Bahr-al-Ghazal', code: 'BN' },
        ['SS_UY']: { name: 'Unity', code: 'UY' },
        ['SS_NU']: { name: 'Upper Nile', code: 'NU' },
        ['SS_WR']: { name: 'Warap', code: 'WR' },
        ['SS_BW']: { name: 'West Bahr-al-Ghazal', code: 'BW' },
        ['SS_EW']: { name: 'West Equatoria', code: 'EW' },
    },
    'United States': {
        ['US_AL']: { name: 'Alabama', code: 'AL' },
        ['US_AK']: { name: 'Alaska', code: 'AK' },
        ['US_AS']: { name: 'American Samoa', code: 'AS' },
        ['US_AZ']: { name: 'Arizona', code: 'AZ' },
        ['US_AR']: { name: 'Arkansas', code: 'AR' },
        ['US_CA']: { name: 'California', code: 'CA' },
        ['US_CO']: { name: 'Colorado', code: 'CO' },
        ['US_CT']: { name: 'Connecticut', code: 'CT' },
        ['US_DE']: { name: 'Delaware', code: 'DE' },
        ['US_DC']: { name: 'District Of Columbia', code: 'DC' },
        ['US_FM']: {
            name: 'Federated States Of Micronesia',
            code: 'FM',
        },
        ['US_FL']: { name: 'Florida', code: 'FL' },
        ['US_GA']: { name: 'Georgia', code: 'GA' },
        ['US_GU']: { name: 'Guam', code: 'GU' },
        ['US_HI']: { name: 'Hawaii', code: 'HI' },
        ['US_ID']: { name: 'Idaho', code: 'ID' },
        ['US_IL']: { name: 'Illinois', code: 'IL' },
        ['US_IN']: { name: 'Indiana', code: 'IN' },
        ['US_IA']: { name: 'Iowa', code: 'IA' },
        ['US_KS']: { name: 'Kansas', code: 'KS' },
        ['US_KY']: { name: 'Kentucky', code: 'KY' },
        ['US_LA']: { name: 'Louisiana', code: 'LA' },
        ['US_ME']: { name: 'Maine', code: 'ME' },
        ['US_MH']: { name: 'Marshall Islands', code: 'MH' },
        ['US_MD']: { name: 'Maryland', code: 'MD' },
        ['US_MA']: { name: 'Massachusetts', code: 'MA' },
        ['US_MI']: { name: 'Michigan', code: 'MI' },
        ['US_MN']: { name: 'Minnesota', code: 'MN' },
        ['US_MS']: { name: 'Mississippi', code: 'MS' },
        ['US_MO']: { name: 'Missouri', code: 'MO' },
        ['US_MT']: { name: 'Montana', code: 'MT' },
        ['US_NE']: { name: 'Nebraska', code: 'NE' },
        ['US_NV']: { name: 'Nevada', code: 'NV' },
        ['US_NH']: { name: 'New Hampshire', code: 'NH' },
        ['US_NJ']: { name: 'New Jersey', code: 'NJ' },
        ['US_NM']: { name: 'New Mexico', code: 'NM' },
        ['US_NY']: { name: 'New York', code: 'NY' },
        ['US_NC']: { name: 'North Carolina', code: 'NC' },
        ['US_ND']: { name: 'North Dakota', code: 'ND' },
        ['US_MP']: { name: 'Northern Mariana Islands', code: 'MP' },
        ['US_OH']: { name: 'Ohio', code: 'OH' },
        ['US_OK']: { name: 'Oklahoma', code: 'OK' },
        ['US_OR']: { name: 'Oregon', code: 'OR' },
        ['US_PW']: { name: 'Palau', code: 'PW' },
        ['US_PA']: { name: 'Pennsylvania', code: 'PA' },
        ['US_PR']: { name: 'Puerto Rico', code: 'PR' },
        ['US_RI']: { name: 'Rhode Island', code: 'RI' },
        ['US_SC']: { name: 'South Carolina', code: 'SC' },
        ['US_SD']: { name: 'South Dakota', code: 'SD' },
        ['US_TN']: { name: 'Tennessee', code: 'TN' },
        ['US_TX']: { name: 'Texas', code: 'TX' },
        ['US_UT']: { name: 'Utah', code: 'UT' },
        ['US_VT']: { name: 'Vermont', code: 'VT' },
        ['US_VI']: { name: 'Virgin Islands', code: 'VI' },
        ['US_VA']: { name: 'Virginia', code: 'VA' },
        ['US_WA']: { name: 'Washington', code: 'WA' },
        ['US_WV']: { name: 'West Virginia', code: 'WV' },
        ['US_WI']: { name: 'Wisconsin', code: 'WI' },
        ['US_WY']: { name: 'Wyoming', code: 'WY' },
    },
};

export const postalCodeRegExp = {
    UK: /^([A-Z]){1}([0-9][0-9]|[0-9]|[A-Z][0-9][A-Z]|[A-Z][0-9][0-9]|[A-Z][0-9]|[0-9][A-Z]){1}([ ])?([0-9][A-z][A-z]){1}$/i,
    JE: /^JE\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
    GG: /^GY\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
    IM: /^IM\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
    US: /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/,
    CA: /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/i,
    DE: /^\d{5}$/,
    JP: /^\d{3}-\d{4}$/,
    FR: /^\d{2}[ ]?\d{3}$/,
    AU: /^\d{4}$/,
    IT: /^\d{5}$/,
    CH: /^\d{4}$/,
    AT: /^\d{4}$/,
    ES: /^\d{5}$/,
    NL: /^\d{4}[ ]?[A-Z]{2}$/,
    BE: /^\d{4}$/,
    DK: /^\d{4}$/,
    SE: /^\d{3}[ ]?\d{2}$/,
    NO: /^\d{4}$/,
    BR: /^\d{5}[-]?\d{3}$/,
    PT: /^\d{4}([-]\d{3})?$/,
    FI: /^\d{5}$/,
    AX: /^22\d{3}$/,
    KR: /^\d{3}[-]\d{3}$/,
    CN: /^\d{6}$/,
    TW: /^\d{3}(\d{2})?$/,
    SG: /^\d{6}$/,
    DZ: /^\d{5}$/,
    AD: /^AD\d{3}$/,
    AR: /^([A-HJ-NP-Z])?\d{4}([A-Z]{3})?$/,
    AM: /^(37)?\d{4}$/,
    AZ: /^\d{4}$/,
    BH: /^((1[0-2]|[2-9])\d{2})?$/,
    BD: /^\d{4}$/,
    BB: /^(BB\d{5})?$/,
    BY: /^\d{6}$/,
    BM: /^[A-Z]{2}[ ]?[A-Z0-9]{2}$/,
    BA: /^\d{5}$/,
    IO: /^BBND 1ZZ$/,
    BN: /^[A-Z]{2}[ ]?\d{4}$/,
    BG: /^\d{4}$/,
    KH: /^\d{5}$/,
    CV: /^\d{4}$/,
    CL: /^\d{7}$/,
    CR: /^\d{4,5}|\d{3}-\d{4}$/,
    HR: /^\d{5}$/,
    CY: /^\d{4}$/,
    CZ: /^\d{3}[ ]?\d{2}$/,
    DO: /^\d{5}$/,
    EC: /^([A-Z]\d{4}[A-Z]|(?:[A-Z]{2})?\d{6})?$/,
    EG: /^\d{5}$/,
    EE: /^\d{5}$/,
    FO: /^\d{3}$/,
    GE: /^\d{4}$/,
    GR: /^\d{3}[ ]?\d{2}$/,
    GL: /^39\d{2}$/,
    GT: /^\d{5}$/,
    HT: /^\d{4}$/,
    HN: /^(?:\d{5})?$/,
    HU: /^\d{4}$/,
    IS: /^\d{3}$/,
    IN: /^\d{6}$/,
    ID: /^\d{5}$/,
    IL: /^\d{5}$/,
    JO: /^\d{5}$/,
    KZ: /^\d{6}$/,
    KE: /^\d{5}$/,
    KW: /^\d{5}$/,
    LA: /^\d{5}$/,
    LV: /^\d{4}$/,
    LB: /^(\d{4}([ ]?\d{4})?)?$/,
    LI: /^(948[5-9])|(949[0-7])$/,
    LT: /^\d{5}$/,
    LU: /^\d{4}$/,
    MK: /^\d{4}$/,
    MY: /^\d{5}$/,
    MV: /^\d{5}$/,
    MT: /^[A-Z]{3}[ ]?\d{2,4}$/,
    MU: /^(\d{3}[A-Z]{2}\d{3})?$/,
    MX: /^\d{5}$/,
    MD: /^\d{4}$/,
    MC: /^980\d{2}$/,
    MA: /^\d{5}$/,
    NP: /^\d{5}$/,
    NZ: /^\d{4}$/,
    NI: /^((\d{4}-)?\d{3}-\d{3}(-\d{1})?)?$/,
    NG: /^(\d{6})?$/,
    OM: /^(PC )?\d{3}$/,
    PK: /^\d{5}$/,
    PY: /^\d{4}$/,
    PH: /^\d{4}$/,
    PL: /^\d{2}-\d{3}$/,
    PR: /^00[679]\d{2}([ -]\d{4})?$/,
    RO: /^\d{6}$/,
    RU: /^\d{6}$/,
    SM: /^4789\d$/,
    SA: /^\d{5}$/,
    SN: /^\d{5}$/,
    SK: /^\d{3}[ ]?\d{2}$/,
    SI: /^\d{4}$/,
    ZA: /^\d{4}$/,
    LK: /^\d{5}$/,
    TJ: /^\d{6}$/,
    TH: /^\d{5}$/,
    TN: /^\d{4}$/,
    TR: /^\d{5}$/,
    TM: /^\d{6}$/,
    UA: /^\d{5}$/,
    UY: /^\d{5}$/,
    UZ: /^\d{6}$/,
    VA: /^00120$/,
    VE: /^\d{4}$/,
    ZM: /^\d{5}$/,
    AS: /^96799$/,
    CC: /^6799$/,
    CK: /^\d{4}$/,
    RS: /^\d{6}$/,
    ME: /^8\d{4}$/,
    CS: /^\d{5}$/,
    YU: /^\d{5}$/,
    CX: /^6798$/,
    ET: /^\d{4}$/,
    FK: /^FIQQ 1ZZ$/,
    NF: /^2899$/,
    FM: /^(9694[1-4])([ -]\d{4})?$/,
    GF: /^9[78]3\d{2}$/,
    GN: /^\d{3}$/,
    GP: /^9[78][01]\d{2}$/,
    GS: /^SIQQ 1ZZ$/,
    GU: /^969[123]\d([ -]\d{4})?$/,
    GW: /^\d{4}$/,
    HM: /^\d{4}$/,
    IQ: /^\d{5}$/,
    KG: /^\d{6}$/,
    LR: /^\d{4}$/,
    LS: /^\d{3}$/,
    MG: /^\d{3}$/,
    MH: /^969[67]\d([ -]\d{4})?$/,
    MN: /^\d{6}$/,
    MP: /^9695[012]([ -]\d{4})?$/,
    MQ: /^9[78]2\d{2}$/,
    NC: /^988\d{2}$/,
    NE: /^\d{4}$/,
    VI: /^008(([0-4]\d)|(5[01]))([ -]\d{4})?$/,
    PF: /^987\d{2}$/,
    PG: /^\d{3}$/,
    PM: /^9[78]5\d{2}$/,
    PN: /^PCRN 1ZZ$/,
    PW: /^96940$/,
    RE: /^9[78]4\d{2}$/,
    SH: /^(ASCN|STHL) 1ZZ$/,
    SJ: /^\d{4}$/,
    SO: /^\d{5}$/,
    SZ: /^[HLMS]\d{3}$/,
    TC: /^TKCA 1ZZ$/,
    WF: /^986\d{2}$/,
    XK: /^\d{5}$/,
    YT: /^976\d{2}$/,
    INTL: /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/i,
};

export const countryNameLookup = country => {
    const normalizedCountry = (country || '').replace(/\s\s+/g, ' ').trim().toLowerCase();
    let foundCountry;

    if (normalizedCountry.length === 2) {
        Object.keys(countryLookup).some(countryName => {
            if (countryLookup[countryName].twoLetter.toLowerCase() === normalizedCountry) {
                foundCountry = countryLookup[countryName].name;
                return true;
            }
        });
    } else if (normalizedCountry.length === 3) {
        Object.keys(countryLookup).some(countryName => {
            if (countryLookup[countryName].threeLetter.toLowerCase() === normalizedCountry) {
                foundCountry = countryLookup[countryName].name;
                return true;
            }
        });
    } else {
        foundCountry = countryLookup[normalizedCountry]
            ? countryLookup[normalizedCountry].name
            : foundCountry;
    }

    return foundCountry;
};

export const countryCodeLookup = (country, type = 'twoLetter') => {
    const foundCountry = countryNameLookup(country);
    const countryCodes = foundCountry ? countryLookup[foundCountry.toLowerCase()] : undefined;
    return countryCodes ? countryCodes[type] : undefined;
};

export const countryHasTerritories = country => !!territoryOptions[countryNameLookup(country)];

export const territoryLabelForCountry = country =>
    territoryLabels[countryNameLookup(country)] || '';

export const territoryOptionsForCountry = country =>
    territoryOptions[countryNameLookup(country)] || {};

/**
 * @function
 * @param {string} jurisdiction The jurisdiction to lookup
 * @returns {{ country: string, territory: string, code: string, name: string }} The country, territory, code, and name of the jurisdiction
 */
export const jurisdictionLookup = jurisdiction => {
    const normalizedJurisdiction = (jurisdiction || '').replace(/\s\s+/g, ' ').trim().toLowerCase();
    const [country, territory] = normalizedJurisdiction.replace('-', '_').split('_');
    const countryName = countryNameLookup(country);
    const foundCountry = countryName ? countryLookup[countryName.toLowerCase()] : undefined;
    if (foundCountry) {
        if (!territory) {
            return {
                country: foundCountry.twoLetter,
                territory: '',
                code: foundCountry.twoLetter,
                name: countryName,
            };
        }
        const territories = territoryOptions[countryName] || {};
        const foundTerritory = territories[`${foundCountry.twoLetter}_${territory}`.toUpperCase()];
        if (foundTerritory) {
            const { code, name } = foundTerritory;
            const foundJurisdiction = `${foundCountry.twoLetter}_${code}`;
            return {
                country: foundCountry.twoLetter,
                territory: code,
                code: foundJurisdiction,
                name: `${name}`,
            };
        }
    }
};

export const validatePostalCode = (value, country) => {
    let response = false;

    if (value && value.trim() !== '') {
        const countryCode = countryCodeLookup(country);

        if (countryCode && countryCode in postalCodeRegExp) {
            response = postalCodeRegExp[countryCode].test(value);
        } else {
            response = Object.keys(postalCodeRegExp).some(key =>
                postalCodeRegExp[key].test(value.trim())
            );
        }
    }

    return response;
};

export const validateZipCode = value => validatePostalCode(value, 'US');

export const validateTerritory = (value, country) => {
    let response = false;
    const trimedValue = value.trim();

    if (value && trimedValue !== '') {
        const territories = territoryOptionsForCountry(country);
        const countryCode = countryCodeLookup(country);
        response =
            // Quick Check
            !!territories[`${countryCode}_${value}`.toUpperCase()] ||
            // Loop check
            !!Object.values(territories).some(
                ({ code, name }) =>
                    code === trimedValue.toUpperCase() ||
                    name.toLowerCase() === trimedValue.toLowerCase()
            );
    }

    return response;
};

export const validateState = value => validateTerritory(value, 'US');
