import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const styles = theme => {
    return {
        root: {
            color: theme.palette.error.main,
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
    };
};

const useStyles = makeStyles(styles);

const Error = props => {
    const { children, ...rest } = props;
    const classes = useStyles(props);
    return (
        <Typography {...rest} classes={classes}>
            {children}
        </Typography>
    );
};

Error.propTypes = {
    children: PropTypes.node,
};

export default Error;
