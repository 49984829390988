export default theme => ({
    title: {},
    content: {
        ...theme.typography.body1,
        backgroundColor: props => props?.contentBackgroundColor || undefined,
        margin: 0,
        padding: theme.spacing(0, 3, 1),
        '$title + &': {
            // This is a hack to fix the overflow hidden between the dialog title and the dialog content.
            marginTop: theme.spacing(-1),
            paddingTop: theme.spacing(1),
        },
    },
    actions: {
        display: 'flex',
        margin: theme.spacing(2, 0),
        padding: theme.spacing(0, 3),
    },
    defaultActions: {
        marginLeft: 'auto',
    },
    body: {
        margin: theme.spacing(0, 0, 2),
        padding: theme.spacing(0, 3),
        whiteSpace: 'pre-line',
    },
    cancelButton: {
        color: theme.palette.error.main,
        marginRight: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    noScroll: {
        opacity: 0.5,
        pointerEvents: 'none',
    },
    delegateScroll: {
        display: 'flex',
        flexDirection: 'column',
    },
});
