import {
    Badge,
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import React, { Fragment, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { isRTL, translate, translationExists } from '/b2b/common/helpers/i18n';
import reducer, { initReducer } from './Translations.reducer';

import Actions from './Translations.actions';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import Locale from '/b2b/common/components/Locale';
import LocaleSelect from '/b2b/common/components/LocaleSelect';
import LocalizedTooltip from '/b2b/common/components/LocalizedTooltip';
import PropTypes from 'prop-types';
import ResponsiveDialog from '/b2b/common/components/ResponsiveDialog';
import makeStyles from '@mui/styles/makeStyles';
import styles from './Translations.styles';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';

const useStyles = makeStyles(styles);

const selectLocale = (dispatch, setLocale, setTranslation, translations, localeCode) => {
    setLocale(localeCode);
    setTranslation(translations[localeCode] || '');
    dispatch(Actions.selectLocale(localeCode));
};

const renderLocaleListItem = ({ classes, localeCode, translation, onDelete, onSelect }) => (
    <ListItem
        key={localeCode}
        className="drag-handle"
        alignItems="flex-start"
        dense
        divider
        button
        disableRipple
        disableTouchRipple
        ContainerComponent="div"
        onClick={() => onSelect(localeCode)}
    >
        <ListItemText
            classes={{
                primary: classes.listItemLocale,
                secondary: classes.listItemTranslation,
            }}
            primary={
                <Fragment>
                    ({localeCode.toLowerCase()}) <Locale path={`languages.${localeCode}.label`} />
                </Fragment>
            }
            secondary={translation}
        />
        <ListItemSecondaryAction>
            <LocalizedTooltip title="common.table.delete.label" edge="end">
                <IconButton
                    component="div"
                    tabIndex={0}
                    role="button"
                    onClick={() => onDelete(localeCode)}
                    size="large"
                >
                    <DeleteIcon />
                </IconButton>
            </LocalizedTooltip>
        </ListItemSecondaryAction>
    </ListItem>
);

const Translations = props => {
    const { label, name, onChange, value = {}, usePopper, ...remain } = props;
    const classes = useStyles(props);

    const [state, dispatch] = useReducer(reducer, value, initReducer);
    const { locales, nextLocale, open, order, translations } = state;

    const [locale, setLocale] = useState(nextLocale);
    const [translation, setTranslation] = useState('');

    const handleOpen = useCallback(() => {
        dispatch(Actions.openDialog());
    }, []);

    const handleCancel = useCallback(() => {
        dispatch(Actions.cancelDialog(value));
    }, [value]);

    const handleConfirm = useCallback(() => {
        onChange(translations);
        dispatch(Actions.confirmDialog(translations));
    }, [onChange, translations]);

    const handleAdd = useCallback(() => {
        dispatch(Actions.add(locale, translation));
    }, [locale, translation]);

    const handleUpdate = useCallback((localeCode, value) => {
        setTranslation(value);
        dispatch(Actions.update(localeCode, value));
    }, []);

    const handleSelect = useBoundCallback(selectLocale, [
        dispatch,
        setLocale,
        setTranslation,
        translations,
    ]);

    const handleKeyDown = useCallback(
        event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleAdd();
            }
        },
        [handleAdd]
    );

    const handleDelete = useCallback(localeCode => dispatch(Actions.delete(localeCode)), []);

    const placeholder = `common.cmp.${name.split('translations.').pop()}`;
    const placeholderString = translationExists(placeholder, locale)
        ? translate(placeholder, locale)
        : '';
    const localesString = useMemo(() => Object.keys(translations).join(', '), [translations]);
    const localeListItems = useMemo(() => {
        return order.map(localeCode =>
            renderLocaleListItem({
                classes,
                localeCode,
                translation: translations[localeCode],
                onDelete: handleDelete,
                onSelect: handleSelect,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        classes.listItemLocale,
        classes.listItemTranslation,
        order,
        translations,
        handleDelete,
        handleSelect,
    ]);

    useEffect(() => {
        handleSelect(nextLocale);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextLocale]);

    useEffect(() => {
        dispatch(Actions.revert(value));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(value)]);

    return (
        <Fragment>
            <LocalizedTooltip
                title={
                    localesString
                        ? [
                              label,
                              '-',
                              'views.ConsentCustomization.translations.hasTheFollowing',
                              localesString,
                          ]
                        : [label, '-', 'views.ConsentCustomization.translations.none']
                }
                tabIndex={0}
            >
                <div className={classes.listItem} onClick={handleOpen}>
                    <Badge
                        classes={{ root: classes.listItemButton }}
                        color="primary"
                        badgeContent={order.length}
                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                        <LanguageIcon />
                    </Badge>
                    <Locale path={label} />
                </div>
            </LocalizedTooltip>
            <ResponsiveDialog
                cancelText={translate('common.cancel')}
                confirmText={translate('common.save')}
                onConfirm={handleConfirm}
                onClose={handleCancel}
                onCancel={handleCancel}
                open={open}
                disablePortal={usePopper}
                title={
                    <Toolbar classes={{ root: classes.toolbar }}>
                        <Typography variant="h4">
                            <Locale path="common.translate" />
                        </Typography>
                        <Typography variant="body1">
                            <Locale path={label} />
                        </Typography>
                        <LocaleSelect options={locales} onChange={handleSelect} value={locale} />
                        <TextField
                            onChange={event => handleUpdate(locale, event.target.value)}
                            onKeyDown={handleKeyDown}
                            className={isRTL(locale) ? classes.rtl : ''}
                            classes={{ root: classes.input }}
                            value={translation}
                            multiline
                            minRows={1}
                            maxRows={3}
                            variant="outlined"
                            placeholder={placeholderString}
                            name={name}
                        />
                    </Toolbar>
                }
                {...remain}
            >
                {localeListItems}
            </ResponsiveDialog>
        </Fragment>
    );
};

Translations.propTypes = {
    disabled: PropTypes.bool,
    localeOptions: PropTypes.arrayOf(PropTypes.string),
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    usePopper: PropTypes.bool,
    value: PropTypes.object,
};

export default Translations;
