import { createSchema } from '/b2b/common/helpers/Schema';

export default createSchema({
    phone: {
        label: 'Phone Number',
        props: {
            disabled: true,
        },
    },
    code: {
        label: 'Verification Code',
        presence: { allowEmpty: false },
        props: {
            placeholder: 'Enter your SMS code...',
            InputLabelProps: {
                shrink: true,
            },
        },
        length: {
            is: 6,
            notValid: 'is not valid',
        },
    },
});
