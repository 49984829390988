/* eslint react/prop-types: 0 */
import React from 'react';
import { SvgIcon } from '@mui/material';

import { white } from '../../colors';

export default function AskExpert(props) {
    return (
        <SvgIcon fontSize="large" viewBox="0 0 24 24" {...props}>
            <path
                d="M19,23c-0.2,0-0.4-0.1-0.6-0.2l-2.2-1.5C16,21.1,15.9,21,15.8,21c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.3,0-0.6,0
                h-1.8c-1.2,0-1.9,0-2.6-0.3c-0.6-0.3-1-0.7-1.3-1.3c-0.3-0.5-0.3-1-0.3-1.8l-1.4,1.4c-0.6,0.6-0.9,0.9-1.5,1c-0.5,0-0.9-0.2-1.3-0.5
                c-0.4-0.4-0.4-0.9-0.4-1.7V16c0,0-0.1,0-0.1,0c-1.7-0.3-3.1-1.6-3.4-3.4c0-0.3,0-0.6,0-1.1V6.8C1,5,1,4,1.4,3.2
                c0.4-0.8,1-1.4,1.7-1.7C4,1,5,1,6.8,1h7.4C16,1,17,1,17.8,1.4c0.8,0.4,1.4,1,1.7,1.7C20,4,20,5,20,6.8V10c0.6,0,1,0.1,1.4,0.3
                c0.6,0.3,1,0.7,1.3,1.3C23,12.3,23,13,23,14.2v2.7c0,1,0,1.6-0.2,2.1c-0.3,0.7-0.9,1.3-1.6,1.6c-0.3,0.1-0.7,0.2-1.1,0.2V22
                c0,0.4-0.2,0.7-0.5,0.9C19.3,23,19.2,23,19,23z M11,15v1.7c0,0.9,0,1.4,0.1,1.7c0.1,0.2,0.3,0.3,0.4,0.4c0.2,0.1,0.8,0.1,1.7,0.1H15
                c0.4,0,0.7,0,0.9,0c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.8,0.5l0.6,0.4v-0.2c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3
                c0.7,0,1.2,0,1.4-0.1c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.1-0.7,0.1-1.4v-2.7c0-0.9,0-1.4-0.1-1.7c-0.1-0.2-0.2-0.3-0.4-0.4
                C20.2,12,19.7,12,18.8,12h-5.6c-0.9,0-1.4,0-1.7,0.1c-0.2,0.1-0.3,0.3-0.4,0.4C11,12.8,11,13.3,11,14.2V15L11,15z M6.8,3
                C5.3,3,4.5,3,4.1,3.2C3.7,3.4,3.4,3.7,3.2,4.1C3,4.5,3,5.3,3,6.8v4.6c0,0.5,0,0.7,0,0.8c0.1,0.9,0.8,1.6,1.7,1.7
                c1.1,0.2,1.8,1,1.8,2v1.1L9,14.6v-0.4c0-1.2,0-1.9,0.3-2.6c0.3-0.6,0.7-1,1.3-1.3C11.3,10,12,10,13.2,10H18V6.8c0-1.5,0-2.3-0.2-2.7
                c-0.2-0.4-0.5-0.7-0.9-0.9C16.5,3,15.7,3,14.2,3H6.8z"
                fill={white}
            />
        </SvgIcon>
    );
}
