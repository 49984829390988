import { Ability, AbilityBuilder } from '@casl/ability';
import {
    PERMISSIONS,
    PERMISSIONS_PATHS_MAP,
    ROLE_NOTIFICATIONS_MAP,
    UNRESTRICTED_PATHS,
} from '../../authentication/constants';

import flatten from 'lodash/flatten';
import memoize from 'fast-memoize';

const blockedInDevTools = () => 'Blocked in Redux Dev Tools.';

export const abilityStrategy = ({
    abilities /* eslint-disable-line no-unused-vars */,
    role,
    roleId,
    permissions,
    ...remain
}) => {
    const { can, rules } = new AbilityBuilder();

    rules.toJSON = blockedInDevTools;

    can('view', UNRESTRICTED_PATHS);

    if (ROLE_NOTIFICATIONS_MAP[role]) {
        can('write', Object.values(ROLE_NOTIFICATIONS_MAP[role]));
    }

    if (!permissions || !Array.isArray(permissions) || permissions.length === 0) {
        return {
            ...remain,
            role,
            roleId,
        };
    }

    return {
        ...remain,
        role,
        roleId,
        permissions,
        abilities: permissions.reduce(
            ([permissionRules, permissionMap], permission) => {
                if (permission === PERMISSIONS.ALL && !permissionMap.has(permission)) {
                    permissionMap.set(permission, true);
                } else if (permission !== PERMISSIONS.ALL) {
                    const [permissionKey, permissionMethod] = permission.split('_');
                    const rolePaths = PERMISSIONS_PATHS_MAP[permissionKey];

                    if (rolePaths && permissionMap.get(`${permissionKey}_view`) !== rolePaths) {
                        permissionMap.set(`${permissionKey}_view`, rolePaths);
                        can('view', rolePaths);
                    }

                    if (!permissionMap.has(permission)) {
                        permissionMap.set(permission, permissionMethod);
                        can(permissionMethod, permissionKey);
                    }
                }
                return [permissionRules, permissionMap];
            },
            [rules, new Map()]
        )[0],
    };
};

const fromPermissions = (permissions = {}) => {
    const { can, cannot, rules } = new AbilityBuilder(Ability);
    Object.entries(permissions?.allow || permissions).forEach(([perm, contentType]) => {
        can(perm, contentType);
    });
    Object.entries(permissions?.deny || {}).forEach(([perm, contentType]) => {
        cannot(perm, contentType);
    });
    return rules;
};

const determinePermissions = memoize(
    (roles = [], customer) => {
        const customerRules = fromPermissions(customer?.permissions);
        const roleRules = flatten(roles.map(({ abilities = [] } = {}) => abilities));
        return [...roleRules, ...customerRules];
    },
    {
        serializer: ([roles, customer]) => {
            return `[${Object.values(roles)
                .map(({ roleId }) => roleId)
                .join(',')}]_${
                customer?.permissions ? JSON.stringify(customer.permissions) : 'rolesOnly'
            }`;
        },
    }
);

export const isAllowedTo = (roles = [], customer = {}, checks = {}, additionalPermissions) => {
    const additionalRules = fromPermissions(additionalPermissions);
    const rules = determinePermissions(roles, customer).concat(additionalRules);

    const ability = new Ability(rules);
    const allChecks = Object.entries(checks);
    let counter = 0;
    const isAble = allChecks.reduce((able, [contentType, actions]) => {
        const actionChecks = Object.entries(actions);
        return (
            able &&
            actionChecks.length > 0 &&
            actionChecks.reduce(
                (actionAble, [action, can]) =>
                    !!++counter &&
                    actionAble &&
                    (can ? ability.can(action, contentType) : ability.cannot(action, contentType)),
                able
            )
        );
    }, allChecks.length > 0);
    return !!counter && isAble;
};
