/* eslint-disable react/no-children-prop */
import { Redirect, Route, Switch } from 'react-router-dom';

import PropTypes from 'prop-types';
import React from 'react';
import SignIn from './SignIn';
import SignInConfirm from './SignInConfirm';
import SignUpResend from './SignUpResend';
import UnauthenticatedPaths from '../../../routing/UnauthenticatedRoutes/UnauthenticatedRoutes.paths';

const SignInRoutes = props => {
    const { needsSignInConfirmed } = props;

    let initialRoute = null;

    if (needsSignInConfirmed) {
        initialRoute = `${UnauthenticatedPaths.SIGN_IN}/confirm`;
    }

    return (
        <Switch>
            {initialRoute && <Route children={<Redirect to={initialRoute} />} />}
            <Route exact path={UnauthenticatedPaths.SIGN_UP_RESEND} children={<SignUpResend />} />
            <Route exact path={UnauthenticatedPaths.SIGN_IN_CONFIRM} children={<SignInConfirm />} />
            <Route exact path={`${UnauthenticatedPaths.SIGN_IN}`} children={<SignIn />} />
            <Route children={<Redirect to={UnauthenticatedPaths.SIGN_IN} />} />
        </Switch>
    );
};

SignInRoutes.propTypes = {
    needsSignInConfirmed: PropTypes.bool,
};

export default SignInRoutes;
