import { fetchAmplifyContext, signIn } from './SignIn.commands';

import Component from './SignIn.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const mapStateToProps = state => {
    return {
        isLoading: fromState.Auth.isLoading(state) || fromState.Auth.isFetchingContext(state),
        email: fromState.Auth.getEmail(state),
        rememberMe: fromState.Auth.shouldRemember(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSignIn: params => {
            const { useCognito } = params;
            if (!useCognito) {
                return dispatch(fetchAmplifyContext(params));
            }
            return dispatch(signIn(params));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
