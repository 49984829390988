import { Autocomplete, Chip, TextField } from '@mui/material';
import React, { useCallback } from 'react';

import Locale from '/b2b/common/components/Locale';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import merge from 'lodash/merge';
import styles from './ValidationChips.styles';
import { translate } from '/b2b/common/helpers/i18n';

const useStyles = makeStyles(styles);

const ValidationChips = props => {
    const {
        label,
        onBlur,
        onChange,
        placeholder,
        loading,
        error,
        options,
        renderTags: providedTagRenderer,
        newChipKeyCodes = [],
        ChipProps,
        value,
        inputProps,
        InputProps,
        InputLabelProps,
    } = props;
    const classes = useStyles(props);
    const [inputValue, setInputValue] = React.useState('');

    const renderTags = useCallback(
        (tagValue, getTagProps) => {
            return providedTagRenderer
                ? providedTagRenderer(tagValue, getTagProps)
                : tagValue.map((option, index) => (
                      <Chip
                          {...ChipProps}
                          key={option}
                          label={option}
                          {...getTagProps({ index })}
                      />
                  ));
        },
        [providedTagRenderer]
    );

    const handleBlur = useCallback(
        event => {
            if (event.target.value) {
                const newValue = [...value, event.target.value];
                setInputValue('');
                onChange && onChange(newValue);
            }
            onBlur && onBlur(event);
            return;
        },
        [value, onBlur, onChange]
    );

    const handleChange = (event, value, reason) => {
        if (reason === 'blur') {
            return;
        }
        switch (reason) {
            case 'createOption':
            case 'selectOption': {
                setInputValue('');
                onChange && onChange(value);
                break;
            }
            case 'removeOption': {
                setInputValue('');
                onChange && onChange(value);
                break;
            }
            case 'clear': {
                setInputValue('');
                onChange && onChange([]);
            }
        }
    };

    const handleKeyDown = useCallback(
        event => {
            if (newChipKeyCodes.includes(event.keyCode)) {
                event.preventDefault();
                event.stopPropagation();
                const newValue = [...value, event.target.value];
                setInputValue('');
                handleChange(event, newValue, 'createOption');
            }
        },
        [newChipKeyCodes.join(','), value, handleChange]
    );

    const handleInputChange = useCallback((event, value, reason) => {
        switch (reason) {
            case 'input': {
                setInputValue(value);
                break;
            }
            case 'clear': {
                setInputValue('');
            }
        }
    }, []);

    const renderInput = useCallback(
        ({ InputProps: InputPropsAutocomplete, inputProps: inputPropsAutocomplete, ...params }) => {
            return (
                <TextField
                    {...params}
                    onBlur={handleBlur}
                    InputProps={merge(InputPropsAutocomplete, InputProps || {})}
                    inputProps={merge(inputPropsAutocomplete, inputProps || {})}
                    onKeyDownCapture={handleKeyDown}
                    error={error}
                    variant="outlined"
                    label={<Locale path={label} />}
                    placeholder={translate(placeholder)}
                    InputLabelProps={InputLabelProps}
                />
            );
        },
        [
            handleBlur,
            handleKeyDown,
            label,
            placeholder,
            error,
            InputProps,
            inputProps,
            InputLabelProps,
        ]
    );

    return (
        <Autocomplete
            classes={classes}
            loading={loading}
            options={options || []}
            value={value}
            ChipProps={ChipProps}
            filterSelectedOptions
            multiple
            freeSolo
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            isOptionEqualToValue={(option, selectedValue) => option.value === selectedValue.value}
            renderTags={renderTags}
            renderInput={renderInput}
        />
    );
};

ValidationChips.propTypes = {
    children: PropTypes.node,
    ChipProps: PropTypes.object,
    error: PropTypes.bool,
    inputProps: PropTypes.object,
    InputProps: PropTypes.object,
    label: PropTypes.string,
    loading: PropTypes.bool,
    name: PropTypes.string,
    newChipKeyCodes: PropTypes.arrayOf(PropTypes.number),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    options: PropTypes.array,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    providedTagRenderer: PropTypes.func,
    renderTags: PropTypes.func,
    value: PropTypes.array,
    InputLabelProps: PropTypes.object,
};

export default ValidationChips;
