// eslint-disable-next-line security/detect-non-literal-regexp
const VALID_URL = new RegExp(
    '^' +
        // protocol identifier
        '(?:(?:https?|ftp)://)?' +
        // user:pass authentication
        '(?:\\S+(?::\\S*)?@)?' +
        '(?:' +
        // IP address exclusion
        // private & local networks
        '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
        '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
        '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
        // IP address dotted notation octets
        // excludes loopback network 0.0.0.0
        // excludes reserved space >= 224.0.0.0
        // excludes network & broacast addresses
        // (first & last IP address of each class)
        '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
        '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
        '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
        '|' +
        // host name
        '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' +
        // domain name
        '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*' +
        // TLD identifier
        '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))' +
        // TLD may end with dot
        '\\.?' +
        ')' +
        // port number
        '(?::\\d{2,5})?' +
        // resource path
        '(?:[/?#]\\S*)?' +
        '$',
    'i'
);

// eslint-disable-next-line security/detect-unsafe-regex
const VALID_IP = new RegExp(
    '((^s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))s*$)|(^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*$))',
    'i'
);

export const VALID_DOMAIN_CHARACTERS = /^[a-z0-9-.]+$/;
// eslint-disable-next-line security/detect-unsafe-regex
export const VALID_DOMAIN = /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z][a-z-]{0,61}[a-z]/;

export const validateURL = value => VALID_URL.test(value);
export const validateIP = value => VALID_IP.test(value);
export const validateDomain = value => VALID_DOMAIN.test(value);
export const formatHostname = (value, throwError) => {
    let url;
    try {
        const domainName = value
            .trim()
            .toLowerCase()
            // eslint-disable-next-line security/detect-unsafe-regex
            .replace(/^((https?:)?\/\/)/, '')
            .split('/')[0]
            .replace(/[^a-z0-9-.]/g, '');
        try {
            url = new URL(domainName);
        } catch (e) {
            // not valid url
            try {
                url = new URL(`http://${domainName}`);
            } catch (e) {
                if (throwError) {
                    throw new Error('Not a valid hostname');
                }
            }
        }
        if (throwError && !validateURL(url.href)) {
            throw new Error('Not a valid hostname');
        }
    } catch (e) {
        // `value` is probably not a string and we cannot parse it as a URL
        if (throwError) {
            throw new Error('Not a valid hostname');
        }
    }
    return url ? url.hostname : value;
};

export const getURLParts = url => {
    try {
        const hostname = formatHostname(url);
        const regex = new RegExp('([a-z-0-9]{2,63}).([a-z.]{2,5})$');
        const urlParts = regex.exec(hostname);
        const domain = urlParts[1];
        const tld = urlParts[2];
        const subdomains = hostname.replace(`${domain}.${tld}`, '').slice(0, -1);
        return {
            domain,
            tld,
            subdomains:
                subdomains.split('.').filter(subdomain => subdomain && subdomain !== 'www') || [],
        };
    } catch (e) {
        return {
            domain: undefined,
            tld: undefined,
            subdomains: [],
        };
    }
};

// Currently only used for trusthub page route. Starting slashes are not
// allowed, but we must not remove trailing slashes until after user
// finishes typing.
export const formatUrlPath = urlPath =>
    urlPath
        // Starting slash(es)
        .replace(/^\/*/, '')
        // Invalid characters
        // \p{L}\p{Nl}\p{Nd} denote unicode categories: https://www.regular-expressions.info/unicode.html#category
        .replace(/[^\p{L}\p{Nl}\p{Nd}~\-_/]/giu, '');
