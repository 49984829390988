import palette from '../palette';
import theme from '../default';

export default {
    root: {
        minHeight: 0,
        position: 'relative',
        zIndex: 150,
        paddingBottom: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: theme.spacing(0, 1),
        },
    },
    left: {
        padding: theme.spacing(0, 2),
        marginBottom: theme.spacing(-2),
        [theme.breakpoints.down('md')]: {
            paddingBottom: '0 !important',
            paddingTop: '0 !important',
            margin: 0,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    titleText: {
        fontSize: '1.75rem',
        padding: theme.spacing(2.75, 0),
        '&:empty': {
            display: 'none',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1.25rem',
        },
    },
    actions: {
        alignSelf: 'flex-start',
        height: 0,
        [theme.breakpoints.down('md')]: {
            textAlign: 'right',
        },
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center',
            order: -1,
            marginLeft: theme.spacing(-1),
        },
    },
    icon: {
        boxShadow: theme.shadows[4],
        backgroundColor: palette.background.paper,
        marginLeft: theme.spacing(1),
        transform: `translate(0,-50%)`,
        '&:hover': {
            color: palette.primary.hover,
            backgroundColor: palette.background.default,
        },
    },
    iconActive: {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        marginLeft: theme.spacing(1),
        transform: `translate(0,-50%)`,
        '&:hover': {
            backgroundColor: palette.primary.hover,
            color: palette.primary.contrastText,
        },
    },
    filterPaper: {
        maxWidth: '50%',
        width: `auto`,
        [theme.breakpoints.down('md')]: {
            maxWidth: `calc(100% - ${theme.spacing(4)})`,
        },
    },
};
