import {
    Avatar,
    Chip,
    CircularProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
} from '@mui/material';
import { Dialog, DialogTitle, Grid } from '@mui/material';
import React, { useEffect } from 'react';

import BrowseIcon from '@mui/icons-material/Face';
import GroupWorkIcon from '@mui/icons-material/DonutSmall';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import startCase from 'lodash/startCase';
import styles from './CustomerSelect.styles';

const useStyles = makeStyles(styles);

const CustomerSelect = props => {
    const { isLoading, customers, onInitialLoad, onClose, onSelectCustomer, userId } = props;
    const classes = useStyles(props);
    useEffect(() => {
        userId && onInitialLoad(userId);
    }, [userId]);

    return isLoading ? (
        <Grid
            className={classes.content}
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item lg={6} xs={3}>
                <CircularProgress />
            </Grid>
        </Grid>
    ) : (
        <Dialog onClose={onClose} aria-labelledby="dialog-title" open>
            <DialogTitle id="dialog-title">
                {customers && customers.length > 0 ? 'Select a Customer' : 'We are Sorry'}
            </DialogTitle>
            <List className={classes.content}>
                {customers && customers.length > 0 ? (
                    customers.map(customer => {
                        const { customerId, name, subscription = {}, photo } = customer;
                        const {
                            planId = '',
                            status = 'active',
                            color = 'inherit',
                            textColor = 'inherit',
                        } = subscription;
                        return (
                            <ListItem
                                key={customerId}
                                button
                                onClick={() => onSelectCustomer(customer)}
                            >
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={photo}>
                                        <GroupWorkIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={name} secondary={startCase(status)} />
                                <ListItemSecondaryAction>
                                    <Chip
                                        size="small"
                                        label={startCase(planId)}
                                        style={{ backgroundColor: color, color: textColor }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })
                ) : (
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <BrowseIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="You are not assigned to any accounts." />
                    </ListItem>
                )}
            </List>
        </Dialog>
    );
};

CustomerSelect.propTypes = {
    customers: PropTypes.arrayOf(
        PropTypes.shape({
            customerId: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            image: PropTypes.string,
        })
    ).isRequired,
    isLoading: PropTypes.bool,
    onClose: PropTypes.func,
    onInitialLoad: PropTypes.func,
    onSelectCustomer: PropTypes.func,
    userId: PropTypes.string,
};

export default CustomerSelect;
