import { formatVendor } from '../vendors/format';
import moment from 'moment';

export const formatLitigation = data => {
    const {
        clientPartyNameID,
        vendorId = clientPartyNameID,
        clientPartyName,
        vendorName = clientPartyName,
        unicourtName,
        caseKey,
        typeName,
        ...remain
    } = data;
    return {
        // TODO: Get unique litigation keys
        litigationId: `${vendorId}-${caseKey}`,
        name: unicourtName,
        ...remain,
        vendors: [
            formatVendor({
                id: vendorId,
                name: vendorName,
            }),
        ],
        case: {
            id: caseKey,
            caseKey,
            type: typeName,
        },
    };
};

export const formatUnicourtCase = details => {
    const { id, attorneys, docket, documents, case_meta: caseMeta, ...remain } = details;

    return {
        ...remain,
        id,
        docket: docket.map((docket, index) => ({
            ...docket,
            // Docket entries need an id
            id: index,
        })),
        attorneys: attorneys.map(attorney => ({
            ...attorney,
            cases: [id],
        })),
        documents: documents
            .map(document => {
                const text = document.description || '';
                const date = new Date(text.slice(0, text.indexOf(':'))).toUTCString();
                if (date instanceof Date && !isNaN(date)) {
                    return {
                        ...document,
                        date: moment(date).valueOf(),
                    };
                } else {
                    // No date in the document
                    return document;
                }
            })
            .sort((a, b) => b.date - a.date),
        ...caseMeta,
    };
};
