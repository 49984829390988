/* eslint-disable camelcase */
import { types as ActionTypes, asyncEvents as AsyncEvents } from './AuthUser.actions';
import {
    CRUDDetailsReducers,
    genericReducer,
    passthroughReducer,
} from '../../../common/helpers/ReduxHelpers';
import customers, { initialState as initialCustomers } from './customers/AuthUserCustomers.reducer';
import notifications, {
    initialState as initialNotifications,
} from './notifications/AuthUserNotifications.reducer';

import { types as AuthActionTypes } from '../Auth.actions';
import { types as AuthCustomerActionTypes } from '../customer/AuthCustomer.actions';
import { BROWSE_CUSTOMER } from '../../../common/services/customer';
import { types as NavActionTypes } from '../../../common/state/nav/Nav.actions';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import schema from '/b2b/users/state/db/User.schema';

export const initialState = {
    customers: initialCustomers,
    emailVerified: false,
    error: null,
    featureFlags: [],
    id: null,
    loading: false,
    notifications: initialNotifications,
    password: null,
    permissions: {},
    phone: null,
    phoneVerified: false,
    signature: null,
};

export const defaultError = 'An error occurred';

const determinePermissions = details => {
    const { identities = {}, permissions: providedPermissions = {} } = details;
    const permissions = { ...providedPermissions };
    if (Object.keys(identities).length === 0) {
        permissions.allow = permissions.allow || {};
        // Cognito user...enabled cognito permissions
        ['updateCognito'].forEach(perm => {
            permissions.allow[perm] = permissions[perm] || [];
            permissions.allow[perm].push('account.mfa');
            permissions.allow[perm].push('account.password');
        });
    }
    return permissions;
};

const updateUserAttributes = (
    state,
    { params: { username, password, newPassword } = {}, response: { details = {} } = {} } = {}
) => {
    const {
        email = username || state.id,
        phone_number = state.phone,
        phone = phone_number,
        phone_number_verified: phoneVerified = state.phoneVerified,
        email_verified: emailVerified = state.emailVerified,
    } = details;
    return {
        ...state,
        emailVerified,
        id: `${email}`.toLowerCase(),
        permissions: determinePermissions(details),
        password: password || newPassword,
        phone,
        phoneVerified,
    };
};

export default reduceReducers(
    initialState,
    combineReducers({
        customers,
        notifications,
        ...CRUDDetailsReducers(
            initialState,
            Object.keys(AsyncEvents).map(key => ActionTypes[key]),
            schema
        ),
        signature: genericReducer(initialState.signature, {
            [NavActionTypes.changeCustomer]: (state, customer = BROWSE_CUSTOMER) => {
                const { signature = initialState.signature } = customer || {};
                return signature;
            },
            [AuthCustomerActionTypes.fetchDetailsSuccess]: (state, { response: customer }) => {
                const { signature = initialState.signature } = customer || {};
                return signature;
            },
        }),
        password: passthroughReducer(initialState.password),
        permissions: passthroughReducer(initialState.permissions),
        phone: passthroughReducer(initialState.phone),
        phoneVerified: genericReducer(initialState.phoneVerified, {
            [AuthActionTypes.verifyPhoneSuccess]: () => true,
        }),
        emailVerified: genericReducer(initialState.emailVerified, {
            [AuthActionTypes.verifyEmailSuccess]: () => true,
        }),
        featureFlags: genericReducer(initialState.featureFlags, {
            [ActionTypes.fetchDetailsSuccess]: (state, { response: { featureFlags } }) => [
                ...initialState.featureFlags,
                ...(featureFlags || state.featureFlags),
            ],
            [ActionTypes.setFeatureFlags]: (state, featureFlags) =>
                Array.isArray(featureFlags)
                    ? state.map(flag => {
                          const { enabled, name } = flag;
                          const idx = featureFlags.indexOf(name);
                          if (idx >= 0 && !enabled) {
                              return { ...flag, enabled: true };
                          } else if (idx === -1 && enabled) {
                              return { ...flag, enabled: false };
                          }
                          return flag;
                      })
                    : state,
        }),
    }),
    genericReducer(initialState, {
        [AuthActionTypes.signInFailure]: (state, { params, error }) => {
            if (error.code === 'UserNotConfirmedException') {
                // The error happens if the user didn't finish the confirmation step when signing up
                return {
                    ...state,
                    id: params.userId || params.username || null,
                };
            } else if (error.code === 'PasswordResetRequiredException') {
                // The error happens when the password is reset in the Cognito console
                return {
                    ...state,
                    id: params.userId || params.username || null,
                };
            } else if (error.code === 'NotAuthorizedException') {
                // The error happens when the password is incorrect, or a temporary password expiration
                if (error.message.indexOf('Temporary password has expired') >= 0) {
                    // Save the user
                    return {
                        ...state,
                        id: params.userId || params.username || params.email || null,
                    };
                }
            }
            return state;
        },
        [AuthActionTypes.signInFederatedBegin]: (
            state,
            { params: { username: email } = {} } = {}
        ) => {
            return {
                ...state,
                // This needs to be CASE-SENSITIVE because SSO is NOT Cognito
                id: email || state.id,
            };
        },
        [AuthActionTypes.signInFederatedFailure]: (state, payload = {}) => {
            const { error = null } = payload || {};
            const { message = error } = error || {};
            return {
                ...state,
                id: initialState.id,
                error: message,
            };
        },
        [AuthActionTypes.signInSuccess]: updateUserAttributes,
        [AuthActionTypes.setPreferredMfaSuccess]: updateUserAttributes,
        [AuthActionTypes.confirmSignInSuccess]: updateUserAttributes,
        [AuthActionTypes.resetPasswordSuccess]: (state, { params }) => {
            return {
                ...state,
                id: params.userId || params.username || null,
                password: params.newPassword || null,
            };
        },
        [AuthActionTypes.forgotPasswordSuccess]: (state, { params, response = {} }) => {
            const {
                codeDeliveryDetails: {
                    attributeName = 'email',
                    deliveryMedium = 'email',
                    destination = params.userId || params.username || null,
                } = {},
            } = response;
            if (deliveryMedium.toLowerCase === 'email' && attributeName.toLowerCase() === 'email') {
                return {
                    ...state,
                    id: destination,
                };
            }
            return state;
        },
        [AuthActionTypes.updateUserAttributesSuccess]: updateUserAttributes,
    })
);
