import startCase from 'lodash/startCase';

const VALID_UPPERCASE = /([A-Z])/;
const VALID_LOWERCASE = /([a-z])/;
const VALID_NUMERIC = /([0-9])/;
const VALID_SPECIAL_CHAR = /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]/;

const textAreaConversion = document.createElement('textarea');

export const htmlDecode = value => {
    // HTML input into a TextArea will not execute.
    textAreaConversion.innerHTML = value;
    return textAreaConversion.textContent;
};

export const htmlEncode = value => {
    // HTML added to a TextArea as textContent will be encoded.
    textAreaConversion.textContent = htmlDecode(value); // Decode first so we don't double encode
    return textAreaConversion.innerHTML;
};

export const getInitials = (name = '', numberOfInitials = 2) => {
    const names = name
        .replace(/\s+/, ' ')
        .split(' ')
        .filter(name => name.indexOf('.') !== name.length - 1)
        .map(startCase)
        .join(' ')
        .split(' ');

    const firstInitial = names.shift() || '';
    const lastInitials = names.reverse().reduce((name, initial) => {
        if (name.length < numberOfInitials - 1) {
            name = `${initial[0]}${name}`;
        }
        return name;
    }, '');
    return `${firstInitial[0] || ''}${lastInitials}`.toUpperCase();
};

export const VALIDATION_CHECKS = {
    CUSTOM: 'custom',
    LOWERCASE: 'lowercase',
    MAX_LENGTH: 'maxLength',
    MIN_LENGTH: 'minLength',
    NUMERIC: 'numeric',
    SPECIAL: 'special',
    UPPERCASE: 'uppercase',
};

export const validateString = (value, validation = {}, details = false) => {
    const {
        [VALIDATION_CHECKS.CUSTOM]: custom = false,
        [VALIDATION_CHECKS.LOWERCASE]: lowercase = false,
        [VALIDATION_CHECKS.MAX_LENGTH]: maxLength = Number.MAX_SAFE_INTEGER,
        [VALIDATION_CHECKS.MIN_LENGTH]: minLength = 0,
        [VALIDATION_CHECKS.NUMERIC]: numeric = false,
        [VALIDATION_CHECKS.SPECIAL]: special = false,
        [VALIDATION_CHECKS.UPPERCASE]: uppercase = false,
    } = validation;
    const check = {
        [VALIDATION_CHECKS.CUSTOM]: value === undefined || !custom || custom.test(value),
        [VALIDATION_CHECKS.LOWERCASE]:
            value === undefined || !lowercase || VALID_LOWERCASE.test(value),
        [VALIDATION_CHECKS.MAX_LENGTH]: value === undefined || value.length <= maxLength,
        [VALIDATION_CHECKS.MIN_LENGTH]: value === undefined || value.length >= minLength,
        [VALIDATION_CHECKS.NUMERIC]: value === undefined || !numeric || VALID_NUMERIC.test(value),
        [VALIDATION_CHECKS.SPECIAL]:
            value === undefined || !special || VALID_SPECIAL_CHAR.test(value),
        [VALIDATION_CHECKS.UPPERCASE]:
            value === undefined || !uppercase || VALID_UPPERCASE.test(value),
    };
    return details
        ? Object.entries(check).reduce(
              (checks, [key, value]) =>
                  validation[key] === undefined ? checks : ((checks[key] = value), checks),
              {}
          )
        : !validation || Object.values(check).every(value => value === true);
};
