import Component from './Stats.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const mapStateToProps = state => {
    return {
        data: fromState.Dashboard.getStatsData(state),
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
