import { translate } from './i18n';

export const ROLE_LABELS = {
    Purchasing: 'purchasing',
    ConsentViewer: 'consentViewer',
    MonitoringManager: 'monitoringManager',
    DataSourceOwner: 'subjectRightsAssignee',
    AssessmentAdmin: 'assessmentAdmin',
    AssessmentManager: 'assessmentManager',
    AssessmentViewer: 'assessmentViewer',
    Admin: 'admin',
    AdvisoryManager: 'advisoryManager',
    DataMappingManager: 'dataMappingManager',
    DataMappingViewer: 'dataMappingViewer',
    DataRequestsManager: 'subjectRightsManager',
    ConsentManager: 'consentManager',
    TrustHubAdmin: 'trusthubAdmin',
    TrustHubEditor: 'trusthubEditor',
    UcManager: 'ucManager',
    UcViewer: 'ucViewer',
};

export const getTranslatedRoleLabel = ({ value }) =>
    translate(`users.roles.${ROLE_LABELS[value] || value}` || value);
