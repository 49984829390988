import { cobalt, emerald, white } from '../../colors';

/* eslint react/prop-types: 0 */
import React from 'react';
import { SvgIcon } from '@mui/material';

export default function Litigation(props) {
    const { active, ...remain } = props;
    const primary = active ? emerald : emerald;
    const secondary = active ? cobalt : white;
    return (
        <SvgIcon fontSize="large" viewBox="0 0 77.44 61.11" {...remain}>
            <path
                d="m38.83 53.79h-38.83v-6h38.83zm11.26-21.93h-50.09v6h50.09zm11.64-15.93h-61.73v6h61.73zm-13.94-15.93h-47.79v6h47.79z"
                fill={secondary}
            />
            <path
                d="m77.44 56.89-7.73-7.81a13.74 13.74 0 1 0 -4.25 4.23l7.72 7.8zm-26.9-15.11a7.65 7.65 0 1 1 7.65 7.65 7.66 7.66 0 0 1 -7.65-7.65z"
                fill={primary}
            />
        </SvgIcon>
    );
}
