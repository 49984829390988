import { types as ActionTypes } from './Nav.actions';
import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import { BEACON_MESSAGES } from './Nav.constants';
import { combineReducers } from 'redux';
import { genericReducer } from '../../helpers/ReduxHelpers';
import { matchPath } from 'react-router-dom';
import reduceReducers from 'reduce-reducers';

export const initialState = {
    canNavigate: true,
};

export default reduceReducers(
    initialState,
    combineReducers({
        canNavigate: genericReducer(initialState.canNavigate, {
            [ActionTypes.routeChanged]: () => true,
        }),
    }),
    genericReducer(initialState, {
        [ActionTypes.routeChanged]: (state, { location }) => {
            // Place any route change effects not involving the state here

            const { messageId } =
                BEACON_MESSAGES.find(({ path, exact, strict }) => {
                    const match = matchPath(location.pathname, {
                        path,
                        exact,
                        strict,
                    });
                    return !!match;
                }) || {};

            try {
                window.Beacon('show-message', messageId);
            } catch (e) {
                // Becon does not exist
            }
            return state;
        },
        [AuthActionTypes.logout]: () => initialState,
    })
);
