import palette from '../palette';

export default {
    variants: [],
    styleOverrides: {
        root: {
            backgroundColor: 'transparent',
            '&.Mui-selected': {
                background: palette.primary.light,
                '&:hover': {
                    background: palette.primary.light,
                },
                '&:focus': {
                    background: palette.primary.light,
                },
            },
        },
        day: {},
        hidden: {},
        current: {},
        daySelected: {},
        dayDisabled: {
            opacity: 0.2,
        },
    },
};
