import * as Forms from './forms/AuthCustomerDSARForms.selectors';
import * as Stats from './stats/AuthCustomerDSARStats.selectors';

import { DOMAIN } from './AuthCustomerDSAR.constants';
import get from 'lodash/get';

export const forms = Forms;
export const stats = Stats;

export const getStore = state => get(state, DOMAIN) || {};
