/* eslint-disable camelcase */
import * as AuthCustomer from './customer/AuthCustomer.selectors';
import * as AuthUser from './user/AuthUser.selectors';

import { AUTH_STATES } from '../constants';
import { DOMAIN } from './Auth.constants';
import get from 'lodash/get';

export const customer = AuthCustomer;
export const user = AuthUser;

export const devMode = ['development', 'dev', 'ci'].includes(process.env.MODE);

export const getStore = state => get(state, DOMAIN) || {};

export const getAuthState = state => getStore(state).authState;
export const getError = state => getStore(state).error;
export const getPhone = state => user.getPhone(state) || '';
export const getCustomer = state => customer.getStore(state);
export const getCustomerId = state => customer.getId(state);
export const getCustomerToken = state => getStore(state).customerToken;
export const getEmail = state => `${user.getEmail(state) || ''}`.toLowerCase();
export const getMfaType = state => getStore(state).mfaType;
export const getMfaOptions = state => getStore(state).mfaOptions || [];
export const getChallengeName = state => get(getStore(state), ['cognitoUser', 'challengeName'], '');
export const getChallengeParam = state =>
    get(getStore(state), ['cognitoUser', 'challengeParam'], {});
export const getSignUpCodeResent = state => getStore(state).resentCode;
export const getPermissionsDetermined = state =>
    (customer.getRoles(state).length > 0 && user.getRoles(state).length > 0) || false;

export const isAuthenticated = state => getAuthState(state) === AUTH_STATES.signedIn;
export const isCustomerSelected = state => getCustomerToken(state) !== null;
export const needsAccountConfirmation = state => getAuthState(state) === AUTH_STATES.confirmSignUp;
export const isEmailVerified = state => user.isEmailVerified(state) || false;
export const isPhoneVerified = state => user.isPhoneVerified(state) || false;

export const needsTOTPSetup = state => getAuthState(state) === AUTH_STATES.setupTOTP;
export const needsEmailVerification = state => getAuthState(state) === AUTH_STATES.verifyEmail;
export const needsPhoneVerification = state => getAuthState(state) === AUTH_STATES.verifyPhone;
export const needsAccountVerififaction = state =>
    needsEmailVerification(state) || needsPhoneVerification(state);
export const needsPhoneSetup = state => getAuthState(state) === AUTH_STATES.setupPhone;
export const needsTOTPVerification = state => getAuthState(state) === AUTH_STATES.verifyTOTP;
export const needsPasswordRecovery = state => getAuthState(state) === AUTH_STATES.forgotPassword;
export const needsSignInConfirmed = state => getAuthState(state) === AUTH_STATES.confirmSignIn;
export const needsNewInvite = state => getAuthState(state) === AUTH_STATES.resendInvitation;
export const needsNewPassword = state => getAuthState(state) === AUTH_STATES.requireNewPassword;

export const needsMFASelected = state => getAuthState(state) === AUTH_STATES.selectMFA;
export const needsSignIn = state => getAuthState(state) === AUTH_STATES.signedOut;
export const signInConfirmed = state => getAuthState(state) === AUTH_STATES.signInConfirmed;
export const shouldRemember = state => Boolean(getStore(state).rememberMe);

export const isLoading = state => getStore(state).loading;
export const isFetchingContext = state => getStore(state).fetchingContext;

// This is NOT a permission, this is for a dev tool
export const canChangeCustomerPlan = () => devMode;
export const canChangeRole = () => devMode;
export const canLogMissingTranslations = () => devMode;
export const canToggleFeatureFlags = () => devMode;
