import { fetchConsentStats, fetchConsentTrafficStats } from '../consent/services';

import API from '@aws-amplify/api';
import { allSettled } from '../common/services/helpers';
import { apiName } from '@osano-b2b';
import { fetchDocumentChangesCount } from '../documents/services';
import { fetchFieldClassificationStats } from '../dataStores/services';
import { fetchFollowingLitigationCount } from '../litigation/services';
import { fetchFollowingVendorsCount } from '../vendors/services';
import { formatDashboard } from './format';
import { handleError } from '../common/services/helpers';

const friendlyMessage = original => {
    switch (original) {
        case 'call not allowed by plan':
            return { upgrade: true, fakeValue: Math.floor(Math.random() * 100 - 1) };
        default:
            return '';
    }
};

const makeStat = (settlement, title) => {
    const { state, value, reason } = settlement;
    const { message = reason } = reason || {};
    return {
        title,
        value: state === 'fulfilled' ? value : friendlyMessage(message) || 0,
    };
};

// TODO: Remove once ENABLE_DASHBOARD_V2 feature flag is removed
export const fetchDashboardLegacy = () => {
    return allSettled([
        fetchFollowingVendorsCount(),
        fetchFollowingLitigationCount(),
        fetchDocumentChangesCount(),
        fetchConsentStats(),
        fetchConsentTrafficStats(),
        fetchFieldClassificationStats(),
    ]).then(resultSet => {
        const [vendors, litigaton, documentChanges, consent, traffic, fieldClassifications] =
            resultSet;
        const stats = [
            makeStat(vendors, 'layouts.Dashboard.stats.VendorsMonitored.label'),
            makeStat(litigaton, 'layouts.Dashboard.stats.LawsuitsFiled.label'),
            makeStat(documentChanges, 'layouts.Dashboard.stats.DocChanges.label'),
        ];
        return {
            traffic: traffic.value || {},
            consent: consent.value || {},
            fieldClassifications: fieldClassifications.value || [],
            stats,
        };
    });
};

const fetchProductTodos = product => API.get(apiName, `/${product}/to-do`).catch(handleError);

export const fetchDashboard = ({
    canViewAssessments,
    canViewCookieConsent,
    canViewDataMapping,
    canViewMonitoring,
    canViewSubjectRights,
    canViewUnifiedConsent,
} = {}) =>
    allSettled([
        fetchFollowingVendorsCount(),
        fetchFollowingLitigationCount(),
        fetchDocumentChangesCount(),
        fetchConsentStats(),
        fetchConsentTrafficStats(),
        canViewAssessments && fetchProductTodos('assessment-management'),
        canViewCookieConsent && fetchProductTodos('consentmanager'),
        canViewDataMapping && fetchProductTodos('data-mapping'),
        canViewSubjectRights && fetchProductTodos('dsar'),
        canViewUnifiedConsent && fetchProductTodos('uc'),
        canViewMonitoring && fetchProductTodos('vendor-management'),
    ])
        .then(
            ([
                vendors,
                litigaton,
                documentChanges,
                consent,
                traffic,
                assessmentsTodos,
                cookieConsentTodos,
                dataMappingTodos,
                subjectRightsTodos,
                unifiedConsentTodos,
                vendorMonitoringTodos,
            ]) => ({
                consent: consent.value || {},
                traffic: traffic.value || {},
                todos: {
                    assessments: assessmentsTodos.value || {},
                    cookieConsent: cookieConsentTodos.value || {},
                    dataMapping: dataMappingTodos.value || {},
                    subjectRights: subjectRightsTodos.value || {},
                    unifiedConsent: unifiedConsentTodos.value || {},
                    vendorMonitoring: vendorMonitoringTodos.value || {},
                },
                stats: [
                    makeStat(vendors, 'layouts.Dashboard.stats.VendorsMonitored.label'),
                    makeStat(litigaton, 'layouts.Dashboard.stats.LawsuitsFiled.label'),
                    makeStat(documentChanges, 'layouts.Dashboard.stats.DocChanges.label'),
                ],
            })
        )
        .then(formatDashboard);
