import {
    ENABLE_TOGGLE_USERPILOT_EXPERIENCE,
    isFeatureEnabled,
} from '../../../common/helpers/FeatureFlags';

import Actions from '../../state/userpilot/Userpilot.actions';
import Component from './Userpilot.component';
import { connect } from 'react-redux';
import fromState from '../../state/selectors';

const mapStateToProps = state => {
    const userId = fromState.Auth.user.getId(state);
    const customerId = fromState.Auth.customer.getId(state);

    return {
        userpilotId: fromState.Userpilot.getId(state),
        userpilotContentId: fromState.Userpilot.getContentId(state),
        isLoading: fromState.Auth.customer.isLoading(state) || fromState.Auth.user.isLoading(state),
        userId,
        userRole: fromState.Auth.user.getRolesString(state),
        user: fromState.DB.user.getEntityById(state, userId),
        customer: fromState.DB.customer.getEntityById(state, customerId),
        customerId,
        toggleUserpilotExperienceEnabled: isFeatureEnabled(
            state,
            ENABLE_TOGGLE_USERPILOT_EXPERIENCE
        ),
    };
};

const mapDispatchToProps = {
    clearContentId: () => Actions.setUserpilotContentId(null),
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
