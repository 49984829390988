import { Link, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import UnauthenticatedPaths from '/b2b/routing/UnauthenticatedRoutes/UnauthenticatedRoutes.paths';
import makeStyles from '@mui/styles/makeStyles';
import styles from './ReselectMFA.styles';

const useStyles = makeStyles(styles);

const ReselectMFA = props => {
    const { hasOtherMFAMethods, mfaType, onReselectMFA } = props;
    const classes = useStyles(props);
    let type = mfaType;

    switch (mfaType) {
        case 'TOTP':
        case 'SOFTWARE_TOKEN_MFA': {
            type = 'Authenticator';
            break;
        }

        case 'SMS':
        case 'SMS_MFA': {
            type = 'SMS';
            break;
        }
    }

    return hasOtherMFAMethods ? (
        <Typography className={classes.root} variant="body1">
            Don&apos;t want to use {type}?{' '}
            <Link
                component={RouterLink}
                className={classes.link}
                to={UnauthenticatedPaths.SELECT_MFA}
                onClick={onReselectMFA}
            >
                Select a different MFA method
            </Link>
        </Typography>
    ) : null;
};

ReselectMFA.propTypes = {
    mfaType: PropTypes.string,
    hasOtherMFAMethods: PropTypes.bool,
    onReselectMFA: PropTypes.func.isRequired,
};

export default ReselectMFA;
