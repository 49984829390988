import {
    canManageDataMapping,
    canReadDataMapping,
} from '/b2b/authentication/state/user/AuthUser.selectors';
import Component from './Dashboard.component';
import { connect } from 'react-redux';
import { fetchDashboard } from './Dashboard.commands';
import fromState from '../../common/state/selectors';

const mapStateToProps = state => {
    return {
        details: fromState.Dashboard.getData(state),
        isLoading: fromState.Dashboard.isLoading(state),
        customerId: fromState.Auth.customer.getId(state),
        canManageDataMapping: canManageDataMapping(state),
        canReadDataMapping: canReadDataMapping(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchDetails: () => dispatch(fetchDashboard()),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...ownProps,
        ...stateProps,
        onInitialLoad: dispatchProps.fetchDetails,
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
