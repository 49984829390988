import Attachment from './conversation/attachment/HelpScoutConversationAttachment.schema';
import Conversation from './conversation/HelpScoutConversation.schema';
import Customer from './customer/HelpScoutCustomer.schema';
import Mailbox from './mailbox/HelpScoutMailbox.schema';
// import OsanoUser from '../user/User.schema';
import Tag from './tag/HelpScoutTag.schema';
import Thread from './conversation/thread/HelpScoutConversationThread.schema';
import User from './user/HelpScoutUser.schema';
import { schema } from 'normalizr';

const Person = new schema.Union(
    {
        customer: Customer,
        user: User,
    },
    'type'
);

Conversation.define({
    assignee: Person,
    createdBy: Person,
    mailboxId: Mailbox,
    tags: [Tag],
    primaryCustomer: Customer,
    user: User,
    threads: [Thread],
});

Thread.define({
    customer: Customer,
    createdBy: Person,
    user: User,
    conversation: Conversation,
    attachments: [Attachment],
});

export default {
    [Attachment.key]: Attachment,
    [Conversation.key]: Conversation,
    [Customer.key]: Customer,
    [Mailbox.key]: Mailbox,
    [Tag.key]: Tag,
    [Thread.key]: Thread,
    [User.key]: User,
};
