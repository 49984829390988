import * as Vendors from './vendor/IabVendor.selectors';
import get from 'lodash/get';
import { getStore as getDB } from '../DB.selectors';
import schema from './Iab.schema';

export const vendors = Vendors;

export const getTcfV2Vendors = (state, vendorIds) => {
    return (vendorIds || []).reduce((vendors, id) => {
        vendors[`${id}`] = Vendors.getEntityById(state, id);
        return vendors;
    }, {});
};

export const getVendorListVersion = state => {
    const gvlStore = get(getDB(state), [schema.key], {});
    const gvlKeys = Object.keys(gvlStore)
        .map(key => parseInt(key, 10))
        .sort((a, b) => b - a);
    const gvlVersion = gvlKeys.length ? gvlKeys[0] : 0;
    return gvlVersion;
};
export const getLastUpdatedById = (state, id) => {
    const lastUpdated = get(getDB(state), [schema.key, id, 'lastUpdated'], null);
    return lastUpdated ? new Date(lastUpdated) : null;
};
