import {
    canSubmit,
    getChanges,
    getCurrentValues,
    getEntityById,
    getFieldErrors,
    getOriginalValues,
    getSubmissionError,
    isLoading,
    isSubmitting,
} from './ORMDetails.selectors';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Actions from './ORMDetails.actions';
import UIActions from '../../../state/ui/UI.actions';
import get from 'lodash/get';
import { initState } from './ORMDetails.reducer';
import isEqual from 'lodash/isEqual';
import reducer from './ORMDetails.reducer';
import { unflatten } from '../../flat';
import { useThunkReducer } from '..';
import validatejs from 'validate.js';

/**
 * @callback FetchDetailsService
 * @param {string|number} id The id for the schema entity
 * @param {any} params Any additional params used by the service
 * @returns {Promise<Record<string,any>>}
 */

/**
 * @callback UpdateDetailsService
 * @param {string} id The id for the schema entity
 * @param {Record<string,any>} details The updated properties to provide to the service
 * @returns {Promise<Record<string,any>>}
 */

/**
 * @callback DeleteDetailsService
 * @param {string} id The id for the schema entity
 * @param {any} params Any additional params used by the service
 * @returns {Promise<Record<string,any>>}
 */

/**
    @typedef ORMDetailsHookProps
    @type {Object}
    @property {string|number} id The ID for the ORM schema
    @property {Object} schema The ORM schema for the details
    @property {Object=} details The initial details props for the schema
    @property {DeleteDetailsService=} deleteDetailsService The service to delete the entity details
    @property {FetchDetailsService=} fetchDetailsService The service to request the entity details
    @property {Object=} manualRefresh Request params that force the details to be downloaded again
    @property {boolean=true} makeDetailsFlat Should the details be flattened before using. Defaults to true
    @property {function=} onChange The callback for details changes
    @property {function=} onError The callback for details changes
    @property {boolean=} requestOnLoad Indicates that the details should be initially requested
    @property {UpdateDetailsService=} updateDetailsService The service to update the entity details
    @property {Object=} validationSchema The validateJS schema for the details
*/

/**
    @typedef UseORMDetailsState
    @type {Object}
    @property {boolean} canSubmit Indicates that the details can be submitted
    @property {Record<string, any>} changes The changes for the details
    @property {Record<string, any>} defaultValues The default values for the details
    @property {Record<string,string[]>} fieldErrors The errors for all fields
    @property {boolean} initialLoad Indicates that the details have been initially loaded
    @property {boolean} loading Indicates that the details are currently loading
    @property {(key: string, value: string) => string | undefined} handleChange The handler for details changes. Returns the field error if any, otherwise undefined
    @property {(params: any) => Promise<Record<string,any>|any>} handleDelete The handler for details field deletion. Provided params are passed directly to the provided `deleteDetailsService`. Returns the new details
    @property {(params: any) => Promise<Record<string,any>|any>} handleRequestDetails The handler for details request. Provided params are passed directly to the provided `fetchDetailsService`. Returns the saved details
    @property {(details: Record<string,any>) => Promise<Record<string,any>|any>} handleSave The handler for details submission. Returns the new details
    @property {boolean} submitting Indicates that the details are currently submitting
    @property {string} submitError The error for the details submission
    @property {Record<string, any>} values The current values for the details including changes
*/

/**
 * useORMDetails
 *
 * @export
 * @param {ORMDetailsHookProps} props
 * @returns {UseORMDetailsState}
 */
export default props => {
    const {
        allowSubmit,
        deleteDetailsService,
        details: providedDetails = {},
        fetchDetailsService,
        id,
        manualRefresh,
        makeDetailsFlat = true,
        onChange,
        onError,
        ormSchema,
        requestOnLoad,
        updateDetailsService,
        validationScheme,
    } = props;
    const reduxDispatch = useDispatch();
    const [initialLoad, setInitialLoad] = useState(false);
    const details = useSelector(
        reduxState =>
            id && ormSchema ? getEntityById(reduxState, id, ormSchema) : providedDetails,
        isEqual
    );
    const [state, localDispatch] = useThunkReducer(
        reducer,
        { ...props, makeDetailsFlat, details },
        initState
    );

    const handleRequestDetails = useCallback(
        params => {
            const meta = { schema: ormSchema, id };
            const action = Actions.fetchDetailsBegin(meta, id);
            // Local first so redux doesn't trigger a refresh on begin
            localDispatch(action);
            reduxDispatch(action);
            return fetchDetailsService(id, params)
                .then(details => {
                    const action = Actions.fetchDetailsSuccess(meta, params, details);
                    // Redux first so redux doesn't trigger a refresh on complete
                    reduxDispatch(action);
                    localDispatch(action);
                    return details;
                })
                .catch(error => {
                    const { message } = error;
                    const action = Actions.fetchDetailsFailure(meta, params, message);
                    // Redux first so redux doesn't trigger a refresh on complete
                    reduxDispatch(action);
                    localDispatch(action);
                    throw message;
                });
        },
        [ormSchema, id, localDispatch, reduxDispatch, fetchDetailsService]
    );

    const validate = useCallback(
        (key, value) =>
            validationScheme
                ? validatejs({ [`${key}`]: value }, { [`${key}`]: validationScheme[`${key}`] })
                : {},
        [validationScheme]
    );

    const handleChange = useCallback(
        (key, value) => {
            const fieldError = get(validate(key, value), key);
            localDispatch(Actions.change(key, value, fieldError));
            onChange && onChange(key, value);
            fieldError && onError && onError(key, fieldError);
            return fieldError;
        },
        [localDispatch, onChange, onError, validate]
    );

    const handleSave = useCallback(
        details => {
            const errors = validatejs(details, validationScheme);
            if (!errors && updateDetailsService) {
                const meta = { schema: ormSchema, id };
                const action = Actions.updateBegin(meta, details);
                // Local first so redux doesn't trigger a refresh on begin
                localDispatch(action);
                reduxDispatch(action);
                // Handle nested
                const requestPromise =
                    updateDetailsService(id, makeDetailsFlat ? unflatten(details) : details) ||
                    Promise.resolve();
                try {
                    requestPromise
                        .catch(error => {
                            const { /* code, */ message: errorMessage } = error || {};
                            const { message = errorMessage, roadblocks } = errorMessage || {};
                            const action = Actions.updateFailure(meta, details, error);
                            // Redux first so redux doesn't trigger a refresh on complete
                            localDispatch(action);
                            reduxDispatch(action);
                            // If there is a roadblock...do not show a snack
                            !roadblocks &&
                                message &&
                                reduxDispatch(UIActions.createSnack(message, { variant: 'error' }));
                            if (errorMessage) {
                                throw errorMessage;
                            }
                        })
                        .then(response => {
                            const action = Actions.updateSuccess(
                                meta,
                                details,
                                response || details
                            );
                            // Redux first so redux doesn't trigger a refresh on complete
                            localDispatch(action);
                            reduxDispatch(action);
                            return response;
                        });
                } catch (error) {
                    // Returned value might not be a promise
                    const action = Actions.updateSuccess(meta, details, requestPromise);
                    // Redux first so redux doesn't trigger a refresh on error
                    localDispatch(action);
                    reduxDispatch(action);
                }
                return requestPromise;
            }
            return Promise.reject(errors);
        },
        [validationScheme, updateDetailsService, ormSchema, id, localDispatch, reduxDispatch]
    );

    const handleDelete = useCallback(
        params => {
            const meta = { schema: ormSchema, id };
            const action = Actions.deleteBegin(meta, details);
            // Local first so redux doesn't trigger a refresh on begin
            localDispatch(action);
            reduxDispatch(action);
            return deleteDetailsService(id, params)
                .then(details => {
                    const action = Actions.deleteSuccess(meta, details);
                    // Redux first so redux doesn't trigger a refresh on complete
                    reduxDispatch(action);
                    localDispatch(action);
                    return details;
                })
                .catch(error => {
                    const { message } = error;
                    const action = Actions.deleteFailure(meta, message);
                    // Redux first so redux doesn't trigger a refresh on complete
                    reduxDispatch(action);
                    localDispatch(action);
                    throw message;
                });
        },
        [ormSchema, id, localDispatch, reduxDispatch, fetchDetailsService]
    );

    useEffect(() => {
        initialLoad && localDispatch(Actions.refresh(details));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details]);

    useEffect(() => {
        initialLoad && id && handleRequestDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, manualRefresh]);

    useEffect(
        () => {
            !initialLoad && requestOnLoad && id && handleRequestDetails();
            setInitialLoad(!!id);
        },
        /* ONLY ON FIRST LOAD */
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return {
        canSubmit: canSubmit(state, allowSubmit),
        changes: getChanges(state),
        defaultValues: getOriginalValues(state),
        fieldErrors: getFieldErrors(state),
        initialLoad,
        loading: isLoading(state),
        // Handlers
        handleChange,
        handleDelete,
        handleRequestDetails,
        handleSave,
        submitting: isSubmitting(state),
        submitError: getSubmissionError(state),
        values: getCurrentValues(state),
    };
};
