import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    namedParameters,
    singleParameter,
} from '../../common/helpers/ReduxHelpers';

import { DOMAIN } from './FormDetails.constants';

export const events = {
    addConditionalField: namedParameters('id', 'value', 'data'),
    addField: namedParameters('data', 'index'),
    addFieldOption: namedParameters('id'),
    deleteField: namedParameters('id'),
    deleteFieldOption: namedParameters('id', 'optionIndex'),
    dragConditionalField: namedParameters('id', 'dragIndex', 'hoverIndex'),
    dragField: namedParameters('dragIndex', 'hoverIndex'),
    dragFieldOption: namedParameters('id', 'dragIndex', 'hoverIndex'),
    dragRequestType: namedParameters('dragIndex', 'hoverIndex'),
    fieldErrors: singleParameter,
    hideFieldOption: namedParameters('id', 'optionIndex', 'hidden'),
    requestTypeErrors: singleParameter,
    updateConditionalField: namedParameters('id', 'data'),
    updateField: namedParameters('id', 'field', 'value'),
    updateFieldOption: namedParameters('id', 'optionIndex', 'field', 'value'),
    updateForm: namedParameters('field', 'value', 'oldValue'),
    updateRequestType: namedParameters('id', 'field', 'value'),
    useDefaultOptions: namedParameters('id'),
};

export const asyncEvents = makeAsyncEvents(['fetchDetails', 'create', 'update', 'delete'], true);

export const types = makeActionTypes([...Object.keys(events), ...Object.keys(asyncEvents)], DOMAIN);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
    },
    types
);
