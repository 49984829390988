import {
    buildActionCreators,
    makeActionTypes,
    singleParameter,
} from '../../common/helpers/ReduxHelpers';

import { DOMAIN } from './DataMap.constants';

export const events = {
    setDirection: singleParameter,
    setFilters: singleParameter,
    setFullscreen: singleParameter,
    setLayoutComplete: singleParameter,
    setLoading: singleParameter,
    setNodesToConnect: singleParameter,
    setSavingNodeId: singleParameter,
    setSelectedFilters: singleParameter,
    setUnmappedNodes: singleParameter,
};

export const types = makeActionTypes(Object.keys(events), DOMAIN);

export default buildActionCreators(events, types);
