/* eslint react/prop-types: 0 */
import React from 'react';
import { SvgIcon } from '@mui/material';

import { white } from '../../colors';

export default function Trusthub(props) {
    return (
        <SvgIcon fontSize="large" viewBox="0 0 24 24" {...props}>
            <path
                d="M15,22H9c-0.6,0-1-0.4-1-1v-3H5.2c-1.2,0-1.9,0-2.6-0.3c-0.6-0.3-1-0.7-1.3-1.3C1,15.7,1,15,1,13.8V6.2
                C1,5,1,4.3,1.3,3.6c0.3-0.6,0.7-1,1.3-1.3C3.3,2,4,2,5.2,2h13.6c1.2,0,1.9,0,2.6,0.3c0.6,0.3,1,0.7,1.3,1.3C23,4.3,23,5,23,6.2v7.6
                c0,1.2,0,1.9-0.3,2.6c-0.3,0.6-0.7,1-1.3,1.3C20.7,18,20,18,18.8,18H16v3C16,21.6,15.6,22,15,22z M10,20h4v-2h-4V20z M5.2,4
                C4.3,4,3.8,4,3.5,4.1C3.4,4.2,3.2,4.4,3.1,4.5C3,4.8,3,5.3,3,6.2v7.6c0,0.9,0,1.4,0.1,1.7c0.1,0.2,0.2,0.3,0.4,0.4
                C3.8,16,4.3,16,5.2,16h13.6c0.9,0,1.4,0,1.7-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.8,0.1-1.7V6.2c0-0.9,0-1.4-0.1-1.7
                c-0.1-0.2-0.2-0.3-0.4-0.4C20.2,4,19.7,4,18.8,4H5.2z"
                fill={white}
            />
        </SvgIcon>
    );
}
