import { DOMAIN } from './Dashboard.constants';
import get from 'lodash/get';

export const getStore = state => get(state, DOMAIN) || {};

export const getData = state => getStore(state).data || {};
export const getError = state => getStore(state).error;

export const getConsentData = state => getData(state).consent;
export const getStatsData = state => getData(state).stats;
export const getTrafficData = state => getData(state).traffic;
export const getFieldClassificationsData = state => getData(state).fieldClassifications;

export const isLoading = state => getStore(state).loading;
