import {
    EVENT_RESOURCE_MAP,
    EVENT_TYPE,
    RESOURCE_EVENT_MAP,
    RESOURCE_TYPE,
    UPDATE_FREQUENCY,
} from './constants';

import AuthenticatedPaths from '../routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { NOTIFICATION_ICONS } from './constants';
import { formatVendor } from '../vendors/format';
import { makeResultsFormatter } from '../common/services/helpers';

const formatNotification = data => {
    const { eventData, eventTitle, eventType, customerId, resourceType, resourceId, ...remain } =
        data;
    const additional = {};
    const { documentId, productId, productName, vendorId, vendorName, configId, ...rest } =
        eventData;

    switch (eventType) {
        case EVENT_TYPE.DOCUMENT_CHANGE: {
            additional.action = `${AuthenticatedPaths.DOCUMENTS}/${documentId || resourceId}`;
            additional.document = {
                documentId,
                ...rest,
            };
            switch (resourceType) {
                case RESOURCE_TYPE.PRODUCT: {
                    additional.document.product = { id: productId, name: productName };
                    break;
                }
                case RESOURCE_TYPE.VENDOR: {
                    additional.document.vendor = formatVendor({ id: vendorId, name: vendorName });
                    break;
                }
            }
            break;
        }

        case EVENT_TYPE.SCORE_UPDATE: {
            switch (resourceType) {
                case RESOURCE_TYPE.PRODUCT: {
                    additional.action = `${AuthenticatedPaths.PRODUCTS}/${resourceId}`;
                    additional.product = { id: resourceId, name: productName, ...rest };
                    break;
                }
                case RESOURCE_TYPE.VENDOR: {
                    additional.action = `${AuthenticatedPaths.VENDORS}/${resourceId}`;
                    additional.vendor = formatVendor({ id: resourceId, name: vendorName, ...rest });
                    break;
                }
            }
            break;
        }

        case EVENT_TYPE.LEGISLATION_POST_UPDATE: {
            additional.action = `${AuthenticatedPaths.LEGISLATION}/${resourceId}`;
            additional.legislation = eventData;
            break;
        }

        case EVENT_TYPE.DSAR_DATASOURCE_ASSIGNED:
        case EVENT_TYPE.DSAR_DATASOURCE_DEADLINE:
        case EVENT_TYPE.DSAR_DATASOURCE_ASSIGNED_SUMMRY:
        case EVENT_TYPE.DSAR_DATASOURCE_DEADLINE_SUMMRY: {
            additional.action = `${AuthenticatedPaths.DSAR_ACTION_ITEMS}/`;
            break;
        }

        case EVENT_TYPE.DSAR_REQUEST_NEW_PORTAL_MESSAGE: {
            additional.action = `${AuthenticatedPaths.DSAR_REQUESTS}/${resourceId}`;
            break;
        }

        case EVENT_TYPE.DSAR_NEW_REQUEST:
        case EVENT_TYPE.DSAR_REQUEST_DEADLINE:
        case EVENT_TYPE.DSAR_NEW_REQUEST_SUMMRY:
        case EVENT_TYPE.DSAR_REQUEST_DEADLINE_SUMMRY: {
            additional.action = `${AuthenticatedPaths.DSAR_REQUESTS}/`;
            break;
        }

        case EVENT_TYPE.TATTLE: {
            additional.action = `${AuthenticatedPaths.CONSENT_MANAGER}/${configId}`;
            additional.consent = eventData;
            break;
        }

        case EVENT_TYPE.ASKANALYST_RESPONSE: {
            additional.action = `${AuthenticatedPaths.ASK_ANALYST}`;
            additional.askAnalyst = eventData;
            break;
        }

        case EVENT_TYPE.LITIGATION_UPDATE: {
            switch (resourceType) {
                case RESOURCE_TYPE.LITIGATION: {
                    // TODO: Deep link to specific litigation
                    /*
                    additional.action = `${AuthenticatedPaths.LITIGATION}/${resourceId}`;
                    additional.litigation = eventData;
                    */

                    // For the time being, treat this resourceType as a Vendor
                    additional.action = `${AuthenticatedPaths.VENDORS}/${resourceId}/lawsuits`;
                    additional.vendor = formatVendor({ id: resourceId, name: vendorName, ...rest });
                    break;
                }
                case RESOURCE_TYPE.VENDOR: {
                    additional.action = `${AuthenticatedPaths.VENDORS}/${resourceId}/lawsuits`;
                    additional.vendor = formatVendor({ id: resourceId, name: vendorName, ...rest });
                    break;
                }
            }
            break;
        }

        // case EVENT_TYPE.ASSIGNED_ASSESSMENT_COMPLETE:
        // case EVENT_TYPE.ASSESSMENT_CLOSED:
        // case EVENT_TYPE.ASSESSMENT_DUE:
        case EVENT_TYPE.ASSESSMENT_ASSIGNED: {
            switch (resourceType) {
                case RESOURCE_TYPE.ASSESSMENT: {
                    additional.action = `${AuthenticatedPaths.ASSESSMENTS}/${resourceId}`;
                    break;
                }
                case RESOURCE_TYPE.ASSESSMENT_ASSIGNMENT: {
                    additional.action = `${AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS}/${resourceId}`;
                    break;
                }
            }
            break;
        }

        case EVENT_TYPE.VENDOR_ASSIGNED: {
            switch (resourceType) {
                case RESOURCE_TYPE.VENDOR: {
                    additional.action = `${AuthenticatedPaths.VENDORS}/${resourceId}`;
                    break;
                }
            }
            break;
        }

        case EVENT_TYPE.VENDOR_BREACH: {
            switch (resourceType) {
                case RESOURCE_TYPE.SITE_BREACH: {
                    additional.action = `${AuthenticatedPaths.VENDORS}/${resourceId}/breaches`;
                    break;
                }
            }
            break;
        }

        case EVENT_TYPE.REVIEW_DATE: {
            switch (resourceType) {
                case RESOURCE_TYPE.VENDOR: {
                    additional.action = `${AuthenticatedPaths.VENDORS}/${resourceId}/scoring`;
                    break;
                }
            }
            break;
        }
    }

    const formatted = {
        ...remain,
        title: eventTitle,
        customer: customerId,
        type: eventType,
        icon: NOTIFICATION_ICONS[eventType] || NOTIFICATION_ICONS.default,
        resourceId,
        resourceType,
        eventData,
        ...additional,
    };

    return formatted;
};

export const formatNotificationResults = makeResultsFormatter(formatNotification);

export const formatNotificationSettings = settings => {
    return settings
        .filter(({ resourceType, frequency }) => {
            return (
                frequency &&
                (RESOURCE_TYPE[resourceType] || EVENT_TYPE[resourceType]) &&
                UPDATE_FREQUENCY[frequency]
            );
        })
        .reduce((allSettings, setting) => {
            let { eventType, resourceType, frequency, preferences } = setting;
            if (!eventType && EVENT_TYPE[resourceType]) {
                allSettings.push({
                    eventType: resourceType,
                    resourceType: EVENT_RESOURCE_MAP[resourceType][0],
                    frequency,
                    preferences,
                });
            } else if (RESOURCE_TYPE[resourceType]) {
                allSettings.push({
                    eventType: eventType || RESOURCE_EVENT_MAP[resourceType][0],
                    resourceType,
                    frequency,
                    preferences,
                });
            } else {
                allSettings.push(setting);
            }
            return allSettings;
        }, []);
};
