import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

export const Variant = ({ children, on }) => <Fragment>{on && children}</Fragment>;

Variant.propTypes = {
    children: PropTypes.node,
    on: PropTypes.bool,
};

export const On = ({ children, on, variant }) => {
    const toggledChildren = React.Children.map(children, child => {
        const variantChild = child.type === Variant && variant;
        if (variantChild) {
            const variantOn = on && child.props.name.toLowerCase() === variant.name.toLowerCase();
            return React.cloneElement(child, { on: variantOn });
        }
        return React.cloneElement(child);
    });
    return <Fragment>{on && toggledChildren}</Fragment>;
};

On.propTypes = {
    children: PropTypes.node,
    on: PropTypes.bool,
    variant: PropTypes.shape({
        enabled: PropTypes.bool,
        name: PropTypes.string,
        payload: PropTypes.shape({}),
    }),
};

export const Off = ({ children, on }) => <Fragment>{!on && children}</Fragment>;

Off.propTypes = {
    children: PropTypes.node,
    on: PropTypes.bool,
};

const FeatureFlag = ({ children, on, variant }) => {
    const toggledChildren = React.Children.map(children, child =>
        React.cloneElement(child, { on, variant })
    );
    return <Fragment>{toggledChildren}</Fragment>;
};

FeatureFlag.propTypes = {
    children: (props, propName, componentName) => {
        let error;
        const prop = props[propName];
        React.Children.forEach(prop, child => {
            if (child.type !== On && child.type !== Off) {
                error = new Error(`${componentName} only accepts children of type [On, Off].`);
            }
        });
        return error;
    },
    on: PropTypes.bool,
    variant: PropTypes.shape({
        enabled: PropTypes.bool,
        name: PropTypes.string,
        payload: PropTypes.shape({}),
    }),
};

export default FeatureFlag;
