// Chart data
export const data = {
    labels: [],
    datasets: [],
};

// Chart options
export const makeOptions = data => {
    const { datasets = [] } = data;
    let min = 1,
        max = 1;
    datasets.forEach(({ data }) => {
        max = Math.max(max, ...data);
        min = Math.min(min, ...data);
    });

    const diff = max - min;
    let divisor = 1,
        suffix = '';
    if (diff >= 1000) {
        divisor = 1000;
        suffix = 'K';
    }
    if (diff >= 1000000) {
        divisor = 1000000;
        suffix = 'M';
    }

    const start = Math.max(0, min - Math.floor((max - min) / 2));

    return {
        maintainAspectRatio: false,
        plugins: {
            legend: { display: false },
        },
        scales: {
            x: {
                maxBarThickness: 20,
                barPercentage: 1,
                categoryPercentage: 0.25,
                ticks: {},
                grid: {
                    display: false,
                    drawBorder: false,
                },
            },
            y: {
                beginAtZero: start === 0,
                min: start,
                ticks: {
                    callback(value) {
                        const v = value / divisor;

                        return v === 0 ? 0 : `${v}${suffix}`;
                    },
                    maxTicksLimit: 5,
                    stepSize: divisor,
                },
                grid: {
                    display: false,
                    drawBorder: false,
                },
            },
        },
        responsiveAnimationDuration: 1000,
    };
};
