import * as Yup from 'yup';

import { formatHostname, getURLParts, validateURL } from './helpers/URL';
import { translate, translationExists } from './helpers/i18n';

import Locale from './components/Locale';
import React from 'react';
import camelCase from 'lodash/camelCase';
import castArray from 'lodash/castArray';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import startCase from 'lodash/startCase';
import validate from 'validate.js';
import { validatePassword } from './helpers/Password';
import { validatePhone } from './helpers/Phone';

// Override URL validator
const oldUrlValidator = validate.validators.url;
validate.validators.url = function (value, options) {
    if (!validate.isDefined(value) || (!value && options.allowEmpty)) {
        return;
    }
    const {
        schemes = ['http:', 'https:'], // A list of schemes to allow. If you want to support any scheme you can use a regexp here (for example [".+"]). The default value is ["http", "https"].
        allowPaths = false,
    } = options;
    let response = oldUrlValidator.call(this, value, options);
    if (response && allowPaths) {
        let url;
        try {
            url = new URL(value, window.location.href);
            if (schemes.includes(url.protocol)) {
                return;
            }
            // Invalid protocol
        } catch (error) {
            // Invalid URL
        }
    }

    return response;
};
validate.validators.urlOrPath = function (value, options) {
    return validate.validators.url.call(this, value, {
        message: 'common.formValidation.isNotValidUrlOrPath',
        ...options,
        allowPaths: true,
    });
};

// Override "convert" function to add a boolean when the error message
// should be prepended with the attribute. This is necessary so we can
// translate it and prepend it ourselves in our custom i18n formatter.
const originalConvertErrorMessages = validate.convertErrorMessages;
function convertErrorMessages(validations, options) {
    const translated = validations.map(validation => {
        if (isArray(validation.error)) {
            return validation;
        }

        const translatedError = translate(validation.error);
        const isPrepend = translatedError[0] !== '^';

        return {
            ...validation,
            error: translatedError,
            isPrepend,
        };
    });

    return originalConvertErrorMessages(translated, options);
}
validate.convertErrorMessages = convertErrorMessages;

// Override format function to use i18n
const originalFormat = validate.format;
const { FORMAT_REGEXP } = originalFormat;
function format(str, interpolations) {
    const { attribute } = interpolations;
    if (attribute) {
        const attrKey = `common.fields.${camelCase(attribute)}.label`;
        const translationResult = translate(attrKey);
        interpolations.attribute =
            translationResult === attrKey
                ? validate.capitalize(validate.prettify(attribute))
                : translationResult;
    }

    return originalFormat(translate(str), interpolations);
}
validate.format = validate.extend(format, { FORMAT_REGEXP });

// Add custom i18n formatter
validate.formatters.i18n = function (validations) {
    const translatedValidations = validations.reduce((acc, validation) => {
        const { attribute, error, isPrepend } = validation;
        const joinedAndPrepended = castArray(error)
            .map(error => (isPrepend ? format(`%{attribute} ${error}`, { attribute }) : error))
            .join('\n');

        acc[attribute] = acc[attribute]
            ? acc[attribute] + '\n' + joinedAndPrepended
            : joinedAndPrepended;

        return acc;
    }, {});

    return translatedValidations;
};

// Global validate.js options (configure for i18n)
validate.options = {
    format: 'i18n',
    fullMessages: false,
};

validate.validators.checked = function (value, options) {
    if (value !== true) {
        return options.message || 'must be checked';
    }
};

const originalLengthValidator = validate.validators.length.bind(validate.validators);
validate.validators.length = function (...args) {
    const lengthError = originalLengthValidator(...args);
    const [value, { allowEmpty }] = args;
    // If we allow empty strings, we should mark valid
    if (lengthError && !value && allowEmpty) {
        return;
    }
    return lengthError;
};

validate.validators.formGroup = (fields = {}, settings) => {
    const { options } = settings;
    const fieldErrors = Object.entries(fields).reduce((errors, [field, value]) => {
        const error = validate(value, options[field]);
        if (error) {
            errors[field] = error;
        }
        return errors;
    }, {});

    return validate.isEmpty(fieldErrors) ? undefined : fieldErrors;
};

validate.validators.password = function (value, options) {
    if (validate.isBoolean(options)) {
        options = { attribute: options };
    }
    if (validate.isEmpty(options.attribute) || !validate.isBoolean(options.attribute)) {
        throw new Error('The attribute must be a boolean value');
    }
    if (validate.isDefined(value)) {
        return !value || validatePassword(value) ? undefined : 'is invalid';
    }
};

validate.validators.domain = function (value, options) {
    if (validate.isBoolean(options)) {
        options = { attribute: options };
    }
    const { required = false } = options;

    if (!required && !value) {
        return;
    }

    if (!validate.isDefined(value)) {
        return 'common.formValidation.isRequired';
    }
    try {
        const domain = formatHostname(value, true);
        const isValid = validateURL(`http://${domain}`);
        return isValid
            ? new URL(`http://${domain}`) && undefined
            : translate('common.formValidation.isNotValid', {
                  attribute: translate('common.fields.domains.singular'),
                  value,
              });
    } catch (e) {
        // Catch a url error
    }
    return translate('common.formValidation.isNotValid', {
        attribute: translate('common.fields.domains.singular'),
        value,
    });
};

function validateSubdomain(value, crossDomain, domains) {
    try {
        const domain = formatHostname(value, true);
        const isValid = validateURL(`http://${domain}`);
        if (crossDomain && !isValid) {
            throw new Error('Not a valid hostname');
        }

        if (!crossDomain && domains.length > 1) {
            return domains
                .map(getURLParts)
                .reduce((error, { domain, tld, subdomains }, idx, arr) => {
                    if (idx > 0) {
                        const {
                            domain: baseDomain,
                            tld: baseTld,
                            subdomains: baseSubdomains,
                        } = arr[0];
                        const hostname = `${domain}.${tld}`;
                        const baseHostname = `${baseDomain}.${baseTld}`;
                        if (hostname !== baseHostname) {
                            error = ['common.fields.subdomains.validations.invalidRoot'];
                        } else if (
                            (baseSubdomains.length && !subdomains.length) ||
                            (!baseSubdomains.length && subdomains.length)
                        ) {
                            error = ['common.fields.subdomains.validations.invalidMix'];
                        }
                    }
                    return error;
                }, []);
        }
    } catch {
        return [
            'common.formValidation.isNotValid',
            {
                attribute: 'common.fields.domains.singular',
                value,
            },
        ];
    }

    return [];
}

validate.validators.subdomain = function (value, options) {
    if (validate.isBoolean(options)) {
        options = { attribute: options };
    }
    const { crossDomain = false, domains = [], required = false } = options;

    if (!required && !value) {
        return;
    }

    const [i18nPath, i18nOptions] = validateSubdomain(value, crossDomain, domains);
    const _i18nOptions = i18nOptions
        ? { ...i18nOptions, attribute: translate(i18nOptions.attribute) }
        : i18nOptions;

    return i18nPath ? translate(i18nPath, _i18nOptions) : undefined;
};

validate.validators.phone = function (value, options = 'US') {
    if (validate.isString(options)) {
        options = { country: options };
    }
    const { country = 'US', required = false } = options;

    if (!required && !value) {
        return;
    }

    const attribute = translate('common.fields.phone.label');
    if (!validate.isDefined(value)) {
        return 'common.formValidation.isRequired';
    }

    if (!validatePhone(value, country)) {
        return translate('common.formValidation.isNotValid', { attribute, value });
    }

    return;
};

validate.validators.array = (arrayItems, itemConstraints, key, attributes) => {
    const constraints = {};
    Object.entries(itemConstraints).forEach(([key, constraint]) => {
        constraints[key] = {
            ...constraint,
            ...attributes,
        };
    });
    const arrayItemErrors = (arrayItems || []).reduce((errors, item, index) => {
        const error =
            typeof item === 'object'
                ? validate(item, constraints)
                : validate.single(item, constraints);
        if (error && !errors.some(e => e.includes(error[0]))) {
            errors[index] = error;
        }
        return errors;
    }, []);
    return isEmpty(arrayItemErrors) ? null : arrayItemErrors;
};

validate.validators.or = (value, { constraints, message = 'is invalid' }) => {
    const validatesWithOne = Object.entries(constraints).reduce(
        (validated, [constraint, options]) => {
            return validated || !validate.single(value, { [constraint]: options });
        },
        false
    );

    return validatesWithOne ? null : message;
};

validate.validators.and = (value, constraints) => {
    const validationErrors = Object.entries(constraints).reduce((errors, [constraint, options]) => {
        const error = validate.single(value, { [constraint]: options });
        if (error) {
            errors.push(error);
        }
        return errors;
    }, []);

    return isEmpty(validationErrors) ? null : validationErrors;
};

validate.validators.ruleMatches = (pattern, constraints, key, attributes) => {
    const { type, rules } = attributes;
    // eslint-disable-next-line security/detect-non-literal-regexp
    const ruleRegex = new RegExp(pattern);
    const ruleMatches = castArray(rules).filter(rule => ruleRegex.test(rule));

    // Specifially return undefined so validate.js will correctly ignore it
    return ruleMatches.length
        ? undefined
        : translate('components.Classification.noRuleMatches', {
              type: translate(`common.${type}`, { smart_count: 3 }),
          });
};

const stringValidatorProp = function (value, options) {
    if (validate.isString(options)) {
        options = { attribute: options };
    }
    if (validate.isEmpty(options.attribute) || !validate.isString(options.attribute)) {
        throw new Error('The attribute must be a non empty string');
    }

    return;
};

const boolValidatorProp = function (value, options) {
    if (validate.isBoolean(options)) {
        options = { attribute: options };
    }
    if (validate.isEmpty(options.attribute) || !validate.isBoolean(options.attribute)) {
        throw new Error('The attribute must be a boolean value');
    }

    return;
};

const objectValidatorProp = function (value, options) {
    if (validate.isObject(options)) {
        return;
    }
    throw new Error('The props attribute must be an object');
};

const validatePresenceAllowEmpty = function (value, options) {
    if (options.allowEmpty === false && validate.isEmpty(value)) {
        return options.message || 'common.formValidation.isRequired';
    }
};

validate.extend(validate.validators.datetime, {
    parse(value) {
        return moment.utc(value);
    },
    format(value) {
        return moment.utc(value).format('MM/DD/YYYY');
    },
});

validate.validators.mask = () => {};
validate.validators.component = () => {};
validate.validators.helperText = () => {};
validate.validators.label = stringValidatorProp;
validate.validators.props = objectValidatorProp;
validate.validators.collection = objectValidatorProp;
validate.validators.depends = objectValidatorProp;
validate.validators.type = stringValidatorProp;
validate.validators.disabled = boolValidatorProp;
validate.validators.presence = validatePresenceAllowEmpty;
validate.validators.presence.message = 'common.formValidation.isRequired';
validate.validators.length.tooShort = 'common.formValidation.minLength';
validate.validators.length.tooLong = 'common.formValidation.maxLength';
validate.validators.email.message = 'common.formValidation.invalidEmail';
validate.validators.datetime.tooEarly = 'common.formValidation.tooEarly';
validate.validators.datetime.tooLate = 'common.formValidation.tooLate';

const getFieldNameFromPath = path => {
    const commonPath = `common.fields.${camelCase(path)}.label`;

    if (translationExists(commonPath)) {
        return commonPath;
    } else if (translationExists(path)) {
        return path;
    }

    return startCase(path);
};

const removeStartingCaret = string =>
    string.charAt(0) === '^' ? string.replace('^', '').trim() : string;

Yup.setLocale({
    // use constant translation keys for messages without values
    mixed: {
        default: ({ path }) => (
            <Locale path={[getFieldNameFromPath(path), 'common.formValidation.isNotValid']} />
        ),
        required: ({ path }) => (
            <Locale path={[getFieldNameFromPath(path), 'common.formValidation.isRequired']} />
        ),
    },
    // use functions to generate an error object that includes the value from the schema
    number: {
        max: ({ max, path }) => (
            <Locale
                path={[startCase(path), 'common.formValidation.maxNumber']}
                options={{ count: max }}
            />
        ),
        min: ({ min, path }) => (
            <Locale
                path={[startCase(path), 'common.formValidation.minNumber']}
                options={{ count: min }}
            />
        ),
    },
    string: {
        email: ({ value }) => (
            <Locale
                formatter={val => (val.charAt(0) === '^' ? val.slice(1) : val)}
                path="common.formValidation.invalidEmail"
                options={{ value }}
            />
        ),
        max: ({ max, path }) => (
            <Locale
                path={[getFieldNameFromPath(path), 'common.formValidation.maxLength']}
                options={{ count: max }}
            />
        ),
        min: ({ min, path }) => (
            <Locale
                path={[getFieldNameFromPath(path), 'common.formValidation.minLength']}
                options={{ count: min }}
            />
        ),
        url: ({ value }) => (
            <Locale
                formatter={removeStartingCaret}
                path="common.formValidation.invalidUrl"
                options={{ value }}
            />
        ),
    },
});

Yup.addMethod(Yup.string, 'subdomain', function (errorMessage) {
    let errors = new Set([]);

    return this.test('subdomain', errorMessage, function (value, context) {
        const isFirstValue = this.path.endsWith('[0]');
        if (isFirstValue) {
            errors = new Set([]);
        }
        const { crossDomain } = context.from[0].value.configuration;
        const domains = isFirstValue ? [value] : [context.parent[0], value];
        let [path, options] = validateSubdomain(value, crossDomain, domains);
        if (errors.has(path) || !path) {
            return true;
        }

        errors.add(path);

        return this.createError({
            path: this.path,
            message: <Locale path={path} options={options} formatter={removeStartingCaret} />,
        });
    });
});

Yup.addMethod(Yup.string, 'urlOrPath', function (errorMessage) {
    return this.test('urlOrPath', errorMessage, function (value) {
        try {
            const url = new URL(value, window.location.href);
            if (url.protocol !== 'http:' && url.protocol !== 'https:') {
                throw new Error('Invalid protocol');
            }
        } catch {
            return this.createError({
                path: this.path,
                message: errorMessage || (
                    <Locale path={[value, 'common.formValidation.isNotValidUrlOrPath']} />
                ),
            });
        }

        return true;
    });
});
