import { getMfaOptions, selectMFA } from './SelectMFA.commands';

import Component from './SelectMFA.component';
import { MFA_TYPES } from '../../../constants';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const mapStateToProps = state => {
    // Standardize the preferredMFA string
    let preferredMFA = fromState.Auth.getMfaType(state);
    if (MFA_TYPES.SMS.includes(preferredMFA)) {
        preferredMFA = 'SMS_MFA';
    } else if (MFA_TYPES.TOTP.includes(preferredMFA)) {
        preferredMFA = 'SOFTWARE_TOKEN_MFA';
    }

    const savedMFAOptions = fromState.Auth.getMfaOptions(state);
    const isSMSEnabled =
        savedMFAOptions.includes('SMS_MFA') || MFA_TYPES.SMS.includes(preferredMFA);
    const isTOTPEnabled =
        savedMFAOptions.includes('SOFTWARE_TOKEN_MFA') || MFA_TYPES.TOTP.includes(preferredMFA);

    return {
        challengeName: fromState.Auth.getChallengeName(state),
        preferredMFA,
        savedMFAOptions,
        isPhoneVerified: fromState.Auth.isPhoneVerified(state),
        isSMSEnabled,
        isTOTPEnabled,
        isLoading: fromState.Auth.isLoading(state),
    };
};

const mapDispatchToProps = { selectMFA, getMfaOptions };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
