import { defaultIdAttribute, defaultProcessStrategy } from '../../../common/helpers/Normalizr';

import { schema } from 'normalizr';

export const entityName = 'vendors';
export const idAttribute = 'vendorId';

export const mergeStrategy = (
    entityA = {},
    { following, sectionScores, mergeFirst = [], ...entityB } = {}
) => {
    const newEntity = {
        ...mergeFirst.reduce((props, key) => {
            props[key] = entityB[key];
            return props;
        }, {}),
        ...entityA,
        ...Object.keys(entityB)
            .filter(key => !mergeFirst.includes(key))
            .reduce((props, key) => {
                props[key] = entityB[key];
                return props;
            }, {}),
    };

    // Only override following data if it's being provided by the new entity
    if (following) {
        newEntity.following = following;
    }
    // Only override sectionScores if it's being provided by the new entity
    if (sectionScores) {
        newEntity.sectionScores = sectionScores;
    }
    return newEntity;
};
export const processStrategy = defaultProcessStrategy(
    idAttribute,
    function (details, parent, parentKey) {
        if (parentKey !== null && parentKey === 'vendors') {
            // Litigation can sometimes override a vendor name, so let's tell the merge
            details.mergeFirst = ['name'];
        }
        return details;
    }
);

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
