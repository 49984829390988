import { LinearProgress, Slide } from '@mui/material';
import React, { Fragment } from 'react';

import AuthenticatedRoutes from './AuthenticatedRoutes';
import BrowseRoutes from './BrowseRoutes';
import CustomerSelect from './CustomerSelect';
import PropTypes from 'prop-types';
import SnackbarTray from '../common/components/SnackbarTray';
import UnauthenticatedRoutes from './UnauthenticatedRoutes';
import { useOsanoRoutes } from './Routes.hook';

const Routes = props => {
    const { asyncLoading, isAuthenticated, isCustomerSelected, customerToken, signOut } = props;
    const { isDataMappingConnection, isLogoutPage, isOauthConnection, isUcIntegrationConnection } =
        useOsanoRoutes(props);

    if (isAuthenticated && isLogoutPage) {
        signOut();
        return null;
    }

    if (window.opener && isAuthenticated) {
        const pairs = location.search.slice(1).split('&');
        const params = pairs.reduce((o, pair) => {
            const [key, value] = pair.split('=');
            o[key] = decodeURIComponent(value || '');
            return o;
        }, {});
        if (isOauthConnection || isDataMappingConnection || isUcIntegrationConnection) {
            window.opener.postMessage(JSON.stringify(params));
            window.self.close();
            return null;
        }
    }

    if (isAuthenticated && !isCustomerSelected) {
        return <CustomerSelect />;
    }

    return (
        <Fragment>
            <Slide direction="down" in={asyncLoading} mountOnEnter unmountOnExit>
                <LinearProgress
                    variant="query"
                    style={{
                        top: 0,
                        left: 0,
                        right: 0,
                        pointerEvents: 'none',
                        position: 'fixed',
                        zIndex: '2147483647',
                    }}
                />
            </Slide>
            {isAuthenticated ? (
                customerToken === false ? (
                    <BrowseRoutes location={location} />
                ) : (
                    <AuthenticatedRoutes location={location} />
                )
            ) : (
                <UnauthenticatedRoutes location={location} />
            )}
            <SnackbarTray />
        </Fragment>
    );
};

Routes.propTypes = {
    blockedPaths: PropTypes.array,
    customerId: PropTypes.string,
    asyncLoading: PropTypes.bool,
    getMatchingBlockedPath: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    isCustomerSelected: PropTypes.bool,
    fetchCustomerDetails: PropTypes.func,
    triggerUserPilot: PropTypes.func,
    documentTitle: PropTypes.string.isRequired,
    customerToken: PropTypes.string,
    signOut: PropTypes.func,
};

export default Routes;
