import { DOMAIN } from './IAB.constants';
import get from 'lodash/get';

export const getStore = state => get(state, DOMAIN) || {};

export const getField = (state, field) => {
    const value = getStore(state)[field];
    return value === undefined || value === null ? '' : value;
};

export const getError = state => get(getStore(state), 'error');
export const getResults = state =>
    get(getStore(state), 'tcf.vendors', []).map(id => parseInt(id, 10));
export const getVendorIds = state =>
    get(getStore(state), 'tcf.vendors', []).map(id => parseInt(id, 10));

export const isLoading = state => get(getStore(state), 'loading');
