import { DOMAIN } from './LitigationDetails.constants';
import buildActionCreators from '../../common/helpers/Redux/buildActionCreators';
import makeActionTypes from '../../common/helpers/Redux/makeActionTypes';
import makeAsyncEvents from '../../common/helpers/Redux/makeAsyncEvents';

export const events = {};

export const asyncEvents = makeAsyncEvents(['fetchDetails'], true);

export const types = makeActionTypes(Object.keys(asyncEvents), DOMAIN);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
    },
    types
);
