import { defaultMergeStrategy, defaultProcessStrategy } from '../../../common/helpers/Normalizr';

import Rule from './rule/Rule.schema';
import Tattle from './tattle/Tattle.schema';
import { schema } from 'normalizr';

export const entityName = 'consentConfig';
export const idAttribute = 'configId';

export const mergeStrategy = defaultMergeStrategy(idAttribute);
export const processStrategy = defaultProcessStrategy(idAttribute);

const ConsentConfig = new schema.Entity(entityName, undefined, {
    idAttribute: value => value[idAttribute] || value.config_id || value.id || value,
    mergeStrategy,
    processStrategy,
});

ConsentConfig.define({
    tattles: [Tattle],
    rules: [Rule],
});

export default ConsentConfig;
