import theme from '../default';

export default {
    root: {
        border: `1px solid ${theme.palette.divider}`,
        '&:before': {
            display: 'none',
        },
    },
};
