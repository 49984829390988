import theme from '../default';

export default {
    root: {
        lineHeight: 1.25,
        marginBottom: theme.spacing(1),
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        // Fix for empty helper text next to Switch component
        '&:empty': {
            marginLeft: 0,
            marginRight: 0,
        },
    },
};
