/* eslint react/prop-types: 0 */
import React from 'react';
import { SvgIcon } from '@mui/material';

import { white } from '../../colors';

export default function Datasource(props) {
    return (
        <SvgIcon fontSize="large" viewBox="0 0 24 24" {...props}>
            <path
                d="M17.2,22H6.8C5,22,4,22,3.2,21.6c-0.8-0.4-1.4-1-1.7-1.7C1,19,1,18,1,16.2v-10C1,5,1,4.3,1.3,3.6
                c0.3-0.6,0.7-1,1.3-1.3C3.3,2,4,2,5.2,2H9c0.8,0,1.2,0,1.7,0.2c0.4,0.1,0.8,0.4,1.1,0.7c0.4,0.4,0.6,0.7,0.9,1.5L13.6,6h3.6
                C19,6,20,6,20.8,6.4c0.8,0.4,1.4,1,1.7,1.7C23,9,23,10,23,11.8v4.4c0,1.8,0,2.8-0.4,3.6c-0.4,0.8-1,1.4-1.7,1.7C20,22,19,22,17.2,22
                z M3,8v8.2c0,1.5,0,2.3,0.2,2.7c0.2,0.4,0.5,0.7,0.9,0.9C4.5,20,5.3,20,6.8,20h10.4c1.5,0,2.3,0,2.7-0.2c0.4-0.2,0.7-0.5,0.9-0.9
                c0.2-0.4,0.2-1.2,0.2-2.7v-4.4c0-1.5,0-2.3-0.2-2.7c-0.2-0.4-0.5-0.7-0.9-0.9C19.5,8,18.7,8,17.2,8H13c0,0,0,0,0,0H3z M3,6h8.4
                L11,5.2c-0.3-0.5-0.4-0.8-0.5-0.9c-0.1-0.1-0.2-0.2-0.4-0.2C9.9,4,9.6,4,9,4H5.2C4.3,4,3.8,4,3.5,4.1C3.4,4.2,3.2,4.4,3.1,4.5
                C3,4.7,3,5.2,3,6z M15.5,18.5c-0.3,0-0.5-0.1-0.7-0.3l-0.9-0.9c-0.7,0.4-1.5,0.7-2.4,0.7C9,18,7,16,7,13.5S9,9,11.5,9s4.5,2,4.5,4.5
                c0,0.9-0.3,1.7-0.7,2.4l0.9,0.9c0.4,0.4,0.4,1,0,1.4C16,18.4,15.8,18.5,15.5,18.5z M11.5,11C10.1,11,9,12.1,9,13.5
                c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5C14,12.1,12.9,11,11.5,11z"
                fill={white}
            />
        </SvgIcon>
    );
}
