import {
    fetchDashboardLegacy as fetchDashboardLegacyService,
    fetchDashboard as fetchDashboardService,
} from '../services';

import Actions from '../state/Dashboard.actions';

export const fetchDashboard =
    (params = {}) =>
    dispatch => {
        dispatch(Actions.fetchDetailsBegin(params));
        return fetchDashboardService(params)
            .then(response => dispatch(Actions.fetchDetailsSuccess(params, response)))
            .catch(error => dispatch(Actions.fetchDetailsFailure(params, error?.message)));
    };

// TODO: Remove once ENABLE_DASHBOARD_V2 feature flag is removed
export const fetchDashboardLegacy =
    (params = {}) =>
    dispatch => {
        dispatch(Actions.fetchDetailsBegin(params));
        return fetchDashboardLegacyService(params)
            .then(response => dispatch(Actions.fetchDetailsSuccess(params, response)))
            .catch(error => dispatch(Actions.fetchDetailsFailure(params, error?.message)));
    };
