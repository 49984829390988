import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    namedParameters,
    noParameters,
} from '../../../common/helpers/ReduxHelpers';

import { DOMAIN } from './Tattles.constants';

export const events = {
    fieldChange: namedParameters('id', 'field', 'value'),
    removeError: namedParameters('id'),
    setError: namedParameters('id', 'error'),
    setRuleMatches: namedParameters('ids'),
    validate: namedParameters('idOrIds', 'errors'),
};

export const filterEvents = {
    addFilter: namedParameters('field', 'value'),
    removeFilter: namedParameters('field', 'value'),
    setFilter: namedParameters('field', 'value'),
    clearFilter: namedParameters('field'),
    assignFilters: namedParameters('value'),
    resetFilters: noParameters,
};

export const pagingEvents = makeAsyncEvents(['fetchResults'], true);
export const asyncEvents = makeAsyncEvents(['delete'], true);

export const types = makeActionTypes(
    [
        ...Object.keys(events),
        ...Object.keys(filterEvents),
        ...Object.keys(pagingEvents),
        ...Object.keys(asyncEvents),
    ],
    DOMAIN
);

export default buildActionCreators(
    {
        ...events,
        ...filterEvents,
        ...pagingEvents,
        ...asyncEvents,
    },
    types
);
