import React, { useEffect } from 'react';

// Custom components
import ConsentGraph from './components/ConsentGraph';
import { DATA_MAPPING_PATHS } from '../../authentication/constants';
import FieldClassificationStats from './components/FieldClassificationStats';
//import DSARStats from './components/DSARStats';
import { Grid } from '@mui/material';
import Permission from '../../common/components/Permission';
import PropTypes from 'prop-types';
import Stats from './components/Stats';
import TrafficGraph from './components/TrafficGraph';
import makeStyles from '@mui/styles/makeStyles';
import styles from './Dashboard.styles';

const useStyles = makeStyles(styles);

const Dashboard = props => {
    const { canReadDataMapping, customerId, onInitialLoad } = props;
    const classes = useStyles(props);
    useEffect(() => {
        customerId && onInitialLoad();
    }, [customerId]);

    return (
        <Grid container spacing={8} className={classes.root}>
            <Grid item xs={12}>
                <Stats />
            </Grid>
            <Grid item md={6} xs={12}>
                <TrafficGraph />
            </Grid>
            <Grid item md={6} xs={12}>
                <ConsentGraph />
            </Grid>
            <Permission
                toPerform="view"
                onAny={DATA_MAPPING_PATHS}
                shouldRender={canReadDataMapping}
            >
                <Grid item xs={12}>
                    <FieldClassificationStats />
                </Grid>
            </Permission>
        </Grid>
    );
};

Dashboard.propTypes = {
    canReadDataMapping: PropTypes.bool,
    customerId: PropTypes.string,
    onInitialLoad: PropTypes.func,
};

export default Dashboard;
