import { types as ActionTypes } from './IAB.actions';
import { types as ConfigActionTypes } from '../../ConfigDetails.actions';
import { defaultConfiguration } from '../../../constants';
import { genericReducer } from '/b2b/common/helpers/ReduxHelpers';
import schema from '/b2b/common/state/db/iab/vendor/IabVendor.schema';

export const initialState = {
    ...defaultConfiguration.iab,
    tcf: {
        vendors: [],
    },
};

export default genericReducer(initialState, {
    [ConfigActionTypes.createSuccess]: (state, { response }) => {
        const { configuration: { iab = {} } = {} } = response;
        return {
            ...state,
            ...iab,
        };
    },
    [ConfigActionTypes.fetchDetailsSuccess]: (state, { response }) => {
        const { configuration: { iab = {} } = {} } = response;
        return {
            ...state,
            ...iab,
        };
    },
    [ConfigActionTypes.updateSuccess]: (state, { response }) => {
        const { configuration: { iab = {} } = {} } = response;
        return {
            ...state,
            ...iab,
        };
    },
    [ActionTypes.fieldChange]: (state, { field, value }) => {
        return {
            ...state,
            [field]: value,
        };
    },
    [ActionTypes.updateIabVendorsSuccess]: (state, { response = {} } = {}) => {
        const { results = [] } = response;
        return {
            ...state,
            tcf: {
                ...state.tcf,
                vendors: results,
            },
        };
    },
    [ActionTypes.addIabVendorSuccess]: (state, { response }) => {
        const id = schema.idAttribute(response);
        return {
            ...state,
            tcf: {
                ...state.tcf,
                vendors: [...(state.tcf?.vendors || []), id],
            },
        };
    },
    [ActionTypes.clearIabVendorsSuccess]: state => {
        return {
            ...state,
            tcf: {
                ...state.tcf,
                vendors: [],
            },
        };
    },
    [ActionTypes.removeIabVendorSuccess]: (state, { response }) => {
        const id = schema.idAttribute(response);
        const vendors = [...(state.tcf?.vendors || [])].filter(
            existingId => `${existingId}` !== `${id}`
        );
        return {
            ...state,
            tcf: {
                ...state.tcf,
                vendors,
            },
        };
    },
});
