import { types as ActionTypes } from './Disclosures.actions';
import { genericReducer } from '/b2b/common/helpers/ReduxHelpers';

export const initialState = [];

export default genericReducer(initialState, {
    [ActionTypes.add]: (state, item = {}) => {
        return [
            {
                name: '',
                type: 'cookie',
                classification: '',
                provider: '',
                purpose: '',
                expiry: '',
                ...item,
            },
            ...state,
        ];
    },
    [ActionTypes.fieldChange]: (state, { index, field, value }) => {
        if ((!index && index !== 0) || !state[index] || value === state[index][field]) {
            return state;
        }
        return [
            ...state.slice(0, index),
            { ...state[index], [field]: value },
            ...state.slice(index + 1),
        ];
    },
    [ActionTypes.delete]: (state, { index }) => {
        if ((!index && index !== 0) || !state[index]) {
            return state;
        }
        return [...state.slice(0, index), ...state.slice(index + 1)];
    },
});
