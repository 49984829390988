import Component from './NotificationList.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';
import { markAllNotificationsRead } from './NotificationList.commands';

const mapStateToProps = (state, { limit }) => {
    const resultIds = fromState.Auth.user.notifications
        .getResults(state)
        .filter(notification => notification && !notification.read)
        .slice(0, limit);
    return {
        resultIds,
        results: fromState.DB.notification.getEntitiesForIds(state, resultIds),
        resultCount: fromState.Auth.user.notifications.getResultCount(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        markAllRead: () => dispatch(markAllNotificationsRead()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
