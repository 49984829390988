import { resetAuthState, resetTemporaryPassword } from './SignUpResend.commands';

import Component from './SignUpResend.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const mapStateToProps = state => {
    return {
        email: fromState.Auth.getEmail(state),
        isLoading: fromState.Auth.isLoading(state),
    };
};

const mapDispatchToProps = {
    resetAuthState,
    resetTemporaryPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
