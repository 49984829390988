import {
    types as ActionTypes,
    asyncEvents as AsyncEvents,
} from './DataStoreClassifications.actions';
import { crudPagingReducers, genericReducer } from '../../../common/helpers/ReduxHelpers';

import { combineReducers } from 'redux';
import schema from '/b2b/dataStores/state/db/classification/DataStoreClassification.schema';

export const initialState = {
    classificationIdsWithoutSelections: new Set(),
    offset: 0,
    limit: 25,
    query: '',
    results: [],
    resultCount: 0,
    error: null,
    loading: false,
};

export default combineReducers({
    classificationIdsWithoutSelections: genericReducer(
        initialState.classificationIdsWithoutSelections,
        {
            [ActionTypes.fetchResultsSuccess]: (state, { response }) => {
                const { results } = response;
                // TODO: Find a way to do this that isn't fragile, or re-architect to remove Unclassified as a classification
                return new Set(
                    results
                        .filter(
                            ({ name }) =>
                                name === 'dataMapping.classificationTypes.none.name' || // UNCLASSIFIED
                                name === 'dataMapping.classificationTypes.notUserData.name' // NOT USER DATA
                        )
                        .map(result => result.id)
                );
            },
        }
    ),
    ...crudPagingReducers(
        initialState,
        Object.keys(AsyncEvents).map(key => ActionTypes[key]),
        schema
    ),
});
