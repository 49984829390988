import { useCallback, useEffect, useReducer, useRef, useState } from 'react';

import identity from 'lodash/identity';

/**
 * @function Thunk
 * @param {Dispatch} dispatch
 * @param {Function} getState
 * @returns {void|*}
 */

/**
 * @function Dispatch
 * @param {Object|Thunk} action
 * @returns {void|*}
 */

/**
 * @object currentState
 */

/**
 * @function GetState
 * @returns {currentState}
 */

/**
 * Augments React's useReducer() hook so that the action
 * dispatcher supports thunks.
 *
 * @param {Function} reducer
 * @param {*} initialArg
 * @param {Function} [init]
 * @returns {[currentState, Dispatch, GetState]} [state, dispatch, initialArg]
 */
export const useThunkReducer = (reducer, initialArg, init = identity) => {
    const mounted = useRef(true);
    const [initialState] = useReducer(identity, initialArg, init);
    const [hookState, setHookState] = useState(initialState);

    // State management.
    const state = useRef(hookState);
    const getState = useCallback(() => state.current, [state]);
    const setState = useCallback(
        newState => {
            state.current = newState;
            mounted.current && setHookState(newState);
        },
        [state, setHookState]
    );

    // Reducer.
    const reduce = useCallback(
        action => {
            return reducer(getState(), action);
        },
        [reducer, getState]
    );

    // Augmented dispatcher.
    const dispatch = useCallback(
        action => {
            const response =
                typeof action === 'function'
                    ? action(dispatch, getState)
                    : setState(reduce(action));
            return response;
        },
        [getState, setState, reduce]
    );

    useEffect(() => ((mounted.current = true), () => (mounted.current = false)), []);

    return [hookState, dispatch, getState];
};

export default useThunkReducer;
