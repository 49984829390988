export default theme => ({
    root: {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        '&.minified .MuiListItemText-root': {
            display: 'none',
        },
        '&.minified .expand-icon': {
            display: 'none',
        },
        // // shift minified list items to the left
        '&.minified': {
            paddingLeft: theme.spacing(0.5),
        },
    },
    listSubheader: {
        backgroundColor: theme.palette.tertiary.main,
        color: theme.palette.grey[400],
        fontSize: '0.75rem',
        lineHeight: 1,
        marginLeft: theme.spacing(-1),
        marginTop: theme.spacing(4),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        textTransform: 'uppercase',
    },
    listItem: {
        cursor: 'pointer',
        marginLeft: theme.spacing(-1),
        paddingLeft: theme.spacing(3),
        width: 'auto',
        borderColor: 'transparent',
        borderStyle: 'solid',
        borderWidth: '0 0 0 4px',
        transitionDuration: '100ms, 300ms',
        transitionProperty: 'background-color, border-color',
        transitionTimingFunction: 'ease-in-out, ease-in-out',
        '& $listItemIcon': {
            marginLeft: '-4px',
        },
        '&:hover': {
            transitionDuration: '0ms, 0ms',
            backgroundColor: theme.palette.secondary.light,
            '& $listItemIcon': {
                color: theme.palette.secondary.contrastText,
            },
            '& $listItemText': {
                color: theme.palette.secondary.contrastText,
            },
        },
    },
    activeListItem: {
        borderColor: `${theme.palette.secondary.main}`,
        backgroundColor: theme.palette.secondary.light,
        transitionDuration: '300ms, 300ms',
        '&:hover': {
            transitionDuration: '0ms, 0ms',
        },
        '& $listItemText': {
            color: theme.palette.secondary.contrastText,
        },
        '& $listItemIcon': {
            color: theme.palette.secondary.contrastText,
        },
        '& + $submenu': {
            borderColor: `${theme.palette.secondary.main}`,
            transitionDuration: '100ms, 300ms, 300ms',
        },
    },
    listItemIcon: {
        marginRight: theme.spacing(-2),
        color: theme.palette.common.white,
    },
    listItemText: {
        fontWeight: 500,
        color: theme.palette.common.white,
    },
    listMenuExpanded: {
        backgroundColor: theme.palette.tertiary.light,
    },
    submenu: {
        backgroundColor: theme.palette.tertiary.light,
        borderColor: 'transparent',
        borderStyle: 'solid',
        borderWidth: '0 0 0 4px',
        marginLeft: theme.spacing(-1),
        marginBottom: '1px',
        overflowX: 'hidden',
        transitionDuration: '100ms, 100ms, 300ms',
        transitionProperty: 'background-color, border-color, height',
        transitionTimingFunction: 'ease-in-out, ease-in-out, cubic-bezier(0.4, 0, 0.2, 1)',
    },
    submenuItem: {
        paddingLeft: theme.spacing(8),
    },
    flyoutSubmenuText: {
        color: theme.palette.common.black,
    },
    flyoutSubmenuItem: {
        marginLeft: theme.spacing(0),
        paddingLeft: theme.spacing(2),
        borderWidth: '0px',
    },
    flyoutSubmenuHeader: {
        fontWeight: '700',
        fontSize: 16,
        color: '#180D43',
    },
    divider: {
        margin: '16px 16px 16px 12px',
    },
});
