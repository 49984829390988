import { Link, Typography } from '@mui/material';

import Locale from '/b2b/common/components/Locale';
import PropTypes from 'prop-types';
import React from 'react';
import ValidationForm from '/b2b/common/components/ValidationForm';
import makeStyles from '@mui/styles/makeStyles';
import schema from './SignInConfirm.schema';
import styles from './SignInConfirm.styles';

const useStyles = makeStyles(styles);

const SignInConfirm = props => {
    const { confirmSignIn, mfaType, resendCode, signOut } = props;
    const classes = useStyles(props);
    return (
        <div className={classes.content}>
            <div className={classes.contentBody}>
                <ValidationForm
                    data-test-id="sign-in-confirm__validation-form"
                    title="auth.confirmSignIn"
                    submitText="auth.continueSignIn"
                    schema={schema}
                    onSubmit={confirmSignIn}
                    defaultValues={{ mfaType }}
                    continuousValidation={false}
                >
                    {mfaType === 'SMS' && (
                        <Typography className={classes.signIn} variant="body1">
                            <Link
                                data-test-id="sign-in-confirm__resend-code-link"
                                className={classes.signInUrl}
                                onClick={resendCode}
                            >
                                <Locale path="password.resendCode" />
                            </Link>
                        </Typography>
                    )}
                    <Typography className={classes.signIn} variant="body1">
                        <Locale path="auth.shouldUseDifferentAccount" />{' '}
                        <Link
                            data-test-id="sign-in-confirm__sign-out-link"
                            className={classes.signInUrl}
                            onClick={signOut}
                        >
                            <Locale path="common.signIn" />
                        </Link>
                    </Typography>
                </ValidationForm>
            </div>
        </div>
    );
};

SignInConfirm.propTypes = {
    className: PropTypes.string,
    username: PropTypes.string,
    mfaType: PropTypes.string,
    confirmSignIn: PropTypes.func.isRequired,
    resendCode: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
};

export default SignInConfirm;
