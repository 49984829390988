import { alpha } from '@mui/material/styles';

export default theme => {
    const textColor =
        theme.palette.mode === 'light'
            ? theme.palette.primary.contrastText
            : theme.palette.getContrastText(theme.palette.background.default);
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor:
                theme.palette.mode === 'light'
                    ? theme.palette.primary.main
                    : theme.palette.background.default,
        },
        container: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        button: {
            color: alpha(textColor, 0.54),
        },
        buttonSelected: {
            color: textColor,
        },
    };
};
