import { alertClasses } from '@mui/material';
import { lighten } from '@mui/system/colorManipulator';
import theme from '../default';

export default {
    styleOverrides: {
        root: {
            [`&.${alertClasses.outlinedError}`]: {
                backgroundColor: lighten(theme.palette.error.light, 0.9),
            },
            [`&.${alertClasses.outlinedInfo}`]: {
                backgroundColor: lighten(theme.palette.info.light, 0.9),
            },
            [`&.${alertClasses.outlinedSuccess}`]: {
                backgroundColor: lighten(theme.palette.success.light, 0.9),
            },
            [`&.${alertClasses.outlinedWarning}`]: {
                backgroundColor: lighten(theme.palette.warning.light, 0.9),
            },
        },
    },
};
