import Component from './VerifyTOTP.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';
import { verify } from './VerifyTOTP.commands';

const mapStateToProps = state => {
    return {
        username: fromState.Auth.user.getId(state),
        authState: fromState.Auth.getAuthState(state),
        isLoading: fromState.Auth.isLoading(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        verify: params => dispatch(verify(params)),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...ownProps,
        ...stateProps,
        onSubmit: dispatchProps.verify,
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
