import * as rudderanalytics from 'rudder-sdk-js';

import Logger from './helpers/Logger';
import { rudderStackUrl } from '@osano-b2b';

/**
 * The group call associates a user to a specific organization.
 * @param  {string} groupId - Denotes the group identifier to which the traits are to be modified or added. RudderStack will call the destination APIs to attach the currently identified user to this group.
 * @param  {Object} [traits] - Denotes the traits of the group. RudderStack will pass these traits to the destination to enhance the group properties.
 * @param  {Object} [options] - This dictionary provides additional context to the event payload.
 * @callback [callback] - This function gets executed after successful execution of the group() method.
 */
function group(...args) {
    try {
        rudderanalytics.group(...args);
    } catch (err) {
        Logger.error('Rudderstack group error', err);
    }
}

/**
 * This method allows you to link the users and their actions to a specific userid.
 * You can also add additional information as traits to a user. Once you set the identify information to the user,
 * those will be passed to the successive track or page calls. To reset the user identification, you can use the reset method.
 * @param  {string} [userId] - This string defines the database ID of the user. If provided, this optional argument will be sent to destinations as the user ID instead of an anonymous ID
 * @param  {Object} [traits] - This optional dictionary contains the traits or properties associated with a userid such as email, address, etc.
 * @param  {Object} [options] - This dictionary is also optional, and provides information such as context, integrations, and anonymousId. Specific user traits can be provided as the context as well
 * @callback [callback] - This function gets executed after successful execution of the identify() method.
 */
function identify(...args) {
    try {
        rudderanalytics.identify(...args);
    } catch (err) {
        Logger.error('Rudderstack identify error', err);
    }
}

/**
 * The load() method loads the rudderstack.js file with your write key.
 */
function load() {
    try {
        rudderanalytics.load(process.env.RUDDERSTACK_WRITE_KEY, rudderStackUrl, {
            configUrl: rudderStackUrl,
        });
    } catch (err) {
        Logger.error('Rudderstack load error', err);
    }
}

/**
 * This method lets you record information about the web page being viewed by the user.
 * This includes page views, category, and other relevant information about the page.
 * @param  {string} [category] - An optional string that defines the category of the page
 * @param  {string} [name] - An optional string that defines the name of the page
 * @param  {Object} [properties] - An optional dictionary that defines the properties of the page. These properties are auto-captured by the page
 * @param  {Object} [options] - An optional dictionary that provides information such as context, integrations, anonymousId, etc. Specific user traits can be provided as the context as well
 * @callback [callback] - This function gets executed after successful execution of the page() method
 */
function page(name) {
    try {
        const { referrer, title, URL: url } = document;
        const { pathname: path, search } = document.location;

        const properties = {
            path,
            referrer,
            search,
            title,
            url,
        };

        rudderanalytics.page(name, properties);
    } catch (err) {
        Logger.error('Rudderstack page error', err);
    }
}

/**
 * This method resets the userid and the associated traits and properties of that specific user.
 */
function reset() {
    try {
        rudderanalytics.reset();
    } catch (err) {
        Logger.error('Rudderstack reset error', err);
    }
}

/**
 * This method allows you to track any actions that your users might perform. Each of these actions is commonly referred to as an event.
 * @param  {string} event - A string that captures the name of the event that is being tracked
 * @param  {Object} [properties] - An optional dictionary that tracks the properties of the event
 * @param  {Object} [options] - An optional dictionary of information such as context, integrations, etc. Specific user traits can be provided as the context as well
 * @callback [callback] - This function gets executed after successful execution of the track call
 */
function track(event, properties = {}, options = {}, callback) {
    try {
        rudderanalytics.track(event, properties, options, callback);
    } catch (err) {
        Logger.error('Rudderstack track error', err);
    }
}

export default {
    group,
    identify,
    load,
    page,
    reset,
    track,
};
