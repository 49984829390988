import { denormalize } from 'normalizr';
import get from 'lodash/get';
import { getStore as getDB } from '../../../state/db/DB.selectors';

const emptyErrorsArray = [];
const emptyValidationScheme = {};

export const getId = state => get(state, 'id');
export const getEntityById = (reduxState, id, schema) =>
    denormalize(id, schema, getDB(reduxState)) || {};
export const getSubmissionError = state => get(state, 'error') || '';

export const isLoading = state => !!get(state, 'loading');
export const isUpdating = state => !!get(state, 'updating');

export const getFieldErrors = state => get(state, 'fieldErrors');
export const getErrorsForField = (state, field) =>
    get(getFieldErrors(state), field) || emptyErrorsArray;
export const getChanges = state => get(state, 'changes');
export const getChangeForField = (state, field) => get(getChanges(state), field, null);
export const getOriginalValues = state => get(state, 'details');
export const getOriginalValueForField = (state, field) =>
    get(getOriginalValues(state), field, null);
export const getCurrentValues = state => get(state, 'values');
export const getCurrentValueForField = (state, field) => get(getCurrentValues(state), field, null);

export const getValidationScheme = state => get(state, 'validationScheme') || emptyValidationScheme;
export const getValidationSchemeForField = (state, field) => {
    const constraint = get(getValidationScheme(state), field) || emptyValidationScheme;
    let scheme = constraint;
    // Functional constraints must be evaluated
    if (typeof constraint === 'function') {
        scheme = constraint(getCurrentValueForField(state, field), getCurrentValues(state), field);
    }
    return scheme;
};

export const hasFieldBeenTouched = (state, field) => !!getChangeForField(state, field);
export const hasTriedToSubmit = state => get(state, 'submitAttempted') || false;

export const isPristine = state => Object.keys(getChanges(state)).length === 0;
export const isSubmitting = state => get(state, 'submitting') === true;
export const isValid = state => Object.keys(getFieldErrors(state)).length === 0;

export const canFieldBeEmpty = (state, field) => {
    const { presence } = getValidationSchemeForField(state, field);
    return (presence && presence?.allowEmpty) === false ? false : true;
};

export const canSubmit = (state, forceAllow) => {
    return (!isSubmitting(state) && isValid(state) && !isPristine(state)) || forceAllow;
};
export const canRefresh = state => !isSubmitting(state) && !isUpdating(state);

export const shouldShowFieldError = (state, field) => {
    const { continuousValidation } = state;
    const fieldTouched = hasFieldBeenTouched(state, field);
    if (continuousValidation && fieldTouched) {
        // Field should always show errors whenever they are found
        return true;
    }

    const triedToSubmit = hasTriedToSubmit(state);
    const value = getCurrentValueForField(state, field);
    if (triedToSubmit && !canFieldBeEmpty(state, field) && value === '') {
        // We tried to submit, but a field can't be empty
        return true;
    }

    // Always show the error if a
    return triedToSubmit;
};
