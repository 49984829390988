import * as Configuration from './configuration/Configuration.selectors';
import * as Consents from './consents/Consents.selectors';
import * as Rules from './rules/Rules.selectors';
import * as Tattles from './tattles/Tattles.selectors';
import * as Versions from './versions/Versions.selectors';

import {
    getEntityById as getOrganizationById,
    getEntitiesForIds as getOrganizationsForIds,
} from '../../organizations/state/db/Organization.selectors';

import { DOMAIN } from './ConfigDetails.constants';
import { denormalize } from 'normalizr';
import get from 'lodash/get';
import { getStore as getDB } from '../../common/state/db/DB.selectors';
import { getVendorListVersion } from '/b2b/common/state/db/iab/Iab.selectors';
import schema from './db/ConsentConfig.schema';
import set from 'lodash/set';

const emptyArray = [];
const emptyObject = {};

export const configuration = Configuration;
export const consents = Consents;
export const tattles = Tattles;
export const rules = Rules;
export const versions = Versions;

export const getStore = state => get(state, DOMAIN) || emptyObject;

export const getId = state => getStore(state).customerId;
export const getError = state => getStore(state).error;
export const getUpdateError = state => getStore(state).updateError;
export const getPublishError = state => getStore(state).publishError;

export const isLoading = state => getStore(state).loading;
export const isPublishing = state => getStore(state).publishing;
export const publishedRevision = state => getStore(state).publishedRevision;

export const isUpdating = state => getStore(state).updating;
export const didSubmit = state => getStore(state).submitted;

export const getEntityById = (state, id) => denormalize(id, schema, getDB(state)) || emptyObject;
export const getEntitiesForIds = (state, ids) => denormalize(ids, [schema], getDB(state));

export const getEntityFieldById = (state, { id, field }) =>
    get(getStore(state), [id, field]) || null;

export const getField = (state, field) => {
    const value = get(getStore(state), field);
    return value === undefined || value === null ? '' : value;
};

export const getErrors = state => getStore(state).errors;
export const getChanges = state => ({
    configuration: configuration.getPayload(state),
    domains: getField(state, 'domains'),
    organizations: getOrganizationsForIds(
        state,
        getField(state, 'organizations').map(({ orgId }) => orgId)
    ).filter(org => org && !org.isRoot),
    name: getField(state, 'name'),
    mode: getField(state, 'mode'),
});
export const getTouched = state => getStore(state).touched || emptyObject;
export const getRulesUpdated = state => getStore(state).rulesUpdated || false;
export const getClassificationView = state => getStore(state).classificationView || 'unclassified';
export const getOrganizations = state => getStore(state).organizations || emptyArray;
export const getFieldErrors = (state, field) => get(getErrors(state), field, emptyObject);
export const getFieldTouched = (state, field) => get(getTouched(state), field, false) || false;

export const getPayload = (state, formikValues = {}) => {
    const orgIds = formikValues.organizations
        ? formikValues.organizations.map(({ value }) => value)
        : getField(state, 'organizations').map(({ orgId } = {}) => orgId);
    const config = configuration.getPayload(state);
    const iab = { ...config.iab, ...formikValues.configuration?.iab };
    set(iab, 'tcf.v2.vendorListVersion', getVendorListVersion(state));

    return {
        configuration: {
            ...config,
            ...formikValues.configuration,
            iab,
        },
        domains: formikValues.domains || getField(state, 'domains'),
        orgIds: orgIds.filter(orgId => !!orgId && !getOrganizationById(state, orgId).isRoot),
        name: formikValues.name || getField(state, 'name'),
        mode: formikValues.mode || getField(state, 'mode'),
    };
};
