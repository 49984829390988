import React, { useCallback } from 'react';

import Locale from '../Locale';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import styles from './DatePicker.styles';
import { translate } from '../../helpers/i18n';

const useStyles = makeStyles(styles);

const DatePicker = (props = {}) => {
    const classes = useStyles(props);
    const {
        autoOk = true,
        DatePickerProps = {},
        disabled,
        format = 'MM/DD/YYYY',
        fullWidth,
        label,
        localeCode,
        helperText,
        InputLabelProps,
        onChange,
        placeholder,
        required,
        value,
    } = props;

    const renderInput = useCallback(
        textFieldProps => (
            <TextField
                {...textFieldProps}
                InputProps={{
                    ...textFieldProps.InputProps,
                    notched: !!label,
                }}
                InputLabelProps={{
                    ...textFieldProps.InputLabelProps,
                    ...InputLabelProps,
                }}
                variant="outlined"
                fullWidth={fullWidth}
                classes={{
                    root: classes.textField,
                }}
                color="secondary"
                value={value ? moment(value).format(format) : undefined}
                placeholder={translate(placeholder)}
                helperText={helperText && <Locale path={helperText} />}
                required={required}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            classes.textField,
            format,
            fullWidth,
            helperText,
            InputLabelProps,
            label,
            localeCode,
            placeholder,
            required,
            value,
        ]
    );

    return (
        <MuiDatePicker
            autoOk={autoOk}
            inputFormat={format}
            label={<Locale path={label} />}
            onChange={selectedDate => onChange(selectedDate ? selectedDate.valueOf() : null)}
            value={value || null}
            renderInput={renderInput}
            disabled={disabled}
            {...DatePickerProps}
        />
    );
};

DatePicker.propTypes = {
    autoOk: PropTypes.bool,
    DatePickerProps: PropTypes.object,
    disabled: PropTypes.bool,
    format: PropTypes.string,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    localeCode: PropTypes.string,
    helperText: PropTypes.string,
    InputLabelProps: PropTypes.object,
    onChange: PropTypes.func,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    required: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DatePicker;
