import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from './TextField';
import useBoundCallback from '../../helpers/Hooks/useBoundCallback';
import { withFormikField } from './FormikFields';

/**
 * @typedef {Object} Base
 * @property {Object=} translationOptions The `options` object provided to all internal <Locale/> translations for the TextField
 * @typedef {Base & Pick<import('@mui/material').FormControlProps, 'error' | 'fullWidth' | 'required'> & Pick<import('@mui/material').TextFieldProps, 'helperText' | 'placeholder'> & import('@mui/x-date-pickers').DatePickerProps} DatePickerProps
 */

/**
 * @type {React.ForwardRefExoticComponent<DatePickerProps>}
 */
const DatePicker = React.forwardRef((props, ref) => {
    const {
        disabled,
        error,
        fullWidth,
        inputFormat = 'MM/DD/YYYY',
        helperText,
        onAccept,
        onChange,
        onError,
        placeholder,
        required,
        value: providedValue,
        ...remain
    } = props;

    // DatePicker can never be uncontrolled, so we need to keep track of the date internally so it updates correctly
    const [value, setValue] = React.useState(providedValue ?? null);

    const handleAccept = useBoundCallback(
        (onAccept, selectedDate) => {
            // Currently using moment.js, so we must sent the ISO timestamp to the handler using `valueOf()`
            setValue(selectedDate.valueOf());
            onAccept && onAccept(selectedDate ? selectedDate.valueOf() : null);
        },
        [onAccept]
    );

    const handleChange = useBoundCallback(
        // eslint-disable-next-line no-unused-vars
        (onChange, onError, dateOrNull, textFieldValue) => {
            // Currently using moment.js, so we must sent the ISO timestamp to the handler using `valueOf()`
            const timestamp = dateOrNull && dateOrNull.valueOf();
            // timestamp is NaN when the input date is invalid according to the format
            if (!isNaN(timestamp) || textFieldValue === undefined) {
                // Valid timestamp should clear the existing error
                onError && onError(null, textFieldValue);
                setValue(timestamp);
            } else {
                // Invalid date
                onError && onError('invalidDate', textFieldValue);
            }
            onChange && onChange(timestamp, textFieldValue);
        },
        [onChange, onError]
    );

    const renderInput = useBoundCallback(
        (
            providedError,
            fullWidth,
            helperText,
            providedPlaceholder,
            { placeholder, ...textFieldProps }
        ) => {
            return (
                <TextField
                    {...textFieldProps}
                    placeholder={providedPlaceholder || placeholder}
                    fullWidth={fullWidth}
                    error={!!providedError}
                    helperText={helperText}
                    required={required}
                />
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [error, fullWidth, helperText, placeholder]
    );

    return (
        <MuiDatePicker
            ref={ref}
            disabled={disabled}
            inputFormat={inputFormat}
            onAccept={handleAccept}
            onChange={handleChange}
            onError={onError}
            renderInput={renderInput}
            value={value}
            {...remain}
        />
    );
});

DatePicker.propTypes = {
    autoOk: PropTypes.bool,
    DatePickerProps: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    inputFormat: PropTypes.string,
    label: PropTypes.string,
    localeCode: PropTypes.string,
    helperText: PropTypes.any,
    InputLabelProps: PropTypes.object,
    onAccept: PropTypes.func,
    onChange: PropTypes.func,
    onError: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DatePicker;

export const FormikDatePicker = withFormikField(DatePicker, { type: 'date' });
FormikDatePicker.displayName = 'FormikDatePicker';
