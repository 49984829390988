const validReadyStates = ['complete', 'interactive'];

export const onDOMReady = fn => {
    const listener = event => {
        if (validReadyStates.includes(document.readyState)) {
            document.removeEventListener(event.type, listener, true);
            return fn();
        }
    };
    document.addEventListener('readystatechange', listener, true);
};

export const showFullscreen = elem => {
    if (!elem) {
        return;
    }
    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
    }
};

export const hideFullscreen = () => {
    if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.fullscreen
    ) {
        // can use exitFullscreen
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            /* Firefox */
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            /* IE/Edge */
            document.msExitFullscreen();
        }
    }
};

export const handleSticky = (elem, { top = 0, bottom = 0 } = {}) => {
    if (!elem) {
        return -1;
    }
    const boundingBox = elem.getBoundingClientRect();
    if (window.innerHeight - bottom >= boundingBox.bottom) {
        return 1;
    } else if (boundingBox.top < top) {
        return 0;
    } else {
        return -1;
    }
};

export const addIdToElements = (parentElement, elementCounterObj, prefix = '') => {
    Object.keys(elementCounterObj).forEach(element => {
        const nodesToAddId = Array.from(
            parentElement.querySelectorAll(`${element}:not([data-testid])`)
        ).filter(n => !n.closest('[data-testid-ignore]'));
        nodesToAddId.forEach(node => {
            node.dataset.testid = `${prefix}_${element}_${++elementCounterObj[element]}`;
        });
    });
};
