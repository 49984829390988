import * as Activity from './activity/RequestActivity.selectors';
import * as Conversation from './conversation/RequestConversation.selectors';

import { DOMAIN } from './RequestDetails.constants';
import get from 'lodash/get';

export const activity = Activity;
export const conversation = Conversation;

export const getStore = state => get(state, DOMAIN) || {};

export const getId = state => getStore(state).customerId;
export const getError = state => getStore(state).error;
export const getStatusError = state => getStore(state).statusError;

export const isLoading = state => getStore(state).loading;
export const isStatusLoading = state => getStore(state).statusLoading;
