import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useMemo } from 'react';

import ColorPicker from '../../../ColorPicker';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DatePicker from '../../../DatePicker';
import DateRangePicker from '../../../DateRangePicker';
import LocalizedTooltip from '../../../LocalizedTooltip';
import MuiPhoneInput from 'material-ui-phone-number';
import PropTypes from 'prop-types';
import RevertIcon from '@mui/icons-material/Replay';
import TextEditor from '../../../TextEditor';
import Translations from './components/Translations';
import ValidationAutocomplete from './components/ValidationAutocomplete';
import ValidationButtonGroup from './components/ValidationButtonGroup';
import ValidationCheckbox from './components/ValidationCheckbox';
import ValidationChips from './components/ValidationChips';
import ValidationDivider from './components/ValidationDivider';
import ValidationFormControl from './components/ValidationFormControl';
import ValidationFormGroup from './components/ValidationFormGroup';
import ValidationJurisdictionAutocomplete from './components/ValidationJurisdictionAutocomplete';
import ValidationRadio from './components/ValidationRadio';
import ValidationSelect from './components/ValidationSelect';
import ValidationSlider from './components/ValidationSlider';
import ValidationSwitch from './components/ValidationSwitch';
import ValidationTextEditor from './components/ValidationTextEditor';
import castArray from 'lodash/castArray';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import noop from 'lodash/noop';
import startCase from 'lodash/startCase';
import styles from './ValidationField.styles';
import { translate } from '/b2b/common/helpers/i18n';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles(styles);

const updateWrapper = (field, update, originalValue, eventOrValue, index) => {
    const val =
        typeof eventOrValue === 'object' && eventOrValue && eventOrValue.target
            ? eventOrValue.target.value
            : eventOrValue;
    const valueArray = castArray(originalValue);
    update &&
        update(
            field,
            index === undefined || typeof index !== 'number'
                ? val
                : [...valueArray.slice(0, index), val, ...valueArray.slice(index + 1)],
            originalValue
        );
};

const handlePreventDefault = event => {
    event.preventDefault();
};

const ValidationField = props => {
    const {
        className,
        field,
        fullWidth = false,
        scheme = {},
        value,
        canCopy: copy = false,
        component,
        error,
        helperText: passedHelperText,
        onBlur,
        onChange,
        onCopy,
        onFocus,
        onRevert,
        required,
        disabled: fieldDisabled,
        usePopper,
        ...additionalProps
    } = props;
    const classes = useStyles(props);
    // eslint-disable-next-line no-unused-vars
    const { pattern, props: { canRevert, canCopy = copy, onClick = noop, ...schemeProps } = {} } =
        scheme;
    const { multiple = scheme?.formGroup?.multiple } = schemeProps;

    // We want to allow the label to be an empty string, so we need to check for undefined
    const label = scheme.label !== undefined ? translate(scheme.label) : startCase(field);

    const revertButton = useMemo(() => {
        return (
            <InputAdornment position="end">
                <IconButton
                    onClick={() => onRevert(field, multiple)}
                    onMouseDown={handlePreventDefault}
                    edge="end"
                    size="small"
                >
                    <RevertIcon />
                </IconButton>
            </InputAdornment>
        );
    }, [field, multiple, onRevert]);

    const handleChange = useBoundCallback(updateWrapper, [field, onChange, value]);
    const handleBlur = useBoundCallback(updateWrapper, [field, onBlur, value]);
    const handleFocus = useBoundCallback(updateWrapper, [field, onFocus, value]);

    let { type = 'text', disabled = false } = scheme;

    const id = useMemo(() => `${field}-${uuid()}`, [field]);

    if (scheme.formGroup) {
        type = `${scheme.type || 'checkbox'}-group`;
    } else if (scheme.checkbox) {
        type = 'checkbox';
    } else if (type === 'text' && scheme.numericality) {
        type = 'number';
    } else if (type === 'text' && scheme.email) {
        type = 'email';
    }

    const helperText = scheme.helperText || passedHelperText;

    disabled = disabled || fieldDisabled;

    const InputProps = useMemo(() => {
        return {
            endAdornment: canRevert && onRevert ? revertButton : undefined,
            ...schemeProps?.InputProps,
        };
    }, [schemeProps?.InputProps, onRevert]);

    const inputProps = useMemo(() => {
        return {
            ...schemeProps.inputProps,
            pattern: pattern && pattern.toString(),
        };
    }, [schemeProps?.InputProps, pattern]);

    const fieldProps = {
        id,
        disabled,
        label,
        error: !!error,
        required,
        onBlur: handleBlur,
        onChange: handleChange,
        onFocus: handleFocus,
        // We want to make sure all form fields are controlled, so we default `null` and `undefined` to `''`
        // eslint-disable-next-line eqeqeq
        value: value == undefined ? '' : value,
        name: field,
        type,
        ...additionalProps,
        ...schemeProps,
        InputProps,
        inputProps,
    };

    const formControlProps = {
        onClick,
    };

    switch (type) {
        case 'autocomplete': {
            return (
                <ValidationFormControl
                    required={required}
                    error={error}
                    helperText={helperText}
                    className={classNames(classes.formGroup, classes.chipSelect, className)}
                    {...formControlProps}
                >
                    <ValidationAutocomplete
                        {...fieldProps}
                        placeholder={translate(fieldProps.placeholder)}
                    />
                </ValidationFormControl>
            );
        }

        case 'jurisdiction': {
            return (
                <ValidationFormControl
                    required={required}
                    error={error}
                    helperText={helperText}
                    className={classNames(classes.formGroup, classes.chipSelect, className)}
                    {...formControlProps}
                >
                    <ValidationJurisdictionAutocomplete
                        {...fieldProps}
                        placeholder={translate(fieldProps.placeholder)}
                        multiple
                        limitTags={1}
                    />
                </ValidationFormControl>
            );
        }

        case 'translations': {
            // eslint-disable-next-line no-unused-vars
            const { inputProps, error, value, ...translationProps } = fieldProps;
            return (
                <Translations
                    usePopper={usePopper}
                    value={value === '' ? undefined : value}
                    {...translationProps}
                />
            );
        }

        case 'phone': {
            return (
                <ValidationFormControl
                    required={required}
                    error={error}
                    helperText={helperText}
                    className={classNames(classes.formGroup, className)}
                    {...formControlProps}
                >
                    <MuiPhoneInput
                        defaultCountry="us"
                        disableAreaCodes
                        className={classes.textField}
                        variant="outlined"
                        color="secondary"
                        {...fieldProps}
                        value={fieldProps.value || ''}
                        InputLabelProps={{
                            ...fieldProps.InputLabelProps,
                            shrink:
                                fieldProps?.InputLabelProps?.shrink === undefined
                                    ? !!fieldProps.value
                                    : fieldProps.InputLabelProps.shrink,
                        }}
                    />
                </ValidationFormControl>
            );
        }

        case 'chips': {
            return (
                <ValidationFormControl
                    required={required}
                    error={error}
                    helperText={helperText}
                    className={classNames(classes.formGroup, classes.chips)}
                    {...formControlProps}
                >
                    <ValidationChips
                        {...fieldProps}
                        placeholder={translate(fieldProps.placeholder)}
                    />
                </ValidationFormControl>
            );
        }

        case 'divider': {
            return <ValidationDivider {...fieldProps} />;
        }

        case 'button-group': {
            const { options = {} } = scheme.formGroup;
            const {
                type /* eslint-disable-line no-unused-vars */,
                error: hasError /* eslint-disable-line no-unused-vars */,
                inputProps /* eslint-disable-line no-unused-vars */,
                InputProps /* eslint-disable-line no-unused-vars */,
                ...rest
            } = fieldProps;
            return (
                <ValidationFormControl
                    required={required}
                    error={error}
                    helperText={helperText}
                    className={classNames(classes.formGroup, classes.buttonGroup)}
                    {...formControlProps}
                >
                    <ValidationButtonGroup {...rest} options={options} multiple={multiple} />
                </ValidationFormControl>
            );
        }

        case 'checkbox': {
            // TODO: verify i18n is thorough (no further translation needed)
            const { InputProps /* eslint-disable-line no-unused-vars */, ...rest } = fieldProps;
            return (
                <ValidationFormControl
                    required={required}
                    error={error}
                    helperText={helperText}
                    className={classNames(classes.formGroup, className)}
                    {...formControlProps}
                >
                    <ValidationCheckbox
                        {...rest}
                        name={field}
                        value={field}
                        checked={value === true ? true : false}
                        onChange={() => handleChange(!value)}
                    />
                </ValidationFormControl>
            );
        }

        case 'checkbox-group': {
            // TODO: verify i18n is thorough (no further translation needed)
            const { options = {} } = scheme.formGroup;
            return (
                <ValidationFormControl
                    required={required}
                    error={error}
                    helperText={helperText}
                    className={classNames(classes.formGroup, className)}
                    {...formControlProps}
                >
                    <ValidationFormGroup
                        {...fieldProps}
                        options={options}
                        multiple={multiple}
                        component={ValidationCheckbox}
                    />
                </ValidationFormControl>
            );
        }

        case 'slider': {
            const {
                type /* eslint-disable-line no-unused-vars */,
                error: hasError /* eslint-disable-line no-unused-vars */,
                inputProps /* eslint-disable-line no-unused-vars */,
                InputProps /* eslint-disable-line no-unused-vars */,
                ...rest
            } = fieldProps;
            return (
                <ValidationFormControl
                    required={required}
                    error={error}
                    helperText={helperText}
                    className={classNames(classes.formGroup, className)}
                    {...formControlProps}
                >
                    <ValidationSlider
                        {...rest}
                        name={field}
                        value={value}
                        onChange={handleChange}
                    />
                </ValidationFormControl>
            );
        }

        case 'switch': {
            const {
                type /* eslint-disable-line no-unused-vars */,
                error: hasError /* eslint-disable-line no-unused-vars */,
                inputProps /* eslint-disable-line no-unused-vars */,
                InputProps /* eslint-disable-line no-unused-vars */,
                ...rest
            } = fieldProps;
            return (
                <ValidationFormControl
                    required={required}
                    error={error}
                    helperText={helperText}
                    className={classNames(classes.formGroup, className)}
                    {...formControlProps}
                >
                    <ValidationSwitch
                        {...rest}
                        value={field}
                        checked={value === true ? true : false}
                        onChange={() => handleChange(!value)}
                    />
                </ValidationFormControl>
            );
        }

        case 'select-group': {
            // TODO: verify i18n is thorough (no further translation needed)
            const { options = {} } = scheme.formGroup;
            return (
                <ValidationFormControl
                    required={required}
                    error={error}
                    helperText={helperText}
                    className={classNames(classes.formGroup, className)}
                    {...formControlProps}
                >
                    <ValidationFormGroup
                        {...fieldProps}
                        usePopper={usePopper}
                        multiple={multiple}
                        options={options}
                        component={ValidationSelect}
                    />
                </ValidationFormControl>
            );
        }

        case 'select': {
            const { inclusion: { within: options = {} } = {} } = scheme;
            return (
                <ValidationFormControl
                    required={required}
                    error={error}
                    helperText={helperText}
                    className={classNames(classes.formGroup, className)}
                    {...formControlProps}
                >
                    <ValidationSelect
                        usePopper={usePopper}
                        options={options}
                        component={component}
                        multiple={multiple}
                        {...fieldProps}
                    />
                </ValidationFormControl>
            );
        }

        case 'radio-group':
        case 'radio': {
            // TODO: verify i18n is thorough (no further translation needed)
            const { inclusion: { within: options = {} } = {} } = scheme;
            return (
                <ValidationFormControl
                    required={required}
                    error={error}
                    helperText={helperText}
                    className={classNames(classes.formGroup, className)}
                    {...formControlProps}
                >
                    <ValidationFormGroup
                        options={options}
                        {...fieldProps}
                        component={ValidationRadio}
                    />
                </ValidationFormControl>
            );
        }

        case 'color': {
            return (
                <ValidationFormControl
                    required={required}
                    error={error}
                    helperText={helperText}
                    className={classNames(classes.colorPicker, className)}
                    {...formControlProps}
                >
                    <ColorPicker
                        className={classes.textField}
                        variant="outlined"
                        color="secondary"
                        {...fieldProps}
                        value={fieldProps.value || ''}
                    />
                </ValidationFormControl>
            );
        }

        case 'texteditor': {
            return (
                <ValidationFormControl
                    fullWidth={fullWidth}
                    required={required}
                    error={error}
                    helperText={helperText}
                    className={classNames(classes.formGroup, className)}
                    {...formControlProps}
                >
                    <ValidationTextEditor {...fieldProps}>
                        <TextEditor
                            value={fieldProps.value || ''}
                            setValue={value => handleChange(value)}
                        />
                    </ValidationTextEditor>
                </ValidationFormControl>
            );
        }

        case 'date': {
            return (
                <ValidationFormControl
                    required={required}
                    error={error}
                    helperText={helperText}
                    className={classNames(classes.formGroup, classes.chipSelect)}
                    {...formControlProps}
                >
                    <DatePicker {...fieldProps} />
                </ValidationFormControl>
            );
        }

        case 'date-range': {
            return (
                <ValidationFormControl
                    required={required}
                    error={error}
                    helperText={helperText}
                    className={classNames(classes.formGroup, classes.chipSelect)}
                    {...formControlProps}
                >
                    <DateRangePicker {...fieldProps} />
                </ValidationFormControl>
            );
        }

        default: {
            const Component = component || TextField;
            return (
                <ValidationFormControl
                    fullWidth={fullWidth}
                    required={required}
                    error={error}
                    helperText={helperText}
                    className={classNames(classes.formGroup, className)}
                    {...formControlProps}
                >
                    <Component
                        fullWidth={fullWidth}
                        className={classes.textField}
                        variant="outlined"
                        color="secondary"
                        {...fieldProps}
                        value={fieldProps.value || ''}
                        placeholder={translate(fieldProps.placeholder)}
                        helperText={translate(fieldProps.helperText)}
                        InputProps={{
                            ...(canCopy
                                ? {
                                      endAdornment: (
                                          <InputAdornment position="end">
                                              <LocalizedTooltip title="codeBlock.copyToClipboard">
                                                  <ContentCopyIcon
                                                      className={classes.copyButton}
                                                      onClick={() => onCopy(fieldProps.value)}
                                                  />
                                              </LocalizedTooltip>
                                          </InputAdornment>
                                      ),
                                  }
                                : {}),
                            ...fieldProps.InputProps,
                        }}
                        InputLabelProps={{
                            ...fieldProps.InputLabelProps,
                            shrink:
                                fieldProps?.InputLabelProps?.shrink === undefined
                                    ? !!fieldProps.value
                                    : fieldProps.InputLabelProps.shrink,
                        }}
                    />
                </ValidationFormControl>
            );
        }
    }
};

ValidationField.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    canCopy: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func, PropTypes.string]),
    disabled: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.bool]),
    field: PropTypes.string,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onCopy: PropTypes.func,
    onFocus: PropTypes.func,
    onRevert: PropTypes.func,
    required: PropTypes.bool,
    scheme: PropTypes.object,
    usePopper: PropTypes.bool,
    value: PropTypes.any,
};

export default ValidationField;
