import palette from '../palette';
import theme from '../default';
import typography from '../typography';

export default {
    root: {
        color: palette.text.secondary,
        ...typography.body1,
    },
    input: {
        '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
            animationDuration: '0ms',
        },
        '&:autofill, &:autofill:hover, &:autofill:focus': {
            animationDuration: '0ms',
        },
    },
    adornedEnd: {
        '& .MuiSelect-icon + .MuiInputAdornment-positionEnd': {
            marginRight: theme.spacing(2),
        },
    },
};
