import React, { forwardRef } from 'react';

import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const NavLinkListItem = forwardRef((props, ref) => (
    <li ref={ref} data-mui-internal-clone-element={props['data-mui-internal-clone-element']}>
        <NavLink {...props} />
    </li>
));

NavLinkListItem.propTypes = {
    'data-mui-internal-clone-element': PropTypes.bool,
};

export default NavLinkListItem;
