import { Button, CircularProgress } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';

import Locale from '/b2b/common/components/Locale';
import Portlet from '/b2b/common/components/Portlet';
import PortletContent from '/b2b/common/components/PortletContent';
import PortletFooter from '/b2b/common/components/PortletFooter';
import PropTypes from 'prop-types';
import ValidationForm from '/b2b/common/components/ValidationForm';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import schema from './AccountDetails.schema';
import styles from './AccountDetails.styles';

const useStyles = makeStyles(styles);

const AccountDetails = props => {
    const { className, isLoading, firstName, lastName, email, userId, roles, updateUserDetails } =
        props;
    const classes = useStyles(props);

    const handleSave = useCallback(
        params => {
            return updateUserDetails({
                userId,
                roles: roles.split(','),
                ...params,
            });
        },
        [updateUserDetails, userId, roles]
    );
    const handleSubmit = useRef(handleSave);
    const [isValid, setIsValid] = useState(false);
    const [canSubmit, setCanSubmit] = useState(isValid);

    const rootClassName = classNames(classes.root, className);

    const handleValidate = useCallback(validation => {
        setCanSubmit(validation === undefined);
        setIsValid(!validation);
    }, []);

    return (
        <Portlet className={rootClassName}>
            <PortletContent>
                <ValidationForm
                    className={classes.form}
                    title="common.profile"
                    subtitle="common.canBeEdited"
                    submitText="common.save"
                    schema={schema}
                    submitButton={({ onClick }) => {
                        handleSubmit.current = onClick;
                        return null;
                    }}
                    onValidate={handleValidate}
                    onSubmit={handleSave}
                    defaultValues={{
                        firstName,
                        lastName,
                        email,
                    }}
                    isLoading={isLoading}
                />
            </PortletContent>
            <PortletFooter className={classes.portletFooter}>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!isValid || !canSubmit}
                    onClick={() => handleSubmit.current && handleSubmit.current()}
                >
                    <Locale path="common.update" />
                </Button>
            </PortletFooter>
            {isLoading && (
                <div className={classes.loadingWrapper}>
                    <CircularProgress />
                </div>
            )}
        </Portlet>
    );
};

AccountDetails.propTypes = {
    className: PropTypes.string,
    details: PropTypes.object,
    isLoading: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    userId: PropTypes.string,
    roles: PropTypes.string,
    updateUserDetails: PropTypes.func,
};

export default AccountDetails;
