import {
    FilledInput,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    OutlinedInput,
} from '@mui/material';
import React, { useMemo, useRef } from 'react';

import PropTypes from 'prop-types';
import { shouldRetranslate } from '../../state/locale/Locale.selectors';
import startCase from 'lodash/startCase';
import { translate } from '../../helpers/i18n';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { withFormikField } from './FormikFields';

/**
 * @typedef {Object} Base
 * @property {Record<string,any>=} translationOptions The `options` object provided to all internal <Locale/> translations for the TextField
 *
 * @typedef {import('@mui/material').TextFieldProps & Base} TextFieldProps
 */

/**
 * @type {React.ForwardRefExoticComponent<TextFieldProps>}
 */
export const TextField = React.forwardRef((props, ref) => {
    const {
        'aria-describedby': ariaDescribedBy,
        children,
        defaultValue,
        error,
        FormControlProps,
        FormHelperTextProps,
        fullWidth,
        helperText,
        id: providedId,
        InputLabelProps,
        InputProps,
        inputProps: { placeholder: providedPlaceholder, ...inputProps } = {},
        name,
        label = name ? startCase(`${name}`) : undefined,
        placeholder,
        required,
        translationOptions,
        value,
        variant,
        ...remain
    } = props;

    const id = useRef(providedId || uuid());
    const errorId = useRef(uuid());
    const updateTranslation = useSelector(shouldRetranslate);
    const translatedPlaceholder = useMemo(
        () => placeholder && translate(placeholder, translationOptions),
        [placeholder, updateTranslation, translationOptions]
    );

    const translatedLabel = useMemo(
        () =>
            !label || React.isValidElement(label) ? label : translate(label, translationOptions),
        [label, updateTranslation, translationOptions]
    );

    const inputVariant = variant || 'outlined';
    const InputComponent = useMemo(() => {
        switch (inputVariant) {
            case 'filled':
                return FilledInput;
            case 'standard':
                return Input;
            default:
                return OutlinedInput;
        }
    }, [inputVariant]);

    return (
        <FormControl
            fullWidth={!!fullWidth}
            error={error}
            required={!!required}
            {...FormControlProps}
        >
            <InputLabel
                shrink={placeholder || value ? true : (!label && providedPlaceholder) || undefined}
                htmlFor={id.current}
                {...InputLabelProps}
            >
                {translatedLabel}
            </InputLabel>
            <InputComponent
                id={id.current}
                inputRef={ref}
                name={name}
                label={translatedLabel}
                aria-describedby={
                    !helperText // If there is an error, the aria-describedby needs to reference the error message, and that's handled by MUI internally
                        ? ariaDescribedBy
                        : undefined
                }
                margin="dense"
                inputProps={inputProps}
                {...remain}
                notched={
                    (InputComponent === OutlinedInput && !!((placeholder || value) && label)) ||
                    undefined
                }
                {...InputProps}
                placeholder={translatedPlaceholder || providedPlaceholder}
                defaultValue={value != null ? undefined : defaultValue}
                value={value ?? ''}
            >
                {children}
            </InputComponent>
            <FormHelperText
                {...FormHelperTextProps}
                id={errorId.current}
                sx={{ whiteSpace: 'pre-line', ...FormHelperTextProps?.sx }}
            >
                {helperText}
            </FormHelperText>
        </FormControl>
    );
});

TextField.propTypes = {
    'aria-describedby': PropTypes.string,
    children: PropTypes.node,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    FormControlProps: PropTypes.object,
    FormHelperTextProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.any,
    id: PropTypes.string,
    InputLabelProps: PropTypes.object,
    InputProps: PropTypes.object,
    inputProps: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    name: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    translationOptions: PropTypes.object,
    value: PropTypes.any,
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

export default TextField;

export const FormikTextField = withFormikField(TextField);
FormikTextField.displayName = 'FormikTextField';
