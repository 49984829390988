import {
    fetchUserDetails as fetchUserDetailsService,
    updateUserDetails as updateUserDetailsService,
} from '/b2b/users/services';

import AuthUserActions from '/b2b/authentication/state/user/AuthUser.actions';
import schema from '/b2b/users/state/db/User.schema';

export const fetchUserDetails = params => dispatch => {
    const { userId } = params;
    const meta = { schema, id: userId };
    dispatch(AuthUserActions.fetchDetailsBegin(meta, params));

    return fetchUserDetailsService()
        .then(details => {
            dispatch(AuthUserActions.fetchDetailsSuccess(meta, params, details));
            return details;
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthUserActions.fetchDetailsFailure(meta, params, message));
            throw message;
        });
};

export const updateUserDetails = params => dispatch => {
    const { userId } = params;
    const meta = { schema, id: userId };
    dispatch(AuthUserActions.updateBegin(meta, params));

    return updateUserDetailsService(params)
        .then(details => {
            dispatch(
                AuthUserActions.updateSuccess(meta, params, {
                    id: userId,
                    ...details,
                })
            );
            return details;
        })
        .catch(error => {
            const { message } = error;
            dispatch(AuthUserActions.updateFailure(meta, params, message));
            throw message;
        });
};
