import {
    types as ActionTypes,
    asyncEvents as AsyncEvents,
} from './DataStoreRequestActions.actions';

import { combineReducers } from 'redux';
import { crudPagingReducers } from '../../../common/helpers/ReduxHelpers';
import schema from '/b2b/dataStores/state/db/requestAction/DataStoreRequestAction.schema';

export const initialState = {
    offset: 0,
    limit: 25,
    query: '',
    results: [],
    resultCount: 0,
    error: null,
    loading: false,
};

export default combineReducers({
    ...crudPagingReducers(
        initialState,
        Object.keys(AsyncEvents).map(key => ActionTypes[key]),
        schema
    ),
});
