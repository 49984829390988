import { Breadcrumbs, Typography } from '@mui/material';
import React, { useEffect } from 'react';

import AccountDetails from './components/AccountDetails';
// Custom components
import AccountSecurity from './components/AccountSecurity';
import AuthenticatedPaths from '../../../routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import DynamicTabbed from '../../../common/components/DynamicTabbed';
import FallbackAvatar from '../../../common/components/FallbackAvatar';
import Locale from '../../../common/components/Locale';
import Notifications from './components/Notifications';
import Password from './components/Password';
import PropTypes from 'prop-types';
import TabLink from '../../../common/components/TabLink';
import makeStyles from '@mui/styles/makeStyles';
import styles from './Account.styles';

const useStyles = makeStyles(styles);

const Tabs = {
    DETAILS: 'details',
    NOTIFICATIONS: 'notifications',
    SECURITY: 'security',
    PASSWORD: 'password',
};

const Account = props => {
    const {
        hasPermissionToUpdateCognitoMFA,
        hasPermissionToUpdateCognitoPassword,
        userId,
        error,
        tab = Tabs.DETAILS,
        details,
        fetchUserDetails = () => {},
    } = props;
    const classes = useStyles(props);
    const { name, photo, initials } = details;

    useEffect(() => {
        userId && fetchUserDetails(userId);
    }, [userId]);

    const tabLabels = {
        [Tabs.DETAILS]: 'common.details',
        [Tabs.NOTIFICATIONS]: 'common.notifications',
        [Tabs.SECURITY]: 'security.title',
        [Tabs.PASSWORD]: 'password.update',
    };

    return (
        <div className={classes.root}>
            <Breadcrumbs maxItems={3} aria-label="breadcrumb" className={classes.breadcrumb}>
                <Typography>
                    <Locale path="common.account" />
                </Typography>
                <Typography>
                    <Locale path={tabLabels[tab]} />
                </Typography>
            </Breadcrumbs>
            <div className={classes.info}>
                <FallbackAvatar alt={name} className={classes.avatar} src={photo}>
                    {initials}
                </FallbackAvatar>
                <div className={classes.title}>
                    <Typography variant="h2">{name}</Typography>
                    <Typography variant="h4">{userId}</Typography>
                </div>
            </div>
            {error && (
                <Typography variant="body2" gutterBottom className={classes.error}>
                    {error}
                </Typography>
            )}
            <DynamicTabbed tab={tab}>
                <AccountDetails
                    tab={Tabs.DETAILS}
                    label={tabLabels[Tabs.DETAILS]}
                    TabProps={{
                        component: TabLink,
                        to: `${AuthenticatedPaths.ACCOUNT}/details`,
                    }}
                />
                <Notifications
                    tab={Tabs.NOTIFICATIONS}
                    label={tabLabels[Tabs.NOTIFICATIONS]}
                    TabProps={{
                        component: TabLink,
                        to: `${AuthenticatedPaths.ACCOUNT}/notifications`,
                    }}
                />
                {hasPermissionToUpdateCognitoMFA && (
                    <AccountSecurity
                        tab={Tabs.SECURITY}
                        label={tabLabels[Tabs.SECURITY]}
                        TabProps={{
                            component: TabLink,
                            to: `${AuthenticatedPaths.ACCOUNT}/security`,
                        }}
                    />
                )}
                {hasPermissionToUpdateCognitoPassword && (
                    <Password
                        tab={Tabs.PASSWORD}
                        label={tabLabels[Tabs.PASSWORD]}
                        TabProps={{
                            component: TabLink,
                            to: `${AuthenticatedPaths.ACCOUNT}/password`,
                        }}
                    />
                )}
            </DynamicTabbed>
        </div>
    );
};

Account.propTypes = {
    details: PropTypes.object,
    error: PropTypes.string,
    hasPermissionToUpdateCognitoMFA: PropTypes.bool,
    hasPermissionToUpdateCognitoPassword: PropTypes.bool,
    onInitialLoad: PropTypes.func,
    fetchUserDetails: PropTypes.func,
    userId: PropTypes.string,
    tab: PropTypes.oneOf(Object.values(Tabs)),
};

export default Account;
