import { Grid, Divider as MuiDivider, Typography } from '@mui/material';
import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import styles from './Divider.styles';

const useStyles = makeStyles(styles);

const Divider = props => {
    const { label } = props;
    const classes = useStyles(props);
    return (
        <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
            className={classes.root}
        >
            <Grid item xs>
                <MuiDivider />
            </Grid>
            {label && (
                <Fragment>
                    <Grid item className={classes.label}>
                        <Typography component="div" variant="body1">
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <MuiDivider />
                    </Grid>
                </Fragment>
            )}
        </Grid>
    );
};

Divider.propTypes = {
    label: PropTypes.any,
};

export default Divider;
