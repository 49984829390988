import { types as ActionTypes, asyncEvents as AsyncEvents } from './ORMDetails.actions';
import {
    CRUDDetailsReducers,
    genericReducer,
    loadingReducer,
    passthroughReducer,
} from '../../ReduxHelpers';
import { canRefresh, shouldShowFieldError } from './ORMDetails.selectors';

import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import { combineReducers } from 'redux';
import { flatten } from '../../flat';
import isEqual from 'lodash/isEqual';
import reduceReducers from 'reduce-reducers';
import schema from '../../../state/db/customer/Customer.schema';

export const initialState = {
    changes: {},
    continuousValidation: false,
    details: {},
    error: null,
    fieldErrors: {},
    id: '',
    loading: false,
    makeDetailsFlat: true,
    ORMSchema: null,
    submitAttempted: false,
    submitting: false,
    validationScheme: {},
    values: {},
};

export const initState = props => {
    const {
        id = '',
        makeDetailsFlat = true,
        continuousValidation,
        details: providedDetails,
        ORMSchema,
        validationScheme,
    } = props;
    const details = makeDetailsFlat
        ? flatten(providedDetails, {
              safe: true,
          })
        : providedDetails;
    return {
        ...initialState,
        continuousValidation,
        details,
        id,
        makeDetailsFlat: !!makeDetailsFlat,
        ORMSchema,
        validationScheme,
        values: { ...details },
    };
};

export const refresh = (state, refreshedDetails) => {
    const { fieldErrors, changes, details, makeDetailsFlat } = state;
    if (!canRefresh(state)) {
        return state;
    }
    const newDetails = {
        ...details,
        ...(makeDetailsFlat
            ? flatten(refreshedDetails, {
                  safe: true,
              })
            : refreshedDetails),
    };
    if (!isEqual(newDetails, details)) {
        const [newFieldErrors, newChanges] = Object.keys(refreshedDetails).reduce(
            ([updatedFieldErrors, updatedChanges], field) => {
                const returnValue = [];
                if (fieldErrors[field]) {
                    returnValue.push({ ...updatedFieldErrors });
                    delete returnValue[0][field];
                } else {
                    returnValue.push(updatedFieldErrors);
                }
                if (changes[field]) {
                    returnValue.push({ ...updatedChanges });
                    delete returnValue[1][field];
                } else {
                    returnValue.push(updatedChanges);
                }
                return returnValue;
            },
            [fieldErrors, changes]
        );
        return {
            ...state,
            changes: isEqual(newChanges, changes) ? changes : newChanges,
            details: newDetails,
            fieldErrors: isEqual(newFieldErrors, fieldErrors) ? fieldErrors : newFieldErrors,
            values: {
                ...newDetails,
                ...newChanges,
            },
        };
    }
    return state;
};

export default reduceReducers(
    initialState,
    combineReducers({
        changes: passthroughReducer(initialState.changes),
        continuousValidation: passthroughReducer(initialState.continuousValidation),
        details: passthroughReducer(initialState.details),
        fieldErrors: passthroughReducer(initialState.fieldErrors),
        makeDetailsFlat: passthroughReducer(initialState.makeDetailsFlat),
        ORMSchema: passthroughReducer(initialState.ORMSchema),
        submitAttempted: loadingReducer(initialState.submitAttempted, [
            ActionTypes.fetchDetailsSuccess,
            ActionTypes.updateBegin,
            ActionTypes.updateSuccess,
        ]),
        submitting: loadingReducer(initialState.submitting, [
            ActionTypes.updateBegin,
            ActionTypes.updateFailure,
            ActionTypes.updateSuccess,
        ]),
        validationScheme: passthroughReducer(initialState.validationScheme),
        values: passthroughReducer(initialState.values),
        ...CRUDDetailsReducers(
            initialState,
            Object.keys(AsyncEvents).map(key => ActionTypes[key]),
            schema
        ),
    }),
    genericReducer(initialState, {
        [AuthActionTypes.logout]: () => initialState,
        [ActionTypes.refresh]: refresh,
        [ActionTypes.change]: (state, { field, value, error }) => {
            const { fieldErrors, changes, details, values } = state;
            const fieldError = shouldShowFieldError(state, field) ? error : undefined;
            const newFieldErrors =
                fieldError || (!fieldError && fieldErrors[field])
                    ? { ...fieldErrors, [field]: fieldError }
                    : fieldErrors;
            !fieldError && delete newFieldErrors[field];
            const newChanges =
                // value matches original
                isEqual(value, details[field]) ||
                // value is different than the current change
                !isEqual(value, changes[field])
                    ? { ...changes, [field]: value }
                    : changes;
            isEqual(value, details[field]) && delete newChanges[field];
            return {
                ...state,
                changes: newChanges,
                fieldErrors: newFieldErrors,
                values:
                    newChanges === changes
                        ? values
                        : {
                              ...details,
                              ...newChanges,
                          },
            };
        },
        [ActionTypes.deleteSuccess]: (state, { params = {} } = {}) => {
            const { values = {} } = state;
            return {
                ...state,
                values: {
                    ...values,
                    ...params,
                },
            };
        },
    })
);
