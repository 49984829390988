import {
    types as ActionTypes,
    filterEvents as FilterEvents,
    pagingEvents as PagingEvents,
} from './Rules.actions';
import {
    collectionReducer,
    crudPagingReducers,
    genericReducer,
} from '../../../common/helpers/ReduxHelpers';

import { types as DetailsActionTypes } from '../ConfigDetails.actions';
import { EVENTS } from '../../constants';
import { types as TattleActionTypes } from '../tattles/Tattles.actions';
import analytics from '../../../common/analytics';
import castArray from 'lodash/castArray';
import { combineReducers } from 'redux';
import { filterUnique } from '/b2b/common/helpers/Array';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import reduceReducers from 'reduce-reducers';
import schema from '../db/rule/Rule.schema';

const omitNil = obj => omitBy(obj, val => isNil(val));

export const initialState = {
    changes: {},
    deleting: [],
    error: null,
    errors: [],
    filters: {},
    limit: 25,
    loading: false,
    offset: 0,
    pageNumber: 1,
    query: '',
    resultCount: 0,
    results: [],
    saving: [],
    sort: '',
    sortDirection: 'none',
};

const previousState = { ...initialState };

export default reduceReducers(
    initialState,
    combineReducers({
        ...crudPagingReducers(
            initialState,
            Object.keys(PagingEvents).map(key => ActionTypes[key]),
            schema
        ),
        changes: genericReducer(initialState.changes, {
            [DetailsActionTypes.classificationViewChange]: () => initialState.changes,
            [ActionTypes.updateSuccess]: (state, { params: { ruleId, ruleIds } = {} }) => {
                if (ruleId) {
                    return omit(state, ruleId);
                } else if (ruleIds) {
                    return Object.keys(state).reduce((obj, key) => {
                        if (!ruleIds.map(ruleId => ruleId.toString()).includes(key)) {
                            obj[`${key}`] = state[`${key}`];
                        }
                        return obj;
                    }, {});
                }
                return initialState.changes;
            },
            [ActionTypes.deleteSuccess]: (state, { params: { ruleId, ruleIds } = {} }) => {
                if (ruleId) {
                    return omit(state, ruleId);
                } else if (ruleIds) {
                    return Object.keys(state).reduce((obj, key) => {
                        if (!ruleIds.map(ruleId => ruleId.toString()).includes(key)) {
                            obj[`${key}`] = state[`${key}`];
                        }
                        return obj;
                    }, {});
                }
                return initialState.changes;
            },
        }),
        filters: collectionReducer(
            initialState.filters,
            Object.keys(FilterEvents).map(key => ActionTypes[key])
        ),
        deleting: genericReducer(initialState.deleting, {
            [ActionTypes.createBegin]: (
                state,
                { params: { classification, rules, storeKey } = {} }
            ) => {
                if (rules) {
                    return [
                        ...state,
                        ...rules
                            .filter(
                                rule =>
                                    rule.classification === 'HIDDEN' &&
                                    rule.storeKey /* only rules with storeKeys are deleting */
                            )
                            .map(rule => rule.storeKey),
                    ];
                } else if (storeKey && classification === 'HIDDEN') {
                    return [...state, storeKey];
                }
                return state;
            },
            [ActionTypes.createFailure]: (state, { params: { rules, storeKey } = {} }) => {
                if (rules) {
                    const ruleKeys = rules
                        .filter(
                            rule =>
                                rule.classification === 'HIDDEN' &&
                                rule.storeKey /* only rules with storeKeys are deleting */
                        )
                        .map(rule => rule.storeKey);
                    return state.filter(savingId => !ruleKeys.includes(savingId));
                }
                return state.filter(savingId => savingId !== storeKey);
            },
            [ActionTypes.createSuccess]: (state, { params: { rules, storeKey } = {} }) => {
                if (rules) {
                    const ruleKeys = rules
                        .filter(
                            rule =>
                                rule.classification === 'HIDDEN' &&
                                rule.storeKey /* only rules with storeKeys are deleting */
                        )
                        .map(rule => rule.storeKey);
                    return state.filter(savingId => !ruleKeys.includes(savingId));
                }
                return state.filter(savingId => savingId !== storeKey);
            },
            [ActionTypes.deleteBegin]: (
                state,
                { params: { id, ruleId = id, ids, ruleIds = ids } = {} }
            ) => {
                if (ruleIds) {
                    return [...state, ...ruleIds];
                }
                return [...state, ruleId];
            },
            [ActionTypes.deleteFailure]: (
                state,
                { params: { id, ruleId = id, ids, ruleIds = ids } = {} }
            ) => {
                if (ruleIds) {
                    return state.filter(deleteId => !ruleIds.includes(deleteId));
                }
                return state.filter(deleteId => deleteId !== ruleId);
            },
            [ActionTypes.deleteSuccess]: (
                state,
                { params: { id, ruleId = id, ids, ruleIds = ids } = {} }
            ) => {
                if (ruleIds) {
                    return state.filter(deleteId => !ruleIds.includes(deleteId));
                }
                return state.filter(deleteId => deleteId !== ruleId);
            },
            [ActionTypes.fetchResultsSuccess]: () => initialState.deleting,
            [TattleActionTypes.fetchResultsSuccess]: () => initialState.deleting,
        }),
        saving: genericReducer(initialState.saving, {
            [ActionTypes.createBegin]: (
                state,
                { params: { classification, rules, storeKey } = {} }
            ) => {
                if (rules) {
                    return [
                        ...state,
                        ...rules
                            .filter(
                                rule =>
                                    rule.classification !== 'HIDDEN' &&
                                    rule.storeKey /* only rules with storeKeys are updating */
                            )
                            .map(rule => rule.storeKey),
                    ];
                } else if (storeKey && classification !== 'HIDDEN') {
                    return [...state, storeKey];
                }
                return state;
            },
            [ActionTypes.createFailure]: (state, { params: { rules, storeKey } = {} }) => {
                if (rules) {
                    const ruleKeys = rules
                        .filter(
                            rule =>
                                rule.classification !== 'HIDDEN' &&
                                rule.storeKey /* only rules with storeKeys are updating */
                        )
                        .map(rule => rule.storeKey);
                    return state.filter(savingId => !ruleKeys.includes(savingId));
                }
                return state.filter(savingId => savingId !== storeKey);
            },

            [ActionTypes.createSuccess]: (state, { params: { rules, storeKey } = {} }) => {
                if (rules) {
                    const ruleKeys = rules
                        .filter(
                            rule =>
                                rule.classification !== 'HIDDEN' &&
                                rule.storeKey /* only rules with storeKeys are updating */
                        )
                        .map(rule => rule.storeKey);
                    return state.filter(savingId => !ruleKeys.includes(savingId));
                }
                return state.filter(savingId => savingId !== storeKey);
            },
            [ActionTypes.updateBegin]: (
                state,
                { params: { id, ruleId = id, ids, ruleIds = ids } = {} }
            ) => {
                if (ruleIds) {
                    return [...state, ...ruleIds];
                }
                return [...state, ruleId];
            },
            [ActionTypes.updateFailure]: (
                state,
                { params: { id, ruleId = id, ids, ruleIds = ids } = {} }
            ) => {
                if (ruleIds) {
                    return state.filter(savingId => !ruleIds.includes(savingId));
                }
                return state.filter(savingId => savingId !== ruleId);
            },
            [ActionTypes.updateSuccess]: (
                state,
                { params: { id, ruleId = id, ids, ruleIds = ids } = {} }
            ) => {
                if (ruleIds) {
                    return state.filter(savingId => !ruleIds.includes(savingId));
                }
                return state.filter(savingId => savingId !== ruleId);
            },
            [ActionTypes.fetchResultsSuccess]: () => initialState.saving,
            [TattleActionTypes.fetchResultsSuccess]: () => initialState.saving,
        }),
        errors: genericReducer(initialState.errors, {
            [ActionTypes.setError]: (state, { id }) => [...state, id].filter(filterUnique),
            [ActionTypes.removeError]: (state, { id }) => state.filter(errorId => errorId !== id),
        }),
    }),
    genericReducer(initialState, {
        [ActionTypes.fetchResultsBegin]: (state, { params: { offset } }) => {
            const newState = {
                ...state,
                loading: state.results.length === 0 || previousState.offset !== offset,
            };

            previousState.offset = offset;

            return newState;
        },
        [ActionTypes.fieldChange]: (state, { id, field, value }) => {
            const { changes, errors } = state;
            if (isNil(value)) {
                // Remove the field change
                if (get(changes, [`${id}`, `${field}`]) === undefined) {
                    // There was no change to undo
                    return state;
                }
                const newState = { ...changes };
                const newRule = { ...changes[`${id}`] };
                delete newState[`${id}`];
                delete newRule[`${field}`];
                switch (field) {
                    case 'vendorName': {
                        delete newRule.vendorId;
                        break;
                    }
                }
                if (newRule.errors && newRule.errors[field]) {
                    delete newRule.errors[field];
                }
                if (Object.keys(newRule).length > 0) {
                    newState[id] = newRule;
                }
                return {
                    ...state,
                    changes: newState,
                    errors: Object.keys(newRule.errors || {}).length
                        ? errors
                        : errors.filter(errorId => errorId !== id),
                };
            }
            const newChanges = { ...changes[id] };
            switch (field) {
                case 'vendorName': {
                    newChanges.vendorName =
                        typeof value === 'string' ? value : value?.vendorName || value?.label || '';
                    newChanges.vendorId = value?.vendorId || value?.value || null;
                    break;
                }
                case 'classification': {
                    analytics.track(EVENTS.CATEGORIZE_RULE);
                    newChanges.classification = value;
                    break;
                }

                default: {
                    newChanges[field] = value;
                    break;
                }
            }
            if (newChanges.errors && newChanges.errors[field]) {
                delete newChanges.errors[field];
            }
            return {
                ...state,
                changes: {
                    ...changes,
                    [id]: newChanges,
                },
                errors: Object.keys(newChanges.errors || {}).length
                    ? errors
                    : errors.filter(errorId => errorId !== id),
            };
        },
        [ActionTypes.validate]: (state, { idOrIds, errors }) => {
            if (Array.isArray(idOrIds)) {
                // lump all errors into a single commit
                let hasNewErrors = false;
                const updates = idOrIds.reduce(
                    (allUpdates, id) => {
                        const err = errors[id];
                        if (err && Object.keys(err).length) {
                            hasNewErrors = true;
                            allUpdates.errors.push(id);
                            allUpdates.changes[id] = {
                                ...state.changes[`${id}`],
                                errors: omitNil({
                                    classification: err.classification,
                                    expiry: err.expiry,
                                    rule: err.rule,
                                    vendorName: err.vendorName,
                                }),
                            };
                        } else if (!err && allUpdates.errors.includes(id)) {
                            hasNewErrors = true;
                            allUpdates.errors = allUpdates.errors.filter(errorId => errorId !== id);
                            delete allUpdates.changes[id].errors;
                        }
                        return allUpdates;
                    },
                    { changes: { ...state.changes }, errors: [...new Set([...state.errors])] }
                );
                return hasNewErrors ? updates : state;
            }
            // Singular ids only beyond this point
            const id = idOrIds;
            if (errors && Object.keys(errors).length) {
                return {
                    ...state,
                    errors: [...new Set([...state.errors, id])],
                    changes: {
                        ...state.changes,
                        [id]: {
                            ...state.changes[`${id}`],
                            errors: omitNil({
                                expiry: errors.expiry && castArray(errors.expiry)[0],
                                rule: errors.rule && castArray(errors.rule)[0],
                                vendorName: errors.vendorName && castArray(errors.vendorName)[0],
                            }),
                        },
                    },
                };
            }

            return {
                ...state,
                errors: state.errors.filter(errorId => errorId !== id),
                changes: {
                    ...state.changes,
                    [id]: omit(state.changes[`${id}`], 'errors'),
                },
            };
        },
    })
);
