import {
    CategoryScale,
    Chart,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { green, orange, red } from './common/colors';

import { parseColor } from './common/helpers/Color';

Chart.register(
    CategoryScale,
    Filler,
    LineElement,
    PointElement,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    TreemapController,
    TreemapElement,
    {
        id: 'bg-gradient',
        beforeDraw(chart) {
            if (chart.config.options.backgroundColor === 'scoreGradient') {
                const ctx = chart.ctx;
                const canvas = chart.canvas;
                const chartArea = chart.chartArea;

                // Chart background
                var gradientBack = canvas
                    .getContext('2d')
                    .createLinearGradient(0, chartArea.bottom - chartArea.top, 0, 0);
                const low = `rgba(${parseColor(red).join(',')}, 0.15)`;
                const mid = `rgba(${parseColor(orange).join(',')}, 0.15)`;
                const high = `rgba(${parseColor(green).join(',')}, 0.15)`;
                gradientBack.addColorStop(0, low);
                gradientBack.addColorStop(0.15, low);
                gradientBack.addColorStop(0.25, mid);
                gradientBack.addColorStop(0.7, mid);
                gradientBack.addColorStop(0.8, high);
                gradientBack.addColorStop(1, high);

                ctx.fillStyle = gradientBack;
                ctx.fillRect(
                    chartArea.left,
                    chartArea.bottom,
                    chartArea.right - chartArea.left,
                    chartArea.top - chartArea.bottom
                );
            }
        },
    },
    {
        id: 'osano',
        afterDraw(chart) {
            if (chart.config.data.datasets.length === 0) {
                // No data is present
                var ctx = chart.ctx;
                var width = chart.width;
                var height = chart.height;
                chart.clear();

                ctx.save();
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.font = "16px normal 'Helvetica Nueue'";
                ctx.fillText('No data to display', width / 2, height / 2);
                ctx.restore();
            }
        },
    }
);
