import { defaultIdAttribute, defaultProcessStrategy } from '../../../common/helpers/Normalizr';

import { schema } from 'normalizr';
import uniq from 'lodash/uniq';

export const entityName = 'products';
export const idAttribute = 'productId';

export const mergeStrategy = (entityA = {}, { sectionScores, ...entityB } = {}) => {
    const newEntity = {
        ...entityA,
        ...entityB,
        subprocessors: uniq([...(entityA.subprocessors || []), ...(entityB.subprocessors || [])]),
    };
    // Only override sectionScores if it's being provided by the new entity
    if (sectionScores) {
        newEntity.sectionScores = sectionScores;
    }
    return newEntity;
};
export const processStrategy = defaultProcessStrategy(idAttribute, details => {
    return details;
    /*
    const { image, website } = details;
    try {
        const url = new URL(website);
        return {
            ...details,
            image: image || `https://www.privacymonitor.com/assets/logos/${url.hostname}.png`,
        };
    } catch (error) {
        return details;
    }
    */
});

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
