export default theme => ({
    root: {},
    outlined: {
        '& input': {
            marginTop: 0,
            paddingTop: theme.spacing(1),
            marginBottom: theme.spacing(0.5),
            paddingBottom: theme.spacing(1.5),
        },
    },
    inputRoot: {
        '&$outlined': {
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(0.5),
        },
    },
    input: {},
    label: {},
    chipContainer: {},
    chip: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    helperText: {
        margin: theme.spacing(1, 0, 0),
    },
});
