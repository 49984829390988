import { denormalize } from 'normalizr';
import get from 'lodash/get';
import { getStore as getDB } from '../../../common/state/db/DB.selectors';
import schema from './Trusthub.schema';

export const DOMAIN = `db.${schema.key}`;

export const getStore = state => get(state, DOMAIN) || {};

export const getEntityById = (state, id) => denormalize(id, schema, getDB(state)) || {};
export const getEntitiesForIds = (state, ids) => denormalize(ids, [schema], getDB(state));
export const getSubdomain = (state, id) => getEntityById(state, id).subdomain;

export const getFieldForEntityById = (state, { id, field }) =>
    get(getStore(state), [id, field]) || null;

export * as page from './TrusthubPage.selectors';
