import fromState from '../state/selectors';

// exports so flag constants are available to import
export * from '../components/FeatureFlag';

/**
 * Is the given flag enabled.
 * @param {Object} state The full redux state
 * @param {String} flagName The full name for the feature flag.
 * @returns {Boolean} If the flag is enabled.
 */
export const isFeatureEnabled = (state, flagName) => {
    const flags = fromState.Auth.user.getFeatureFlags(state);
    return flags.some(f => f.name === flagName && f.enabled);
};

/**
 * Return the variant associated with the given feature flag.
 * @param {Object} state The full redux state
 * @param {String} flagName The full name for the feature flag.
 * @returns {FeatureFlagVariant} The variant attached to this feature flag.
 */
export const getFeatureVariant = (state, flagName) => {
    const flags = fromState.Auth.user.getFeatureFlags(state);
    for (const toggle of flags) {
        if (toggle.name === flagName && toggle.variant?.enabled) {
            return toggle.variant;
        }
    }
    return { name: 'disabled', enabled: false };
};
