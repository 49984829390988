import {
    types as ActionTypes,
    asyncEvents as AsyncEvents,
    events as Events,
} from './AuthCustomerDSARForms.actions';
import {
    CRUDPagingQueryReducer,
    collectionReducer,
    crudPagingReducers,
    crudStatusByIdReducer,
    errorReducer,
    genericReducer,
    loadingReducer,
} from '/b2b/common/helpers/ReduxHelpers';

import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { types as NavActionTypes } from '/b2b/common/state/nav/Nav.actions';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import schema from '/b2b/dsarForms/state/db/Form.schema';

export const initialState = {
    offset: 0,
    limit: 25,
    query: '',
    results: [],
    resultCount: 0,
    error: null,
    loading: false,
    filters: {},
    sort: '',
    sortDirection: 'none',
    creationError: null,
    creating: false,
    deleteStatus: {},
    planData: {},
};

export default reduceReducers(
    initialState,
    combineReducers({
        filters: collectionReducer(
            initialState.filters,
            Object.keys(Events).map(key => ActionTypes[key])
        ),
        ...crudPagingReducers(
            initialState,
            Object.keys(AsyncEvents).map(key => ActionTypes[key]),
            schema
        ),
        creationError: errorReducer(
            initialState.creationError,
            [ActionTypes.createBegin, ActionTypes.createSuccess, ActionTypes.createFailure],
            schema
        ),
        creating: loadingReducer(
            initialState.loading,
            [ActionTypes.createBegin, ActionTypes.createSuccess, ActionTypes.createFailure],
            schema
        ),
        deleteStatus: crudStatusByIdReducer(
            initialState.deleteStatus,
            [ActionTypes.deleteBegin, ActionTypes.deleteSuccess, ActionTypes.deleteFailure],
            schema
        ),
    }),
    genericReducer(initialState, {
        [NavActionTypes.routeChanged]: CRUDPagingQueryReducer(
            initialState,
            AuthenticatedPaths.DSAR
        ),
    })
);
