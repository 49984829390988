import { Badge } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import styles from './DateRangePickerDay.styles';

const useStyles = makeStyles(styles);

/**
    @typedef DateRangePickerDayProps
    @type {Object}
    @property {moment.Moment} date The date that is being rendered
    @property {number} maxDate The current value (in milliseconds) for the maximum date in the range
    @property {number} minDate The current value (in milliseconds) for the minimum date in the range
    @property {boolean} dayInCurrentMonth Indicates if the date being rendered is in the current month being viewed in the picker
    @property {function} onClick The callback method for the picker
*/

/**
 * DateRangePickerDay Component
 * @param {DateRangePickerDayProps} props
 * @returns
 */
const DateRangePickerDay = props => {
    const {
        // allowSameDateSelection,
        // autoFocus,
        day,
        // disabled,
        disableHighlightToday,
        // key,
        isPreviewing,
        isStartOfPreviewing,
        isEndOfPreviewing,
        isHighlighting,
        isStartOfHighlighting,
        isEndOfHighlighting,
        outsideCurrentMonth,
        // ref,
        selected,
        // eslint-disable-next-line no-unused-vars
        showDaysOutsideCurrentMonth,
        today,
        ...remain
    } = props;
    const classes = useStyles(props);

    const wrapperClassName = classNames({
        [classes.dayWrapper]: true,
        [classes.selected]: selected && (isStartOfHighlighting || isEndOfHighlighting),
        [classes.highlight]: isHighlighting,
        [classes.preview]: isPreviewing,
        [classes.startPreview]: isStartOfPreviewing,
        [classes.endPreview]: isEndOfPreviewing,
        [classes.startHighlight]: isStartOfHighlighting,
        [classes.endHighlight]: isEndOfHighlighting,
        [classes.outsideCurrentMonth]: outsideCurrentMonth,
        [classes.highlightOutsideCurrentMonth]: outsideCurrentMonth && isHighlighting,
    });

    const dayClassName = classNames(classes.day, {
        [classes.day]: true,
        [classes.selectedDay]: selected && (isStartOfHighlighting || isEndOfHighlighting),
        [classes.highlightDay]: isHighlighting,
        [classes.previewDay]: isPreviewing,
        [classes.startHighlightDay]: isStartOfHighlighting,
        [classes.endHighlightDay]: isEndOfHighlighting,
        [classes.startPreviewDay]: isStartOfPreviewing,
        [classes.endPreviewDay]: isEndOfPreviewing,
        [classes.outsideCurrentMonthDay]: outsideCurrentMonth,
        [classes.highlightOutsideCurrentMonthDay]: outsideCurrentMonth && isHighlighting,
    });

    return (
        <div className={wrapperClassName}>
            <Badge
                classes={{ root: classes.badge }}
                color="secondary"
                variant="dot"
                overlap="circular"
                invisible={!today || disableHighlightToday}
            >
                <PickersDay
                    className={dayClassName}
                    day={day}
                    disableHighlightToday={disableHighlightToday}
                    outsideCurrentMonth={outsideCurrentMonth}
                    selected={selected}
                    showDaysOutsideCurrentMonth={true}
                    {...remain}
                />
                {/*
                <IconButton
                    onClick={selectDate}
                    disabled={disabled}
                    className={dayClassName}
                    size="large"
                >
                    {day.format('D')}
                </IconButton>
                */}
            </Badge>
        </div>
    );
};

DateRangePickerDay.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    current: PropTypes.bool,
    day: PropTypes.object,
    disabled: PropTypes.bool,
    disableHighlightToday: PropTypes.bool,
    isEndOfHighlighting: PropTypes.bool,
    isEndOfPreviewing: PropTypes.bool,
    isHighlighting: PropTypes.bool,
    isPreviewing: PropTypes.bool,
    isStartOfHighlighting: PropTypes.bool,
    isStartOfPreviewing: PropTypes.bool,
    selected: PropTypes.bool,
    outsideCurrentMonth: PropTypes.bool,
    selectDate: PropTypes.func,
    showDaysOutsideCurrentMonth: PropTypes.bool,
    today: PropTypes.bool,
};

export default DateRangePickerDay;
