import {
    assignField,
    assignResults,
    formatFilterParams,
    formatPagingParams,
    getPaginatedResults,
    handleError,
    makeResultsFormatter,
} from '../common/services/helpers';
import {
    formatProduct,
    formatScoringResults,
    formatVendor,
    formatVendorResults,
    minScore,
} from './format';

import API from '@aws-amplify/api';
import { apiName } from '@osano-b2b';
import { formatOrganization } from '/b2b/organizations/format';
import { formatUser } from '/b2b/users/format';

/**
 * This is the placeholder entity description for a Vendor result
 * @typedef DomainEntity
 * @property {string} domain
 * @property {number} created
 */

/**
 * This is the placeholder entity description for a Vendor result
 * @typedef ProductEntity
 * @property {string} productId
 */

/**
 * This is the placeholder entity description for a Vendor result
 * @typedef VendorEntity
 * @property {string} vendorId
 * @property {number} score
 * @property {boolean} following
 */

/**
 * Fetches a paged list of vendors
 * @param {import('../common/services/helpers').PagedParams} params
 * @return {ReturnType<getPaginatedResults<VendorEntity>}
 */
export const fetchVendors = ({ customerId, ...params }) =>
    getPaginatedResults('/vendors', params, formatVendor).then(response => {
        if (customerId) {
            response.results.forEach(result => {
                result.score = result.score || minScore;
                result.following = result.following ? [customerId] : [];
            });
        }
        return response;
    });

/**
 * Fetches the vendor for the provided vendorId.
 *
 * @param {string|number} vendorId
 * @param {string} [customerId] The current customerId. (Optional)
 * @return {VendorEntity}
 */
export const fetchVendorById = (vendorId, customerId) => {
    const options = {};
    return API.get(apiName, `/vendor/${vendorId}`, options)
        .catch(handleError)
        .then(formatScoringResults('rating'))
        .then(formatVendor)
        .then(result => {
            if (customerId) {
                result.score = result.score || minScore;
                result.following = result.following ? [customerId] : [];
            }
            return result;
        });
};

/**
 * Gets the domains associated with the provided "vendorId"
 *
 * @param {string} vendorId
 * @param {import('../common/services/helpers').PagedParams} params
 * @return {ReturnType<getPaginatedResults<DomainEntity>}
 */
export const fetchVendorDomains = (vendorId, params) =>
    getPaginatedResults(`/vendor/${vendorId}/domains`, params);

/**
 * Gets the segmented scoring associated with the provided "vendorId"
 *
 * @param {string} vendorId The vendorId to fetch scoring for
 * @param {'day'|'week'|'month'|'year'} interval The interval to use for the scoring
 * @return {ReturnType<getPaginatedResults<DomainEntity>}
 */
export const fetchScoringForVendorById = (vendorId, interval) => {
    let pageSize = 365;
    switch (interval) {
        case 'week':
            pageSize = 52;
            break;
        case 'month':
            pageSize = 36;
            break;
        case 'year':
            pageSize = 5;
            break;
        default:
            interval = 'day';
            break;
    }
    const options = {
        queryStringParameters: {
            interval,
            pageSize,
        },
    };
    return API.get(apiName, `/vendor/${vendorId}/scoring`, options)
        .catch(handleError)
        .then(formatScoringResults('percent'));
};

/**
 * Fetches all subprocessors for a vendor
 * @param {string} vendorId
 * @return {ReturnType<getPaginatedResults<VendorEntity>}
 */
export const fetchSubprocessorsForVendorById = vendorId => {
    const options = {};
    return API.get(apiName, `/vendor/${vendorId}/subprocessors`, options)
        .catch(handleError)
        .then(results => ({
            data: results,
        }))
        .then(makeResultsFormatter(formatVendor));
};

/**
 * Fetches a paged list of products for a vendor
 * @param {string} vendorId
 * @param {import('../common/services/helpers').PagedParams} params
 * @return {ReturnType<getPaginatedResults<ProductEntity>}
 */
export const fetchProductsForVendorById = (vendorId, params) =>
    getPaginatedResults(`/vendor/${vendorId}/products`, params, formatProduct);

/**
 * Gets the users assigned to the provided "vendorId"
 *
 * @param {string} vendorId
 * @param {import('../common/services/helpers').PagedParams} params
 * @return {ReturnType<getPaginatedResults<import('/b2b/users/format').UserEntity>}
 */
export const fetchVendorUsers = (vendorId, params) =>
    getPaginatedResults(`/vendor/${vendorId}/users`, params, formatUser);

export const followVendor = vendorId => {
    const options = {
        body: {
            vendorId,
        },
    };
    return API.post(apiName, `/vendor/customer/follow`, options).catch(handleError);
};

export const unfollowVendor = vendorId => {
    const options = {};
    return API.del(apiName, `/vendor/customer/follow/${vendorId}`, options).catch(handleError);
};

/**
 * Assigns user(s) to the provided "vendorId"
 *
 * @param {string|string[]} vendorId The vendorId of the vendor to assign users to
 * @param {string[]} orgs An array of orgIds to assign to the vendor
 * @return {{ count: number, users: import('/b2b/users/format').UserEntity[] }} The number of users assigned and the users assigned. This does not return users that were already assigned.
 */
export const assignVendorToOrgs = (vendorId, orgs) => {
    const options = {
        body: {
            orgs,
            vendorId,
        },
    };
    return API.post(apiName, `/vendor/organization/follow`, options)
        .catch(handleError)
        .then(({ count = 0, orgs = [] } = {}) => {
            return { count, orgs: orgs.map(formatOrganization) };
        });
};

/**
 * Assigns user(s) to the provided "vendorId"
 *
 * @param {string|string[]} vendorId The vendorId of the vendor to assign users to
 * @param {string[]} users An array of email addresses for users to assign to the vendor
 * @return {{ count: number, users: import('/b2b/users/format').UserEntity[] }} The number of users assigned and the users assigned. This does not return users that were already assigned.
 */
export const assignVendorToUsers = (vendorId, users) => {
    const options = {
        body: {
            users,
            vendorId,
        },
    };
    return API.post(apiName, `/vendor/user/follow`, options)
        .catch(handleError)
        .then(({ count = 0, users = [] } = {}) => {
            return { count, users: users.map(formatUser) };
        });
};

/**
 * Unassigns user(s) from the provided "vendorId"
 *
 * @param {string} vendorId The vendorId of the vendor to unassign users from
 * @param {string[]} users An array of email addresses for users to unassign from the vendor
 * @return {{ count: number, users: import('/b2b/users/format').UserEntity[] }} The number of users unassigned and the users unassigned. This does not return users that were not previously assigned.
 */
export const unassignVendorFromUsers = (vendorId, users) => {
    const options = {
        body: {
            users,
        },
    };
    return API.del(apiName, `/vendor/user/follow/${vendorId}`, options)
        .catch(handleError)
        .then(({ count = 0, users = [] } = {}) => {
            return { count, users: users.map(formatUser) };
        });
};

/**
 * Fetches a paged list of vendors the current customer is following
 * @param {import('../common/services/helpers').PagedParams & { customerId?: string }} params
 * @return {ReturnType<getPaginatedResults<VendorEntity>}
 */
export const fetchFollowingVendors = ({ customerId, ...params }) =>
    getPaginatedResults(`/vendors/customer/follow`, params, formatVendor).then(response => {
        if (customerId) {
            return assignResults(assignField('following', [customerId]))(response);
        }
        return response;
    });

export const fetchDiscoveredVendors = ({ ...params }) => {
    const options = {
        queryStringParameters: {
            ...formatFilterParams(params),
            ...formatPagingParams(params),
        },
    };
    return API.get(apiName, `/vendors/customer/discovered`, options)
        .catch(handleError)
        .then(formatVendorResults);
};

/**
 * Fetches a total count of vendors followed by the customer
 * @return {number} The total number of vendors the customer is following
 */
export const fetchFollowingVendorsCount = async () => {
    // return API.get(apiName, `/vendors/customer/follow`)
    //     .catch(handleError)
    //     .then(({ count = 0 }) => count);
    const resp = await getPaginatedResults(`/vendors/customer/follow`);
    return resp.count || 0;
};

/**
 * Fetches all breaches for a vendor
 * @param {string} vendorId
 * @return {ReturnType<getPaginatedResults<VendorEntity>}
 */
export const fetchVendorBreaches = vendorId => {
    return API.get(apiName, `/vendor/${vendorId}/breaches`)
        .catch(handleError)
        .then(results => ({
            results,
        }));
};

/**
 * Fetches settings on a per customer per vendor basis
 * @param {string} vendorId
 * @return {{ items: { priority: boolean, reviewDate: string} }}}
 */
export const fetchVendorSettings = vendorId => {
    return API.get(apiName, `/vendor/${vendorId}/customer/settings`).catch(handleError);
};

/**
 * Creates settings on a per customer per vendor basis
 * @param {string} vendorId
 * @return {{ items: { priority: boolean, reviewDate: string} }}}
 */
export const addVendorSettings = (vendorId, priority = false, reviewDate = null) => {
    const options = {
        body: {
            priority,
            reviewDate,
        },
    };

    return API.post(apiName, `/vendor/${vendorId}/customer/settings`, options).catch(handleError);
};

/**
 * Modifies a setting on a per customer per vendor basis
 * @param {string} vendorId
 * @return {{ items: { priority: boolean, reviewDate: string} }}}
 */
export const modifyVendorSetting = (vendorId, settingName, settingValue) => {
    const options = {
        body: {
            settingName,
            settingValue,
        },
    };

    return API.patch(apiName, `/vendor/${vendorId}/customer/settings`, options).catch(handleError);
};

/**
 * Fetches a paged list of products
 * @param {import('../common/services/helpers').PagedParams} params
 * @return {ReturnType<getPaginatedResults<ProductEntity>}
 */
export const fetchProducts = ({ ...params }) =>
    getPaginatedResults('/product-search', params).then(response => {
        return response;
    });
