import { DOMAIN } from './DataMap.constants';
import { MAP_FILTERS } from '/b2b/dataMapping/constants';
import get from 'lodash/get';

export const getStore = state => get(state, DOMAIN) || {};

export const getDirection = state => getStore(state).direction || null;
export const getFilters = state => getStore(state).filters || {};
export const getFullscreen = state => getStore(state).fullscreen || false;
export const getLayoutComplete = state => getStore(state).layoutComplete || false;
export const getLoading = state => getStore(state).loading || false;
export const getNodesToConnect = state => getStore(state).nodesToConnect || {};
export const getSavingNodeId = state => getStore(state).savingNodeId || null;
export const getSelectedFilters = state => getStore(state).selectedFilters || {};
export const getUnmappedNodes = state => getStore(state).unmappedNodes || [];

export const getActiveFilters = state =>
    Object.entries(getSelectedFilters(state)).filter(
        ([filterName, selected]) => MAP_FILTERS[filterName] && selected.length > 0
    );

export const filterExists = state => {
    const filters = getFilters(state);
    return Object.keys(filters).some(filter => filters[filter].length > 0);
};
