export default {
    root: {
        padding: '10px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
        },
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
        '&.MuiPhoneNumber-flagButton': {
            width: 'min-content',
            '& svg': {
                width: 'fill-available',
            },
        },
    },
};
