export default theme => ({
    root: {
        marginTop: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    link: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
});
