import { EVENT_TYPE, RESOURCE_TYPE, UPDATE_FREQUENCY } from './constants';
import { formatNotificationResults, formatNotificationSettings } from './format';
import { formatPagingParams, handleError } from '../common/services/helpers';

import API from '@aws-amplify/api';
import { apiName } from '@osano-b2b';

export const fetchNotifications = (params = {}) => {
    const { filters: { unreadOnly = true, eventType } = {} } = params;

    const options = {
        queryStringParameters: {
            ...formatPagingParams(params),
            unreadOnly: !!unreadOnly,
            eventType,
        },
    };
    return API.get(apiName, '/notifications', options)
        .catch(handleError)
        .then(formatNotificationResults);
};

export const markAllNotificationsRead = () => {
    const options = {};
    return API.post(apiName, '/notifications/read', options).catch(handleError);
};

export const markNotificationById = (notificationId, params = {}) => {
    const { read = true } = params;
    const options = {
        body: { read },
    };
    return API.post(apiName, `/notification/${notificationId}/read`, options).catch(handleError);
};

export const fetchUserNotifications = () => {
    return API.get(apiName, `/user/prefs/notification`, {})
        .catch(handleError)
        .then(formatNotificationSettings);
};

export const updateUserNotifications = settings => {
    const body = settings.reduce(
        (allSettings, { eventType, resourceType, frequency, preferences }) => {
            if (
                (frequency !== null && (!frequency || !UPDATE_FREQUENCY[frequency])) ||
                (!EVENT_TYPE[eventType] && !RESOURCE_TYPE[resourceType])
            ) {
                return allSettings;
            }
            if (preferences) {
                allSettings.push({
                    resourceType,
                    frequency,
                    preferences,
                });
            } else {
                allSettings.push({
                    resourceType,
                    frequency,
                });
            }

            return allSettings;
        },
        []
    );
    const options = {
        body,
    };

    return API.put(apiName, `/user/prefs/notification`, options)
        .catch(handleError)
        .then(response => response || body)
        .then(formatNotificationSettings);
};
