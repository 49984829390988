import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    namedParameters,
    noParameters,
    singleParameter,
} from '../../common/helpers/ReduxHelpers';

import { DOMAIN } from './Auth.constants';

export const events = {
    logout: noParameters,
    changeUserRole: singleParameter,
    updateAuthState: singleParameter,
    selectMfa: singleParameter,
    removeMfa: namedParameters('params', 'cognitoUser'),
    getMfaOptionsBegin: noParameters,
    getMfaOptionsSuccess: singleParameter,
    getMfaOptionsFailure: singleParameter,
};

export const asyncEvents = makeAsyncEvents([
    'signUp',
    'signIn',
    'signInFederated',
    'forgotPassword',
    'confirmSignUp',
    'confirmSignIn',
    'resendSignUpCode',
    'verifyEmail',
    'verifyPhone',
    'resendCode',
    'resendEmailVerificationCode',
    'resendPhoneVerificationCode',
    'resetPassword',
    'resetTemporaryPassword',
    'completeNewPassword',
    'fetchCustomer',
    'changePassword',
    'setPreferredMfa',
    'setupPhone',
    'setupTotp',
    'updateUserAttributes',
]);

export const contextEvents = makeAsyncEvents(['fetchSignInContext']);

export const types = makeActionTypes(
    [...Object.keys(events), ...Object.keys(asyncEvents), ...Object.keys(contextEvents)],
    DOMAIN
);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
        ...contextEvents,
    },
    types
);
