import {
    buildActionCreators,
    makeActionTypes,
    noParameters,
    singleParameter,
} from '../../helpers/ReduxHelpers';

export const events = {
    accept: singleParameter,
    cancel: noParameters,
    change: singleParameter,
    closeDialog: noParameters,
    openDialog: noParameters,
    pick: singleParameter,
    reset: noParameters,
};

export const types = makeActionTypes(Object.keys(events), '@@Components__DateRangePicker');

export default buildActionCreators(events, types);
