import PropTypes from 'prop-types';
import React from 'react';
import { SvgIcon } from '@mui/material';
import { white } from '../../colors';

export default function Assessments(props) {
    const { fill = white } = props;

    return (
        <SvgIcon fontSize="large" viewBox="0 0 24 24" {...props}>
            <path
                d="M4,20c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S5.1,20,4,20z M4,18L4,18L4,18z M21,19H9c-0.6,0-1-0.4-1-1
                s0.4-1,1-1h12c0.6,0,1,0.4,1,1S21.6,19,21,19z M4,14c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C6,13.1,5.1,14,4,14z M4,12L4,12
                L4,12z M21,13H9c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S21.6,13,21,13z M4,8C2.9,8,2,7.1,2,6s0.9-2,2-2s2,0.9,2,2S5.1,8,4,8
                z M4,6L4,6L4,6z M21,7H9C8.4,7,8,6.6,8,6s0.4-1,1-1h12c0.6,0,1,0.4,1,1S21.6,7,21,7z"
                fill={fill}
            />
        </SvgIcon>
    );
}

Assessments.propTypes = {
    fill: PropTypes.string,
};
