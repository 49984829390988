import { useEffect, useRef } from 'react';

/**
 * Uses the provided `compare` function to determine whether the Previous
 * value is equal to the Next value provided.
 * - If Previous is `undefined`, then Next will be returned by default.
 * - If `compare` is undefined, then strict equality (`===`) will be used for comparison
 * - If `compare` returns `true`, then Previous is provided.
 * - If `compare` returns `false` then Next is provided.
 *
 * @example const isGreater = (a, b) => a > b;
 * useMemoCompare(1, isGreater); // Will return `1` and store `1` in Previous because Previous was not defined
 * useMemoCompare(0, isGreater); // Will return `1` and maintain `1` in Previous because `isGreater` returns true
 * useMemoCompare(2, isGreater); // Will return `2` and replace Previous with `2` because `isGreater` returns false
 * useMemoCompare(1, isGreater); // Will return `2` and maintain `2` in Previous because `isGreater` returns true
 *
 * @template {*} Next
 * @template {*} Previous
 * @param {Next} next
 * @param {function(Previous, Next):boolean=} compare
 * @return {Previous|Next}
 */
export const useMemoCompare = function (next, compare) {
    const compareStrictEquality = !compare;
    // Ref for storing previous value
    const previousRef = useRef();
    const previous = previousRef.current;
    // Pass previous and next value to compare function
    // to determine whether to consider them equal.
    const isEqual = (compareStrictEquality && next === previous) || compare(previous, next);
    // If not equal update previousRef to next value.
    // We only update if not equal so that this hook continues to return
    // the same old value if compare keeps returning true.
    useEffect(() => {
        if (!isEqual) {
            previousRef.current = next;
        }
    });
    // Finally, if equal then return the previous value
    return isEqual ? previous : next;
};

export default useMemoCompare;
