import { Button, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { Fragment } from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { Link } from 'react-router-dom';
import Locale from '/b2b/common/components/Locale';
import PropTypes from 'prop-types';
import { URGENT_TYPES } from '/b2b/notifications/constants';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import schema from '/b2b/notifications/state/db/Notification.schema';
import styles from './NotificationList.styles';

const useStyles = makeStyles(styles);

const NotificationList = props => {
    const { className, results, markAllRead, onSelect } = props;
    const classes = useStyles(props);

    const rootClassName = classNames(classes.root, className);

    return (
        <div className={rootClassName}>
            {results.length > 0 ? (
                <Fragment>
                    <div className={classes.header}>
                        <Typography variant="h3">
                            <Locale path="notifications.userNotifications" />
                        </Typography>
                    </div>
                    <div className={classes.content}>
                        <List component="div">
                            {results.map(notification => {
                                const { type } = notification;
                                const id = schema.idAttribute(notification);
                                return (
                                    <Link key={id} to={`${AuthenticatedPaths.NOTIFICATIONS}/${id}`}>
                                        <ListItem
                                            className={classes.listItem}
                                            component="div"
                                            onClick={onSelect}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            URGENT_TYPES.includes(type)
                                                                ? classes.urgentItem
                                                                : classes.listItemTextSecondary
                                                        }
                                                    >
                                                        {notification.title}
                                                    </Typography>
                                                }
                                                secondary={notification.when}
                                            />
                                            <ArrowForwardIosIcon className={classes.arrowForward} />
                                        </ListItem>
                                        <Divider />
                                    </Link>
                                );
                            })}
                        </List>
                        <div className={classes.notificationButtons}>
                            <Button
                                className={classes.viewAllButton}
                                color="primary"
                                component={Link}
                                size="small"
                                to={AuthenticatedPaths.NOTIFICATIONS}
                                variant="outlined"
                            >
                                <Locale path="notifications.viewAll" />
                            </Button>
                            <Button
                                color="primary"
                                onClick={markAllRead}
                                size="small"
                                variant="outlined"
                            >
                                <Locale path="notifications.markAllAsRead" />
                            </Button>
                        </div>
                    </div>
                </Fragment>
            ) : (
                <div className={classes.empty}>
                    <div className={classes.emptyImageWrapper}>
                        <img
                            alt="Empty list"
                            className={classes.emptyImage}
                            src="/assets/images/empty.png"
                        />
                    </div>
                    <Typography variant="h4">
                        <Locale path="notifications.youAreAllCaughtUp" />
                    </Typography>
                    <div className={classes.footer}>
                        <Button
                            color="primary"
                            component={Link}
                            size="small"
                            to={AuthenticatedPaths.NOTIFICATIONS}
                            variant="contained"
                        >
                            <Locale path="notifications.viewAll" />
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

NotificationList.propTypes = {
    className: PropTypes.string,
    results: PropTypes.array.isRequired,
    resultCount: PropTypes.number,
    onSelect: PropTypes.func,
    markAllRead: PropTypes.func,
};

NotificationList.defaultProps = {
    results: [],
    resultCount: 0,
    onSelect: () => {},
};

export default NotificationList;
