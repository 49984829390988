import theme from '../default';
import typography from '../typography';

export default {
    root: {
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
            '&.Mui-expanded': {
                marginLeft: theme.spacing(1),
            },
        },
        '& .MuiTypography-root': {
            ...typography.h6,
        },
    },
};
