import {
    buildActionCreators,
    makeActionTypes,
    namedParameters,
    noParameters,
    singleParameter,
} from '/b2b/common/helpers/ReduxHelpers';

export const events = {
    add: namedParameters('locale', 'value'),
    delete: singleParameter,
    dragField: namedParameters('dragIndex', 'hoverIndex'),
    openDialog: noParameters,
    confirmDialog: singleParameter,
    cancelDialog: singleParameter,
    revert: singleParameter,
    selectLocale: singleParameter,
    update: namedParameters('locale', 'value'),
};

export const types = makeActionTypes(Object.keys(events), '@@Components__Translations');

export default buildActionCreators(events, types);
