const baseTitle = document.title;

import { DOMAIN, documentTitleByPath, pathMatcher } from './UI.constants';

import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { translate } from '../../helpers/i18n';

export const getStore = state => get(state, DOMAIN) || {};

export const getDocumentTitle = (state, locale, location) => {
    const { pathname = '' } = location || document.location;
    const title =
        documentTitleByPath[pathMatcher(pathname)] ||
        startCase(pathname.split('/')[1]) ||
        'title.dashboard';
    return `${baseTitle} - ${translate(title, locale)}`;
};
export const getPreventWarning = state => getStore(state).preventWarning;
export const getResultsView = state => getStore(state).resultsView;
export const getResultsViewForPath = (state, path) => get(getResultsView(state), path);
export const getSnacks = state => getStore(state).snackLookup;
export const getSnackOrder = state => getStore(state).snackOrder;
export const getSnackByKey = (state, key) => getStore(state).snackLookup[key];
export const getSubmenus = state => getStore(state).submenus;
export const getRoadblockActions = state => getStore(state).roadblocks;
export const getRoadblockError = state => getStore(state).error;

export const isUserIdle = state => getStore(state).userIdle === true;
export const isBlackout = state => !!getStore(state).blackout;
export const isLoading = state => !!getStore(state).async;
export const isDrawerOpen = state => !!getStore(state).drawerOpen;
export const areNotificationsOpen = state => !!getStore(state).notificationsOpen;
export const areUserDetailsOpen = state => !!getStore(state).userDetailsOpen;
export const shouldPreventWarning = (state, path) =>
    path ? !!getPreventWarning(state).includes(path) : false;
