import { types as ActionTypes, asyncEvents as AsyncEvents } from './RequestList.actions';
import {
    errorReducer,
    genericReducer,
    loadingReducer,
    passthroughReducer,
} from '../../../common/helpers/ReduxHelpers';

import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

export const initialState = {
    loading: false,
    error: null,
    columnOrder: [],
    columnVisibility: {},
};

export default reduceReducers(
    initialState,
    combineReducers({
        columnOrder: passthroughReducer(initialState.columnOrder),
        columnVisibility: passthroughReducer(initialState.columnVisibility),
        loading: loadingReducer(
            initialState.loading,
            Object.keys(AsyncEvents).map(key => ActionTypes[key])
        ),
        error: errorReducer(
            initialState.error,
            Object.keys(AsyncEvents).map(key => ActionTypes[key])
        ),
    }),
    genericReducer(initialState, {
        [ActionTypes.setColumnOrder]: (state, { columnOrder = {}, orderedColumns = [] }) => {
            const { field, targetIndex } = columnOrder;
            const defaultColumnOrdering = orderedColumns.map(column => column.field);

            const mergedColumnOrder = [
                ...new Set([...state.columnOrder, ...defaultColumnOrdering]),
            ];

            //Remove the column we're moving from the current index and insert it at the target index
            const currentIndex = mergedColumnOrder.indexOf(field);
            if (currentIndex !== -1) {
                mergedColumnOrder.splice(currentIndex, 1);
            }
            mergedColumnOrder.splice(targetIndex, 0, field);

            return {
                ...state,
                columnOrder: mergedColumnOrder,
            };
        },
        [ActionTypes.setColumnVisibility]: (state, columnVisibility) => ({
            ...state,
            columnVisibility,
        }),
        [AuthActionTypes.logout]: () => initialState,
    })
);
