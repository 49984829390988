import React, { useState } from 'react';

import PropTypes from 'prop-types';
import ValidationForm from '/b2b/common/components/ValidationForm';
import schema from './VerifyPhoneDialog.schema';

const VerifyPhoneDialog = props => {
    const { onSubmit, onVerify, onUpdate, onResend, defaultValues } = props;
    const [updated, setUpdated] = useState(false);

    const handleSubmit = params => {
        return updated
            ? onVerify(params).then(() => {
                  return onSubmit();
              })
            : onUpdate(params).then(() => {
                  onResend();
                  setUpdated(true);
                  return { ...params, currentPhone: params.phone, updated: true };
              });
    };

    return (
        <ValidationForm
            schema={schema}
            onSubmit={handleSubmit}
            submitText={'views.Account.components.AccountSecurity.dialogs.savePhoneNumber'}
            allowSubmit={true}
            defaultValues={{ ...defaultValues, updated }}
        />
    );
};

VerifyPhoneDialog.propTypes = {
    currentPhone: PropTypes.string,
    defaultValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onVerify: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onResend: PropTypes.func.isRequired,
};

export default VerifyPhoneDialog;
