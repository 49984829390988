import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    namedParameters,
} from '../../helpers/ReduxHelpers';

import { DOMAIN } from './Locale.constants';

export const events = {
    localeExtended: namedParameters('domain', 'localeCode', 'phrases'),
};

export const asyncEvents = makeAsyncEvents(['fetchLocale'], true);

export const types = makeActionTypes([...Object.keys(events), ...Object.keys(asyncEvents)], DOMAIN);

export default buildActionCreators({ ...events, ...asyncEvents }, types);
