import Component from './SelectMFARoutes.component';
import UnauthenticatedPaths from '../../../routing/UnauthenticatedRoutes/UnauthenticatedRoutes.paths';
import { connect } from 'react-redux';
import fromState from '../../../common/state/selectors';
import { matchPath } from 'react-router-dom';

const mapStateToProps = state => {
    return {
        needsPhoneSetup:
            !matchPath(location.pathname, {
                path: `${UnauthenticatedPaths.SELECT_MFA}/setup-phone`,
            }) && fromState.Auth.needsPhoneSetup(state),
        needsTOTPSetup:
            !matchPath(location.pathname, {
                path: `${UnauthenticatedPaths.SELECT_MFA}/setup-totp`,
            }) && fromState.Auth.needsTOTPSetup(state),
        needsPhoneVerification:
            !matchPath(location.pathname, {
                path: `${UnauthenticatedPaths.SELECT_MFA}/verify-phone`,
            }) && fromState.Auth.needsPhoneVerification(state),
        needsTOTPVerification:
            !matchPath(location.pathname, {
                path: `${UnauthenticatedPaths.SELECT_MFA}/verify-totp`,
            }) && fromState.Auth.needsTOTPVerification(state),
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
