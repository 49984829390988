import { createSchema } from '../../../common/helpers/Schema';

export default createSchema({
    newPassword: {
        label: 'common.fields.newPassword.label',
    },
    passwords: {
        label: 'password.confirmNew',
        equality: {
            attribute: 'newPassword',
            message: 'password.validations.notMatching',
        },
    },
});
