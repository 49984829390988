import AuthenticatedPaths from '../../../routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';

export const DOMAIN = 'nav';

export const BEACON_MESSAGES = [
    {
        path: AuthenticatedPaths.DOCUMENT_CHANGES,
        strict: false,
        exact: true,
        messageId: '62975e3c-c865-4be6-ac10-7bb3d2e5eb7c',
    },

    {
        path: `${AuthenticatedPaths.LITIGATION}/:id/documents`,
        strict: false,
        exact: true,
        messageId: 'f64f500a-5b1f-4ccf-bf99-7d5ff55018d0',
    },

    {
        path: `${AuthenticatedPaths.LITIGATION}/:id`,
        strict: false,
        exact: true,
        messageId: '942026dd-467e-4ed1-a641-7cc2009abf7f',
    },

    {
        path: `${AuthenticatedPaths.LITIGATION}`,
        strict: false,
        exact: true,
        messageId: 'd60ff493-2509-4bcf-a735-66aebd36ea74',
    },

    {
        path: `${AuthenticatedPaths.LEGISLATION}`,
        strict: false,
        exact: true,
        messageId: '54b7d4b9-1d8d-4153-9f10-d9330d8dac5c',
    },

    {
        path: `${AuthenticatedPaths.VENDORS}/:id/domains`,
        strict: false,
        exact: true,
        messageId: '33a96db2-ff7b-4bc9-849e-7ab749045d7b',
    },

    {
        path: `${AuthenticatedPaths.VENDORS}/:id/documents`,
        strict: false,
        exact: true,
        messageId: '33a96db2-ff7b-4bc9-849e-7ab749045d7b',
    },

    {
        path: `${AuthenticatedPaths.VENDORS}/:id/products`,
        strict: false,
        exact: true,
        messageId: '9247d184-1c1f-4827-85b3-aa195a208a4e',
    },

    {
        path: `${AuthenticatedPaths.VENDORS}/:id`,
        strict: false,
        exact: true,
        messageId: 'c01f656b-e15d-42b0-8265-f9dd76eff96f',
    },

    {
        path: `${AuthenticatedPaths.VENDORS}`,
        strict: false,
        exact: true,
        messageId: '62dacadb-2ecb-4e56-b46a-651f34a90e55',
    },

    {
        path: `${AuthenticatedPaths.PRODUCTS}/:id/domains`,
        strict: false,
        exact: true,
        messageId: '885a4783-0c96-4f5e-a123-b9585e4d76ba',
    },

    {
        path: `${AuthenticatedPaths.PRODUCTS}/:id/documents`,
        strict: false,
        exact: true,
        messageId: 'c7eb0656-9c78-4ddf-a7dd-62876b1ba5fe',
    },

    {
        path: `${AuthenticatedPaths.PRODUCTS}/:id/scoring`,
        strict: false,
        exact: true,
        messageId: 'd03d72f1-a62d-4c37-b841-a74716d74330',
    },

    {
        path: `${AuthenticatedPaths.PRODUCTS}/:id`,
        strict: false,
        exact: true,
        messageId: '50bb2b38-4634-4297-bc29-48a56eab0f99',
    },

    {
        path: `${AuthenticatedPaths.PRODUCTS}/add`,
        strict: false,
        exact: true,
        messageId: '64d5763f-303e-4b54-8189-f6bbc94d30f0',
    },

    {
        path: `${AuthenticatedPaths.PRODUCTS}`,
        strict: false,
        exact: true,
        messageId: '3ebaa960-7bbd-4eba-abb6-1b491116da37',
    },

    {
        path: `${AuthenticatedPaths.CONSENT_MANAGER}/:id/classification`,
        strict: false,
        exact: true,
        messageId: '93b42697-e492-49c8-970d-caae4262a9f4',
    },

    {
        path: `${AuthenticatedPaths.CONSENT_MANAGER}/new/styling`,
        strict: false,
        exact: true,
        messageId: '5050611d-c334-4eb1-8596-76fe0ac9638d',
    },

    {
        path: `${AuthenticatedPaths.CONSENT_MANAGER}/new`,
        strict: false,
        exact: true,
        messageId: '2847cdf5-3a6d-456b-88f6-04aaf9d6add4',
    },

    {
        path: `${AuthenticatedPaths.CONSENT_MANAGER}`,
        strict: false,
        exact: true,
        messageId: 'f41b58a0-c50f-4ff8-b4ec-d020837eae4b',
    },

    {
        path: `${AuthenticatedPaths.USERS}`,
        strict: false,
        exact: true,
        messageId: '8c799aa3-ba13-4c2c-aa4c-ff26096d9c72',
    },

    {
        path: `${AuthenticatedPaths.NOTIFICATIONS}`,
        strict: false,
        exact: true,
        messageId: '0f76a7fb-a4eb-4537-b34a-30c777052ced',
    },

    {
        path: `${AuthenticatedPaths.ASK_ANALYST}`,
        strict: false,
        exact: true,
        messageId: '5d5f333a-b7ed-4dd0-9a50-b5ddee00712c',
    },

    {
        path: `${AuthenticatedPaths.DOCUMENTS}/:id/versions`,
        strict: false,
        exact: true,
        messageId: 'c7e2ae10-8b06-400a-9e4d-e0ed5e9c875f',
    },

    {
        path: `${AuthenticatedPaths.ACCOUNT}/notifications`,
        strict: false,
        exact: true,
        messageId: '92a58653-242f-43d3-9282-43000bac7d7e',
    },

    {
        path: `${AuthenticatedPaths.ACCOUNT}`,
        strict: false,
        exact: true,
        messageId: 'b547afcf-8052-41a6-b6aa-5ec404c10352',
    },

    {
        path: `${AuthenticatedPaths.GDPR}`,
        strict: false,
        exact: true,
        messageId: 'e87dc8d4-0150-49d5-82d4-093e2d461dbf',
    },

    {
        path: `${AuthenticatedPaths.DATA_STORES}`,
        strict: false,
        exact: true,
        messageId: '5d5f333a-b7ed-4dd0-9a50-b5ddee00712c', // TODO: New HelpScout beacon message
    },
];
