import {
    FormControl,
    InputLabel,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select,
} from '@mui/material';
import React, { useCallback, useEffect } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import Locale from '/b2b/common/components/Locale';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const styles = theme => {
    return {
        formControl: {
            display: 'flex',
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(1),
        },
        popover: {
            zIndex: theme.zIndex.tooltip + 1,
        },
    };
};

const useStyles = makeStyles(styles);

const CustomerPlanSelect = props => {
    const { customerId, label, value, options = [], onChange, ...remain } = props;
    const classes = useStyles(props);
    const isOpen = React.useRef(false);
    const inputLabel = React.useRef(null);
    const [currentValue, setCurrentValue] = React.useState(value);

    const handleChange = useCallback(event => {
        const val = event?.target?.value || event;
        setCurrentValue(val);
    }, []);

    const handleClose = useCallback(() => {
        isOpen.current = false;
        if (currentValue.length) {
            onChange && onChange(currentValue, value);
        } else {
            setCurrentValue(value);
        }
    }, [handleChange, currentValue, value]);
    const handleOpen = useCallback(() => {
        isOpen.current = true;
    }, []);

    const handleRenderValue = useCallback(() => {
        // Selected is not `currentValue`
        return currentValue.filter(Boolean).join(', ') || '--';
    }, [customerId, currentValue, options]);

    useEffect(() => {
        setCurrentValue(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="customer_plan" ref={inputLabel}>
                <Locale path={label} />
            </InputLabel>
            <Select
                variant="outlined"
                label={<Locale path={label} />}
                name="plan"
                id="customer_plan"
                {...remain}
                onOpen={handleOpen}
                onClose={handleClose}
                onChange={handleChange}
                multiple={true}
                value={currentValue}
                renderValue={handleRenderValue}
                MenuProps={{ PopoverClasses: { root: classes.popover } }}
            >
                {options.map(({ feature, label }) => (
                    <MenuItem key={feature} value={feature}>
                        <ListItemText primary={label} />
                        <ListItemSecondaryAction>
                            {currentValue && currentValue.includes(feature) ? <CheckIcon /> : null}
                        </ListItemSecondaryAction>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

CustomerPlanSelect.propTypes = {
    customerId: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.array,
};

CustomerPlanSelect.defaultProps = { options: [] };

export default CustomerPlanSelect;
