import './rich.css';
import { Editor, RichUtils, getDefaultKeyBinding } from 'draft-js';
import React, { useRef } from 'react';
import BlockStyleControls from './BlockStyleControls';
import { Box } from '@mui/material';
import InlineStyleControls from './InlineStyleControls';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import styles from './DraftJsEditor.styles';

const useStyles = makeStyles(styles);

const DraftJsEditor = props => {
    const { editorState, setEditorState } = props;
    const classes = useStyles(props);
    const editorRef = useRef(null);

    const focus = () => editorRef.current.focus();

    const onChange = editorState => setEditorState(editorState);

    const _handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onChange(newState);
            return true;
        }
        return false;
    };

    const _mapKeyToEditorCommand = e => {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);
            if (newEditorState !== editorState) {
                onChange(newEditorState);
            }
            return;
        }
        return getDefaultKeyBinding(e);
    };

    function _toggleBlockType(blockType) {
        onChange(RichUtils.toggleBlockType(editorState, blockType));
    }

    function _toggleInlineStyle(inlineStyle) {
        onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    }

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    let contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
            className += ' RichEditor-hidePlaceholder';
        }
    } else {
        className += ' RichEditor-hidePlaceholder';
    }

    return (
        <Box className={classes.editorWrapper + ' RichEditor-root'}>
            <Box className={classes.toolbarWrapper}>
                <InlineStyleControls editorState={editorState} onToggle={_toggleInlineStyle} />
                <BlockStyleControls editorState={editorState} onToggle={_toggleBlockType} />
            </Box>
            <div className={className} onClick={focus}>
                <Editor
                    editorState={editorState}
                    handleKeyCommand={_handleKeyCommand}
                    keyBindingFn={_mapKeyToEditorCommand}
                    onChange={onChange}
                    ref={editorRef}
                    spellCheck={true}
                />
            </div>
        </Box>
    );
};

DraftJsEditor.propTypes = {
    editorState: PropTypes.object,
    setEditorState: PropTypes.func,
};

export default DraftJsEditor;
