import Component from './FieldClassificationStats.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const mapStateToProps = state => {
    return {
        fieldClassifications: fromState.Dashboard.getFieldClassificationsData(state),
        isLoading: fromState.Dashboard.isLoading(state),
        localeCode: fromState.Locale.getLocaleCode(state),
    };
};

export default connect(mapStateToProps, {})(Component);
