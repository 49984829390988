/* eslint-disable camelcase */
import { fetchJSON, handleError, handleResponse } from '../common/services/helpers';

import API from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';
import { MFA_TYPES } from './constants';
import { apiName } from '@osano-b2b';
import { apiUrl } from '@osano-b2b';
import { updateAuth } from '../amplify.config';

const requiredSignUpProps = ['name', 'email', 'given_name', 'family_name', 'phone_number'];

const toCognitoMap = {
    firstName: 'given_name',
    lastName: 'family_name',
    phone: 'phone_number',
};

const fromCognitoMap = Object.entries(toCognitoMap).reduce(
    (map, [key, value]) => ((map[value] = key), map),
    {}
);

let _cognitoUser;
const storeCognitoUser = user => ((_cognitoUser = user), user);

export const getCognitoUser = () => {
    return _cognitoUser
        ? Promise.resolve(_cognitoUser)
        : Auth.currentAuthenticatedUser().then(storeCognitoUser);
};

export const mapFromCognitoParams = (params, allowedReceived) => {
    const standardizedProps = Object.entries(params).reduce((props, [key, value]) => {
        if (key === 'address') {
            props[key] = JSON.parse(value);
        } else if (key === 'identities') {
            // Federated identities the user uses
            try {
                const identities = JSON.parse(value);
                props[key] = {};
                identities.forEach(({ providerName, providerType, userId, primary }) => {
                    props[key][providerName] = {
                        primary,
                        type: providerType,
                        userId,
                    };
                });
            } catch (e) {
                // Malformed
            }
        } else if (fromCognitoMap[key]) {
            props[fromCognitoMap[key]] = value;
        } else {
            props[key] = value;
        }
        return props;
    }, {});

    const allowed = allowedReceived || Object.keys(standardizedProps);

    return Object.keys(standardizedProps)
        .filter(
            key =>
                allowed.includes(key) &&
                standardizedProps[key] !== undefined &&
                standardizedProps[key] !== ''
        )
        .reduce((o, key) => ((o[key] = standardizedProps[key]), o), {});
};

export const mapToCognitoParams = (params, allowedProvided) => {
    let {
        firstName,
        lastName,
        policy,
        street_address = '',
        locality = '',
        region = '',
        postal_code = '',
        country = 'US',
    } = params;

    const name =
        `${firstName || ''}${firstName && lastName ? ' ' : ''}${lastName || ''}` || undefined;
    const address =
        street_address && locality && region && postal_code
            ? {
                  formatted: `${street_address}\n${locality}, ${region} ${postal_code}\n${country}`,
                  street_address,
                  locality,
                  region,
                  postal_code,
                  country,
              }
            : undefined;

    const standardizedProps = Object.entries(params).reduce(
        (props, [key, value]) => {
            if (key === 'phone') {
                if (value && value.indexOf('+') !== 0) {
                    props[toCognitoMap[key]] = `+${value}`;
                } else {
                    props[toCognitoMap[key]] = value ? value : '';
                }
            } else if (toCognitoMap[key]) {
                props[toCognitoMap[key]] = value;
            } else {
                props[key] = value;
            }
            return props;
        },
        {
            address: address ? JSON.stringify(address) : address,
            name,
            policy: policy ? policy.toString() : policy,
        }
    );

    const allowed = allowedProvided || Object.keys(standardizedProps);

    return Object.keys(standardizedProps)
        .filter(
            key =>
                allowed.includes(key) &&
                standardizedProps[key] !== undefined &&
                standardizedProps[key] !== ''
        )
        .reduce((o, key) => ((o[key] = standardizedProps[key]), o), {});
};

export const extractUserDetails = cognitoUser => {
    return new Promise((resolve, reject) => {
        const { username, challengeParam, attributes } = cognitoUser;

        if (attributes) {
            // User already has attributes loaded
            const userDetails = { username, ...attributes };
            resolve(mapFromCognitoParams(userDetails || {}));
            return;
        }

        if (challengeParam) {
            // User needs to complete a challenge
            const { userAttributes } = challengeParam;
            const userDetails = { username, ...userAttributes };
            resolve(mapFromCognitoParams(userDetails || {}));
            return;
        }
        cognitoUser.getUserAttributes((err, result) => {
            if (err) {
                reject(err);
                return;
            }
            const { username } = cognitoUser;
            const userDetails = { username };
            for (let i = 0; i < result.length; i++) {
                const key = result[i].getName();
                const value = result[i].getValue();
                if (key.indexOf('custom:') === 0) {
                    const customKey = key.split(':').slice(1).join(':');
                    userDetails[customKey] = value;
                } else {
                    userDetails[key] = value;
                }
            }
            resolve(mapFromCognitoParams(userDetails || {}));
        });
    });
};

const updateCognitoUserWithUserData = (cognitoUser, userData) => {
    const { UserMFASettingList, UserAttributes, PreferredMfaSetting } = userData;
    const [preferredMFA = PreferredMfaSetting || cognitoUser?.preferredMFA || 'NOMFA'] =
        UserMFASettingList || [];
    const {
        attributes = (UserAttributes || []).reduce(
            (attrs, { Name, Value }) => ((attrs[Name] = Value), attrs),
            {}
        ),
    } = cognitoUser;
    cognitoUser.preferredMFA = preferredMFA;
    cognitoUser.UserMFASettingList = UserMFASettingList || [];
    cognitoUser.attributes = attributes;
    return cognitoUser;
};

export const getUserData = (user, options) => {
    return getCognitoUser({ user })
        .catch(handleError)
        .then(
            cognitoUser =>
                new Promise((resolve, reject) => {
                    cognitoUser.getUserData((error, userData) => {
                        if (error) {
                            reject(error);
                        }
                        resolve(userData);
                    }, options);
                })
        );
};

const getSignedInUserSession = cognitoUser => cognitoUser?.signInUserSession;

export const getAccessToken = cognitoUser => getSignedInUserSession(cognitoUser)?.accessToken;
export const getAccessTokenExpiration = cognitoUser =>
    getAccessToken(cognitoUser)?.payload?.exp || 0;

export const fixAuthContext = () => {
    return Auth.currentSession().catch(e => {
        const { message = e } = e;
        switch (message) {
            case 'No current user': {
                // User pool may be wrong, lets try the other one
                const { userPoolId } = Auth.configure();
                switch (userPoolId) {
                    case process.env.COGNITO_IDP_USER_POOL_ID: {
                        // Switch to Cognito User Pool
                        updateAuth({
                            ssoEnabled: false,
                        });
                        break;
                    }

                    default: {
                        // Switch to SSO User Pool
                        updateAuth({
                            ssoEnabled: true,
                        });
                        break;
                    }
                }
                // Try again
                return Auth.currentSession();
            }
        }
        throw e;
    });
};

export const refreshTokens = () => {
    return fixAuthContext()
        .then(session => {
            if (session.isValid()) {
                // If the session is valid, return it, no need to refresh
                return session;
            }
            // console.log('-- refreshTokens', session, session.isValid());
            return Auth.currentAuthenticatedUser().then(cognitoUser => {
                const { refreshToken } = cognitoUser.getSignInUserSession();
                return new Promise((resolve, reject) => {
                    cognitoUser.refreshSession(refreshToken, (err, session) => {
                        err ? reject(err) : resolve(session);
                    });
                });
            });
        })
        .catch(handleError);
};

export const checkUser = () => {
    return Auth.currentAuthenticatedUser({
        // Do NOT get a cached response
        bypassCache: true,
    })
        .catch(error => {
            switch (error) {
                case 'not authenticated': {
                    throw {
                        status: 'error',
                        code: 'NotAuthorizedException',
                        name: 'NotAuthorizedException',
                        message: 'User is not authenticated',
                    };
                }
            }
            throw error;
        })
        .catch(handleError)
        .then(cognitoUser => {
            return getUserData(cognitoUser)
                .then(userData => {
                    updateCognitoUserWithUserData(cognitoUser, userData);
                    return extractUserDetails(cognitoUser).then(details => ({
                        details,
                        cognitoUser,
                    }));
                })
                .catch(() => {
                    // We get here when there's a challenge
                    return extractUserDetails(cognitoUser).then(details => ({
                        details,
                        cognitoUser,
                    }));
                });
        });
};

export const getUserMfaOptions = () => {
    return getCognitoUser().then(cognitoUser =>
        getUserData(cognitoUser)
            .then(userData => userData['UserMFASettingList'] || [])
            .catch(() => {
                // We get here when the user isn't authenticated
                const {
                    MFAS_CAN_CHOOSE, // JSON Array "["SMS_MFA","SOFTWARE_TOKEN_MFA"]"
                } = cognitoUser?.challengeParam || {};
                try {
                    return JSON.parse(MFAS_CAN_CHOOSE) || [];
                } catch (errpr) {
                    return [];
                }
            })
    );
};

export const updateUserAttributes = params => {
    const attributes = mapToCognitoParams(params);
    /*
    const attributeList = [];
    for (const key in attributes) {
        if (key !== 'sub') {
            const attr = {
                Name: key,
                Value: attributes[key],
            };
            attributeList.push(attr);
        }
    }
    */
    return getCognitoUser()
        .then(cognitoUser =>
            Auth.updateUserAttributes(cognitoUser, attributes)
                .then(() => extractUserDetails(cognitoUser))
                .then(details => ({
                    details: {
                        ...details,
                        ...mapFromCognitoParams(attributes),
                    },
                    cognitoUser,
                }))
        )
        .catch(handleError);
};

export const signUp = params => {
    const { username, password, ...extra } = params;

    return Auth.signUp({
        username,
        password,
        attributes: mapToCognitoParams(extra, requiredSignUpProps),
        validationData: [], //optional
    })
        .catch(handleError)
        .then(response => {
            const { userConfirmed, user } = response;
            storeCognitoUser(user);
            return extractUserDetails(user).then(details => ({
                details,
                cognitoUser: user,
                userConfirmed,
            }));
        });
};

export const signIn = params => {
    const { username, password } = params;
    return Auth.signIn(username, password)
        .catch(handleError)
        .then(storeCognitoUser)
        .then(cognitoUser =>
            getUserData(cognitoUser)
                .then(userData => {
                    updateCognitoUserWithUserData(cognitoUser, userData);
                    return extractUserDetails(cognitoUser).then(details => ({
                        details,
                        cognitoUser,
                    }));
                })
                .catch(() => {
                    // We get here when there's a challenge
                    return extractUserDetails(cognitoUser).then(details => ({
                        details,
                        cognitoUser,
                    }));
                })
        );
};

export const fetchAmplifyContext = params => {
    const { username } = params;
    return new Promise((resolve, reject) => {
        const url = new URL(
            `${apiUrl}/auth/${encodeURIComponent(username)}`,
            window.location.origin
        );
        resolve(fetchJSON(url).catch(reject));
    });
};

export const signInFederated = params => {
    return Auth.federatedSignIn(params).catch(handleError);
};

export const confirmSignInFederated = url => {
    return Auth._handleAuthResponse(url)
        .catch(handleError)
        .then(() => getCognitoUser())
        .then(storeCognitoUser)
        .then(cognitoUser => {
            return getUserData(cognitoUser)
                .then(userData => {
                    updateCognitoUserWithUserData(cognitoUser, userData);
                    return extractUserDetails(cognitoUser).then(details => ({
                        details,
                        cognitoUser,
                    }));
                })
                .catch(() => {
                    // We get here when there's a challenge
                    return extractUserDetails(cognitoUser).then(details => ({
                        details,
                        cognitoUser,
                    }));
                });
        });
};

export const sendMFASelection = params => {
    const { mfaType } = params;
    let type = mfaType;
    switch (mfaType) {
        case 'TOTP': {
            type = 'SOFTWARE_TOKEN_MFA';
            break;
        }
        case 'SMS': {
            type = 'SMS_MFA';
            break;
        }
    }
    return getCognitoUser().then(cognitoUser => {
        return new Promise((resolve, reject) => {
            const callback = () => {
                cognitoUser.challengeName = type;
                resolve(cognitoUser);
            };
            cognitoUser.sendMFASelectionAnswer(type, {
                mfaRequired: callback,
                totpRequired: callback,
                onFailure: reject,
            });
        });
    });
};

export const confirmSignIn = params => {
    const { code } = params;
    return getCognitoUser()
        .then(cognitoUser =>
            Auth.confirmSignIn(cognitoUser, code, cognitoUser.challengeName).then(() =>
                getUserData(cognitoUser).then(userData => {
                    updateCognitoUserWithUserData(cognitoUser, userData);
                    return extractUserDetails(cognitoUser).then(details => ({
                        details,
                        cognitoUser,
                    }));
                })
            )
        )
        .catch(handleError);
};

export const confirmSignUp = params => {
    const { username, code } = params;
    return Auth.confirmSignUp(username, code).catch(handleError);
};

export const verifyEmail = params => {
    const { code } = params;
    return Auth.verifyCurrentUserAttributeSubmit('email', code);
};

export const verifyPhone = params => {
    const { code } = params;
    return Auth.verifyCurrentUserAttributeSubmit('phone_number', code);
};

export const verifyTotpToken = ({ code, user }) =>
    getCognitoUser({ user })
        .then(cognitoUser =>
            Auth.verifyTotpToken(cognitoUser, code).then(response => {
                const { Status } = response;
                if (Status !== 'SUCCESS') {
                    throw { message: 'Code is invalid' };
                }
                return cognitoUser;
            })
        )
        .catch(handleError);

const determineMFAPreferences = (user, mfaType, remove = false) => {
    return getCognitoUser({ user })
        .then(cognitoUser => getUserMfaOptions(cognitoUser))
        .then(mfaOptions => {
            const resetMFA = mfaType === 'NOMFA';
            let trueMFAType = mfaType;
            if (MFA_TYPES.SMS.includes(mfaType)) {
                trueMFAType = 'SMS_MFA';
            } else if (MFA_TYPES.TOTP.includes(mfaType)) {
                trueMFAType = 'SOFTWARE_TOKEN_MFA';
            }
            const provideSMS =
                MFA_TYPES.SMS.includes(trueMFAType) || [mfaOptions].includes(trueMFAType);
            const provideTOTP =
                MFA_TYPES.TOTP.includes(trueMFAType) || [mfaOptions].includes(trueMFAType);
            let isSMSEnabled =
                [mfaOptions].includes('SMS_MFA') || MFA_TYPES.SMS.includes(trueMFAType)
                    ? !remove || !MFA_TYPES.SMS.includes(trueMFAType)
                    : false;
            let isTOTPEnabled =
                [mfaOptions].includes('SOFTWARE_TOKEN_MFA') || MFA_TYPES.TOTP.includes(trueMFAType)
                    ? !remove || !MFA_TYPES.TOTP.includes(trueMFAType)
                    : false;
            return {
                smsMfaSettings:
                    provideSMS && !resetMFA
                        ? {
                              Enabled: isSMSEnabled,
                              PreferredMFA: MFA_TYPES.SMS.includes(trueMFAType) && isSMSEnabled,
                          }
                        : null,
                totpMfaSettings:
                    provideTOTP && !resetMFA
                        ? {
                              Enabled: isTOTPEnabled,
                              PreferredMFA: MFA_TYPES.TOTP.includes(trueMFAType) && isTOTPEnabled,
                          }
                        : null,
            };
        });
};

export const setUserMfaPreference = (user, { smsMfaSettings, totpMfaSettings }) => {
    return getCognitoUser({ user }).then(
        cognitoUser =>
            new Promise((resolve, reject) => {
                const callback = (error, status) => {
                    if (error) {
                        return reject({ message: error, data: status });
                    }
                    if (status !== 'SUCCESS') {
                        return reject({ message: error, data: status });
                    }
                    // Cache new user data
                    getUserData(cognitoUser, { bypassCache: true })
                        .catch(err => reject({ message: err }))
                        .then(userData => {
                            updateCognitoUserWithUserData(cognitoUser, userData);
                            return extractUserDetails(cognitoUser).then(details =>
                                resolve({
                                    details,
                                    cognitoUser,
                                })
                            );
                        });
                };
                return cognitoUser.setUserMfaPreference(smsMfaSettings, totpMfaSettings, callback);
            })
    );
};

export const setPreferredMFA = params => {
    const { mfaType } = params;
    return getCognitoUser()
        .then(cognitoUser => {
            return determineMFAPreferences(cognitoUser, mfaType).then(mfaPreferences => {
                /*
                return Auth.setPreferredMFA(cognitoUser, mfaType).then(() =>
                    return setUserMfaPreference(cognitoUser, mfaPreferences);
                );
                */
                return setUserMfaPreference(cognitoUser, mfaPreferences);
            });
        })
        .catch(handleError);
};

export const removeMFA = params => {
    const { mfaType } = params;
    return getCognitoUser()
        .then(cognitoUser =>
            mfaType === 'NOMFA'
                ? cognitoUser
                : determineMFAPreferences(cognitoUser, mfaType, true).then(mfaPreferences =>
                      setUserMfaPreference(cognitoUser, mfaPreferences)
                  )
        )
        .catch(handleError);
};

/*
export const setPreferredMFA = params => {
    const { mfaType } = params;
    return Auth.currentAuthenticatedUser()
        .then(cognitoUser => Auth.setPreferredMFA(cognitoUser, mfaType).then(() => cognitoUser))
        .catch(handleError);
};
*/
export const setupTOTP = user => getCognitoUser({ user }).then(Auth.setupTOTP).catch(handleError);

export const resendSignUpCode = params => {
    const { username } = params;
    return Auth.resendSignUp(username).catch(handleError);
};

export const resendEmailVerificationCode = () => {
    return Auth.verifyCurrentUserAttribute('email');
};

export const resendPhoneVerificationCode = () => {
    return Auth.verifyCurrentUserAttribute('phone_number');
};

export const resetTemporaryPassword = email => {
    /** @type {RequestInit} */
    const options = {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        redirect: 'follow',
        referrer: 'no-referrer',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({ email }),
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    return fetch(`${apiUrl}/auth/reset`, options)
        .catch(handleError)
        .then(handleResponse)
        .then(({ json }) => {
            return json;
        });
};

export const completeNewPassword = params => {
    const { newPassword, ...extra } = params;
    return getCognitoUser()
        .then(cognitoUser => Auth.completeNewPassword(cognitoUser, newPassword, extra))
        .catch(handleError);
};

export const forgotPassword = params => {
    const { username } = params;
    return Auth.forgotPassword(username).catch(handleError);
};

export const resetPassword = params => {
    const { username, code, newPassword, confirmNewPassword } = params;
    if (newPassword !== confirmNewPassword) {
        return Promise.reject('Passwords do not match!');
    }
    return Auth.forgotPasswordSubmit(username, code, newPassword)
        .catch(handleError)
        .then(() => {
            // When you reset your password, you are not OFFICIALLY logged in
            return signIn({ username, password: newPassword });
        });
};

export const signOut = params => {
    return API.get(apiName, '/logout').finally(() => Auth.signOut(params).catch(handleError));
};

export const resendCode = async params => {
    const { hasOtherMFAMethods, mfaType, username, password } = params;
    try {
        await signOut();
        if (hasOtherMFAMethods) {
            const resp = await signIn({ username, password });
            await sendMFASelection({ mfaType });
            return resp;
        }
        return await signIn({ username, password });
    } catch (err) {
        return handleError(err);
    }
};

export const changePassword = async params => {
    const { newPassword, oldPassword, revoke } = params;
    const cognitoUser = await getCognitoUser();
    const { email: username } = await extractUserDetails(cognitoUser);
    const response = await Auth.changePassword(cognitoUser, oldPassword, newPassword);
    if (!revoke) {
        return response;
    }
    await signOut({ global: true });
    return await signIn({ username, password: newPassword });
};
