import { VALIDATION_CHECKS, validateString } from './String';

export const VALID_MIN_LENGTH = 12;
export const VALID_MAX_LENGTH = 128;

export const requirements = {
    [VALIDATION_CHECKS.LOWERCASE]: true,
    [VALIDATION_CHECKS.MAX_LENGTH]: VALID_MAX_LENGTH,
    [VALIDATION_CHECKS.MIN_LENGTH]: VALID_MIN_LENGTH,
    [VALIDATION_CHECKS.NUMERIC]: true,
    [VALIDATION_CHECKS.SPECIAL]: true,
    [VALIDATION_CHECKS.UPPERCASE]: true,
};

export const failureMessages = [
    [VALIDATION_CHECKS.LOWERCASE, 'password.validations.lowerCase'],
    [VALIDATION_CHECKS.MAX_LENGTH, 'password.validations.maxLength'],
    [VALIDATION_CHECKS.MIN_LENGTH, 'password.validations.minLength'],
    [VALIDATION_CHECKS.NUMERIC, 'password.validations.numberChar'],
    [VALIDATION_CHECKS.SPECIAL, 'password.validations.specialChar'],
    [VALIDATION_CHECKS.UPPERCASE, 'password.validations.upperCase'],
];

export const validatePassword = (password = '', details = false) =>
    validateString(password, requirements, details);
