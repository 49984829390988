import {
    CONSENTS_PATHS,
    DSAR_REQUEST_PATHS,
    PERMISSIONS,
    UNIFIED_CONSENT_PATHS,
} from '/b2b/authentication/constants';
import { Collapse, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import FeatureFlag, {
    ENABLE_ASSESSMENTS_REPORTING,
    ENABLE_CMP_DASHBOARD,
    ENABLE_COPILOT,
    ENABLE_CUSTOM_ASSESSMENTS,
    ENABLE_DISCOVERY_SOURCES,
    ENABLE_DSAR_REPORTING,
    ENABLE_UC_DASHBOARD,
    Off,
    On,
    REPORTS_FEATURE_FLAG,
} from '/b2b/common/components/FeatureFlag';
import { NAV_LABELS, SUBMENU_LABELS } from './constants';
import React, { forwardRef, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AskExpertIcon from '/b2b/common/icons/AskExpert';
import AssessmentIcon from '/b2b/common/icons/Assessments';
import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import ConsentIcon from '/b2b/common/icons/ConsentManagement';
import DSARIcon from '/b2b/common/icons/DSAR';
import DataMappingIcon from '/b2b/common/icons/DataMapping';
import Divider from '@mui/material/Divider';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import GDPRIcon from '/b2b/common/icons/GDPR';
import LegislationIcon from '/b2b/common/icons/Legislation';
import Locale from '/b2b/common/components/Locale';
import LocalizedTooltip from '/b2b/common/components/LocalizedTooltip';
import NavLinkListItem from '/b2b/common/components/NavLinkListItem';
import Permission from '/b2b/common/components/Permission';
import PropTypes from 'prop-types';
import ReportsIcon from '/b2b/common/icons/Reports';
import SidebarPopperMenu from './components/SidebarPopperMenu.component';
import TemplatesIcon from '/b2b/common/icons/Templates';
import TrusthubIcon from '/b2b/common/icons/Trusthub';
import UnifiedConsentIcon from '@mui/icons-material/Rule';
import VendorIcon from '/b2b/common/icons/Vendors';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import styles from './Sidebar.styles';

const useStyles = makeStyles(styles);

const Sidebar = forwardRef((props, sidebarRef) => {
    const {
        canManageSubjectRights,
        canManageVendors,
        canReadDataMapping,
        className,
        hasPermissionToReadAssessments,
        hasPermissionToReadUnifiedConsent,
        hasPermissionToViewAssessments,
        hasPermissionToViewUnifiedConsent,
        minified,
        submenus,
        toggleSubmenu,
    } = props;
    const classes = useStyles(props);
    const routerLocation = useLocation();
    const history = useHistory();
    const rootClassName = classNames(classes.root, className);
    const assessmentsActive = [
        AuthenticatedPaths.ASSESSMENTS,
        AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS,
        AuthenticatedPaths.ASSESSMENT_TEMPLATES,
    ].some(path => routerLocation.pathname.indexOf(path) === 0);

    const consentActive = CONSENTS_PATHS.some(path => routerLocation.pathname.indexOf(path) === 0);
    const dsarActive = DSAR_REQUEST_PATHS.some(path => routerLocation.pathname.indexOf(path) === 0);
    const dataMappingActive = [
        AuthenticatedPaths.DATA_STORES,
        AuthenticatedPaths.DATA_MAPPING_SOURCES,
        AuthenticatedPaths.DATA_MAPPING_APPLICATIONS,
        AuthenticatedPaths.DATA_MAP,
    ].some(path => routerLocation.pathname.indexOf(path) === 0);
    const reportsActive = [
        AuthenticatedPaths.REPORTS_CONSENT,
        AuthenticatedPaths.REPORTS_DATASUBJECT,
        AuthenticatedPaths.REPORTS_VENDOR,
    ].some(path => routerLocation.pathname.indexOf(path) === 0);
    const ucActive = UNIFIED_CONSENT_PATHS.some(
        path => routerLocation.pathname.indexOf(path) === 0
    );
    const vendorManagementActive = [
        AuthenticatedPaths.VENDORS,
        AuthenticatedPaths.PRODUCTS,
        AuthenticatedPaths.LITIGATION,
        AuthenticatedPaths.DOCUMENT_CHANGES,
        AuthenticatedPaths.DOCUMENTS,
    ].some(path => routerLocation.pathname.indexOf(path) === 0);

    const assessmentsOpen =
        hasPermissionToReadAssessments && (submenus.assessments.open ?? assessmentsActive);
    const consentOpen = submenus.consent.open ?? consentActive;
    const ucOpen = hasPermissionToReadUnifiedConsent && (submenus.uc.open ?? ucActive);
    const dsarOpen = submenus.dsar.open ?? dsarActive;
    const dataMappingOpen = submenus.dataMapping.open ?? dataMappingActive;
    const reportsOpen = submenus.reports.open ?? reportsActive;
    const vendorManagementOpen = submenus.vendorManagement.open ?? vendorManagementActive;

    const handleToggleConsent = useCallback(
        force => {
            toggleSubmenu('consent', force);
        },
        [toggleSubmenu]
    );
    const handleToggleDSAR = useCallback(
        force => {
            toggleSubmenu('dsar', force);
        },
        [toggleSubmenu]
    );
    const handleToggleDataMapping = useCallback(
        force => {
            toggleSubmenu('dataMapping', force);
        },
        [toggleSubmenu]
    );
    const handleToggleReports = useCallback(
        force => {
            toggleSubmenu('reports', force);
        },
        [toggleSubmenu]
    );
    const handleToggleUc = useCallback(
        force => {
            if (hasPermissionToViewUnifiedConsent && hasPermissionToReadUnifiedConsent) {
                toggleSubmenu('uc', force);
            } else {
                history.push(AuthenticatedPaths.UC_CONFIGS);
            }
        },
        [toggleSubmenu, hasPermissionToViewUnifiedConsent, hasPermissionToReadUnifiedConsent]
    );
    const handleToggleVendorManagement = useCallback(
        force => {
            toggleSubmenu('vendorManagement', force);
        },
        [toggleSubmenu]
    );
    const handleToggleAssessments = useCallback(
        force => {
            if (hasPermissionToViewAssessments && hasPermissionToReadAssessments) {
                toggleSubmenu('assessments', force);
            } else {
                history.push(
                    hasPermissionToViewAssessments
                        ? AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS
                        : // Force navigate to assessments to trigger the Userpilot experience
                          AuthenticatedPaths.ASSESSMENTS
                );
            }
        },
        [hasPermissionToReadAssessments, hasPermissionToViewAssessments, toggleSubmenu]
    );

    const expandIcon = open => {
        return open ? (
            <ExpandLess className="expand-icon" />
        ) : (
            <ExpandMore className="expand-icon" />
        );
    };

    const [currentFlyout, setCurrentFlyout] = React.useState(null);
    const openFlyoutMenu = (event, title) => {
        setCurrentFlyout({ anchor: event.currentTarget, title });
    };

    const closeFlyoutMenu = () => {
        setCurrentFlyout(null);
    };

    return (
        <nav ref={sidebarRef} className={`${rootClassName} ${minified ? 'minified' : ''}`}>
            <Permission
                forRole
                toPerform="read"
                onAny={[
                    PERMISSIONS.CONSENTS,
                    PERMISSIONS.DSAR_DATASOURCE,
                    PERMISSIONS.DSAR_REQUEST,
                    PERMISSIONS.DATA_MAPPING,
                    PERMISSIONS.UNIFIED_CONSENT,
                ]}
            >
                <List key="management" component="div" className={classes.list} disablePadding>
                    {minified && (
                        <Divider className={classes.divider} variant="middle" component="li" />
                    )}
                    <Permission forRole toPerform="read" on={PERMISSIONS.CONSENTS}>
                        <FeatureFlag flag={ENABLE_CMP_DASHBOARD}>
                            <Off>
                                <LocalizedTooltip
                                    title={minified ? NAV_LABELS.consentManager : ''}
                                    placement="right-start"
                                >
                                    <ListItem
                                        activeClassName={classes.activeListItem}
                                        className={classes.listItem}
                                        component={NavLinkListItem}
                                        to={AuthenticatedPaths.CONSENT_MANAGER}
                                        isActive={(match, location) =>
                                            match
                                                ? true
                                                : location.pathname.indexOf(
                                                      `${AuthenticatedPaths.CONSENT_MANAGER}/`
                                                  ) === 0
                                        }
                                    >
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <ConsentIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.listItemText }}
                                            primary={<Locale path={NAV_LABELS.consentManager} />}
                                        />
                                    </ListItem>
                                </LocalizedTooltip>
                            </Off>
                            <On>
                                <LocalizedTooltip
                                    title={minified ? NAV_LABELS.consentManager : ''}
                                    placement="right-start"
                                >
                                    <ListItem
                                        className={classNames(
                                            classes.listMenu,
                                            classes.listItem,
                                            consentOpen ? classes.listMenuExpanded : '',
                                            consentActive ? classes.activeListItem : ''
                                        )}
                                        onClick={event =>
                                            minified
                                                ? openFlyoutMenu(event, NAV_LABELS.consentManager)
                                                : handleToggleConsent(!consentOpen)
                                        }
                                    >
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <ConsentIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.listItemText }}
                                            primary={<Locale path={NAV_LABELS.consentManager} />}
                                        />
                                        {expandIcon(consentOpen)}
                                    </ListItem>
                                </LocalizedTooltip>
                                {!minified ? (
                                    <Collapse
                                        in={consentOpen}
                                        timeout="auto"
                                        classes={{
                                            root: classNames(classes.submenu),
                                        }}
                                    >
                                        <List
                                            component="div"
                                            className={classes.submenuList}
                                            disablePadding
                                        >
                                            <ListItem
                                                key={AuthenticatedPaths.CONSENT_MANAGER}
                                                activeClassName={classes.activeListItem}
                                                classes={{
                                                    root: classNames(
                                                        classes.listItem,
                                                        classes.submenuItem
                                                    ),
                                                }}
                                                component={NavLinkListItem}
                                                to={AuthenticatedPaths.CONSENT_MANAGER}
                                                isActive={(match, location) =>
                                                    match
                                                        ? true
                                                        : location.pathname.indexOf(
                                                              `${AuthenticatedPaths.CONSENT_MANAGER}/`
                                                          ) === 0
                                                }
                                            >
                                                <ListItemText
                                                    classes={{
                                                        primary: classNames(
                                                            classes.submenuItemText,
                                                            classes.listItemText
                                                        ),
                                                    }}
                                                    primary={
                                                        <Locale
                                                            path={
                                                                SUBMENU_LABELS.commonConfigurations
                                                            }
                                                        />
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem
                                                key={AuthenticatedPaths.CONSENT_DASHBOARD}
                                                activeClassName={classes.activeListItem}
                                                classes={{
                                                    root: classNames(
                                                        classes.listItem,
                                                        classes.submenuItem
                                                    ),
                                                }}
                                                component={NavLinkListItem}
                                                to={AuthenticatedPaths.CONSENT_DASHBOARD}
                                                isActive={(match, location) =>
                                                    match
                                                        ? true
                                                        : location.pathname.indexOf(
                                                              `${AuthenticatedPaths.CONSENT_DASHBOARD}/`
                                                          ) === 0
                                                }
                                            >
                                                <ListItemText
                                                    classes={{
                                                        primary: classNames(
                                                            classes.submenuItemText,
                                                            classes.listItemText
                                                        ),
                                                    }}
                                                    primary={
                                                        <Locale
                                                            path={SUBMENU_LABELS.commonReporting}
                                                        />
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                ) : (
                                    <></>
                                )}
                            </On>
                        </FeatureFlag>
                    </Permission>
                    {hasPermissionToReadUnifiedConsent && hasPermissionToViewUnifiedConsent ? (
                        <>
                            <LocalizedTooltip
                                title={minified ? NAV_LABELS.unifiedConsent : ''}
                                placement="right-start"
                            >
                                <ListItem
                                    className={classNames(
                                        classes.listMenu,
                                        classes.listItem,
                                        ucOpen ? classes.listMenuExpanded : '',
                                        ucActive ? classes.activeListItem : ''
                                    )}
                                    onClick={event =>
                                        minified
                                            ? openFlyoutMenu(event, NAV_LABELS.unifiedConsent)
                                            : handleToggleUc(!ucOpen)
                                    }
                                >
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <UnifiedConsentIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{ primary: classes.listItemText }}
                                        primary={<Locale path={NAV_LABELS.unifiedConsent} />}
                                    />
                                    {expandIcon(ucOpen)}
                                </ListItem>
                            </LocalizedTooltip>
                            {!minified && (
                                <Collapse
                                    in={ucOpen}
                                    timeout="auto"
                                    classes={{
                                        root: classNames(classes.submenu),
                                    }}
                                >
                                    <List
                                        component="div"
                                        className={classes.submenuList}
                                        disablePadding
                                    >
                                        <ListItem
                                            key={AuthenticatedPaths.UC_PRIVACY_PROTOCOLS}
                                            activeClassName={classes.activeListItem}
                                            classes={{
                                                root: classNames(
                                                    classes.listItem,
                                                    classes.submenuItem
                                                ),
                                            }}
                                            component={NavLinkListItem}
                                            to={AuthenticatedPaths.UC_PRIVACY_PROTOCOLS}
                                            isActive={(match, location) =>
                                                match
                                                    ? true
                                                    : location.pathname.indexOf(
                                                          `${AuthenticatedPaths.UC_PRIVACY_PROTOCOLS}/`
                                                      ) === 0
                                            }
                                        >
                                            <ListItemText
                                                classes={{
                                                    primary: classNames(
                                                        classes.submenuItemText,
                                                        classes.listItemText
                                                    ),
                                                }}
                                                primary={
                                                    <Locale path={SUBMENU_LABELS.ucProtocols} />
                                                }
                                            />
                                        </ListItem>
                                        <ListItem
                                            key={AuthenticatedPaths.UC_CONFIGS}
                                            activeClassName={classes.activeListItem}
                                            classes={{
                                                root: classNames(
                                                    classes.listItem,
                                                    classes.submenuItem
                                                ),
                                            }}
                                            component={NavLinkListItem}
                                            to={AuthenticatedPaths.UC_CONFIGS}
                                            isActive={(match, location) =>
                                                match
                                                    ? true
                                                    : location.pathname.indexOf(
                                                          `${AuthenticatedPaths.UC_CONFIGS}/`
                                                      ) === 0
                                            }
                                        >
                                            <ListItemText
                                                classes={{
                                                    primary: classNames(
                                                        classes.submenuItemText,
                                                        classes.listItemText
                                                    ),
                                                }}
                                                primary={
                                                    <Locale
                                                        path={SUBMENU_LABELS.ucConfigurations}
                                                    />
                                                }
                                            />
                                        </ListItem>
                                        <ListItem
                                            key={AuthenticatedPaths.UC_INTEGRATIONS}
                                            activeClassName={classes.activeListItem}
                                            classes={{
                                                root: classNames(
                                                    classes.listItem,
                                                    classes.submenuItem
                                                ),
                                            }}
                                            component={NavLinkListItem}
                                            to={AuthenticatedPaths.UC_INTEGRATIONS}
                                            isActive={(match, location) =>
                                                match
                                                    ? true
                                                    : location.pathname.indexOf(
                                                          `${AuthenticatedPaths.UC_INTEGRATIONS}/`
                                                      ) === 0
                                            }
                                        >
                                            <ListItemText
                                                classes={{
                                                    primary: classNames(
                                                        classes.submenuItemText,
                                                        classes.listItemText
                                                    ),
                                                }}
                                                primary={
                                                    <Locale path={SUBMENU_LABELS.ucIntegrations} />
                                                }
                                            />
                                        </ListItem>
                                        <FeatureFlag flag={ENABLE_UC_DASHBOARD}>
                                            <On>
                                                <ListItem
                                                    key={AuthenticatedPaths.UC_DASHBOARD}
                                                    activeClassName={classes.activeListItem}
                                                    classes={{
                                                        root: classNames(
                                                            classes.listItem,
                                                            classes.submenuItem
                                                        ),
                                                    }}
                                                    component={NavLinkListItem}
                                                    to={AuthenticatedPaths.UC_DASHBOARD}
                                                    isActive={(match, location) =>
                                                        match
                                                            ? true
                                                            : location.pathname.indexOf(
                                                                  `${AuthenticatedPaths.UC_DASHBOARD}/`
                                                              ) === 0
                                                    }
                                                >
                                                    <ListItemText
                                                        classes={{
                                                            primary: classNames(
                                                                classes.submenuItemText,
                                                                classes.listItemText
                                                            ),
                                                        }}
                                                        primary={
                                                            <Locale
                                                                path={
                                                                    SUBMENU_LABELS.commonReporting
                                                                }
                                                            />
                                                        }
                                                    />
                                                </ListItem>
                                            </On>
                                        </FeatureFlag>
                                    </List>
                                </Collapse>
                            )}
                        </>
                    ) : null}
                    <Permission
                        forRole
                        toPerform="read"
                        onAny={[PERMISSIONS.DSAR_DATASOURCE, PERMISSIONS.DSAR_REQUEST]}
                    >
                        <LocalizedTooltip
                            title={minified ? NAV_LABELS.dsar : ''}
                            placement="right-start"
                        >
                            <ListItem
                                key="DSAR"
                                className={classNames(
                                    classes.listMenu,
                                    classes.listItem,
                                    dsarOpen ? classes.listMenuExpanded : '',
                                    dsarActive ? classes.activeListItem : ''
                                )}
                                onClick={event =>
                                    minified
                                        ? openFlyoutMenu(event, NAV_LABELS.dsar)
                                        : handleToggleDSAR(!dsarOpen)
                                }
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <DSARIcon />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary={<Locale path={NAV_LABELS.dsar} />}
                                />
                                {expandIcon(dsarOpen)}
                            </ListItem>
                        </LocalizedTooltip>
                        {!minified && (
                            <Collapse
                                in={dsarOpen}
                                timeout="auto"
                                classes={{
                                    root: classNames(classes.submenu),
                                }}
                            >
                                <List
                                    component="div"
                                    className={classes.submenuList}
                                    disablePadding
                                >
                                    <Permission
                                        forRole
                                        toPerform="read"
                                        on={PERMISSIONS.DSAR_DATASOURCE}
                                    >
                                        <ListItem
                                            key={AuthenticatedPaths.DSAR_ACTION_ITEMS}
                                            activeClassName={classes.activeListItem}
                                            classes={{
                                                root: classNames(
                                                    classes.listItem,
                                                    classes.submenuItem
                                                ),
                                            }}
                                            component={NavLinkListItem}
                                            to={`${AuthenticatedPaths.DSAR_ACTION_ITEMS}`}
                                            isActive={(match, location) =>
                                                match
                                                    ? true
                                                    : location.pathname.indexOf(
                                                          `${AuthenticatedPaths.DSAR_ACTION_ITEMS}/`
                                                      ) === 0
                                            }
                                        >
                                            <ListItemText
                                                classes={{
                                                    primary: classNames(
                                                        classes.submenuItemText,
                                                        classes.listItemText
                                                    ),
                                                }}
                                                primary={
                                                    <Locale path={SUBMENU_LABELS.dsarActionItems} />
                                                }
                                            />
                                        </ListItem>
                                    </Permission>
                                    <Permission
                                        forRole
                                        toPerform="read"
                                        on={PERMISSIONS.DSAR_REQUEST}
                                    >
                                        <ListItem
                                            key={`${AuthenticatedPaths.DSAR_REQUESTS}`}
                                            activeClassName={classes.activeListItem}
                                            classes={{
                                                root: classNames(
                                                    classes.listItem,
                                                    classes.submenuItem
                                                ),
                                            }}
                                            component={NavLinkListItem}
                                            to={`${AuthenticatedPaths.DSAR_REQUESTS}`}
                                            isActive={(match, location) =>
                                                match
                                                    ? true
                                                    : location.pathname.indexOf(
                                                          `${AuthenticatedPaths.DSAR_REQUESTS}/`
                                                      ) === 0
                                            }
                                        >
                                            <ListItemText
                                                classes={{
                                                    primary: classNames(
                                                        classes.submenuItemText,
                                                        classes.listItemText
                                                    ),
                                                }}
                                                primary={
                                                    <Locale path={SUBMENU_LABELS.dsarRequests} />
                                                }
                                            />
                                        </ListItem>
                                        <ListItem
                                            key={AuthenticatedPaths.DSAR_FORMS}
                                            activeClassName={classes.activeListItem}
                                            classes={{
                                                root: classNames(
                                                    classes.listItem,
                                                    classes.submenuItem
                                                ),
                                            }}
                                            component={NavLinkListItem}
                                            to={AuthenticatedPaths.DSAR_FORMS}
                                            isActive={(match, location) =>
                                                match
                                                    ? true
                                                    : location.pathname.indexOf(
                                                          `${AuthenticatedPaths.DSAR_FORMS}/`
                                                      ) === 0
                                            }
                                        >
                                            <ListItemText
                                                classes={{
                                                    primary: classNames(
                                                        classes.submenuItemText,
                                                        classes.listItemText
                                                    ),
                                                }}
                                                primary={<Locale path={SUBMENU_LABELS.dsarForms} />}
                                            />
                                        </ListItem>
                                    </Permission>
                                    <FeatureFlag flag={ENABLE_DSAR_REPORTING}>
                                        <On>
                                            <Permission
                                                forRole
                                                toPerform="read"
                                                on={PERMISSIONS.DSAR_REQUEST}
                                            >
                                                <ListItem
                                                    key={AuthenticatedPaths.DSAR_REPORTING}
                                                    activeClassName={classes.activeListItem}
                                                    classes={{
                                                        root: classNames(
                                                            classes.listItem,
                                                            classes.submenuItem
                                                        ),
                                                    }}
                                                    component={NavLinkListItem}
                                                    to={AuthenticatedPaths.DSAR_REPORTING}
                                                    isActive={(match, location) =>
                                                        match
                                                            ? true
                                                            : location.pathname.indexOf(
                                                                  `${AuthenticatedPaths.DSAR_REPORTING}/`
                                                              ) === 0
                                                    }
                                                >
                                                    <ListItemText
                                                        classes={{
                                                            primary: classNames(
                                                                classes.submenuItemText,
                                                                classes.listItemText
                                                            ),
                                                        }}
                                                        primary={
                                                            <Locale
                                                                path={
                                                                    SUBMENU_LABELS.commonReporting
                                                                }
                                                            />
                                                        }
                                                    />
                                                </ListItem>
                                            </Permission>
                                        </On>
                                    </FeatureFlag>
                                </List>
                            </Collapse>
                        )}
                    </Permission>
                    <Permission
                        forRole
                        toPerform="read"
                        onAny={[
                            PERMISSIONS.DATA_MAPPING,
                            PERMISSIONS.DATA_DISCOVERY,
                            PERMISSIONS.DSAR_REQUEST,
                        ]}
                    >
                        <LocalizedTooltip
                            title={minified ? NAV_LABELS.dataMapping : ''}
                            placement="right-start"
                        >
                            <ListItem
                                className={classNames(
                                    classes.listMenu,
                                    classes.listItem,
                                    dataMappingOpen ? classes.listMenuExpanded : '',
                                    dataMappingActive ? classes.activeListItem : ''
                                )}
                                onClick={event =>
                                    minified
                                        ? openFlyoutMenu(event, NAV_LABELS.dataMapping)
                                        : handleToggleDataMapping(!dataMappingOpen)
                                }
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <DataMappingIcon />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary={
                                        <FeatureFlag flag={ENABLE_DISCOVERY_SOURCES}>
                                            <On>
                                                <Locale path={NAV_LABELS.dataMapping} />
                                            </On>
                                            <Off>
                                                <Locale path={NAV_LABELS.dataDiscovery} />
                                            </Off>
                                        </FeatureFlag>
                                    }
                                />
                                {expandIcon(dataMappingOpen)}
                            </ListItem>
                        </LocalizedTooltip>
                        {!minified && (
                            <Collapse
                                in={dataMappingOpen}
                                timeout="auto"
                                unmountOnExit
                                classes={{
                                    root: classNames(classes.submenu),
                                }}
                            >
                                <List
                                    component="div"
                                    className={classes.submenuList}
                                    disablePadding
                                >
                                    {canReadDataMapping && (
                                        <FeatureFlag flag={ENABLE_DISCOVERY_SOURCES}>
                                            <On>
                                                <ListItem
                                                    activeClassName={classes.activeListItem}
                                                    classes={{
                                                        root: classNames(
                                                            classes.listItem,
                                                            classes.submenuItem
                                                        ),
                                                    }}
                                                    component={NavLinkListItem}
                                                    to={`${AuthenticatedPaths.DATA_MAPPING_SOURCES}`}
                                                    isActive={(match, location) =>
                                                        match
                                                            ? true
                                                            : location.pathname.indexOf(
                                                                  `${AuthenticatedPaths.DATA_MAPPING_SOURCES}/`
                                                              ) === 0
                                                    }
                                                >
                                                    <ListItemText
                                                        classes={{
                                                            primary: classNames(
                                                                classes.submenuItemText,
                                                                classes.listItemText
                                                            ),
                                                        }}
                                                        primary={
                                                            <Locale
                                                                path={SUBMENU_LABELS.dataSources}
                                                            />
                                                        }
                                                    />
                                                </ListItem>
                                                <ListItem
                                                    activeClassName={classes.activeListItem}
                                                    classes={{
                                                        root: classNames(
                                                            classes.listItem,
                                                            classes.submenuItem
                                                        ),
                                                    }}
                                                    component={NavLinkListItem}
                                                    to={`${AuthenticatedPaths.DATA_MAPPING_APPLICATIONS}`}
                                                    isActive={(match, location) =>
                                                        match
                                                            ? true
                                                            : location.pathname.indexOf(
                                                                  `${AuthenticatedPaths.DATA_MAPPING_APPLICATIONS}/`
                                                              ) === 0
                                                    }
                                                >
                                                    <ListItemText
                                                        classes={{
                                                            primary: classNames(
                                                                classes.submenuItemText,
                                                                classes.listItemText
                                                            ),
                                                        }}
                                                        primary={
                                                            <Locale
                                                                path={
                                                                    SUBMENU_LABELS.dataApplications
                                                                }
                                                            />
                                                        }
                                                    />
                                                </ListItem>
                                            </On>
                                        </FeatureFlag>
                                    )}
                                    {(canReadDataMapping || canManageSubjectRights) && (
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            classes={{
                                                root: classNames(
                                                    classes.listItem,
                                                    classes.submenuItem
                                                ),
                                            }}
                                            component={NavLinkListItem}
                                            to={`${AuthenticatedPaths.DATA_STORES}`}
                                            isActive={(match, location) =>
                                                match
                                                    ? true
                                                    : location.pathname.indexOf(
                                                          `${AuthenticatedPaths.DATA_STORES}/`
                                                      ) === 0
                                            }
                                        >
                                            <ListItemText
                                                classes={{
                                                    primary: classNames(
                                                        classes.submenuItemText,
                                                        classes.listItemText
                                                    ),
                                                }}
                                                primary={
                                                    <Locale path={SUBMENU_LABELS.dataProviders} />
                                                }
                                            />
                                        </ListItem>
                                    )}
                                    {canReadDataMapping && (
                                        <ListItem
                                            activeClassName={classes.activeListItem}
                                            classes={{
                                                root: classNames(
                                                    classes.listItem,
                                                    classes.submenuItem
                                                ),
                                            }}
                                            component={NavLinkListItem}
                                            to={`${AuthenticatedPaths.DATA_MAP}`}
                                            isActive={(match, location) =>
                                                match
                                                    ? true
                                                    : location.pathname.indexOf(
                                                          `${AuthenticatedPaths.DATA_MAP}/`
                                                      ) === 0
                                            }
                                        >
                                            <ListItemText
                                                classes={{
                                                    primary: classNames(
                                                        classes.submenuItemText,
                                                        classes.listItemText
                                                    ),
                                                }}
                                                primary={<Locale path={SUBMENU_LABELS.dataMap} />}
                                            />
                                        </ListItem>
                                    )}
                                </List>
                            </Collapse>
                        )}
                    </Permission>
                </List>
            </Permission>
            <List component="div" className={classes.list} disablePadding>
                <LocalizedTooltip title={minified ? NAV_LABELS.vendor : ''} placement="right-start">
                    <ListItem
                        className={classNames(
                            classes.listMenu,
                            classes.listItem,
                            vendorManagementOpen ? classes.listMenuExpanded : '',
                            vendorManagementActive ? classes.activeListItem : ''
                        )}
                        onClick={event =>
                            minified
                                ? openFlyoutMenu(event, NAV_LABELS.vendor)
                                : handleToggleVendorManagement(!vendorManagementOpen)
                        }
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <VendorIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary={<Locale path={NAV_LABELS.vendor} />}
                        />
                        {expandIcon(vendorManagementOpen)}
                    </ListItem>
                </LocalizedTooltip>
                {!minified && (
                    <Collapse
                        in={vendorManagementOpen}
                        timeout="auto"
                        unmountOnExit
                        classes={{
                            root: classNames(classes.submenu),
                        }}
                    >
                        <List component="div" className={classes.submenuList} disablePadding>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                classes={{
                                    root: classNames(classes.listItem, classes.submenuItem),
                                }}
                                component={NavLinkListItem}
                                to={AuthenticatedPaths.VENDORS}
                                isActive={(match, location) =>
                                    match
                                        ? true
                                        : location.pathname.indexOf(
                                              `${AuthenticatedPaths.VENDORS}/`
                                          ) === 0
                                }
                            >
                                <ListItemText
                                    classes={{
                                        primary: classNames(
                                            classes.submenuItemText,
                                            classes.listItemText
                                        ),
                                    }}
                                    primary={<Locale path={SUBMENU_LABELS.vmVendors} />}
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                classes={{
                                    root: classNames(classes.listItem, classes.submenuItem),
                                }}
                                component={NavLinkListItem}
                                to={AuthenticatedPaths.LITIGATION}
                                isActive={(match, location) =>
                                    match
                                        ? true
                                        : location.pathname.indexOf(
                                              `${AuthenticatedPaths.LITIGATION}/`
                                          ) === 0
                                }
                            >
                                <ListItemText
                                    classes={{
                                        primary: classNames(
                                            classes.submenuItemText,
                                            classes.listItemText
                                        ),
                                    }}
                                    primary={<Locale path={SUBMENU_LABELS.vmLitigation} />}
                                />
                            </ListItem>
                            <ListItem
                                activeClassName={classes.activeListItem}
                                classes={{
                                    root: classNames(classes.listItem, classes.submenuItem),
                                }}
                                component={NavLinkListItem}
                                to={AuthenticatedPaths.DOCUMENT_CHANGES}
                                isActive={(match, location) =>
                                    match
                                        ? true
                                        : location.pathname.indexOf(
                                              `${AuthenticatedPaths.DOCUMENT_CHANGES}/`
                                          ) === 0 ||
                                          location.pathname.indexOf(
                                              `${AuthenticatedPaths.DOCUMENTS}/`
                                          ) === 0
                                }
                            >
                                <ListItemText
                                    classes={{
                                        primary: classNames(
                                            classes.submenuItemText,
                                            classes.listItemText
                                        ),
                                    }}
                                    primary={<Locale path={SUBMENU_LABELS.vmPolicyChanges} />}
                                />
                            </ListItem>
                        </List>
                    </Collapse>
                )}
            </List>
            <List component="div" className={classes.list} disablePadding>
                <LocalizedTooltip
                    title={minified ? NAV_LABELS.assessments : ''}
                    placement="right-start"
                >
                    <ListItem
                        className={classNames(
                            classes.listMenu,
                            classes.listItem,
                            assessmentsOpen ? classes.listMenuExpanded : '',
                            assessmentsActive ? classes.activeListItem : ''
                        )}
                        onClick={event =>
                            minified
                                ? openFlyoutMenu(event, NAV_LABELS.assessments)
                                : handleToggleAssessments(!assessmentsOpen)
                        }
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <AssessmentIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary={<Locale path={NAV_LABELS.assessments} />}
                        />
                        {!hasPermissionToViewAssessments || !hasPermissionToReadAssessments
                            ? null
                            : expandIcon(assessmentsOpen)}
                    </ListItem>
                </LocalizedTooltip>
                <Permission toPerform="view" on={AuthenticatedPaths.ASSESSMENTS}>
                    {!minified && (
                        <Collapse
                            in={assessmentsOpen}
                            timeout="auto"
                            unmountOnExit
                            classes={{
                                root: classNames(classes.submenu),
                            }}
                        >
                            <List component="div" className={classes.submenuList} disablePadding>
                                <Permission
                                    forRole
                                    toPerform="read"
                                    onAny={[PERMISSIONS.ADMIN, PERMISSIONS.ASSESSMENT]}
                                >
                                    <ListItem
                                        activeClassName={classes.activeListItem}
                                        classes={{
                                            root: classNames(classes.listItem, classes.submenuItem),
                                        }}
                                        component={NavLinkListItem}
                                        to={AuthenticatedPaths.ASSESSMENTS}
                                        isActive={(match, location) =>
                                            location.pathname.indexOf(
                                                `${AuthenticatedPaths.ASSESSMENTS}`
                                            ) === 0 &&
                                            location.pathname.indexOf(
                                                `${AuthenticatedPaths.ASSESSMENT_TEMPLATES}`
                                            ) !== 0 &&
                                            location.pathname.indexOf(
                                                `${AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS}`
                                            ) !== 0 &&
                                            location.pathname.indexOf(
                                                `${AuthenticatedPaths.ASSESSMENTS_REPORTING}`
                                            ) !== 0
                                        }
                                    >
                                        <ListItemText
                                            classes={{
                                                primary: classNames(
                                                    classes.submenuItemText,
                                                    classes.listItemText
                                                ),
                                            }}
                                            primary={<Locale path={NAV_LABELS.assessments} />}
                                        />
                                    </ListItem>
                                    <FeatureFlag flag={ENABLE_CUSTOM_ASSESSMENTS}>
                                        <On>
                                            <ListItem
                                                activeClassName={classes.activeListItem}
                                                classes={{
                                                    root: classNames(
                                                        classes.listItem,
                                                        classes.submenuItem
                                                    ),
                                                }}
                                                component={NavLinkListItem}
                                                to={AuthenticatedPaths.ASSESSMENT_TEMPLATES}
                                                isActive={(match, location) =>
                                                    location.pathname.indexOf(
                                                        `${AuthenticatedPaths.ASSESSMENT_TEMPLATES}`
                                                    ) === 0
                                                }
                                            >
                                                <ListItemText
                                                    classes={{
                                                        primary: classNames(
                                                            classes.submenuItemText,
                                                            classes.listItemText
                                                        ),
                                                    }}
                                                    primary={
                                                        <Locale
                                                            path={
                                                                SUBMENU_LABELS.assessmentTemplates
                                                            }
                                                        />
                                                    }
                                                />
                                            </ListItem>
                                        </On>
                                    </FeatureFlag>
                                </Permission>
                                <ListItem
                                    activeClassName={classes.activeListItem}
                                    classes={{
                                        root: classNames(classes.listItem, classes.submenuItem),
                                    }}
                                    component={NavLinkListItem}
                                    to={AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS}
                                    isActive={(match, location) =>
                                        location.pathname.indexOf(
                                            `${AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS}`
                                        ) === 0
                                    }
                                >
                                    <ListItemText
                                        classes={{
                                            primary: classNames(
                                                classes.submenuItemText,
                                                classes.listItemText
                                            ),
                                        }}
                                        primary={
                                            <Locale path={SUBMENU_LABELS.assessmentAssignments} />
                                        }
                                    />
                                </ListItem>
                                <FeatureFlag flag={ENABLE_ASSESSMENTS_REPORTING}>
                                    <On>
                                        <Permission
                                            forRole
                                            toPerform="read"
                                            on={PERMISSIONS.ASSESSMENT}
                                        >
                                            <ListItem
                                                activeClassName={classes.activeListItem}
                                                classes={{
                                                    root: classNames(
                                                        classes.listItem,
                                                        classes.submenuItem
                                                    ),
                                                }}
                                                component={NavLinkListItem}
                                                to={AuthenticatedPaths.ASSESSMENTS_REPORTING}
                                                isActive={(match, location) =>
                                                    location.pathname.indexOf(
                                                        `${AuthenticatedPaths.ASSESSMENTS_REPORTING}`
                                                    ) === 0
                                                }
                                            >
                                                <ListItemText
                                                    classes={{
                                                        primary: classNames(
                                                            classes.submenuItemText,
                                                            classes.listItemText
                                                        ),
                                                    }}
                                                    primary={
                                                        <Locale
                                                            path={
                                                                SUBMENU_LABELS.assessmentReporting
                                                            }
                                                        />
                                                    }
                                                />
                                            </ListItem>
                                        </Permission>
                                    </On>
                                </FeatureFlag>
                            </List>
                        </Collapse>
                    )}
                </Permission>
            </List>
            <Permission
                forRole
                toPerform="read"
                onAny={[
                    PERMISSIONS.ADMIN,
                    PERMISSIONS.ADVISORY,
                    PERMISSIONS.CONSENTS,
                    PERMISSIONS.DSAR_REQUEST,
                    PERMISSIONS.MONITORING,
                    PERMISSIONS.TRUSTHUB,
                ]}
            >
                <List
                    className={classes.list}
                    component="div"
                    disablePadding
                    subheader={
                        !minified ? (
                            <ListSubheader className={classes.listSubheader}>
                                <Locale
                                    path={'layouts.Dashboard.components.Sidebar.services.label'}
                                />
                            </ListSubheader>
                        ) : (
                            <Divider className={classes.divider} variant="middle" component="li" />
                        )
                    }
                >
                    <FeatureFlag flag={REPORTS_FEATURE_FLAG}>
                        <On>
                            <Permission
                                forRole
                                toPerform="write"
                                onAny={[
                                    PERMISSIONS.CONSENTS,
                                    PERMISSIONS.DSAR_REQUEST,
                                    PERMISSIONS.MONITORING,
                                ]}
                            >
                                <LocalizedTooltip
                                    title={minified ? NAV_LABELS.reports : ''}
                                    placement="right-start"
                                >
                                    <ListItem
                                        className={classNames(
                                            classes.listMenu,
                                            classes.listItem,
                                            reportsOpen ? classes.listMenuExpanded : '',
                                            reportsActive ? classes.activeListItem : ''
                                        )}
                                        onClick={event =>
                                            minified
                                                ? openFlyoutMenu(event, NAV_LABELS.reports)
                                                : handleToggleReports(!reportsOpen)
                                        }
                                    >
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <ReportsIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.listItemText }}
                                            primary={<Locale path={NAV_LABELS.reports} />}
                                        />
                                        {expandIcon(reportsOpen)}
                                    </ListItem>
                                </LocalizedTooltip>
                                {!minified && (
                                    <Collapse
                                        in={reportsOpen}
                                        timeout="auto"
                                        classes={{
                                            root: classNames(classes.submenu),
                                        }}
                                    >
                                        <List component="div" disablePadding>
                                            <Permission
                                                forRole
                                                toPerform="write"
                                                on={PERMISSIONS.CONSENTS}
                                            >
                                                <ListItem
                                                    activeClassName={classes.activeListItem}
                                                    classes={{
                                                        root: classNames(
                                                            classes.listItem,
                                                            classes.submenuItem
                                                        ),
                                                    }}
                                                    component={NavLinkListItem}
                                                    to={AuthenticatedPaths.REPORTS_CONSENT}
                                                    isActive={(match, location) =>
                                                        match
                                                            ? true
                                                            : location.pathname.indexOf(
                                                                  AuthenticatedPaths.REPORTS_CONSENT
                                                              ) === 0
                                                    }
                                                >
                                                    <ListItemText
                                                        classes={{
                                                            primary: classNames(
                                                                classes.submenuItemText,
                                                                classes.listItemText
                                                            ),
                                                        }}
                                                        primary={
                                                            <Locale
                                                                path={SUBMENU_LABELS.reportsConsent}
                                                            />
                                                        }
                                                    />
                                                </ListItem>
                                            </Permission>
                                            <Permission
                                                forRole
                                                toPerform="write"
                                                on={PERMISSIONS.DSAR_REQUEST}
                                            >
                                                <ListItem
                                                    activeClassName={classes.activeListItem}
                                                    classes={{
                                                        root: classNames(
                                                            classes.listItem,
                                                            classes.submenuItem
                                                        ),
                                                    }}
                                                    component={NavLinkListItem}
                                                    to={AuthenticatedPaths.REPORTS_DATASUBJECT}
                                                    isActive={(match, location) =>
                                                        match
                                                            ? true
                                                            : location.pathname.indexOf(
                                                                  /* eslint-disable-next-line  max-len */
                                                                  AuthenticatedPaths.REPORTS_DATASUBJECT
                                                              ) === 0
                                                    }
                                                >
                                                    <ListItemText
                                                        classes={{
                                                            primary: classNames(
                                                                classes.submenuItemText,
                                                                classes.listItemText
                                                            ),
                                                        }}
                                                        primary={
                                                            <Locale
                                                                path={SUBMENU_LABELS.reportsDsar}
                                                            />
                                                        }
                                                    />
                                                </ListItem>
                                            </Permission>
                                            <Permission
                                                forRole
                                                toPerform="write"
                                                on={PERMISSIONS.MONITORING}
                                            >
                                                <ListItem
                                                    activeClassName={classes.activeListItem}
                                                    classes={{
                                                        root: classNames(
                                                            classes.listItem,
                                                            classes.submenuItem
                                                        ),
                                                    }}
                                                    component={NavLinkListItem}
                                                    to={AuthenticatedPaths.REPORTS_VENDOR}
                                                    isActive={(match, location) =>
                                                        match
                                                            ? true
                                                            : location.pathname.indexOf(
                                                                  AuthenticatedPaths.REPORTS_VENDOR
                                                              ) === 0
                                                    }
                                                >
                                                    <ListItemText
                                                        classes={{
                                                            primary: classNames(
                                                                classes.submenuItemText,
                                                                classes.listItemText
                                                            ),
                                                        }}
                                                        primary={
                                                            <Locale
                                                                path={SUBMENU_LABELS.reportsVendor}
                                                            />
                                                        }
                                                    />
                                                </ListItem>
                                            </Permission>
                                        </List>
                                    </Collapse>
                                )}
                            </Permission>
                        </On>
                    </FeatureFlag>
                    <Permission toPerform="view" on={AuthenticatedPaths.TRUSTHUB}>
                        <Permission forRole toPerform="read" on={PERMISSIONS.TRUSTHUB}>
                            <List component="div" className={classes.list} disablePadding>
                                <LocalizedTooltip
                                    title={minified ? NAV_LABELS.trusthub : ''}
                                    placement="right-start"
                                >
                                    <ListItem
                                        activeClassName={classes.activeListItem}
                                        className={classes.listItem}
                                        component={NavLinkListItem}
                                        to={AuthenticatedPaths.TRUSTHUB}
                                    >
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <TrusthubIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.listItemText }}
                                            primary={<Locale path={NAV_LABELS.trusthub} />}
                                        />
                                    </ListItem>
                                </LocalizedTooltip>
                            </List>
                        </Permission>
                    </Permission>
                    <List component="div" className={classes.list} disablePadding>
                        <LocalizedTooltip
                            title={minified ? NAV_LABELS.privacyTemplates : ''}
                            placement="right-start"
                        >
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLinkListItem}
                                to={`${AuthenticatedPaths.RESOURCES}/templates`}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <TemplatesIcon />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary={<Locale path={NAV_LABELS.privacyTemplates} />}
                                />
                            </ListItem>
                        </LocalizedTooltip>
                    </List>

                    {canManageVendors && (
                        <LocalizedTooltip
                            title={minified ? NAV_LABELS.regulatoryGuidance : ''}
                            placement="right-start"
                        >
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLinkListItem}
                                to={AuthenticatedPaths.LEGISLATION}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <LegislationIcon />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary={<Locale path={NAV_LABELS.regulatoryGuidance} />}
                                />
                            </ListItem>
                        </LocalizedTooltip>
                    )}

                    <Permission
                        forRole
                        toPerform="read"
                        onAny={[PERMISSIONS.ADMIN, PERMISSIONS.DSAR_REQUEST]}
                    >
                        <LocalizedTooltip
                            title={minified ? NAV_LABELS.gdprRepresentative : ''}
                            placement="right-start"
                        >
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLinkListItem}
                                to={AuthenticatedPaths.GDPR}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <GDPRIcon />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary={<Locale path={NAV_LABELS.gdprRepresentative} />}
                                />
                            </ListItem>
                        </LocalizedTooltip>
                    </Permission>
                    <Permission forRole toPerform="read" on={PERMISSIONS.ADVISORY}>
                        <LocalizedTooltip
                            title={minified ? NAV_LABELS.consultPrivacyTeam : ''}
                            placement="right-start"
                        >
                            <ListItem
                                activeClassName={classes.activeListItem}
                                className={classes.listItem}
                                component={NavLinkListItem}
                                to={AuthenticatedPaths.ASK_ANALYST}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AskExpertIcon />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary={<Locale path={NAV_LABELS.consultPrivacyTeam} />}
                                />
                            </ListItem>
                        </LocalizedTooltip>
                    </Permission>
                    <FeatureFlag flag={ENABLE_COPILOT}>
                        <On>
                            <Permission forRole toPerform="read" on={PERMISSIONS.ADVISORY}>
                                <LocalizedTooltip title="COPILOT" placement="right-start">
                                    <ListItem
                                        activeClassName={classes.activeListItem}
                                        className={classes.listItem}
                                        component={NavLinkListItem}
                                        to={AuthenticatedPaths.COPILOT}
                                    >
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <AskExpertIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.listItemText }}
                                            primary="COPILOT"
                                        />
                                    </ListItem>
                                </LocalizedTooltip>
                            </Permission>
                        </On>
                    </FeatureFlag>
                </List>
            </Permission>
            {minified && (
                <SidebarPopperMenu
                    anchorEl={currentFlyout?.anchor}
                    onClose={closeFlyoutMenu}
                    title={currentFlyout?.title}
                    rolePermissions={{ canReadDataMapping, canManageSubjectRights }}
                />
            )}
        </nav>
    );
});

Sidebar.propTypes = {
    canManageSubjectRights: PropTypes.bool,
    canManageVendors: PropTypes.bool,
    canReadDataMapping: PropTypes.bool,
    className: PropTypes.string,
    hasPermissionToReadAssessments: PropTypes.bool,
    hasPermissionToReadConsent: PropTypes.bool,
    hasPermissionToReadUnifiedConsent: PropTypes.bool,
    hasPermissionToViewAssessments: PropTypes.bool,
    hasPermissionToViewUnifiedConsent: PropTypes.bool,
    location: PropTypes.object,
    minified: PropTypes.bool,
    submenus: PropTypes.shape({
        assessments: PropTypes.shape({
            open: PropTypes.bool,
        }),
        consent: PropTypes.shape({
            open: PropTypes.bool,
        }),
        dataMapping: PropTypes.shape({
            open: PropTypes.bool,
        }),
        dsar: PropTypes.shape({
            open: PropTypes.bool,
        }),
        reports: PropTypes.shape({
            open: PropTypes.bool,
        }),
        uc: PropTypes.shape({
            open: PropTypes.bool,
        }),
        vendorManagement: PropTypes.shape({
            open: PropTypes.bool,
        }),
    }),
    toggleSubmenu: PropTypes.func,
};

export default Sidebar;
