import { fetchCustomerDetails, signOut, triggerUserPilot } from './Routes.commands';

import Component from './Routes.component';
import { connect } from 'react-redux';
import fromState from '../common/state/selectors';

const mapStateToProps = state => {
    return {
        asyncLoading: fromState.UI.isLoading(state),
        customerId: fromState.Auth.customer.getId(state),
        customerToken: fromState.Auth.getCustomerToken(state),
        documentTitle: fromState.UI.getDocumentTitle(state),
        isAuthenticated: fromState.Auth.isAuthenticated(state),
        isCustomerSelected: fromState.Auth.isCustomerSelected(state),
        permissionsDetermined: fromState.Auth.getPermissionsDetermined(state),
    };
};

const mapDispatchToProps = {
    fetchCustomerDetails,
    signOut,
    triggerUserPilot,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
