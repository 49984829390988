import classifications, {
    initialState as initialClassifications,
} from './classifications/DataStoreClassifications.reducer';
import requestActions, {
    initialState as initialRequestActions,
} from './requestActions/DataStoreRequestActions.reducer';

import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import { combineReducers } from 'redux';
import { genericReducer } from '../../common/helpers/ReduxHelpers';
import reduceReducers from 'reduce-reducers';

export const initialState = {
    classifications: initialClassifications,
    requestActions: initialRequestActions,
};

export default reduceReducers(
    initialState,
    combineReducers({
        classifications,
        requestActions,
    }),
    genericReducer(initialState, {
        [AuthActionTypes.logout]: () => initialState,
    })
);
