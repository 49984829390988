import { Chip, Autocomplete as MuiAutocomplete, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Locale from '/b2b/common/components/Locale';
import PropTypes from 'prop-types';
import castArray from 'lodash/castArray';
import debounce from 'lodash/debounce';
import isArray from 'lodash/isArray';
import makeStyles from '@mui/styles/makeStyles';
import merge from 'lodash/merge';
import styles from './ValidationAutocomplete.styles';
import { translate } from '/b2b/common/helpers/i18n';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';

const useStyles = makeStyles(styles);
const emptyArray = [];

const addCurrentValueToOptions = (value, options) => {
    let newOptions = emptyArray;
    if (value) {
        newOptions = [...castArray(value)];
    }
    if (options) {
        newOptions = [...newOptions, ...options];
    }
    return newOptions;
};

const ValidationAutocomplete = props => {
    const currentValue = useRef(null);
    const {
        autoSelect,
        blurOnSelect,
        component,
        disabled,
        required,
        error,
        freeSolo,
        getOptionLabel: providedGetOptionLabel,
        isOptionEqualToValue: providedIsOptionEqualToValue,
        label,
        limit = 10,
        helperText,
        onBlur,
        onChange,
        onFocus,
        onRequestResults,
        value: providedValue = currentValue.current,
        minLength = 0,
        multiple = false,
        name,
        options,
        placeholder,
        selectOnFocus,
        newChipKeyCodes = emptyArray,
        renderOption: providedRenderOption,
        renderTags: providedTagRenderer,
        inputProps,
        InputProps,
        InputLabelProps,
        sortOptions = true,
    } = props;
    const {
        label: value = typeof providedValue === 'string' ? providedValue : '',
        value: optionId = '',
    } = (!isArray(providedValue) && providedValue) || {
        value: '',
        label: '',
    };
    const classes = useStyles(props);
    const Component = component || TextField;
    const inputValue = useRef(value || undefined);
    const [results, setResults] = useState(options || emptyArray);
    const fetchResults = useRef(null);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const memoizedIsOptionEqualToValue = useCallback(
        (option, value) =>
            !!castArray(value).find(toFind =>
                typeof option === 'string'
                    ? option === toFind
                    : (toFind?.label || toFind) === (option?.label || option) ||
                      (toFind?.value || toFind) === (option?.value || option)
            ),
        []
    );
    const isOptionEqualToValue = providedIsOptionEqualToValue || memoizedIsOptionEqualToValue;
    const memoizedGetOptionLabel = useBoundCallback(
        (isOptionEqualToValue, options = [], option) => {
            return option && typeof option === 'object'
                ? option?.label?.type === Locale
                    ? translate(option.label.props.path)
                    : translate(option?.label || option?.value || '')
                : options.find(toTest => isOptionEqualToValue(toTest, option))?.label ||
                      option ||
                      '';
        },
        [isOptionEqualToValue, options]
    );
    const getOptionLabel = providedGetOptionLabel || memoizedGetOptionLabel;
    const handleRequestResults = useCallback(
        searchTerm => {
            setLoading(true);
            return onRequestResults
                ? onRequestResults({ offset: 0, limit, query: searchTerm || '' }).then(results => {
                      fetchResults.current &&
                          setResults(
                              sortOptions
                                  ? (results || []).sort((a, b) =>
                                        // Compare if `a` is a string, otherwise treat as equal.
                                        a?.label?.localeCompare ? a.label.localeCompare(b.label) : 0
                                    )
                                  : results || []
                          );
                      fetchResults.current && setLoading(false);
                  })
                : Promise.resolve().then(() => {
                      fetchResults.current && setLoading(false);
                      return options;
                  });
        },
        [limit, onRequestResults, options]
    );

    useEffect(() => {
        fetchResults.current = debounce(handleRequestResults, 300, {
            trailing: true,
        });
        return () => {
            fetchResults.current && fetchResults.current.cancel();
            fetchResults.current = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleRequestResults]);

    useEffect(() => {
        inputValue.current = value || undefined;
        currentValue.current = providedValue;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionId]);

    const handleOpen = useCallback(() => {
        setOpen(true);
        (!results || results.length === 0) && handleRequestResults(inputValue.current);
        onFocus && onFocus();
    }, [handleRequestResults, onFocus, results]);

    const handleBlur = useCallback(
        event => {
            if (freeSolo) {
                const newValue =
                    // We only want to create a new value if this blur was not triggered by selecting an option
                    inputValue.current &&
                    (!currentValue.current || currentValue.current?.label !== inputValue.current)
                        ? {
                              label: event.target.value,
                              value: event.target.value,
                          }
                        : null;
                if (newValue) {
                    if (multiple) {
                        currentValue.current = [...currentValue.current, newValue];
                        inputValue.current = '';
                    } else {
                        currentValue.current = newValue;
                        inputValue.current = newValue ? newValue.label : '';
                    }
                }
                onChange && onChange(currentValue.current);
            } else {
                // We need to revert the value back to the selected value when the field is not `freeSolo`
                inputValue.current = (currentValue.current && currentValue.current?.label) || '';
            }
            setOpen(false);
            onBlur && onBlur(currentValue.current);
        },
        [onBlur, onChange, freeSolo, multiple]
    );

    const handleInputChange = useCallback(
        (event, value, reason) => {
            switch (reason) {
                case 'input': {
                    inputValue.current = value;
                    if (onRequestResults) {
                        if (
                            fetchResults.current &&
                            open &&
                            (!minLength || !value || value.length >= minLength)
                        ) {
                            return fetchResults.current(value);
                        }
                    }
                    break;
                }
                case 'clear': {
                    inputValue.current = '';
                    break;
                }
            }
            return setResults(options || emptyArray);
        },
        [onRequestResults, options, open, minLength]
    );

    const handleChange = useCallback(
        (event, value, reason) => {
            if (reason === 'blur') {
                return;
            }
            switch (reason) {
                case 'createOption':
                case 'selectOption': {
                    inputValue.current = multiple ? '' : value?.label || value;
                    currentValue.current = value;
                }
                // eslint-disable-next-line no-fallthrough
                case 'removeOption': {
                    currentValue.current = value || null;
                    onChange && onChange(value || null, reason);
                    /*
                     *  Since you can remove an option without focusing on the field
                     *  we need to blur the field to trigger validation
                     */
                    onBlur && onBlur(currentValue.current);
                    setOpen(false);
                    break;
                }
                case 'clear': {
                    currentValue.current = null;
                    onChange && onChange(multiple ? [] : null);
                    inputValue.current = '';
                    break;
                }
            }
        },
        [multiple, onChange]
    );

    const handleKeyDown = useCallback(
        event => {
            if (freeSolo && multiple && newChipKeyCodes.includes(event.keyCode)) {
                event.preventDefault();
                event.stopPropagation();
                // Do not let MUI handle this keypress anymore
                event.defaultMuiPrevented = true;
                const newValue = [...currentValue.current, event.target.value];
                inputValue.current = '';
                handleChange(event, newValue, 'createOption');
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [freeSolo, newChipKeyCodes.join(','), value, handleChange]
    );

    const renderOption = useCallback(
        (props, option, state) => {
            const { value = option } = option;
            return providedRenderOption ? (
                providedRenderOption(props, option, state)
            ) : (
                <li {...props} key={value}>
                    {getOptionLabel(option)}
                </li>
            );
        },
        [providedRenderOption, getOptionLabel]
    );

    const renderTags = useCallback(
        (tagValue, getTagProps) => {
            return providedTagRenderer
                ? providedTagRenderer(tagValue, getTagProps)
                : tagValue.map((option, index) => {
                      const { disabled, value = option } = option || {};
                      return (
                          <Chip
                              key={value}
                              classes={{
                                  root: classes.chipRoot,
                              }}
                              {...getTagProps({ index })}
                              label={getOptionLabel(option)}
                              disabled={disabled}
                          />
                      );
                  });
        },
        [classes.chipRoot, getOptionLabel, providedTagRenderer]
    );

    const renderInput = useCallback(
        ({
            InputProps: InputPropsAutocomplete,
            inputProps: inputPropsAutocomplete,
            InputLabelProps: InputLabelPropsAutocomplete,
            ...params
        }) => {
            return (
                <Component
                    {...params}
                    InputProps={merge(InputPropsAutocomplete, InputProps || {})}
                    inputProps={merge(inputPropsAutocomplete, inputProps || {}, {
                        value: translate(inputValue.current) ?? inputPropsAutocomplete?.value,
                    })}
                    InputLabelProps={merge(InputLabelPropsAutocomplete, InputLabelProps || {})}
                    onKeyDownCapture={handleKeyDown}
                    label={<Locale path={label} />}
                    placeholder={translate(placeholder)}
                    classes={{
                        root: classes.inputRoot,
                    }}
                    required={required}
                    helperText={helperText ? <Locale path={helperText} /> : null}
                    error={error}
                    autoComplete="off"
                    variant="outlined"
                    onBlur={handleBlur}
                />
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            Component,
            handleBlur,
            handleKeyDown,
            label,
            placeholder,
            error,
            helperText,
            required,
            classes.inputRoot,
            InputProps,
            InputLabelProps,
            inputProps,
            providedValue,
        ]
    );

    // For Autocomplete on multiple values, we need to always provide the same empty array when the value is empty
    const memoValue = useMemo(() => {
        const val = multiple ? castArray(providedValue) : providedValue;
        return isArray(val) ? (val.length ? val : emptyArray) : val;
    }, [multiple, providedValue]);

    return (
        <MuiAutocomplete
            autoSelect={!!autoSelect}
            blurOnSelect={!!blurOnSelect}
            classes={{
                root: classes.root,
            }}
            filterSelectedOptions
            disabled={disabled}
            fullWidth
            loading={loading}
            onChange={handleChange}
            inputValue={
                undefined /* Setting this in `renderInput` so we don't cause an infinite loop in the `useAutocomplete` hook */
            }
            name={name}
            onInputChange={handleInputChange}
            onOpen={handleOpen}
            openOnFocus={true}
            open={open}
            options={
                placeholder ? addCurrentValueToOptions(currentValue.current, results) : results
            }
            selectOnFocus={selectOnFocus ?? !freeSolo}
            renderTags={renderTags}
            renderInput={renderInput}
            /* Setting the `getOptionLabel` prop shouldn't be necessary as setting
               `renderOption` overrides it, but as of MUI 5.2.7 there is some code
               in Autocomplete that still calls it. Something about our usage
               causes a lot of warnings without this set. This issue may be related:
               https://github.com/mui-org/material-ui/issues/26492 */
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            isOptionEqualToValue={isOptionEqualToValue}
            multiple={multiple}
            freeSolo={!!freeSolo}
            value={memoValue}
        />
    );
};

ValidationAutocomplete.propTypes = {
    autoSelect: PropTypes.bool,
    blurOnSelect: PropTypes.bool,
    component: PropTypes.any,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.any,
            })
        ),
    ]),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.bool,
    freeSolo: PropTypes.bool,
    getOptionLabel: PropTypes.func,
    helperText: PropTypes.string,
    isOptionEqualToValue: PropTypes.func,
    inputProps: PropTypes.object,
    InputProps: PropTypes.object,
    InputLabelProps: PropTypes.object,
    label: PropTypes.string,
    limit: PropTypes.number,
    minLength: PropTypes.number,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    newChipKeyCodes: PropTypes.arrayOf(PropTypes.number),
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onRequestResults: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
        })
    ),
    renderOption: PropTypes.func,
    renderTags: PropTypes.func,
    selectOnFocus: PropTypes.bool,
    sortOptions: PropTypes.bool,
    value: PropTypes.any,
};

export default ValidationAutocomplete;
