import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    namedParameters,
    singleParameter,
} from '../../ReduxHelpers';

export const events = {
    change: namedParameters('field', 'value', 'error'),
    refresh: singleParameter, // When the ORM updates
};

export const asyncEvents = makeAsyncEvents(['fetchDetails', 'update', 'delete'], true);

export const types = makeActionTypes(
    [...Object.keys(events), ...Object.keys(asyncEvents)],
    '@@Hooks__ORMDetails'
);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
    },
    types
);
