import * as mapDispatchToProps from './AccountSecurity.commands.js';

import Component from './AccountSecurity.component.jsx';
import SMSIcon from '@mui/icons-material/Sms';
import TOTPIcon from '@mui/icons-material/ScreenLockPortrait';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const MFA_OPTIONS = {
    SMS_MFA: {
        icon: SMSIcon,
        primary: 'views.Account.components.AccountSecurity.sms',
        secondary: 'views.Account.components.AccountSecurity.smsRatesApply',
        value: 'SMS',
    },
    SOFTWARE_TOKEN_MFA: {
        icon: TOTPIcon,
        primary: 'views.Account.components.AccountSecurity.authenticatorApp',
        secondary: 'views.Account.components.AccountSecurity.authenticatorAppExamples',
        value: 'TOTP',
    },
};

const mapStateToProps = state => {
    const phone = fromState.Auth.getPhone(state);
    return {
        preferredMFA: fromState.Auth.getMfaType(state),
        mfaOptions: fromState.Auth.getMfaOptions(state),
        phone,
        hasPhone: !!fromState.Auth.getPhone(state),
        isPhoneVerified: fromState.Auth.isPhoneVerified(state),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { mfaOptions } = stateProps;
    return {
        ...ownProps,
        ...stateProps,
        mfaOptions: mfaOptions.map(option => MFA_OPTIONS[option]),
        additionalMfaOptions: Object.keys(MFA_OPTIONS)
            .filter(option => !mfaOptions.includes(option))
            .map(option => MFA_OPTIONS[option]),
        onSetPreferredMFA: params => {
            return dispatchProps.setPreferredMFA({
                mfaOptions,
                ...params,
            });
        },
        onRemoveMFA: params => {
            return dispatchProps.removeMFA({
                mfaOptions,
                ...params,
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
