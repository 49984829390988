import {
    refreshTokens as RefreshTokensService,
    signOut as SignOutService,
} from '/b2b/authentication/services';

import AuthActions from '/b2b/authentication/state/Auth.actions';
import { changePassword } from '/b2b/authentication/services';

export const changeUserPassword =
    ({ userId, password: oldPassword, newPassword, revoke }) =>
    dispatch => {
        const params = { userId };
        dispatch(AuthActions.changePasswordBegin(params));
        return changePassword({ oldPassword, newPassword, revoke })
            .then(details => {
                dispatch(AuthActions.changePasswordSuccess(params, details));
                return details;
            })
            .catch(error => {
                const { code, message } = error;
                switch (code) {
                    case 'LimitExceededException': {
                        // Too many password change attempts
                        dispatch(AuthActions.changePasswordFailure(params, message));
                        throw message;
                    }
                }
                dispatch(AuthActions.changePasswordFailure(params, message));
                // Error here means that we were logged out or had tokens revoked
                return RefreshTokensService().catch(() => {
                    // Could not refresh tokens
                    dispatch(AuthActions.logout());
                    SignOutService();
                });
            });
    };
