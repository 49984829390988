import { createSchema } from '/b2b/common/helpers/Schema';

export default createSchema({
    mfaType: {
        type: 'hidden',
    },
    code(value, attributes) {
        const constraint = {
            label: 'Code',
            props: {
                placeholder: 'Enter your code...',
                autoFocus: true,
                InputLabelProps: {
                    shrink: true,
                },
            },
            length: {
                is: 6,
                notValid: 'is not valid',
            },
            presence: { allowEmpty: false },
        };
        switch (attributes.mfaType) {
            case 'TOTP':
            case 'SOFTWARE_TOKEN_MFA': {
                constraint.label = 'Authenticator Code';
                break;
            }

            case 'SMS':
            case 'SMS_MFA': {
                constraint.label = 'SMS Code';
                break;
            }
        }
        return constraint;
    },
});
