import { Link, Typography } from '@mui/material';

import { Logo as OsanoLogo } from '../../icons/Osano';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import styles from './Welcome.styles.js';

const useStyles = makeStyles(styles);

const Welcome = props => {
    const classes = useStyles(props);
    return (
        <div className={classes.quote}>
            <div className={classes.quoteInner}>
                <Typography className={classes.quoteHeadline} variant="h1">
                    <OsanoLogo className={classes.logo} alt="Osano" />
                </Typography>
                <Typography className={classes.quoteSubhead} variant="body1">
                    If you are not yet an Osano subscriber, please visit
                    <br />
                    <Link
                        className={classes.link}
                        href="https://www.osano.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://www.osano.com
                    </Link>
                </Typography>
            </div>
        </div>
    );
};

Welcome.propTypes = {};

export default Welcome;
