import {
    colorNameToHex,
    contrastRgb,
    hslToRgb,
    hueFromString,
    intToHexString,
    parseColor,
} from '/b2b/common/helpers/Color';
import {
    defaultIdAttribute,
    defaultMergeStrategy,
    defaultProcessStrategy,
} from '/b2b/common/helpers/Normalizr';

import { schema } from 'normalizr';

export const entityName = 'helpScoutTags';
export const idAttribute = 'helpScoutTagId';

export const mergeStrategy = defaultMergeStrategy(idAttribute);
export const processStrategy = defaultProcessStrategy(idAttribute, data => {
    const { name } = data;
    const rgb = hslToRgb(hueFromString(`${name}`) / 360, 0.6, 0.9);
    let { color = intToHexString.apply(null, rgb) } = data;
    color = colorNameToHex(color);
    return {
        textColor: color && contrastRgb.apply(null, parseColor(color)) ? '#ffffff' : '#000000',
        color: color && intToHexString.apply(null, parseColor(color)),
        ...data,
        name,
    };
});

export default new schema.Entity(entityName, undefined, {
    idAttribute: defaultIdAttribute(idAttribute),
    mergeStrategy,
    processStrategy,
});
