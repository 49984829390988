import {
    types as ActionTypes,
    asyncEvents as AsyncEvents,
    crudEvents as CRUDEvents,
    filterEvents as FilterEvents,
    statusEvents as StatusEvents,
} from './Versions.actions';
import {
    collectionReducer,
    crudPagingReducers,
    crudStatusByIdReducer,
} from '../../../common/helpers/ReduxHelpers';

import { combineReducers } from 'redux';
import { genericReducer } from '../../../common/helpers/ReduxHelpers';
import reduceReducers from 'reduce-reducers';

export const initialState = {
    offset: 0,
    limit: 10,
    query: '',
    status: {},
    results: [],
    resultCount: 0,
    error: null,
    loading: false,
    filters: {},
    sort: '',
    sortDirection: 'none',
    planData: {},
};

export default reduceReducers(
    initialState,
    combineReducers({
        filters: collectionReducer(
            initialState.filters,
            Object.keys(FilterEvents).map(key => ActionTypes[`${key}`])
        ),
        status: crudStatusByIdReducer(
            initialState.status,
            Object.keys(StatusEvents).map(key => ActionTypes[`${key}`])
        ),
        ...crudPagingReducers(
            initialState,
            Object.keys(AsyncEvents).map(key => ActionTypes[`${key}`])
        ),
    }),
    collectionReducer(
        initialState,
        Object.keys(CRUDEvents).map(key => ActionTypes[`${key}`])
    ),
    genericReducer(initialState, {
        [ActionTypes.fetchResultsSuccess]: state => {
            const { results = [] } = state;

            return {
                ...state,
                results,
            };
        },
    })
);
