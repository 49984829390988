import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { PERMISSIONS } from '/b2b/authentication/constants';

const basePath = 'views.Dashboard.todos.labels';

export const TODOS = {
    // assessments
    dueSoonAssessmentAssignments: {
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.ASSESSMENT,
        },
        urlHref: AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS,
        urlPath: `${basePath}.nAssignments`,
        path: `${basePath}.dueWithinNDays`,
        days: 14,
        order: 1,
    },
    overdueAssessmentAssignments: {
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.ASSESSMENT,
        },
        urlHref: AuthenticatedPaths.ASSESSMENT_ASSIGNMENTS,
        urlPath: `${basePath}.nAssignments`,
        path: `${basePath}.overdue`,
        order: 0,
        warning: true,
    },
    unpublishedAssessmentTemplates: {
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.ASSESSMENT_TEMPLATES,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.ASSESSMENT,
        },
        urlHref: AuthenticatedPaths.ASSESSMENT_TEMPLATES,
        urlPath: `${basePath}.nTemplates`,
        path: `${basePath}.unpublished`,
        order: 2,
    },
    // cookieConsent
    consentConfigUnclassified: {
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.CONSENT_MANAGER,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.CONSENTS,
        },
        urlHref: AuthenticatedPaths.CONSENT_MANAGER,
        urlPath: `${basePath}.nConfigurations`,
        path: `${basePath}.unclassified`,
        order: 0,
    },
    unpublishedConsentConfigs: {
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.CONSENT_MANAGER,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.CONSENTS,
        },
        urlHref: `${AuthenticatedPaths.CONSENT_MANAGER}?publishStatus=unpublished`,
        urlPath: `${basePath}.nConfigurations`,
        path: `${basePath}.unpublished`,
        order: 1,
    },
    // dataMapping
    discoErrors: {
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DATA_STORES,
        },
        rolePermission: {
            toPerform: 'write',
            onAny: [PERMISSIONS.DATA_MAPPING, PERMISSIONS.DSAR_REQUEST],
        },
        urlHref: `${AuthenticatedPaths.DATA_STORES}?status=ERROR`,
        urlPath: `${basePath}.nDataStores`,
        path: `${basePath}.errors`,
        order: 0,
        warning: true,
    },
    sourceErrors: {
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DATA_MAPPING_SOURCES,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.DATA_MAPPING,
        },
        urlHref: AuthenticatedPaths.DATA_MAPPING_SOURCES,
        urlPath: `${basePath}.nSources`,
        path: `${basePath}.errors`,
        order: 1,
        warning: true,
    },
    unassignedApps: {
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DATA_MAPPING_APPLICATIONS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.DATA_MAPPING,
        },
        urlHref: `${AuthenticatedPaths.DATA_MAPPING_APPLICATIONS}?status=NEW`,
        urlPath: `${basePath}.nDiscoveries`,
        path: `${basePath}.unassigned`,
        order: 2,
    },
    // subjectRights
    actionItemsOverdueRegulatory: {
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DSAR_ACTION_ITEMS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.DSAR_DATASOURCE,
        },
        urlHref: AuthenticatedPaths.DSAR_ACTION_ITEMS,
        urlPath: `${basePath}.nActionItems`,
        path: `${basePath}.overdue`,
        order: 4,
        warning: true,
    },
    actionItemsOverdueSoon: {
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DSAR_ACTION_ITEMS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.DSAR_DATASOURCE,
        },
        urlHref: AuthenticatedPaths.DSAR_ACTION_ITEMS,
        urlPath: `${basePath}.nActionItems`,
        path: `${basePath}.dueWithinNDays`,
        days: 7,
        order: 5,
    },
    pendingIdVerification: {
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DSAR_REQUESTS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.DSAR_REQUEST,
        },
        urlHref: `${AuthenticatedPaths.DSAR_REQUESTS}?requestStatus=is%3APENDING_IDENTITY_VERIFICATION`,
        urlPath: `${basePath}.nRequests`,
        path: `${basePath}.awaitingIdVerification`,
        order: 3,
    },
    requestOverdueSoon: {
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DSAR_REQUESTS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.DSAR_REQUEST,
        },
        urlHref: `${AuthenticatedPaths.DSAR_REQUESTS}?spotlightMetric=is%3AdueWithin7DaysRegulatory`,
        urlPath: `${basePath}.nRequests`,
        path: `${basePath}.dueWithinNDays`,
        days: 7,
        order: 1,
    },
    requestsOverdueInternal: {
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DSAR_REQUESTS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.DSAR_REQUEST,
        },
        urlHref: `${AuthenticatedPaths.DSAR_REQUESTS}?spotlightMetric=is%3AcurrentlyOverdueInternal`,
        urlPath: `${basePath}.nRequests`,
        path: `${basePath}.overdueInternal`,
        order: 2,
    },
    requestsOverdueRegulatory: {
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DSAR_REQUESTS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.DSAR_REQUEST,
        },
        urlHref: `${AuthenticatedPaths.DSAR_REQUESTS}?spotlightMetric=is%3AcurrentlyOverdueRegulatory`,
        urlPath: `${basePath}.nRequests`,
        path: `${basePath}.overdueRegulatory`,
        order: 0,
        warning: true,
    },
    // unifiedConsent
    unpublishedConfigCount: {
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.UC_CONFIGS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.UNIFIED_CONSENT,
        },
        urlHref: AuthenticatedPaths.UC_CONFIGS,
        urlPath: `${basePath}.nConfigurations`,
        path: `${basePath}.unpublished`,
        order: 0,
    },
    // vendorMonitoring
    vendorReviewsDueSoon: {
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.VENDORS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.MONITORING,
        },
        urlHref: AuthenticatedPaths.VENDORS,
        urlPath: `${basePath}.nVendorReviews`,
        path: `${basePath}.dueWithinNDays`,
        days: 14,
        order: 1,
    },
    vendorReviewsOverdue: {
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.VENDORS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.MONITORING,
        },
        urlHref: AuthenticatedPaths.VENDORS,
        urlPath: `${basePath}.nVendorReviews`,
        path: `${basePath}.overdue`,
        order: 0,
        warning: true,
    },
};
